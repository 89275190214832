import {
  CASE_AUDIT_ADD_NEW,
  CASE_AUDIT_ADD_NEW_SUCCESS,
  CASE_AUDIT_ADD_NEW_ERROR,
  CASE_AUDIT_EDIT,
  CASE_AUDIT_EDIT_ERROR,
  CASE_AUDIT_EDIT_SUCCESS,
  CASE_AUDIT_GET_BY_QUERY,
  CASE_AUDIT_GET_BY_ID,
  CASE_AUDIT_GET_BY_QUERY_SUCCESS,
  CASE_AUDIT_GET_BY_QUERY_ERROR,
  CASE_AUDIT_GET_BY_ID_SUCCESS,
  CASE_AUDIT_ADD_NEW_REQUEST,
  CASE_AUDIT_DELETE,
  CASE_AUDIT_DELETE_SUCCESS,
  CASE_AUDIT_DELETE_ERROR,
  CASE_AUDIT_GET_BY_ID_ERROR,
} from './types';

// CaseAudit

export const addNewCaseAudit = items => ({
  type: CASE_AUDIT_ADD_NEW,
  payload: items,
});

export const addNewCaseAuditRequest = () => ({
  type: CASE_AUDIT_ADD_NEW_REQUEST,
});

export const addNewCaseAuditSuccess = items => ({
  type: CASE_AUDIT_ADD_NEW_SUCCESS,
  payload: items,
});

export const addNewCaseAuditError = error => ({
  type: CASE_AUDIT_ADD_NEW_ERROR,
  payload: error,
});

export const editCaseAudit = item => ({
  type: CASE_AUDIT_EDIT,
  payload: item,
});

export const editCaseAuditSuccess = item => ({
  type: CASE_AUDIT_EDIT_SUCCESS,
  payload: item,
});

export const editCaseAuditError = error => ({
  type: CASE_AUDIT_EDIT_ERROR,
  payload: error,
});

export const getCaseAuditByQuery = query => ({
  type: CASE_AUDIT_GET_BY_QUERY,
  payload: query,
});

export const getCaseAuditByQuerySuccess = item => ({
  type: CASE_AUDIT_GET_BY_QUERY_SUCCESS,
  payload: item,
});

export const getCaseAuditByQueryError = error => ({
  type: CASE_AUDIT_GET_BY_QUERY_ERROR,
  payload: error,
});

export const getCaseAuditById = id => ({
  type: CASE_AUDIT_GET_BY_ID,
  payload: id,
});

export const getCaseAuditByIdSuccess = item => ({
  type: CASE_AUDIT_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getCaseAuditByIdError = error => ({
  type: CASE_AUDIT_GET_BY_ID_ERROR,
  payload: error,
});

export const deleteCaseAuditById = id => ({
  type: CASE_AUDIT_DELETE,
  payload: id,
});

export const deleteCaseAuditByIdSuccess = item => ({
  type: CASE_AUDIT_DELETE_SUCCESS,
  payload: item,
});

export const deleteCaseAuditByIdErrorr = item => ({
  type: CASE_AUDIT_DELETE_ERROR,
  payload: item,
});
