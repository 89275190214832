import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  EMPLOYMENT_ACTION_PLAN_DELETE,
  EMPLOYMENT_ACTION_PLAN_EDIT,
  EMPLOYMENT_ACTION_PLAN_GET_BY_ID,
  EMPLOYMENT_ACTION_PLAN_ADD_NEW,
  EMPLOYMENT_ACTION_PLAN_GET_LIST,
  EMPLOYMENT_ACTION_PLAN_QUERY,
  EMPLOYMENT_ACTION_PLAN_BY_CLIENT,
} from '../actions';
import Config from '../../config';

import {
  getEmploymentActionPlanListSuccess,
  getEmploymentActionPlanListError,
  editEmploymentActionPlanSuccess,
  editEmploymentActionPlanError,
  addNewEmploymentActionPlanSuccess,
  addNewEmploymentActionPlanError,
  deleteEmploymentActionPlanSuccess,
  deleteEmploymentActionPlanError,
  getEmploymentActionPlanByIdSuccess,
  getEmploymentActionPlanQuerySuccess,
  getEmploymentActionPlanQueryError,
  getEmploymentActionPlanByClientSuccess,
  getEmploymentActionPlanByClientError,
} from './actions';

const getEmploymentActionPlanQueryRequest = async query => {
  const url = `${Config.apiServerHost}/api/EmploymentActionPlan/searchByClient`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const getEmploymentActionPlanListRequest = async query => {
  const url = `${Config.apiServerHost}/api/EmploymentActionPlan/search`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const addNewEmploymentActionPlanReqeust = async caseNote => {
  return axios.post(
    `${Config.apiServerHost}/api/EmploymentActionPlan`,
    caseNote,
    await authHeader()
  );
};

const editEmploymentActionPlanRequest = async caseNote => {
  return axios.put(
    `${Config.apiServerHost}/api/EmploymentActionPlan`,
    caseNote,
    await authHeader()
  );
};

const deleteEmploymentActionPlanRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/EmploymentActionPlan/${id}`, await authHeader());
};

const getEmploymentActionPlanByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/EmploymentActionPlan/${id}`, await authHeader());
};

function* getEmploymentActionPlanList({ payload }) {
  try {
    const response = yield call(getEmploymentActionPlanListRequest, payload);
    yield put(getEmploymentActionPlanListSuccess(response.data));
  } catch (error) {
    yield put(getEmploymentActionPlanListError(error.response.statusText));
  }
}

function* getEmploymentActionPlanQuery({ payload }) {
  try {
    const response = yield call(getEmploymentActionPlanQueryRequest, payload);
    yield put(getEmploymentActionPlanQuerySuccess(response.data));
  } catch (error) {
    yield put(getEmploymentActionPlanQueryError(error.response.statusText));
  }
}

function* getEmploymentActionPlanByClient({ payload }) {
  try {
    const response = yield call(getEmploymentActionPlanListRequest, payload);
    const { data } = response;
    let item = null;
    if (data.length > 0) {
      item = data[0];
    }
    yield put(getEmploymentActionPlanByClientSuccess(item));
  } catch (error) {
    yield put(getEmploymentActionPlanByClientError(error.response.statusText));
  }
}

function* addNewEmploymentActionPlan({ payload }) {
  try {
    let response = yield call(addNewEmploymentActionPlanReqeust, payload);
    response = yield call(getEmploymentActionPlanByIdRequest, response.data);
    yield put(addNewEmploymentActionPlanSuccess(response.data));
  } catch (error) {
    yield put(addNewEmploymentActionPlanError(error.response.statusText));
  }
}

function* editEmploymentActionPlan({ payload }) {
  try {
    let response = yield call(editEmploymentActionPlanRequest, payload);
    response = yield call(getEmploymentActionPlanByIdRequest, payload.id);
    yield put(editEmploymentActionPlanSuccess(response.data));
  } catch (error) {
    yield put(editEmploymentActionPlanError(error.response.statusText));
  }
}

function* getEmploymentActionPlanById({ payload }) {
  try {
    const response = yield call(getEmploymentActionPlanByIdRequest, payload);
    yield put(getEmploymentActionPlanByIdSuccess(response.data));
  } catch (error) {
    yield put(editEmploymentActionPlanError(error.response.statusText));
  }
}

function* deleteEmploymentActionPlan({ payload }) {
  try {
    const response = yield call(deleteEmploymentActionPlanRequest, payload);
    yield put(deleteEmploymentActionPlanSuccess(response.data));
  } catch (error) {
    yield put(deleteEmploymentActionPlanError(error.response.statusText));
  }
}

export function* watchEmploymentActionPlanQuery() {
  yield takeEvery(EMPLOYMENT_ACTION_PLAN_QUERY, getEmploymentActionPlanQuery);
}

export function* watchEmploymentActionPlanByClient() {
  yield takeEvery(EMPLOYMENT_ACTION_PLAN_BY_CLIENT, getEmploymentActionPlanByClient);
}

export function* watchEmploymentActionPlanList() {
  yield takeEvery(EMPLOYMENT_ACTION_PLAN_GET_LIST, getEmploymentActionPlanList);
}

export function* watchAddNewEmploymentActionPlan() {
  yield takeEvery(EMPLOYMENT_ACTION_PLAN_ADD_NEW, addNewEmploymentActionPlan);
}

export function* watchEmploymentActionPlanById() {
  yield takeEvery(EMPLOYMENT_ACTION_PLAN_GET_BY_ID, getEmploymentActionPlanById);
}

export function* watchEditEmploymentActionPlan() {
  yield takeEvery(EMPLOYMENT_ACTION_PLAN_EDIT, editEmploymentActionPlan);
}

export function* watchDeleteEmploymentActionPlan() {
  yield takeEvery(EMPLOYMENT_ACTION_PLAN_DELETE, deleteEmploymentActionPlan);
}

export default function* rootSaga() {
  yield all([
    fork(watchEmploymentActionPlanQuery),
    fork(watchEmploymentActionPlanByClient),
    fork(watchEmploymentActionPlanList),
    fork(watchAddNewEmploymentActionPlan),
    fork(watchEmploymentActionPlanById),
    fork(watchEditEmploymentActionPlan),
    fork(watchDeleteEmploymentActionPlan),
  ]);
}
