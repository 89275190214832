import {
  EMPLOYER_INFO_GET_BY_ID,
  EMPLOYER_INFO_GET_BY_ID_ERROR,
  EMPLOYER_INFO_GET_BY_ID_SUCCESS,
  EMPLOYER_INFO_UPDATE,
  EMPLOYER_INFO_UPDATE_SUCCESS,
  EMPLOYER_INFO_UPDATE_ERROR,
  EMPLOYER_INFO_SYNC,
  EMPLOYER_INFO_SYNC_SUCCESS,
  EMPLOYER_INFO_SYNC_ERROR,
  EMPLOYER_INFO_ADD_NEW,
  EMPLOYER_INFO_ADD_NEW_SUCCESS,
  EMPLOYER_INFO_ADD_NEW_ERROR,
  EMPLOYER_INFO_CLEAR_NOTIFY,
} from './types';
// EmployerInfo
export const getEmployerInfoById = id => ({
  type: EMPLOYER_INFO_GET_BY_ID,
  payload: id,
});

export const getEmployerInfoByIdSuccess = clientInfo => ({
  type: EMPLOYER_INFO_GET_BY_ID_SUCCESS,
  payload: clientInfo,
});

export const getEmployerInfoByIdError = error => ({
  type: EMPLOYER_INFO_GET_BY_ID_ERROR,
  payload: error,
});

export const updateEmployerInfo = clientInfo => ({
  type: EMPLOYER_INFO_UPDATE,
  payload: clientInfo,
});

export const updateEmployerInfoSuccess = clientInfo => ({
  type: EMPLOYER_INFO_UPDATE_SUCCESS,
  payload: clientInfo,
});

export const updateEmployerInfoError = error => ({
  type: EMPLOYER_INFO_UPDATE_ERROR,
  payload: error,
});

export const addNewEmployerInfo = clientInfo => ({
  type: EMPLOYER_INFO_ADD_NEW,
  payload: clientInfo,
});

export const addNewEmployerInfoSuccess = clientInfo => ({
  type: EMPLOYER_INFO_ADD_NEW_SUCCESS,
  payload: clientInfo,
});

export const addNewEmployerInfoError = error => ({
  type: EMPLOYER_INFO_ADD_NEW_ERROR,
  payload: error,
});

export const syncEmployerInfo = (id, employerReferenceNumberOverride) => ({
  type: EMPLOYER_INFO_SYNC,
  payload: { id, employerReferenceNumberOverride },
});

export const syncEmployerInfoSuccess = clientInfo => ({
  type: EMPLOYER_INFO_SYNC_SUCCESS,
  payload: clientInfo,
});

export const syncEmployerInfoError = error => ({
  type: EMPLOYER_INFO_SYNC_ERROR,
  payload: error,
});

export const clearEmployerInfoNotify = () => ({
  type: EMPLOYER_INFO_CLEAR_NOTIFY,
});
