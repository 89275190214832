import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  GET_DOCUMENTS_BY_CLIENT_ID,
  SAVE_DOCUMENTS,
  DELETE_DOCUMENT_BY_ID,
  GET_DOCUMENTS_BY_ID,
  UPDATE_DOCUMENTS,
} from '../actions';

import {
  getDocumentsByClientIdSuccess,
  getDocumentsByClientIdError,
  saveDocumentsError,
  deleteDocumentByIdError,
  getDocumentsByIdSuccess,
  getDocumentsByIdError,
  // updateDocumentError,
  // updateDocumentSuccess,
} from './actions';

const getDocumentsByClientIdRequest = async query => {
  let url = `${Config.apiServerHost}/api/client/searchDocuments`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getDocumentsByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/client/searchDocumentsPaging`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getDocumentsByIdRequest = async id => {
  return await axios.get(`${Config.apiServerHost}/api/ClientDocument/${id}`, await authHeader());
};

const updateDocumentsRequest = async caseNote => {
  return axios.put(`${Config.apiServerHost}/api/ClientDocument`, caseNote, await authHeader());
};

const saveDocumentsRequest = async (documents, clientId) => {
  return axios.put(
    `${Config.apiServerHost}/api/client/${clientId}/document`,
    documents,
    await authHeader()
  );
};

const createFileUploadSecureLinkRequest = async (clientId, servicePlanHeaderId) => {
  return axios.post(
    `${Config.apiServerHost}/api/fileUpload/securelink/client/${clientId}/servicePlan/${servicePlanHeaderId}`,
    null,
    await authHeader()
  );
};

const deleteDocumentByIdRequest = async (clientId, id) => {
  return axios.delete(
    `${Config.apiServerHost}/api/client/${clientId}/document/${id}`,
    await authHeader()
  );
};

function* getDocumentsByClientId({ payload }) {
  try {
    const response = yield call(getDocumentsByClientIdRequest, payload);
    yield put(getDocumentsByClientIdSuccess(response.data));
  } catch (error) {
    yield put(getDocumentsByClientIdError(error.response.statusText));
  }
}

function* getDocumentsById({ payload }) {
  try {
    const response = yield call(getDocumentsByIdRequest, payload);
    yield put(getDocumentsByIdSuccess(response.data));
  } catch (error) {
    yield put(getDocumentsByIdError(error.response.statusText));
  }
}

function* saveDocuments({ payload }) {
  try {
    const { documents, clientId } = payload;
    let response = yield call(saveDocumentsRequest, documents, clientId);
    response = yield call(getDocumentsByClientIdRequest, response.data);
    yield put(getDocumentsByClientIdSuccess(response.data));
  } catch (error) {
    yield put(saveDocumentsError(error.response.statusText));
  }
}

function* updateDocuments({ payload }) {
  try {
    const { clientDocument, clientId, programId, servicePlanHeaderId } = payload;
    let response = yield call(updateDocumentsRequest, clientDocument);
    const query = {
      clientId,
      programId,
      servicePlanHeaderId: servicePlanHeaderId ?? '',
    };
    response = yield call(getDocumentsByClientIdRequest, query);
    yield put(getDocumentsByClientIdSuccess(response.data));
  } catch (error) {
    yield put(saveDocumentsError(error.response.statusText));
  }
}

function* deleteDocumentById({ payload }) {
  try {
    const { clientId, id, programId, servicePlanHeaderId } = payload;
    // logger.debug(payload);
    const query = {
      clientId,
      programId,
      servicePlanHeaderId,
    };
    let response = yield call(deleteDocumentByIdRequest, clientId, id);
    response = yield call(getDocumentsByClientIdRequest, query);
    yield put(getDocumentsByClientIdSuccess(response.data));
  } catch (error) {
    yield put(deleteDocumentByIdError(error.response.statusText));
  }
}

export function* watchDocumentsByClientId() {
  yield takeEvery(GET_DOCUMENTS_BY_CLIENT_ID, getDocumentsByClientId);
}

export function* watchDocumentsById() {
  yield takeEvery(GET_DOCUMENTS_BY_ID, getDocumentsById);
}

export function* watchSaveingDocuments() {
  yield takeEvery(SAVE_DOCUMENTS, saveDocuments);
}

export function* watchUpdatingDocuments() {
  yield takeEvery(UPDATE_DOCUMENTS, updateDocuments);
}

export function* watchDeleteDocumentById() {
  yield takeEvery(DELETE_DOCUMENT_BY_ID, deleteDocumentById);
}

export default function* rootSaga() {
  yield all([
    fork(watchDocumentsById),
    fork(watchDocumentsByClientId),
    fork(watchUpdatingDocuments),
    fork(watchSaveingDocuments),
    fork(watchDeleteDocumentById),
  ]);
}

export { getDocumentsByQueryRequest, createFileUploadSecureLinkRequest };
