import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  CLIENT_SUPPORT_DELETE,
  CLIENT_SUPPORT_EDIT,
  CLIENT_SUPPORT_GET_BY_ID,
  CLIENT_SUPPORT_ADD_NEW,
  CLIENT_SUPPORT_GET_LIST,
  CLIENT_SUPPORT_LOG_LIST,
  CLIENT_SUPPORT_DELETE_ATTACHMENT,
  CLIENT_SUPPORT_REPORT,
} from './types';

import {
  getClientSupportListSuccess,
  getClientSupportListError,
  editClientSupportError,
  addNewClientSupportError,
  deleteClientSupportError,
  getClientSupportByIdSuccess,
  getClientSupportLogListSuccess,
  getClientSupportLogListError,
  deleteClientSupportAttachmentError,
  deleteClientSupportAttachmentSuccess,
  getClientSupportReportSuccess,
  getClientSupportReportError,
} from './actions';

const getClientSupportLogListRequest = async ClientSupportId => {
  return axios.get(
    `${Config.apiServerHost}/api/ClientSupport/log/${ClientSupportId}`,
    await authHeader()
  );
};

const getClientSupportListRequest = async query => {
  let url = `${Config.apiServerHost}/api/ClientSupport/fetch`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const addNewClientSupportReqeust = async ClientSupport => {
  return axios.post(`${Config.apiServerHost}/api/ClientSupport`, ClientSupport, await authHeader());
};

const editClientSupportRequest = async ClientSupport => {
  return axios.put(`${Config.apiServerHost}/api/ClientSupport`, ClientSupport, await authHeader());
};

const deleteClientSupportRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/ClientSupport/${id}`, await authHeader());
};

const getClientSupportByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/ClientSupport/${id}`, await authHeader());
};

const deleteClientSupportAttachmentRequest = async id => {
  return axios.delete(
    `${Config.apiServerHost}/api/ClientSupport/deleteAttachment/${id}`,
    await authHeader()
  );
};

const getSupportTypeRequest = async () => {
  return await axios.get(
    `${Config.apiServerHost}/api/ClientSupport/supportType`,
    await authHeader()
  );
};

const getSupportPaymentRequest = async () => {
  return await axios.get(
    `${Config.apiServerHost}/api/ClientSupport/supportPayment`,
    await authHeader()
  );
};

const getClientSupportReportRequest = async query => {
  let url = `${Config.apiServerHost}/api/ClientSupport/report`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

function* getClientSupportList({ payload }) {
  try {
    const response = yield call(getClientSupportListRequest, payload);
    yield put(getClientSupportListSuccess(response.data));
  } catch (error) {
    yield put(getClientSupportListError(error.response.statusText));
  }
}

function* getClientSupportLogList({ payload }) {
  try {
    const response = yield call(getClientSupportLogListRequest, payload);
    yield put(getClientSupportLogListSuccess(response.data));
  } catch (error) {
    yield put(getClientSupportLogListError(error.response.statusText));
  }
}

function* addNewClientSupport({ payload }) {
  try {
    const { data, queryParams } = payload;

    yield call(addNewClientSupportReqeust, data);
    const response = yield call(getClientSupportListRequest, queryParams);
    yield put(getClientSupportListSuccess(response.data));
  } catch (error) {
    yield put(addNewClientSupportError(error.response.statusText));
  }
}

function* editClientSupport({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(editClientSupportRequest, data);

    response = yield call(getClientSupportListRequest, queryParams);
    yield put(getClientSupportListSuccess(response.data));
  } catch (error) {
    yield put(editClientSupportError(error.response.statusText));
  }
}

function* getClientSupportById({ payload }) {
  try {
    const response = yield call(getClientSupportByIdRequest, payload);
    yield put(getClientSupportByIdSuccess(response.data));
  } catch (error) {
    yield put(editClientSupportError(error.response.statusText));
  }
}

function* deleteClientSupport({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(deleteClientSupportRequest, data);
    response = yield call(getClientSupportListRequest, queryParams);
    yield put(getClientSupportListSuccess(response.data));
  } catch (error) {
    yield put(deleteClientSupportError(error.response.statusText));
  }
}

function* deleteClientSupportAttachment({ payload }) {
  try {
    const response = yield call(deleteClientSupportAttachmentRequest, payload);
    yield put(deleteClientSupportAttachmentSuccess(response.data));
  } catch (error) {
    yield put(deleteClientSupportAttachmentError(error.response.statusText));
  }
}

function* getClientSupportReport({ payload }) {
  try {
    const response = yield call(getClientSupportReportRequest, payload);
    yield put(getClientSupportReportSuccess(response.data));
  } catch (error) {
    yield put(getClientSupportReportError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(CLIENT_SUPPORT_LOG_LIST, getClientSupportLogList);
  yield takeEvery(CLIENT_SUPPORT_GET_LIST, getClientSupportList);
  yield takeEvery(CLIENT_SUPPORT_ADD_NEW, addNewClientSupport);
  yield takeEvery(CLIENT_SUPPORT_GET_BY_ID, getClientSupportById);
  yield takeEvery(CLIENT_SUPPORT_EDIT, editClientSupport);
  yield takeEvery(CLIENT_SUPPORT_DELETE, deleteClientSupport);
  yield takeEvery(CLIENT_SUPPORT_DELETE_ATTACHMENT, deleteClientSupportAttachment);
  yield takeEvery(CLIENT_SUPPORT_REPORT, getClientSupportReport);
}

export {
  getSupportTypeRequest,
  getSupportPaymentRequest,
  getClientSupportByIdRequest,
  editClientSupportRequest,
  addNewClientSupportReqeust,
  deleteClientSupportRequest,
  getClientSupportListRequest,
};
