import { logger } from '../../logger';
import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  EAP_GET_BY_ID,
  EAP_UPDATE,
  EAP_SYNC,
  GET_EAP_DOCUMENT_LIST,
  GET_EAP_LIST,
  SAVE_EAP_PLAN_ITEM_ATTACHMENT,
  GET_REFRESH_EAP_LIST,
  EAP_DETECT_DIFFERENCE,
  GET_REFRESH_EAP_ATTACHMENT_LIST,
} from './types';

import {
  getEAPByIdError,
  getEAPByIdSuccess,
  getEapDocumentListSuccess,
  getEapListSuccess,
  updateEAPError,
  syncEAPSuccess,
  syncEAPError,
  getRefreshEapListSuccess,
  getRefreshEapAttachmentListSuccess,
  detectDifferenceEapDetailSuccess,
} from './actions';
import { getClientLeftSidebarSuccess, getClientServicePlanSuccess } from '../actions';
import { getClientLeftSidebarRequest, getClientServicePlanRequest } from '../client/saga';

const getSSMUserRequest = async () => {
  return axios.get(`${Config.apiServerHost}/api/EAPDetail/ssmUser`, await authHeader());
};

const getActiveFsApprovals = async () => {
  return axios.get(`${Config.apiServerHost}/api/Approvals/fs/active`, await authHeader());
};

const getActivePbfApprovals = async () => {
  return axios.get(`${Config.apiServerHost}/api/Approvals/pbf/active`, await authHeader());
};

const getLiveEAPTransactions = async () => {
  return axios.get(`${Config.apiServerHost}/api/EAPDetail/transaction/live`, await authHeader());
};

const deletePlannedItemAttachmentRequest = async data => {
  return axios.post(
    `${Config.apiServerHost}/api/EAPDetail/deletePlannedItemAttachment`,
    data,
    await authHeader()
  );
};

const savePlannedItemAttachmentRequest = async (data, documentType, description) => {
  return axios.post(
    `${Config.apiServerHost}/api/EAPDetail/savePlannedItemAttachments?documentType=${documentType}&description=${description}`,
    data,
    await authHeader()
  );
};

const savePlannedItemAttachmentFromExistingRequest = async (data, documentType, description) => {
  return axios.post(
    `${Config.apiServerHost}/api/EAPDetail/savePlannedItemAttachmentsFromExisting?documentType=${documentType}&description=${description}`,
    data,
    await authHeader()
  );
};
const detectDifferenceEapDetailRequest = async queryParam => {
  return axios.get(`${Config.apiServerHost}/api/EAPDetail/detectDifference`, {
    params: queryParam,
    headers: { ...(await authHeader()).headers },
  });
};

const getEAPByIdRequest = async payload => {
  const { clientId, servicePlanHeaderId } = payload;
  return axios.get(
    `${Config.apiServerHost}/api/EAPDetail/client/${clientId}/servicePlan/${servicePlanHeaderId}`,
    await authHeader()
  );
};

const getEaplistRequest = async queryParam => {
  return axios.get(`${Config.apiServerHost}/api/EAPDetail`, {
    params: queryParam,
    headers: { ...(await authHeader()).headers },
  });
};

const getEapDocumentsRequest = async queryParam => {
  return axios.get(`${Config.apiServerHost}/api/EAPDetail/document`, {
    params: queryParam,
    headers: { ...(await authHeader()).headers },
  });
};

const getCreateEapDefault = async queryParam => {
  return axios.get(`${Config.apiServerHost}/api/EAPDetail/create-eap/default`, {
    params: queryParam,
    headers: { ...(await authHeader()).headers },
  });
};

function* getEapList({ payload }) {
  try {
    let response = yield call(getEaplistRequest, payload);
    yield put(getEapListSuccess(response.data));
  } catch (error) {
    yield put(getEAPByIdError(error.response.statusText));
  }
}

function* detectDifferenceEapDetail({ payload }) {
  try {
    let response = yield call(detectDifferenceEapDetailRequest, payload);
    yield put(detectDifferenceEapDetailSuccess(response.data));
  } catch (error) {
    yield put(getEAPByIdError(error.response.statusText));
  }
}

function* getRefreshEapList({ payload }) {
  try {
    let response = yield call(getEaplistRequest, payload);
    yield put(getRefreshEapListSuccess(response.data));
  } catch (error) {
    yield put(getEAPByIdError(error.response.statusText));
  }
}

function* getRefreshEapAttachmentList({ payload }) {
  try {
    let response = yield call(getEaplistRequest, payload);
    yield put(getRefreshEapAttachmentListSuccess(response.data));
  } catch (error) {
    yield put(getEAPByIdError(error.response.statusText));
  }
}

function* getEapDocumentList({ payload }) {
  try {
    let response = yield call(getEapDocumentsRequest, payload);
    yield put(getEapDocumentListSuccess(response.data));
  } catch (error) {
    yield put(getEAPByIdError(error.response.statusText));
  }
}

function* getEAPById({ payload }) {
  try {
    let response = yield call(getEAPByIdRequest, payload);
    yield put(getEAPByIdSuccess(response.data));
  } catch (error) {
    yield put(getEAPByIdError(error.response.statusText));
  }
}

const updateEAPRequest = async eapDetail => {
  return axios.put(`${Config.apiServerHost}/api/EAPDetail`, eapDetail, await authHeader());
};

const validateUpdateEAPRequest = async eapDetail => {
  return axios.post(
    `${Config.apiServerHost}/api/EAPDetail/validate`,
    eapDetail,
    await authHeader()
  );
};

const syncEAPRequest = async data => {
  return axios.post(
    `${Config.apiServerHost}/api/EAPDetail/sync?clientId=${data.clientId}&servicePlanHeaderId=${data.servicePlanHeaderId}&clientReferenceNumberOverride=${data.clientReferenceNumberOverride}`,
    '{}',
    await authHeader()
  );
};

function* updateEAP({ payload }) {
  try {
    let response = yield call(updateEAPRequest, payload);
    const { clientId, servicePlanHeaderId } = payload;

    response = yield call(getEaplistRequest, { clientId, servicePlanHeaderId });
    yield put(getEapListSuccess(response.data));

    // Call to refresh life sidebar and breadscrum
    response = yield call(getClientLeftSidebarRequest, { clientId });
    yield put(getClientLeftSidebarSuccess(response.data));

    response = yield call(getClientServicePlanRequest, { clientId, id: servicePlanHeaderId });
    yield put(getClientServicePlanSuccess(response.data));
  } catch (error) {
    yield put(updateEAPError(error?.response?.statusText));
  }
}

function* syncEAP({ payload }) {
  try {
    const { clientId, servicePlanHeaderId, clientReferenceNumberOverride } = payload;
    let response = yield call(syncEAPRequest, {
      clientId,
      servicePlanHeaderId,
      clientReferenceNumberOverride,
    });

    response = yield call(getEaplistRequest, { clientId, servicePlanHeaderId });
    yield put(getEapListSuccess(response.data));
    yield put(syncEAPSuccess(response.data));
    response = yield call(getClientServicePlanRequest, { clientId, id: servicePlanHeaderId });
    yield put(getClientServicePlanSuccess(response.data));

    // Call to refresh life sidebar and breadscrum
    response = yield call(getClientLeftSidebarRequest, { clientId });
    yield put(getClientLeftSidebarSuccess(response.data));
  } catch (error) {
    const message = {
      title: 'Sync EAP Detail',
      text: error.response?.data?.message ?? 'something went wrong.',
    };
    yield put(syncEAPError(message));
  }
}

function* savePlannedItemAttachment({ payload }) {
  try {
    const { clientId, servicePlanHeaderId, data } = payload;
    let response = yield call(savePlannedItemAttachmentRequest, data);
    response = yield call(getEaplistRequest, { clientId, servicePlanHeaderId });
    logger.debug(response.data);
    yield put(getEapListSuccess(response.data));
  } catch (error) {
    yield put(updateEAPError(error?.response?.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_REFRESH_EAP_LIST, getRefreshEapList);
  yield takeEvery(GET_REFRESH_EAP_ATTACHMENT_LIST, getRefreshEapAttachmentList);
  yield takeEvery(GET_EAP_LIST, getEapList);
  yield takeEvery(GET_EAP_DOCUMENT_LIST, getEapDocumentList);
  yield takeEvery(EAP_GET_BY_ID, getEAPById);
  yield takeEvery(EAP_UPDATE, updateEAP);
  yield takeEvery(EAP_SYNC, syncEAP);
  yield takeEvery(SAVE_EAP_PLAN_ITEM_ATTACHMENT, savePlannedItemAttachment);
  yield takeEvery(EAP_DETECT_DIFFERENCE, detectDifferenceEapDetail);
}

export {
  getSSMUserRequest,
  savePlannedItemAttachmentRequest,
  savePlannedItemAttachmentFromExistingRequest,
  getLiveEAPTransactions,
  getActiveFsApprovals,
  getActivePbfApprovals,
  detectDifferenceEapDetailRequest,
  updateEAPRequest,
  getCreateEapDefault,
  getEaplistRequest,
  deletePlannedItemAttachmentRequest,
  validateUpdateEAPRequest,
};
