import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  CLIENT_TSA_ADD_NEW,
  CLIENT_TSA_EDIT,
  CLIENT_TSA_GET_BY_QUERY,
  CLIENT_TSA_GET_BY_ID,
  CLIENT_TSA_DELETE,
} from './types';

import {
  addNewClientTSAError,
  editClientTSAError,
  getClientTSAByQuerySuccess,
  getClientTSAByQueryError,
  getClientTSAByIdError,
  getClientTSAByIdSuccess,
  deleteClientTSAByIdErrorr,
} from './actions';

const addNewClientTSAReqeust = async items => {
  return axios.post(`${Config.apiServerHost}/api/clientTSA`, items, await authHeader());
};

const editClientTSARequest = async items => {
  return axios.put(`${Config.apiServerHost}/api/clientTSA`, items, await authHeader());
};

const getClientTSAByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/ClientTSA/${id}`, await authHeader());
};

const getClientTSAByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/ClientTSA/fetch`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const deleteClientTSARequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/ClientTSA/${id}`, await authHeader());
};
function* addNewClientTSA({ payload }) {
  try {
    const { clientId, programId, servicePlanHeaderId, data } = payload;
    const query = {
      clientId,
      programId,
      servicePlanHeaderId,
    };

    let response = yield call(addNewClientTSAReqeust, data);
    response = yield call(getClientTSAByQueryRequest, query);
    yield put(getClientTSAByQuerySuccess(response.data));
  } catch (error) {
    yield put(addNewClientTSAError(error.response.statusText));
  }
}

function* editClientTSA({ payload }) {
  try {
    const { clientId, programId, servicePlanHeaderId, data } = payload;
    const query = {
      clientId,
      programId,
      servicePlanHeaderId,
    };

    let response = yield call(editClientTSARequest, data);
    response = yield call(getClientTSAByQueryRequest, query);
    yield put(getClientTSAByQuerySuccess(response.data));
  } catch (error) {
    yield put(editClientTSAError(error.response.statusText));
  }
}

function* getClientTSAByQuery({ payload }) {
  try {
    const response = yield call(getClientTSAByQueryRequest, payload);
    yield put(getClientTSAByQuerySuccess(response.data));
  } catch (error) {
    yield put(getClientTSAByQueryError(error.response.statusText));
  }
}

function* getClientTSAById({ payload }) {
  try {
    const response = yield call(getClientTSAByIdRequest, payload);
    yield put(getClientTSAByIdSuccess(response.data));
  } catch (error) {
    yield put(getClientTSAByIdError(error.response.statusText));
  }
}

function* deleteClientTSA({ payload }) {
  try {
    const { programId, clientId, servicePlanHeaderId, data } = payload;
    const query = {
      programId,
      clientId,
      servicePlanHeaderId,
    };

    let response = yield call(deleteClientTSARequest, data);
    response = yield call(getClientTSAByQueryRequest, query);
    yield put(getClientTSAByQuerySuccess(response.data));
  } catch (error) {
    yield put(deleteClientTSAByIdErrorr(error.response.statusText));
  }
}

export function* watchAddNewClientTSA() {
  yield takeEvery(CLIENT_TSA_ADD_NEW, addNewClientTSA);
}
export function* watchEditClientTSA() {
  yield takeEvery(CLIENT_TSA_EDIT, editClientTSA);
}

export function* watchGetClientTSAByQuery() {
  yield takeEvery(CLIENT_TSA_GET_BY_QUERY, getClientTSAByQuery);
}

export function* watchGetClientTSAById() {
  yield takeEvery(CLIENT_TSA_GET_BY_ID, getClientTSAById);
}

export function* watchDeleteClientTSA() {
  yield takeEvery(CLIENT_TSA_DELETE, deleteClientTSA);
}

export default function* rootSaga() {
  yield all([
    fork(watchAddNewClientTSA),
    fork(watchEditClientTSA),
    fork(watchGetClientTSAByQuery),
    fork(watchDeleteClientTSA),
    fork(watchGetClientTSAById),
  ]);
}
