import {
  OUTREACH_GET_BY_CLIENT_AND_PROGRAM,
  OUTREACH_GET_BY_CLIENT_AND_PROGRAM_SUCCESS,
  OUTREACH_SAVE,
  OUTREACH_ERROR,
} from './types';

export const getOutreachByClientAndProgram = params => ({
  type: OUTREACH_GET_BY_CLIENT_AND_PROGRAM,
  payload: params,
});

export const getOutreachByClientAndProgramSuccess = data => ({
  type: OUTREACH_GET_BY_CLIENT_AND_PROGRAM_SUCCESS,
  payload: data,
});

export const saveOutreach = data => ({
  type: OUTREACH_SAVE,
  payload: data,
});

export const outreachError = error => ({
  type: OUTREACH_ERROR,
  payload: error,
});
