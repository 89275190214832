import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  COJG_CASE_NOTE_DELETE,
  COJG_CASE_NOTE_EDIT,
  COJG_CASE_NOTE_GET_BY_ID,
  COJG_CASE_NOTE_ADD_NEW,
  COJG_CASE_NOTE_GET_LIST,
  COJG_CASE_NOTE_QUERY,
} from '../actions';

import {
  getCOJGCaseNoteListSuccess,
  getCOJGCaseNoteListError,
  editCOJGCaseNoteSuccess,
  editCOJGCaseNoteError,
  addNewCOJGCaseNoteSuccess,
  addNewCOJGCaseNoteError,
  deleteCOJGCaseNoteSuccess,
  deleteCOJGCaseNoteError,
  getCOJGCaseNoteByIdSuccess,
  getCOJGCaseNoteQuerySuccess,
  getCOJGCaseNoteQueryError,
} from './actions';

const getCOJGCaseNoteQueryRequest = async query => {
  const url = `${Config.apiServerHost}/api/employer/cojg-case-note-query?employerId=${query.employerId}&fromDate=${query.fromDate}&toDate=${query.toDate}`;
  return axios.get(url, await authHeader());
};

const getCOJGCaseNoteListRequest = async employerId => {
  return axios.get(
    `${Config.apiServerHost}/api/employer/cojg-case-note-by/${employerId}`,
    await authHeader()
  );
};

const addNewCOJGCaseNoteReqeust = async caseNote => {
  return axios.post(
    `${Config.apiServerHost}/api/employer/cojg-case-note`,
    caseNote,
    await authHeader()
  );
};

const editCOJGCaseNoteRequest = async caseNote => {
  return axios.put(
    `${Config.apiServerHost}/api/employer/cojg-case-note`,
    caseNote,
    await authHeader()
  );
};

const deleteCOJGCaseNoteRequest = async id => {
  return axios.delete(
    `${Config.apiServerHost}/api/employer/cojg-case-note/${id}`,
    await authHeader()
  );
};

const getCOJGCaseNoteByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/employer/cojg-case-note/${id}`, await authHeader());
};

function* getCOJGCaseNoteList({ payload }) {
  try {
    const response = yield call(getCOJGCaseNoteListRequest, payload);
    yield put(getCOJGCaseNoteListSuccess(response.data));
  } catch (error) {
    yield put(getCOJGCaseNoteListError(error.response.statusText));
  }
}

function* getCOJGCaseNoteQuery({ payload }) {
  try {
    const response = yield call(getCOJGCaseNoteQueryRequest, payload);
    yield put(getCOJGCaseNoteQuerySuccess(response.data));
  } catch (error) {
    yield put(getCOJGCaseNoteQueryError(error.response.statusText));
  }
}

function* addNewCOJGCaseNote({ payload }) {
  try {
    let response = yield call(addNewCOJGCaseNoteReqeust, payload);
    response = yield call(getCOJGCaseNoteByIdRequest, response.data);
    yield put(addNewCOJGCaseNoteSuccess(response.data));
  } catch (error) {
    yield put(addNewCOJGCaseNoteError(error.response.statusText));
  }
}

function* editCOJGCaseNote({ payload }) {
  try {
    let response = yield call(editCOJGCaseNoteRequest, payload);
    response = yield call(getCOJGCaseNoteByIdRequest, payload.id);
    yield put(editCOJGCaseNoteSuccess(response.data));
  } catch (error) {
    yield put(editCOJGCaseNoteError(error.response.statusText));
  }
}

function* getCOJGCaseNoteById({ payload }) {
  try {
    const response = yield call(getCOJGCaseNoteByIdRequest, payload);
    yield put(getCOJGCaseNoteByIdSuccess(response.data));
  } catch (error) {
    yield put(editCOJGCaseNoteError(error.response.statusText));
  }
}

function* deleteCOJGCaseNote({ payload }) {
  try {
    const response = yield call(deleteCOJGCaseNoteRequest, payload);
    yield put(deleteCOJGCaseNoteSuccess(response.data));
  } catch (error) {
    yield put(deleteCOJGCaseNoteError(error.response.statusText));
  }
}

export function* watchCOJGCaseNoteQuery() {
  yield takeEvery(COJG_CASE_NOTE_QUERY, getCOJGCaseNoteQuery);
}

export function* watchCOJGCaseNoteList() {
  yield takeEvery(COJG_CASE_NOTE_GET_LIST, getCOJGCaseNoteList);
}

export function* watchAddNewCOJGCaseNote() {
  yield takeEvery(COJG_CASE_NOTE_ADD_NEW, addNewCOJGCaseNote);
}

export function* watchCOJGCaseNoteById() {
  yield takeEvery(COJG_CASE_NOTE_GET_BY_ID, getCOJGCaseNoteById);
}

export function* watchEditCOJGCaseNote() {
  yield takeEvery(COJG_CASE_NOTE_EDIT, editCOJGCaseNote);
}

export function* watchDeleteCOJGCaseNote() {
  yield takeEvery(COJG_CASE_NOTE_DELETE, deleteCOJGCaseNote);
}

export default function* rootSaga() {
  yield all([
    fork(watchCOJGCaseNoteQuery),
    fork(watchCOJGCaseNoteList),
    fork(watchAddNewCOJGCaseNote),
    fork(watchCOJGCaseNoteById),
    fork(watchEditCOJGCaseNote),
    fork(watchDeleteCOJGCaseNote),
  ]);
}

export {
  getCOJGCaseNoteListRequest,
  deleteCOJGCaseNoteRequest,
  editCOJGCaseNoteRequest,
  getCOJGCaseNoteByIdRequest,
};
