import {
  RECONNECTING_USER,
  RECONNECTING_USER_DONE,
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  VERIFY_CODE,
  VERIFY_CODE_SUCCESS,
  VERIFY_CODE_ERROR,
  USER_CLOSE_ERROR_MESSAGE,
  UPDATE_USER_SETTING,
} from '../actions';
import { get } from 'lodash';

const INIT_STATE = {
  username: '',
  firstName: '',
  lastName: '',
  cognitoId: '',
  avatar: null,
  userData: [],
  sites: null,
  siteRoles: [],
  dashboardReports: null,
  role: 0,
  roleName: '',
  user: '', //aka cognito token
  userId: 0,
  forgotUserMail: '',
  newPassword: '',
  resetPasswordCode: '',
  loading: false,
  registerSuccessed: false,
  error: '',
  reconnecting: false,
  mfaCodeRequired: null,
  cognitoUser: null,
  caMSUserId: null,
  hasClientServices: false,
  backgroundServerBaseDomain: 'localhost',
  exportBaseDomain: 'localhost',
  client: null,
  setting: null,
};

const authFunction = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: '' };
    case LOGIN_USER_SUCCESS:
      const setting = action.payload.setting ? JSON.parse(action.payload.setting) : null;
      return {
        ...state,
        loading: false,
        caMSUserId: action.payload.caMSUserId,
        username: action.payload.username,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        isAdmin: action.payload.isAdmin,
        isCommunityPartner: action.payload.isCommunityPartner,
        cognitoId: action.payload.cognitoId,
        avatar: action.payload.avatar,
        userData: action.payload.permissions,
        role: action.payload.role,
        roleName: action.payload.roleName,
        user: action.payload.token,
        userId: action.payload.id,
        sites: action.payload.sites,
        siteRoles: action.payload.siteRoles,
        dashboardReports: action.payload.dashboardReports,
        employerDirectory: action.payload.employerDirectory,
        mfaCodeRequired: action.payload.mfaCodeRequired,
        cognitoUser: action.payload.cognitoUser,
        hasClientServices: action.payload.hasClientServices,
        backgroundServerBaseDomain: action.payload.backgroundServerBaseDomain,
        exportBaseDomain: action.payload.exportBaseDomain,
        client: action.payload.client,
        setting,
        error: '',
      };
    case UPDATE_USER_SETTING:
      return {
        ...state,
        setting: action.payload.setting,
      };
    case RECONNECTING_USER:
      return {
        ...state,
        reconnecting: true,
        mfaCodeRequired: null,
      };
    case RECONNECTING_USER_DONE:
      return {
        ...state,
        reconnecting: false,
        mfaCodeRequired: null,
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        ...INIT_STATE,
        user: '',
        mfaCodeRequired: null,
        cognitoUser: null,
        error:
          typeof action.payload === 'string'
            ? action.payload
            : get(
                action,
                'payload.response.data.message',
                get(action, 'payload.message', 'Unknown error')
              ),
      };
    case FORGOT_PASSWORD:
      return { ...state, loading: true, error: '' };
    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, loading: false, forgotUserMail: action.payload, error: '' };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotUserMail: '',
        error:
          typeof action.payload === 'string'
            ? action.payload
            : get(
                action,
                'payload.response.data.message',
                get(action, 'payload.message', 'Unknown error')
              ),
      };
    case RESET_PASSWORD:
      return { ...state, loading: true, error: '' };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        newPassword: action.payload,
        resetPasswordCode: '',
        error: '',
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        newPassword: '',
        resetPasswordCode: '',
        error:
          typeof action.payload === 'string'
            ? action.payload
            : get(
                action,
                'payload.response.data.message',
                get(action, 'payload.message', 'Unknown error')
              ),
      };
    case VERIFY_CODE:
      return {
        ...state,
        loading: true,
      };
    case VERIFY_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        userId: action.payload,
      };
    case VERIFY_CODE_ERROR:
      return {
        ...state,
        loading: false,
        error:
          typeof action.payload === 'string'
            ? action.payload
            : get(
                action,
                'payload.response.data.message',
                get(action, 'payload.message', 'Unknown error')
              ),
      };
    case REGISTER_USER:
      return {
        ...state,
        loading: true,
        error: '',
        registerSuccessed: false,
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.uid,
        error: '',
        registerSuccessed: true,
      };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: '',
        error:
          typeof action.payload === 'string'
            ? action.payload
            : get(
                action,
                'payload.response.data.message',
                get(action, 'payload.message', 'Unknown error')
              ),
        registerSuccessed: false,
      };
    case USER_CLOSE_ERROR_MESSAGE:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case LOGOUT_USER:
      return {
        ...state,
        user: null,
        error: '',
        cognitoUser: null,
        mfaCodeRequired: null,
        caMSUserId: null,
        siteRoles: [],
      };
    default:
      return { ...state };
  }
};

export default authFunction;
