import {
  STAKE_HOLDER_ADD_NEW,
  STAKE_HOLDER_ADD_NEW_ERROR,
  STAKE_HOLDER_ADD_NEW_SUCCESS,
  STAKE_HOLDER_CHANGE_STATUS,
  STAKE_HOLDER_CHANGE_STATUS_ERROR,
  STAKE_HOLDER_CHANGE_STATUS_SUCCESS,
  STAKE_HOLDER_DELETE,
  STAKE_HOLDER_DELETE_ERROR,
  STAKE_HOLDER_DELETE_SUCCESS,
  STAKE_HOLDER_GET_BY_ID,
  STAKE_HOLDER_GET_BY_ID_ERROR,
  STAKE_HOLDER_GET_BY_ID_SUCCESS,
  STAKE_HOLDER_GET_PAGING,
  STAKE_HOLDER_GET_PAGING_ERROR,
  STAKE_HOLDER_GET_PAGING_SUCCESS,
  STAKE_HOLDER_UPDATE,
  STAKE_HOLDER_UPDATE_ERROR,
  STAKE_HOLDER_UPDATE_SUCCESS,
} from './types';

// StakeHolder
export const getPagingStakeHolder = items => ({
  type: STAKE_HOLDER_GET_PAGING,
  payload: items,
});

export const getPagingStakeHolderSuccess = items => ({
  type: STAKE_HOLDER_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingStakeHolderError = error => ({
  type: STAKE_HOLDER_GET_PAGING_ERROR,
  payload: error,
});

export const addStakeHolder = items => ({
  type: STAKE_HOLDER_ADD_NEW,
  payload: items,
});

export const addStakeHolderSuccess = items => ({
  type: STAKE_HOLDER_ADD_NEW_SUCCESS,
  payload: items,
});

export const addStakeHolderError = error => ({
  type: STAKE_HOLDER_ADD_NEW_ERROR,
  payload: error,
});

export const getStakeHolderById = items => ({
  type: STAKE_HOLDER_GET_BY_ID,
  payload: items,
});

export const getStakeHolderByIdSuccess = items => ({
  type: STAKE_HOLDER_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getStakeHolderByIdError = error => ({
  type: STAKE_HOLDER_GET_BY_ID_ERROR,
  payload: error,
});

export const updateStakeHolder = items => ({
  type: STAKE_HOLDER_UPDATE,
  payload: items,
});

export const updateStakeHolderSuccess = items => ({
  type: STAKE_HOLDER_UPDATE_SUCCESS,
  payload: items,
});

export const updateStakeHolderError = error => ({
  type: STAKE_HOLDER_UPDATE_ERROR,
  payload: error,
});

export const changeStatusStakeHolder = items => ({
  type: STAKE_HOLDER_CHANGE_STATUS,
  payload: items,
});

export const changeStatusStakeHolderSuccess = items => ({
  type: STAKE_HOLDER_CHANGE_STATUS_SUCCESS,
  payload: items,
});

export const changeStatusStakeHolderError = error => ({
  type: STAKE_HOLDER_CHANGE_STATUS_ERROR,
  payload: error,
});

export const deleteStakeHolder = items => ({
  type: STAKE_HOLDER_DELETE,
  payload: items,
});

export const deleteStakeHolderSuccess = items => ({
  type: STAKE_HOLDER_DELETE_SUCCESS,
  payload: items,
});

export const deleteStakeHolderError = error => ({
  type: STAKE_HOLDER_DELETE_ERROR,
  payload: error,
});
