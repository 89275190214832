import {
  PROSPECT_RECEIVE_NOTIFICATION_GET_ALL,
  PROSPECT_RECEIVE_NOTIFICATION_GET_ALL_SUCCESS,
  PROSPECT_RECEIVE_NOTIFICATION_GET_ALL_ERROR,
  PROSPECT_RECEIVE_NOTIFICATION_GET_BY_ID,
  PROSPECT_RECEIVE_NOTIFICATION_GET_BY_ID_SUCCESS,
  PROSPECT_RECEIVE_NOTIFICATION_GET_BY_ID_ERROR,
  PROSPECT_RECEIVE_NOTIFICATION_ADD_NEW,
  PROSPECT_RECEIVE_NOTIFICATION_ADD_NEW_SUCCESS,
  PROSPECT_RECEIVE_NOTIFICATION_ADD_NEW_ERROR,
  PROSPECT_RECEIVE_NOTIFICATION_EDIT,
  PROSPECT_RECEIVE_NOTIFICATION_EDIT_SUCCESS,
  PROSPECT_RECEIVE_NOTIFICATION_EDIT_ERROR,
  PROSPECT_RECEIVE_NOTIFICATION_DELETE,
  PROSPECT_RECEIVE_NOTIFICATION_DELETE_SUCCESS,
  PROSPECT_RECEIVE_NOTIFICATION_DELETE_ERROR,
} from '../actions';

// ProspectReceiveNotification
export const getProspectReceiveNotifications = () => ({
  type: PROSPECT_RECEIVE_NOTIFICATION_GET_ALL,
});

export const getProspectReceiveNotificationsSuccess = items => ({
  type: PROSPECT_RECEIVE_NOTIFICATION_GET_ALL_SUCCESS,
  payload: items,
});

export const getProspectReceiveNotificationsError = error => ({
  type: PROSPECT_RECEIVE_NOTIFICATION_GET_ALL_ERROR,
  payload: error,
});

export const getProspectReceiveNotificationById = id => ({
  type: PROSPECT_RECEIVE_NOTIFICATION_GET_BY_ID,
  payload: id,
});

export const getProspectReceiveNotificationByIdSuccess = item => ({
  type: PROSPECT_RECEIVE_NOTIFICATION_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getProspectReceiveNotificationByIdError = error => ({
  type: PROSPECT_RECEIVE_NOTIFICATION_GET_BY_ID_ERROR,
  payload: error,
});

export const addNewProspectReceiveNotification = item => ({
  type: PROSPECT_RECEIVE_NOTIFICATION_ADD_NEW,
  payload: item,
});

export const addNewProspectReceiveNotificationSuccess = item => ({
  type: PROSPECT_RECEIVE_NOTIFICATION_ADD_NEW_SUCCESS,
  payload: item,
});

export const addNewProspectReceiveNotificationError = error => ({
  type: PROSPECT_RECEIVE_NOTIFICATION_ADD_NEW_ERROR,
  payload: error,
});

export const editProspectReceiveNotification = item => ({
  type: PROSPECT_RECEIVE_NOTIFICATION_EDIT,
  payload: item,
});

export const editProspectReceiveNotificationSuccess = item => ({
  type: PROSPECT_RECEIVE_NOTIFICATION_EDIT_SUCCESS,
  payload: item,
});

export const editProspectReceiveNotificationError = error => ({
  type: PROSPECT_RECEIVE_NOTIFICATION_EDIT_ERROR,
  payload: error,
});

export const deleteProspectReceiveNotification = id => ({
  type: PROSPECT_RECEIVE_NOTIFICATION_DELETE,
  payload: id,
});

export const deleteProspectReceiveNotificationSuccess = item => ({
  type: PROSPECT_RECEIVE_NOTIFICATION_DELETE_SUCCESS,
  payload: item,
});

export const deleteProspectReceiveNotificationError = error => ({
  type: PROSPECT_RECEIVE_NOTIFICATION_DELETE_ERROR,
  payload: error,
});
