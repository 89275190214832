import {
  FORM_DOCUMENT_ADD_NEW,
  FORM_DOCUMENT_ADD_NEW_SUCCESS,
  FORM_DOCUMENT_ADD_NEW_ERROR,
  FORM_DOCUMENT_DELETE,
  FORM_DOCUMENT_DELETE_ERROR,
  FORM_DOCUMENT_DELETE_SUCCESS,
  FORM_DOCUMENT_EDIT,
  FORM_DOCUMENT_EDIT_ERROR,
  FORM_DOCUMENT_EDIT_SUCCESS,
  FORM_DOCUMENT_GET_BY_ID,
  FORM_DOCUMENT_GET_BY_ID_ERROR,
  FORM_DOCUMENT_GET_BY_ID_SUCCESS,
  FORM_DOCUMENT_GET_LIST,
  FORM_DOCUMENT_GET_LIST_ERROR,
  FORM_DOCUMENT_GET_LIST_SUCCESS,
  FORM_DOCUMENT_ADD_NEW_REQUEST,
} from '../actions';

// FormDocument

export const getFormDocumentList = () => ({
  type: FORM_DOCUMENT_GET_LIST,
});

export const getFormDocumentListSuccess = items => ({
  type: FORM_DOCUMENT_GET_LIST_SUCCESS,
  payload: items,
});

export const getFormDocumentListError = error => ({
  type: FORM_DOCUMENT_GET_LIST_ERROR,
  payload: error,
});

export const addNewFormDocument = item => ({
  type: FORM_DOCUMENT_ADD_NEW,
  payload: item,
});

export const addNewFormDocumentRequest = () => ({
  type: FORM_DOCUMENT_ADD_NEW_REQUEST,
});

export const addNewFormDocumentSuccess = item => ({
  type: FORM_DOCUMENT_ADD_NEW_SUCCESS,
  payload: item,
});

export const addNewFormDocumentError = error => ({
  type: FORM_DOCUMENT_ADD_NEW_ERROR,
  payload: error,
});

export const editFormDocument = item => ({
  type: FORM_DOCUMENT_EDIT,
  payload: item,
});

export const editFormDocumentSuccess = item => ({
  type: FORM_DOCUMENT_EDIT_SUCCESS,
  payload: item,
});

export const editFormDocumentError = error => ({
  type: FORM_DOCUMENT_EDIT_ERROR,
  payload: error,
});

export const deleteFormDocument = id => ({
  type: FORM_DOCUMENT_DELETE,
  payload: id,
});

export const deleteFormDocumentSuccess = id => ({
  type: FORM_DOCUMENT_DELETE_SUCCESS,
  payload: id,
});

export const deleteFormDocumentError = error => ({
  type: FORM_DOCUMENT_DELETE_ERROR,
  payload: error,
});

export const getFormDocumentById = id => ({
  type: FORM_DOCUMENT_GET_BY_ID,
  payload: id,
});

export const getFormDocumentByIdSuccess = item => ({
  type: FORM_DOCUMENT_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getFormDocumentByIdError = error => ({
  type: FORM_DOCUMENT_GET_BY_ID_ERROR,
  payload: error,
});
