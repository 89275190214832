import {
  SERVICE_PLAN_ADD_NEW,
  SERVICE_PLAN_ADD_NEW_SUCCESS,
  SERVICE_PLAN_ADD_NEW_ERROR,
  SERVICE_PLAN_DELETE,
  SERVICE_PLAN_DELETE_ERROR,
  SERVICE_PLAN_DELETE_SUCCESS,
  SERVICE_PLAN_EDIT,
  SERVICE_PLAN_EDIT_ERROR,
  SERVICE_PLAN_EDIT_SUCCESS,
  SERVICE_PLAN_GET_BY_ID,
  SERVICE_PLAN_GET_BY_ID_ERROR,
  SERVICE_PLAN_GET_BY_ID_SUCCESS,
  SERVICE_PLAN_GET_LIST,
  SERVICE_PLAN_GET_LIST_ERROR,
  SERVICE_PLAN_GET_LIST_SUCCESS,
  SERVICE_PLAN_ADD_NEW_REQUEST,
  NOTIFICATION_GET_LIST_SUCCESS,
  SERVICE_PLAN_STATUS_CHANGE,
  SERVICE_PLAN_STATUS_CHANGE_SUCCESS,
  SERVICE_PLAN_STATUS_CHANGE_ERROR,
} from '../actions';

// ServicePlan
export const getNotificationListSuccess = notifications => ({
  type: NOTIFICATION_GET_LIST_SUCCESS,
  payload: notifications,
});

export const getServicePlanList = query => ({
  type: SERVICE_PLAN_GET_LIST,
  payload: query,
});

export const getServicePlanListSuccess = ServicePlans => ({
  type: SERVICE_PLAN_GET_LIST_SUCCESS,
  payload: ServicePlans,
});

export const getServicePlanListError = error => ({
  type: SERVICE_PLAN_GET_LIST_ERROR,
  payload: error,
});

export const addNewServicePlan = ServicePlan => ({
  type: SERVICE_PLAN_ADD_NEW,
  payload: ServicePlan,
});

export const addNewServicePlanRequest = () => ({
  type: SERVICE_PLAN_ADD_NEW_REQUEST,
});

export const addNewServicePlanSuccess = ServicePlan => ({
  type: SERVICE_PLAN_ADD_NEW_SUCCESS,
  payload: ServicePlan,
});

export const addNewServicePlanError = error => ({
  type: SERVICE_PLAN_ADD_NEW_ERROR,
  payload: error,
});

export const editServicePlan = ServicePlan => ({
  type: SERVICE_PLAN_EDIT,
  payload: ServicePlan,
});

export const editServicePlanSuccess = ServicePlan => ({
  type: SERVICE_PLAN_EDIT_SUCCESS,
  payload: ServicePlan,
});

export const editServicePlanError = error => ({
  type: SERVICE_PLAN_EDIT_ERROR,
  payload: error,
});

export const deleteServicePlan = id => ({
  type: SERVICE_PLAN_DELETE,
  payload: id,
});

export const deleteServicePlanSuccess = id => ({
  type: SERVICE_PLAN_DELETE_SUCCESS,
  payload: id,
});

export const deleteServicePlanError = error => ({
  type: SERVICE_PLAN_DELETE_ERROR,
  payload: error,
});

export const getServicePlanById = id => ({
  type: SERVICE_PLAN_GET_BY_ID,
  payload: id,
});

export const getServicePlanByIdSuccess = ServicePlan => ({
  type: SERVICE_PLAN_GET_BY_ID_SUCCESS,
  payload: ServicePlan,
});

export const getServicePlanByIdError = error => ({
  type: SERVICE_PLAN_GET_BY_ID_ERROR,
  payload: error,
});

export const changeServicePlanStatus = id => ({
  type: SERVICE_PLAN_STATUS_CHANGE,
  payload: id,
});

export const changeServicePlanStatusSuccess = ServicePlan => ({
  type: SERVICE_PLAN_STATUS_CHANGE_SUCCESS,
  payload: ServicePlan,
});

export const changeServicePlanStatusError = error => ({
  type: SERVICE_PLAN_STATUS_CHANGE_ERROR,
  payload: error,
});
