import {
  ACTION_PLAN_ADD_NEW,
  ACTION_PLAN_ADD_NEW_SUCCESS,
  ACTION_PLAN_ADD_NEW_ERROR,
  ACTION_PLAN_DELETE,
  ACTION_PLAN_DELETE_ERROR,
  ACTION_PLAN_DELETE_SUCCESS,
  ACTION_PLAN_EDIT,
  ACTION_PLAN_EDIT_ERROR,
  ACTION_PLAN_EDIT_SUCCESS,
  ACTION_PLAN_GET_BY_ID,
  ACTION_PLAN_GET_BY_ID_ERROR,
  ACTION_PLAN_GET_BY_ID_SUCCESS,
  ACTION_PLAN_GET_LIST,
  ACTION_PLAN_GET_LIST_ERROR,
  ACTION_PLAN_GET_LIST_SUCCESS,
  ACTION_PLAN_ADD_NEW_REQUEST,
  NOTIFICATION_GET_LIST_SUCCESS,
  ACTION_PLAN_STATUS_CHANGE,
  ACTION_PLAN_STATUS_CHANGE_SUCCESS,
  ACTION_PLAN_STATUS_CHANGE_ERROR,
} from '../actions';

export const getNotificationListSuccess = notifications => ({
  type: NOTIFICATION_GET_LIST_SUCCESS,
  payload: notifications,
});

export const getActionPlanList = query => ({
  type: ACTION_PLAN_GET_LIST,
  payload: query,
});

export const getActionPlanListSuccess = actionPlans => ({
  type: ACTION_PLAN_GET_LIST_SUCCESS,
  payload: actionPlans,
});

export const getActionPlanListError = error => ({
  type: ACTION_PLAN_GET_LIST_ERROR,
  payload: error,
});

export const addNewActionPlan = actionPlan => ({
  type: ACTION_PLAN_ADD_NEW,
  payload: actionPlan,
});

export const addNewActionPlanRequest = () => ({
  type: ACTION_PLAN_ADD_NEW_REQUEST,
});

export const addNewActionPlanSuccess = actionPlan => ({
  type: ACTION_PLAN_ADD_NEW_SUCCESS,
  payload: actionPlan,
});

export const addNewActionPlanError = error => ({
  type: ACTION_PLAN_ADD_NEW_ERROR,
  payload: error,
});

export const editActionPlan = actionPlan => ({
  type: ACTION_PLAN_EDIT,
  payload: actionPlan,
});

export const editActionPlanSuccess = actionPlan => ({
  type: ACTION_PLAN_EDIT_SUCCESS,
  payload: actionPlan,
});

export const editActionPlanError = error => ({
  type: ACTION_PLAN_EDIT_ERROR,
  payload: error,
});

export const deleteActionPlan = id => ({
  type: ACTION_PLAN_DELETE,
  payload: id,
});

export const deleteActionPlanSuccess = id => ({
  type: ACTION_PLAN_DELETE_SUCCESS,
  payload: id,
});

export const deleteActionPlanError = error => ({
  type: ACTION_PLAN_DELETE_ERROR,
  payload: error,
});

export const getActionPlanById = id => ({
  type: ACTION_PLAN_GET_BY_ID,
  payload: id,
});

export const getActionPlanByIdSuccess = actionPlan => ({
  type: ACTION_PLAN_GET_BY_ID_SUCCESS,
  payload: actionPlan,
});

export const getActionPlanByIdError = error => ({
  type: ACTION_PLAN_GET_BY_ID_ERROR,
  payload: error,
});

export const changeActionPlanStatus = id => ({
  type: ACTION_PLAN_STATUS_CHANGE,
  payload: id,
});

export const changeActionPlanStatusSuccess = actionPlan => ({
  type: ACTION_PLAN_STATUS_CHANGE_SUCCESS,
  payload: actionPlan,
});

export const changeActionPlanStatusError = error => ({
  type: ACTION_PLAN_STATUS_CHANGE_ERROR,
  payload: error,
});
