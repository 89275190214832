import {
  ALERT_ADD_NEW,
  ALERT_ADD_NEW_SUCCESS,
  ALERT_ADD_NEW_ERROR,
  ALERT_DELETE,
  ALERT_DELETE_ERROR,
  ALERT_DELETE_SUCCESS,
  ALERT_EDIT,
  ALERT_EDIT_ERROR,
  ALERT_EDIT_SUCCESS,
  ALERT_GET_BY_ID,
  ALERT_GET_BY_ID_ERROR,
  ALERT_GET_BY_ID_SUCCESS,
  ALERT_GET_LIST,
  ALERT_GET_LIST_ERROR,
  ALERT_GET_LIST_SUCCESS,
  ALERT_ADD_NEW_REQUEST,
  ALERT_STATUS_CHANGE,
  ALERT_STATUS_CHANGE_SUCCESS,
  ALERT_STATUS_CHANGE_ERROR,
} from '../actions';

// alert
export const getAlertList = query => ({
  type: ALERT_GET_LIST,
  payload: query,
});

export const getAlertListSuccess = alerts => ({
  type: ALERT_GET_LIST_SUCCESS,
  payload: alerts,
});

export const getAlertListError = error => ({
  type: ALERT_GET_LIST_ERROR,
  payload: error,
});

export const addNewAlert = alert => ({
  type: ALERT_ADD_NEW,
  payload: alert,
});

export const addNewAlertRequest = () => ({
  type: ALERT_ADD_NEW_REQUEST,
});

export const addNewAlertSuccess = alert => ({
  type: ALERT_ADD_NEW_SUCCESS,
  payload: alert,
});

export const addNewAlertError = error => ({
  type: ALERT_ADD_NEW_ERROR,
  payload: error,
});

export const editAlert = alert => ({
  type: ALERT_EDIT,
  payload: alert,
});

export const editAlertSuccess = alert => ({
  type: ALERT_EDIT_SUCCESS,
  payload: alert,
});

export const editAlertError = error => ({
  type: ALERT_EDIT_ERROR,
  payload: error,
});

export const deleteAlert = id => ({
  type: ALERT_DELETE,
  payload: id,
});

export const deleteAlertSuccess = id => ({
  type: ALERT_DELETE_SUCCESS,
  payload: id,
});

export const deleteAlertError = error => ({
  type: ALERT_DELETE_ERROR,
  payload: error,
});

export const getAlertById = id => ({
  type: ALERT_GET_BY_ID,
  payload: id,
});

export const getAlertByIdSuccess = alert => ({
  type: ALERT_GET_BY_ID_SUCCESS,
  payload: alert,
});

export const getAlertByIdError = error => ({
  type: ALERT_GET_BY_ID_ERROR,
  payload: error,
});

export const changeAlertStatus = id => ({
  type: ALERT_STATUS_CHANGE,
  payload: id,
});

export const changeAlertStatusSuccess = alert => ({
  type: ALERT_STATUS_CHANGE_SUCCESS,
  payload: alert,
});

export const changeAlertStatusError = error => ({
  type: ALERT_STATUS_CHANGE_ERROR,
  payload: error,
});
