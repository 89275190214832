import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  SURVEY_NOTIFICATION_GET_PAGING,
  SURVEY_NOTIFICATION_GET_PAGING_ERROR,
  SURVEY_NOTIFICATION_GET_PAGING_SUCCESS,
  SURVEY_NOTIFICATION_TURN_OFF,
} from './types';

import {
  getSurveyNotificationPaging,
  getSurveyNotificationPagingSuccess,
  getSurveyNotificationPagingError,
  tu,
  onOrOffNotificationError,
  onOrOffNotificationSuccess,
} from './actions';

const getSurveyNotificationSettingRequest = async params => {
  const url = `${Config.apiServerHost}/api/OutcomeCheckpoint/survey-notification-setting-paging`;
  return axios.post(url, params, await authHeader());
};

const turnOffSurveyNotificationSettingRequest = async payload => {
  const { caseManagerId, enabled } = payload;
  const url = `${Config.apiServerHost}/api/OutcomeCheckpoint/survey-notification-setting-turn-off/${caseManagerId}/${enabled}`;
  return axios.post(url, {}, await authHeader());
};

function* getSurveyNotificationSetting({ payload }) {
  try {
    const response = yield call(getSurveyNotificationSettingRequest, payload);
    yield put(getSurveyNotificationPagingSuccess(response.data));
  } catch (error) {
    yield put(getSurveyNotificationPagingError(error.response.statusText));
  }
}

function* turnOffSurveyNotificationSetting({ payload }) {
  try {
    const { queryParam, data } = payload;
    let response = yield call(turnOffSurveyNotificationSettingRequest, data);
    response = yield call(getSurveyNotificationSettingRequest, queryParam);
    yield put(getSurveyNotificationPagingSuccess(response.data));
  } catch (error) {
    yield put(onOrOffNotificationError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(SURVEY_NOTIFICATION_GET_PAGING, getSurveyNotificationSetting);
  yield takeEvery(SURVEY_NOTIFICATION_TURN_OFF, turnOffSurveyNotificationSetting);
}
