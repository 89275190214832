export const GET_USER_DOCUMENTS = 'GET_USER_DOCUMENTS';
export const GET_USER_DOCUMENTS_SUCCESS = 'GET_USER_DOCUMENTS_SUCCESS';
export const GET_USER_DOCUMENTS_ERROR = 'GET_USER_DOCUMENTS_ERROR';

export const GET_USER_DOCUMENTS_BY_ID = 'GET_USER_DOCUMENTS_BY_ID';
export const GET_USER_DOCUMENTS_BY_ID_SUCCESS = 'GET_USER_DOCUMENTS_BY_ID_SUCCESS';
export const GET_USER_DOCUMENTS_BY_ID_ERROR = 'GET_USER_DOCUMENTS_BY_ID_ERROR';

export const UPDATE_USER_DOCUMENTS = 'UPDATE_USER_DOCUMENTS';
export const UPDATE_USER_DOCUMENTS_SUCCESS = 'UPDATE_USER_DOCUMENTS_SUCCESS';
export const UPDATE_USER_DOCUMENTS_ERROR = 'UPDATE_USER_DOCUMENTS_ERROR';

export const SAVE_USER_DOCUMENTS = 'SAVE_USER_DOCUMENTS';
export const SAVE_USER_DOCUMENTS_SUCCESS = 'SAVE_USER_DOCUMENTS_SUCCESS';
export const SAVE_USER_DOCUMENTS_ERROR = 'SAVE_USER_DOCUMENTS_ERROR';

export const DELETE_USER_DOCUMENT_BY_ID = 'DELETE_USER_DOCUMENT_BY_ID';
export const DELETE_USER_DOCUMENT_BY_ID_SUCCESS = 'DELETE_USER_DOCUMENT_BY_ID_SUCCESS';
export const DELETE_USER_DOCUMENT_BY_ID_ERROR = 'DELETE_USER_DOCUMENT_BY_ID_ERROR';
