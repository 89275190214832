import {
  REFERRAL_ADD_NEW,
  REFERRAL_ADD_NEW_SUCCESS,
  REFERRAL_ADD_NEW_ERROR,
  REFERRAL_DELETE,
  REFERRAL_DELETE_ERROR,
  REFERRAL_DELETE_SUCCESS,
  REFERRAL_EDIT,
  REFERRAL_EDIT_ERROR,
  REFERRAL_EDIT_SUCCESS,
  REFERRAL_GET_BY_ID,
  REFERRAL_GET_BY_ID_ERROR,
  REFERRAL_GET_BY_ID_SUCCESS,
  REFERRAL_GET_LIST,
  REFERRAL_GET_LIST_ERROR,
  REFERRAL_GET_LIST_SUCCESS,
  REFERRAL_GET_LIST_BY_QUERY,
  REFERRAL_GET_LIST_BY_QUERY_SUCCESS,
  REFERRAL_GET_LIST_BY_QUERY_ERROR,
  REFERRAL_ADD_NEW_REQUEST,
} from '../actions';

/** Referral */

export const getReferralListByQuery = query => ({
  type: REFERRAL_GET_LIST_BY_QUERY,
  payload: query,
});

export const getReferralListByQuerySuccess = workshops => ({
  type: REFERRAL_GET_LIST_BY_QUERY_SUCCESS,
  payload: workshops,
});

export const getReferralListByQueryError = error => ({
  type: REFERRAL_GET_LIST_BY_QUERY_ERROR,
  payload: error,
});

export const getReferralList = () => ({
  type: REFERRAL_GET_LIST,
});

export const getReferralListSuccess = workshops => ({
  type: REFERRAL_GET_LIST_SUCCESS,
  payload: workshops,
});

export const getReferralListError = error => ({
  type: REFERRAL_GET_LIST_ERROR,
  payload: error,
});

export const addNewReferral = workshop => ({
  type: REFERRAL_ADD_NEW,
  payload: workshop,
});

export const addNewReferralRequest = () => ({
  type: REFERRAL_ADD_NEW_REQUEST,
});

export const addNewReferralSuccess = workshop => ({
  type: REFERRAL_ADD_NEW_SUCCESS,
  payload: workshop,
});

export const addNewReferralError = error => ({
  type: REFERRAL_ADD_NEW_ERROR,
  payload: error,
});

export const editReferral = workshop => ({
  type: REFERRAL_EDIT,
  payload: workshop,
});

export const editReferralSuccess = workshop => ({
  type: REFERRAL_EDIT_SUCCESS,
  payload: workshop,
});

export const editReferralError = error => ({
  type: REFERRAL_EDIT_ERROR,
  payload: error,
});

export const deleteReferral = id => ({
  type: REFERRAL_DELETE,
  payload: id,
});

export const deleteReferralSuccess = id => ({
  type: REFERRAL_DELETE_SUCCESS,
  payload: id,
});

export const deleteReferralError = error => ({
  type: REFERRAL_DELETE_ERROR,
  payload: error,
});

export const getReferralById = id => ({
  type: REFERRAL_GET_BY_ID,
  payload: id,
});

export const getReferralByIdSuccess = actionPlan => ({
  type: REFERRAL_GET_BY_ID_SUCCESS,
  payload: actionPlan,
});

export const getReferralByIdError = error => ({
  type: REFERRAL_GET_BY_ID_ERROR,
  payload: error,
});
