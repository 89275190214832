import {
  OUTCOME_CHECKPOINT_GET_BY_QUERY,
  OUTCOME_CHECKPOINT_GET_BY_QUERY_ERROR,
  OUTCOME_CHECKPOINT_GET_BY_QUERY_SUCCESS,
  OUTCOME_CHECKPOINT_UPDATE,
  OUTCOME_CHECKPOINT_UPDATE_SUCCESS,
  OUTCOME_CHECKPOINT_UPDATE_ERROR,
  OUTCOME_CHECKPOINT_CLEAR_NOTIFIED,
  OUTCOME_CHECKPOINT_SYNC,
  OUTCOME_CHECKPOINT_SYNC_SUCCESS,
  OUTCOME_CHECKPOINT_SYNC_ERROR,
  GET_REFRESH_OUTCOME_CHECKPOINT_GET_BY_QUERY,
  GET_REFRESH_OUTCOME_CHECKPOINT_GET_BY_QUERY_SUCCESS,
  OUTCOME_CHECKPOINT_DETECT_DIFFERENCE,
  OUTCOME_CHECKPOINT_DETECT_DIFFERENCE_SUCCESS,
  OUTCOME_CHECKPOINT_SINGLE_UPDATE,
  OUTCOME_CHECKPOINT_SINGLE_UPDATE_SUCCESS,
  OUTCOME_CHECKPOINT_SINGLE_UPDATE_ERROR,
  GET_REFRESH_OUTCOME_CHECKPOINT_ATTACHMENT_GET_BY_QUERY,
  GET_REFRESH_OUTCOME_CHECKPOINT_ATTACHMENT_GET_BY_QUERY_SUCCESS,
} from './types';

// OutcomeCheckpoint

export const detectDifferenceOutcomeCheckpoint = payload => ({
  type: OUTCOME_CHECKPOINT_DETECT_DIFFERENCE,
  payload,
});

export const detectDifferenceOutcomeCheckpointSuccess = clientInfo => ({
  type: OUTCOME_CHECKPOINT_DETECT_DIFFERENCE_SUCCESS,
  payload: clientInfo,
});

export const getOutcomeCheckpointByQuery = id => ({
  type: OUTCOME_CHECKPOINT_GET_BY_QUERY,
  payload: id,
});

export const getOutcomeCheckpointByQuerySuccess = clientInfo => ({
  type: OUTCOME_CHECKPOINT_GET_BY_QUERY_SUCCESS,
  payload: clientInfo,
});

export const getRefreshOutcomeCheckpointByQuery = id => ({
  type: GET_REFRESH_OUTCOME_CHECKPOINT_GET_BY_QUERY,
  payload: id,
});

export const getRefreshOutcomeCheckpointByQuerySuccess = clientInfo => ({
  type: GET_REFRESH_OUTCOME_CHECKPOINT_GET_BY_QUERY_SUCCESS,
  payload: clientInfo,
});

export const getRefreshOutcomeCheckpointAttachmentsByQuery = payload => ({
  type: GET_REFRESH_OUTCOME_CHECKPOINT_ATTACHMENT_GET_BY_QUERY,
  payload,
});

export const getRefreshOutcomeCheckpointAttachmentsByQuerySuccess = payload => ({
  type: GET_REFRESH_OUTCOME_CHECKPOINT_ATTACHMENT_GET_BY_QUERY_SUCCESS,
  payload,
});

export const getOutcomeCheckpointByQueryError = error => ({
  type: OUTCOME_CHECKPOINT_GET_BY_QUERY_ERROR,
  payload: error,
});

export const updateOutcomeCheckpoint = clientInfo => ({
  type: OUTCOME_CHECKPOINT_UPDATE,
  payload: clientInfo,
});

export const updateOutcomeCheckpointSuccess = clientInfo => ({
  type: OUTCOME_CHECKPOINT_UPDATE_SUCCESS,
  payload: clientInfo,
});

export const updateOutcomeCheckpointError = error => ({
  type: OUTCOME_CHECKPOINT_UPDATE_ERROR,
  payload: error,
});

export const updateSingleOutcomeCheckpoint = clientInfo => ({
  type: OUTCOME_CHECKPOINT_SINGLE_UPDATE,
  payload: clientInfo,
});

export const updateSingleOutcomeCheckpointSuccess = clientInfo => ({
  type: OUTCOME_CHECKPOINT_SINGLE_UPDATE_SUCCESS,
  payload: clientInfo,
});

export const updateSingleOutcomeCheckpointError = error => ({
  type: OUTCOME_CHECKPOINT_SINGLE_UPDATE_ERROR,
  payload: error,
});

export const syncOutcomeCheckpoint = (
  clientId,
  servicePlanHeaderId,
  clientReferenceNumberOverride
) => ({
  type: OUTCOME_CHECKPOINT_SYNC,
  payload: { clientId, servicePlanHeaderId, clientReferenceNumberOverride },
});

export const syncOutcomeCheckpointSuccess = clientInfo => ({
  type: OUTCOME_CHECKPOINT_SYNC_SUCCESS,
  payload: clientInfo,
});

export const syncOutcomeCheckpointError = error => ({
  type: OUTCOME_CHECKPOINT_SYNC_ERROR,
  payload: error,
});

export const clearOutcomeCheckpointNotify = () => ({
  type: OUTCOME_CHECKPOINT_CLEAR_NOTIFIED,
});
