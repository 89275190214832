import {
  COJG_FINANCIAL_GET_LIST,
  COJG_FINANCIAL_GET_LIST_SUCCESS,
  COJG_FINANCIAL_GET_LIST_ERROR,
  COJG_FINANCIAL_GET_LIST_WITH_FILTER,
  COJG_FINANCIAL_GET_LIST_WITH_ORDER,
  COJG_FINANCIAL_GET_LIST_SEARCH,
} from '../../actions';

export const getCOJGFinancialList = filter => ({
  type: COJG_FINANCIAL_GET_LIST,
  payload: filter,
});

export const getCOJGFinancialListSuccess = items => ({
  type: COJG_FINANCIAL_GET_LIST_SUCCESS,
  payload: items,
});

export const getCOJGFinancialListError = error => ({
  type: COJG_FINANCIAL_GET_LIST_ERROR,
  payload: error,
});

export const getCOJGFinancialListWithFilter = (column, value) => ({
  type: COJG_FINANCIAL_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getCOJGFinancialListWithOrder = column => ({
  type: COJG_FINANCIAL_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getCOJGFinancialListSearch = keyword => ({
  type: COJG_FINANCIAL_GET_LIST_SEARCH,
  payload: keyword,
});
