import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../../helpers/auth-header';
import { buildUrl } from '../../../helpers/Utils';
import axios from 'axios';
import Config from '../../../config';

import {
  COJG_CONTRACT_GET_LIST,
  COJG_CONTRACT_ADD_ITEM,
  COJG_CONTRACT_EDIT_ITEM,
  COJG_CONTRACT_DELETE_ITEM,
  COJG_CONTRACT_GET_BY_ID,
  COJG_CONTRACT_GET_BY_EMPLOYER,
} from '../../actions';

import {
  getCOJGContractListSuccess,
  getCOJGContractListError,
  addCOJGContractItemSuccess,
  addCOJGContractItemError,
  editCOJGContractItemError,
  editCOJGContractItemSuccess,
  getCOJGContractByIdSuccess,
  getCOJGContractByIdError,
  getCOJGContractByEmployerSuccess,
  getCOJGContractByEmployerError,
  deleteCOJGContractItemSuccess,
  deleteCOJGContractItemError,
} from './actions';

const getCOJGContractByEmployerRequest = async query => {
  let url = `${Config.apiServerHost}/api/COJGContract/getByEmployer`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getCOJGContractsRequest = async query => {
  let url = `${Config.apiServerHost}/api/COJGContract`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getCOJGContractListRequest = async query => {
  let url = `${Config.apiServerHost}/api/COJGContract/search`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const poshCOJGContractListRequest = async event => {
  return await axios.post(`${Config.apiServerHost}/api/COJGContract`, event, await authHeader());
};

const deleteCOJGContractListRequest = async id => {
  return await axios.delete(`${Config.apiServerHost}/api/COJGContract/${id}`, await authHeader());
};

const addCOJGContractItemRequest = async event => {
  return await axios.post(`${Config.apiServerHost}/api/COJGContract`, event, await authHeader());
};

const getCOJGContractByIdRequest = async id => {
  return await axios.get(`${Config.apiServerHost}/api/COJGContract/${id}`, await authHeader());
};

function* getCOJGContractByEmployer({ payload }) {
  try {
    const response = yield call(getCOJGContractByEmployerRequest, payload);
    yield put(getCOJGContractByEmployerSuccess(response.data));
  } catch (error) {
    yield put(getCOJGContractByEmployerError(error));
  }
}

function* getCOJGContractListItems({ payload }) {
  try {
    const response = yield call(getCOJGContractListRequest, payload);
    yield put(getCOJGContractListSuccess(response.data));
  } catch (error) {
    yield put(getCOJGContractListError(error));
  }
}

function* editCOJGContractItem({ payload }) {
  try {
    const { item, history } = payload;
    let response = yield call(poshCOJGContractListRequest, item);
    response = yield call(getCOJGContractByIdRequest, response.data);
    yield put(editCOJGContractItemSuccess(response.data));
    history.push('/app/contract/cojg');
  } catch (error) {
    yield put(editCOJGContractItemError(error));
  }
}

function* deleteCOJGContractItem({ payload }) {
  try {
    let response = yield call(deleteCOJGContractListRequest, payload);
    yield put(deleteCOJGContractItemSuccess(response.data));
  } catch (error) {
    yield put(deleteCOJGContractItemError(error));
  }
}

function* addCOJGContractItem({ payload }) {
  try {
    const { event } = payload;
    const response = yield call(addCOJGContractItemRequest, event);
    yield put(addCOJGContractItemSuccess(response));
  } catch (error) {
    yield put(addCOJGContractItemError(error));
  }
}

function* getCOJGContractById({ payload }) {
  try {
    const response = yield call(getCOJGContractByIdRequest, payload);
    yield put(getCOJGContractByIdSuccess(response.data));
  } catch (error) {
    yield put(getCOJGContractByIdError(error));
  }
}

export function* watchtCOJGContractByEmployer() {
  yield takeEvery(COJG_CONTRACT_GET_BY_EMPLOYER, getCOJGContractByEmployer);
}

export function* watchCOJGContractById() {
  yield takeEvery(COJG_CONTRACT_GET_BY_ID, getCOJGContractById);
}

export function* watchGetList() {
  yield takeEvery(COJG_CONTRACT_GET_LIST, getCOJGContractListItems);
}

export function* wathcAddItem() {
  yield takeEvery(COJG_CONTRACT_ADD_ITEM, addCOJGContractItem);
}
export function* wathcEditItem() {
  yield takeEvery(COJG_CONTRACT_EDIT_ITEM, editCOJGContractItem);
}
export function* wathcDeleteItem() {
  yield takeEvery(COJG_CONTRACT_DELETE_ITEM, deleteCOJGContractItem);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(wathcAddItem),
    fork(wathcEditItem),
    fork(wathcDeleteItem),
    fork(watchCOJGContractById),
    fork(watchtCOJGContractByEmployer),
  ]);
}

export { getCOJGContractsRequest };
