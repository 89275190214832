import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  ES_CASE_NOTE_DELETE,
  ES_CASE_NOTE_EDIT,
  ES_CASE_NOTE_GET_BY_ID,
  ES_CASE_NOTE_ADD_NEW,
  ES_CASE_NOTE_GET_LIST,
  ES_CASE_NOTE_QUERY,
} from '../actions';
import Config from '../../config';

import {
  getESCaseNoteListSuccess,
  getESCaseNoteListError,
  editESCaseNoteSuccess,
  editESCaseNoteError,
  addNewESCaseNoteSuccess,
  addNewESCaseNoteError,
  deleteESCaseNoteSuccess,
  deleteESCaseNoteError,
  getESCaseNoteByIdSuccess,
  getESCaseNoteQuerySuccess,
  getESCaseNoteQueryError,
} from './actions';

const getESCaseNoteQueryRequest = async query => {
  const url = `${Config.apiServerHost}/api/escasenote/es-case-note-query?esContractId=${query.esContractId}&fromDate=${query.fromDate}&toDate=${query.toDate}`;
  return axios.get(url, await authHeader());
};

const getESCaseNoteListRequest = async esContractId => {
  return axios.get(
    `${Config.apiServerHost}/api/escasenote/es-contract-id/${esContractId}`,
    await authHeader()
  );
};

const addNewESCaseNoteReqeust = async caseNote => {
  return axios.post(`${Config.apiServerHost}/api/escasenote`, caseNote, await authHeader());
};

const editESCaseNoteRequest = async caseNote => {
  return axios.put(`${Config.apiServerHost}/api/escasenote`, caseNote, await authHeader());
};

const deleteESCaseNoteRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/escasenote/${id}`, await authHeader());
};

const getESCaseNoteByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/escasenote/${id}`, await authHeader());
};

function* getESCaseNoteList({ payload }) {
  try {
    const response = yield call(getESCaseNoteListRequest, payload);
    yield put(getESCaseNoteListSuccess(response.data));
  } catch (error) {
    yield put(getESCaseNoteListError(error.response.statusText));
  }
}

function* getESCaseNoteQuery({ payload }) {
  try {
    const response = yield call(getESCaseNoteQueryRequest, payload);
    yield put(getESCaseNoteQuerySuccess(response.data));
  } catch (error) {
    yield put(getESCaseNoteQueryError(error.response.statusText));
  }
}

function* addNewESCaseNote({ payload }) {
  try {
    let response = yield call(addNewESCaseNoteReqeust, payload);
    response = yield call(getESCaseNoteByIdRequest, response.data);
    yield put(addNewESCaseNoteSuccess(response.data));
  } catch (error) {
    yield put(addNewESCaseNoteError(error.response.statusText));
  }
}

function* editESCaseNote({ payload }) {
  try {
    let response = yield call(editESCaseNoteRequest, payload);
    response = yield call(getESCaseNoteByIdRequest, payload.id);
    yield put(editESCaseNoteSuccess(response.data));
  } catch (error) {
    yield put(editESCaseNoteError(error.response.statusText));
  }
}

function* getESCaseNoteById({ payload }) {
  try {
    const response = yield call(getESCaseNoteByIdRequest, payload);
    yield put(getESCaseNoteByIdSuccess(response.data));
  } catch (error) {
    yield put(editESCaseNoteError(error.response.statusText));
  }
}

function* deleteESCaseNote({ payload }) {
  try {
    const response = yield call(deleteESCaseNoteRequest, payload);
    yield put(deleteESCaseNoteSuccess(response.data));
  } catch (error) {
    yield put(deleteESCaseNoteError(error.response.statusText));
  }
}

export function* watchESCaseNoteQuery() {
  yield takeEvery(ES_CASE_NOTE_QUERY, getESCaseNoteQuery);
}

export function* watchESCaseNoteList() {
  yield takeEvery(ES_CASE_NOTE_GET_LIST, getESCaseNoteList);
}

export function* watchAddNewESCaseNote() {
  yield takeEvery(ES_CASE_NOTE_ADD_NEW, addNewESCaseNote);
}

export function* watchESCaseNoteById() {
  yield takeEvery(ES_CASE_NOTE_GET_BY_ID, getESCaseNoteById);
}

export function* watchEditESCaseNote() {
  yield takeEvery(ES_CASE_NOTE_EDIT, editESCaseNote);
}

export function* watchDeleteESCaseNote() {
  yield takeEvery(ES_CASE_NOTE_DELETE, deleteESCaseNote);
}

export default function* rootSaga() {
  yield all([
    fork(watchESCaseNoteQuery),
    fork(watchESCaseNoteList),
    fork(watchAddNewESCaseNote),
    fork(watchESCaseNoteById),
    fork(watchEditESCaseNote),
    fork(watchDeleteESCaseNote),
  ]);
}
