import {
  GET_IES_INTERVENTION,
  GET_IES_INTERVENTION_SUCCESS,
  GET_IES_INTERVENTION_ERROR,
} from './types';

export const getIESIntervention = query => ({
  type: GET_IES_INTERVENTION,
  payload: query,
});

export const getIESInterventionSuccess = item => ({
  type: GET_IES_INTERVENTION_SUCCESS,
  payload: item,
});

export const getIESInterventionError = item => ({
  type: GET_IES_INTERVENTION_ERROR,
  payload: item,
});
