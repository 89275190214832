import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  CONTACT_DELETE,
  CONTACT_EDIT,
  CONTACT_GET_BY_ID,
  CONTACT_ADD_NEW,
  CONTACT_GET_LIST,
  CONTACT_QUERY,
} from '../actions';

import {
  getContactListSuccess,
  getContactListError,
  editContactSuccess,
  editContactError,
  addNewContactSuccess,
  addNewContactError,
  deleteContactSuccess,
  deleteContactError,
  getContactByIdSuccess,
  getContactQuerySuccess,
  getContactQueryError,
} from './actions';

const getContactQueryRequest = async query => {
  const url = `${Config.apiServerHost}/api/employer/cojg-case-note-query?employerId=${query.employerId}&fromDate=${query.fromDate}&toDate=${query.toDate}`;
  return axios.get(url, await authHeader());
};

const getContactListRequest = async employerId => {
  return axios.get(
    `${Config.apiServerHost}/api/employer/ContactsByEmployerId/${employerId}`,
    await authHeader()
  );
};

const addNewContactReqeust = async caseNote => {
  return axios.post(`${Config.apiServerHost}/api/employer/Contact`, caseNote, await authHeader());
};

const editContactRequest = async caseNote => {
  return axios.put(`${Config.apiServerHost}/api/employer/Contact`, caseNote, await authHeader());
};

const deleteContactRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/employer/Contact/${id}`, await authHeader());
};

const getContactByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/employer/Contact/${id}`, await authHeader());
};

function* getContactList({ payload }) {
  try {
    const response = yield call(getContactListRequest, payload);
    yield put(getContactListSuccess(response.data));
  } catch (error) {
    yield put(getContactListError(error.response.statusText));
  }
}

function* getContactQuery({ payload }) {
  try {
    const response = yield call(getContactQueryRequest, payload);
    yield put(getContactQuerySuccess(response.data));
  } catch (error) {
    yield put(getContactQueryError(error.response.statusText));
  }
}

function* addNewContact({ payload }) {
  try {
    let response = yield call(addNewContactReqeust, payload);
    response = yield call(getContactByIdRequest, response.data);
    yield put(addNewContactSuccess(response.data));
  } catch (error) {
    yield put(addNewContactError(error.response.statusText));
  }
}

function* editContact({ payload }) {
  try {
    let response = yield call(editContactRequest, payload);
    response = yield call(getContactByIdRequest, payload.id);
    yield put(editContactSuccess(response.data));
  } catch (error) {
    yield put(editContactError(error.response.statusText));
  }
}

function* getContactById({ payload }) {
  try {
    const response = yield call(getContactByIdRequest, payload);
    yield put(getContactByIdSuccess(response.data));
  } catch (error) {
    yield put(editContactError(error.response.statusText));
  }
}

function* deleteContact({ payload }) {
  try {
    const response = yield call(deleteContactRequest, payload);
    yield put(deleteContactSuccess(response.data));
  } catch (error) {
    yield put(deleteContactError(error.response.statusText));
  }
}

export function* watchContactQuery() {
  yield takeEvery(CONTACT_QUERY, getContactQuery);
}

export function* watchContactList() {
  yield takeEvery(CONTACT_GET_LIST, getContactList);
}

export function* watchAddNewContact() {
  yield takeEvery(CONTACT_ADD_NEW, addNewContact);
}

export function* watchContactById() {
  yield takeEvery(CONTACT_GET_BY_ID, getContactById);
}

export function* watchEditContact() {
  yield takeEvery(CONTACT_EDIT, editContact);
}

export function* watchDeleteContact() {
  yield takeEvery(CONTACT_DELETE, deleteContact);
}

export default function* rootSaga() {
  yield all([
    fork(watchContactQuery),
    fork(watchContactList),
    fork(watchAddNewContact),
    fork(watchContactById),
    fork(watchEditContact),
    fork(watchDeleteContact),
  ]);
}
