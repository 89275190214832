import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import FileSaver from 'file-saver';

import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  BUDGET_TRACKING_GET_LIST,
  BUDGET_TRACKING_ADD_CLIENT_SUPPORT,
  BUDGET_TRACKING_EDIT_CLIENT_SUPPORT,
  BUDGET_TRACKING_GET_CLIENT_SUPPORT_BY_ID,
  BUDGET_TRACKING_DELETE_CLIENT_SUPPORT,
  BUDGET_TRACKING_ADD_PLACEMENT,
  BUDGET_TRACKING_EDIT_PLACEMENT,
  BUDGET_TRACKING_GET_PLACEMENT_BY_ID,
  BUDGET_TRACKING_DELETE_PLACEMENT,
} from './types';

import {
  getBudgetTrackingListSuccess,
  getBudgetTrackingListError,
  getBudgetTrackingClientSupportByIdSuccess,
  getBudgetTrackingPlacementByIdSuccess,
} from './actions';
import {
  addNewClientSupportError,
  deleteClientSupportError,
  editClientSupportError,
} from '../client-support/actions';
import {
  addNewClientSupportReqeust,
  deleteClientSupportRequest,
  editClientSupportRequest,
  getClientSupportByIdRequest,
} from '../client-support/saga';
import {
  addESContractItemError,
  deleteESContractItemError,
  editESContractItemError,
  getESContractByIdError,
} from '../es-contract/actions';
import {
  addNewESContractRequest,
  deleteESContractRequest,
  editESContractRequest,
  getESContractRequest,
} from '../es-contract/saga';
import { hasClientServices } from '../../helpers/Utils';

const exportBudgetTrackingListRequest = async (query, fileName) => {
  return axios
    .post(
      `${Config.apiServiceHostForExport}/api/ClientSupport/budgetTrackingExportToExcel`,
      query,
      {
        headers: { ...(await authHeader()).headers },
        responseType: 'blob',
      }
    )
    .then(res => {
      if (res) {
        const { data } = res;
        FileSaver.saveAs(new Blob([data]), fileName);
      }
    });
};

const getBudgetTrackingListRequest = async query => {
  return axios.post(
    `${Config.apiServerHost}/api/ClientSupport/budgetTracking`,
    query,
    await authHeader()
  );
};

function* getBudgetTrackingList({ payload }) {
  try {
    const response = yield call(getBudgetTrackingListRequest, payload);
    yield put(getBudgetTrackingListSuccess(response.data));
  } catch (error) {
    yield put(getBudgetTrackingListError(error.response.statusText));
  }
}

function* addNewClientSupport({ payload }) {
  try {
    const { data, queryParams } = payload;
    yield call(addNewClientSupportReqeust, data);

    let response = yield call(getBudgetTrackingListRequest, queryParams);

    yield put(getBudgetTrackingListSuccess(response.data));
  } catch (error) {
    yield put(addNewClientSupportError(error.response.statusText));
  }
}

function* editClientSupport({ payload }) {
  try {
    const { data, queryParams } = payload;

    yield call(editClientSupportRequest, data);

    let response = yield call(getBudgetTrackingListRequest, queryParams);

    yield put(getBudgetTrackingListSuccess(response.data));
  } catch (error) {
    yield put(editClientSupportError(error.response.statusText));
  }
}

function* deleteClientSupport({ payload }) {
  try {
    const { data, queryParams } = payload;

    yield call(deleteClientSupportRequest, data);
    let response = yield call(getBudgetTrackingListRequest, queryParams);

    yield put(getBudgetTrackingListSuccess(response.data));
  } catch (error) {
    yield put(deleteClientSupportError(error.response.statusText));
  }
}

function* getClientSupportById({ payload }) {
  try {
    const response = yield call(getClientSupportByIdRequest, payload);
    yield put(getBudgetTrackingClientSupportByIdSuccess(response.data));
  } catch (error) {
    yield put(editClientSupportError(error.response.statusText));
  }
}

function* addNewESContract({ payload }) {
  try {
    const { data, queryParams } = payload;

    yield call(addNewESContractRequest, data);
    let response = yield call(getBudgetTrackingListRequest, queryParams);

    yield put(getBudgetTrackingListSuccess(response.data));
  } catch (error) {
    yield put(addESContractItemError(error));
  }
}

function* editESContract({ payload }) {
  try {
    const { data, queryParams } = payload;
    yield call(editESContractRequest, data);

    let response = yield call(getBudgetTrackingListRequest, queryParams);

    yield put(getBudgetTrackingListSuccess(response.data));
  } catch (error) {
    yield put(editESContractItemError(error));
  }
}

function* getESContractById({ payload }) {
  try {
    const response = yield call(getESContractRequest, payload);
    yield put(getBudgetTrackingPlacementByIdSuccess(response.data));
  } catch (error) {
    yield put(getESContractByIdError(error));
  }
}

function* deleteESContract({ payload }) {
  try {
    const { data, queryParams } = payload;

    yield call(deleteESContractRequest, data);
    let response = yield call(getBudgetTrackingListRequest, queryParams);

    yield put(getBudgetTrackingListSuccess(response.data));
  } catch (error) {
    yield put(deleteESContractItemError(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(BUDGET_TRACKING_GET_LIST, getBudgetTrackingList);
  yield takeEvery(BUDGET_TRACKING_ADD_CLIENT_SUPPORT, addNewClientSupport);
  yield takeEvery(BUDGET_TRACKING_EDIT_CLIENT_SUPPORT, editClientSupport);
  yield takeEvery(BUDGET_TRACKING_GET_CLIENT_SUPPORT_BY_ID, getClientSupportById);
  yield takeEvery(BUDGET_TRACKING_DELETE_CLIENT_SUPPORT, deleteClientSupport);

  yield takeEvery(BUDGET_TRACKING_ADD_PLACEMENT, addNewESContract);
  yield takeEvery(BUDGET_TRACKING_EDIT_PLACEMENT, editESContract);
  yield takeEvery(BUDGET_TRACKING_GET_PLACEMENT_BY_ID, getESContractById);
  yield takeEvery(BUDGET_TRACKING_DELETE_PLACEMENT, deleteESContract);
}

export { getBudgetTrackingListRequest, exportBudgetTrackingListRequest };
