import {
  EVENT_PARTICIPANT_ADD_NEW,
  EVENT_PARTICIPANT_ADD_NEW_SUCCESS,
  EVENT_PARTICIPANT_ADD_NEW_ERROR,
  EVENT_PARTICIPANT_DELETE,
  EVENT_PARTICIPANT_DELETE_ERROR,
  EVENT_PARTICIPANT_DELETE_SUCCESS,
  EVENT_PARTICIPANT_EDIT,
  EVENT_PARTICIPANT_EDIT_ERROR,
  EVENT_PARTICIPANT_EDIT_SUCCESS,
  EVENT_PARTICIPANT_GET_BY_ID,
  EVENT_PARTICIPANT_GET_BY_ID_ERROR,
  EVENT_PARTICIPANT_GET_BY_ID_SUCCESS,
  EVENT_PARTICIPANT_GET_LIST,
  EVENT_PARTICIPANT_GET_LIST_ERROR,
  EVENT_PARTICIPANT_GET_LIST_SUCCESS,
  EVENT_PARTICIPANT_ADD_NEW_REQUEST,
} from '../actions';
// EventParticipant items
export const getEventParticipantList = query => ({
  type: EVENT_PARTICIPANT_GET_LIST,
  payload: query,
});

export const getEventParticipantListSuccess = items => ({
  type: EVENT_PARTICIPANT_GET_LIST_SUCCESS,
  payload: items,
});

export const getEventParticipantListError = error => ({
  type: EVENT_PARTICIPANT_GET_LIST_ERROR,
  payload: error,
});

export const addNewEventParticipant = item => ({
  type: EVENT_PARTICIPANT_ADD_NEW,
  payload: item,
});

export const addNewEventParticipantRequest = () => ({
  type: EVENT_PARTICIPANT_ADD_NEW_REQUEST,
});

export const addNewEventParticipantSuccess = item => ({
  type: EVENT_PARTICIPANT_ADD_NEW_SUCCESS,
  payload: item,
});

export const addNewEventParticipantError = error => ({
  type: EVENT_PARTICIPANT_ADD_NEW_ERROR,
  payload: error,
});

export const editEventParticipant = item => ({
  type: EVENT_PARTICIPANT_EDIT,
  payload: item,
});

export const editEventParticipantSuccess = item => ({
  type: EVENT_PARTICIPANT_EDIT_SUCCESS,
  payload: item,
});

export const editEventParticipantError = error => ({
  type: EVENT_PARTICIPANT_EDIT_ERROR,
  payload: error,
});

export const deleteEventParticipant = id => ({
  type: EVENT_PARTICIPANT_DELETE,
  payload: id,
});

export const deleteEventParticipantSuccess = id => ({
  type: EVENT_PARTICIPANT_DELETE_SUCCESS,
  payload: id,
});

export const deleteEventParticipantError = error => ({
  type: EVENT_PARTICIPANT_DELETE_ERROR,
  payload: error,
});

export const getEventParticipantById = id => ({
  type: EVENT_PARTICIPANT_GET_BY_ID,
  payload: id,
});

export const getEventParticipantByIdSuccess = item => ({
  type: EVENT_PARTICIPANT_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getEventParticipantByIdError = error => ({
  type: EVENT_PARTICIPANT_GET_BY_ID_ERROR,
  payload: error,
});
