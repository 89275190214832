import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SYSTEM_SETTING_GET_BY_ID, SYSTEM_SETTING_SAVE } from '../actions';

import {
  getSettingByIdSuccess,
  getSettingByIdError,
  saveSettingSuccess,
  saveSettingError,
} from './actions';

const getSettingByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/settings/${id}`, await authHeader());
};

const saveSettingRequest = async setting => {
  return axios.post(`${Config.apiServerHost}/api/settings/`, setting, await authHeader());
};

function* getSettingById({ payload }) {
  try {
    const response = yield call(getSettingByIdRequest, payload);
    yield put(getSettingByIdSuccess(response.data));
  } catch (error) {
    yield put(getSettingByIdError(error.response.statusText));
  }
}

function* saveSetting({ payload }) {
  try {
    let response = yield call(saveSettingRequest, payload);
    response = yield call(getSettingByIdRequest, response.data);
    yield put(saveSettingSuccess(response.data));
  } catch (error) {
    yield put(saveSettingError(error.response.statusText));
  }
}

export function* watchSettingById() {
  yield takeEvery(SYSTEM_SETTING_GET_BY_ID, getSettingById);
}

export function* watchSaveingSetting() {
  yield takeEvery(SYSTEM_SETTING_SAVE, saveSetting);
}

export default function* rootSaga() {
  yield all([fork(watchSettingById), fork(watchSaveingSetting)]);
}
