import {
  APPLICANT_ADD_NEW,
  APPLICANT_ADD_NEW_SUCCESS,
  APPLICANT_ADD_NEW_ERROR,
  APPLICANT_EDIT,
  APPLICANT_EDIT_ERROR,
  APPLICANT_EDIT_SUCCESS,
  APPLICANT_GET_BY_QUERY,
  APPLICANT_GET_BY_ID,
  APPLICANT_GET_BY_QUERY_SUCCESS,
  APPLICANT_GET_BY_QUERY_ERROR,
  APPLICANT_GET_BY_ID_SUCCESS,
  APPLICANT_ADD_NEW_REQUEST,
  APPLICANT_DELETE,
  APPLICANT_DELETE_SUCCESS,
  APPLICANT_DELETE_ERROR,
  APPLICANT_GET_BY_ID_ERROR,
  APPLICANT_COUNT_NEW,
  APPLICANT_COUNT_NEW_SUCCESS,
  APPLICANT_REMOVE_ATTACHMENT,
  APPLICANT_REMOVE_ATTACHMENT_SUCCESS,
} from './types';

// Applicant

export const removeApplicantAttachment = payload => ({
  type: APPLICANT_REMOVE_ATTACHMENT,
  payload,
});

export const removeApplicantAttachmentSuccess = item => ({
  type: APPLICANT_REMOVE_ATTACHMENT_SUCCESS,
  payload: item,
});

export const countNewApplicant = () => ({
  type: APPLICANT_COUNT_NEW,
});

export const countNewApplicantSuccess = item => ({
  type: APPLICANT_COUNT_NEW_SUCCESS,
  payload: item,
});

export const addNewApplicant = items => ({
  type: APPLICANT_ADD_NEW,
  payload: items,
});

export const addNewApplicantRequest = () => ({
  type: APPLICANT_ADD_NEW_REQUEST,
});

export const addNewApplicantSuccess = items => ({
  type: APPLICANT_ADD_NEW_SUCCESS,
  payload: items,
});

export const addNewApplicantError = error => ({
  type: APPLICANT_ADD_NEW_ERROR,
  payload: error,
});

export const editApplicant = item => ({
  type: APPLICANT_EDIT,
  payload: item,
});

export const editApplicantSuccess = item => ({
  type: APPLICANT_EDIT_SUCCESS,
  payload: item,
});

export const editApplicantError = error => ({
  type: APPLICANT_EDIT_ERROR,
  payload: error,
});

export const getApplicantByQuery = query => ({
  type: APPLICANT_GET_BY_QUERY,
  payload: query,
});

export const getApplicantByQuerySuccess = item => ({
  type: APPLICANT_GET_BY_QUERY_SUCCESS,
  payload: item,
});

export const getApplicantByQueryError = error => ({
  type: APPLICANT_GET_BY_QUERY_ERROR,
  payload: error,
});

export const getApplicantById = id => ({
  type: APPLICANT_GET_BY_ID,
  payload: id,
});

export const getApplicantByIdSuccess = item => ({
  type: APPLICANT_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getApplicantByIdError = error => ({
  type: APPLICANT_GET_BY_ID_ERROR,
  payload: error,
});

export const deleteApplicantById = id => ({
  type: APPLICANT_DELETE,
  payload: id,
});

export const deleteApplicantByIdSuccess = item => ({
  type: APPLICANT_DELETE_SUCCESS,
  payload: item,
});

export const deleteApplicantByIdErrorr = item => ({
  type: APPLICANT_DELETE_ERROR,
  payload: item,
});
