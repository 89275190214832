import {
  JOBTYPE_GET_LIST,
  JOBTYPE_GET_LIST_SUCCESS,
  JOBTYPE_GET_LIST_ERROR,
  JOBTYPE_GET_LIST_WITH_FILTER,
  JOBTYPE_GET_LIST_WITH_ORDER,
  JOBTYPE_GET_LIST_SEARCH,
  JOBTYPE_SELECTED_ITEMS_CHANGE,
  JOBTYPE_EDIT_ITEM,
  JOBTYPE_EDIT_ITEM_SUCCESS,
  JOBTYPE_EDIT_ITEM_ERROR,
  JOBTYPE_DELETE_ITEM,
  JOBTYPE_DELETE_ITEM_SUCCESS,
  JOBTYPE_DELETE_ITEM_ERROR,
} from '../actions';

export const getJobtypeList = () => ({
  type: JOBTYPE_GET_LIST,
});

export const getJobtypeListSuccess = items => ({
  type: JOBTYPE_GET_LIST_SUCCESS,
  payload: items,
});

export const getJobtypeListError = error => ({
  type: JOBTYPE_GET_LIST_ERROR,
  payload: error,
});

export const getJobtypeListWithFilter = (column, value) => ({
  type: JOBTYPE_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getJobtypeListWithOrder = column => ({
  type: JOBTYPE_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getJobtypeListSearch = keyword => ({
  type: JOBTYPE_GET_LIST_SEARCH,
  payload: keyword,
});

export const editJobtypeItem = item => ({
  type: JOBTYPE_EDIT_ITEM,
  payload: item,
});

export const editJobtypeItemSuccess = items => ({
  type: JOBTYPE_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editJobtypeItemError = error => ({
  type: JOBTYPE_EDIT_ITEM_ERROR,
  payload: error,
});

export const selectedJobtypeItemsChange = selectedItems => ({
  type: JOBTYPE_SELECTED_ITEMS_CHANGE,
  payload: selectedItems,
});

export const deleteJobtypeItem = item => ({
  type: JOBTYPE_DELETE_ITEM,
  payload: item,
});

export const deleteJobtypeItemSuccess = items => ({
  type: JOBTYPE_DELETE_ITEM_SUCCESS,
  payload: items,
});

export const deleteJobtypeItemError = error => ({
  type: JOBTYPE_DELETE_ITEM_ERROR,
  payload: error,
});
