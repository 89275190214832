import {
  APT_GET_BY_CLIENT_ID,
  APT_SAVE,
  APT_GET_BY_CLIENT_ID_SUCCESS,
  APT_SAVE_SUCCESS,
  APT_GET_BY_CLIENT_ID_ERROR,
  APT_SAVE_ERROR,
} from './types';
// item
const initialState = {
  item: null,
  loading: false,
  error: null,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case APT_GET_BY_CLIENT_ID:
    case APT_SAVE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case APT_GET_BY_CLIENT_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
      };

    case APT_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case APT_GET_BY_CLIENT_ID_ERROR:
    case APT_SAVE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
}
