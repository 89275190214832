import {
  TRAINER_GET_LIST,
  TRAINER_GET_LIST_SUCCESS,
  TRAINER_GET_LIST_ERROR,
  TRAINER_GET_LIST_WITH_FILTER,
  TRAINER_GET_LIST_WITH_ORDER,
  TRAINER_GET_LIST_SEARCH,
  TRAINER_ADD_ITEM,
  TRAINER_ADD_ITEM_SUCCESS,
  TRAINER_ADD_ITEM_ERROR,
  TRAINER_SELECTED_ITEMS_CHANGE,
  TRAINER_EDIT_ITEM,
  TRAINER_EDIT_ITEM_SUCCESS,
  TRAINER_EDIT_ITEM_ERROR,
  TRAINER_DELETE_ITEM,
  TRAINER_DELETE_ITEM_SUCCESS,
  TRAINER_DELETE_ITEM_ERROR,
} from '../actions';

import {
  EMPLOYER_GET_LIST,
  EMPLOYER_GET_LIST_SUCCESS,
  EMPLOYER_GET_LIST_ERROR,
  EMPLOYER_ADD_ITEM,
  EMPLOYER_ADD_ITEM_ERROR,
  EMPLOYER_EDIT_ITEM,
  EMPLOYER_EDIT_ITEM_ERROR,
  EMPLOYER_DELETE_ITEM,
  EMPLOYER_DELETE_ITEM_ERROR,
  EMPLOYER_GET_BY_ID,
  EMPLOYER_GET_BY_ID_SUCCESS,
  EMPLOYER_GET_BY_ID_ERROR,
} from './employer.types';

export const getTrainerList = option => ({
  type: TRAINER_GET_LIST,
  payload: option,
});

export const getTrainerListSuccess = items => ({
  type: TRAINER_GET_LIST_SUCCESS,
  payload: items,
});

export const getTrainerListError = error => ({
  type: TRAINER_GET_LIST_ERROR,
  payload: error,
});

export const getTrainerListWithFilter = (column, value) => ({
  type: TRAINER_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getTrainerListWithOrder = column => ({
  type: TRAINER_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getTrainerListSearch = keyword => ({
  type: TRAINER_GET_LIST_SEARCH,
  payload: keyword,
});

export const addTrainerItem = item => ({
  type: TRAINER_ADD_ITEM,
  payload: item,
});
export const addTrainerItemSuccess = items => ({
  type: TRAINER_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addTrainerItemError = error => ({
  type: TRAINER_ADD_ITEM_ERROR,
  payload: error,
});

export const editTrainerItem = item => ({
  type: TRAINER_EDIT_ITEM,
  payload: item,
});

export const editTrainerItemSuccess = items => ({
  type: TRAINER_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editTrainerItemError = error => ({
  type: TRAINER_EDIT_ITEM_ERROR,
  payload: error,
});

export const deleteTrainerItem = item => ({
  type: TRAINER_DELETE_ITEM,
  payload: item,
});

export const deleteTrainerItemSuccess = items => ({
  type: TRAINER_DELETE_ITEM_SUCCESS,
  payload: items,
});

export const deleteTrainerItemError = error => ({
  type: TRAINER_DELETE_ITEM_ERROR,
  payload: error,
});

export const selectedTrainerItemsChange = selectedItems => ({
  type: TRAINER_SELECTED_ITEMS_CHANGE,
  payload: selectedItems,
});

export const getEmployerList = filter => ({
  type: EMPLOYER_GET_LIST,
  payload: filter,
});

export const getEmployerListSuccess = items => ({
  type: EMPLOYER_GET_LIST_SUCCESS,
  payload: items,
});

export const getEmployerListError = error => ({
  type: EMPLOYER_GET_LIST_ERROR,
  payload: error,
});

export const addEmployerItem = (item, history) => ({
  type: EMPLOYER_ADD_ITEM,
  payload: { item, history },
});

export const addEmployerItemError = error => ({
  type: EMPLOYER_ADD_ITEM_ERROR,
  payload: error,
});

export const editEmployerItem = (item, history) => ({
  type: EMPLOYER_EDIT_ITEM,
  payload: { item, history },
});

export const editEmployerItemError = error => ({
  type: EMPLOYER_EDIT_ITEM_ERROR,
  payload: error,
});

export const deleteEmployerItem = (id, isDeleted) => ({
  type: EMPLOYER_DELETE_ITEM,
  payload: { id, isDeleted },
});

export const deleteEmployerItemError = error => ({
  type: EMPLOYER_DELETE_ITEM_ERROR,
  payload: error,
});

export const getEmployerById = id => ({
  type: EMPLOYER_GET_BY_ID,
  payload: id,
});
export const getEmployerByIdError = error => ({
  type: EMPLOYER_GET_BY_ID_ERROR,
  payload: error,
});
export const getEmployerByIdSuccess = employer => ({
  type: EMPLOYER_GET_BY_ID_SUCCESS,
  payload: employer,
});
