import {
  DISMISS_ALL_NOTIFICATION,
  GET_NOTIFICATION_BY_QUERY,
  GET_NOTIFICATION_BY_QUERY_ERROR,
  GET_NOTIFICATION_BY_QUERY_SUCCESS,
  READ_NOTIFICATION,
  READ_NOTIFICATION_ERROR,
  READ_NOTIFICATION_SUCCESS,
  VIEW_NOTIFICATION,
  VIEW_NOTIFICATION_ERROR,
  VIEW_NOTIFICATION_SUCCESS,
} from './types';

export const getNotificationByQuery = payload => ({
  type: GET_NOTIFICATION_BY_QUERY,
  payload,
});

export const getNotificationByQuerySuccess = payload => ({
  type: GET_NOTIFICATION_BY_QUERY_SUCCESS,
  payload,
});

export const getNotificationByQueryError = payload => ({
  type: GET_NOTIFICATION_BY_QUERY_ERROR,
  payload,
});

export const readNotification = payload => ({
  type: READ_NOTIFICATION,
  payload,
});

export const readNotificationSuccess = payload => ({
  type: READ_NOTIFICATION_SUCCESS,
  payload,
});

export const readNotificationError = payload => ({
  type: READ_NOTIFICATION_ERROR,
  payload,
});

export const viewNotification = payload => ({
  type: VIEW_NOTIFICATION,
  payload,
});

export const viewNotificationSuccess = payload => ({
  type: VIEW_NOTIFICATION_SUCCESS,
  payload,
});

export const viewNotificationError = payload => ({
  type: VIEW_NOTIFICATION_ERROR,
  payload,
});

export const dismissAllNotification = payload => ({
  type: DISMISS_ALL_NOTIFICATION,
  payload,
});
