import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { OUTREACH_GET_BY_CLIENT_AND_PROGRAM, OUTREACH_SAVE } from './types';

import { getOutreachByClientAndProgramSuccess, outreachError } from './actions';
import { getClientKPISuccess } from '../actions';

const getOutreachByClientAndProgramRequest = async query => {
  let url = `${Config.apiServerHost}/api/Outreach/getByClientAndProgram`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getClientKPIRequest = async query => {
  let url = `${Config.apiServerHost}/api/client/kpis`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const saveOutreachRequst = async item => {
  return axios.post(`${Config.apiServerHost}/api/Outreach`, item, await authHeader());
};

function* getOutreachByClientAndProgram({ payload }) {
  try {
    const response = yield call(getOutreachByClientAndProgramRequest, payload);
    yield put(getOutreachByClientAndProgramSuccess(response.data));
  } catch (error) {
    yield put(outreachError(error.response.statusText));
  }
}

function* saveOutreach({ payload }) {
  try {
    const { clientId, programId, servicePlanHeaderId } = payload;
    const query = {
      clientId,
      programId,
      servicePlanHeaderId: servicePlanHeaderId ?? '',
    };

    yield call(saveOutreachRequst, payload);
    let response = yield call(getOutreachByClientAndProgramRequest, query);
    yield put(getOutreachByClientAndProgramSuccess(response.data));
    response = yield call(getClientKPIRequest, query);
    yield put(getClientKPISuccess(response.data));
  } catch (error) {
    yield put(outreachError(error.response.statusText));
  }
}

export function* watchOutreachByClientAndProgram() {
  yield takeEvery(OUTREACH_GET_BY_CLIENT_AND_PROGRAM, getOutreachByClientAndProgram);
}

export function* watchOutreachSaving() {
  yield takeEvery(OUTREACH_SAVE, saveOutreach);
}

export default function* rootSaga() {
  yield all([fork(watchOutreachByClientAndProgram), fork(watchOutreachSaving)]);
}
