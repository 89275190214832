import {
  PROSPECT_GET_LIST,
  PROSPECT_GET_LIST_SUCCESS,
  PROSPECT_GET_LIST_ERROR,
  PROSPECT_ARCHIVE,
} from '../actions';

export const getProspectList = filter => ({
  type: PROSPECT_GET_LIST,
  payload: filter,
});

export const getProspectListSuccess = items => ({
  type: PROSPECT_GET_LIST_SUCCESS,
  payload: items,
});

export const getProspectListError = error => ({
  type: PROSPECT_GET_LIST_ERROR,
  payload: error,
});

export const archiveProspect = items => ({
  type: PROSPECT_ARCHIVE,
  payload: items,
});
