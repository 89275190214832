import {
  ALL_ES_CONTRACT_OUSTANDING_GET_LIST,
  ALL_ES_CONTRACT_OUSTANDING_GET_LIST_SUCCESS,
  ALL_ES_CONTRACT_OUSTANDING_GET_LIST_ERROR,
  ALL_ES_CONTRACT_OUSTANDING_GET_LIST_WITH_FILTER,
  ALL_ES_CONTRACT_OUSTANDING_GET_LIST_WITH_ORDER,
  ALL_ES_CONTRACT_OUSTANDING_GET_LIST_SEARCH,
} from '../../actions';

export const getAllESContractOustandingList = filter => ({
  type: ALL_ES_CONTRACT_OUSTANDING_GET_LIST,
  payload: filter,
});

export const getAllESContractOustandingListSuccess = items => ({
  type: ALL_ES_CONTRACT_OUSTANDING_GET_LIST_SUCCESS,
  payload: items,
});

export const getAllESContractOustandingListError = error => ({
  type: ALL_ES_CONTRACT_OUSTANDING_GET_LIST_ERROR,
  payload: error,
});

export const getAllESContractOustandingListWithFilter = (column, value) => ({
  type: ALL_ES_CONTRACT_OUSTANDING_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getAllESContractOustandingListWithOrder = column => ({
  type: ALL_ES_CONTRACT_OUSTANDING_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getAllESContractOustandingListSearch = keyword => ({
  type: ALL_ES_CONTRACT_OUSTANDING_GET_LIST_SEARCH,
  payload: keyword,
});
