import {
  CAMPAIGN_ADD_NEW,
  CAMPAIGN_ADD_NEW_ERROR,
  CAMPAIGN_ADD_NEW_SUCCESS,
  CAMPAIGN_DELETE,
  CAMPAIGN_DELETE_ERROR,
  CAMPAIGN_DELETE_SUCCESS,
  CAMPAIGN_GET_BY_ID,
  CAMPAIGN_GET_BY_ID_ERROR,
  CAMPAIGN_GET_BY_ID_SUCCESS,
  CAMPAIGN_GET_PAGING,
  CAMPAIGN_GET_PAGING_ERROR,
  CAMPAIGN_GET_PAGING_SUCCESS,
  CAMPAIGN_STATUS_CHANGE,
  CAMPAIGN_STATUS_CHANGE_ERROR,
  CAMPAIGN_STATUS_CHANGE_SUCCESS,
  CAMPAIGN_UPDATE,
  CAMPAIGN_UPDATE_ERROR,
  CAMPAIGN_UPDATE_SUCCESS,
  CAMPAIGN_DASHBOARD_GET_PAGIN,
  CAMPAIGN_DASHBOARD_GET_PAGIN_ERROR,
  CAMPAIGN_DASHBOARD_GET_PAGIN_SUCCESS,
} from './types';

// Campaign
export const getPagingCampaign = items => ({
  type: CAMPAIGN_GET_PAGING,
  payload: items,
});

export const getCampaignDashboardPaging = payload => ({
  type: CAMPAIGN_DASHBOARD_GET_PAGIN,
  payload,
});

export const getCampaignDashboardSuccess = data => ({
  type: CAMPAIGN_DASHBOARD_GET_PAGIN_SUCCESS,
  payload: data,
});

export const getCampaignDashboardError = error => ({
  type: CAMPAIGN_DASHBOARD_GET_PAGIN_ERROR,
  payload: error,
});

export const getPagingCampaignSuccess = items => ({
  type: CAMPAIGN_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingCampaignError = error => ({
  type: CAMPAIGN_GET_PAGING_ERROR,
  payload: error,
});

export const addCampaign = items => ({
  type: CAMPAIGN_ADD_NEW,
  payload: items,
});

export const addCampaignSuccess = items => ({
  type: CAMPAIGN_ADD_NEW_SUCCESS,
  payload: items,
});

export const addCampaignError = error => ({
  type: CAMPAIGN_ADD_NEW_ERROR,
  payload: error,
});

export const getCampaignById = items => ({
  type: CAMPAIGN_GET_BY_ID,
  payload: items,
});

export const getCampaignByIdSuccess = items => ({
  type: CAMPAIGN_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getCampaignByIdError = error => ({
  type: CAMPAIGN_GET_BY_ID_ERROR,
  payload: error,
});

export const updateCampaign = items => ({
  type: CAMPAIGN_UPDATE,
  payload: items,
});

export const updateCampaignSuccess = items => ({
  type: CAMPAIGN_UPDATE_SUCCESS,
  payload: items,
});

export const updateCampaignError = error => ({
  type: CAMPAIGN_UPDATE_ERROR,
  payload: error,
});

export const deleteCampaign = items => ({
  type: CAMPAIGN_DELETE,
  payload: items,
});

export const deleteCampaignSuccess = items => ({
  type: CAMPAIGN_DELETE_SUCCESS,
  payload: items,
});

export const deleteCampaignError = error => ({
  type: CAMPAIGN_DELETE_ERROR,
  payload: error,
});

export const changeCampaignStatus = id => ({
  type: CAMPAIGN_STATUS_CHANGE,
  payload: id,
});

export const changeCampaignStatusSuccess = items => ({
  type: CAMPAIGN_STATUS_CHANGE_SUCCESS,
  payload: items,
});

export const changeCampaignStatusError = error => ({
  type: CAMPAIGN_STATUS_CHANGE_ERROR,
  payload: error,
});
