import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { NOTE_ADD_NEW, NOTE_EDIT, NOTE_GET_BY_QUERY, NOTE_GET_BY_ID, NOTE_DELETE } from './types';

import {
  addNewNoteError,
  editNoteError,
  getNoteByQuerySuccess,
  getNoteByQueryError,
  getNoteByIdError,
  getNoteByIdSuccess,
  deleteNoteByIdErrorr,
} from './actions';

const addNewNoteReqeust = async items => {
  return axios.post(`${Config.apiServerHost}/api/Note`, items, await authHeader());
};

const editNoteRequest = async items => {
  return axios.put(`${Config.apiServerHost}/api/Note`, items, await authHeader());
};

const getNoteByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/Note/${id}`, await authHeader());
};

const getNoteByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/Note/fetch`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const deleteNoteRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/Note/${id}`, await authHeader());
};
function* addNewNote({ payload }) {
  try {
    let response = yield call(addNewNoteReqeust, payload);
    response = yield call(getNoteByQueryRequest, { userId: payload.userId });
    yield put(getNoteByQuerySuccess(response.data));
  } catch (error) {
    yield put(addNewNoteError(error.response.statusText));
  }
}

function* editNote({ payload }) {
  try {
    let response = yield call(editNoteRequest, payload);
    response = yield call(getNoteByQueryRequest, { userId: payload.userId });
    yield put(getNoteByQuerySuccess(response.data));
  } catch (error) {
    yield put(editNoteError(error.response.statusText));
  }
}

function* getNoteByQuery({ payload }) {
  try {
    const response = yield call(getNoteByQueryRequest, payload);
    yield put(getNoteByQuerySuccess(response.data));
  } catch (error) {
    yield put(getNoteByQueryError(error.response.statusText));
  }
}

function* getNoteById({ payload }) {
  try {
    const response = yield call(getNoteByIdRequest, payload);
    yield put(getNoteByIdSuccess(response.data));
  } catch (error) {
    yield put(getNoteByIdError(error.response.statusText));
  }
}

function* deleteNote({ payload }) {
  try {
    const { programId, clientId, servicePlanHeaderId, data } = payload;
    const query = {
      programId,
      clientId,
      servicePlanHeaderId,
    };

    let response = yield call(deleteNoteRequest, data);
    response = yield call(getNoteByQueryRequest, query);
    yield put(getNoteByQuerySuccess(response.data));
  } catch (error) {
    yield put(deleteNoteByIdErrorr(error.response.statusText));
  }
}

export function* watchAddNewNote() {
  yield takeEvery(NOTE_ADD_NEW, addNewNote);
}
export function* watchEditNote() {
  yield takeEvery(NOTE_EDIT, editNote);
}

export function* watchGetNoteByQuery() {
  yield takeEvery(NOTE_GET_BY_QUERY, getNoteByQuery);
}

export function* watchGetNoteById() {
  yield takeEvery(NOTE_GET_BY_ID, getNoteById);
}

export function* watchDeleteNote() {
  yield takeEvery(NOTE_DELETE, deleteNote);
}

export default function* rootSaga() {
  yield all([
    fork(watchAddNewNote),
    fork(watchEditNote),
    fork(watchGetNoteByQuery),
    fork(watchDeleteNote),
    fork(watchGetNoteById),
  ]);
}

export { addNewNoteReqeust, getNoteByQueryRequest, deleteNoteRequest };
