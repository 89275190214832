import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  EMAIL_TEMPLATE_ADD_NEW,
  EMAIL_TEMPLATE_EDIT,
  EMAIL_TEMPLATE_GET_BY_QUERY,
  EMAIL_TEMPLATE_GET_BY_ID,
  EMAIL_TEMPLATE_DELETE,
} from './types';

import {
  addNewEmailTemplateError,
  editEmailTemplateError,
  getEmailTemplateByQuerySuccess,
  getEmailTemplateByQueryError,
  getEmailTemplateByIdError,
  getEmailTemplateByIdSuccess,
  deleteEmailTemplateByIdErrorr,
} from './actions';

const getAllEmailTemplateRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/EmailTemplate/getAll`, {
    headers: { ...(await authHeader()).headers },
  });
};

const addNewEmailTemplateRequest = async items => {
  return axios.post(`${Config.apiServerHost}/api/EmailTemplate`, items, {
    headers: { ...(await authHeader()).headers },
  });
};

const editEmailTemplateRequest = async items => {
  return axios.put(`${Config.apiServerHost}/api/EmailTemplate`, items, {
    headers: { ...(await authHeader()).headers },
  });
};

const getEmailTemplateByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/EmailTemplate/${id}`, {
    headers: { ...(await authHeader()).headers },
  });
};

const getEmailTemplateByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/EmailTemplate/paging`;
  return await axios.get(url, {
    params: query,
    headers: { ...(await authHeader()).headers },
  });
};

const deleteEmailTemplateRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/EmailTemplate/${id}`, {
    headers: { ...(await authHeader()).headers },
  });
};

function* addNewEmailTemplate({ payload }) {
  try {
    const { data } = payload;
    let response = yield call(addNewEmailTemplateRequest, data);
    response = yield call(getEmailTemplateByQueryRequest, {});
    yield put(getEmailTemplateByQuerySuccess(response.data));
  } catch (error) {
    yield put(addNewEmailTemplateError(error.response.statusText));
  }
}

function* editEmailTemplate({ payload }) {
  try {
    const { data } = payload;
    let response = yield call(editEmailTemplateRequest, data);
    response = yield call(getEmailTemplateByQueryRequest, {});
    yield put(getEmailTemplateByQuerySuccess(response.data));
  } catch (error) {
    yield put(editEmailTemplateError(error.response.statusText));
  }
}

function* getEmailTemplateByQuery({ payload }) {
  try {
    const response = yield call(getEmailTemplateByQueryRequest, payload);
    yield put(getEmailTemplateByQuerySuccess(response.data));
  } catch (error) {
    yield put(getEmailTemplateByQueryError(error.response.statusText));
  }
}

function* getEmailTemplateById({ payload }) {
  try {
    const response = yield call(getEmailTemplateByIdRequest, payload);
    yield put(getEmailTemplateByIdSuccess(response.data));
  } catch (error) {
    yield put(getEmailTemplateByIdError(error.response.statusText));
  }
}

function* deleteEmailTemplate({ payload }) {
  try {
    const { data } = payload;
    let response = yield call(deleteEmailTemplateRequest, data);
    response = yield call(getEmailTemplateByQueryRequest, {});
    yield put(getEmailTemplateByQuerySuccess(response.data));
  } catch (error) {
    yield put(deleteEmailTemplateByIdErrorr(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(EMAIL_TEMPLATE_ADD_NEW, addNewEmailTemplate);
  yield takeEvery(EMAIL_TEMPLATE_EDIT, editEmailTemplate);
  yield takeEvery(EMAIL_TEMPLATE_GET_BY_QUERY, getEmailTemplateByQuery);
  yield takeEvery(EMAIL_TEMPLATE_GET_BY_ID, getEmailTemplateById);
  yield takeEvery(EMAIL_TEMPLATE_DELETE, deleteEmailTemplate);
}

export { getAllEmailTemplateRequest };
