import {
  ACTION_PLAN_FOR_STAFF_ADD_NEW,
  ACTION_PLAN_FOR_STAFF_ADD_NEW_ERROR,
  ACTION_PLAN_FOR_STAFF_ADD_NEW_SUCCESS,
  ACTION_PLAN_FOR_STAFF_CHANGE_STATUS,
  ACTION_PLAN_FOR_STAFF_CHANGE_STATUS_ERROR,
  ACTION_PLAN_FOR_STAFF_CHANGE_STATUS_SUCCESS,
  ACTION_PLAN_FOR_STAFF_DELETE,
  ACTION_PLAN_FOR_STAFF_DELETE_ERROR,
  ACTION_PLAN_FOR_STAFF_DELETE_SUCCESS,
  ACTION_PLAN_FOR_STAFF_GET_BY_ID,
  ACTION_PLAN_FOR_STAFF_GET_BY_ID_ERROR,
  ACTION_PLAN_FOR_STAFF_GET_BY_ID_SUCCESS,
  ACTION_PLAN_FOR_STAFF_GET_PAGING,
  ACTION_PLAN_FOR_STAFF_GET_PAGING_ERROR,
  ACTION_PLAN_FOR_STAFF_GET_PAGING_SUCCESS,
  ACTION_PLAN_FOR_STAFF_UPDATE,
  ACTION_PLAN_FOR_STAFF_UPDATE_ERROR,
  ACTION_PLAN_FOR_STAFF_UPDATE_SUCCESS,
} from './types';

export const getPagingActionPlanForStaff = items => ({
  type: ACTION_PLAN_FOR_STAFF_GET_PAGING,
  payload: items,
});

export const getPagingActionPlanForStaffSuccess = items => ({
  type: ACTION_PLAN_FOR_STAFF_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingActionPlanForStaffError = error => ({
  type: ACTION_PLAN_FOR_STAFF_GET_PAGING_ERROR,
  payload: error,
});

export const addActionPlanForStaff = items => ({
  type: ACTION_PLAN_FOR_STAFF_ADD_NEW,
  payload: items,
});

export const addActionPlanForStaffSuccess = items => ({
  type: ACTION_PLAN_FOR_STAFF_ADD_NEW_SUCCESS,
  payload: items,
});

export const addActionPlanForStaffError = error => ({
  type: ACTION_PLAN_FOR_STAFF_ADD_NEW_ERROR,
  payload: error,
});

export const getActionPlanForStaffById = items => ({
  type: ACTION_PLAN_FOR_STAFF_GET_BY_ID,
  payload: items,
});

export const getActionPlanForStaffByIdSuccess = items => ({
  type: ACTION_PLAN_FOR_STAFF_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getActionPlanForStaffByIdError = error => ({
  type: ACTION_PLAN_FOR_STAFF_GET_BY_ID_ERROR,
  payload: error,
});

export const updateActionPlanForStaff = items => ({
  type: ACTION_PLAN_FOR_STAFF_UPDATE,
  payload: items,
});

export const updateActionPlanForStaffSuccess = items => ({
  type: ACTION_PLAN_FOR_STAFF_UPDATE_SUCCESS,
  payload: items,
});

export const updateActionPlanForStaffError = error => ({
  type: ACTION_PLAN_FOR_STAFF_UPDATE_ERROR,
  payload: error,
});

export const changeStatusActionPlanForStaff = items => ({
  type: ACTION_PLAN_FOR_STAFF_CHANGE_STATUS,
  payload: items,
});

export const changeStatusActionPlanForStaffSuccess = items => ({
  type: ACTION_PLAN_FOR_STAFF_CHANGE_STATUS_SUCCESS,
  payload: items,
});

export const changeStatusActionPlanForStaffError = error => ({
  type: ACTION_PLAN_FOR_STAFF_CHANGE_STATUS_ERROR,
  payload: error,
});

export const deleteActionPlanForStaff = items => ({
  type: ACTION_PLAN_FOR_STAFF_DELETE,
  payload: items,
});

export const deleteActionPlanForStaffSuccess = items => ({
  type: ACTION_PLAN_FOR_STAFF_DELETE_SUCCESS,
  payload: items,
});

export const deleteActionPlanForStaffError = error => ({
  type: ACTION_PLAN_FOR_STAFF_DELETE_ERROR,
  payload: error,
});
