import axios from 'axios';
import FileSaver from 'file-saver';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { buildUrl } from '../../helpers/Utils';
import { call, put, takeEvery } from 'redux-saga/effects';
import { EMPLOYER_CASE_NOTE_QUERY } from '../actions';

import {
  getPagingEmployerCaseNoteSuccess,
  getPagingEmployerCaseNoteError,
  editEmployerCaseNoteSuccess,
  editEmployerCaseNoteError,
  addNewEmployerCaseNoteSuccess,
  addNewEmployerCaseNoteError,
  deleteEmployerCaseNoteSuccess,
  deleteEmployerCaseNoteError,
  getEmployerCaseNoteByIdSuccess,
} from './actions';
import {
  EMPLOYER_CASE_NOTE_GET_PAGING,
  EMPLOYER_CASE_NOTE_ADD_NEW,
  EMPLOYER_CASE_NOTE_GET_BY_ID,
  EMPLOYER_CASE_NOTE_EDIT,
  EMPLOYER_CASE_NOTE_DELETE,
} from './types';

const getPagingEmployerCaseNoteRequest = async query => {
  return axios.get(`${Config.apiServerHost}/api/EmployerCaseNote/paging`, {
    params: query,
    headers: { ...(await authHeader()).headers },
  });
};

const addNewEmployerCaseNoteReqeust = async EmployerCaseNote => {
  return axios.post(
    `${Config.apiServerHost}/api/EmployerCaseNote`,
    EmployerCaseNote,
    await authHeader()
  );
};

const editEmployerCaseNoteRequest = async EmployerCaseNote => {
  return axios.put(
    `${Config.apiServerHost}/api/EmployerCaseNote`,
    EmployerCaseNote,
    await authHeader()
  );
};

const exportCaseNotesToDoc = async (params, filename) => {
  const { headers } = await authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };

  return await fetch(
    buildUrl(`${Config.apiServerHost}/api/EmployerCaseNote/exportToDoc`, params),
    requestOptions
  )
    .then(response => response.blob())
    .then(data => {
      FileSaver.saveAs(new Blob([data]), filename);
    });
};

const exportEmpoyerCaseNoteToDocById = async (id, filename) => {
  const { headers } = await authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };

  return await fetch(
    buildUrl(`${Config.apiServerHost}/api/EmployerCaseNote/exportToDoc/${id}`),
    requestOptions
  )
    .then(response => response.blob())
    .then(data => {
      FileSaver.saveAs(new Blob([data]), filename);
    });
};

const deleteEmployerCaseNoteRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/EmployerCaseNote/${id}`, await authHeader());
};

const getEmployerCaseNoteByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/EmployerCaseNote/${id}`, await authHeader());
};

function* getPagingEmployerCaseNote({ payload }) {
  try {
    let response = yield call(getPagingEmployerCaseNoteRequest, payload);
    yield put(getPagingEmployerCaseNoteSuccess(response.data));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage
      ? errorMessage
      : errorJSON
      ? JSON.stringify(errorJSON)
      : 'Something was wrong';
    yield put(getPagingEmployerCaseNoteError(err));
  }
}

function* addNewEmployerCaseNote({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(addNewEmployerCaseNoteReqeust, data);
    yield put(addNewEmployerCaseNoteSuccess(response.data));

    response = yield call(getPagingEmployerCaseNoteRequest, queryParams);
    yield put(getPagingEmployerCaseNoteSuccess(response.data));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage
      ? errorMessage
      : errorJSON
      ? JSON.stringify(errorJSON)
      : 'Something was wrong';
    yield put(addNewEmployerCaseNoteError(err));
  }
}

function* editEmployerCaseNote({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(editEmployerCaseNoteRequest, data);
    yield put(editEmployerCaseNoteSuccess(response.data));

    response = yield call(getPagingEmployerCaseNoteRequest, queryParams);
    yield put(getPagingEmployerCaseNoteSuccess(response.data));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage
      ? errorMessage
      : errorJSON
      ? JSON.stringify(errorJSON)
      : 'Something was wrong';
    yield put(editEmployerCaseNoteError(err));
  }
}

function* getEmployerCaseNoteById({ payload }) {
  try {
    const response = yield call(getEmployerCaseNoteByIdRequest, payload);
    yield put(getEmployerCaseNoteByIdSuccess(response.data));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage
      ? errorMessage
      : errorJSON
      ? JSON.stringify(errorJSON)
      : 'Something was wrong';
    yield put(editEmployerCaseNoteError(err));
  }
}

function* deleteEmployerCaseNote({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(deleteEmployerCaseNoteRequest, data);
    yield put(deleteEmployerCaseNoteSuccess(response.data));

    response = yield call(getPagingEmployerCaseNoteRequest, queryParams);
    yield put(getPagingEmployerCaseNoteSuccess(response.data));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage
      ? errorMessage
      : errorJSON
      ? JSON.stringify(errorJSON)
      : 'Something was wrong';
    yield put(deleteEmployerCaseNoteError(err));
  }
}

export default function* rootSaga() {
  yield takeEvery(EMPLOYER_CASE_NOTE_GET_PAGING, getPagingEmployerCaseNote);
  yield takeEvery(EMPLOYER_CASE_NOTE_ADD_NEW, addNewEmployerCaseNote);
  yield takeEvery(EMPLOYER_CASE_NOTE_GET_BY_ID, getEmployerCaseNoteById);
  yield takeEvery(EMPLOYER_CASE_NOTE_EDIT, editEmployerCaseNote);
  yield takeEvery(EMPLOYER_CASE_NOTE_DELETE, deleteEmployerCaseNote);
}

export { exportCaseNotesToDoc, exportEmpoyerCaseNoteToDocById };
