import {
  GEOGRAPHY_GET_LIST,
  GEOGRAPHY_GET_LIST_SUCCESS,
  GEOGRAPHY_GET_LIST_ERROR,
  GEOGRAPHY_GET_LIST_WITH_FILTER,
  GEOGRAPHY_GET_LIST_WITH_ORDER,
  GEOGRAPHY_GET_LIST_SEARCH,
  GEOGRAPHY_SELECTED_ITEMS_CHANGE,
  GEOGRAPHY_ADD_OR_UPDATE_ITEM,
  GEOGRAPHY_ADD_OR_UPDATE_ITEM_SUCCESS,
  GEOGRAPHY_ADD_OR_UPDATE_ITEM_ERROR,
  GEOGRAPHY_DELETE_ITEM,
  GEOGRAPHY_DELETE_ITEM_SUCCESS,
  GEOGRAPHY_DELETE_ITEM_ERROR,
} from '../actions';

export const getGeographyList = () => ({
  type: GEOGRAPHY_GET_LIST,
});

export const getGeographyListSuccess = items => ({
  type: GEOGRAPHY_GET_LIST_SUCCESS,
  payload: items,
});

export const getGeographyListError = error => ({
  type: GEOGRAPHY_GET_LIST_ERROR,
  payload: error,
});

export const getGeographyListWithFilter = (column, value) => ({
  type: GEOGRAPHY_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getGeographyListWithOrder = column => ({
  type: GEOGRAPHY_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getGeographyListSearch = keyword => ({
  type: GEOGRAPHY_GET_LIST_SEARCH,
  payload: keyword,
});

export const AddorUpdateGeographyItem = item => ({
  type: GEOGRAPHY_ADD_OR_UPDATE_ITEM,
  payload: item,
});

export const AddorUpdateGeographyItemSuccess = items => ({
  type: GEOGRAPHY_ADD_OR_UPDATE_ITEM_SUCCESS,
  payload: items,
});

export const AddorUpdateGeographyItemError = error => ({
  type: GEOGRAPHY_ADD_OR_UPDATE_ITEM_ERROR,
  payload: error,
});

export const selectedGeographyItemsChange = selectedItems => ({
  type: GEOGRAPHY_SELECTED_ITEMS_CHANGE,
  payload: selectedItems,
});

export const deleteGeographyItem = item => ({
  type: GEOGRAPHY_DELETE_ITEM,
  payload: item,
});

export const deleteGeographyItemSuccess = items => ({
  type: GEOGRAPHY_DELETE_ITEM_SUCCESS,
  payload: items,
});

export const deleteGeographyItemError = error => ({
  type: GEOGRAPHY_DELETE_ITEM_ERROR,
  payload: error,
});
