// NOTE
export const NOTE_GET_BY_ID = 'NOTE_GET_BY_ID';
export const NOTE_GET_BY_ID_SUCCESS = 'NOTE_GET_BY_ID_SUCCESS';
export const NOTE_GET_BY_ID_ERROR = 'NOTE_GET_BY_ID_ERROR';

export const NOTE_GET_LIST = 'NOTE_GET_LIST';
export const NOTE_GET_LIST_SUCCESS = 'NOTE_GET_LIST_SUCCESS';
export const NOTE_GET_LIST_ERROR = 'NOTE_GET_LIST_ERROR';

export const NOTE_EDIT = 'NOTE_EDIT';
export const NOTE_EDIT_SUCCESS = 'NOTE_EDIT_SUCCESS';
export const NOTE_EDIT_ERROR = 'NOTE_EDIT_ERROR';

export const NOTE_ADD_NEW = 'NOTE_ADD_NEW';
export const NOTE_ADD_NEW_SUCCESS = 'NOTE_ADD_NEW_SUCCESS';
export const NOTE_ADD_NEW_ERROR = 'NOTE_ADD_NEW_ERROR';
export const NOTE_ADD_NEW_REQUEST = 'NOTE_ADD_NEW_REQUEST';

export const NOTE_DELETE = 'NOTE_DELETE';
export const NOTE_DELETE_SUCCESS = 'NOTE_DELETE_SUCCESS';
export const NOTE_DELETE_ERROR = 'NOTE_DELETE_ERROR';

export const NOTE_GET_BY_QUERY = 'NOTE_GET_BY_QUERY';
export const NOTE_GET_BY_QUERY_SUCCESS = 'NOTE_GET_BY_QUERY_SUCCESS';
export const NOTE_GET_BY_QUERY_ERROR = 'NOTE_GET_BY_QUERY_ERROR';
