import {
  SYSTEM_SETTING_GET_BY_ID,
  SYSTEM_SETTING_GET_BY_ID_SUCCESS,
  SYSTEM_SETTING_GET_BY_ID_ERROR,
  SYSTEM_SETTING_SAVE,
  SYSTEM_SETTING_SAVE_SUCCESS,
  SYSTEM_SETTING_SAVE_ERROR,
} from '../actions';

const initialState = {
  setting: null,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SYSTEM_SETTING_GET_BY_ID:
    case SYSTEM_SETTING_SAVE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SYSTEM_SETTING_SAVE_SUCCESS:
    case SYSTEM_SETTING_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        setting: payload ? payload : {},
      };

    case SYSTEM_SETTING_GET_BY_ID_ERROR:
    case SYSTEM_SETTING_SAVE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
