import {
  POSTING_SENT_TO_CLIENT_GET_BY_ID,
  POSTING_SENT_TO_CLIENT_GET_BY_ID_SUCCESS,
  POSTING_SENT_TO_CLIENT_GET_LIST,
  POSTING_SENT_TO_CLIENT_GET_LIST_SUCCESS,
  POSTING_SENT_TO_CLIENT_ERROR,
} from './types';

const initialState = {
  postings: [],
  posting: null,
  loading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case POSTING_SENT_TO_CLIENT_GET_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
        posting: null,
      };
    case POSTING_SENT_TO_CLIENT_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        posting: payload,
      };

    case POSTING_SENT_TO_CLIENT_GET_LIST:
      return {
        ...state,
        error: null,
        loading: true,
        posting: null,
      };
    case POSTING_SENT_TO_CLIENT_GET_LIST_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        postings: data,
        loading: false,
        posting: null,
        itemCount,
        pageCount,
      };

    case POSTING_SENT_TO_CLIENT_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        posting: null,
      };

    default:
      return { ...state };
  }
}
