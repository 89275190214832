import {
  GET_WORKSHOP_EVENT_LIST,
  GET_WORKSHOP_EVENT_LIST_ERROR,
  GET_WORKSHOP_EVENT_LIST_SUCCESS,
} from './types';

const initialState = {
  item: null,
  items: [],
  loading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
  total: 0,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_WORKSHOP_EVENT_LIST:
      return {
        ...state,
        loading: true,
        error: null,
        item: null,
      };
    case GET_WORKSHOP_EVENT_LIST_SUCCESS:
      const { itemCount, pageCount, total, data } = payload;
      return {
        ...state,
        loading: false,
        items: data,
        total,
        itemCount,
        pageCount,
      };
    case GET_WORKSHOP_EVENT_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
}
