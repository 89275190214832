import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  ACTION_PLAN_FOR_STAFF_ADD_NEW,
  ACTION_PLAN_FOR_STAFF_CHANGE_STATUS,
  ACTION_PLAN_FOR_STAFF_DELETE,
  ACTION_PLAN_FOR_STAFF_GET_BY_ID,
  ACTION_PLAN_FOR_STAFF_GET_PAGING,
  ACTION_PLAN_FOR_STAFF_UPDATE,
} from './types';

import {
  addActionPlanForStaffSuccess,
  addActionPlanForStaffError,
  getPagingActionPlanForStaffSuccess,
  getPagingActionPlanForStaffError,
  getActionPlanForStaffByIdSuccess,
  getActionPlanForStaffByIdError,
  updateActionPlanForStaffSuccess,
  updateActionPlanForStaffError,
} from './actions';

const addActionPlanForStaffRequest = async item => {
  return axios.post(`${Config.apiServerHost}/api/ActionPlanForStaff`, item, await authHeader());
};

const updateActionPlanForStaffRequest = async item => {
  return axios.put(`${Config.apiServerHost}/api/ActionPlanForStaff`, item, await authHeader());
};

const getPagingActionPlanForStaffRequest = async item => {
  return axios.post(
    `${Config.apiServerHost}/api/ActionPlanForStaff/paging`,
    item,
    await authHeader()
  );
};

const getActionPlanForStaffByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/ActionPlanForStaff/${id}`, await authHeader());
};

const changeStatusActionPlanForStaffRequest = async item => {
  return axios.get(`${Config.apiServerHost}/api/ActionPlanForStaff/statusChange`, {
    params: item,
    headers: { ...(await authHeader()).headers },
  });
};

const deleteActionPlanForStaffRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/ActionPlanForStaff/${id}`, await authHeader());
};

function* addNewActionPlanForStaff({ payload }) {
  try {
    const { data, userIds, status, taskCategory } = payload;
    let response = yield call(addActionPlanForStaffRequest, data);
    yield put(addActionPlanForStaffSuccess(response.data));

    response = yield call(getPagingActionPlanForStaffRequest, { userIds, status, taskCategory });
    yield put(getPagingActionPlanForStaffSuccess(response.data));
  } catch (error) {
    yield put(addActionPlanForStaffError(error.response.statusText));
  }
}

function* updateActionPlanForStaff({ payload }) {
  try {
    const { data, userIds, status, taskCategory } = payload;
    let response = yield call(updateActionPlanForStaffRequest, data);
    yield put(updateActionPlanForStaffSuccess(response.data));

    response = yield call(getPagingActionPlanForStaffRequest, { userIds, status, taskCategory });
    yield put(getPagingActionPlanForStaffSuccess(response.data));
  } catch (error) {
    yield put(updateActionPlanForStaffError(error.response.statusText));
  }
}

function* getPagingActionPlanForStaff({ payload }) {
  try {
    let response = yield call(getPagingActionPlanForStaffRequest, payload);
    yield put(getPagingActionPlanForStaffSuccess(response.data));
  } catch (error) {
    yield put(getPagingActionPlanForStaffError(error.response.statusText));
  }
}

function* getActionPlanForStaffById({ payload }) {
  try {
    let response = yield call(getActionPlanForStaffByIdRequest, payload);
    yield put(getActionPlanForStaffByIdSuccess(response.data));
  } catch (error) {
    yield put(getActionPlanForStaffByIdError(error.response.statusText));
  }
}

function* changeStatusActionPlanForStaff({ payload }) {
  try {
    const { data, userIds, status, taskCategory } = payload;
    let response = yield call(changeStatusActionPlanForStaffRequest, data);

    response = yield call(getPagingActionPlanForStaffRequest, { userIds, status, taskCategory });
    yield put(getPagingActionPlanForStaffSuccess(response.data));
  } catch (error) {
    yield put(getPagingActionPlanForStaffError(error.response.statusText));
  }
}

function* deleteActionPlanForStaff({ payload }) {
  try {
    const { data, userIds, status, taskCategory } = payload;
    let response = yield call(deleteActionPlanForStaffRequest, data, status, taskCategory);

    response = yield call(getPagingActionPlanForStaffRequest, { userIds });
    yield put(getPagingActionPlanForStaffSuccess(response.data));
  } catch (error) {
    yield put(getPagingActionPlanForStaffError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(ACTION_PLAN_FOR_STAFF_ADD_NEW, addNewActionPlanForStaff);
  yield takeEvery(ACTION_PLAN_FOR_STAFF_UPDATE, updateActionPlanForStaff);
  yield takeEvery(ACTION_PLAN_FOR_STAFF_GET_PAGING, getPagingActionPlanForStaff);
  yield takeEvery(ACTION_PLAN_FOR_STAFF_GET_BY_ID, getActionPlanForStaffById);
  yield takeEvery(ACTION_PLAN_FOR_STAFF_CHANGE_STATUS, changeStatusActionPlanForStaff);
  yield takeEvery(ACTION_PLAN_FOR_STAFF_DELETE, deleteActionPlanForStaff);
}
