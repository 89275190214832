import {
  CASESUMMARY_GET_LIST,
  CASESUMMARY_GET_LIST_ERROR,
  CASESUMMARY_GET_LIST_SUCCESS,
  JOB_TRACKING_GET_LIST,
  JOB_TRACKING_GET_LIST_SUCCESS,
  JOB_TRACKING_GET_LIST_ERROR,
  JOB_TRACKING_ADD_NEW,
  JOB_TRACKING_ADD_NEW_SUCCESS,
  JOB_TRACKING_ADD_NEW_ERROR,
  JOB_TRACKING_DELETE,
  JOB_TRACKING_DELETE_SUCCESS,
  JOB_TRACKING_DELETE_ERROR,
  JOB_TRACKING_GET_BY_ID,
  JOB_TRACKING_GET_BY_ID_SUCCESS,
  JOB_TRACKING_GET_BY_ID_ERROR,
  JOB_TRACKING_EDIT,
  JOB_TRACKING_EDIT_SUCCESS,
  JOB_TRACKING_EDIT_ERROR,
  JOB_TRACKING_GET_LIST_BY_CLIENT,
  JOB_TRACKING_GET_LIST_BY_CLIENT_SUCCESS,
  JOB_TRACKING_GET_LIST_BY_CLIENT_ERROR,
} from '../actions';

const initialState = {
  jobTrackings: [],
  jobTracking: null,
  loading: false,
  error: null,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case JOB_TRACKING_GET_BY_ID:
      return {
        ...state,
        loading: false,
        error: null,
        editSuccess: false,
      };
    case JOB_TRACKING_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: true,
        jobTracking: payload,
      };
    case JOB_TRACKING_GET_LIST_BY_CLIENT:
    case JOB_TRACKING_GET_LIST:
    case CASESUMMARY_GET_LIST:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case JOB_TRACKING_GET_LIST_BY_CLIENT_SUCCESS:
    case CASESUMMARY_GET_LIST_SUCCESS:
      return {
        ...state,
        jobTrackings: payload,
        loading: true,
      };

    case JOB_TRACKING_GET_LIST_ERROR:
    case CASESUMMARY_GET_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case JOB_TRACKING_GET_LIST_SUCCESS:
      return {
        ...state,
        jobTrackings: payload,
        loading: true,
      };

    case JOB_TRACKING_ADD_NEW:
      return {
        ...state,
        loading: true,
        error: null,
        addSuccess: false,
      };

    case JOB_TRACKING_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: true,
        jobTracking: payload,
        jobTrackings: state.jobTrackings.concat(payload),
        addSuccess: true,
      };

    case JOB_TRACKING_DELETE:
      return {
        ...state,
        loading: false,
        error: null,
        deleteSuccess: false,
      };
    case JOB_TRACKING_DELETE_SUCCESS:
      return {
        ...state,
        loading: true,
        jobTrackings: state.jobTrackings.filter(c => c.id !== payload),
        deleteSuccess: true,
      };

    case JOB_TRACKING_EDIT:
      return {
        ...state,
        loading: false,
        error: null,
        editSuccess: false,
      };
    case JOB_TRACKING_EDIT_SUCCESS:
      return {
        ...state,
        jobTrackings: state.jobTrackings.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        loading: true,
        jobTracking: payload,
        error: null,
        editSuccess: true,
      };
    case JOB_TRACKING_GET_LIST_BY_CLIENT_ERROR:
    case JOB_TRACKING_EDIT_ERROR:
    case JOB_TRACKING_GET_BY_ID_ERROR:
    case JOB_TRACKING_DELETE_ERROR:
    case JOB_TRACKING_ADD_NEW_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
