import {
  ADDITIONAL_INFO_ADD_NEW,
  ADDITIONAL_INFO_ADD_NEW_SUCCESS,
  ADDITIONAL_INFO_ADD_NEW_ERROR,
  ADDITIONAL_INFO_EDIT,
  ADDITIONAL_INFO_EDIT_ERROR,
  ADDITIONAL_INFO_EDIT_SUCCESS,
  ADDITIONAL_INFO_GET_BY_ID,
  ADDITIONAL_INFO_GET_BY_ID_ERROR,
  ADDITIONAL_INFO_GET_BY_ID_SUCCESS,
  ADDITIONAL_INFO_GET_LIST,
  ADDITIONAL_INFO_GET_LIST_ERROR,
  ADDITIONAL_INFO_GET_LIST_SUCCESS,
  ADDITIONAL_INFO_ADD_NEW_REQUEST,
  ADDITIONAL_INFO_CLOSED_NOTIFICATION_UPDATE,
} from '../actions';
// AdditionalInfo

export const getAdditionalInfoList = query => ({
  type: ADDITIONAL_INFO_GET_LIST,
  payload: query,
});

export const getAdditionalInfoListSuccess = items => ({
  type: ADDITIONAL_INFO_GET_LIST_SUCCESS,
  payload: items,
});

export const getAdditionalInfoListError = error => ({
  type: ADDITIONAL_INFO_GET_LIST_ERROR,
  payload: error,
});

export const addNewAdditionalInfo = item => ({
  type: ADDITIONAL_INFO_ADD_NEW,
  payload: item,
});

export const addNewAdditionalInfoRequest = () => ({
  type: ADDITIONAL_INFO_ADD_NEW_REQUEST,
});

export const addNewAdditionalInfoSuccess = item => ({
  type: ADDITIONAL_INFO_ADD_NEW_SUCCESS,
  payload: item,
});

export const addNewAdditionalInfoError = error => ({
  type: ADDITIONAL_INFO_ADD_NEW_ERROR,
  payload: error,
});

export const editAdditionalInfo = item => ({
  type: ADDITIONAL_INFO_EDIT,
  payload: item,
});

export const editAdditionalInfoSuccess = item => ({
  type: ADDITIONAL_INFO_EDIT_SUCCESS,
  payload: item,
});

export const editAdditionalInfoError = error => ({
  type: ADDITIONAL_INFO_EDIT_ERROR,
  payload: error,
});

export const getAdditionalInfoById = id => ({
  type: ADDITIONAL_INFO_GET_BY_ID,
  payload: id,
});

export const getAdditionalInfoByIdSuccess = item => ({
  type: ADDITIONAL_INFO_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getAdditionalInfoByIdError = error => ({
  type: ADDITIONAL_INFO_GET_BY_ID_ERROR,
  payload: error,
});

export const closeAdditionalInfoUpdateNotification = query => ({
  type: ADDITIONAL_INFO_CLOSED_NOTIFICATION_UPDATE,
  payload: query,
});
