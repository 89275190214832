import {
  CONTACT_ADD_NEW,
  CONTACT_ADD_NEW_SUCCESS,
  CONTACT_ADD_NEW_ERROR,
  CONTACT_DELETE,
  CONTACT_DELETE_ERROR,
  CONTACT_DELETE_SUCCESS,
  CONTACT_EDIT,
  CONTACT_EDIT_ERROR,
  CONTACT_EDIT_SUCCESS,
  CONTACT_GET_BY_ID,
  CONTACT_GET_BY_ID_ERROR,
  CONTACT_GET_BY_ID_SUCCESS,
  CONTACT_GET_LIST,
  CONTACT_GET_LIST_ERROR,
  CONTACT_GET_LIST_SUCCESS,
  CONTACT_ADD_NEW_REQUEST,
  CONTACT_QUERY,
  CONTACT_QUERY_SUCCESS,
  CONTACT_QUERY_ERROR,
} from '../actions';

// contact
const initialState = {
  contacts: [],
  contact: null,
  loading: false,
  error: null,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CONTACT_GET_BY_ID:
      return {
        ...state,
        loading: false,
        error: null,
        editSuccess: false,
      };
    case CONTACT_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: true,
        contact: payload,
      };

    case CONTACT_ADD_NEW_REQUEST:
      return {
        ...state,
        addSuccess: false,
      };

    case CONTACT_QUERY:
    case CONTACT_GET_LIST:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case CONTACT_QUERY_SUCCESS:
    case CONTACT_GET_LIST_SUCCESS:
      return {
        ...state,
        contacts: payload,
        loading: true,
      };
    case CONTACT_ADD_NEW:
      return {
        ...state,
        loading: false,
        error: null,
        addSuccess: false,
      };

    case CONTACT_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: true,
        contact: payload,
        contacts: state.contacts.concat(payload),
        addSuccess: true,
      };

    case CONTACT_EDIT:
      return {
        ...state,
        loading: false,
        error: null,
        editSuccess: false,
      };
    case CONTACT_EDIT_SUCCESS:
      return {
        ...state,
        loading: true,
        contacts: state.contacts.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        contact: payload,
        error: null,
        editSuccess: true,
      };
    case CONTACT_DELETE:
      return {
        ...state,
        loading: false,
        error: null,
        deleteSuccess: false,
      };
    case CONTACT_DELETE_SUCCESS:
      return {
        ...state,
        loading: true,
        contacts: state.contacts.filter(c => c.id !== payload),
        deleteSuccess: true,
      };
    case CONTACT_QUERY_ERROR:
    case CONTACT_DELETE_ERROR:
    case CONTACT_ADD_NEW_ERROR:
    case CONTACT_EDIT_ERROR:
    case CONTACT_GET_BY_ID_ERROR:
    case CONTACT_GET_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
