import {
  getBackgroundServerBaseDomain,
  hasClientServices,
  getExportBaseDomain,
} from '../helpers/Utils';

export default class Config {
  static get apiServerHost() {
    const hostname = window.location.hostname;
    let apiHost = hostname;
    if (hostname.indexOf('local') >= 0) {
      apiHost = 'localhost:5001';
    }
    if (apiHost.charAt(apiHost.length - 1) === '/') {
      apiHost = apiHost.substr(0, apiHost.length - 1);
    }
    return `https://${apiHost}`;
  }

  static get apiServiceHost() {
    let backgroundServerBaseDomain = getBackgroundServerBaseDomain();
    const hostname = window.location.hostname;

    let apiHost = hostname;

    if (hostname.indexOf('local') >= 0) {
      apiHost = 'localhost:5001';
    }
    if (apiHost.charAt(apiHost.length - 1) === '/') {
      apiHost = apiHost.substr(0, apiHost.length - 1);
    }

    if (hasClientServices()) {
      apiHost = `${backgroundServerBaseDomain}.escases.ca`;
    }

    return `https://${apiHost}`;
  }

  static get apiServiceHostForExport() {
    const hostname = window.location.hostname;
    let apiHost = hostname;
    if (hostname.indexOf('local') >= 0) {
      apiHost = 'localhost:5001';
      return `https://${apiHost}`;
    } else {
      let exportBaseDomain = getExportBaseDomain();
      return exportBaseDomain;
    }
  }
}
