import {
  NOTIFICATION_REPORT_GET_LIST,
  NOTIFICATION_REPORT_GET_LIST_SUCCESS,
  NOTIFICATION_REPORT_GET_LIST_ERROR,
} from '../../actions';

const INIT_STATE = {
  notificationReports: [],
  notificationReport: [],
  error: '',
  filter: null,
  searchKeyword: '',
  orderColumn: null,
  loading: false,
  categories: ['Flexbox', 'Sass', 'React'],
  selectedItems: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case NOTIFICATION_REPORT_GET_LIST:
      return { ...state, loading: false };

    case NOTIFICATION_REPORT_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        notificationReports: action.payload,
        notificationReport: action.payload,
      };

    case NOTIFICATION_REPORT_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };
    default:
      return { ...state };
  }
};
