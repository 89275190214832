import {
  STAKE_HOLDER_ACTION_ADD_NEW,
  STAKE_HOLDER_ACTION_ADD_NEW_ERROR,
  STAKE_HOLDER_ACTION_ADD_NEW_SUCCESS,
  STAKE_HOLDER_ACTION_GET_BY_ID,
  STAKE_HOLDER_ACTION_GET_BY_ID_ERROR,
  STAKE_HOLDER_ACTION_GET_BY_ID_SUCCESS,
  STAKE_HOLDER_ACTION_GET_PAGING,
  STAKE_HOLDER_ACTION_GET_PAGING_ERROR,
  STAKE_HOLDER_ACTION_GET_PAGING_SUCCESS,
  STAKE_HOLDER_ACTION_UPDATE,
  STAKE_HOLDER_ACTION_UPDATE_ERROR,
  STAKE_HOLDER_ACTION_UPDATE_SUCCESS,
} from './types';
// item
const initialState = {
  item: null,
  items: [],
  loading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
  total: 0,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case STAKE_HOLDER_ACTION_ADD_NEW:
    case STAKE_HOLDER_ACTION_GET_PAGING:
    case STAKE_HOLDER_ACTION_GET_BY_ID:
    case STAKE_HOLDER_ACTION_UPDATE:
      return {
        ...state,
        loading: true,
        error: null,
        item: null,
      };

    case STAKE_HOLDER_ACTION_GET_PAGING_SUCCESS:
      const { itemCount, pageCount, data, pendingItems, overdueItems, completedItems } = payload;
      return {
        ...state,
        loading: false,
        error: null,
        itemCount,
        pageCount,
        items: data,
        pendingItems,
        overdueItems,
        completedItems,
      };

    case STAKE_HOLDER_ACTION_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        // item: payload
      };

    case STAKE_HOLDER_ACTION_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        item: payload,
      };

    case STAKE_HOLDER_ACTION_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        item: payload,
      };

    case STAKE_HOLDER_ACTION_ADD_NEW_ERROR:
    case STAKE_HOLDER_ACTION_GET_PAGING_ERROR:
    case STAKE_HOLDER_ACTION_GET_BY_ID_ERROR:
    case STAKE_HOLDER_ACTION_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
}
