import {
  CASE_NOTE2_ADD_NEW,
  CASE_NOTE2_ADD_NEW_SUCCESS,
  CASE_NOTE2_DELETE,
  CASE_NOTE2_DELETE_SUCCESS,
  CASE_NOTE2_EDIT,
  CASE_NOTE2_EDIT_SUCCESS,
  CASE_NOTE2_GET_BY_ID,
  CASE_NOTE2_GET_BY_ID_SUCCESS,
  CASE_NOTE2_ADD_NEW_REQUEST,
  CASE_NOTE2_QUERY,
  CASE_NOTE2_QUERY_SUCCESS,
  CASE_NOTE2_AGGREGATE,
  CASE_NOTE2_AGGREGATE_SUCCESS,
  EMPLOYER_CASE_NOTE2_EDIT,
  EMPLOYER_CASE_NOTE2_EDIT_SUCCESS,
  EMPLOYER_CASE_NOTE2_GET_BY_ID,
  EMPLOYER_CASE_NOTE2_GET_BY_ID_SUCCESS,
  CASE_NOTE2_NOTIFICATION_CLOSE,
  CASE_NOTE2_TOGGLE_MODAL_ADD,
  CASE_NOTE2_TOGGLE_MODAL_EDIT,
  CASE_NOTE2_TOGGLE_MODAL_DELETE,
  CASE_NOTE2_ERROR,
  CASE_NOTE2_TOGGLE_MODAL_DELETE_EMP,
  CASE_NOTE2_TOGGLE_MODAL_EDIT_EMP,
} from '../actions';

import { displayMessage, SEVERITY } from '../../helpers/Utils';
import IntlMessages from '../../helpers/IntlMessages';

const initialState = {
  caseNoteAggregate: null,
  caseNotes: [],
  caseNote: null,
  loading: false,
  error: null,
  saving: false,
  modalAddNewIsOpen: false,
  modalEditIsOpen: false,
  modalEditEmpIsOpen: false,
  modalDeleteIsOpen: false,
  modalDeleteEmpIsOpen: false,
};

export default function reducerState(state = initialState, action) {
  const { type, payload } = action;
  state = { ...state };

  switch (type) {
    case CASE_NOTE2_GET_BY_ID:
    case EMPLOYER_CASE_NOTE2_GET_BY_ID:
      return {
        ...state,
        loading: true,
        caseNote: null,
        error: null,
        modalEditIsOpen: true,
      };
    case CASE_NOTE2_GET_BY_ID_SUCCESS:
    case EMPLOYER_CASE_NOTE2_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        caseNote: payload,
      };

    case CASE_NOTE2_ADD_NEW_REQUEST:
      return {
        ...state,
        error: null,
        modalAddNewIsOpen: true,
      };

    case CASE_NOTE2_AGGREGATE:
    case CASE_NOTE2_QUERY:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case CASE_NOTE2_AGGREGATE_SUCCESS:
      return {
        ...state,
        caseNoteAggregate: payload,
        loading: false,
      };
    case CASE_NOTE2_QUERY_SUCCESS:
      return {
        ...state,
        caseNotes: payload,
        loading: false,
      };
    case CASE_NOTE2_ADD_NEW:
      return {
        ...state,
        loading: true,
        error: null,
        saving: true,
      };

    case CASE_NOTE2_ADD_NEW_SUCCESS:
      displayMessage({
        title: <IntlMessages id="pages.case-note" />,
        message: <IntlMessages id="case-note.add_success_status" />,
        severity: SEVERITY.INFO,
      });
      return {
        ...state,
        loading: false,
        modalAddNewIsOpen: false,
        saving: false,
      };

    case EMPLOYER_CASE_NOTE2_EDIT:
    case CASE_NOTE2_EDIT:
      return {
        ...state,
        loading: true,
        error: null,
        saving: true,
      };
    case EMPLOYER_CASE_NOTE2_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        caseNotes: payload,
        error: null,
        modalEditEmpIsOpen: false,
      };
    case CASE_NOTE2_EDIT_SUCCESS:
      displayMessage({
        title: <IntlMessages id="pages.case-note" />,
        message: <IntlMessages id="case-note.update_success_status" />,
        severity: SEVERITY.INFO,
      });

      return {
        ...state,
        loading: false,
        error: null,
        modalEditIsOpen: false,
        saving: false,
      };
    case CASE_NOTE2_DELETE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CASE_NOTE2_DELETE_SUCCESS:
      displayMessage({
        title: <IntlMessages id="pages.case-note" />,
        message: <IntlMessages id="case-note.delete_success_status" />,
        severity: SEVERITY.INFO,
      });
      return {
        ...state,
        loading: false,
        modalDeleteIsOpen: false,
      };
    case CASE_NOTE2_NOTIFICATION_CLOSE:
      return {
        ...state,
        error: null,
      };
    case CASE_NOTE2_TOGGLE_MODAL_ADD:
      return {
        ...state,
        modalAddNewIsOpen: !state.modalAddNewIsOpen,
      };

    case CASE_NOTE2_TOGGLE_MODAL_EDIT:
      return {
        ...state,
        modalEditIsOpen: !state.modalEditIsOpen,
      };

    case CASE_NOTE2_TOGGLE_MODAL_EDIT_EMP:
      return {
        ...state,
        modalEditEmpIsOpen: !state.modalEditEmpIsOpen,
      };

    case CASE_NOTE2_TOGGLE_MODAL_DELETE:
      return {
        ...state,
        modalDeleteIsOpen: !state.modalDeleteIsOpen,
      };

    case CASE_NOTE2_TOGGLE_MODAL_DELETE_EMP:
      return {
        ...state,
        modalDeleteEmpIsOpen: !state.modalDeleteEmpIsOpen,
      };

    case CASE_NOTE2_ERROR:
      displayMessage({
        title: 'Case Note',
        message: payload,
        severity: SEVERITY.DANGER,
      });

      return {
        ...state,
        loading: false,
        caseNote: null,
        error: payload,
        saving: false,
      };

    default:
      return { ...state };
  }
}
