import {
  MILEAGE_ADD_NEW,
  MILEAGE_ADD_NEW_SUCCESS,
  MILEAGE_ADD_NEW_ERROR,
  MILEAGE_DELETE,
  MILEAGE_DELETE_ERROR,
  MILEAGE_DELETE_SUCCESS,
  MILEAGE_EDIT,
  MILEAGE_EDIT_ERROR,
  MILEAGE_EDIT_SUCCESS,
  MILEAGE_GET_BY_ID,
  MILEAGE_GET_BY_ID_ERROR,
  MILEAGE_GET_BY_ID_SUCCESS,
  MILEAGE_GET_LIST,
  MILEAGE_GET_LIST_SUCCESS,
  MILEAGE_GET_LIST_ERROR,
  MILEAGE_GET_LIST_BY_USER_ID,
  MILEAGE_GET_LIST_BY_USER_ID_SUCCESS,
  MILEAGE_GET_LIST_BY_USER_ID_ERROR,
  MILEAGE_GET_LIST_BY_STATUS,
  MILEAGE_GET_LIST_BY_STATUS_SUCCESS,
  MILEAGE_GET_LIST_BY_STATUS_ERROR,
  MILEAGE_SUBMIT,
  MILEAGE_SUBMIT_SUCCESS,
  MILEAGE_SUBMIT_ERROR,
  MILEAGE_APPROVE_SUBMIT,
  MILEAGE_APPROVE_SUBMIT_SUCCESS,
  MILEAGE_APPROVE_SUBMIT_ERROR,
  MILEAGE_ADD_NEW_REQUEST,
  MILEAGE_GET_BY_QUERY,
  MILEAGE_GET_BY_QUERY_SUCCESS,
  MILEAGE_GET_BY_QUERY_ERROR,
  MILEAGE_PREVIOUS_SUBMISSION,
  MILEAGE_PREVIOUS_SUBMISSION_SUCCESS,
  MILEAGE_PREVIOUS_SUBMISSION_ERROR,
  MILEAGE_PAID_OR_UNPAID,
  MILEAGE_PAID_OR_UNPAID_SUCCESS,
  MILEAGE_PAID_OR_UNPAID_ERROR,
  MILEAGE_REJECT_SUBMIT,
  MILEAGE_REJECT_SUBMIT_SUCCESS,
  MILEAGE_REJECT_SUBMIT_ERROR,
} from '../actions';

export const getMileagesByQuery = query => ({
  type: MILEAGE_GET_BY_QUERY,
  payload: query,
});

export const getMileagesByQuerySuccess = mileages => ({
  type: MILEAGE_GET_BY_QUERY_SUCCESS,
  payload: mileages,
});

export const getMileagesByQueryError = error => ({
  type: MILEAGE_GET_BY_QUERY_ERROR,
  payload: error,
});

export const getMileageList = (role, userId) => ({
  type: MILEAGE_GET_LIST,
  payload: { role, userId },
});

export const getMileageListSuccess = mileages => ({
  type: MILEAGE_GET_LIST_SUCCESS,
  payload: mileages,
});

export const getMileageListError = error => ({
  type: MILEAGE_GET_LIST_ERROR,
  payload: error,
});

export const getMileageListByUserId = (role, userId) => ({
  type: MILEAGE_GET_LIST_BY_USER_ID,
  payload: { role, userId },
});

export const getMileageListByUserIdSuccess = mileages => ({
  type: MILEAGE_GET_LIST_BY_USER_ID_SUCCESS,
  payload: mileages,
});

export const getMileageListByUserIdError = error => ({
  type: MILEAGE_GET_LIST_BY_USER_ID_ERROR,
  payload: error,
});

export const getMileageListByStatus = param => ({
  type: MILEAGE_GET_LIST_BY_STATUS,
  payload: param,
});

export const getMileageListByStatusSuccess = mileages => ({
  type: MILEAGE_GET_LIST_BY_STATUS_SUCCESS,
  payload: mileages,
});

export const getMileageListByStatusError = error => ({
  type: MILEAGE_GET_LIST_BY_STATUS_ERROR,
  payload: error,
});

export const addNewMileage = (mileage, history) => ({
  type: MILEAGE_ADD_NEW,
  payload: { mileage, history },
});

export const addNewMileageRequest = () => ({
  type: MILEAGE_ADD_NEW_REQUEST,
});

export const addNewMileageSuccess = mileage => ({
  type: MILEAGE_ADD_NEW_SUCCESS,
  payload: mileage,
});

export const addNewMileageError = error => ({
  type: MILEAGE_ADD_NEW_ERROR,
  payload: error,
});

export const editMileage = (mileage, history) => ({
  type: MILEAGE_EDIT,
  payload: { mileage, history },
});

export const editMileageSuccess = mileage => ({
  type: MILEAGE_EDIT_SUCCESS,
  payload: mileage,
});

export const editMileageError = error => ({
  type: MILEAGE_EDIT_ERROR,
  payload: error,
});

export const deleteMileage = id => ({
  type: MILEAGE_DELETE,
  payload: id,
});

export const deleteMileageSuccess = id => ({
  type: MILEAGE_DELETE_SUCCESS,
  payload: id,
});

export const deleteMileageError = error => ({
  type: MILEAGE_DELETE_ERROR,
  payload: error,
});

export const getMileageById = id => ({
  type: MILEAGE_GET_BY_ID,
  payload: id,
});

export const getMileageByIdSuccess = mileage => ({
  type: MILEAGE_GET_BY_ID_SUCCESS,
  payload: mileage,
});

export const getMileageByIdError = error => ({
  type: MILEAGE_GET_BY_ID_ERROR,
  payload: error,
});

export const submitMileage = data => ({
  type: MILEAGE_SUBMIT,
  payload: data,
});

export const submitMileageSuccess = mileage => ({
  type: MILEAGE_SUBMIT_SUCCESS,
  payload: mileage,
});

export const submitMileageError = error => ({
  type: MILEAGE_SUBMIT_ERROR,
  payload: error,
});

export const submitApproveMileage = data => ({
  type: MILEAGE_APPROVE_SUBMIT,
  payload: data,
});

export const submitApproveMileageSuccess = mileage => ({
  type: MILEAGE_APPROVE_SUBMIT_SUCCESS,
  payload: mileage,
});

export const submitApproveMileageError = error => ({
  type: MILEAGE_APPROVE_SUBMIT_ERROR,
  payload: error,
});

export const submitRejectMileage = data => ({
  type: MILEAGE_REJECT_SUBMIT,
  payload: data,
});

export const submitRejectMileageSuccess = mileage => ({
  type: MILEAGE_REJECT_SUBMIT_SUCCESS,
  payload: mileage,
});

export const submitRejectMileageError = error => ({
  type: MILEAGE_REJECT_SUBMIT_ERROR,
  payload: error,
});

export const getPreviousSubmission = userId => ({
  type: MILEAGE_PREVIOUS_SUBMISSION,
  payload: userId,
});

export const getPreviousSubmissionSuccess = user => ({
  type: MILEAGE_PREVIOUS_SUBMISSION_SUCCESS,
  payload: user,
});

export const getPreviousSubmissionError = error => ({
  type: MILEAGE_PREVIOUS_SUBMISSION_ERROR,
  payload: error,
});

export const mileagePaidOrUnpaidSubmission = (id, paid) => ({
  type: MILEAGE_PAID_OR_UNPAID,
  payload: { id, paid },
});

export const mileagePaidOrUnpaidSubmissionSuccess = data => ({
  type: MILEAGE_PAID_OR_UNPAID_SUCCESS,
  payload: data,
});

export const mileagePaidOrUnpaidSubmissionError = error => ({
  type: MILEAGE_PAID_OR_UNPAID_ERROR,
  payload: error,
});
