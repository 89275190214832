import {
  SUB_GOAL_LBS_ADD_NEW,
  SUB_GOAL_LBS_ADD_NEW_ERROR,
  SUB_GOAL_LBS_BY_CLIENT,
  SUB_GOAL_LBS_BY_CLIENT_SUCCESS,
  SUB_GOAL_LBS_BY_CLIENT_ERROR,
} from './types';

// items
const initialState = {
  items: [],
  item: null,
  loading: false,
  error: null,
};

export default function reducerState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SUB_GOAL_LBS_BY_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
      };

    case SUB_GOAL_LBS_BY_CLIENT:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUB_GOAL_LBS_ADD_NEW:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SUB_GOAL_LBS_ADD_NEW_ERROR:
    case SUB_GOAL_LBS_BY_CLIENT_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
}
