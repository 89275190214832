import axios from 'axios';
import FileSaver from 'file-saver';
import { authHeader } from '../../helpers/auth-header';

import { call, put, takeEvery } from 'redux-saga/effects';
import { GET_JOB_SEEKER_RETAINED_BY_EMPLOYER_DETAIL_BY_QUERY } from './types';
import Config from '../../config';

import {
  getJobSeekerRetainedByEmployerSuccess,
  getJobSeekerRetainedByEmployerError,
} from './actions';

const getJobSeekerRetainedByEmployerRequest = async payload => {
  return axios.post(`${Config.apiServiceHost}/api/Dashboard/jobSeekerRetainedByEmployer`, payload, {
    headers: { ...(await authHeader()).headers },
  });
};

const exportIESClientReportToExcelReqeust = async (query, fileName) => {
  return axios
    .post(`${Config.apiServerHost}/api/Dashboard/exportIesChartDetailReportToExcel`, query, {
      headers: { ...(await authHeader()).headers },
      responseType: 'blob',
    })
    .then(res => {
      if (res) {
        const { data } = res;
        FileSaver.saveAs(new Blob([data]), fileName);
      }
    });
};

function* getJobSeekerRetainedByEmployer({ payload }) {
  try {
    const response = yield call(getJobSeekerRetainedByEmployerRequest, payload);
    yield put(getJobSeekerRetainedByEmployerSuccess(response.data));
  } catch (error) {
    yield put(getJobSeekerRetainedByEmployerError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(
    GET_JOB_SEEKER_RETAINED_BY_EMPLOYER_DETAIL_BY_QUERY,
    getJobSeekerRetainedByEmployer
  );
}

export { exportIESClientReportToExcelReqeust };
