import {
  CAMS_IMPORT_ADD_NEW,
  CAMS_IMPORT_ADD_NEW_SUCCESS,
  CAMS_IMPORT_ADD_NEW_ERROR,
  CAMS_IMPORT_DELETE,
  CAMS_IMPORT_DELETE_ERROR,
  CAMS_IMPORT_DELETE_SUCCESS,
  CAMS_IMPORT_EDIT,
  CAMS_IMPORT_EDIT_ERROR,
  CAMS_IMPORT_EDIT_SUCCESS,
  CAMS_IMPORT_GET_BY_ID,
  CAMS_IMPORT_GET_BY_ID_ERROR,
  CAMS_IMPORT_GET_BY_ID_SUCCESS,
  CAMS_IMPORT_GET_LIST,
  CAMS_IMPORT_GET_LIST_ERROR,
  CAMS_IMPORT_GET_LIST_SUCCESS,
  CAMS_IMPORT_ADD_NEW_REQUEST,
  CAMS_IMPORT_READ_FILENAME_ERROR,
  CAMS_IMPORT_READ_FILENAME,
  CAMS_IMPORT_READ_FILENAME_SUCCESS,
  CAMS_IMPORT_CLOSE_NOTIFICATION,
  CAMS_IMPORT_UPDATE_CLIENT,
  CAMS_IMPORT_UPDATE_DFECLT,
  CAMS_IMPORT_UPDATE_DFESPN,
  CAMS_IMPORT_UPDATE_DFFOL,
  CAMS_IMPORT_IMPORT_ALL_CLIENTS,
  CAMS_IMPORT_IMPORT_ALL_CLIENTS_SUCCESS,
  CAMS_IMPORT_IMPORT_ALL_CLIENTS_ERROR,
  CAMS_IMPORT_FILTER_CLIENT_DATA,
  CAMS_IMPORT_IMPORT_DFECLT,
  CAMS_IMPORT_IMPORT_DFECLT_ERROR,
  CAMS_IMPORT_IMPORT_DFECLT_SUCCESS,
  CAMS_IMPORT_FILTER_DFECLT_DATA,
  CAMS_SYNC_DATA_COJG_DFTCLT_CLIENTS,
  CAMS_SYNC_DATA_COJG_DFTCLT_CLIENTS_SUCCESS,
  CAMS_SYNC_DATA_COJG_DFTCLT_CLIENTS_ERROR,
  CAMS_IMPORT_IMPORT_DFESPN,
  CAMS_IMPORT_IMPORT_DFESPN_SUCCESS,
  CAMS_IMPORT_IMPORT_DFESPN_ERROR,
  CAMS_SYNC_DATA_COJG_DFTSPN,
  CAMS_SYNC_DATA_COJG_DFTSPN_SUCCESS,
  CAMS_SYNC_DATA_COJG_DFTSPN_ERROR,
  CAMS_IMPORT_IMPORT_DFFOL,
  CAMS_IMPORT_IMPORT_DFFOL_SUCCESS,
  CAMS_IMPORT_IMPORT_DFFOL_ERROR,
  CAMS_IMPORT_IMPORT_ESDFRIP,
  CAMS_IMPORT_IMPORT_ESDFRIP_SUCCESS,
  CAMS_IMPORT_IMPORT_ESDFRIP_ERROR,
  CAMS_IMPORT_UPDATE_ESDFRIP,
  CAMS_SYNC_DATA_ES_DFSPN,
  CAMS_SYNC_DATA_ES_DFSPN_SUCCESS,
  CAMS_SYNC_DATA_ES_DFSPN_ERROR,
  CAMS_IMPORT_IMPORT_ESDFRIC,
  CAMS_IMPORT_IMPORT_ESDFRIC_SUCCESS,
  CAMS_IMPORT_IMPORT_ESDFRIC_ERROR,
  CAMS_IMPORT_UPDATE_ESDFRIC,
  CAMS_SYNC_DATA_ES_DFSPI,
  CAMS_SYNC_DATA_ES_DFSPI_SUCCESS,
  CAMS_SYNC_DATA_ES_DFSPI_ERROR,
  CAMS_IMPORT_UPDATE_ESDFSPI,
  CAMS_SYNC_DATA_ES_DFRIS,
  CAMS_SYNC_DATA_ES_DFRIS_SUCCESS,
  CAMS_SYNC_DATA_ES_DFRIS_ERROR,
  CAMS_IMPORT_DETAIL,
  CAMS_IMPORT_DETAIL_SUCCESS,
  CAMS_IMPORT_DETAIL_ERROR,
  CAMS_IMPORT_GET_CLIENT_LIST_BY_PARAMS,
  CAMS_IMPORT_GET_CLIENT_LIST_BY_PARAMS_SUCCESS,
  CAMS_IMPORT_GET_CLIENT_LIST_BY_PARAMS_ERROR,
  CAMS_IMPORT_GET_EMPLOYER_LIST_BY_PARAMS,
  CAMS_IMPORT_GET_EMPLOYER_LIST_BY_PARAMS_SUCCESS,
  CAMS_IMPORT_GET_EMPLOYER_LIST_BY_PARAMS_ERROR,
} from '../actions';

export const importCaMSESDFRIC = data => ({
  type: CAMS_IMPORT_IMPORT_ESDFRIC,
  payload: data,
});

export const importCaMSESDFRICSuccess = data => ({
  type: CAMS_IMPORT_IMPORT_ESDFRIC_SUCCESS,
  payload: data,
});

export const importCaMSESDFRICError = error => ({
  type: CAMS_IMPORT_IMPORT_ESDFRIC_ERROR,
  payload: error,
});

export const importCaMSESDFRIP = data => ({
  type: CAMS_IMPORT_IMPORT_ESDFRIP,
  payload: data,
});

export const importCaMSESDFRIPSuccess = data => ({
  type: CAMS_IMPORT_IMPORT_ESDFRIP_SUCCESS,
  payload: data,
});

export const importCaMSESDFRIPError = error => ({
  type: CAMS_IMPORT_IMPORT_ESDFRIP_ERROR,
  payload: error,
});

export const importCaMSDFFOL = data => ({
  type: CAMS_IMPORT_IMPORT_DFFOL,
  payload: data,
});

export const importCaMSDFFOLSuccess = data => ({
  type: CAMS_IMPORT_IMPORT_DFFOL_SUCCESS,
  payload: data,
});

export const importCaMSDFFOLError = error => ({
  type: CAMS_IMPORT_IMPORT_DFFOL_ERROR,
  payload: error,
});

export const importCaMSDFESPN = data => ({
  type: CAMS_IMPORT_IMPORT_DFESPN,
  payload: data,
});

export const importCaMSDFESPNSuccess = data => ({
  type: CAMS_IMPORT_IMPORT_DFESPN_SUCCESS,
  payload: data,
});

export const importCaMSDFESPNError = error => ({
  type: CAMS_IMPORT_IMPORT_DFESPN_ERROR,
  payload: error,
});

export const importCaMSDFECLT = data => ({
  type: CAMS_IMPORT_IMPORT_DFECLT,
  payload: data,
});

export const importCaMSDFECLTSuccess = data => ({
  type: CAMS_IMPORT_IMPORT_DFECLT_SUCCESS,
  payload: data,
});

export const importCaMSDFECLTError = error => ({
  type: CAMS_IMPORT_IMPORT_DFECLT_ERROR,
  payload: error,
});

export const camsImportFilterDFECLTData = filter => ({
  type: CAMS_IMPORT_FILTER_DFECLT_DATA,
  payload: filter,
});

export const camsImportFilterClientData = filter => ({
  type: CAMS_IMPORT_FILTER_CLIENT_DATA,
  payload: filter,
});

export const camsImportCloseNotification = () => ({
  type: CAMS_IMPORT_CLOSE_NOTIFICATION,
});

export const importCaMSClients = clients => ({
  type: CAMS_IMPORT_IMPORT_ALL_CLIENTS,
  payload: clients,
});

export const importCaMSClientsSuccess = data => ({
  type: CAMS_IMPORT_IMPORT_ALL_CLIENTS_SUCCESS,
  payload: data,
});

export const importCaMSClientsError = error => ({
  type: CAMS_IMPORT_IMPORT_ALL_CLIENTS_ERROR,
  payload: error,
});

export const syncDataFromCOJGDFTCLTToClient = clients => ({
  type: CAMS_SYNC_DATA_COJG_DFTCLT_CLIENTS,
  payload: clients,
});

export const syncDataFromCOJGDFTCLTToClientSuccess = data => ({
  type: CAMS_SYNC_DATA_COJG_DFTCLT_CLIENTS_SUCCESS,
  payload: data,
});

export const syncDataFromCOJGDFTCLTToClientError = error => ({
  type: CAMS_SYNC_DATA_COJG_DFTCLT_CLIENTS_ERROR,
  payload: error,
});

export const syncDataDFTSPN = clients => ({
  type: CAMS_SYNC_DATA_COJG_DFTSPN,
  payload: clients,
});

export const syncDataDFTSPNSuccess = data => ({
  type: CAMS_SYNC_DATA_COJG_DFTSPN_SUCCESS,
  payload: data,
});

export const syncDataDFTSPNError = error => ({
  type: CAMS_SYNC_DATA_COJG_DFTSPN_ERROR,
  payload: error,
});

export const syncDataESDFSPN = clients => ({
  type: CAMS_SYNC_DATA_ES_DFSPN,
  payload: clients,
});

export const syncDataESDFSPNSuccess = data => ({
  type: CAMS_SYNC_DATA_ES_DFSPN_SUCCESS,
  payload: data,
});

export const syncDataESDFSPNError = error => ({
  type: CAMS_SYNC_DATA_ES_DFSPN_ERROR,
  payload: error,
});

export const syncDataESDFSPI = data => ({
  type: CAMS_SYNC_DATA_ES_DFSPI,
  payload: data,
});

export const syncDataESDFSPISuccess = data => ({
  type: CAMS_SYNC_DATA_ES_DFSPI_SUCCESS,
  payload: data,
});

export const syncDataESDFSPIError = error => ({
  type: CAMS_SYNC_DATA_ES_DFSPI_ERROR,
  payload: error,
});

export const syncDataESDFRIS = clients => ({
  type: CAMS_SYNC_DATA_ES_DFRIS,
  payload: clients,
});

export const syncDataESDFRISSuccess = data => ({
  type: CAMS_SYNC_DATA_ES_DFRIS_SUCCESS,
  payload: data,
});

export const syncDataESDFRISError = error => ({
  type: CAMS_SYNC_DATA_ES_DFRIS_ERROR,
  payload: error,
});

export const updateClientCaMSImport = client => ({
  type: CAMS_IMPORT_UPDATE_CLIENT,
  payload: client,
});

export const updateDFECLTCaMSImport = dfeclt => ({
  type: CAMS_IMPORT_UPDATE_DFECLT,
  payload: dfeclt,
});

export const updateDFESPNCaMSImport = dfespn => ({
  type: CAMS_IMPORT_UPDATE_DFESPN,
  payload: dfespn,
});

export const updateDFFOLCaMSImport = dffol => ({
  type: CAMS_IMPORT_UPDATE_DFFOL,
  payload: dffol,
});

export const updateESDFRIPCaMSImport = dffol => ({
  type: CAMS_IMPORT_UPDATE_ESDFRIP,
  payload: dffol,
});

export const updateESDFSPICaMSImport = dfspi => ({
  type: CAMS_IMPORT_UPDATE_ESDFSPI,
  payload: dfspi,
});

export const updateESDFRICCaMSImport = dfric => ({
  type: CAMS_IMPORT_UPDATE_ESDFRIC,
  payload: dfric,
});

export const readDataFromCaMSImportFile = params => ({
  type: CAMS_IMPORT_READ_FILENAME,
  payload: params,
});

export const readDataFromCaMSImportFileSuccess = data => ({
  type: CAMS_IMPORT_READ_FILENAME_SUCCESS,
  payload: data,
});

export const readDataFromCaMSImportFileError = error => ({
  type: CAMS_IMPORT_READ_FILENAME_ERROR,
  payload: error,
});

export const getCaMSImportList = () => ({
  type: CAMS_IMPORT_GET_LIST,
});

export const getCaMSImportListSuccess = camsImports => ({
  type: CAMS_IMPORT_GET_LIST_SUCCESS,
  payload: camsImports,
});

export const getCaMSImportListError = error => ({
  type: CAMS_IMPORT_GET_LIST_ERROR,
  payload: error,
});

export const addNewCaMSImport = camsImport => ({
  type: CAMS_IMPORT_ADD_NEW,
  payload: camsImport,
});

export const addNewCaMSImportRequest = () => ({
  type: CAMS_IMPORT_ADD_NEW_REQUEST,
});

export const addNewCaMSImportSuccess = camsImport => ({
  type: CAMS_IMPORT_ADD_NEW_SUCCESS,
  payload: camsImport,
});

export const addNewCaMSImportError = error => ({
  type: CAMS_IMPORT_ADD_NEW_ERROR,
  payload: error,
});

export const editCaMSImport = camsImport => ({
  type: CAMS_IMPORT_EDIT,
  payload: camsImport,
});

export const editCaMSImportSuccess = camsImport => ({
  type: CAMS_IMPORT_EDIT_SUCCESS,
  payload: camsImport,
});

export const editCaMSImportError = error => ({
  type: CAMS_IMPORT_EDIT_ERROR,
  payload: error,
});

export const deleteCaMSImport = id => ({
  type: CAMS_IMPORT_DELETE,
  payload: id,
});

export const deleteCaMSImportSuccess = id => ({
  type: CAMS_IMPORT_DELETE_SUCCESS,
  payload: id,
});

export const deleteCaMSImportError = error => ({
  type: CAMS_IMPORT_DELETE_ERROR,
  payload: error,
});

export const getCaMSImportById = id => ({
  type: CAMS_IMPORT_GET_BY_ID,
  payload: id,
});

export const getCaMSImportByIdSuccess = camsImport => ({
  type: CAMS_IMPORT_GET_BY_ID_SUCCESS,
  payload: camsImport,
});

export const getCaMSImportByIdError = error => ({
  type: CAMS_IMPORT_GET_BY_ID_ERROR,
  payload: error,
});

export const getCaMSImportDetail = id => ({
  type: CAMS_IMPORT_DETAIL,
  payload: id,
});

export const getCaMSImportDetailSuccess = data => ({
  type: CAMS_IMPORT_DETAIL_SUCCESS,
  payload: data,
});

export const getCaMSImportDetailError = error => ({
  type: CAMS_IMPORT_DETAIL_ERROR,
  payload: error,
});

export const getClientsByParam = param => ({
  type: CAMS_IMPORT_GET_CLIENT_LIST_BY_PARAMS,
  payload: param,
});

export const getClientsByParamSuccess = data => ({
  type: CAMS_IMPORT_GET_CLIENT_LIST_BY_PARAMS_SUCCESS,
  payload: data,
});

export const getClientsByParamError = error => ({
  type: CAMS_IMPORT_GET_CLIENT_LIST_BY_PARAMS_ERROR,
  payload: error,
});

export const getEmployersByParam = param => ({
  type: CAMS_IMPORT_GET_EMPLOYER_LIST_BY_PARAMS,
  payload: param,
});

export const getEmployersByParamSuccess = data => ({
  type: CAMS_IMPORT_GET_EMPLOYER_LIST_BY_PARAMS_SUCCESS,
  payload: data,
});

export const getEmployersByParamError = error => ({
  type: CAMS_IMPORT_GET_EMPLOYER_LIST_BY_PARAMS_ERROR,
  payload: error,
});
