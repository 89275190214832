import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import axios from 'axios';
import Config from '../../config';

import {
  IES_CONTRACT_GET_LIST,
  IES_CONTRACT_ADD_ITEM,
  IES_CONTRACT_EDIT_ITEM,
  IES_CONTRACT_DELETE_ITEM,
  IES_CONTRACT_GET_BY_ID,
} from '../actions';

import {
  getIESContractListSuccess,
  getIESContractListError,
  addIESContractItemSuccess,
  addIESContractItemError,
  editIESContractItemSuccess,
  editIESContractItemError,
  deleteIESContractItemSuccess,
  deleteIESContractItemError,
  getIESContractByIdSuccess,
  getIESContractByIdError,
} from './actions';

// const getESContractsRequest = async () => {
//   return await axios.get(`${Config.apiServerHost}/api/ESContract`, await authHeader());
// };

let currentCancelToken;

const getIESContractListRequest = async query => {
  if (currentCancelToken) {
    currentCancelToken.cancel('Operation canceled due to new request.');
  }

  // Create a new CancelToken source
  currentCancelToken = axios.CancelToken.source();

  return await axios.post(`${Config.apiServerHost}/api/ESContract/paging`, query, {
    cancelToken: currentCancelToken.token,
    headers: { ...(await authHeader()).headers },
  });
};

const getIESContractRequest = async id => {
  return await axios.get(`${Config.apiServerHost}/api/ESContract/${id}`, await authHeader());
};

const addNewIESContractRequest = async data => {
  return await axios.post(`${Config.apiServerHost}/api/ESContract`, data, await authHeader());
};

const editIESContractRequest = async data => {
  return await axios.put(`${Config.apiServerHost}/api/ESContract`, data, await authHeader());
};

const deleteIESContractRequest = async id => {
  return await axios.delete(`${Config.apiServerHost}/api/ESContract/${id}`, await authHeader());
};

// const getEmployerIncentiveAndClientSupportRequest = async () => {
//   return await axios.get(
//     `${Config.apiServerHost}/api/ESContract/employerIncentiveAndClientSupport`,
//     await authHeader()
//   );
// };

function* getIESContractById({ payload }) {
  try {
    const response = yield call(getIESContractRequest, payload);
    yield put(getIESContractByIdSuccess(response.data));
  } catch (error) {
    yield put(getIESContractByIdError(error));
  }
}

function* getIESContractList({ payload }) {
  try {
    const response = yield call(getIESContractListRequest, payload);
    yield put(getIESContractListSuccess(response.data));
  } catch (error) {
    yield put(getIESContractListError(error));
  }
}

function* addNewIESContract({ payload }) {
  try {
    const { item, history } = payload;

    if (history) {
      yield call(addNewIESContractRequest, item);
      history.push('/app/contract/es');
    } else {
      const { data, programId, clientId, employerId, servicePlanHeaderId } = item;
      const query = {
        programId,
        clientId,
        employerId,
        servicePlanHeaderId,
      };

      yield call(addNewIESContractRequest, data);
      const response = yield call(getIESContractListRequest, query);
      yield put(addIESContractItemSuccess(response.data));
    }
  } catch (error) {
    yield put(addIESContractItemError(error));
  }
}

function* editIESContract({ payload }) {
  try {
    const { item, history } = payload;
    if (history) {
      yield call(editIESContractRequest, item);
      history.push('/app/contract/es');
    } else {
      const { data, programId, clientId, employerId, servicePlanHeaderId } = item;
      const query = {
        programId,
        clientId,
        employerId,
        servicePlanHeaderId,
      };
      yield call(editIESContractRequest, data);
      const response = yield call(getIESContractListRequest, query);
      yield put(editIESContractItemSuccess(response.data));
    }
  } catch (error) {
    yield put(editIESContractItemError(error));
  }
}

function* deleteIESContract({ payload }) {
  try {
    const { clientId, programId, id, employerId, servicePlanHeaderId } = payload;
    const query = {
      programId,
      clientId,
      employerId,
      servicePlanHeaderId,
    };
    yield call(deleteIESContractRequest, id);
    const response = yield call(getIESContractListRequest, query);
    yield put(deleteIESContractItemSuccess(response.data));
  } catch (error) {
    yield put(deleteIESContractItemError(error));
  }
}

export function* watchIESContactList() {
  yield takeEvery(IES_CONTRACT_GET_LIST, getIESContractList);
}

export function* watchIESContactGetById() {
  yield takeEvery(IES_CONTRACT_GET_BY_ID, getIESContractById);
}

export function* watchIESContractAddNew() {
  yield takeEvery(IES_CONTRACT_ADD_ITEM, addNewIESContract);
}

export function* watchIESContractEdit() {
  yield takeEvery(IES_CONTRACT_EDIT_ITEM, editIESContract);
}

export function* watchIESContractDelete() {
  yield takeEvery(IES_CONTRACT_DELETE_ITEM, deleteIESContract);
}

export default function* rootSaga() {
  yield all([
    fork(watchIESContactList),
    fork(watchIESContactGetById),
    fork(watchIESContractAddNew),
    fork(watchIESContractEdit),
    fork(watchIESContractDelete),
  ]);
}

export { getIESContractListRequest };
