import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { GET_CLIENT_SUMMARY_YJC, SAVE_CLIENT_SUMMARY_YJC } from './types';
import Config from '../../config';

import { getClientSummaryYJCSuccess, clientSummaryYJCError } from './actions';

const getClientSummaryYJCRequest = async payload => {
  let url = `${Config.apiServerHost}/api/Client/clientSummary`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const saveClientSummaryYJCReqeust = async clientSummary => {
  return axios.post(
    `${Config.apiServerHost}/api/Client/clientSummary`,
    clientSummary,
    await authHeader()
  );
};

function* getClientSummaryYJC({ payload }) {
  try {
    const response = yield call(getClientSummaryYJCRequest, payload);
    yield put(getClientSummaryYJCSuccess(response.data));
  } catch (error) {
    yield put(clientSummaryYJCError(error.response.statusText));
  }
}

function* saveClientSummaryYJC({ payload }) {
  try {
    const { clientId, servicePlanHeaderId, data } = payload;
    yield call(saveClientSummaryYJCReqeust, data);
    const query = {
      clientId: clientId ?? '',
      servicePlanHeaderId: servicePlanHeaderId ?? '',
    };
    let response = yield call(getClientSummaryYJCRequest, query);
    yield put(getClientSummaryYJCSuccess(response.data));
  } catch (error) {
    yield put(clientSummaryYJCError(error.response.statusText));
  }
}

export function* watchClientSummaryYJC() {
  yield takeEvery(GET_CLIENT_SUMMARY_YJC, getClientSummaryYJC);
}

export function* watchSaveClientSummaryYJC() {
  yield takeEvery(SAVE_CLIENT_SUMMARY_YJC, saveClientSummaryYJC);
}

export default function* rootSaga() {
  yield all([fork(watchClientSummaryYJC), fork(watchSaveClientSummaryYJC)]);
}
