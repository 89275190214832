import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { EIDM_EDIT, EIDM_GET_BY_QUERY, EIDM_GET_BY_ID, EIDM_DELETE } from './types';

import {
  getEIDMByQuerySuccess,
  getEIDMByQueryError,
  saveEIDMError,
  getEIDMByIdSuccess,
} from './actions';

const getEIDMByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/eidm/search`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getEIDMByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/eidm/${id}`, await authHeader());
};

const deleteEIDMByIdRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/eidm/${id}`, await authHeader());
};

const editEIDMRequest = async item => {
  return axios.put(`${Config.apiServerHost}/api/eidm`, item, await authHeader());
};

const addNewEIDMRequest = async item => {
  return axios.post(`${Config.apiServerHost}/api/eidm`, item, await authHeader());
};

function* getEIDMByQuery({ payload }) {
  try {
    const response = yield call(getEIDMByQueryRequest, payload);
    const { data } = response;

    if (data) {
      yield put(getEIDMByQuerySuccess(data));
    }
  } catch (error) {
    yield put(getEIDMByQueryError(error.response.statusText));
  }
}

function* getEIDMById({ payload }) {
  try {
    const response = yield call(getEIDMByIdRequest, payload);
    const { data } = response;

    if (data) {
      yield put(getEIDMByIdSuccess(data));
    }
  } catch (error) {
    yield put(getEIDMByQueryError(error.response.statusText));
  }
}

function* deleteEIDMById({ payload }) {
  try {
    const { employerId, clientId, servicePlanHeaderId, data } = payload;
    let response = yield call(deleteEIDMByIdRequest, data);

    const query = {
      clientId: clientId ?? '',
      servicePlanHeaderId: servicePlanHeaderId ?? '',
      employerId: employerId ?? '',
    };

    response = yield call(getEIDMByQueryRequest, query);

    if (response.data) {
      yield put(getEIDMByQuerySuccess(response.data));
    }
  } catch (error) {
    yield put(getEIDMByQueryError(error.response.statusText));
  }
}

function* saveEIDM({ payload }) {
  try {
    const { clientId, servicePlanHeaderId, employerId, data } = payload;
    let response = null;
    if (data.id === 0) {
      response = yield call(addNewEIDMRequest, data);
    } else {
      response = yield call(editEIDMRequest, data);
    }
    const query = {
      clientId: clientId ?? '',
      servicePlanHeaderId: servicePlanHeaderId ?? '',
      employerId: employerId ?? '',
    };

    response = yield call(getEIDMByQueryRequest, query);
    yield put(getEIDMByQuerySuccess(response.data));
  } catch (error) {
    yield put(saveEIDMError(error.response.statusText));
  }
}

export function* watchEIDMByQuery() {
  yield takeEvery(EIDM_GET_BY_QUERY, getEIDMByQuery);
}

export function* watchEIDMById() {
  yield takeEvery(EIDM_GET_BY_ID, getEIDMById);
}

export function* watchDeleteEIDMById() {
  yield takeEvery(EIDM_DELETE, deleteEIDMById);
}

export function* watchSaveEIDEM() {
  yield takeEvery(EIDM_EDIT, saveEIDM);
}

export default function* rootSaga() {
  yield all([
    fork(watchEIDMByQuery),
    fork(watchEIDMById),
    fork(watchDeleteEIDMById),
    fork(watchSaveEIDEM),
  ]);
}
