import {
  SE_CHEQUE_GET_BY_ESCONTRACT_ID,
  SE_CHEQUE_GET_BY_ESCONTRACT_ID_SUCCESS,
  SE_CHEQUE_GET_BY_ESCONTRACT_ID_ERROR,
  SE_CHEQUE_GET_BY_ID,
  SE_CHEQUE_GET_BY_ID_SUCCESS,
  SE_CHEQUE_GET_BY_ID_ERROR,
  SE_CHEQUE_ADD_NEW,
  SE_CHEQUE_ADD_NEW_SUCCESS,
  SE_CHEQUE_ADD_NEW_ERROR,
  SE_CHEQUE_EDIT,
  SE_CHEQUE_EDIT_SUCCESS,
  SE_CHEQUE_EDIT_ERROR,
  SE_CHEQUE_DELETE,
  SE_CHEQUE_DELETE_SUCCESS,
  SE_CHEQUE_DELETE_ERROR,
} from '../actions';

export const getSEChequeByESContractId = esContractId => ({
  type: SE_CHEQUE_GET_BY_ESCONTRACT_ID,
  payload: esContractId,
});

export const getSEChequeByESContractIdSuccess = items => ({
  type: SE_CHEQUE_GET_BY_ESCONTRACT_ID_SUCCESS,
  payload: items,
});

export const getSEChequeByESContractIdError = error => ({
  type: SE_CHEQUE_GET_BY_ESCONTRACT_ID_ERROR,
  payload: error,
});

export const getSEChequeById = id => ({
  type: SE_CHEQUE_GET_BY_ID,
  payload: id,
});

export const getSEChequeByIdSuccess = item => ({
  type: SE_CHEQUE_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getSEChequeByIdError = error => ({
  type: SE_CHEQUE_GET_BY_ID_ERROR,
  payload: error,
});

export const addNewSECheque = item => ({
  type: SE_CHEQUE_ADD_NEW,
  payload: item,
});

export const addNewSEChequeSuccess = item => ({
  type: SE_CHEQUE_ADD_NEW_SUCCESS,
  payload: item,
});

export const addNewSEChequeError = error => ({
  type: SE_CHEQUE_ADD_NEW_ERROR,
  payload: error,
});

export const editSECheque = item => ({
  type: SE_CHEQUE_EDIT,
  payload: item,
});

export const editSEChequeSuccess = item => ({
  type: SE_CHEQUE_EDIT_SUCCESS,
  payload: item,
});

export const editSEChequeError = error => ({
  type: SE_CHEQUE_EDIT_ERROR,
  payload: error,
});

export const deleteSECheque = id => ({
  type: SE_CHEQUE_DELETE,
  payload: id,
});

export const deleteSEChequeSuccess = item => ({
  type: SE_CHEQUE_DELETE_SUCCESS,
  payload: item,
});

export const deleteSEChequeError = error => ({
  type: SE_CHEQUE_DELETE_ERROR,
  payload: error,
});
