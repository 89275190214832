import {
  MIlEAGE_SUPERVISOR_GET_ALL,
  MIlEAGE_SUPERVISOR_GET_ALL_SUCCESS,
  MIlEAGE_SUPERVISOR_GET_ALL_ERROR,
  MIlEAGE_SUPERVISOR_GET_BY_ID,
  MIlEAGE_SUPERVISOR_GET_BY_ID_SUCCESS,
  MIlEAGE_SUPERVISOR_GET_BY_ID_ERROR,
  MIlEAGE_SUPERVISOR_ADD_NEW,
  MIlEAGE_SUPERVISOR_ADD_NEW_SUCCESS,
  MIlEAGE_SUPERVISOR_ADD_NEW_ERROR,
  MIlEAGE_SUPERVISOR_EDIT,
  MIlEAGE_SUPERVISOR_EDIT_SUCCESS,
  MIlEAGE_SUPERVISOR_EDIT_ERROR,
  MIlEAGE_SUPERVISOR_DELETE,
  MIlEAGE_SUPERVISOR_DELETE_SUCCESS,
  MIlEAGE_SUPERVISOR_DELETE_ERROR,
} from '../actions';

export const getMileageSupervisors = () => ({
  type: MIlEAGE_SUPERVISOR_GET_ALL,
});

export const getMileageSupervisorsSuccess = items => ({
  type: MIlEAGE_SUPERVISOR_GET_ALL_SUCCESS,
  payload: items,
});

export const getMileageSupervisorsError = error => ({
  type: MIlEAGE_SUPERVISOR_GET_ALL_ERROR,
  payload: error,
});

export const getMileageSupervisorById = id => ({
  type: MIlEAGE_SUPERVISOR_GET_BY_ID,
  payload: id,
});

export const getMileageSupervisorByIdSuccess = item => ({
  type: MIlEAGE_SUPERVISOR_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getMileageSupervisorByIdError = error => ({
  type: MIlEAGE_SUPERVISOR_GET_BY_ID_ERROR,
  payload: error,
});

export const addNewMileageSupervisor = item => ({
  type: MIlEAGE_SUPERVISOR_ADD_NEW,
  payload: item,
});

export const addNewMileageSupervisorSuccess = item => ({
  type: MIlEAGE_SUPERVISOR_ADD_NEW_SUCCESS,
  payload: item,
});

export const addNewMileageSupervisorError = error => ({
  type: MIlEAGE_SUPERVISOR_ADD_NEW_ERROR,
  payload: error,
});

export const editMileageSupervisor = item => ({
  type: MIlEAGE_SUPERVISOR_EDIT,
  payload: item,
});

export const editMileageSupervisorSuccess = item => ({
  type: MIlEAGE_SUPERVISOR_EDIT_SUCCESS,
  payload: item,
});

export const editMileageSupervisorError = error => ({
  type: MIlEAGE_SUPERVISOR_EDIT_ERROR,
  payload: error,
});

export const deleteMileageSupervisor = id => ({
  type: MIlEAGE_SUPERVISOR_DELETE,
  payload: id,
});

export const deleteMileageSupervisorSuccess = item => ({
  type: MIlEAGE_SUPERVISOR_DELETE_SUCCESS,
  payload: item,
});

export const deleteMileageSupervisorError = error => ({
  type: MIlEAGE_SUPERVISOR_DELETE_ERROR,
  payload: error,
});
