import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  STAKE_HOLDER_CONTACT_ADD_NEW,
  STAKE_HOLDER_CONTACT_CHANGE_STATUS,
  STAKE_HOLDER_CONTACT_DELETE,
  STAKE_HOLDER_CONTACT_GET_BY_ID,
  STAKE_HOLDER_CONTACT_GET_PAGING,
  STAKE_HOLDER_CONTACT_UPDATE,
} from './types';

import {
  addStakeHolderContactSuccess,
  addStakeHolderContactError,
  getPagingStakeHolderContactSuccess,
  getPagingStakeHolderContactError,
  getStakeHolderContactByIdSuccess,
  getStakeHolderContactByIdError,
  updateStakeHolderContactSuccess,
  updateStakeHolderContactError,
} from './actions';

const addStakeHolderContactRequest = async item => {
  return axios.post(`${Config.apiServerHost}/api/StakeHolderContact`, item, {
    headers: { ...(await authHeader()).headers },
  });
};

const updateStakeHolderContactRequest = async item => {
  return axios.put(`${Config.apiServerHost}/api/StakeHolderContact`, item, {
    headers: { ...(await authHeader()).headers },
  });
};

const inviteStakeHolderContactRequest = async contactId => {
  return axios.post(`${Config.apiServerHost}/api/StakeHolderContact/invite/${contactId}`, null, {
    headers: { ...(await authHeader()).headers },
  });
};

const getPagingStakeHolderContactRequest = async query => {
  return axios.get(`${Config.apiServerHost}/api/StakeHolderContact/paging`, {
    params: query,
    headers: { ...(await authHeader()).headers },
  });
};

const getStakeHolderContactByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/StakeHolderContact/${id}`, {
    headers: { ...(await authHeader()).headers },
  });
};

const changeStatusStakeHolderContactRequest = async item => {
  return axios.get(`${Config.apiServerHost}/api/StakeHolderContact/statusChange`, {
    params: item,
    headers: { ...(await authHeader()).headers },
  });
};

const deleteStakeHolderContactRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/StakeHolderContact/${id}`, await authHeader());
};

function* addNewStakeHolderContact({ payload }) {
  try {
    const { data, stakeHolderType, stakeHolderId } = payload;
    let response = yield call(addStakeHolderContactRequest, data);
    yield put(addStakeHolderContactSuccess(response.data));

    response = yield call(getPagingStakeHolderContactRequest, { stakeHolderType, stakeHolderId });
    yield put(getPagingStakeHolderContactSuccess(response.data));
  } catch (error) {
    yield put(addStakeHolderContactError(error.response.statusText));
  }
}

function* updateStakeHolderContact({ payload }) {
  try {
    const { data, stakeHolderType, stakeHolderId } = payload;
    let response = yield call(updateStakeHolderContactRequest, data);
    yield put(updateStakeHolderContactSuccess(response.data));

    response = yield call(getPagingStakeHolderContactRequest, { stakeHolderType, stakeHolderId });
    yield put(getPagingStakeHolderContactSuccess(response.data));
  } catch (error) {
    yield put(updateStakeHolderContactError(error.response.statusText));
  }
}

function* getPagingStakeHolderContact({ payload }) {
  try {
    let response = yield call(getPagingStakeHolderContactRequest, payload);
    yield put(getPagingStakeHolderContactSuccess(response.data));
  } catch (error) {
    yield put(getPagingStakeHolderContactError(error.response.statusText));
  }
}

function* getStakeHolderContactById({ payload }) {
  try {
    let response = yield call(getStakeHolderContactByIdRequest, payload);
    yield put(getStakeHolderContactByIdSuccess(response.data));
  } catch (error) {
    yield put(getStakeHolderContactByIdError(error.response.statusText));
  }
}

function* changeStatusStakeHolderContact({ payload }) {
  try {
    const { data, stakeHolderType, stakeHolderId } = payload;
    let response = yield call(changeStatusStakeHolderContactRequest, data);

    response = yield call(getPagingStakeHolderContactRequest, { stakeHolderType, stakeHolderId });
    yield put(getPagingStakeHolderContactSuccess(response.data));
  } catch (error) {
    yield put(getPagingStakeHolderContactError(error.response.statusText));
  }
}

function* deleteStakeHolderContact({ payload }) {
  try {
    const { data, stakeHolderType, stakeHolderId } = payload;
    let response = yield call(deleteStakeHolderContactRequest, data);

    response = yield call(getPagingStakeHolderContactRequest, { stakeHolderType, stakeHolderId });
    yield put(getPagingStakeHolderContactSuccess(response.data));
  } catch (error) {
    yield put(getPagingStakeHolderContactError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(STAKE_HOLDER_CONTACT_ADD_NEW, addNewStakeHolderContact);
  yield takeEvery(STAKE_HOLDER_CONTACT_UPDATE, updateStakeHolderContact);
  yield takeEvery(STAKE_HOLDER_CONTACT_GET_PAGING, getPagingStakeHolderContact);
  yield takeEvery(STAKE_HOLDER_CONTACT_GET_BY_ID, getStakeHolderContactById);
  yield takeEvery(STAKE_HOLDER_CONTACT_CHANGE_STATUS, changeStatusStakeHolderContact);
  yield takeEvery(STAKE_HOLDER_CONTACT_DELETE, deleteStakeHolderContact);
}

export { inviteStakeHolderContactRequest };
