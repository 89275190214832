import {
  EAP_GET_BY_ID,
  EAP_GET_BY_ID_ERROR,
  EAP_GET_BY_ID_SUCCESS,
  EAP_UPDATE,
  EAP_UPDATE_SUCCESS,
  EAP_UPDATE_ERROR,
  EAP_SYNC,
  EAP_SYNC_SUCCESS,
  EAP_SYNC_ERROR,
  GET_EAP_LIST_SUCCESS,
  GET_EAP_LIST,
  GET_EAP_DOCUMENT_LIST,
  GET_EAP_DOCUMENT_LIST_SUCCESS,
  EAP_SYNC_CLEAR_NOTIFY,
  GET_REFRESH_EAP_LIST_SUCCESS,
  GET_REFRESH_EAP_ATTACHMENT_LIST_SUCCESS,
  EAP_DETECT_DIFFERENCE_SUCCESS,
} from './types';
// item
const initialState = {
  item: null,
  items: [],
  loading: false,
  syncLoading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
  total: 0,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
  documents: null,
  lastUpdate: null,
  difference: null,
  updateAttachmentsOnly: false,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case EAP_DETECT_DIFFERENCE_SUCCESS:
      return {
        ...state,
        difference: payload,
      };
    case GET_EAP_LIST:
    case EAP_GET_BY_ID:
    case EAP_UPDATE:
    case GET_EAP_DOCUMENT_LIST:
      return {
        ...state,
        loading: true,
        error: null,
        item: null,
      };
    case EAP_SYNC:
      return {
        ...state,
        syncLoading: true,
        loading: false,
        error: null,
      };
    case GET_REFRESH_EAP_LIST_SUCCESS:
      return {
        ...state,
        item: JSON.parse(JSON.stringify(payload)),
        syncLoading: false,
        updateAttachmentsOnly: false,
        lastUpdate: new Date(),
      };
    case GET_REFRESH_EAP_ATTACHMENT_LIST_SUCCESS:
      return {
        ...state,
        item: payload,
        syncLoading: false,
        updateAttachmentsOnly: true,
        lastUpdate: new Date(),
      };
    case GET_EAP_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        documents: JSON.parse(JSON.stringify(payload)),
        loading: false,
        syncLoading: false,
        updateAttachmentsOnly: false,
        lastUpdate: new Date(),
      };

    case GET_EAP_LIST_SUCCESS:
      return {
        ...state,
        item: JSON.parse(JSON.stringify(payload)),
        loading: false,
        syncLoading: false,
        updateAttachmentsOnly: false,
        lastUpdate: new Date(),
      };
    case EAP_SYNC_SUCCESS:
      return {
        ...state,
        loading: false,
        item: JSON.parse(JSON.stringify(payload)),
        syncLoading: false,
        updateAttachmentsOnly: false,
        lastUpdate: new Date(),
        difference: null,
      };
    case EAP_GET_BY_ID_SUCCESS:
    case EAP_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        item: JSON.parse(JSON.stringify(payload)),
        syncLoading: false,
        updateAttachmentsOnly: false,
        lastUpdate: new Date(),
      };
    case EAP_GET_BY_ID_ERROR:
    case EAP_UPDATE_ERROR:
    case EAP_SYNC_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        syncLoading: false,
      };
    case EAP_SYNC_CLEAR_NOTIFY:
      return {
        ...state,
        loading: false,
        error: null,
        syncLoading: false,
      };

    default:
      return { ...state };
  }
}
