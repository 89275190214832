export const CP_REFERRER_ADD_NEW_REQUEST = 'CP_REFERRER_ADD_NEW_REQUEST';
export const CP_REFERRER_ADD_NEW_REQUEST_SUCCESS = 'CP_REFERRER_ADD_NEW_SUCCESS';
export const CP_REFERRER_ADD_NEW_REQUEST_ERROR = 'CP_REFERRER_ADD_NEW_ERROR';

export const CP_REFERRER_EDIT = 'CP_REFERRER_EDIT';
export const CP_REFERRER_EDIT_SUCCESS = 'CP_REFERRER_EDIT_SUCCESS';
export const CP_REFERRER_EDIT_ERROR = 'CP_REFERRER_EDIT_ERROR';

export const GET_CP_REFERRER_LIST = 'GET_CP_REFERRER_LIST';
export const GET_CP_REFERRER_LIST_SUCCESS = 'GET_CP_REFERRER_LIST_SUCCESS';
export const GET_CP_REFERRER_LIST_ERROR = 'GET_CP_REFERRER_LIST_ERROR';

export const GET_SP_LIST = 'GET_SP_LIST';
export const GET_SP_LIST_SUCCESS = 'GET_SP_LIST_SUCCESS';
export const GET_SP_LIST_ERROR = 'GET_SP_LIST_ERROR';

export const GET_CP_REFERRER_BY_ID = 'GET_CP_REFERRER_BY_ID';
export const GET_CP_REFERRER_BY_ID_SUCCESS = 'GET_CP_REFERRER_BY_ID_SUCCESS';
export const GET_CP_REFERRER_BY_ID_ERROR = 'GET_CP_REFERRER_BY_ID_ERROR';
