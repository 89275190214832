import {
  EMPLOYER_GET_LIST,
  EMPLOYER_GET_LIST_SUCCESS,
  EMPLOYER_GET_LIST_ERROR,
  EMPLOYER_ADD_ITEM,
  EMPLOYER_ADD_ITEM_ERROR,
  EMPLOYER_EDIT_ITEM,
  EMPLOYER_EDIT_ITEM_ERROR,
  EMPLOYER_DELETE_ITEM,
  EMPLOYER_DELETE_ITEM_ERROR,
  EMPLOYER_GET_BY_ID,
  EMPLOYER_GET_BY_ID_SUCCESS,
  EMPLOYER_GET_BY_ID_ERROR,
} from './employer.types';

const INIT_STATE = {
  employers: [],
  employer: null,
  loading: false,
  itemCount: 0,
  pageCount: 0,
  error: '',
};

const employer = (state = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case EMPLOYER_GET_BY_ID:
    case EMPLOYER_ADD_ITEM:
    case EMPLOYER_GET_LIST:
    case EMPLOYER_EDIT_ITEM:
    case EMPLOYER_DELETE_ITEM:
      return { ...state, loading: true };

    case EMPLOYER_GET_LIST_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        loading: false,
        employers: data,
        itemCount,
        pageCount,
      };

    case EMPLOYER_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        employer: payload,
        error: null,
      };

    case EMPLOYER_ADD_ITEM_ERROR:
    case EMPLOYER_EDIT_ITEM_ERROR:
    case EMPLOYER_GET_LIST_ERROR:
    case EMPLOYER_DELETE_ITEM_ERROR:
    case EMPLOYER_GET_BY_ID_ERROR:
      return { ...state, loading: true, error: payload };

    default:
      return { ...state };
  }
};

export default employer;
