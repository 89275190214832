// OUTCOME_QUESTIONAIRE

export const OUTCOME_QUESTIONAIRE_GET_BY_ID = 'OUTCOME_QUESTIONAIRE_GET_BY_ID';
export const OUTCOME_QUESTIONAIRE_GET_BY_ID_SUCCESS = 'OUTCOME_QUESTIONAIRE_GET_BY_ID_SUCCESS';
export const OUTCOME_QUESTIONAIRE_GET_BY_ID_ERROR = 'OUTCOME_QUESTIONAIRE_GET_BY_ID_ERROR';

export const OUTCOME_QUESTIONAIRE_UPDATE = 'OUTCOME_QUESTIONAIRE_UPDATE';
export const OUTCOME_QUESTIONAIRE_UPDATE_SUCCESS = 'OUTCOME_QUESTIONAIRE_UPDATE_SUCCESS';
export const OUTCOME_QUESTIONAIRE_UPDATE_ERROR = 'OUTCOME_QUESTIONAIRE_UPDATE_ERROR';

export const OUTCOME_QUESTIONAIRE_SYNC = 'OUTCOME_QUESTIONAIRE_SYNC';
export const OUTCOME_QUESTIONAIRE_SYNC_SUCCESS = 'OUTCOME_QUESTIONAIRE_SYNC_SUCCESS';
export const OUTCOME_QUESTIONAIRE_SYNC_ERROR = 'OUTCOME_QUESTIONAIRE_SYNC_ERROR';

export const OUTCOME_QUESTIONAIRE_CLEAR_NOTIFIED = 'OUTCOME_QUESTIONAIRE_CLEAR_NOTIFIED';
