import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  EVENT_DELETE,
  EVENT_EDIT,
  EVENT_GET_BY_ID,
  EVENT_ADD_NEW,
  EVENT_GET_LIST,
  EVENT_GET_LIST_BY_QUERY,
  EVENT_STATUS_CHANGE,
} from '../actions';
import Config from '../../config';
// Event
import {
  getEventListSuccess,
  getEventListError,
  editEventSuccess,
  editEventError,
  addNewEventSuccess,
  addNewEventError,
  deleteEventSuccess,
  deleteEventError,
  getEventByIdSuccess,
  getEventListByQuerySuccess,
  getEventListByQueryError,
  changeEventStatusSuccess,
  changeEventStatusError,
} from './actions';

const getEventListByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/Event/byQuery`;
  return await axios.post(url, query, await authHeader());
};

const getEventListRequest = async () => {
  return axios.get(`${Config.apiServerHost}/api/Event`, await authHeader());
};

const addNewEventReqeust = async actionPlan => {
  return axios.post(`${Config.apiServerHost}/api/Event`, actionPlan, await authHeader());
};

const editEventRequest = async actionPlan => {
  return axios.put(`${Config.apiServerHost}/api/Event`, actionPlan, await authHeader());
};

const deleteEventRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/Event/${id}`, await authHeader());
};

const getEventByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/Event/${id}`, await authHeader());
};

const changeEventStatusRequest = async id => {
  return axios.put(`${Config.apiServerHost}/api/Event/${id}/complete`, null, await authHeader());
};

const getEvetByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/Event/${id}`, await authHeader());
};

function* getEventListByQuery({ payload }) {
  try {
    const response = yield call(getEventListByQueryRequest, payload);
    yield put(getEventListByQuerySuccess(response.data));
  } catch (error) {
    yield put(getEventListByQueryError(error.response.statusText));
  }
}

function* getEventList() {
  try {
    const response = yield call(getEventListRequest);
    yield put(getEventListSuccess(response.data));
  } catch (error) {
    yield put(getEventListError(error.response.statusText));
  }
}

function* addNewEvent({ payload }) {
  try {
    let response = yield call(addNewEventReqeust, payload);
    response = yield call(getEventByIdRequest, response.data);
    yield put(addNewEventSuccess(response.data));
  } catch (error) {
    yield put(addNewEventError(error.response.statusText));
  }
}

function* editEvent({ payload }) {
  try {
    let response = yield call(editEventRequest, payload);
    response = yield call(getEventByIdRequest, response.data);
    yield put(editEventSuccess(response.data));
  } catch (error) {
    yield put(editEventError(error.response.statusText));
  }
}

function* getEventById({ payload }) {
  try {
    const response = yield call(getEventByIdRequest, payload);
    yield put(getEventByIdSuccess(response.data));
  } catch (error) {
    yield put(editEventError(error.response.statusText));
  }
}

function* deleteEvent({ payload }) {
  try {
    const response = yield call(deleteEventRequest, payload);
    yield put(deleteEventSuccess(response.data));
  } catch (error) {
    yield put(deleteEventError(error.response.statusText));
  }
}

function* changeEventStatus({ payload }) {
  try {
    let response = yield call(changeEventStatusRequest, payload);
    response = yield call(getEvetByIdRequest, response.data);
    yield put(changeEventStatusSuccess(response.data));
  } catch (error) {
    yield put(changeEventStatusError(error.response.statusText));
  }
}

export function* watchEventListByQuery() {
  yield takeEvery(EVENT_GET_LIST_BY_QUERY, getEventListByQuery);
}

export function* watchEventList() {
  yield takeEvery(EVENT_GET_LIST, getEventList);
}

export function* watchAddNewEvent() {
  yield takeEvery(EVENT_ADD_NEW, addNewEvent);
}

export function* watchEventById() {
  yield takeEvery(EVENT_GET_BY_ID, getEventById);
}

export function* watchEditEvent() {
  yield takeEvery(EVENT_EDIT, editEvent);
}

export function* watchDeleteEvent() {
  yield takeEvery(EVENT_DELETE, deleteEvent);
}

export function* watchChangeEventStatus() {
  yield takeEvery(EVENT_STATUS_CHANGE, changeEventStatus);
}

export default function* rootSaga() {
  yield all([
    fork(watchEventListByQuery),
    fork(watchEventList),
    fork(watchAddNewEvent),
    fork(watchEventById),
    fork(watchEditEvent),
    fork(watchDeleteEvent),
    fork(watchChangeEventStatus),
  ]);
}
