import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { buildUrl } from '../../helpers/Utils';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  CASE_NOTE2_DELETE,
  CASE_NOTE2_EDIT,
  CASE_NOTE2_GET_BY_ID,
  CASE_NOTE2_ADD_NEW,
  CASE_NOTE2_QUERY,
  CASE_NOTE2_AGGREGATE,
  EMPLOYER_CASE_NOTE2_EDIT,
  EMPLOYER_CASE_NOTE2_GET_BY_ID,
  getClientServicePlanSuccess,
  getClientLeftSidebarSuccess,
  caseNoteError,
} from '../actions';

import {
  editCaseNote2Success,
  addNewCaseNote2Success,
  deleteCaseNote2Success,
  getCaseNote2ByIdSuccess,
  getCaseNote2QuerySuccess,
  getCaseNotesAggregateSuccess,
  editEmployerCaseNote2Success,
  editEmployerCaseNote2Error,
  getEmployerCaseNote2ByIdSuccess,
} from './actions';
import { getClientLeftSidebarRequest, getClientServicePlanRequest } from '../client/saga';

const getCaseNotesAggregateRequest = async query => {
  const url = `${Config.apiServerHost}/api/CaseNote/aggregate`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const getPagingLastContactRequest = async query => {
  return axios.get(`${Config.apiServerHost}/api/CaseNote/lastContact`, {
    params: query,
    headers: { ...(await authHeader()).headers },
  });
};

const getCaseNote2QueryRequest = async query => {
  const url = `${Config.apiServerHost}/api/CaseNote/search`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const addNewCaseNote2Reqeust = async caseNote2 => {
  return axios.post(`${Config.apiServerHost}/api/CaseNote`, caseNote2, await authHeader());
};

const editCaseNote2Request = async CaseNote2 => {
  return axios.put(`${Config.apiServerHost}/api/CaseNote`, CaseNote2, await authHeader());
};

const editEmployerCaseNote2Request = async caseNote => {
  return axios.put(`${Config.apiServerHost}/api/EmployerCaseNote`, caseNote, await authHeader());
};

const deleteCaseNote2Request = async id => {
  return axios.delete(`${Config.apiServerHost}/api/CaseNote/${id}`, await authHeader());
};

const getCaseNote2ByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/CaseNote/${id}`, await authHeader());
};

const getEmployerCaseNote2ByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/EmployerCaseNote/${id}`, await authHeader());
};

function* getCaseNotesAggregate({ payload }) {
  try {
    const response = yield call(getCaseNotesAggregateRequest, payload);
    yield put(getCaseNotesAggregateSuccess(response.data));
  } catch (error) {
    let err = error.response?.data?.message ?? 'something went wrong.';
    yield put(caseNoteError(err));
  }
}

function* getCaseNote2Query({ payload }) {
  try {
    const response = yield call(getCaseNote2QueryRequest, payload);
    yield put(getCaseNote2QuerySuccess(response.data));
  } catch (error) {
    let err = error.response?.data?.message ?? 'something went wrong.';
    yield put(caseNoteError(err));
  }
}

function* addNewCaseNote2({ payload }) {
  try {
    const { queryParams, data } = payload;
    const { programId } = queryParams;
    let response = yield call(addNewCaseNote2Reqeust, data);
    yield put(addNewCaseNote2Success(response.data));

    response = yield call(getCaseNote2QueryRequest, queryParams);
    yield put(getCaseNote2QuerySuccess(response.data));

    // Call to refresh life sidebar and breadscrum
    if (programId && programId === 14) {
      // IES = 14
      response = yield call(getClientLeftSidebarRequest, queryParams);
      yield put(getClientLeftSidebarSuccess(response.data));
    }

    response = yield call(getClientServicePlanRequest, {
      clientId: queryParams.clientId,
      id: queryParams.servicePlanHeaderId,
    });

    yield put(getClientServicePlanSuccess(response.data));
  } catch (error) {
    let err = error.response?.data?.message ?? 'something went wrong.';
    yield put(caseNoteError(err));
  }
}

function* editCaseNote2({ payload }) {
  try {
    const { queryParams, data } = payload;
    const { programId } = queryParams;

    let response = yield call(editCaseNote2Request, data);
    yield put(editCaseNote2Success(response.data));

    response = yield call(getCaseNote2QueryRequest, queryParams);
    yield put(getCaseNote2QuerySuccess(response.data));

    // Call to refresh life sidebar and breadscrum
    if (programId && programId === 14) {
      // IES = 14
      response = yield call(getClientLeftSidebarRequest, queryParams);
      yield put(getClientLeftSidebarSuccess(response.data));
    }

    response = yield call(getClientServicePlanRequest, {
      clientId: queryParams.clientId,
      id: queryParams.servicePlanHeaderId,
    });

    yield put(getClientServicePlanSuccess(response.data));
  } catch (error) {
    let err = error.response?.data?.message ?? 'something went wrong.';
    yield put(caseNoteError(err));
  }
}

function* editEmployerCaseNote2({ payload }) {
  try {
    const { caseNote, query } = payload;
    let response = yield call(editEmployerCaseNote2Request, caseNote);
    response = yield call(getCaseNote2QueryRequest, query);
    yield put(editEmployerCaseNote2Success(response.data));
  } catch (error) {
    yield put(editEmployerCaseNote2Error(error.response.statusText));
  }
}

function* getCaseNote2ById({ payload }) {
  try {
    const response = yield call(getCaseNote2ByIdRequest, payload);
    yield put(getCaseNote2ByIdSuccess(response.data));
  } catch (error) {
    let err = error.response?.data?.message ?? 'something went wrong.';
    yield put(caseNoteError(err));
  }
}

function* getEmployerCaseNote2ById({ payload }) {
  try {
    const response = yield call(getEmployerCaseNote2ByIdRequest, payload);
    yield put(getEmployerCaseNote2ByIdSuccess(response.data));
  } catch (error) {
    let err = error.response?.data?.message ?? 'something went wrong.';
    yield put(caseNoteError(err));
  }
}

function* deleteCaseNote2({ payload }) {
  try {
    const { queryParams, data } = payload;
    let response = yield call(deleteCaseNote2Request, data);
    yield put(deleteCaseNote2Success(response.data));

    response = yield call(getCaseNote2QueryRequest, queryParams);
    yield put(getCaseNote2QuerySuccess(response.data));
  } catch (error) {
    let err = error.response?.data?.message ?? 'something went wrong.';
    yield put(caseNoteError(err));
  }
}

export default function* rootSaga() {
  yield takeEvery(CASE_NOTE2_AGGREGATE, getCaseNotesAggregate);
  yield takeEvery(CASE_NOTE2_QUERY, getCaseNote2Query);
  yield takeEvery(CASE_NOTE2_ADD_NEW, addNewCaseNote2);
  yield takeEvery(CASE_NOTE2_GET_BY_ID, getCaseNote2ById);
  yield takeEvery(EMPLOYER_CASE_NOTE2_GET_BY_ID, getEmployerCaseNote2ById);
  yield takeEvery(CASE_NOTE2_EDIT, editCaseNote2);
  yield takeEvery(EMPLOYER_CASE_NOTE2_EDIT, editEmployerCaseNote2);
  yield takeEvery(CASE_NOTE2_DELETE, deleteCaseNote2);
}

export {
  getCaseNote2QueryRequest,
  editCaseNote2Request,
  getCaseNote2ByIdRequest,
  deleteCaseNote2Request,
  getPagingLastContactRequest,
};
