import {
  GET_IES_CHART_DETAIL_BY_QUERY,
  GET_IES_CHART_DETAIL_BY_QUERY_SUCCESS,
  GET_IES_CHART_DETAIL_BY_QUERY_ERROR,
} from './types';

// item
const initialState = {
  item: null,
  items: [],
  itemCount: 0,
  pageCount: 0,
  total: 0,
  loading: false,
  error: null,
};

const iesChartDetailReport = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_IES_CHART_DETAIL_BY_QUERY:
      return {
        ...state,
        loading: true,
        error: null,
        items: null,
        item: null,
      };
    case GET_IES_CHART_DETAIL_BY_QUERY_SUCCESS:
      const { itemCount, pageCount, total, data } = payload;
      return {
        ...state,
        loading: false,
        items: data,
        total,
        itemCount,
        pageCount,
      };

    case GET_IES_CHART_DETAIL_BY_QUERY_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return { ...state };
  }
};

export default iesChartDetailReport;
