import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  SE_CASE_NOTE_DELETE,
  SE_CASE_NOTE_EDIT,
  SE_CASE_NOTE_GET_BY_ID,
  SE_CASE_NOTE_ADD_NEW,
  SE_CASE_NOTE_GET_LIST,
  SE_CASE_NOTE_QUERY,
} from '../actions';

import {
  getSECaseNoteListSuccess,
  getSECaseNoteListError,
  editSECaseNoteSuccess,
  editSECaseNoteError,
  addNewSECaseNoteSuccess,
  addNewSECaseNoteError,
  deleteSECaseNoteSuccess,
  deleteSECaseNoteError,
  getSECaseNoteByIdSuccess,
  getSECaseNoteQuerySuccess,
  getSECaseNoteQueryError,
} from './actions';

const getSECaseNoteQueryRequest = async query => {
  const url = `${Config.apiServerHost}/api/SECaseNote/se-case-note-query?seContractId=${query.esContractId}&fromDate=${query.fromDate}&toDate=${query.toDate}`;
  return axios.get(url, await authHeader());
};

const getSECaseNoteListRequest = async seContractId => {
  return axios.get(
    `${Config.apiServerHost}/api/SECaseNote/se-contract-id/${seContractId}`,
    await authHeader()
  );
};

const addNewSECaseNoteReqeust = async caseNote => {
  return axios.post(`${Config.apiServerHost}/api/SECaseNote`, caseNote, await authHeader());
};

const editSECaseNoteRequest = async caseNote => {
  return axios.put(`${Config.apiServerHost}/api/SECaseNote`, caseNote, await authHeader());
};

const deleteSECaseNoteRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/SECaseNote/${id}`, await authHeader());
};

const getSECaseNoteByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/SECaseNote/${id}`, await authHeader());
};

function* getSECaseNoteList({ payload }) {
  try {
    const response = yield call(getSECaseNoteListRequest, payload);
    yield put(getSECaseNoteListSuccess(response.data));
  } catch (error) {
    yield put(getSECaseNoteListError(error.response.statusText));
  }
}

function* getSECaseNoteQuery({ payload }) {
  try {
    const response = yield call(getSECaseNoteQueryRequest, payload);
    yield put(getSECaseNoteQuerySuccess(response.data));
  } catch (error) {
    yield put(getSECaseNoteQueryError(error.response.statusText));
  }
}

function* addNewSECaseNote({ payload }) {
  try {
    let response = yield call(addNewSECaseNoteReqeust, payload);
    response = yield call(getSECaseNoteByIdRequest, response.data);
    yield put(addNewSECaseNoteSuccess(response.data));
  } catch (error) {
    yield put(addNewSECaseNoteError(error.response.statusText));
  }
}

function* editSECaseNote({ payload }) {
  try {
    let response = yield call(editSECaseNoteRequest, payload);
    response = yield call(getSECaseNoteByIdRequest, payload.id);
    yield put(editSECaseNoteSuccess(response.data));
  } catch (error) {
    yield put(editSECaseNoteError(error.response.statusText));
  }
}

function* getSECaseNoteById({ payload }) {
  try {
    const response = yield call(getSECaseNoteByIdRequest, payload);
    yield put(getSECaseNoteByIdSuccess(response.data));
  } catch (error) {
    yield put(editSECaseNoteError(error.response.statusText));
  }
}

function* deleteSECaseNote({ payload }) {
  try {
    const response = yield call(deleteSECaseNoteRequest, payload);
    yield put(deleteSECaseNoteSuccess(response.data));
  } catch (error) {
    yield put(deleteSECaseNoteError(error.response.statusText));
  }
}

export function* watchSECaseNoteQuery() {
  yield takeEvery(SE_CASE_NOTE_QUERY, getSECaseNoteQuery);
}

export function* watchSECaseNoteList() {
  yield takeEvery(SE_CASE_NOTE_GET_LIST, getSECaseNoteList);
}

export function* watchAddNewSECaseNote() {
  yield takeEvery(SE_CASE_NOTE_ADD_NEW, addNewSECaseNote);
}

export function* watchSECaseNoteById() {
  yield takeEvery(SE_CASE_NOTE_GET_BY_ID, getSECaseNoteById);
}

export function* watchEditSECaseNote() {
  yield takeEvery(SE_CASE_NOTE_EDIT, editSECaseNote);
}

export function* watchDeleteSECaseNote() {
  yield takeEvery(SE_CASE_NOTE_DELETE, deleteSECaseNote);
}

export default function* rootSaga() {
  yield all([
    fork(watchSECaseNoteQuery),
    fork(watchSECaseNoteList),
    fork(watchAddNewSECaseNote),
    fork(watchSECaseNoteById),
    fork(watchEditSECaseNote),
    fork(watchDeleteSECaseNote),
  ]);
}
