export const EMPLOYER_GET_LIST = 'EMPLOYER_GET_LIST';
export const EMPLOYER_GET_LIST_SUCCESS = 'EMPLOYER_GET_LIST_SUCCESS';
export const EMPLOYER_GET_LIST_ERROR = 'EMPLOYER_GET_LIST_ERROR';
export const EMPLOYER_ADD_ITEM = 'EMPLOYER_ADD_ITEM';
export const EMPLOYER_ADD_ITEM_ERROR = 'EMPLOYER_ADD_ITEM_ERROR';
export const EMPLOYER_EDIT_ITEM = 'EMPLOYER_EDIT_ITEM';
export const EMPLOYER_EDIT_ITEM_ERROR = 'EMPLOYER_EDIT_ITEM_ERROR';
export const EMPLOYER_DELETE_ITEM = 'EMPLOYER_DELETE_ITEM';
export const EMPLOYER_DELETE_ITEM_ERROR = 'EMPLOYER_DELETE_ITEM_ERROR';

export const EMPLOYER_GET_BY_ID = 'EMPLOYER_GET_BY_ID';
export const EMPLOYER_GET_BY_ID_SUCCESS = 'EMPLOYER_GET_BY_ID_SUCCESS';
export const EMPLOYER_GET_BY_ID_ERROR = 'EMPLOYER_GET_BY_ID_ERROR';
