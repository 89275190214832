import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  CASESUMMARY_GET_LIST,
  JOB_TRACKING_GET_LIST,
  JOB_TRACKING_ADD_NEW,
  JOB_TRACKING_DELETE,
  JOB_TRACKING_GET_BY_ID,
  JOB_TRACKING_EDIT,
  JOB_TRACKING_GET_LIST_BY_CLIENT,
} from '../actions';

import {
  getCaseSummaryListSuccess,
  getCaseSummaryListError,
  getJobTrackingListSuccess,
  getJobTrackingListByClientSuccess,
  getJobTrackingListByClientError,
  getJobTrackingListError,
  addNewJobTrackingSuccess,
  addNewJobTrackingError,
  deleteJobTrackingSuccess,
  deleteJobTrackingError,
  getJobTrackingByIdSuccess,
  getJobTrackingByIdError,
  editJobTrackingSuccess,
  editJobTrackingError,
} from './actions';

const geJobTrackingListByClientRequest = async query => {
  let url = `${Config.apiServerHost}/api/JobTracking/search`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const geJobTrackingListRequest = async query => {
  let url = `${Config.apiServerHost}/api/client/searchJobTracking`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const getCaseSummaryListRequest = async clientId => {
  return axios.get(
    `${Config.apiServerHost}/api/client/CaseSummaryByClientId/${clientId}`,
    await authHeader()
  );
};

const getJobTrackingReportListRequest = async filter => {
  return await axios.get(
    `${Config.apiServerHost}/api/client/job-tracking-report?fromDate=${filter.payload.fromDate}&toDate=${filter.payload.toDate}`,
    await authHeader()
  );
};

const addNewJobTrackingReqeust = async jobTracking => {
  return axios.post(`${Config.apiServerHost}/api/JobTracking`, jobTracking, await authHeader());
};

const editJobTrackingRequest = async jobTracking => {
  return axios.put(`${Config.apiServerHost}/api/JobTracking`, jobTracking, await authHeader());
};

const getJobTrackingByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/JobTracking/${id}`, await authHeader());
};

const deleteJobTrackingRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/JobTracking/${id}`, await authHeader());
};

function* getCaseSummaryList({ payload }) {
  try {
    const { clientId } = payload;
    const response = yield call(getCaseSummaryListRequest, clientId);
    yield put(getCaseSummaryListSuccess(response.data));

    const res = yield call(geJobTrackingListRequest, payload);
    yield put(getJobTrackingListSuccess(res.data));
  } catch (error) {
    yield put(getCaseSummaryListError(error.response.statusText));
  }
}

function* getJobTrackingReportList(payload) {
  try {
    const response = yield call(getJobTrackingReportListRequest, payload);
    yield put(getJobTrackingListSuccess(response.data));
  } catch (error) {
    yield put(getJobTrackingListError(error));
  }
}

function* editJobTracking({ payload }) {
  try {
    let response = yield call(editJobTrackingRequest, payload);
    response = yield call(getJobTrackingByIdRequest, payload.id);
    yield put(editJobTrackingSuccess(response.data));
  } catch (error) {
    yield put(editJobTrackingError(error.response.statusText));
  }
}

function* getJobTrackingById({ payload }) {
  try {
    const response = yield call(getJobTrackingByIdRequest, payload);
    yield put(getJobTrackingByIdSuccess(response.data));
  } catch (error) {
    yield put(getJobTrackingByIdError(error.response.statusText));
  }
}

function* geJobTrackingList({ payload }) {
  try {
    const response = yield call(geJobTrackingListRequest, payload);
    yield put(getJobTrackingListSuccess(response.data));
  } catch (error) {
    yield put(getJobTrackingListError(error.response.statusText));
  }
}

function* geJobTrackingListByClient({ payload }) {
  try {
    const response = yield call(geJobTrackingListByClientRequest, payload);
    yield put(getJobTrackingListByClientSuccess(response.data));
  } catch (error) {
    yield put(getJobTrackingListByClientError(error.response.statusText));
  }
}

function* addNewJobTracking({ payload }) {
  try {
    let response = yield call(addNewJobTrackingReqeust, payload);
    response = yield call(getJobTrackingByIdRequest, response.data);
    yield put(addNewJobTrackingSuccess(response.data));
  } catch (error) {
    yield put(addNewJobTrackingError(error.response.statusText));
  }
}

function* deleteJobTracking({ payload }) {
  try {
    const response = yield call(deleteJobTrackingRequest, payload);
    yield put(deleteJobTrackingSuccess(response.data));
  } catch (error) {
    yield put(deleteJobTrackingError(error.response.statusText));
  }
}

export function* watchCaseSummaryList() {
  yield takeEvery(CASESUMMARY_GET_LIST, getCaseSummaryList);
}

export function* watchJobTrackingById() {
  yield takeEvery(JOB_TRACKING_GET_BY_ID, getJobTrackingById);
}

export function* watchJobTrackingListByClient() {
  yield takeEvery(JOB_TRACKING_GET_LIST_BY_CLIENT, geJobTrackingListByClient);
}

export function* watchJobTrackingList() {
  yield takeEvery(JOB_TRACKING_GET_LIST, geJobTrackingList);
}

export function* watchAddNewJobTracking() {
  yield takeEvery(JOB_TRACKING_ADD_NEW, addNewJobTracking);
}

export function* watchDeleteJobTracking() {
  yield takeEvery(JOB_TRACKING_DELETE, deleteJobTracking);
}

export function* watchEditJobTracking() {
  yield takeEvery(JOB_TRACKING_EDIT, editJobTracking);
}
export function* watchGetList() {
  yield takeEvery(JOB_TRACKING_GET_LIST, getJobTrackingReportList);
}

export default function* rootSaga() {
  yield all([
    fork(watchCaseSummaryList),
    fork(watchJobTrackingListByClient),
    fork(watchJobTrackingList),
    fork(watchAddNewJobTracking),
    fork(watchDeleteJobTracking),
    fork(watchJobTrackingById),
    fork(watchEditJobTracking),
    fork(watchGetList),
  ]);
}
