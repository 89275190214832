import {
  EMPLOYMENT_ACTION_PLAN_ADD_NEW,
  EMPLOYMENT_ACTION_PLAN_ADD_NEW_SUCCESS,
  EMPLOYMENT_ACTION_PLAN_ADD_NEW_ERROR,
  EMPLOYMENT_ACTION_PLAN_DELETE,
  EMPLOYMENT_ACTION_PLAN_DELETE_ERROR,
  EMPLOYMENT_ACTION_PLAN_DELETE_SUCCESS,
  EMPLOYMENT_ACTION_PLAN_EDIT,
  EMPLOYMENT_ACTION_PLAN_EDIT_ERROR,
  EMPLOYMENT_ACTION_PLAN_EDIT_SUCCESS,
  EMPLOYMENT_ACTION_PLAN_GET_BY_ID,
  EMPLOYMENT_ACTION_PLAN_GET_BY_ID_ERROR,
  EMPLOYMENT_ACTION_PLAN_GET_BY_ID_SUCCESS,
  EMPLOYMENT_ACTION_PLAN_GET_LIST,
  EMPLOYMENT_ACTION_PLAN_GET_LIST_ERROR,
  EMPLOYMENT_ACTION_PLAN_GET_LIST_SUCCESS,
  EMPLOYMENT_ACTION_PLAN_ADD_NEW_REQUEST,
  EMPLOYMENT_ACTION_PLAN_QUERY,
  EMPLOYMENT_ACTION_PLAN_QUERY_SUCCESS,
  EMPLOYMENT_ACTION_PLAN_QUERY_ERROR,
  EMPLOYMENT_ACTION_PLAN_BY_CLIENT,
  EMPLOYMENT_ACTION_PLAN_BY_CLIENT_SUCCESS,
  EMPLOYMENT_ACTION_PLAN_BY_CLIENT_ERROR,
  EMPLOYMENT_ACTION_PLAN_NOTIFICATION_CLOSED,
} from '../actions';

/** EmploymentActionPlan */
export const getEmploymentActionPlanQuery = query => ({
  type: EMPLOYMENT_ACTION_PLAN_QUERY,
  payload: query,
});

export const getEmploymentActionPlanQuerySuccess = caseNotes => ({
  type: EMPLOYMENT_ACTION_PLAN_QUERY_SUCCESS,
  payload: caseNotes,
});

export const getEmploymentActionPlanQueryError = error => ({
  type: EMPLOYMENT_ACTION_PLAN_QUERY_ERROR,
  payload: error,
});

export const getEmploymentActionPlanByClient = query => ({
  type: EMPLOYMENT_ACTION_PLAN_BY_CLIENT,
  payload: query,
});

export const getEmploymentActionPlanByClientSuccess = caseNotes => ({
  type: EMPLOYMENT_ACTION_PLAN_BY_CLIENT_SUCCESS,
  payload: caseNotes,
});

export const getEmploymentActionPlanByClientError = error => ({
  type: EMPLOYMENT_ACTION_PLAN_BY_CLIENT_ERROR,
  payload: error,
});

export const getEmploymentActionPlanList = clientId => ({
  type: EMPLOYMENT_ACTION_PLAN_GET_LIST,
  payload: clientId,
});

export const getEmploymentActionPlanListSuccess = CaseNotes => ({
  type: EMPLOYMENT_ACTION_PLAN_GET_LIST_SUCCESS,
  payload: CaseNotes,
});

export const getEmploymentActionPlanListError = error => ({
  type: EMPLOYMENT_ACTION_PLAN_GET_LIST_ERROR,
  payload: error,
});

export const addNewEmploymentActionPlan = caseNote => ({
  type: EMPLOYMENT_ACTION_PLAN_ADD_NEW,
  payload: caseNote,
});

export const addNewEmploymentActionPlanRequest = () => ({
  type: EMPLOYMENT_ACTION_PLAN_ADD_NEW_REQUEST,
});

export const addNewEmploymentActionPlanSuccess = CaseNote => ({
  type: EMPLOYMENT_ACTION_PLAN_ADD_NEW_SUCCESS,
  payload: CaseNote,
});

export const addNewEmploymentActionPlanError = error => ({
  type: EMPLOYMENT_ACTION_PLAN_ADD_NEW_ERROR,
  payload: error,
});

export const editEmploymentActionPlan = CaseNote => ({
  type: EMPLOYMENT_ACTION_PLAN_EDIT,
  payload: CaseNote,
});

export const editEmploymentActionPlanSuccess = caseNote => ({
  type: EMPLOYMENT_ACTION_PLAN_EDIT_SUCCESS,
  payload: caseNote,
});

export const editEmploymentActionPlanError = error => ({
  type: EMPLOYMENT_ACTION_PLAN_EDIT_ERROR,
  payload: error,
});

export const deleteEmploymentActionPlan = id => ({
  type: EMPLOYMENT_ACTION_PLAN_DELETE,
  payload: id,
});

export const deleteEmploymentActionPlanSuccess = id => ({
  type: EMPLOYMENT_ACTION_PLAN_DELETE_SUCCESS,
  payload: id,
});

export const deleteEmploymentActionPlanError = error => ({
  type: EMPLOYMENT_ACTION_PLAN_DELETE_ERROR,
  payload: error,
});

export const getEmploymentActionPlanById = id => ({
  type: EMPLOYMENT_ACTION_PLAN_GET_BY_ID,
  payload: id,
});

export const getEmploymentActionPlanByIdSuccess = caseNote => ({
  type: EMPLOYMENT_ACTION_PLAN_GET_BY_ID_SUCCESS,
  payload: caseNote,
});

export const getEmploymentActionPlanByIdError = error => ({
  type: EMPLOYMENT_ACTION_PLAN_GET_BY_ID_ERROR,
  payload: error,
});

export const employmentActionPlanNotificationClosed = () => ({
  type: EMPLOYMENT_ACTION_PLAN_NOTIFICATION_CLOSED,
});
