import {
  PLAN_CONTENT_ADD_NEW,
  PLAN_CONTENT_ADD_NEW_SUCCESS,
  PLAN_CONTENT_ADD_NEW_ERROR,
  PLAN_CONTENT_EDIT,
  PLAN_CONTENT_EDIT_ERROR,
  PLAN_CONTENT_EDIT_SUCCESS,
  PLAN_CONTENT_GET_BY_QUERY,
  PLAN_CONTENT_GET_BY_ID,
  PLAN_CONTENT_GET_BY_QUERY_SUCCESS,
  PLAN_CONTENT_GET_BY_QUERY_ERROR,
  PLAN_CONTENT_GET_BY_ID_SUCCESS,
  PLAN_CONTENT_ADD_NEW_REQUEST,
  PLAN_CONTENT_DELETE,
  PLAN_CONTENT_DELETE_SUCCESS,
  PLAN_CONTENT_DELETE_ERROR,
  PLAN_CONTENT_GET_BY_ID_ERROR,
} from './types';

// PlanContent

export const addNewPlanContent = items => ({
  type: PLAN_CONTENT_ADD_NEW,
  payload: items,
});

export const addNewPlanContentRequest = () => ({
  type: PLAN_CONTENT_ADD_NEW_REQUEST,
});

export const addNewPlanContentSuccess = items => ({
  type: PLAN_CONTENT_ADD_NEW_SUCCESS,
  payload: items,
});

export const addNewPlanContentError = error => ({
  type: PLAN_CONTENT_ADD_NEW_ERROR,
  payload: error,
});

export const editPlanContent = item => ({
  type: PLAN_CONTENT_EDIT,
  payload: item,
});

export const editPlanContentSuccess = item => ({
  type: PLAN_CONTENT_EDIT_SUCCESS,
  payload: item,
});

export const editPlanContentError = error => ({
  type: PLAN_CONTENT_EDIT_ERROR,
  payload: error,
});

export const getPlanContentByQuery = query => ({
  type: PLAN_CONTENT_GET_BY_QUERY,
  payload: query,
});

export const getPlanContentByQuerySuccess = item => ({
  type: PLAN_CONTENT_GET_BY_QUERY_SUCCESS,
  payload: item,
});

export const getPlanContentByQueryError = error => ({
  type: PLAN_CONTENT_GET_BY_QUERY_ERROR,
  payload: error,
});

export const getPlanContentById = id => ({
  type: PLAN_CONTENT_GET_BY_ID,
  payload: id,
});

export const getPlanContentByIdSuccess = item => ({
  type: PLAN_CONTENT_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getPlanContentByIdError = error => ({
  type: PLAN_CONTENT_GET_BY_ID_ERROR,
  payload: error,
});

export const deletePlanContentById = id => ({
  type: PLAN_CONTENT_DELETE,
  payload: id,
});

export const deletePlanContentByIdSuccess = item => ({
  type: PLAN_CONTENT_DELETE_SUCCESS,
  payload: item,
});

export const deletePlanContentByIdErrorr = item => ({
  type: PLAN_CONTENT_DELETE_ERROR,
  payload: item,
});
