// TRANSACTION_NOTIFICATION
export const IGNORE_TRANSACTION_NOTIFICATION = 'IGNORE_TRANSACTION_NOTIFICATION';
export const IGNORE_TRANSACTION_NOTIFICATION_SUCCESS = 'IGNORE_TRANSACTION_NOTIFICATION_SUCCESS';
export const IGNORE_TRANSACTION_NOTIFICATION_ERROR = 'IGNORE_TRANSACTION_NOTIFICATION_ERROR';

export const TRANSACTION_NOTIFICATION_GET_BY_QUERY = 'TRANSACTION_NOTIFICATION_GET_BY_QUERY';
export const TRANSACTION_NOTIFICATION_GET_BY_QUERY_SUCCESS =
  'TRANSACTION_NOTIFICATION_GET_BY_QUERY_SUCCESS';
export const TRANSACTION_NOTIFICATION_GET_BY_QUERY_ERROR =
  'TRANSACTION_NOTIFICATION_GET_BY_QUERY_ERROR';

export const CLEAR_TRANSACTION_NOTIFICATION_MESSAGE = 'CLEAR_TRANSACTION_NOTIFICATION_MESSAGE';
