import {
  OUTREACH_GET_BY_CLIENT_AND_PROGRAM,
  OUTREACH_GET_BY_CLIENT_AND_PROGRAM_SUCCESS,
  OUTREACH_SAVE,
  OUTREACH_ERROR,
} from './types';

const initialState = {
  items: [],
  item: null,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case OUTREACH_SAVE:
    case OUTREACH_GET_BY_CLIENT_AND_PROGRAM:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case OUTREACH_GET_BY_CLIENT_AND_PROGRAM_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
      };
    case OUTREACH_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
