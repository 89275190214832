import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { RESUME_ADD_NEW, RESUME_DELETE, RESUME_GET_BY_CLIENT_ID } from '../actions';

import {
  addNewResumeSuccess,
  addNewResumeError,
  deleteResumeSuccess,
  deleteResumeError,
  getResumeByClientIdSuccess,
} from './actions';

const addNewResumeReqeust = async payload => {
  return axios.post(`${Config.apiServerHost}/api/client/resume`, payload, await authHeader());
};

const deleteResumeRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/client/resume/${id}`, await authHeader());
};

const getResumeByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/client/resume/${id}`, await authHeader());
};

function* addNewResume({ payload }) {
  try {
    let response = yield call(addNewResumeReqeust, payload.resume);
    response = yield call(getResumeByIdRequest, response.data);
    yield put(addNewResumeSuccess(response.data));
  } catch (error) {
    yield put(addNewResumeError(error.response.statusText));
  }
}

const getResumeByClientIdRequest = async clientId => {
  return axios.get(
    `${Config.apiServerHost}/api/client/ResumeByClientId/${clientId}`,
    await authHeader()
  );
};
function* getResumeByClientId({ payload }) {
  try {
    const response = yield call(getResumeByClientIdRequest, payload);
    yield put(getResumeByClientIdSuccess(response.data));
  } catch (error) {
    yield put(getResumeByClientIdSuccess(error.response));
  }
}

function* deleteResume({ payload }) {
  try {
    const response = yield call(deleteResumeRequest, payload);
    yield put(deleteResumeSuccess(response.data));
  } catch (error) {
    yield put(deleteResumeError(error.response));
  }
}

export function* watchAddNewResume() {
  yield takeEvery(RESUME_ADD_NEW, addNewResume);
}

export function* watchResumeByClinetId() {
  yield takeEvery(RESUME_GET_BY_CLIENT_ID, getResumeByClientId);
}

export function* watchDeleteResume() {
  yield takeEvery(RESUME_DELETE, deleteResume);
}

export default function* rootSaga() {
  yield all([fork(watchAddNewResume), fork(watchResumeByClinetId), fork(watchDeleteResume)]);
}
