import {
  GET_CUSTOMER_SATISFACTION_REPORT,
  GET_CUSTOMER_SATISFACTION_REPORT_SUCCESS,
  GET_CUSTOMER_SATISFACTION_REPORT_ERROR,
  GET_CLIENT_OUTCOMES_REPORT,
  GET_CLIENT_OUTCOMES_REPORT_SUCCESS,
  GET_CLIENT_OUTCOMES_REPORT_ERROR,
  GET_CLIENT_VOLUMES_REPORT,
  GET_CLIENT_VOLUMES_REPORT_SUCCESS,
  GET_CLIENT_VOLUMES_REPORT_ERROR,
  SAVE_TARGET_CLIENT_VOLUMES_REPORT,
  SAVE_TARGET_CLIENT_VOLUMES_REPORT_SUCCESS,
  SAVE_TARGET_CLIENT_VOLUMES_REPORT_ERROR,
  SAVE_TARGET_CUSTOMER_SATISFACTION_REPORT,
  SAVE_TARGET_CUSTOMER_SATISFACTION_REPORT_SUCCESS,
  SAVE_TARGET_CUSTOMER_SATISFACTION_REPORT_ERROR,
  SAVE_TARGET_CLIENT_OUTCOMES_REPORT,
  SAVE_TARGET_CLIENT_OUTCOMES_REPORT_SUCCESS,
  SAVE_TARGET_CLIENT_OUTCOMES_REPORT_ERROR,
} from './types';

export const getCustomerSatisfactionReport = payload => ({
  type: GET_CUSTOMER_SATISFACTION_REPORT,
  payload,
});

export const getCustomerSatisfactionReportSuccess = items => ({
  type: GET_CUSTOMER_SATISFACTION_REPORT_SUCCESS,
  payload: items,
});

export const getCustomerSatisfactionReportError = error => ({
  type: GET_CUSTOMER_SATISFACTION_REPORT_ERROR,
  payload: error,
});

export const getClientOutcomesReport = payload => ({
  type: GET_CLIENT_OUTCOMES_REPORT,
  payload,
});

export const getClientOutcomesReportSuccess = items => ({
  type: GET_CLIENT_OUTCOMES_REPORT_SUCCESS,
  payload: items,
});

export const getClientOutcomesReportError = error => ({
  type: GET_CLIENT_OUTCOMES_REPORT_ERROR,
  payload: error,
});

export const getClientVolumesReport = payload => ({
  type: GET_CLIENT_VOLUMES_REPORT,
  payload,
});

export const getClientVolumesReportSuccess = items => ({
  type: GET_CLIENT_VOLUMES_REPORT_SUCCESS,
  payload: items,
});

export const getClientVolumesReportError = error => ({
  type: GET_CLIENT_VOLUMES_REPORT_ERROR,
  payload: error,
});

export const saveTargetClientVolumeReport = payload => ({
  type: SAVE_TARGET_CLIENT_VOLUMES_REPORT,
  payload,
});

export const saveTargetClientVolumeReportSuccess = items => ({
  type: SAVE_TARGET_CLIENT_VOLUMES_REPORT_SUCCESS,
  payload: items,
});

export const saveTargetClientVolumeReportError = error => ({
  type: SAVE_TARGET_CLIENT_VOLUMES_REPORT_ERROR,
  payload: error,
});

export const saveTargetCustomerSatisfactionReport = payload => ({
  type: SAVE_TARGET_CUSTOMER_SATISFACTION_REPORT,
  payload,
});

export const saveTargetCustomerSatisfactionReportSuccess = items => ({
  type: SAVE_TARGET_CUSTOMER_SATISFACTION_REPORT_SUCCESS,
  payload: items,
});

export const saveTargetCustomerSatisfactionReportError = error => ({
  type: SAVE_TARGET_CUSTOMER_SATISFACTION_REPORT_ERROR,
  payload: error,
});

export const saveTargetClientOutcomesReport = payload => ({
  type: SAVE_TARGET_CLIENT_OUTCOMES_REPORT,
  payload,
});

export const saveTargetClientOutcomesReportSuccess = items => ({
  type: SAVE_TARGET_CLIENT_OUTCOMES_REPORT_SUCCESS,
  payload: items,
});

export const saveTargetClientOutcomesReportError = error => ({
  type: SAVE_TARGET_CLIENT_OUTCOMES_REPORT_ERROR,
  payload: error,
});
