import {
  ACTIVITY_ADD_NEW,
  ACTIVITY_ADD_NEW_SUCCESS,
  ACTIVITY_ADD_NEW_ERROR,
  ACTIVITY_DELETE,
  ACTIVITY_DELETE_ERROR,
  ACTIVITY_DELETE_SUCCESS,
  ACTIVITY_EDIT,
  ACTIVITY_EDIT_ERROR,
  ACTIVITY_EDIT_SUCCESS,
  ACTIVITY_GET_BY_ID,
  ACTIVITY_GET_BY_ID_ERROR,
  ACTIVITY_GET_BY_ID_SUCCESS,
  ACTIVITY_GET_LIST,
  ACTIVITY_GET_LIST_ERROR,
  ACTIVITY_GET_LIST_SUCCESS,
  ACTIVITY_ADD_NEW_REQUEST,
} from '../actions';

export const getActivityList = clientId => ({
  type: ACTIVITY_GET_LIST,
  payload: clientId,
});

export const getActivityListSuccess = activities => ({
  type: ACTIVITY_GET_LIST_SUCCESS,
  payload: activities,
});

export const getActivityListError = error => ({
  type: ACTIVITY_GET_LIST_ERROR,
  payload: error,
});

export const addNewActivity = activity => ({
  type: ACTIVITY_ADD_NEW,
  payload: activity,
});

export const addNewActivityRequest = () => ({
  type: ACTIVITY_ADD_NEW_REQUEST,
});

export const addNewActivitySuccess = activity => ({
  type: ACTIVITY_ADD_NEW_SUCCESS,
  payload: activity,
});

export const addNewActivityError = error => ({
  type: ACTIVITY_ADD_NEW_ERROR,
  payload: error,
});

export const editActivity = activity => ({
  type: ACTIVITY_EDIT,
  payload: activity,
});

export const editActivitySuccess = activity => ({
  type: ACTIVITY_EDIT_SUCCESS,
  payload: activity,
});

export const editActivityError = error => ({
  type: ACTIVITY_EDIT_ERROR,
  payload: error,
});

export const deleteActivity = id => ({
  type: ACTIVITY_DELETE,
  payload: id,
});

export const deleteActivitySuccess = id => ({
  type: ACTIVITY_DELETE_SUCCESS,
  payload: id,
});

export const deleteActivityError = error => ({
  type: ACTIVITY_DELETE_ERROR,
  payload: error,
});

export const getActivityById = id => ({
  type: ACTIVITY_GET_BY_ID,
  payload: id,
});

export const getActivityByIdSuccess = activity => ({
  type: ACTIVITY_GET_BY_ID_SUCCESS,
  payload: activity,
});

export const getActivityByIdError = error => ({
  type: ACTIVITY_GET_BY_ID_ERROR,
  payload: error,
});
