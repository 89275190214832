import {
  EXPENSE_ENTRY_ADD_NEW,
  EXPENSE_ENTRY_ADD_NEW_SUCCESS,
  EXPENSE_ENTRY_ADD_NEW_ERROR,
  EXPENSE_ENTRY_DELETE,
  EXPENSE_ENTRY_DELETE_ERROR,
  EXPENSE_ENTRY_DELETE_SUCCESS,
  EXPENSE_ENTRY_EDIT,
  EXPENSE_ENTRY_EDIT_ERROR,
  EXPENSE_ENTRY_EDIT_SUCCESS,
  EXPENSE_ENTRY_GET_BY_ID,
  EXPENSE_ENTRY_GET_BY_ID_ERROR,
  EXPENSE_ENTRY_GET_BY_ID_SUCCESS,
  EXPENSE_ENTRY_GET_LIST_BY_EXPENSE_ID,
  EXPENSE_ENTRY_GET_LIST_BY_EXPENSE_ID_ERROR,
  EXPENSE_ENTRY_GET_LIST_BY_EXPENSE_ID_SUCCESS,
  EXPENSE_ENTRY_RECEIPT_DELETE,
  EXPENSE_ENTRY_RECEIPT_DELETE_SUCCESS,
  EXPENSE_ENTRY_RECEIPT_DELETE_ERROR,
  EXPENSE_ENTRY_ADD_NEW_REQUEST,
} from '../actions';

export const getExpenseEntryList = params => ({
  type: EXPENSE_ENTRY_GET_LIST_BY_EXPENSE_ID,
  payload: params,
});

export const getExpenseEntryListSuccess = entries => ({
  type: EXPENSE_ENTRY_GET_LIST_BY_EXPENSE_ID_SUCCESS,
  payload: entries,
});

export const getExpenseEntryListError = error => ({
  type: EXPENSE_ENTRY_GET_LIST_BY_EXPENSE_ID_ERROR,
  payload: error,
});

export const addNewExpenseEntry = entry => ({
  type: EXPENSE_ENTRY_ADD_NEW,
  payload: entry,
});

export const addNewExpenseEntryRequest = () => ({
  type: EXPENSE_ENTRY_ADD_NEW_REQUEST,
});

export const addNewExpenseEntrySuccess = entry => ({
  type: EXPENSE_ENTRY_ADD_NEW_SUCCESS,
  payload: entry,
});

export const addNewExpenseEntryError = error => ({
  type: EXPENSE_ENTRY_ADD_NEW_ERROR,
  payload: error,
});

export const editExpenseEntry = entry => ({
  type: EXPENSE_ENTRY_EDIT,
  payload: entry,
});

export const editExpenseEntrySuccess = entry => ({
  type: EXPENSE_ENTRY_EDIT_SUCCESS,
  payload: entry,
});

export const editExpenseEntryError = error => ({
  type: EXPENSE_ENTRY_EDIT_ERROR,
  payload: error,
});

export const deleteExpenseEntry = id => ({
  type: EXPENSE_ENTRY_DELETE,
  payload: id,
});

export const deleteExpenseEntrySuccess = id => ({
  type: EXPENSE_ENTRY_DELETE_SUCCESS,
  payload: id,
});

export const deleteExpenseEntryError = error => ({
  type: EXPENSE_ENTRY_DELETE_ERROR,
  payload: error,
});

export const deleteExpenseEntryReceipt = id => ({
  type: EXPENSE_ENTRY_RECEIPT_DELETE,
  payload: id,
});

export const deleteExpenseEntryReceiptSuccess = id => ({
  type: EXPENSE_ENTRY_RECEIPT_DELETE_SUCCESS,
  payload: id,
});

export const deleteExpenseEntryReceiptError = error => ({
  type: EXPENSE_ENTRY_RECEIPT_DELETE_ERROR,
  payload: error,
});

export const getExpenseEntryById = id => ({
  type: EXPENSE_ENTRY_GET_BY_ID,
  payload: id,
});

export const getExpenseEntryByIdSuccess = entry => ({
  type: EXPENSE_ENTRY_GET_BY_ID_SUCCESS,
  payload: entry,
});

export const getExpenseEntryByIdError = error => ({
  type: EXPENSE_ENTRY_GET_BY_ID_ERROR,
  payload: error,
});
