import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  TRF_CA_REFERRAL_ADD_NEW,
  TRF_CA_REFERRAL_ASSIGN_REFERRAL,
  TRF_CA_REFERRAL_CHANGE_STATUS,
  TRF_CA_REFERRAL_DELETE,
  TRF_CA_REFERRAL_GET_BY_ID,
  TRF_CA_REFERRAL_GET_PAGING,
  TRF_CA_REFERRAL_UPDATE,
} from './types';

import {
  addTrfCaReferralSuccess,
  addTrfCaReferralError,
  getPagingTrfCaReferralSuccess,
  getPagingTrfCaReferralError,
  getTrfCaReferralByIdSuccess,
  getTrfCaReferralByIdError,
  updateTrfCaReferralSuccess,
  updateTrfCaReferralError,
  assignRefferal,
  assignRefferalSuccess,
  assignRefferalError,
} from './actions';

const addTrfCaReferralRequest = async item => {
  return axios.post(`${Config.apiServerHost}/api/TrfCaReferral`, item, await authHeader());
};

const assignReferralRequest = async item => {
  return axios.post(
    `${Config.apiServerHost}/api/TrfCaReferral/AssignReferral`,
    item,
    await authHeader()
  );
};

const updateTrfCaReferralRequest = async item => {
  return axios.put(`${Config.apiServerHost}/api/TrfCaReferral`, item, await authHeader());
};

const getPagingTrfCaReferralRequest = async item => {
  console.log(item);
  return axios.post(`${Config.apiServerHost}/api/TrfCaReferral/paging`, item, await authHeader());
};

const getTrfCaReferralByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/TrfCaReferral/${id}`, await authHeader());
};

const changeStatusTrfCaReferralRequest = async item => {
  return axios.get(`${Config.apiServerHost}/api/TrfCaReferral/statusChange`, {
    params: item,
    headers: { ...(await authHeader()).headers },
  });
};

const deleteTrfCaReferralRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/TrfCaReferral/${id}`, await authHeader());
};

function* addNewTrfCaReferral({ payload }) {
  try {
    const { data, userIds, status, taskCategory } = payload;
    let response = yield call(addTrfCaReferralRequest, data);
    yield put(addTrfCaReferralSuccess(response.data));

    response = yield call(getPagingTrfCaReferralRequest, { userIds, status, taskCategory });
    yield put(getPagingTrfCaReferralSuccess(response.data));
  } catch (error) {
    yield put(addTrfCaReferralError(error.response.statusText));
  }
}

function* assignReferral({ payload }) {
  try {
    const { data, userIds, status, taskCategory } = payload;
    let response = yield call(assignReferralRequest, data);
    yield put(assignRefferalSuccess(response.data));
    response = yield call(getPagingTrfCaReferralRequest, { userIds, status, taskCategory });
    yield put(getPagingTrfCaReferralSuccess(response.data));
  } catch (error) {
    yield put(addTrfCaReferralError(error.response.statusText));
  }
}

function* updateTrfCaReferral({ payload }) {
  try {
    const { data, userIds, status, taskCategory } = payload;
    let response = yield call(updateTrfCaReferralRequest, data);
    response = yield call(getPagingTrfCaReferralRequest, { userIds, status, taskCategory });
    yield put(getPagingTrfCaReferralSuccess(response.data));
  } catch (error) {
    yield put(updateTrfCaReferralError(error.response.statusText));
  }
}

function* getPagingTrfCaReferral({ payload }) {
  try {
    let response = yield call(getPagingTrfCaReferralRequest, payload);
    yield put(getPagingTrfCaReferralSuccess(response.data));
  } catch (error) {
    yield put(getPagingTrfCaReferralError(error.response.statusText));
  }
}

function* getTrfCaReferralById({ payload }) {
  try {
    let response = yield call(getTrfCaReferralByIdRequest, payload);
    yield put(getTrfCaReferralByIdSuccess(response.data));
  } catch (error) {
    yield put(getTrfCaReferralByIdError(error.response.statusText));
  }
}

function* changeStatusTrfCaReferral({ payload }) {
  try {
    const { data, userIds, status, taskCategory } = payload;
    let response = yield call(changeStatusTrfCaReferralRequest, data);

    response = yield call(getPagingTrfCaReferralRequest, { userIds, status, taskCategory });
    yield put(getPagingTrfCaReferralSuccess(response.data));
  } catch (error) {
    yield put(getPagingTrfCaReferralError(error.response.statusText));
  }
}

function* deleteTrfCaReferral({ payload }) {
  try {
    const { data, userIds, status, taskCategory } = payload;
    let response = yield call(deleteTrfCaReferralRequest, data, status, taskCategory);

    response = yield call(getPagingTrfCaReferralRequest, { userIds });
    yield put(getPagingTrfCaReferralSuccess(response.data));
  } catch (error) {
    yield put(getPagingTrfCaReferralError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(TRF_CA_REFERRAL_ADD_NEW, addNewTrfCaReferral);
  yield takeEvery(TRF_CA_REFERRAL_UPDATE, updateTrfCaReferral);
  yield takeEvery(TRF_CA_REFERRAL_GET_PAGING, getPagingTrfCaReferral);
  yield takeEvery(TRF_CA_REFERRAL_GET_BY_ID, getTrfCaReferralById);
  yield takeEvery(TRF_CA_REFERRAL_CHANGE_STATUS, changeStatusTrfCaReferral);
  yield takeEvery(TRF_CA_REFERRAL_DELETE, deleteTrfCaReferral);
  yield takeEvery(TRF_CA_REFERRAL_ASSIGN_REFERRAL, assignReferral);
}
