import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  ACTIVITY_DELETE,
  ACTIVITY_EDIT,
  ACTIVITY_GET_BY_ID,
  ACTIVITY_ADD_NEW,
  ACTIVITY_GET_LIST,
} from '../actions';

import {
  getActivityListSuccess,
  getActivityListError,
  editActivitySuccess,
  editActivityError,
  addNewActivitySuccess,
  addNewActivityError,
  deleteActivitySuccess,
  deleteActivityError,
  getActivityByIdSuccess,
} from './actions';

const getActivityListRequest = async clientId => {
  return axios.get(
    `${Config.apiServerHost}/api/client/activityByClientId/${clientId}`,
    await authHeader()
  );
};

const addNewActivityReqeust = async caseNote => {
  return axios.post(
    `${Config.apiServerHost}/api/client/FileActivity`,
    caseNote,
    await authHeader()
  );
};

const editActivityRequest = async caseNote => {
  return axios.put(`${Config.apiServerHost}/api/client/FileActivity`, caseNote, await authHeader());
};

const deleteActivityRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/client/FileActivity/${id}`, await authHeader());
};

const getActivityByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/client/FileActivity/${id}`, await authHeader());
};

function* getActivityList({ payload }) {
  try {
    const response = yield call(getActivityListRequest, payload);
    yield put(getActivityListSuccess(response.data));
  } catch (error) {
    yield put(getActivityListError(error.response.statusText));
  }
}

function* addNewActivity({ payload }) {
  try {
    let response = yield call(addNewActivityReqeust, payload);
    response = yield call(getActivityByIdRequest, response.data);
    yield put(addNewActivitySuccess(response.data));
  } catch (error) {
    yield put(addNewActivityError(error.response.statusText));
  }
}

function* editActivity({ payload }) {
  try {
    let response = yield call(editActivityRequest, payload);
    response = yield call(getActivityByIdRequest, payload.id);
    yield put(editActivitySuccess(response.data));
  } catch (error) {
    yield put(editActivityError(error.response.statusText));
  }
}

function* getActivityById({ payload }) {
  try {
    const response = yield call(getActivityByIdRequest, payload);
    yield put(getActivityByIdSuccess(response.data));
  } catch (error) {
    yield put(editActivityError(error.response.statusText));
  }
}

function* deleteActivity({ payload }) {
  try {
    const response = yield call(deleteActivityRequest, payload);
    yield put(deleteActivitySuccess(response.data));
  } catch (error) {
    yield put(deleteActivityError(error.response.statusText));
  }
}

export function* watchActivityList() {
  yield takeEvery(ACTIVITY_GET_LIST, getActivityList);
}

export function* watchAddNewActivity() {
  yield takeEvery(ACTIVITY_ADD_NEW, addNewActivity);
}

export function* watchActivityById() {
  yield takeEvery(ACTIVITY_GET_BY_ID, getActivityById);
}

export function* watchEditActivity() {
  yield takeEvery(ACTIVITY_EDIT, editActivity);
}

export function* watchDeleteActivity() {
  yield takeEvery(ACTIVITY_DELETE, deleteActivity);
}

export default function* rootSaga() {
  yield all([
    fork(watchActivityList),
    fork(watchAddNewActivity),
    fork(watchActivityById),
    fork(watchEditActivity),
    fork(watchDeleteActivity),
  ]);
}
