// CAMPAIGN
export const CAMPAIGN_ADD_NEW = 'CAMPAIGN_ADD_NEW';
export const CAMPAIGN_ADD_NEW_SUCCESS = 'CAMPAIGN_ADD_NEW_SUCCESS';
export const CAMPAIGN_ADD_NEW_ERROR = 'CAMPAIGN_ADD_NEW_ERROR';

export const CAMPAIGN_GET_PAGING = 'CAMPAIGN_GET_PAGING';
export const CAMPAIGN_GET_PAGING_SUCCESS = 'CAMPAIGN_GET_PAGING_SUCCESS';
export const CAMPAIGN_GET_PAGING_ERROR = 'CAMPAIGN_GET_PAGING_ERROR';

export const CAMPAIGN_GET_BY_ID = 'CAMPAIGN_GET_BY_ID';
export const CAMPAIGN_GET_BY_ID_SUCCESS = 'CAMPAIGN_GET_BY_ID_SUCCESS';
export const CAMPAIGN_GET_BY_ID_ERROR = 'CAMPAIGN_GET_BY_ID_ERROR';

export const CAMPAIGN_UPDATE = 'CAMPAIGN_UPDATE';
export const CAMPAIGN_UPDATE_SUCCESS = 'CAMPAIGN_UPDATE_SUCCESS';
export const CAMPAIGN_UPDATE_ERROR = 'CAMPAIGN_UPDATE_ERROR';

export const CAMPAIGN_DELETE = 'CAMPAIGN_DELETE';
export const CAMPAIGN_DELETE_SUCCESS = 'CAMPAIGN_DELETE_SUCCESS';
export const CAMPAIGN_DELETE_ERROR = 'CAMPAIGN_DELETE_ERROR';

export const CAMPAIGN_STATUS_CHANGE = 'CAMPAIGN_STATUS_CHANGE';
export const CAMPAIGN_STATUS_CHANGE_SUCCESS = 'CAMPAIGN_STATUS_CHANGE_SUCCESS';
export const CAMPAIGN_STATUS_CHANGE_ERROR = 'CAMPAIGN_STATUS_CHANGE_ERROR';

export const CAMPAIGN_DASHBOARD_GET_PAGIN = 'CAMPAIGN_DASHBOARD_GET_PAGIN';
export const CAMPAIGN_DASHBOARD_GET_PAGIN_SUCCESS = 'CAMPAIGN_DASHBOARD_GET_PAGIN_SUCCESS';
export const CAMPAIGN_DASHBOARD_GET_PAGIN_ERROR = 'CAMPAIGN_DASHBOARD_GET_PAGIN_ERROR';
