import { logger } from '../../logger';
import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  CAMS_IMPORT_DELETE,
  CAMS_IMPORT_EDIT,
  CAMS_IMPORT_GET_BY_ID,
  CAMS_IMPORT_ADD_NEW,
  CAMS_IMPORT_GET_LIST,
  CAMS_IMPORT_READ_FILENAME,
  CAMS_IMPORT_IMPORT_ALL_CLIENTS,
  CAMS_IMPORT_IMPORT_DFECLT,
  CAMS_SYNC_DATA_COJG_DFTCLT_CLIENTS,
  CAMS_IMPORT_IMPORT_DFESPN,
  CAMS_IMPORT_IMPORT_DFFOL,
  CAMS_SYNC_DATA_COJG_DFTSPN,
  CAMS_IMPORT_IMPORT_ESDFRIP,
  CAMS_SYNC_DATA_ES_DFSPN,
  CAMS_IMPORT_IMPORT_ESDFRIC,
  CAMS_SYNC_DATA_ES_DFSPI,
  CAMS_SYNC_DATA_ES_DFRIS,
  CAMS_IMPORT_DETAIL,
  CAMS_IMPORT_GET_CLIENT_LIST_BY_PARAMS,
  CAMS_IMPORT_GET_EMPLOYER_LIST_BY_PARAMS,
} from '../actions';

import {
  getCaMSImportListSuccess,
  getCaMSImportListError,
  editCaMSImportSuccess,
  editCaMSImportError,
  addNewCaMSImportSuccess,
  addNewCaMSImportError,
  deleteCaMSImportSuccess,
  deleteCaMSImportError,
  getCaMSImportByIdSuccess,
  readDataFromCaMSImportFileSuccess,
  readDataFromCaMSImportFileError,
  importCaMSClientsSuccess,
  importCaMSClientsError,
  importCaMSDFECLTError,
  importCaMSDFECLTSuccess,
  syncDataFromCOJGDFTCLTToClientSuccess,
  syncDataFromCOJGDFTCLTToClientError,
  importCaMSDFESPNSuccess,
  importCaMSDFESPNError,
  syncDataDFTSPNSuccess,
  syncDataDFTSPNError,
  importCaMSDFFOLSuccess,
  importCaMSDFFOLError,
  importCaMSESDFRIPSuccess,
  importCaMSESDFRIPError,
  syncDataESDFSPNSuccess,
  syncDataESDFSPNError,
  importCaMSESDFRICSuccess,
  importCaMSESDFRICError,
  syncDataESDFSPISuccess,
  syncDataESDFSPIError,
  syncDataESDFRISSuccess,
  syncDataESDFRISError,
  getCaMSImportDetailSuccess,
  getCaMSImportDetailError,
  getClientsByParamSuccess,
  getClientsByParamError,
  getEmployersByParamSuccess,
  getEmployersByParamError,
} from './actions';

const importCaMSESDFRICRequest = async data => {
  return axios.post(
    `${Config.apiServerHost}/api/CaMSImport/import-es-dfric`,
    data,
    await authHeader()
  );
};

const importCaMSESDFRIPRequest = async data => {
  return axios.post(
    `${Config.apiServerHost}/api/CaMSImport/import-es-dfrip`,
    data,
    await authHeader()
  );
};

const importCaMSDFFOLRequest = async data => {
  return axios.post(
    `${Config.apiServerHost}/api/CaMSImport/import-nps-dffol`,
    data,
    await authHeader()
  );
};

const importCaMSDFESPNRequest = async data => {
  return axios.post(
    `${Config.apiServerHost}/api/CaMSImport/import-cojg-dfespn`,
    data,
    await authHeader()
  );
};

const importCaMSDFECLTRequest = async data => {
  return axios.post(
    `${Config.apiServerHost}/api/CaMSImport/import-cojg-dfeclt`,
    data,
    await authHeader()
  );
};

const importCaMSClientsRequest = async clients => {
  return axios.post(
    `${Config.apiServerHost}/api/CaMSImport/import-nps-dfclt`,
    clients,
    await authHeader()
  );
};

const readDataFromCaMSImportFileRequest = async query => {
  let qs = '';
  for (const key in query) {
    if (query.hasOwnProperty(key)) {
      const value = query[key];
      qs += `${key}=${value}&`;
    }
  }

  qs = qs.substring(0, qs.length - 1); //chop off last "&"
  return axios.get(
    `${Config.apiServerHost}/api/CaMSImport/getdata-by-filename?${qs}`,
    await authHeader()
  );
};

const getCaMSImportListRequest = async () => {
  return axios.get(`${Config.apiServerHost}/api/CaMSImport`, await authHeader());
};

const addNewCaMSImportReqeust = async CaMSImport => {
  return axios.post(`${Config.apiServerHost}/api/CaMSImport`, CaMSImport, await authHeader());
};

const editCaMSImportRequest = async CaMSImport => {
  return axios.post(`${Config.apiServerHost}/api/CaMSImport`, CaMSImport, await authHeader());
};

const deleteCaMSImportRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/CaMSImport/${id}`, await authHeader());
};

const getCaMSImportByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/CaMSImport/${id}`, await authHeader());
};

const getCaMSImportDetailRequest = async id => {
  return axios.get(
    `${Config.apiServerHost}/api/CaMSImport/${id}/fetch-import-data`,
    await authHeader()
  );
};

const getClientsByParamRequest = async query => {
  let qs = '';
  for (const key in query) {
    if (query.hasOwnProperty(key)) {
      const value = query[key];
      qs += `${key}=${value}&`;
    }
  }

  qs = qs.substring(0, qs.length - 1); //chop off last "&"
  return axios.get(`${Config.apiServerHost}/api/CaMSImport/fetch-client?${qs}`, await authHeader());
};

const getEmployersByParamRequest = async query => {
  let qs = '';
  for (const key in query) {
    if (query.hasOwnProperty(key)) {
      const value = query[key];
      qs += `${key}=${value}&`;
    }
  }

  qs = qs.substring(0, qs.length - 1); //chop off last "&"
  return axios.get(
    `${Config.apiServerHost}/api/CaMSImport/fetch-employer?${qs}`,
    await authHeader()
  );
};

function* importCaMSESDFRIC({ payload }) {
  try {
    const response = yield call(importCaMSESDFRICRequest, payload);
    logger.debug(response.data);
    yield put(importCaMSESDFRICSuccess(response.data));
  } catch (error) {
    yield put(importCaMSESDFRICError(error));
  }
}

function* importCaMSESDFRIP({ payload }) {
  try {
    const response = yield call(importCaMSESDFRIPRequest, payload);
    yield put(importCaMSESDFRIPSuccess(response.data));
  } catch (error) {
    yield put(importCaMSESDFRIPError(error));
  }
}

function* importCaMSDFFOL({ payload }) {
  try {
    const response = yield call(importCaMSDFFOLRequest, payload);
    yield put(importCaMSDFFOLSuccess(response.data));
  } catch (error) {
    yield put(importCaMSDFFOLError(error));
  }
}

function* importCaMSDFESPN({ payload }) {
  try {
    const response = yield call(importCaMSDFESPNRequest, payload);
    yield put(importCaMSDFESPNSuccess(response.data));
  } catch (error) {
    yield put(importCaMSDFESPNError(error));
  }
}

function* importCaMSDFECLT({ payload }) {
  try {
    const response = yield call(importCaMSDFECLTRequest, payload);
    yield put(importCaMSDFECLTSuccess(response.data));
  } catch (error) {
    yield put(importCaMSDFECLTError(error));
  }
}

function* importCaMSClients({ payload }) {
  try {
    const response = yield call(importCaMSClientsRequest, payload);
    yield put(importCaMSClientsSuccess(response.data));
  } catch (error) {
    yield put(importCaMSClientsError(error));
  }
}

const syncDataFromCOJGDFTCLTToClientRequest = async clients => {
  return axios.post(
    `${Config.apiServerHost}/api/CaMSImport/import-cojg-dftclt`,
    clients,
    await authHeader()
  );
};

function* syncDataFromCOJGDFTCLTToClient({ payload }) {
  try {
    const response = yield call(syncDataFromCOJGDFTCLTToClientRequest, payload);
    yield put(syncDataFromCOJGDFTCLTToClientSuccess(response.data));
  } catch (error) {
    yield put(syncDataFromCOJGDFTCLTToClientError(error));
  }
}

const syncDataDFTSPNRequest = async clients => {
  return axios.post(
    `${Config.apiServerHost}/api/CaMSImport/import-cojg-dftspn`,
    clients,
    await authHeader()
  );
};

function* syncDataDFTSPN({ payload }) {
  try {
    const response = yield call(syncDataDFTSPNRequest, payload);
    yield put(syncDataDFTSPNSuccess(response.data));
  } catch (error) {
    yield put(syncDataDFTSPNError(error));
  }
}

const syncDataESDFSPNRequest = async clients => {
  return axios.post(
    `${Config.apiServerHost}/api/CaMSImport/import-es-dfspn`,
    clients,
    await authHeader()
  );
};

function* syncDataESDFSPN({ payload }) {
  try {
    const response = yield call(syncDataESDFSPNRequest, payload);
    yield put(syncDataESDFSPNSuccess(response.data));
  } catch (error) {
    yield put(syncDataESDFSPNError(error));
  }
}

const syncDataESDFSPIRequest = async clients => {
  return axios.post(
    `${Config.apiServerHost}/api/CaMSImport/import-es-dfspi`,
    clients,
    await authHeader()
  );
};

function* syncDataESDFSPI({ payload }) {
  try {
    const response = yield call(syncDataESDFSPIRequest, payload);
    yield put(syncDataESDFSPISuccess(response.data));
  } catch (error) {
    yield put(syncDataESDFSPIError(error));
  }
}

const syncDataESDFRISRequest = async clients => {
  return axios.post(
    `${Config.apiServerHost}/api/CaMSImport/import-es-dfris`,
    clients,
    await authHeader()
  );
};

function* syncDataESDFRIS({ payload }) {
  try {
    const response = yield call(syncDataESDFRISRequest, payload);
    yield put(syncDataESDFRISSuccess(response.data));
  } catch (error) {
    yield put(syncDataESDFRISError(error));
  }
}

function* readDataFromCaMSImportFile({ payload }) {
  try {
    const response = yield call(readDataFromCaMSImportFileRequest, payload);
    yield put(readDataFromCaMSImportFileSuccess(response.data));
  } catch (error) {
    yield put(readDataFromCaMSImportFileError(error.response.statusText));
  }
}

function* getCaMSImportList() {
  try {
    const response = yield call(getCaMSImportListRequest);
    yield put(getCaMSImportListSuccess(response.data));
  } catch (error) {
    yield put(getCaMSImportListError(error.response.statusText));
  }
}

function* addNewCaMSImport({ payload }) {
  try {
    let response = yield call(addNewCaMSImportReqeust, payload);
    response = yield call(getCaMSImportByIdRequest, response.data);
    yield put(addNewCaMSImportSuccess(response.data));

    const response2 = yield call(getCaMSImportListRequest);
    yield put(getCaMSImportListSuccess(response2.data));
  } catch (error) {
    yield put(addNewCaMSImportError(error.response.statusText));
  }
}

function* editCaMSImport({ payload }) {
  try {
    let response = yield call(editCaMSImportRequest, payload);
    response = yield call(getCaMSImportByIdRequest, payload.id);
    yield put(editCaMSImportSuccess(response.data));
  } catch (error) {
    yield put(editCaMSImportError(error.response.statusText));
  }
}

function* getCaMSImportById({ payload }) {
  try {
    const response = yield call(getCaMSImportByIdRequest, payload);
    yield put(getCaMSImportByIdSuccess(response.data));
  } catch (error) {
    yield put(editCaMSImportError(error.response.statusText));
  }
}

function* deleteCaMSImport({ payload }) {
  try {
    const response = yield call(deleteCaMSImportRequest, payload);
    yield put(deleteCaMSImportSuccess(response.data));
  } catch (error) {
    yield put(deleteCaMSImportError(error.response.statusText));
  }
}

function* getCaMSImportDetail({ payload }) {
  try {
    const response = yield call(getCaMSImportDetailRequest, payload);
    yield put(getCaMSImportDetailSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(getCaMSImportDetailError(error));
  }
}

function* getClientsByParam({ payload }) {
  try {
    const response = yield call(getClientsByParamRequest, payload);
    yield put(getClientsByParamSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(getClientsByParamError(error));
  }
}

function* getEmployersByParam({ payload }) {
  try {
    const response = yield call(getEmployersByParamRequest, payload);
    yield put(getEmployersByParamSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(getEmployersByParamError(error));
  }
}

export function* watchImportCaMSESDFRIC() {
  yield takeEvery(CAMS_IMPORT_IMPORT_ESDFRIC, importCaMSESDFRIC);
}

export function* watchImportCaMSESDFRIP() {
  yield takeEvery(CAMS_IMPORT_IMPORT_ESDFRIP, importCaMSESDFRIP);
}

export function* watchImportCaMSDFFOL() {
  yield takeEvery(CAMS_IMPORT_IMPORT_DFFOL, importCaMSDFFOL);
}

export function* watchImportCaMSDFESPN() {
  yield takeEvery(CAMS_IMPORT_IMPORT_DFESPN, importCaMSDFESPN);
}

export function* watchImportCaMSDFECLT() {
  yield takeEvery(CAMS_IMPORT_IMPORT_DFECLT, importCaMSDFECLT);
}

export function* watchImportCaMSClients() {
  yield takeEvery(CAMS_IMPORT_IMPORT_ALL_CLIENTS, importCaMSClients);
}

export function* watchSyncDataFromCOJGDFTCLTToClient() {
  yield takeEvery(CAMS_SYNC_DATA_COJG_DFTCLT_CLIENTS, syncDataFromCOJGDFTCLTToClient);
}
export function* watchSyncDataCOJGDFTSPN() {
  yield takeEvery(CAMS_SYNC_DATA_COJG_DFTSPN, syncDataDFTSPN);
}

export function* watchSyncDataESDFSPN() {
  yield takeEvery(CAMS_SYNC_DATA_ES_DFSPN, syncDataESDFSPN);
}

export function* watchSyncDataESDFSPI() {
  yield takeEvery(CAMS_SYNC_DATA_ES_DFSPI, syncDataESDFSPI);
}

export function* watchSyncDataESDFRIS() {
  yield takeEvery(CAMS_SYNC_DATA_ES_DFRIS, syncDataESDFRIS);
}

export function* watchReadDataFromCaMSImportFile() {
  yield takeEvery(CAMS_IMPORT_READ_FILENAME, readDataFromCaMSImportFile);
}

export function* watchCaMSImportList() {
  yield takeEvery(CAMS_IMPORT_GET_LIST, getCaMSImportList);
}

export function* watchAddNewCaMSImport() {
  yield takeEvery(CAMS_IMPORT_ADD_NEW, addNewCaMSImport);
}

export function* watchCaMSImportById() {
  yield takeEvery(CAMS_IMPORT_GET_BY_ID, getCaMSImportById);
}

export function* watchCaMSImportDetail() {
  yield takeEvery(CAMS_IMPORT_DETAIL, getCaMSImportDetail);
}

export function* watchEditCaMSImport() {
  yield takeEvery(CAMS_IMPORT_EDIT, editCaMSImport);
}

export function* watchDeleteCaMSImport() {
  yield takeEvery(CAMS_IMPORT_DELETE, deleteCaMSImport);
}

export function* watchClientsByParam() {
  yield takeEvery(CAMS_IMPORT_GET_CLIENT_LIST_BY_PARAMS, getClientsByParam);
}

export function* watchEmployersByParam() {
  yield takeEvery(CAMS_IMPORT_GET_EMPLOYER_LIST_BY_PARAMS, getEmployersByParam);
}

export default function* rootSaga() {
  yield all([
    fork(watchCaMSImportList),
    fork(watchAddNewCaMSImport),
    fork(watchCaMSImportById),
    fork(watchCaMSImportDetail),
    fork(watchEditCaMSImport),
    fork(watchDeleteCaMSImport),
    fork(watchReadDataFromCaMSImportFile),
    fork(watchImportCaMSClients),
    fork(watchImportCaMSDFECLT),
    fork(watchSyncDataFromCOJGDFTCLTToClient),
    fork(watchImportCaMSDFESPN),
    fork(watchImportCaMSDFFOL),
    fork(watchSyncDataCOJGDFTSPN),
    fork(watchImportCaMSESDFRIP),
    fork(watchSyncDataESDFSPN),
    fork(watchImportCaMSESDFRIC),
    fork(watchSyncDataESDFSPI),
    fork(watchSyncDataESDFRIS),
    fork(watchClientsByParam),
    fork(watchEmployersByParam),
  ]);
}
