import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../helpers/auth-header';
import axios from 'axios';
import Config from '../../config';

import { SHIFTS_GET_LIST, SHIFTS_EDIT_ITEM, SHIFTS_DELETE_ITEM } from '../actions';

import { getShiftsListSuccess, getShiftsListError, editShiftsItemError } from './actions';

const getShiftsListRequest = async () => {
  return await axios.get(`${Config.apiServerHost}/api/common/shifts`, await authHeader());
};

function* getShiftsListItems() {
  try {
    const response = yield call(getShiftsListRequest);
    yield put(getShiftsListSuccess(response.data));
  } catch (error) {
    yield put(getShiftsListError(error));
  }
}

const poshShiftsListRequest = async shifts => {
  return await axios.post(`${Config.apiServerHost}/api/common/shifts`, shifts, await authHeader());
};

function* editShiftsItem({ payload }) {
  try {
    yield call(poshShiftsListRequest, payload);
    const res = yield call(getShiftsListRequest);
    yield put(getShiftsListSuccess(res.data));
  } catch (error) {
    yield put(editShiftsItemError(error));
  }
}

const deleteShiftsListRequest = async id => {
  return await axios.delete(`${Config.apiServerHost}/api/common/shifts/${id}`, await authHeader());
};
function* deleteShiftsItem({ payload }) {
  try {
    yield call(deleteShiftsListRequest, payload);
    const res = yield call(getShiftsListRequest);
    yield put(getShiftsListSuccess(res.data));
  } catch (error) {
    yield put(editShiftsItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(SHIFTS_GET_LIST, getShiftsListItems);
}

export function* wathcEditItem() {
  yield takeEvery(SHIFTS_EDIT_ITEM, editShiftsItem);
}
export function* wathcDeleteItem() {
  yield takeEvery(SHIFTS_DELETE_ITEM, deleteShiftsItem);
}

export default function* rootSaga() {
  yield all([fork(watchGetList), fork(wathcEditItem), fork(wathcDeleteItem)]);
}
