import {
  SITE_ADD_NEW,
  SITE_ADD_NEW_SUCCESS,
  SITE_ADD_NEW_ERROR,
  SITE_DELETE,
  SITE_DELETE_ERROR,
  SITE_DELETE_SUCCESS,
  SITE_EDIT,
  SITE_EDIT_ERROR,
  SITE_EDIT_SUCCESS,
  SITE_GET_BY_ID,
  SITE_GET_BY_ID_ERROR,
  SITE_GET_BY_ID_SUCCESS,
  SITE_GET_LIST,
  SITE_GET_LIST_ERROR,
  SITE_GET_LIST_SUCCESS,
  SITE_ADD_NEW_REQUEST,
  SITE_QUERY,
  SITE_QUERY_SUCCESS,
  SITE_QUERY_ERROR,
} from '../actions';

// Site
export const getSiteQuery = query => ({
  type: SITE_QUERY,
  payload: query,
});

export const getSiteQuerySuccess = caseNotes => ({
  type: SITE_QUERY_SUCCESS,
  payload: caseNotes,
});

export const getSiteQueryError = error => ({
  type: SITE_QUERY_ERROR,
  payload: error,
});

export const getSiteList = clientId => ({
  type: SITE_GET_LIST,
  payload: clientId,
});

export const getSiteListSuccess = CaseNotes => ({
  type: SITE_GET_LIST_SUCCESS,
  payload: CaseNotes,
});

export const getSiteListError = error => ({
  type: SITE_GET_LIST_ERROR,
  payload: error,
});

export const addNewSite = caseNote => ({
  type: SITE_ADD_NEW,
  payload: caseNote,
});

export const addNewSiteRequest = () => ({
  type: SITE_ADD_NEW_REQUEST,
});

export const addNewSiteSuccess = CaseNote => ({
  type: SITE_ADD_NEW_SUCCESS,
  payload: CaseNote,
});

export const addNewSiteError = error => ({
  type: SITE_ADD_NEW_ERROR,
  payload: error,
});

export const editSite = CaseNote => ({
  type: SITE_EDIT,
  payload: CaseNote,
});

export const editSiteSuccess = caseNote => ({
  type: SITE_EDIT_SUCCESS,
  payload: caseNote,
});

export const editSiteError = error => ({
  type: SITE_EDIT_ERROR,
  payload: error,
});

export const deleteSite = id => ({
  type: SITE_DELETE,
  payload: id,
});

export const deleteSiteSuccess = id => ({
  type: SITE_DELETE_SUCCESS,
  payload: id,
});

export const deleteSiteError = error => ({
  type: SITE_DELETE_ERROR,
  payload: error,
});

export const getSiteById = id => ({
  type: SITE_GET_BY_ID,
  payload: id,
});

export const getSiteByIdSuccess = caseNote => ({
  type: SITE_GET_BY_ID_SUCCESS,
  payload: caseNote,
});

export const getSiteByIdError = error => ({
  type: SITE_GET_BY_ID_ERROR,
  payload: error,
});
