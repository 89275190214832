import {
  CLIENT_COURSE_ADD_NEW,
  CLIENT_COURSE_ADD_NEW_SUCCESS,
  CLIENT_COURSE_ADD_NEW_ERROR,
  CLIENT_COURSE_EDIT,
  CLIENT_COURSE_EDIT_ERROR,
  CLIENT_COURSE_EDIT_SUCCESS,
  CLIENT_COURSE_GET_BY_QUERY,
  CLIENT_COURSE_GET_BY_ID,
  CLIENT_COURSE_GET_BY_QUERY_SUCCESS,
  CLIENT_COURSE_GET_BY_QUERY_ERROR,
  CLIENT_COURSE_GET_BY_ID_SUCCESS,
  CLIENT_COURSE_ADD_NEW_REQUEST,
  CLIENT_COURSE_DELETE,
  CLIENT_COURSE_DELETE_SUCCESS,
  CLIENT_COURSE_DELETE_ERROR,
  CLIENT_COURSE_GET_BY_ID_ERROR,
} from './types';

// ClientCOURSE

export const addNewClientCourse = items => ({
  type: CLIENT_COURSE_ADD_NEW,
  payload: items,
});

export const addNewClientCourseRequest = () => ({
  type: CLIENT_COURSE_ADD_NEW_REQUEST,
});

export const addNewClientCourseSuccess = items => ({
  type: CLIENT_COURSE_ADD_NEW_SUCCESS,
  payload: items,
});

export const addNewClientCourseError = error => ({
  type: CLIENT_COURSE_ADD_NEW_ERROR,
  payload: error,
});

export const editClientCourse = item => ({
  type: CLIENT_COURSE_EDIT,
  payload: item,
});

export const editClientCourseSuccess = item => ({
  type: CLIENT_COURSE_EDIT_SUCCESS,
  payload: item,
});

export const editClientCourseError = error => ({
  type: CLIENT_COURSE_EDIT_ERROR,
  payload: error,
});

export const getClientCourseByQuery = query => ({
  type: CLIENT_COURSE_GET_BY_QUERY,
  payload: query,
});

export const getClientCourseByQuerySuccess = item => ({
  type: CLIENT_COURSE_GET_BY_QUERY_SUCCESS,
  payload: item,
});

export const getClientCourseByQueryError = error => ({
  type: CLIENT_COURSE_GET_BY_QUERY_ERROR,
  payload: error,
});

export const getClientCourseById = id => ({
  type: CLIENT_COURSE_GET_BY_ID,
  payload: id,
});

export const getClientCourseByIdSuccess = item => ({
  type: CLIENT_COURSE_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getClientCourseByIdError = error => ({
  type: CLIENT_COURSE_GET_BY_ID_ERROR,
  payload: error,
});

export const deleteClientCourseById = id => ({
  type: CLIENT_COURSE_DELETE,
  payload: id,
});

export const deleteClientCourseByIdSuccess = item => ({
  type: CLIENT_COURSE_DELETE_SUCCESS,
  payload: item,
});

export const deleteClientCourseByIdErrorr = item => ({
  type: CLIENT_COURSE_DELETE_ERROR,
  payload: item,
});
