import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { call, put, takeEvery } from 'redux-saga/effects';
import { GET_IES_DASHBOARD } from './types';
import Config from '../../config';
import { buildUrl } from '../../helpers/Utils';

import { getIESDashboardSuccess, getIESDashboardError } from './actions';

const getDashboardReportOptionsRequest = async () => {
  return axios.get(`${Config.apiServiceHost}/api/Dashboard/dashboardReport`, {
    headers: { ...(await authHeader()).headers },
  });
};

const getReferralInChartDataRequest = async query => {
  let url = `${Config.apiServiceHost}/api/Dashboard/referralInChartData`;
  return axios.post(url, query, await authHeader());
};

const getClientCompletedSurveyChartDataRequest = async query => {
  return axios.post(
    `${Config.apiServiceHost}/api/Dashboard/clientCompletedSurveyChartData`,
    query,
    {
      headers: { ...(await authHeader()).headers },
    }
  );
};

const getClientSatisfactionBySpecializePopulationChartDataRequest = async query => {
  return axios.post(
    `${Config.apiServiceHost}/api/Dashboard/clientSatisfactionBySpecializePopulationChartData`,
    query,
    {
      headers: { ...(await authHeader()).headers },
    }
  );
};

const getHiringPerSectorChartDataRequest = async query => {
  const url = `${Config.apiServiceHost}/api/Dashboard/hiringPerSectorChartData`;
  return axios.post(url, query, await authHeader());
};

const getPlannedItemBudgetTrackingDataRequest = async query => {
  const url = `${Config.apiServiceHost}/api/Dashboard/plannedItemBudgetTrackingData`;
  return axios.post(url, query, await authHeader());
};

const getUserCompletedTrainingDataRequest = async query => {
  return axios.get(`${Config.apiServerHost}/api/Dashboard/userCompletedTrainingData`, {
    params: query,
    headers: { ...(await authHeader()).headers },
  });
};

const getInsightChartDataRequest = async query => {
  return axios.get(`${Config.apiServerHost}/api/Dashboard/insightChartData`, {
    params: query,
    headers: { ...(await authHeader()).headers },
  });
};

const getCaseActivityStateDataDataRequest = async query => {
  return axios.get(`${Config.apiServerHost}/api/Dashboard/caseActivityStateData`, {
    params: query,
    headers: { ...(await authHeader()).headers },
  });
};

const updateDashboardReportOptionsRequest = async data => {
  return axios.post(`${Config.apiServerHost}/api/Dashboard/dashboardReport`, data, {
    headers: { ...(await authHeader()).headers },
  });
};

let currentCancelToken;

const getIESDashboardRequest = async payload => {
  // If there's an ongoing request, cancel it
  if (currentCancelToken) {
    console.log('Operation canceled due to new request.');
    currentCancelToken.cancel('Operation canceled due to new request.');
  }

  // Create a new CancelToken source
  currentCancelToken = axios.CancelToken.source();

  return axios.post(`${Config.apiServiceHost}/api/Dashboard/iesDashBoard`, payload, {
    cancelToken: currentCancelToken.token,
    headers: { ...(await authHeader()).headers },
  });
};

const getJobSeekerProfileRequest = async clientId => {
  return axios.get(`${Config.apiServerHost}/api/Dashboard/jobSeekerProfile/${clientId}`, {
    headers: { ...(await authHeader()).headers },
  });
};

function* getIESDashboard({ payload }) {
  try {
    const response = yield call(getIESDashboardRequest, payload);
    yield put(getIESDashboardSuccess(response.data));
  } catch (error) {
    console.log(error);
    let errorMessage = error.response?.data?.message ?? 'something went wrong.';
    yield put(getIESDashboardError(errorMessage));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_IES_DASHBOARD, getIESDashboard);
}

export {
  getDashboardReportOptionsRequest,
  updateDashboardReportOptionsRequest,
  getReferralInChartDataRequest,
  getInsightChartDataRequest,
  getCaseActivityStateDataDataRequest,
  getUserCompletedTrainingDataRequest,
  getHiringPerSectorChartDataRequest,
  getClientSatisfactionBySpecializePopulationChartDataRequest,
  getClientCompletedSurveyChartDataRequest,
  getPlannedItemBudgetTrackingDataRequest,
  getJobSeekerProfileRequest,
};
