import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { saveAs } from 'file-saver';
import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  STAKE_HOLDER_MEETING_NOTE_ADD_NEW,
  STAKE_HOLDER_MEETING_NOTE_CHANGE_STATUS,
  STAKE_HOLDER_MEETING_NOTE_DELETE,
  STAKE_HOLDER_MEETING_NOTE_GET_BY_ID,
  STAKE_HOLDER_MEETING_NOTE_GET_PAGING,
  STAKE_HOLDER_MEETING_NOTE_UPDATE,
} from './types';

import {
  addStakeHolderMeetingNoteSuccess,
  addStakeHolderMeetingNoteError,
  getPagingStakeHolderMeetingNoteSuccess,
  getPagingStakeHolderMeetingNoteError,
  getStakeHolderMeetingNoteByIdSuccess,
  getStakeHolderMeetingNoteByIdError,
  updateStakeHolderMeetingNoteSuccess,
  updateStakeHolderMeetingNoteError,
} from './actions';

const addStakeHolderMeetingNoteRequest = async item => {
  return axios.post(`${Config.apiServerHost}/api/StakeHolderMeetingNote`, item, {
    headers: { ...(await authHeader()).headers },
  });
};

const updateStakeHolderMeetingNoteRequest = async item => {
  return axios.put(`${Config.apiServerHost}/api/StakeHolderMeetingNote`, item, {
    headers: { ...(await authHeader()).headers },
  });
};

const getPagingStakeHolderMeetingNoteRequest = async query => {
  return axios.get(`${Config.apiServerHost}/api/StakeHolderMeetingNote/paging`, {
    params: query,
    headers: { ...(await authHeader()).headers },
  });
};

const getStakeHolderMeetingNoteByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/StakeHolderMeetingNote/${id}`, {
    headers: { ...(await authHeader()).headers },
  });
};

const changeStatusStakeHolderMeetingNoteRequest = async item => {
  return axios.get(`${Config.apiServerHost}/api/StakeHolderMeetingNote/statusChange`, {
    params: item,
    headers: { ...(await authHeader()).headers },
  });
};

const deleteStakeHolderMeetingNoteRequest = async id => {
  return axios.delete(
    `${Config.apiServerHost}/api/StakeHolderMeetingNote/${id}`,
    await authHeader()
  );
};

const exportMeetingNotesToPdfRequest = async (params, fileName) => {
  axios
    .get(`${Config.apiServerHost}/api/StakeHolderMeetingNote/exportToPdf`, {
      params,
      responseType: 'blob',
      headers: {
        ...(await authHeader()).headers,
      },
    })
    .then(res => {
      if (res.data) {
        saveAs(new Blob([res.data]), fileName);
      }
    });
};

const exportMeetingNotesToExcelRequest = async (params, fileName) => {
  axios
    .get(`${Config.apiServerHost}/api/StakeHolderMeetingNote/exportToExcel`, {
      params,
      responseType: 'blob',
      headers: {
        ...(await authHeader()).headers,
      },
    })
    .then(res => {
      if (res.data) {
        saveAs(new Blob([res.data]), fileName);
      }
    });
};

function* addNewStakeHolderMeetingNote({ payload }) {
  try {
    const { data, stakeHolderType, stakeHolderId } = payload;
    let response = yield call(addStakeHolderMeetingNoteRequest, data);
    yield put(addStakeHolderMeetingNoteSuccess(response.data));

    response = yield call(getPagingStakeHolderMeetingNoteRequest, {
      stakeHolderType,
      stakeHolderId,
    });
    yield put(getPagingStakeHolderMeetingNoteSuccess(response.data));
  } catch (error) {
    yield put(addStakeHolderMeetingNoteError(error.response.statusText));
  }
}

function* updateStakeHolderMeetingNote({ payload }) {
  try {
    const { data, stakeHolderType, stakeHolderId } = payload;
    let response = yield call(updateStakeHolderMeetingNoteRequest, data);
    yield put(updateStakeHolderMeetingNoteSuccess(response.data));

    response = yield call(getPagingStakeHolderMeetingNoteRequest, {
      stakeHolderType,
      stakeHolderId,
    });
    yield put(getPagingStakeHolderMeetingNoteSuccess(response.data));
  } catch (error) {
    yield put(updateStakeHolderMeetingNoteError(error.response.statusText));
  }
}

function* getPagingStakeHolderMeetingNote({ payload }) {
  try {
    let response = yield call(getPagingStakeHolderMeetingNoteRequest, payload);
    yield put(getPagingStakeHolderMeetingNoteSuccess(response.data));
  } catch (error) {
    yield put(getPagingStakeHolderMeetingNoteError(error.response.statusText));
  }
}

function* getStakeHolderMeetingNoteById({ payload }) {
  try {
    let response = yield call(getStakeHolderMeetingNoteByIdRequest, payload);
    yield put(getStakeHolderMeetingNoteByIdSuccess(response.data));
  } catch (error) {
    yield put(getStakeHolderMeetingNoteByIdError(error.response.statusText));
  }
}

function* changeStatusStakeHolderMeetingNote({ payload }) {
  try {
    const { data, stakeHolderType, stakeHolderId } = payload;
    let response = yield call(changeStatusStakeHolderMeetingNoteRequest, data);

    response = yield call(getPagingStakeHolderMeetingNoteRequest, {
      stakeHolderType,
      stakeHolderId,
    });
    yield put(getPagingStakeHolderMeetingNoteSuccess(response.data));
  } catch (error) {
    yield put(getPagingStakeHolderMeetingNoteError(error.response.statusText));
  }
}

function* deleteStakeHolderMeetingNote({ payload }) {
  try {
    const { data, stakeHolderType, stakeHolderId } = payload;
    let response = yield call(deleteStakeHolderMeetingNoteRequest, data);

    response = yield call(getPagingStakeHolderMeetingNoteRequest, {
      stakeHolderType,
      stakeHolderId,
    });
    yield put(getPagingStakeHolderMeetingNoteSuccess(response.data));
  } catch (error) {
    yield put(getPagingStakeHolderMeetingNoteError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(STAKE_HOLDER_MEETING_NOTE_ADD_NEW, addNewStakeHolderMeetingNote);
  yield takeEvery(STAKE_HOLDER_MEETING_NOTE_UPDATE, updateStakeHolderMeetingNote);
  yield takeEvery(STAKE_HOLDER_MEETING_NOTE_GET_PAGING, getPagingStakeHolderMeetingNote);
  yield takeEvery(STAKE_HOLDER_MEETING_NOTE_GET_BY_ID, getStakeHolderMeetingNoteById);
  yield takeEvery(STAKE_HOLDER_MEETING_NOTE_CHANGE_STATUS, changeStatusStakeHolderMeetingNote);
  yield takeEvery(STAKE_HOLDER_MEETING_NOTE_DELETE, deleteStakeHolderMeetingNote);
}

export { exportMeetingNotesToPdfRequest, exportMeetingNotesToExcelRequest };
