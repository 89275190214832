import {
  TARGET_SCHEME_ADD_NEW,
  TARGET_SCHEME_ADD_NEW_SUCCESS,
  TARGET_SCHEME_ADD_NEW_ERROR,
  TARGET_SCHEME_EDIT,
  TARGET_SCHEME_EDIT_SUCCESS,
  TARGET_SCHEME_EDIT_ERROR,
  TARGET_SCHEME_GET_BY_QUERY,
  TARGET_SCHEME_GET_BY_ID,
  TARGET_SCHEME_GET_BY_ID_SUCCESS,
  TARGET_SCHEME_GET_BY_ID_ERROR,
  TARGET_SCHEME_GET_BY_QUERY_SUCCESS,
  TARGET_SCHEME_GET_BY_QUERY_ERROR,
  TARGET_SCHEME_DELETE,
  TARGET_SCHEME_DELETE_ERROR,
  TARGET_SCHEME_GET_OPTIONS_SUCCESS,
} from './types';
// item
const initialState = {
  item: null,
  items: [],
  itemOptions: [],
  loading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
  total: 0,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case TARGET_SCHEME_ADD_NEW:
    case TARGET_SCHEME_DELETE:
    case TARGET_SCHEME_GET_BY_ID:
    case TARGET_SCHEME_GET_BY_QUERY:
      return {
        ...state,
        loading: true,
        error: null,
        item: null,
      };
    case TARGET_SCHEME_GET_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        itemOptions: payload,
      };
    case TARGET_SCHEME_EDIT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TARGET_SCHEME_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        item: null,
        error: null,
      };
    case TARGET_SCHEME_ADD_NEW_SUCCESS:
    case TARGET_SCHEME_GET_BY_QUERY_SUCCESS:
      const { itemCount, pageCount, total, data } = payload;
      return {
        ...state,
        loading: false,
        items: data,
        total,
        itemCount,
        pageCount,
      };

    case TARGET_SCHEME_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
      };

    case TARGET_SCHEME_ADD_NEW_ERROR:
    case TARGET_SCHEME_DELETE_ERROR:
    case TARGET_SCHEME_EDIT_ERROR:
    case TARGET_SCHEME_GET_BY_ID_ERROR:
    case TARGET_SCHEME_GET_BY_QUERY_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
}
