import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import axios from 'axios';
import Config from '../../config';

import {
  TRAINER_GET_LIST,
  TRAINER_ADD_ITEM,
  TRAINER_EDIT_ITEM,
  TRAINER_DELETE_ITEM,
} from '../actions';

import {
  getTrainerListSuccess,
  getTrainerListError,
  addTrainerItemSuccess,
  addTrainerItemError,
  editTrainerItemError,
} from './actions';

const getTrainerListRequest = async payload => {
  let url = `${Config.apiServerHost}/api/Trainer/fetch`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

function* getTrainerListItems({ payload }) {
  try {
    const response = yield call(getTrainerListRequest, payload);
    yield put(getTrainerListSuccess(response.data));
  } catch (error) {
    yield put(getTrainerListError(error));
  }
}

const poshTrainerListRequest = async trainer => {
  return await axios.post(`${Config.apiServerHost}/api/Trainer`, trainer, await authHeader());
};
function* editTrainerItem({ payload }) {
  try {
    yield call(poshTrainerListRequest, payload);
    const res = yield call(getTrainerListRequest);
    yield put(getTrainerListSuccess(res.data));
  } catch (error) {
    yield put(editTrainerItemError(error));
  }
}

const deleteTrainerListRequest = async id => {
  return await axios.delete(`${Config.apiServerHost}/api/Trainer/${id}`, await authHeader());
};
function* deleteTrainerItem({ payload }) {
  try {
    yield call(deleteTrainerListRequest, payload);
    const res = yield call(getTrainerListRequest);
    yield put(getTrainerListSuccess(res.data));
  } catch (error) {
    yield put(editTrainerItemError(error));
  }
}

const addTrainerItemRequest = async trainer => {
  return await axios.post(`${Config.apiServerHost}/api/Trainer`, trainer, await authHeader());
};

function* addTrainerItem({ payload }) {
  try {
    const response = yield call(addTrainerItemRequest, payload);
    yield put(addTrainerItemSuccess(response));
  } catch (error) {
    yield put(addTrainerItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(TRAINER_GET_LIST, getTrainerListItems);
}

export function* wathcAddItem() {
  yield takeEvery(TRAINER_ADD_ITEM, addTrainerItem);
}
export function* wathcEditItem() {
  yield takeEvery(TRAINER_EDIT_ITEM, editTrainerItem);
}
export function* wathcDeleteItem() {
  yield takeEvery(TRAINER_DELETE_ITEM, deleteTrainerItem);
}

export default function* rootSaga() {
  yield all([fork(watchGetList), fork(wathcAddItem), fork(wathcEditItem), fork(wathcDeleteItem)]);
}

export const quickCreateEmployerRequest = async employerName => {
  return await axios.get(
    `${Config.apiServerHost}/api/employer/quickCreate/${employerName}`,
    await authHeader()
  );
};

export const quickCreateTrainerRequest = async trainerName => {
  return await axios.get(
    `${Config.apiServerHost}/api/common/quickCreate/${trainerName}`,
    await authHeader()
  );
};

export const quickCreateEmployer = async employerName => {
  return await axios.post(
    `${Config.apiServerHost}/api/employer/quickCreate`,
    employerName,
    await authHeader()
  );
};
