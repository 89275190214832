import {
  MIlEAGE_SUPERVISOR_GET_ALL,
  MIlEAGE_SUPERVISOR_GET_ALL_SUCCESS,
  MIlEAGE_SUPERVISOR_GET_ALL_ERROR,
  MIlEAGE_SUPERVISOR_GET_BY_ID,
  MIlEAGE_SUPERVISOR_GET_BY_ID_SUCCESS,
  MIlEAGE_SUPERVISOR_GET_BY_ID_ERROR,
  MIlEAGE_SUPERVISOR_ADD_NEW,
  MIlEAGE_SUPERVISOR_ADD_NEW_SUCCESS,
  MIlEAGE_SUPERVISOR_ADD_NEW_ERROR,
  MIlEAGE_SUPERVISOR_EDIT,
  MIlEAGE_SUPERVISOR_EDIT_SUCCESS,
  MIlEAGE_SUPERVISOR_EDIT_ERROR,
  MIlEAGE_SUPERVISOR_DELETE,
  MIlEAGE_SUPERVISOR_DELETE_SUCCESS,
  MIlEAGE_SUPERVISOR_DELETE_ERROR,
} from '../actions';

const initialState = {
  items: [],
  item: null,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case MIlEAGE_SUPERVISOR_DELETE:
    case MIlEAGE_SUPERVISOR_EDIT:
    case MIlEAGE_SUPERVISOR_ADD_NEW:
    case MIlEAGE_SUPERVISOR_GET_BY_ID:
    case MIlEAGE_SUPERVISOR_GET_ALL:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MIlEAGE_SUPERVISOR_GET_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
        loading: false,
      };

    case MIlEAGE_SUPERVISOR_GET_ALL_SUCCESS:
      return {
        ...state,
        items: payload,
        loading: false,
      };

    case MIlEAGE_SUPERVISOR_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
        items: state.items.concat(payload),
      };

    case MIlEAGE_SUPERVISOR_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        item: payload,
        error: null,
      };

    case MIlEAGE_SUPERVISOR_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.filter(c => c.id !== payload),
        error: null,
      };

    case MIlEAGE_SUPERVISOR_GET_ALL_ERROR:
    case MIlEAGE_SUPERVISOR_GET_BY_ID_ERROR:
    case MIlEAGE_SUPERVISOR_ADD_NEW_ERROR:
    case MIlEAGE_SUPERVISOR_EDIT_ERROR:
    case MIlEAGE_SUPERVISOR_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
