import {
  GET_CLIENT_SUMMARY,
  GET_CLIENT_SUMMARY_SUCCESS,
  SAVE_CLIENT_SUMMARY,
  CLIENT_SUMMARY_ERROR,
} from './types';

export const getClientSummary = payload => ({
  type: GET_CLIENT_SUMMARY,
  payload,
});

export const getClientSummarySuccess = data => ({
  type: GET_CLIENT_SUMMARY_SUCCESS,
  payload: data,
});

export const saveClientSummary = data => ({
  type: SAVE_CLIENT_SUMMARY,
  payload: data,
});

export const clientSummaryError = error => ({
  type: CLIENT_SUMMARY_ERROR,
  payload: error,
});
