import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  SE_CHEQUE_GET_BY_ESCONTRACT_ID,
  SE_CHEQUE_GET_BY_ID,
  SE_CHEQUE_ADD_NEW,
  SE_CHEQUE_EDIT,
  SE_CHEQUE_DELETE,
} from '../actions';

import {
  getSEChequeByESContractIdSuccess,
  getSEChequeByESContractIdError,
  getSEChequeByIdSuccess,
  getSEChequeByIdError,
  addNewSEChequeSuccess,
  addNewSEChequeError,
  editSEChequeSuccess,
  editSEChequeError,
  deleteSEChequeSuccess,
  deleteSEChequeError,
} from './actions';

const getSEChequeByESContractIdRequest = async seContractId => {
  const url = `${Config.apiServerHost}/api/secheque/se-contract-id/${seContractId}`;
  return axios.get(url, await authHeader());
};

const getSEChequeByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/secheque/${id}`, await authHeader());
};

const addNewSEChequeReqeust = async item => {
  return axios.post(`${Config.apiServerHost}/api/secheque`, item, await authHeader());
};

const editSEChequeRequest = async item => {
  return axios.put(`${Config.apiServerHost}/api/secheque`, item, await authHeader());
};

const deleteSEChequeRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/secheque/${id}`, await authHeader());
};

function* getSEChequeByESContractId({ payload }) {
  try {
    const response = yield call(getSEChequeByESContractIdRequest, payload);
    yield put(getSEChequeByESContractIdSuccess(response.data));
  } catch (error) {
    yield put(getSEChequeByESContractIdError(error.response.statusText));
  }
}

function* getSEChequeById({ payload }) {
  try {
    const response = yield call(getSEChequeByIdRequest, payload);
    yield put(getSEChequeByIdSuccess(response.data));
  } catch (error) {
    yield put(getSEChequeByIdError(error.response.statusText));
  }
}

function* addNewSECheque({ payload }) {
  try {
    let response = yield call(addNewSEChequeReqeust, payload);
    response = yield call(getSEChequeByIdRequest, response.data);
    yield put(addNewSEChequeSuccess(response.data));
  } catch (error) {
    yield put(addNewSEChequeError(error.response.statusText));
  }
}

function* editSECheque({ payload }) {
  try {
    let response = yield call(editSEChequeRequest, payload);
    response = yield call(getSEChequeByIdRequest, response.data);
    yield put(editSEChequeSuccess(response.data));
  } catch (error) {
    yield put(editSEChequeError(error.response.statusText));
  }
}

function* deleteSECheque({ payload }) {
  try {
    const response = yield call(deleteSEChequeRequest, payload);
    yield put(deleteSEChequeSuccess(response.data));
  } catch (error) {
    yield put(deleteSEChequeError(error.response.statusText));
  }
}

export function* watchGetSEChequeByESContractId() {
  yield takeEvery(SE_CHEQUE_GET_BY_ESCONTRACT_ID, getSEChequeByESContractId);
}

export function* watchGetSEChequeById() {
  yield takeEvery(SE_CHEQUE_GET_BY_ID, getSEChequeById);
}

export function* watchAddNewSECheque() {
  yield takeEvery(SE_CHEQUE_ADD_NEW, addNewSECheque);
}

export function* watchEditSECheque() {
  yield takeEvery(SE_CHEQUE_EDIT, editSECheque);
}

export function* watchDeleteSECheque() {
  yield takeEvery(SE_CHEQUE_DELETE, deleteSECheque);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetSEChequeByESContractId),
    fork(watchGetSEChequeById),
    fork(watchAddNewSECheque),
    fork(watchEditSECheque),
    fork(watchDeleteSECheque),
  ]);
}
