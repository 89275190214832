import {
  ALERT_ADD_NEW,
  ALERT_ADD_NEW_SUCCESS,
  ALERT_ADD_NEW_ERROR,
  ALERT_DELETE,
  ALERT_DELETE_ERROR,
  ALERT_DELETE_SUCCESS,
  ALERT_EDIT,
  ALERT_EDIT_ERROR,
  ALERT_EDIT_SUCCESS,
  ALERT_GET_BY_ID,
  ALERT_GET_BY_ID_ERROR,
  ALERT_GET_BY_ID_SUCCESS,
  ALERT_GET_LIST,
  ALERT_GET_LIST_ERROR,
  ALERT_GET_LIST_SUCCESS,
  ALERT_ADD_NEW_REQUEST,
  ALERT_STATUS_CHANGE,
  ALERT_STATUS_CHANGE_SUCCESS,
  ALERT_STATUS_CHANGE_ERROR,
} from '../actions';

const initialState = {
  alerts: [],
  alert: null,
  loading: false,
  error: null,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ALERT_GET_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case ALERT_STATUS_CHANGE:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case ALERT_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        alerts: state.alerts.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        alert: payload,
        error: null,
        loading: false,
      };
    case ALERT_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        alert: payload,
      };

    case ALERT_ADD_NEW_REQUEST:
      return {
        ...state,
        addSuccess: false,
      };
    case ALERT_GET_LIST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ALERT_GET_LIST_SUCCESS:
      return {
        ...state,
        alerts: payload,
        loading: false,
      };
    case ALERT_ADD_NEW:
      return {
        ...state,
        loading: true,
        error: null,
        addSuccess: false,
      };

    case ALERT_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        alert: payload,
        alerts: state.alerts.concat(payload),
        addSuccess: true,
      };

    case ALERT_EDIT:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case ALERT_EDIT_SUCCESS:
      return {
        ...state,
        alerts: state.alerts.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        alert: payload,
        error: null,
        loading: false,
        editSuccess: true,
      };
    case ALERT_DELETE:
      return {
        ...state,
        loading: true,
        error: null,
        deleteSuccess: false,
      };
    case ALERT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        alerts: state.alerts.filter(c => c.id !== payload),
        deleteSuccess: true,
      };

    case ALERT_DELETE_ERROR:
    case ALERT_ADD_NEW_ERROR:
    case ALERT_EDIT_ERROR:
    case ALERT_GET_BY_ID_ERROR:
    case ALERT_GET_LIST_ERROR:
    case ALERT_STATUS_CHANGE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
