import { GET_IES_DASHBOARD, GET_IES_DASHBOARD_SUCCESS, GET_IES_DASHBOARD_ERROR } from './types';

export const getIESDashboard = query => ({
  type: GET_IES_DASHBOARD,
  payload: query,
});

export const getIESDashboardSuccess = item => ({
  type: GET_IES_DASHBOARD_SUCCESS,
  payload: item,
});

export const getIESDashboardError = item => ({
  type: GET_IES_DASHBOARD_ERROR,
  payload: item,
});
