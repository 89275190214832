import { logger } from '../../logger';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../helpers/auth-header';
import { authAccessHeader } from '../../helpers/auth-access-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { escasesUpdateLastChangePasswordDate } from '../auth/saga';
import { NotificationManager } from '../../components/common/react-notifications';
import {
  USER_GET_LIST,
  ALL_USER_GET_LIST,
  USER_ADD_OR_UPDATE_ITEM,
  USER_DELETE_ITEM,
  USER_ACTIVATE,
  USER_RESET_PASSWORD,
  USER_ROLE_GET_BY_ID,
  USER_ROLE_UPDATE,
  USER_ROLE_DELETE_LIST,
  USER_PROFILE_GET_BY_ID,
  USER_PROFILE_PICTURE_UPDATE,
  USER_DISABLED_OR_ENABLED,
  FORCE_GLOBAL_SIGNOUT,
  UPDATE_USER_IS_ADMIN,
  USER_PROFILE_UPDATE,
} from '../actions';

import {
  getUserListSuccess,
  getUserListError,
  getAllUserListSuccess,
  getAllUserListError,
  AddorUpdateUserItemError,
  deleteUserItemError,
  getUserRoleByIdSuccess,
  getUserRoleByIdError,
  getUserProfileByIdSuccess,
  getUserProfileByIdError,
  forceGlobalSignoutError,
} from './actions';

export const isCurrentUserAdmin = async () => {
  let url = `${Config.apiServerHost}/api/account/user/is-admin`;
  return axios.get(url, await authHeader());
};

export const getMfaQrCode = async payload => {
  let url = `${Config.apiServerHost}/api/account/mfa-qr-code`;
  return axios.get(buildUrl(url, payload), await authAccessHeader());
};

export const getMfaVerifyCode = async payload => {
  let url = `${Config.apiServerHost}/api/account/mfa-verify-code`;
  return axios.get(buildUrl(url, payload), await authAccessHeader());
};

export const getMfaDisableCode = async payload => {
  let url = `${Config.apiServerHost}/api/account/mfa-disable-code`;
  return axios.get(buildUrl(url, payload), await authAccessHeader());
};

const getAllUserListRequest = async payload => {
  let url = `${Config.apiServerHost}/api/admin/all-users-paging`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const getUserListRequest = async payload => {
  return axios.get(`${Config.apiServerHost}/api/account/fetch`, {
    params: payload,
    headers: { ...(await authHeader()).headers },
  });
};

const getUserRoleByIdRequest = async id => {
  return await axios.get(
    `${Config.apiServerHost}/api/account/getuserwithuserroles/${id}`,
    await authHeader()
  );
};

const updateUserIsAdminRequest = async ({ id, isAdmin }) => {
  return await axios.put(
    `${Config.apiServerHost}/api/admin/user/${id}/is-admin?isAdmin=${isAdmin}`,
    {},
    await authHeader()
  );
};

function* updateUserIsAdmin({ payload }) {
  try {
    yield call(updateUserIsAdminRequest, payload);
    const response = yield call(getUserListRequest, { includeDisabled: true });
    yield put(getUserListSuccess(response.data));
  } catch (error) {
    yield put(getAllUserListError(error));
  }
}

function* getUserRoleById({ payload }) {
  try {
    const response = yield call(getUserRoleByIdRequest, payload);
    yield put(getUserRoleByIdSuccess(response.data));
  } catch (error) {
    yield put(getUserRoleByIdError(error));
  }
}

const disableOrEnableUserByIdRequest = async id => {
  return await axios.get(
    `${Config.apiServerHost}/api/account/disableOrEnable/${id}`,
    await authHeader()
  );
};

const forceGlobalSignoutRequest = async id => {
  return await axios.put(
    `${Config.apiServerHost}/api/account/user/${id}/revoke-cognito-access`,
    {},
    await authHeader()
  );
};

const getUserProfileByIdRequest = async id => {
  logger.debug('GOT HERE!');
  return await axios.get(`${Config.apiServerHost}/api/account/user/${id}`, await authHeader());
};

function* disableOrEnableUserById({ payload }) {
  try {
    const { id, page, pageSize } = payload;
    yield call(disableOrEnableUserByIdRequest, id);
    const response = yield call(getUserListRequest, { page, pageSize, includeDisabled: true });
    yield put(getUserListSuccess(response.data));
  } catch (error) {
    yield put(getUserProfileByIdError(error));
  }
}

function* forceGlobalSignout({ payload }) {
  try {
    yield call(forceGlobalSignoutRequest, payload);
    const response = yield call(getUserListRequest, { includeDisabled: true });
    yield put(getUserListSuccess(response.data));
  } catch (error) {
    yield put(forceGlobalSignoutError(error));
  }
}

function* getUserProfileById({ payload }) {
  try {
    const response = yield call(getUserProfileByIdRequest, payload);
    yield put(getUserProfileByIdSuccess(response.data));
  } catch (error) {
    yield put(getUserProfileByIdError(error));
  }
}

function* getUserListItems({ payload }) {
  try {
    const response = yield call(getUserListRequest, payload);
    yield put(getUserListSuccess(response.data));
  } catch (error) {
    yield put(getUserListError(error));
  }
}

function* getAllUserListItems({ payload }) {
  try {
    const response = yield call(getAllUserListRequest, payload);
    yield put(getAllUserListSuccess(response.data));
  } catch (error) {
    yield put(getAllUserListError(error));
  }
}

const poshUserListRequest = async user => {
  return await axios.post(`${Config.apiServerHost}/api/account/register`, user, await authHeader());
};
const updateUserRequest = async user => {
  const lang = localStorage.getItem('currentLanguage')
    ? localStorage.getItem('currentLanguage')
    : 'en';

  logger.debug({ lang });

  return await axios.put(
    `${Config.apiServerHost}/api/account/user/${user.id}?lang=${lang}`,
    user,
    await authHeader()
  );
};

function* AddorUpdateUserItem({ payload }) {
  try {
    const { user, page, pageSize, isAddNew, includeDisabled } = payload;
    if (isAddNew) {
      yield call(poshUserListRequest, user);
    } else {
      yield call(updateUserRequest, user);
    }
    const res = yield call(getUserListRequest, { page, pageSize, includeDisabled });
    yield put(getUserListSuccess(res.data));
    if (typeof user.id !== 'undefined' && user.id !== null) {
      const resProfle = yield call(getUserProfileByIdRequest, user.id);
      yield put(getUserProfileByIdSuccess(resProfle.data));
    }
  } catch (error) {
    yield put(AddorUpdateUserItemError(error));
  }
}
const UpdateUserPwdRequestRequest = async user => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const res = await Auth.changePassword(cognitoUser, user.currentPassword, user.newPassword);
  console.log(res);
  if (res === 'SUCCESS') {
    NotificationManager.success(
      'Your password was successfully changed.',
      'Password Change',
      3000,
      null,
      null,
      ''
    );
  }
};
function* UpdateUserPwdRequest({ payload }) {
  try {
    yield call(UpdateUserPwdRequestRequest, payload);
    yield call(escasesUpdateLastChangePasswordDate);

    const res = yield call(getUserListRequest);
    yield put(getUserListSuccess(res.data));
  } catch (error) {
    yield put(AddorUpdateUserItemError(error));
  }
}

const handleUpdateUserPictureRequest = async user => {
  return await axios.put(
    `${Config.apiServerHost}/api/account/UpdateUserPicture`,
    user,
    await authHeader()
  );
};

function* handleUpdateUserPicture({ payload }) {
  try {
    const { user } = payload;
    yield call(updateUserRequest, user);

    const resProfle = yield call(getUserProfileByIdRequest, user.id);
    yield put(getUserProfileByIdSuccess(resProfle.data));
  } catch (error) {
    yield put(getUserProfileByIdError(error));
  }
}

function* userProfileUpdate({ payload }) {
  try {
    yield call(handleUpdateUserPictureRequest, payload);
    const res = yield call(getUserProfileByIdRequest, payload.id);
    yield put(getUserProfileByIdSuccess(res.data));
  } catch (error) {
    yield put(getUserProfileByIdError(error));
  }
}

const UpdateUserRole = async user => {
  return await axios.put(
    `${Config.apiServerHost}/api/account/addroletoUser/${user.userId}`,
    { id: user.id },
    await authHeader()
  );
};

const updateUserRoleRequest = async payload => {
  return await axios.post(`${Config.apiServerHost}/api/account/updateUserRole`, payload, {
    headers: { ...(await authHeader()).headers },
  });
};

const performTransferCasesRequest = async payload => {
  return await axios.post(`${Config.apiServerHost}/api/Counsellor/servicePlans/transfer`, payload, {
    headers: { ...(await authHeader()).headers },
  });
};

const countServicePlansByUserIdRequest = async id => {
  return await axios.get(
    `${Config.apiServerHost}/api/Counsellor/servicePlans/${id}`,
    await authHeader()
  );
};

function* editUserRole({ payload }) {
  try {
    yield call(UpdateUserRole, payload);

    const res = yield call(getUserRoleByIdRequest, payload.userId);
    yield put(getUserRoleByIdSuccess(res.data));
  } catch (error) {
    yield put(getUserRoleByIdError(error));
  }
}

const deleteUserRoleListRequest = async user => {
  return await axios.delete(
    `${Config.apiServerHost}/api/account/deleterolefromuser/${user.userId}/${user.roleId}`,
    await authHeader()
  );
};
function* deleteUserRoleItem({ payload }) {
  try {
    yield call(deleteUserRoleListRequest, payload);
    const res = yield call(getUserRoleByIdRequest, payload.userId);
    yield put(getUserRoleByIdSuccess(res.data));
  } catch (error) {
    yield put(getUserRoleByIdError(error));
  }
}
const activateUserRequest = async id => {
  return await axios.put(
    `${Config.apiServerHost}/api/account/user/${id}/activate`,
    {},
    await authHeader()
  );
};

const deleteUserListRequest = async id => {
  return await axios.delete(`${Config.apiServerHost}/api/account/user/${id}`, await authHeader());
};
function* deleteUserItem({ payload }) {
  try {
    const { id, page, pageSize } = payload;
    yield call(deleteUserListRequest, id);
    const res = yield call(getUserListRequest, { page, pageSize, includeDisabled: true });
    yield put(getUserListSuccess(res.data));
  } catch (error) {
    yield put(deleteUserItemError(error));
  }
}

function* activateUser({ payload }) {
  try {
    const { id, page, pageSize } = payload;
    yield call(activateUserRequest, id);
    const res = yield call(getUserListRequest, { page, pageSize, includeDisabled: true });
    yield put(getUserListSuccess(res.data));
  } catch (error) {
    yield put(deleteUserItemError(error));
  }
}

export function* watchDisableOrEnableUserById() {
  yield takeEvery(USER_DISABLED_OR_ENABLED, disableOrEnableUserById);
}

export function* watchUpdateUserIsAdmin() {
  yield takeEvery(UPDATE_USER_IS_ADMIN, updateUserIsAdmin);
}

export function* watchForceGlobalSignout() {
  yield takeEvery(FORCE_GLOBAL_SIGNOUT, forceGlobalSignout);
}

export function* watchGetList() {
  yield takeEvery(USER_GET_LIST, getUserListItems);
}

export function* watchGetAllList() {
  yield takeEvery(ALL_USER_GET_LIST, getAllUserListItems);
}

export function* wathcADD_OR_UPDATEItem() {
  yield takeEvery(USER_ADD_OR_UPDATE_ITEM, AddorUpdateUserItem);
}
export function* wathcDeleteItem() {
  yield takeEvery(USER_DELETE_ITEM, deleteUserItem);
}
export function* watchUserActivate() {
  yield takeEvery(USER_ACTIVATE, activateUser);
}
export function* wathcUpdateUserPwdRequest() {
  yield takeEvery(USER_RESET_PASSWORD, UpdateUserPwdRequest);
}
export function* wathcUpdateUserRole() {
  yield takeEvery(USER_ROLE_UPDATE, editUserRole);
}
export function* wathcGetUserRole() {
  yield takeEvery(USER_ROLE_GET_BY_ID, getUserRoleById);
}

export function* wathcDeleteUserRole() {
  yield takeEvery(USER_ROLE_DELETE_LIST, deleteUserRoleItem);
}

export function* wathcGetUserProfile() {
  yield takeEvery(USER_PROFILE_GET_BY_ID, getUserProfileById);
}

export function* wathcUserProfilePictureUpdate() {
  yield takeEvery(USER_PROFILE_PICTURE_UPDATE, handleUpdateUserPicture);
}

export function* watchUserProfileUpdate() {
  yield takeEvery(USER_PROFILE_UPDATE, handleUpdateUserPicture);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetAllList),
    fork(wathcADD_OR_UPDATEItem),
    fork(wathcDeleteItem),
    fork(wathcUpdateUserPwdRequest),
    fork(wathcUpdateUserRole),
    fork(wathcGetUserRole),
    fork(wathcDeleteUserRole),
    fork(wathcGetUserProfile),
    fork(wathcUserProfilePictureUpdate),
    fork(watchDisableOrEnableUserById),
    fork(watchForceGlobalSignout),
    fork(watchUpdateUserIsAdmin),
    fork(watchUserActivate),
    fork(watchUserProfileUpdate),
  ]);
}

export {
  updateUserRoleRequest,
  getUserProfileByIdRequest,
  countServicePlansByUserIdRequest,
  performTransferCasesRequest,
};
