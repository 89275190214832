import {
  TRF_CA_REFERRAL_ADD_NEW,
  TRF_CA_REFERRAL_ADD_NEW_ERROR,
  TRF_CA_REFERRAL_ADD_NEW_SUCCESS,
  TRF_CA_REFERRAL_GET_BY_ID,
  TRF_CA_REFERRAL_GET_BY_ID_ERROR,
  TRF_CA_REFERRAL_GET_BY_ID_SUCCESS,
  TRF_CA_REFERRAL_GET_PAGING,
  TRF_CA_REFERRAL_GET_PAGING_ERROR,
  TRF_CA_REFERRAL_GET_PAGING_SUCCESS,
  TRF_CA_REFERRAL_UPDATE,
  TRF_CA_REFERRAL_UPDATE_ERROR,
  TRF_CA_REFERRAL_UPDATE_SUCCESS,
  TRF_CA_REFERRAL_ASSIGN_REFERRAL,
  TRF_CA_REFERRAL_ASSIGN_REFERRAL_SUCCESS,
  TRF_CA_REFERRAL_ASSIGN_REFERRAL_ERROR,
} from './types';
// item
const initialState = {
  item: null,
  items: [],
  loading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
  total: 0,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case TRF_CA_REFERRAL_ASSIGN_REFERRAL:
    case TRF_CA_REFERRAL_ADD_NEW:
    case TRF_CA_REFERRAL_GET_PAGING:
    case TRF_CA_REFERRAL_GET_BY_ID:
    case TRF_CA_REFERRAL_UPDATE:
      return {
        ...state,
        loading: true,
        error: null,
        item: null,
      };

    case TRF_CA_REFERRAL_GET_PAGING_SUCCESS:
      const { itemCount, pageCount, data, pendingItems, overdueItems, completedItems } = payload;
      return {
        ...state,
        loading: false,
        error: null,
        itemCount,
        pageCount,
        items: data,
        pendingItems,
        overdueItems,
        completedItems,
      };

    case TRF_CA_REFERRAL_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        // item: payload
      };

    case TRF_CA_REFERRAL_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        item: payload,
      };

    case TRF_CA_REFERRAL_ASSIGN_REFERRAL_SUCCESS:
    case TRF_CA_REFERRAL_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        item: payload,
      };

    case TRF_CA_REFERRAL_ASSIGN_REFERRAL_ERROR:
    case TRF_CA_REFERRAL_ADD_NEW_ERROR:
    case TRF_CA_REFERRAL_GET_PAGING_ERROR:
    case TRF_CA_REFERRAL_GET_BY_ID_ERROR:
    case TRF_CA_REFERRAL_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
}
