import {
  EIDM_EDIT,
  EIDM_EDIT_ERROR,
  EIDM_EDIT_SUCCESS,
  EIDM_GET_BY_QUERY,
  EIDM_GET_BY_ID,
  EIDM_GET_BY_QUERY_SUCCESS,
  EIDM_GET_BY_QUERY_ERROR,
  EIDM_GET_BY_ID_SUCCESS,
  EIDM_ADD_NEW_REQUEST,
  EIDM_DELETE,
  EIDM_DELETE_SUCCESS,
} from './types';

// EIDM
export const getEIDMByQuery = query => ({
  type: EIDM_GET_BY_QUERY,
  payload: query,
});

export const getEIDMByQuerySuccess = item => ({
  type: EIDM_GET_BY_QUERY_SUCCESS,
  payload: item,
});

export const getEIDMByQueryError = error => ({
  type: EIDM_GET_BY_QUERY_ERROR,
  payload: error,
});

export const getEIDMById = query => ({
  type: EIDM_GET_BY_ID,
  payload: query,
});

export const getEIDMByIdSuccess = item => ({
  type: EIDM_GET_BY_ID_SUCCESS,
  payload: item,
});

export const saveEIDM = item => ({
  type: EIDM_EDIT,
  payload: item,
});

export const saveEIDMSuccess = item => ({
  type: EIDM_EDIT_SUCCESS,
  payload: item,
});

export const saveEIDMError = error => ({
  type: EIDM_EDIT_ERROR,
  payload: error,
});

export const addNewEIDMRequest = () => ({
  type: EIDM_ADD_NEW_REQUEST,
});

export const deleteEIDMById = id => ({
  type: EIDM_DELETE,
  payload: id,
});

export const deleteEIDMByIdSuccess = item => ({
  type: EIDM_DELETE_SUCCESS,
  payload: item,
});
