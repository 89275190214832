import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  INTERVENTION_FOLLOWUP_IES_EDIT,
  INTERVENTION_FOLLOWUP_IES_GET_BY_ID,
  INTERVENTION_FOLLOWUP_IES_GET_LIST,
} from './types';

import {
  getInterventionFollowupIESListSuccess,
  getInterventionFollowupIESListError,
  editInterventionFollowupIESError,
  getInterventionFollowupIESByIdSuccess,
} from './actions';

const getClientsByInterventionIESIdRequest = async ids => {
  return axios.post(`${Config.apiServerHost}/api/ActionPlan/clients`, ids, await authHeader());
};

const getInterventionFollowupIESListRequest = async query => {
  let url = `${Config.apiServerHost}/api/InterventionFollowupIES/search`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const editInterventionFollowupIESRequest = async payload => {
  return axios.post(
    `${Config.apiServerHost}/api/OutcomeCheckpoint/updateSingle`,
    payload,
    await authHeader()
  );
};

const getInterventionFollowupIESByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/OutcomeCheckpoint/${id}`, await authHeader());
};

const getSurveysByServicePlanRequest = async servicePlanHeaderId => {
  return axios.get(
    `${Config.apiServerHost}/api/ClientSurvey/program/IES/serviceplan/${servicePlanHeaderId}/summary`,
    await authHeader()
  );
};

const postTransferSurveyAnswersRequest = async ({
  clientId,
  servicePlanHeaderId,
  outcomeCkpReferenceNumber,
  data,
}) => {
  return axios.post(
    `${Config.apiServerHost}/api/OutcomeCheckpointSurvey/transfer?clientId=${clientId}&servicePlanHeaderId=${servicePlanHeaderId}&outcomeCkpReferenceNumber=${outcomeCkpReferenceNumber}`,
    data,
    await authHeader()
  );
};

function* getInterventionFollowupIESList({ payload }) {
  try {
    const response = yield call(getInterventionFollowupIESListRequest, payload);
    yield put(getInterventionFollowupIESListSuccess(response.data));
  } catch (error) {
    yield put(getInterventionFollowupIESListError(error.response.statusText));
  }
}

function* editInterventionFollowupIES({ payload }) {
  try {
    const { clientId, servicePlanHeaderId } = payload;
    const query = {
      clientId: clientId ?? '',
      servicePlanHeaderId: servicePlanHeaderId ?? '',
    };

    let response = yield call(editInterventionFollowupIESRequest, payload);
    response = yield call(getInterventionFollowupIESListRequest, query);
    yield put(getInterventionFollowupIESListSuccess(response.data));
  } catch (error) {
    yield put(editInterventionFollowupIESError(error.response.statusText));
  }
}

function* getInterventionFollowupIESById({ payload }) {
  try {
    const response = yield call(getInterventionFollowupIESByIdRequest, payload);
    yield put(getInterventionFollowupIESByIdSuccess(response.data));
  } catch (error) {
    yield put(editInterventionFollowupIESError(error.response.statusText));
  }
}

export function* watchInterventionFollowupIESList() {
  yield takeEvery(INTERVENTION_FOLLOWUP_IES_GET_LIST, getInterventionFollowupIESList);
}

export function* watchInterventionFollowupIESById() {
  yield takeEvery(INTERVENTION_FOLLOWUP_IES_GET_BY_ID, getInterventionFollowupIESById);
}

export function* watchEditInterventionFollowupIES() {
  yield takeEvery(INTERVENTION_FOLLOWUP_IES_EDIT, editInterventionFollowupIES);
}

export default function* rootSaga() {
  yield all([
    fork(watchInterventionFollowupIESList),
    fork(watchInterventionFollowupIESById),
    fork(watchEditInterventionFollowupIES),
  ]);
}

export {
  getSurveysByServicePlanRequest,
  getClientsByInterventionIESIdRequest,
  getInterventionFollowupIESByIdRequest,
  editInterventionFollowupIESRequest,
  postTransferSurveyAnswersRequest,
};
