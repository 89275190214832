import {
  GET_EMPLOYER_DOCUMENTS_BY_ID,
  GET_EMPLOYER_DOCUMENTS_BY_ID_SUCCESS,
  GET_EMPLOYER_DOCUMENTS_BY_QUERY,
  GET_EMPLOYER_DOCUMENTS_BY_QUERY_SUCCESS,
  UPLOAD_EMPLOYER_DOCUMENTS,
  EDIT_EMPLOYER_DOCUMENTS,
  DELETE_EMPLOYER_DOCUMENTS,
  EMPLOYER_DOCUMENT_ERROR,
} from './types';

export const getEmployerDocumentById = id => ({
  type: GET_EMPLOYER_DOCUMENTS_BY_ID,
  payload: id,
});

export const getEmployerDocumentByIdSuccess = employer => ({
  type: GET_EMPLOYER_DOCUMENTS_BY_ID_SUCCESS,
  payload: employer,
});

export const getEmployerDocumentByQuery = query => ({
  type: GET_EMPLOYER_DOCUMENTS_BY_QUERY,
  payload: query,
});

export const getEmloyerDocumentByQuerySuccess = data => ({
  type: GET_EMPLOYER_DOCUMENTS_BY_QUERY_SUCCESS,
  payload: data,
});

export const uploadEmployerDocument = item => ({
  type: UPLOAD_EMPLOYER_DOCUMENTS,
  payload: item,
});

export const editEmployerDocument = item => ({
  type: EDIT_EMPLOYER_DOCUMENTS,
  payload: item,
});

export const deleteEmployerDocument = id => ({
  type: DELETE_EMPLOYER_DOCUMENTS,
  payload: id,
});

export const employerDocumentError = error => ({
  type: EMPLOYER_DOCUMENT_ERROR,
  payload: error,
});
