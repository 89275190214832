import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  SUB_GOAL_EDIT,
  SUB_GOAL_GET_BY_ID,
  SUB_GOAL_ADD_NEW,
  SUB_GOAL_GET_LIST,
  SUB_GOAL_BY_CLIENT,
} from './types';
import Config from '../../config';

import {
  getSubGoalListSuccess,
  getSubGoalListError,
  editSubGoalSuccess,
  editSubGoalError,
  addNewSubGoalSuccess,
  addNewSubGoalError,
  getSubGoalByIdSuccess,
  getSubGoalByClientSuccess,
  getSubGoalByClientError,
} from './actions';

const getSubGoalListRequest = async query => {
  const url = `${Config.apiServerHost}/api/SubGoal/search`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const addNewSubGoalReqeust = async caseNote => {
  return axios.post(`${Config.apiServerHost}/api/SubGoal`, caseNote, await authHeader());
};

const editSubGoalRequest = async caseNote => {
  return axios.put(`${Config.apiServerHost}/api/SubGoal`, caseNote, await authHeader());
};

const getSubGoalByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/SubGoal/${id}`, await authHeader());
};

function* getSubGoalList({ payload }) {
  try {
    const response = yield call(getSubGoalListRequest, payload);
    yield put(getSubGoalListSuccess(response.data));
  } catch (error) {
    yield put(getSubGoalListError(error.response.statusText));
  }
}

function* getSubGoalByClient({ payload }) {
  try {
    const response = yield call(getSubGoalListRequest, payload);
    const { data } = response;
    if (data) {
      yield put(getSubGoalByClientSuccess(data[0] ?? null)); // get only one element of item
    }
  } catch (error) {
    yield put(getSubGoalByClientError(error.response.statusText));
  }
}

function* addNewSubGoal({ payload }) {
  try {
    let response = yield call(addNewSubGoalReqeust, payload);
    response = yield call(getSubGoalByIdRequest, response.data);
    yield put(addNewSubGoalSuccess(response.data));
  } catch (error) {
    yield put(addNewSubGoalError(error.response.statusText));
  }
}

function* editSubGoal({ payload }) {
  try {
    let response = yield call(editSubGoalRequest, payload);
    response = yield call(getSubGoalByIdRequest, payload.id);
    yield put(editSubGoalSuccess(response.data));
  } catch (error) {
    yield put(editSubGoalError(error.response.statusText));
  }
}

function* getSubGoalById({ payload }) {
  try {
    const response = yield call(getSubGoalByIdRequest, payload);
    yield put(getSubGoalByIdSuccess(response.data));
  } catch (error) {
    yield put(editSubGoalError(error.response.statusText));
  }
}

export function* watchSubGoalByClient() {
  yield takeEvery(SUB_GOAL_BY_CLIENT, getSubGoalByClient);
}

export function* watchSubGoalList() {
  yield takeEvery(SUB_GOAL_GET_LIST, getSubGoalList);
}

export function* watchAddNewSubGoal() {
  yield takeEvery(SUB_GOAL_ADD_NEW, addNewSubGoal);
}

export function* watchSubGoalById() {
  yield takeEvery(SUB_GOAL_GET_BY_ID, getSubGoalById);
}

export function* watchEditSubGoal() {
  yield takeEvery(SUB_GOAL_EDIT, editSubGoal);
}

export default function* rootSaga() {
  yield all([
    fork(watchSubGoalByClient),
    fork(watchSubGoalList),
    fork(watchAddNewSubGoal),
    fork(watchSubGoalById),
    fork(watchEditSubGoal),
  ]);
}
