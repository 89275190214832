// CLIENT_TSA
export const CLIENT_TSA_GET_BY_ID = 'CLIENT_TSA_GET_BY_ID';
export const CLIENT_TSA_GET_BY_ID_SUCCESS = 'CLIENT_TSA_GET_BY_ID_SUCCESS';
export const CLIENT_TSA_GET_BY_ID_ERROR = 'CLIENT_TSA_GET_BY_ID_ERROR';

export const CLIENT_TSA_GET_LIST = 'CLIENT_TSA_GET_LIST';
export const CLIENT_TSA_GET_LIST_SUCCESS = 'CLIENT_TSA_GET_LIST_SUCCESS';
export const CLIENT_TSA_GET_LIST_ERROR = 'CLIENT_TSA_GET_LIST_ERROR';

export const CLIENT_TSA_EDIT = 'CLIENT_TSA_EDIT';
export const CLIENT_TSA_EDIT_SUCCESS = 'CLIENT_TSA_EDIT_SUCCESS';
export const CLIENT_TSA_EDIT_ERROR = 'CLIENT_TSA_EDIT_ERROR';

export const CLIENT_TSA_ADD_NEW = 'CLIENT_TSA_ADD_NEW';
export const CLIENT_TSA_ADD_NEW_SUCCESS = 'CLIENT_TSA_ADD_NEW_SUCCESS';
export const CLIENT_TSA_ADD_NEW_ERROR = 'CLIENT_TSA_ADD_NEW_ERROR';
export const CLIENT_TSA_ADD_NEW_REQUEST = 'CLIENT_TSA_ADD_NEW_REQUEST';

export const CLIENT_TSA_DELETE = 'CLIENT_TSA_DELETE';
export const CLIENT_TSA_DELETE_SUCCESS = 'CLIENT_TSA_DELETE_SUCCESS';
export const CLIENT_TSA_DELETE_ERROR = 'CLIENT_TSA_DELETE_ERROR';

export const CLIENT_TSA_GET_BY_QUERY = 'CLIENT_TSA_GET_BY_QUERY';
export const CLIENT_TSA_GET_BY_QUERY_SUCCESS = 'CLIENT_TSA_GET_BY_QUERY_SUCCESS';
export const CLIENT_TSA_GET_BY_QUERY_ERROR = 'CLIENT_TSA_GET_BY_QUERY_ERROR';
