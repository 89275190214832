import {
  FINANCIAL_TRACKING_ADD_NEW,
  FINANCIAL_TRACKING_DELETE,
  FINANCIAL_TRACKING_EDIT,
  FINANCIAL_TRACKING_GET_BY_ID,
  FINANCIAL_TRACKING_GET_BY_ID_SUCCESS,
  FINANCIAL_TRACKING_GET_LIST,
  FINANCIAL_TRACKING_GET_LIST_SUCCESS,
  FINANCIAL_TRACKING_RESULT_CHANGE,
  FINANCIAL_TRACKING_ADD_NEW_REQUEST,
  FINANCIAL_TRACKING_ERROR,
} from './types';
// FinancialTracking
export const getFinancialTrackingList = query => ({
  type: FINANCIAL_TRACKING_GET_LIST,
  payload: query,
});

export const getFinancialTrackingListSuccess = item => ({
  type: FINANCIAL_TRACKING_GET_LIST_SUCCESS,
  payload: item,
});

export const addNewFinancialTracking = item => ({
  type: FINANCIAL_TRACKING_ADD_NEW,
  payload: item,
});

export const addNewFinancialTrackingRequest = () => ({
  type: FINANCIAL_TRACKING_ADD_NEW_REQUEST,
});

export const editFinancialTracking = item => ({
  type: FINANCIAL_TRACKING_EDIT,
  payload: item,
});

export const deleteFinancialTracking = id => ({
  type: FINANCIAL_TRACKING_DELETE,
  payload: id,
});

export const getFinancialTrackingById = id => ({
  type: FINANCIAL_TRACKING_GET_BY_ID,
  payload: id,
});

export const getFinancialTrackingByIdSuccess = item => ({
  type: FINANCIAL_TRACKING_GET_BY_ID_SUCCESS,
  payload: item,
});

export const postingResultChange = item => ({
  type: FINANCIAL_TRACKING_RESULT_CHANGE,
  payload: item,
});

export const postingError = error => ({
  type: FINANCIAL_TRACKING_ERROR,
  payload: error,
});
