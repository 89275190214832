import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  LOCATION_ADD_NEW,
  LOCATION_DELETE,
  LOCATION_GET_BY_ID,
  LOCATION_GET_PAGING,
  LOCATION_UPDATE,
} from './types';

import {
  addLocationSuccess,
  addLocationError,
  getPagingLocationSuccess,
  getPagingLocationError,
  getLocationByIdSuccess,
  getLocationByIdError,
  updateLocationSuccess,
  updateLocationError,
} from './actions';

const addLocationRequest = async item => {
  return axios.post(`${Config.apiServerHost}/api/Location`, item, await authHeader());
};

const updateLocationRequest = async item => {
  return axios.put(`${Config.apiServerHost}/api/Location`, item, await authHeader());
};

const getPagingLocationRequest = async item => {
  return axios.get(`${Config.apiServerHost}/api/Location/paging`, {
    params: item,
    headers: { ...(await authHeader()).headers },
  });
};

const getLocationByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/Location/${id}`, await authHeader());
};

const changeStatusLocationRequest = async item => {
  return axios.get(`${Config.apiServerHost}/api/Location/statusChange`, {
    params: item,
    headers: { ...(await authHeader()).headers },
  });
};

const deleteLocationRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/Location/${id}`, await authHeader());
};

function* addNewLocation({ payload }) {
  try {
    let response = yield call(addLocationRequest, payload);
    yield put(addLocationSuccess(response.data));

    response = yield call(getPagingLocationRequest, {});
    yield put(getPagingLocationSuccess(response.data));
  } catch (error) {
    yield put(addLocationError(error.response.statusText));
  }
}

function* updateLocation({ payload }) {
  try {
    let response = yield call(updateLocationRequest, payload);
    yield put(updateLocationSuccess(response.data));

    response = yield call(getPagingLocationRequest, {});
    yield put(getPagingLocationSuccess(response.data));
  } catch (error) {
    yield put(updateLocationError(error.response.statusText));
  }
}

function* getPagingLocation({ payload }) {
  try {
    let response = yield call(getPagingLocationRequest, payload);
    yield put(getPagingLocationSuccess(response.data));
  } catch (error) {
    yield put(getPagingLocationError(error.response.statusText));
  }
}

function* getLocationById({ payload }) {
  try {
    let response = yield call(getLocationByIdRequest, payload);
    yield put(getLocationByIdSuccess(response.data));
  } catch (error) {
    yield put(getLocationByIdError(error.response.statusText));
  }
}

function* deleteLocation({ payload }) {
  try {
    let response = yield call(deleteLocationRequest, payload);
    response = yield call(getPagingLocationRequest, {});
    yield put(getPagingLocationSuccess(response.data));
  } catch (error) {
    yield put(getPagingLocationError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(LOCATION_ADD_NEW, addNewLocation);
  yield takeEvery(LOCATION_UPDATE, updateLocation);
  yield takeEvery(LOCATION_GET_PAGING, getPagingLocation);
  yield takeEvery(LOCATION_GET_BY_ID, getLocationById);
  yield takeEvery(LOCATION_DELETE, deleteLocation);
}
