import {
  GET_JOB_SEEKER_RETAINED_BY_EMPLOYER_DETAIL_BY_QUERY,
  GET_JOB_SEEKER_RETAINED_BY_EMPLOYER_DETAIL_BY_QUERY_SUCCESS,
  GET_JOB_SEEKER_RETAINED_BY_EMPLOYER_DETAIL_BY_QUERY_ERROR,
} from './types';

export const getJobSeekerRetainedByEmployer = query => ({
  type: GET_JOB_SEEKER_RETAINED_BY_EMPLOYER_DETAIL_BY_QUERY,
  payload: query,
});

export const getJobSeekerRetainedByEmployerSuccess = item => ({
  type: GET_JOB_SEEKER_RETAINED_BY_EMPLOYER_DETAIL_BY_QUERY_SUCCESS,
  payload: item,
});

export const getJobSeekerRetainedByEmployerError = item => ({
  type: GET_JOB_SEEKER_RETAINED_BY_EMPLOYER_DETAIL_BY_QUERY_ERROR,
  payload: item,
});
