import {
  YJC_CONTRACT_GET_LIST,
  YJC_CONTRACT_GET_LIST_SUCCESS,
  YJC_CONTRACT_GET_LIST_ERROR,
  YJC_CONTRACT_GET_BY_ID,
  YJC_CONTRACT_GET_BY_ID_SUCCESS,
  YJC_CONTRACT_GET_BY_ID_ERROR,
  YJC_CONTRACT_ADD_ITEM,
  YJC_CONTRACT_ADD_ITEM_SUCCESS,
  YJC_CONTRACT_ADD_ITEM_ERROR,
  YJC_CONTRACT_EDIT_ITEM,
  YJC_CONTRACT_EDIT_ITEM_SUCCESS,
  YJC_CONTRACT_EDIT_ITEM_ERROR,
  YJC_CONTRACT_DELETE_ITEM,
  YJC_CONTRACT_DELETE_ITEM_SUCCESS,
  YJC_CONTRACT_DELETE_ITEM_ERROR,
  YJC_CONTRACT_GET_BY_EMPLOYER,
  YJC_CONTRACT_GET_BY_EMPLOYER_SUCCESS,
  YJC_CONTRACT_GET_BY_EMPLOYER_ERROR,
  YJC_CONTRACT_GET_BY_CLIENT,
  YJC_CONTRACT_GET_BY_CLIENT_SUCCESS,
  YJC_CONTRACT_GET_BY_CLIENT_ERROR,
} from '../actions';

// YJCContract

export const getYJCContractByClient = query => ({
  type: YJC_CONTRACT_GET_BY_CLIENT,
  payload: query,
});

export const getYJCContractByClientSuccess = items => ({
  type: YJC_CONTRACT_GET_BY_CLIENT_SUCCESS,
  payload: items,
});

export const getYJCContractByClientError = error => ({
  type: YJC_CONTRACT_GET_BY_CLIENT_ERROR,
  payload: error,
});

export const getYJCContractByEmployer = query => ({
  type: YJC_CONTRACT_GET_BY_EMPLOYER,
  payload: query,
});

export const getYJCContractByEmployerSuccess = items => ({
  type: YJC_CONTRACT_GET_BY_EMPLOYER_SUCCESS,
  payload: items,
});

export const getYJCContractByEmployerError = error => ({
  type: YJC_CONTRACT_GET_BY_EMPLOYER_ERROR,
  payload: error,
});

export const getYJCContractList = filter => ({
  type: YJC_CONTRACT_GET_LIST,
  payload: filter,
});

export const getYJCContractListSuccess = items => ({
  type: YJC_CONTRACT_GET_LIST_SUCCESS,
  payload: items,
});

export const getYJCContractListError = error => ({
  type: YJC_CONTRACT_GET_LIST_ERROR,
  payload: error,
});

export const getYJCContractById = id => ({
  type: YJC_CONTRACT_GET_BY_ID,
  payload: id,
});

export const getYJCContractByIdSuccess = item => ({
  type: YJC_CONTRACT_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getYJCContractByIdError = error => ({
  type: YJC_CONTRACT_GET_BY_ID_ERROR,
  payload: error,
});

export const addYJCContractItem = (item, history) => ({
  type: YJC_CONTRACT_ADD_ITEM,
  payload: { item, history },
});

export const addYJCContractItemSuccess = items => ({
  type: YJC_CONTRACT_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addYJCContractItemError = error => ({
  type: YJC_CONTRACT_ADD_ITEM_ERROR,
  payload: error,
});

export const editYJCContractItem = (item, history) => ({
  type: YJC_CONTRACT_EDIT_ITEM,
  payload: { item, history },
});

export const editYJCContractItemSuccess = items => ({
  type: YJC_CONTRACT_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editYJCContractItemError = error => ({
  type: YJC_CONTRACT_EDIT_ITEM_ERROR,
  payload: error,
});

export const deleteYJCContractItem = item => ({
  type: YJC_CONTRACT_DELETE_ITEM,
  payload: item,
});

export const deleteYJCContractItemSuccess = items => ({
  type: YJC_CONTRACT_DELETE_ITEM_SUCCESS,
  payload: items,
});

export const deleteYJCContractItemError = error => ({
  type: YJC_CONTRACT_DELETE_ITEM_ERROR,
  payload: error,
});
