/** SUB_GOAL */
export const SUB_GOAL_QUERY = 'SUB_GOAL_QUERY';
export const SUB_GOAL_QUERY_SUCCESS = 'SUB_GOAL_QUERY_SUCCESS';
export const SUB_GOAL_QUERY_ERROR = 'SUB_GOAL_QUERY_ERROR';

export const SUB_GOAL_BY_CLIENT = 'SUB_GOAL_BY_CLIENT';
export const SUB_GOAL_BY_CLIENT_SUCCESS = 'SUB_GOAL_BY_CLIENT_SUCCESS';
export const SUB_GOAL_BY_CLIENT_ERROR = 'SUB_GOAL_BY_CLIENT_ERROR';

export const SUB_GOAL_GET_LIST = 'SUB_GOAL_GET_LIST';
export const SUB_GOAL_GET_LIST_SUCCESS = 'SUB_GOAL_GET_LIST_SUCCESS';
export const SUB_GOAL_GET_LIST_ERROR = 'SUB_GOAL_GET_LIST_ERROR';

export const SUB_GOAL_GET_BY_ID = 'SUB_GOAL_GET_BY_ID';
export const SUB_GOAL_GET_BY_ID_SUCCESS = 'SUB_GOAL_GET_BY_ID_SUCCESS';
export const SUB_GOAL_GET_BY_ID_ERROR = 'SUB_GOAL_GET_BY_ID_ERROR';

export const SUB_GOAL_ADD_NEW = 'SUB_GOAL_ADD_NEW';
export const SUB_GOAL_ADD_NEW_SUCCESS = 'SUB_GOAL_ADD_NEW_SUCCESS';
export const SUB_GOAL_ADD_NEW_ERROR = 'SUB_GOAL_ADD_NEW_ERROR';
export const SUB_GOAL_ADD_NEW_REQUEST = 'SUB_GOAL_ADD_NEW_REQUEST';

export const SUB_GOAL_EDIT = 'SUB_GOAL_EDIT';
export const SUB_GOAL_EDIT_SUCCESS = 'SUB_GOAL_EDIT_SUCCESS';
export const SUB_GOAL_EDIT_ERROR = 'SUB_GOAL_EDIT_ERROR';

export const SUB_GOAL_NOTIFICATION_CLOSED = 'SUB_GOAL_NOTIFICATION_CLOSED';
