import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  ALERT_DELETE,
  ALERT_EDIT,
  ALERT_GET_BY_ID,
  ALERT_ADD_NEW,
  ALERT_GET_LIST,
  ALERT_STATUS_CHANGE,
} from '../actions';
import Config from '../../config';

import {
  getAlertListSuccess,
  getAlertListError,
  editAlertSuccess,
  editAlertError,
  addNewAlertSuccess,
  addNewAlertError,
  deleteAlertSuccess,
  deleteAlertError,
  getAlertByIdSuccess,
  changeAlertStatusSuccess,
  changeAlertStatusError,
} from './actions';
// Alert
const getAlertListRequest = async payload => {
  let url = `${Config.apiServerHost}/api/Alert`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const getAlertsByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/Alert/search`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const getAlertCountByClientIdRequest = async payload => {
  let url = `${Config.apiServerHost}/api/Alert/count`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const addNewAlertReqeust = async actionPlan => {
  return axios.post(`${Config.apiServerHost}/api/Alert`, actionPlan, await authHeader());
};

const editAlertRequest = async actionPlan => {
  return axios.put(`${Config.apiServerHost}/api/Alert`, actionPlan, await authHeader());
};

const deleteAlertRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/Alert/${id}`, await authHeader());
};

const getAlertByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/Alert/${id}`, await authHeader());
};

const changeAlertStatusRequest = async id => {
  return axios.put(
    `${Config.apiServerHost}/api/Alert/${id}/statusChange`,
    null,
    await authHeader()
  );
};

function* getAlertList({ payload }) {
  try {
    const response = yield call(getAlertsByQueryRequest, payload);
    yield put(getAlertListSuccess(response.data));
  } catch (error) {
    yield put(getAlertListError(error.response.statusText));
  }
}

function* addNewAlert({ payload }) {
  try {
    let response = yield call(addNewAlertReqeust, payload);
    response = yield call(getAlertByIdRequest, response.data);
    yield put(addNewAlertSuccess(response.data));
  } catch (error) {
    yield put(addNewAlertError(error.response.statusText));
  }
}

function* editAlert({ payload }) {
  try {
    let response = yield call(editAlertRequest, payload);
    response = yield call(getAlertByIdRequest, response.data);
    yield put(editAlertSuccess(response.data));
  } catch (error) {
    yield put(editAlertError(error.response.statusText));
  }
}

function* getAlertById({ payload }) {
  try {
    const response = yield call(getAlertByIdRequest, payload);
    yield put(getAlertByIdSuccess(response.data));
  } catch (error) {
    yield put(editAlertError(error.response.statusText));
  }
}

function* changeAlertStatus({ payload }) {
  try {
    let response = yield call(changeAlertStatusRequest, payload);
    response = yield call(getAlertByIdRequest, response.data);
    yield put(changeAlertStatusSuccess(response.data));
  } catch (error) {
    yield put(changeAlertStatusError(error.response.statusText));
  }
}

function* deleteAlert({ payload }) {
  try {
    const response = yield call(deleteAlertRequest, payload);
    yield put(deleteAlertSuccess(response.data));
  } catch (error) {
    yield put(deleteAlertError(error.response.statusText));
  }
}

export function* watchAlertList() {
  yield takeEvery(ALERT_GET_LIST, getAlertList);
}

export function* watchAddNewAlert() {
  yield takeEvery(ALERT_ADD_NEW, addNewAlert);
}

export function* watchAlertById() {
  yield takeEvery(ALERT_GET_BY_ID, getAlertById);
}

export function* watchEditAlert() {
  yield takeEvery(ALERT_EDIT, editAlert);
}

export function* watchDeleteAlert() {
  yield takeEvery(ALERT_DELETE, deleteAlert);
}

export function* watchChangeAlertStatus() {
  yield takeEvery(ALERT_STATUS_CHANGE, changeAlertStatus);
}

export default function* rootSaga() {
  yield all([
    fork(watchAlertList),
    fork(watchAddNewAlert),
    fork(watchAlertById),
    fork(watchEditAlert),
    fork(watchDeleteAlert),
    fork(watchChangeAlertStatus),
  ]);
}

export { getAlertListRequest, editAlertRequest, getAlertCountByClientIdRequest };
