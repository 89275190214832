import {
  INTERVENTION_FOLLOWUP_IES_EDIT,
  INTERVENTION_FOLLOWUP_IES_EDIT_ERROR,
  INTERVENTION_FOLLOWUP_IES_GET_BY_ID,
  INTERVENTION_FOLLOWUP_IES_GET_BY_ID_ERROR,
  INTERVENTION_FOLLOWUP_IES_GET_BY_ID_SUCCESS,
  INTERVENTION_FOLLOWUP_IES_GET_LIST,
  INTERVENTION_FOLLOWUP_IES_GET_LIST_ERROR,
  INTERVENTION_FOLLOWUP_IES_GET_LIST_SUCCESS,
} from './types';
// items
const initialState = {
  items: [],
  item: null,
  loading: false,
  error: null,
  editSuccess: false,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case INTERVENTION_FOLLOWUP_IES_GET_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case INTERVENTION_FOLLOWUP_IES_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
      };

    case INTERVENTION_FOLLOWUP_IES_GET_LIST:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case INTERVENTION_FOLLOWUP_IES_GET_LIST_SUCCESS:
      return {
        ...state,
        items: payload,
        loading: false,
        editSuccess: true,
      };

    case INTERVENTION_FOLLOWUP_IES_EDIT:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };

    case INTERVENTION_FOLLOWUP_IES_EDIT_ERROR:
    case INTERVENTION_FOLLOWUP_IES_GET_BY_ID_ERROR:
    case INTERVENTION_FOLLOWUP_IES_GET_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
}
