import {
  JMPI_MATRIX_ADD_NEW,
  JMPI_MATRIX_ADD_NEW_SUCCESS,
  JMPI_MATRIX_ADD_NEW_ERROR,
  JMPI_MATRIX_EDIT,
  JMPI_MATRIX_EDIT_ERROR,
  JMPI_MATRIX_EDIT_SUCCESS,
  JMPI_MATRIX_GET_BY_QUERY,
  JMPI_MATRIX_GET_BY_ID,
  JMPI_MATRIX_GET_BY_QUERY_SUCCESS,
  JMPI_MATRIX_GET_BY_QUERY_ERROR,
  JMPI_MATRIX_GET_BY_ID_SUCCESS,
  JMPI_MATRIX_ADD_NEW_REQUEST,
  JMPI_MATRIX_DELETE,
  JMPI_MATRIX_DELETE_SUCCESS,
  JMPI_MATRIX_DELETE_ERROR,
  JMPI_MATRIX_GET_BY_ID_ERROR,
} from './types';

// JmpiMatrix

export const addNewJmpiMatrix = items => ({
  type: JMPI_MATRIX_ADD_NEW,
  payload: items,
});

export const addNewJmpiMatrixRequest = () => ({
  type: JMPI_MATRIX_ADD_NEW_REQUEST,
});

export const addNewJmpiMatrixSuccess = items => ({
  type: JMPI_MATRIX_ADD_NEW_SUCCESS,
  payload: items,
});

export const addNewJmpiMatrixError = error => ({
  type: JMPI_MATRIX_ADD_NEW_ERROR,
  payload: error,
});

export const editJmpiMatrix = item => ({
  type: JMPI_MATRIX_EDIT,
  payload: item,
});

export const editJmpiMatrixSuccess = item => ({
  type: JMPI_MATRIX_EDIT_SUCCESS,
  payload: item,
});

export const editJmpiMatrixError = error => ({
  type: JMPI_MATRIX_EDIT_ERROR,
  payload: error,
});

export const getJmpiMatrixByQuery = query => ({
  type: JMPI_MATRIX_GET_BY_QUERY,
  payload: query,
});

export const getJmpiMatrixByQuerySuccess = item => ({
  type: JMPI_MATRIX_GET_BY_QUERY_SUCCESS,
  payload: item,
});

export const getJmpiMatrixByQueryError = error => ({
  type: JMPI_MATRIX_GET_BY_QUERY_ERROR,
  payload: error,
});

export const getJmpiMatrixById = id => ({
  type: JMPI_MATRIX_GET_BY_ID,
  payload: id,
});

export const getJmpiMatrixByIdSuccess = item => ({
  type: JMPI_MATRIX_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getJmpiMatrixByIdError = error => ({
  type: JMPI_MATRIX_GET_BY_ID_ERROR,
  payload: error,
});

export const deleteJmpiMatrixById = id => ({
  type: JMPI_MATRIX_DELETE,
  payload: id,
});

export const deleteJmpiMatrixByIdSuccess = item => ({
  type: JMPI_MATRIX_DELETE_SUCCESS,
  payload: item,
});

export const deleteJmpiMatrixByIdErrorr = item => ({
  type: JMPI_MATRIX_DELETE_ERROR,
  payload: item,
});
