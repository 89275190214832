import axios from 'axios';
import { authHeader } from '../helpers/auth-header';
import Config from '../config';

export async function getSettings() {
  return axios.get(`${Config.apiServerHost}/api/Company/settings`, await authHeader());
}

export async function getCognitoSettings() {
  return axios.get(`${Config.apiServerHost}/api/settings/cognito`);
}
