import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { CLIENT_NEW_MATCHING_GET_BY_ID, CLIENT_NEW_MATCHING_GET_LIST } from './types';
import Config from '../../config';

import {
  getClientNewMatchingListSuccess,
  getClientNewMatchingByIdSuccess,
  clientNewMatchingError,
} from './actions';

// ClientNewMatching

const getClientNewMatchingListByClientIdRequest = async payload => {
  return axios.post(`${Config.apiServerHost}/api/Posting/fetch`, payload, await authHeader());
};

const getClientNewMatchingByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/Posting/${id}`, await authHeader());
};

function* getClientNewMatchingList({ payload }) {
  try {
    const response = yield call(getClientNewMatchingListByClientIdRequest, payload);
    yield put(getClientNewMatchingListSuccess(response.data));
  } catch (error) {
    yield put(clientNewMatchingError(error.response.statusText));
  }
}

function* getClientNewMatchingById({ payload }) {
  try {
    const response = yield call(getClientNewMatchingByIdRequest, payload);
    yield put(getClientNewMatchingByIdSuccess(response.data));
  } catch (error) {
    yield put(clientNewMatchingError(error.response.statusText));
  }
}

export function* watchClientNewMatchingList() {
  yield takeEvery(CLIENT_NEW_MATCHING_GET_LIST, getClientNewMatchingList);
}

export function* watchClientNewMatchingById() {
  yield takeEvery(CLIENT_NEW_MATCHING_GET_BY_ID, getClientNewMatchingById);
}

export default function* rootSaga() {
  yield all([fork(watchClientNewMatchingList), fork(watchClientNewMatchingById)]);
}

export { getClientNewMatchingListByClientIdRequest };
