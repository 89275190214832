import {
  TRAINER_GET_LIST,
  TRAINER_GET_LIST_SUCCESS,
  TRAINER_GET_LIST_ERROR,
  TRAINER_GET_LIST_WITH_FILTER,
  TRAINER_GET_LIST_WITH_ORDER,
  TRAINER_GET_LIST_SEARCH,
  TRAINER_ADD_ITEM,
  TRAINER_ADD_ITEM_SUCCESS,
  TRAINER_ADD_ITEM_ERROR,
  TRAINER_SELECTED_ITEMS_CHANGE,
  TRAINER_EDIT_ITEM,
  TRAINER_EDIT_ITEM_SUCCESS,
  TRAINER_EDIT_ITEM_ERROR,
  TRAINER_DELETE_ITEM,
  TRAINER_DELETE_ITEM_SUCCESS,
  TRAINER_DELETE_ITEM_ERROR,
} from '../actions';

const INIT_STATE = {
  allTranerItems: null,
  trainerItems: null,
  error: '',
  filter: null,
  searchKeyword: '',
  orderColumn: null,
  loading: false,
  labels: [
    { label: 'EDUCATION', color: 'secondary' },
    { label: 'NEW FRAMEWORK', color: 'primary' },
    { label: 'PERSONAL', color: 'info' },
  ],
  orderColumns: [
    { column: 'id', label: 'Id' },
    { column: 'name', label: 'Name' },
    { column: 'hid', label: 'HST' },
    { column: 'pcc', label: 'PCC' },
    { column: 'note', label: 'Note' },
  ],
  categories: ['Flexbox', 'Sass', 'React'],
  selectedItems: [],
  itemCount: 0,
  pageCount: 0,
};

export default (state = INIT_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case TRAINER_GET_LIST:
      return { ...state, loading: false };

    case TRAINER_GET_LIST_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        loading: true,
        allTranerItems: data,
        trainerItems: data,
        itemCount,
        pageCount,
      };

    case TRAINER_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case TRAINER_GET_LIST_WITH_FILTER:
      if (action.payload.column === '' || action.payload.value === '') {
        return { ...state, loading: true, trainerItems: state.allTranerItems, filter: null };
      } else {
        const filteredItems = state.allTranerItems.filter(
          item => item[action.payload.column] === action.payload.value
        );
        return {
          ...state,
          loading: true,
          trainerItems: filteredItems,
          filter: {
            column: action.payload.column,
            value: action.payload.value,
          },
        };
      }

    case TRAINER_GET_LIST_WITH_ORDER:
      if (action.payload === '') {
        return { ...state, loading: true, trainerItems: state.trainerItems, orderColumn: null };
      } else {
        const sortedItems = state.trainerItems.sort((a, b) => {
          if (a[action.payload] < b[action.payload]) return -1;
          else if (a[action.payload] > b[action.payload]) return 1;
          return 0;
        });
        return {
          ...state,
          loading: true,
          trainerItems: sortedItems,
          orderColumn: state.orderColumns.find(x => x.column === action.payload),
        };
      }

    case TRAINER_GET_LIST_SEARCH:
      if (action.payload === '') {
        return { ...state, trainerItems: state.allTranerItems };
      } else {
        const keyword = action.payload.toLowerCase();

        const searchItems = state.allTranerItems.filter(
          item =>
            String(item.id.toLowerCase()).startsWith(keyword) ||
            String(item.name.toLowerCase()).startsWith(keyword) ||
            String(item.hid.toLowerCase()).startsWith(keyword) ||
            String(item.pcc.toLowerCase()).startsWith(keyword) ||
            String(item.note.toLowerCase()).startsWith(keyword)
        );
        return {
          ...state,
          loading: true,
          trainerItems: searchItems,
          searchKeyword: action.payload,
        };
      }

    case TRAINER_ADD_ITEM:
      return { ...state, loading: false };

    case TRAINER_ADD_ITEM_SUCCESS:
      return {
        ...state,
        loading: true,
        allTodoItems: action.payload,
        trainerItems: action.payload,
      };

    case TRAINER_ADD_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };

    case TRAINER_SELECTED_ITEMS_CHANGE:
      return { ...state, loading: true, selectedItems: action.payload };
    default:
      return { ...state };

    case TRAINER_EDIT_ITEM:
      return { ...state, loading: false };

    case TRAINER_EDIT_ITEM_SUCCESS:
      return {
        ...state,
        loading: true,
        allTrainerItems: action.payload,
        trainerItems: action.payload,
      };

    case TRAINER_EDIT_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };

    case TRAINER_DELETE_ITEM:
      return { ...state, loading: false };

    case TRAINER_DELETE_ITEM_SUCCESS:
      return {
        ...state,
        loading: true,
        allTrainerItems: action.payload,
        trainerItems: action.payload,
      };

    case TRAINER_DELETE_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };
  }
};
