import {
  ASSESSEMENT_ADD_NEW,
  ASSESSEMENT_ADD_NEW_SUCCESS,
  ASSESSEMENT_ADD_NEW_ERROR,
  ASSESSEMENT_DELETE,
  ASSESSEMENT_DELETE_ERROR,
  ASSESSEMENT_DELETE_SUCCESS,
  ASSESSEMENT_EDIT,
  ASSESSEMENT_EDIT_ERROR,
  ASSESSEMENT_EDIT_SUCCESS,
  ASSESSEMENT_GET_BY_ID,
  ASSESSEMENT_GET_BY_ID_ERROR,
  ASSESSEMENT_GET_BY_ID_SUCCESS,
  ASSESSEMENT_GET_LIST,
  ASSESSEMENT_GET_LIST_ERROR,
  ASSESSEMENT_GET_LIST_SUCCESS,
  ASSESSEMENT_ADD_NEW_REQUEST,
} from '../actions';

export const getAssessementList = query => ({
  type: ASSESSEMENT_GET_LIST,
  payload: query,
});

export const getAssessementListSuccess = assessements => ({
  type: ASSESSEMENT_GET_LIST_SUCCESS,
  payload: assessements,
});

export const getAssessementListError = error => ({
  type: ASSESSEMENT_GET_LIST_ERROR,
  payload: error,
});

export const addNewAssessement = assessement => ({
  type: ASSESSEMENT_ADD_NEW,
  payload: assessement,
});

export const addNewAssessementRequest = () => ({
  type: ASSESSEMENT_ADD_NEW_REQUEST,
});

export const addNewAssessementSuccess = assessement => ({
  type: ASSESSEMENT_ADD_NEW_SUCCESS,
  payload: assessement,
});

export const addNewAssessementError = error => ({
  type: ASSESSEMENT_ADD_NEW_ERROR,
  payload: error,
});

export const editAssessement = assessement => ({
  type: ASSESSEMENT_EDIT,
  payload: assessement,
});

export const editAssessementSuccess = assessement => ({
  type: ASSESSEMENT_EDIT_SUCCESS,
  payload: assessement,
});

export const editAssessementError = error => ({
  type: ASSESSEMENT_EDIT_ERROR,
  payload: error,
});

export const deleteAssessement = id => ({
  type: ASSESSEMENT_DELETE,
  payload: id,
});

export const deleteAssessementSuccess = id => ({
  type: ASSESSEMENT_DELETE_SUCCESS,
  payload: id,
});

export const deleteAssessementError = error => ({
  type: ASSESSEMENT_DELETE_ERROR,
  payload: error,
});

export const getAssessementById = id => ({
  type: ASSESSEMENT_GET_BY_ID,
  payload: id,
});

export const getAssessementByIdSuccess = assessement => ({
  type: ASSESSEMENT_GET_BY_ID_SUCCESS,
  payload: assessement,
});

export const getAssessementByIdError = error => ({
  type: ASSESSEMENT_GET_BY_ID_ERROR,
  payload: error,
});
