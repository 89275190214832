import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import axios from 'axios';
import Config from '../../config';

import { PROSPECT_GET_LIST, PROSPECT_ARCHIVE } from '../actions';

import { getProspectListSuccess, getProspectListError } from './actions';

const getProspectListRequest = async query => {
  let url = `${Config.apiServerHost}/api/Client/prospectiveFetch`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const archiveProspectsRequest = async id => {
  return await axios.post(
    `${Config.apiServerHost}/api/client/prospective/${id}/archive`,
    null,
    await authHeader()
  );
};

function* getProspectList({ payload }) {
  try {
    const response = yield call(getProspectListRequest, payload);
    yield put(getProspectListSuccess(response.data));
  } catch (error) {
    yield put(getProspectListError(error));
  }
}

function* archiveProspects({ payload }) {
  try {
    const { type, id } = payload;
    const query = {
      type,
    };

    yield call(archiveProspectsRequest, id);
    const response = yield call(getProspectListRequest, query);
    yield put(getProspectListSuccess(response.data));
  } catch (error) {
    yield put(getProspectListError(error));
  }
}

export function* watchGetProspectList() {
  yield takeEvery(PROSPECT_GET_LIST, getProspectList);
}

export function* watchArchiveProspects() {
  yield takeEvery(PROSPECT_ARCHIVE, archiveProspects);
}

export default function* rootSaga() {
  yield all([fork(watchGetProspectList), fork(watchArchiveProspects)]);
}
