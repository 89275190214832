import {
  CA_REFERRAL_GET_BY_ID,
  CA_REFERRAL_GET_BY_ID_ERROR,
  CA_REFERRAL_GET_BY_ID_SUCCESS,
  CA_REFERRAL_UPDATE,
  CA_REFERRAL_UPDATE_SUCCESS,
  CA_REFERRAL_UPDATE_ERROR,
} from './types';
//CAReferral
export const getCAReferralById = id => ({
  type: CA_REFERRAL_GET_BY_ID,
  payload: id,
});

export const getCAReferralByIdSuccess = clientInfo => ({
  type: CA_REFERRAL_GET_BY_ID_SUCCESS,
  payload: clientInfo,
});

export const getCAReferralByIdError = error => ({
  type: CA_REFERRAL_GET_BY_ID_ERROR,
  payload: error,
});

export const updateCAReferral = clientInfo => ({
  type: CA_REFERRAL_UPDATE,
  payload: clientInfo,
});

export const updateCAReferralSuccess = clientInfo => ({
  type: CA_REFERRAL_UPDATE_SUCCESS,
  payload: clientInfo,
});

export const updateCAReferralError = error => ({
  type: CA_REFERRAL_UPDATE_ERROR,
  payload: error,
});
