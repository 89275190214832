import { logger } from '../../logger';
import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  MILEAGE_ENTRY_DELETE,
  MILEAGE_ENTRY_EDIT,
  MILEAGE_ENTRY_GET_BY_ID,
  MILEAGE_ENTRY_ADD_NEW,
  MILEAGE_ENTRY_GET_LIST_BY_MILEAGE_ID,
} from '../actions';

import {
  getMileageEntryListSuccess,
  getMileageEntryListError,
  editMileageEntrySuccess,
  editMileageEntryError,
  addNewMileageEntrySuccess,
  addNewMileageEntryError,
  deleteMileageEntrySuccess,
  deleteMileageEntryError,
  getMileageEntryByIdSuccess,
} from './actions';

const getMileageEntryListRequest = async mileageId => {
  return axios.get(
    `${Config.apiServerHost}/api/mileage/${mileageId}/mileageEntryies`,
    await authHeader()
  );
};

const addNewMileageEntryReqeust = async entry => {
  return axios.post(`${Config.apiServerHost}/api/mileage/mileageEntry`, entry, await authHeader());
};

const editMileageEntryRequest = async entry => {
  return axios.put(
    `${Config.apiServerHost}/api/mileage/mileageEntry/${entry.id}`,
    entry,
    await authHeader()
  );
};

const deleteMileageEntryRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/mileage/mileageEntry/${id}`, await authHeader());
};

const getMileageEntryByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/mileage/mileageEntry/${id}`, await authHeader());
};

function* getMileageEntryList({ payload }) {
  try {
    const response = yield call(getMileageEntryListRequest, payload);
    yield put(getMileageEntryListSuccess(response.data));
  } catch (err) {
    logger.debug(err);
    // let { statusText, data } = err.response
    // const error = {
    //     title: statusText,
    //     message: data
    // };
    yield put(getMileageEntryListError(err));
  }
}

function* addNewMileageEntry({ payload }) {
  try {
    let response = yield call(addNewMileageEntryReqeust, payload);
    response = yield call(getMileageEntryByIdRequest, response.data.data);
    yield put(addNewMileageEntrySuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(addNewMileageEntryError(error));
  }
}

function* editMileageEntry({ payload }) {
  try {
    let response = yield call(editMileageEntryRequest, payload);
    response = yield call(getMileageEntryByIdRequest, payload.id);
    yield put(editMileageEntrySuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(editMileageEntryError(error));
  }
}

function* getMileageEntryById({ payload }) {
  try {
    const response = yield call(getMileageEntryByIdRequest, payload);
    yield put(getMileageEntryByIdSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(editMileageEntryError(error));
  }
}

function* deleteMileageEntry({ payload }) {
  try {
    const response = yield call(deleteMileageEntryRequest, payload);
    yield put(deleteMileageEntrySuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(deleteMileageEntryError(error));
  }
}

export function* watchMileageEntryList() {
  yield takeEvery(MILEAGE_ENTRY_GET_LIST_BY_MILEAGE_ID, getMileageEntryList);
}

export function* watchAddNewMileageEntry() {
  yield takeEvery(MILEAGE_ENTRY_ADD_NEW, addNewMileageEntry);
}

export function* watchMileageEntryById() {
  yield takeEvery(MILEAGE_ENTRY_GET_BY_ID, getMileageEntryById);
}

export function* watchEditMileageEntry() {
  yield takeEvery(MILEAGE_ENTRY_EDIT, editMileageEntry);
}

export function* watchDeleteMileageEntry() {
  yield takeEvery(MILEAGE_ENTRY_DELETE, deleteMileageEntry);
}

export default function* rootSaga() {
  yield all([
    fork(watchMileageEntryList),
    fork(watchAddNewMileageEntry),
    fork(watchMileageEntryById),
    fork(watchEditMileageEntry),
    fork(watchDeleteMileageEntry),
  ]);
}
