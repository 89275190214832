import {
  INVITATION_ADD_NEW,
  INVITATION_ADD_NEW_SUCCESS,
  INVITATION_ADD_NEW_ERROR,
  INVITATION_EDIT,
  INVITATION_EDIT_ERROR,
  INVITATION_EDIT_SUCCESS,
  INVITATION_GET_BY_QUERY,
  INVITATION_GET_BY_ID,
  INVITATION_GET_BY_QUERY_SUCCESS,
  INVITATION_GET_BY_QUERY_ERROR,
  INVITATION_GET_BY_ID_SUCCESS,
  INVITATION_ADD_NEW_REQUEST,
  INVITATION_DELETE,
  INVITATION_DELETE_SUCCESS,
  INVITATION_DELETE_ERROR,
  INVITATION_GET_BY_ID_ERROR,
  INVITATION_SEND_INVITATION,
  INVITATION_SEND_INVITATION_SUCCESS,
  INVITATION_SEND_INVITATION_ERROR,
} from './types';

// Invitation

export const sendInvitation = items => ({
  type: INVITATION_SEND_INVITATION,
  payload: items,
});

export const sendInvitationSuccess = items => ({
  type: INVITATION_SEND_INVITATION_SUCCESS,
  payload: items,
});
export const sendInvitationError = error => ({
  type: INVITATION_SEND_INVITATION_ERROR,
  payload: error,
});

export const addNewInvitation = items => ({
  type: INVITATION_ADD_NEW,
  payload: items,
});

export const addNewInvitationRequest = () => ({
  type: INVITATION_ADD_NEW_REQUEST,
});

export const addNewInvitationSuccess = items => ({
  type: INVITATION_ADD_NEW_SUCCESS,
  payload: items,
});

export const addNewInvitationError = error => ({
  type: INVITATION_ADD_NEW_ERROR,
  payload: error,
});

export const editInvitation = item => ({
  type: INVITATION_EDIT,
  payload: item,
});

export const editInvitationSuccess = item => ({
  type: INVITATION_EDIT_SUCCESS,
  payload: item,
});

export const editInvitationError = error => ({
  type: INVITATION_EDIT_ERROR,
  payload: error,
});

export const getInvitationByQuery = query => ({
  type: INVITATION_GET_BY_QUERY,
  payload: query,
});

export const getInvitationByQuerySuccess = item => ({
  type: INVITATION_GET_BY_QUERY_SUCCESS,
  payload: item,
});

export const getInvitationByQueryError = error => ({
  type: INVITATION_GET_BY_QUERY_ERROR,
  payload: error,
});

export const getInvitationById = id => ({
  type: INVITATION_GET_BY_ID,
  payload: id,
});

export const getInvitationByIdSuccess = item => ({
  type: INVITATION_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getInvitationByIdError = error => ({
  type: INVITATION_GET_BY_ID_ERROR,
  payload: error,
});

export const deleteInvitationById = id => ({
  type: INVITATION_DELETE,
  payload: id,
});

export const deleteInvitationByIdSuccess = item => ({
  type: INVITATION_DELETE_SUCCESS,
  payload: item,
});

export const deleteInvitationByIdErrorr = item => ({
  type: INVITATION_DELETE_ERROR,
  payload: item,
});
