import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  JMPI_MATRIX_ADD_NEW,
  JMPI_MATRIX_EDIT,
  JMPI_MATRIX_GET_BY_QUERY,
  JMPI_MATRIX_GET_BY_ID,
  JMPI_MATRIX_DELETE,
} from './types';

import {
  addNewJmpiMatrixError,
  editJmpiMatrixError,
  getJmpiMatrixByQuerySuccess,
  getJmpiMatrixByQueryError,
  getJmpiMatrixByIdError,
  getJmpiMatrixByIdSuccess,
  deleteJmpiMatrixByIdErrorr,
} from './actions';

const addNewJmpiMatrixRequest = async items => {
  return axios.post(`${Config.apiServerHost}/api/JmpiMatrix`, items, {
    headers: { ...(await authHeader()).headers },
  });
};

const editJmpiMatrixRequest = async items => {
  return axios.put(`${Config.apiServerHost}/api/JmpiMatrix`, items, {
    headers: { ...(await authHeader()).headers },
  });
};

const getJmpiMatrixByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/JmpiMatrix/${id}`, {
    headers: { ...(await authHeader()).headers },
  });
};

const getJmpiMatrixByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/JmpiMatrix/paging`;
  return await axios.get(url, {
    params: query,
    headers: { ...(await authHeader()).headers },
  });
};

const deleteJmpiMatrixRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/JmpiMatrix/${id}`, {
    headers: { ...(await authHeader()).headers },
  });
};

function* addNewJmpiMatrix({ payload }) {
  try {
    const { clientId, employerId, data } = payload;

    let response = yield call(addNewJmpiMatrixRequest, data);
    response = yield call(getJmpiMatrixByQueryRequest, {
      clientId,
      employerId,
    });
    yield put(getJmpiMatrixByQuerySuccess(response.data));
  } catch (error) {
    yield put(addNewJmpiMatrixError(error.response.statusText));
  }
}

function* editJmpiMatrix({ payload }) {
  try {
    const { clientId, employerId, data } = payload;

    let response = yield call(editJmpiMatrixRequest, data);
    response = yield call(getJmpiMatrixByQueryRequest, {
      clientId,
      employerId,
    });
    yield put(getJmpiMatrixByQuerySuccess(response.data));
  } catch (error) {
    yield put(editJmpiMatrixError(error.response.statusText));
  }
}

function* getJmpiMatrixByQuery({ payload }) {
  try {
    const response = yield call(getJmpiMatrixByQueryRequest, payload);
    yield put(getJmpiMatrixByQuerySuccess(response.data));
  } catch (error) {
    yield put(getJmpiMatrixByQueryError(error.response.statusText));
  }
}

function* getJmpiMatrixById({ payload }) {
  try {
    const response = yield call(getJmpiMatrixByIdRequest, payload);
    yield put(getJmpiMatrixByIdSuccess(response.data));
  } catch (error) {
    yield put(getJmpiMatrixByIdError(error.response.statusText));
  }
}

function* deleteJmpiMatrix({ payload }) {
  try {
    const { clientId, employerId, data } = payload;

    let response = yield call(deleteJmpiMatrixRequest, data);
    response = yield call(getJmpiMatrixByQueryRequest, {
      clientId,
      employerId,
    });
    yield put(getJmpiMatrixByQuerySuccess(response.data));
  } catch (error) {
    yield put(deleteJmpiMatrixByIdErrorr(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(JMPI_MATRIX_ADD_NEW, addNewJmpiMatrix);
  yield takeEvery(JMPI_MATRIX_EDIT, editJmpiMatrix);
  yield takeEvery(JMPI_MATRIX_GET_BY_QUERY, getJmpiMatrixByQuery);
  yield takeEvery(JMPI_MATRIX_GET_BY_ID, getJmpiMatrixById);
  yield takeEvery(JMPI_MATRIX_DELETE, deleteJmpiMatrix);
}
