import {
  CLIENT_FUND_ADD_NEW,
  CLIENT_FUND_ADD_NEW_SUCCESS,
  CLIENT_FUND_ADD_NEW_ERROR,
  CLIENT_FUND_DELETE,
  CLIENT_FUND_DELETE_ERROR,
  CLIENT_FUND_DELETE_SUCCESS,
  CLIENT_FUND_EDIT,
  CLIENT_FUND_EDIT_ERROR,
  CLIENT_FUND_EDIT_SUCCESS,
  CLIENT_FUND_GET_BY_ID,
  CLIENT_FUND_GET_BY_ID_ERROR,
  CLIENT_FUND_GET_BY_ID_SUCCESS,
  CLIENT_FUND_GET_LIST,
  CLIENT_FUND_GET_LIST_ERROR,
  CLIENT_FUND_GET_LIST_SUCCESS,
  CLIENT_FUND_ADD_NEW_REQUEST,
  CLIENT_FUND_LOG_LIST,
  CLIENT_FUND_LOG_LIST_SUCCESS,
  CLIENT_FUND_LOG_LIST_ERROR,
  CLIENT_FUND_DELETE_ATTACHMENT,
  CLIENT_FUND_DELETE_ATTACHMENT_SUCCESS,
  CLIENT_FUND_DELETE_ATTACHMENT_ERROR,
  CLIENT_FUND_REPORT,
  CLIENT_FUND_REPORT_SUCCESS,
  CLIENT_FUND_REPORT_ERROR,
} from '../actions';

export const getClientFundReport = query => ({
  type: CLIENT_FUND_REPORT,
  payload: query,
});

export const getClientFundReportSuccess = clientFunds => ({
  type: CLIENT_FUND_REPORT_SUCCESS,
  payload: clientFunds,
});

export const getClientFundReportError = error => ({
  type: CLIENT_FUND_REPORT_ERROR,
  payload: error,
});

export const getClientFundLogList = clientFundId => ({
  type: CLIENT_FUND_LOG_LIST,
  payload: clientFundId,
});

export const getClientFundLogListSuccess = clientFundLogs => ({
  type: CLIENT_FUND_LOG_LIST_SUCCESS,
  payload: clientFundLogs,
});

export const getClientFundLogListError = error => ({
  type: CLIENT_FUND_LOG_LIST_ERROR,
  payload: error,
});

export const getClientFundList = query => ({
  type: CLIENT_FUND_GET_LIST,
  payload: query,
});

export const getClientFundListSuccess = clientFunds => ({
  type: CLIENT_FUND_GET_LIST_SUCCESS,
  payload: clientFunds,
});

export const getClientFundListError = error => ({
  type: CLIENT_FUND_GET_LIST_ERROR,
  payload: error,
});

export const addNewClientFund = clientFund => ({
  type: CLIENT_FUND_ADD_NEW,
  payload: clientFund,
});

export const addNewClientFundSuccess = clientFund => ({
  type: CLIENT_FUND_ADD_NEW_SUCCESS,
  payload: clientFund,
});

export const addNewClientFundError = error => ({
  type: CLIENT_FUND_ADD_NEW_ERROR,
  payload: error,
});

export const addNewClientFundRequest = () => ({
  type: CLIENT_FUND_ADD_NEW_REQUEST,
});

export const editClientFund = clientFund => ({
  type: CLIENT_FUND_EDIT,
  payload: clientFund,
});

export const editClientFundSuccess = clientFund => ({
  type: CLIENT_FUND_EDIT_SUCCESS,
  payload: clientFund,
});

export const editClientFundError = error => ({
  type: CLIENT_FUND_EDIT_ERROR,
  payload: error,
});

export const deleteClientFund = id => ({
  type: CLIENT_FUND_DELETE,
  payload: id,
});

export const deleteClientFundSuccess = id => ({
  type: CLIENT_FUND_DELETE_SUCCESS,
  payload: id,
});

export const deleteClientFundError = error => ({
  type: CLIENT_FUND_DELETE_ERROR,
  payload: error,
});

export const getClientFundById = id => ({
  type: CLIENT_FUND_GET_BY_ID,
  payload: id,
});

export const getClientFundByIdSuccess = clientFund => ({
  type: CLIENT_FUND_GET_BY_ID_SUCCESS,
  payload: clientFund,
});

export const getClientFundByIdError = error => ({
  type: CLIENT_FUND_GET_BY_ID_ERROR,
  payload: error,
});

export const deleteClientFundAttachment = id => ({
  type: CLIENT_FUND_DELETE_ATTACHMENT,
  payload: id,
});

export const deleteClientFundAttachmentSuccess = data => ({
  type: CLIENT_FUND_DELETE_ATTACHMENT_SUCCESS,
  payload: data,
});

export const deleteClientFundAttachmentError = error => ({
  type: CLIENT_FUND_DELETE_ATTACHMENT_ERROR,
  payload: error,
});
