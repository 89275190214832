export const GET_CUSTOMER_SATISFACTION_REPORT = 'GET_CUSTOMER_SATISFACTION_REPORT';
export const GET_CUSTOMER_SATISFACTION_REPORT_SUCCESS = 'GET_CUSTOMER_SATISFACTION_REPORT_SUCCESS';
export const GET_CUSTOMER_SATISFACTION_REPORT_ERROR = 'GET_CUSTOMER_SATISFACTION_REPORT_ERROR';

export const GET_CLIENT_OUTCOMES_REPORT = 'GET_CLIENT_OUTCOMES_REPORT';
export const GET_CLIENT_OUTCOMES_REPORT_SUCCESS = 'GET_CLIENT_OUTCOMES_REPORT_SUCCESS';
export const GET_CLIENT_OUTCOMES_REPORT_ERROR = 'GET_CLIENT_OUTCOMES_REPORT_ERROR';

export const GET_CLIENT_VOLUMES_REPORT = 'GET_CLIENT_VOLUMES_REPORT';
export const GET_CLIENT_VOLUMES_REPORT_SUCCESS = 'GET_CLIENT_VOLUMES_REPORT_SUCCESS';
export const GET_CLIENT_VOLUMES_REPORT_ERROR = 'GET_CLIENT_VOLUMES_REPORT_ERROR';

export const SAVE_TARGET_CLIENT_VOLUMES_REPORT = 'SAVE_TARGET_CLIENT_VOLUMES_REPORT';
export const SAVE_TARGET_CLIENT_VOLUMES_REPORT_SUCCESS =
  'SAVE_TARGET_CLIENT_VOLUMES_REPORT_SUCCESS';
export const SAVE_TARGET_CLIENT_VOLUMES_REPORT_ERROR = 'SAVE_TARGET_CLIENT_VOLUMES_REPORT_ERROR';

export const SAVE_TARGET_CLIENT_OUTCOMES_REPORT = 'SAVE_TARGET_CLIENT_OUTCOMES_REPORT';
export const SAVE_TARGET_CLIENT_OUTCOMES_REPORT_SUCCESS =
  'SAVE_TARGET_CLIENT_OUTCOMES_REPORT_SUCCESS';
export const SAVE_TARGET_CLIENT_OUTCOMES_REPORT_ERROR = 'SAVE_TARGET_CLIENT_OUTCOMES_REPORT_ERROR';

export const SAVE_TARGET_CUSTOMER_SATISFACTION_REPORT = 'SAVE_TARGET_CUSTOMER_SATISFACTION_REPORT';
export const SAVE_TARGET_CUSTOMER_SATISFACTION_REPORT_SUCCESS =
  'SAVE_TARGET_CUSTOMER_SATISFACTION_REPORT_SUCCESS';
export const SAVE_TARGET_CUSTOMER_SATISFACTION_REPORT_ERROR =
  'SAVE_TARGET_CUSTOMER_SATISFACTION_REPORT_ERROR';
