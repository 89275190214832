import {
  POSTING_MATCHING_SENT_TO_CLIENT_GET_BY_ID,
  POSTING_MATCHING_SENT_TO_CLIENT_GET_BY_ID_SUCCESS,
  POSTING_MATCHING_SENT_TO_CLIENT_GET_LIST,
  POSTING_MATCHING_SENT_TO_CLIENT_GET_LIST_SUCCESS,
  POSTING_MATCHING_SENT_TO_CLIENT_ERROR,
} from './types';
// PostingMatchingSentToClient
export const getPostingMatchingSentToClientList = query => ({
  type: POSTING_MATCHING_SENT_TO_CLIENT_GET_LIST,
  payload: query,
});

export const getPostingMatchingSentToClientListSuccess = item => ({
  type: POSTING_MATCHING_SENT_TO_CLIENT_GET_LIST_SUCCESS,
  payload: item,
});

export const getPostingMatchingSentToClientById = id => ({
  type: POSTING_MATCHING_SENT_TO_CLIENT_GET_BY_ID,
  payload: id,
});

export const getPostingMatchingSentToClientByIdSuccess = item => ({
  type: POSTING_MATCHING_SENT_TO_CLIENT_GET_BY_ID_SUCCESS,
  payload: item,
});

export const postingMatchingSentToClientError = error => ({
  type: POSTING_MATCHING_SENT_TO_CLIENT_ERROR,
  payload: error,
});
