import {
  CASESUMMARY_GET_LIST,
  CASESUMMARY_GET_LIST_ERROR,
  CASESUMMARY_GET_LIST_SUCCESS,
  JOB_TRACKING_GET_LIST_BY_CLIENT,
  JOB_TRACKING_GET_LIST_BY_CLIENT_SUCCESS,
  JOB_TRACKING_GET_LIST_BY_CLIENT_ERROR,
  JOB_TRACKING_GET_LIST,
  JOB_TRACKING_GET_LIST_SUCCESS,
  JOB_TRACKING_GET_LIST_ERROR,
  JOB_TRACKING_ADD_NEW,
  JOB_TRACKING_ADD_NEW_SUCCESS,
  JOB_TRACKING_ADD_NEW_ERROR,
  JOB_TRACKING_DELETE,
  JOB_TRACKING_DELETE_SUCCESS,
  JOB_TRACKING_DELETE_ERROR,
  JOB_TRACKING_GET_BY_ID,
  JOB_TRACKING_GET_BY_ID_SUCCESS,
  JOB_TRACKING_GET_BY_ID_ERROR,
  JOB_TRACKING_EDIT,
  JOB_TRACKING_EDIT_SUCCESS,
  JOB_TRACKING_EDIT_ERROR,
} from '../actions';

export const getCaseSummaryList = query => ({
  type: CASESUMMARY_GET_LIST,
  payload: query,
});

export const getCaseSummaryListSuccess = caseSummarys => ({
  type: CASESUMMARY_GET_LIST_SUCCESS,
  payload: caseSummarys,
});

export const getCaseSummaryListError = error => ({
  type: CASESUMMARY_GET_LIST_ERROR,
  payload: error,
});

export const getJobTrackingListByClient = query => ({
  type: JOB_TRACKING_GET_LIST_BY_CLIENT,
  payload: query,
});

export const getJobTrackingListByClientSuccess = caseSummarys => ({
  type: JOB_TRACKING_GET_LIST_BY_CLIENT_SUCCESS,
  payload: caseSummarys,
});

export const getJobTrackingListByClientError = error => ({
  type: JOB_TRACKING_GET_LIST_BY_CLIENT_ERROR,
  payload: error,
});

export const geJobTrackingList = query => ({
  type: JOB_TRACKING_GET_LIST,
  payload: query,
});

export const getJobTrackingListSuccess = caseSummarys => ({
  type: JOB_TRACKING_GET_LIST_SUCCESS,
  payload: caseSummarys,
});

export const getJobTrackingListError = error => ({
  type: JOB_TRACKING_GET_LIST_ERROR,
  payload: error,
});

export const getJobTrackingReportList = filter => ({
  type: JOB_TRACKING_GET_LIST,
  payload: filter,
});

export const getJobTrackingReportListSuccess = items => ({
  type: JOB_TRACKING_GET_LIST_SUCCESS,
  payload: items,
});

export const getJobtrackingReportListError = error => ({
  type: JOB_TRACKING_GET_LIST_ERROR,
  payload: error,
});

export const addNewJobTracking = jobTracking => ({
  type: JOB_TRACKING_ADD_NEW,
  payload: jobTracking,
});

export const addNewJobTrackingSuccess = jobTracking => ({
  type: JOB_TRACKING_ADD_NEW_SUCCESS,
  payload: jobTracking,
});

export const addNewJobTrackingError = error => ({
  type: JOB_TRACKING_ADD_NEW_ERROR,
  payload: error,
});

export const deleteJobTracking = id => ({
  type: JOB_TRACKING_DELETE,
  payload: id,
});

export const deleteJobTrackingSuccess = id => ({
  type: JOB_TRACKING_DELETE_SUCCESS,
  payload: id,
});

export const deleteJobTrackingError = error => ({
  type: JOB_TRACKING_DELETE_ERROR,
  payload: error,
});

export const getJobTrackingById = id => ({
  type: JOB_TRACKING_GET_BY_ID,
  payload: id,
});

export const getJobTrackingByIdSuccess = jobTracking => ({
  type: JOB_TRACKING_GET_BY_ID_SUCCESS,
  payload: jobTracking,
});

export const getJobTrackingByIdError = error => ({
  type: JOB_TRACKING_GET_BY_ID_ERROR,
  payload: error,
});

export const editJobTracking = activity => ({
  type: JOB_TRACKING_EDIT,
  payload: activity,
});

export const editJobTrackingSuccess = activity => ({
  type: JOB_TRACKING_EDIT_SUCCESS,
  payload: activity,
});

export const editJobTrackingError = error => ({
  type: JOB_TRACKING_EDIT_ERROR,
  payload: error,
});
