import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import { JOURNEY_ACK_ADD_NEW, JOURNEY_ACK_GET_BY_ID } from './types';

import {
  addJourneyAckSuccess,
  addJourneyAckError,
  getJourneyAckByIdSuccess,
  getJourneyAckByIdError,
} from './actions';

const addJourneyAckRequest = async item => {
  return axios.post(`${Config.apiServerHost}/api/JourneyAck/acceptOrDecline`, item, {
    headers: { ...(await authHeader()).headers },
  });
};

const getJourneyAckByIdRequest = async params => {
  return axios.get(`${Config.apiServerHost}/api/JourneyAck/query`, {
    params,
    headers: { ...(await authHeader()).headers },
  });
};

function* addNewJourneyAck({ payload }) {
  try {
    const { clientId, servicePlanHeaderId } = payload;

    let response = yield call(addJourneyAckRequest, payload);
    yield put(addJourneyAckSuccess(response.data));

    response = yield call(getJourneyAckByIdRequest, { clientId, servicePlanHeaderId });
    yield put(getJourneyAckByIdSuccess(response.data));
  } catch (error) {
    yield put(addJourneyAckError(error.response.statusText));
  }
}

function* getJourneyAckById({ payload }) {
  try {
    let response = yield call(getJourneyAckByIdRequest, payload);
    yield put(getJourneyAckByIdSuccess(response.data));
  } catch (error) {
    yield put(getJourneyAckByIdError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(JOURNEY_ACK_ADD_NEW, addNewJourneyAck);
  yield takeEvery(JOURNEY_ACK_GET_BY_ID, getJourneyAckById);
}
