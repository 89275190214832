import {
  SITE_ADD_NEW,
  SITE_ADD_NEW_SUCCESS,
  SITE_ADD_NEW_ERROR,
  SITE_DELETE,
  SITE_DELETE_ERROR,
  SITE_DELETE_SUCCESS,
  SITE_EDIT,
  SITE_EDIT_ERROR,
  SITE_EDIT_SUCCESS,
  SITE_GET_BY_ID,
  SITE_GET_BY_ID_ERROR,
  SITE_GET_BY_ID_SUCCESS,
  SITE_GET_LIST,
  SITE_GET_LIST_ERROR,
  SITE_GET_LIST_SUCCESS,
  SITE_ADD_NEW_REQUEST,
  SITE_QUERY,
  SITE_QUERY_SUCCESS,
  SITE_QUERY_ERROR,
} from '../actions';

const initialState = {
  sites: [],
  site: null,
  loading: false,
  error: null,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SITE_GET_BY_ID:
      return {
        ...state,
        loading: false,
        error: null,
        editSuccess: false,
      };
    case SITE_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: true,
        site: payload,
      };

    case SITE_ADD_NEW_REQUEST:
      return {
        ...state,
        addSuccess: false,
      };

    case SITE_QUERY:
    case SITE_GET_LIST:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case SITE_QUERY_SUCCESS:
    case SITE_GET_LIST_SUCCESS:
      return {
        ...state,
        sites: payload,
        loading: true,
      };
    case SITE_ADD_NEW:
      return {
        ...state,
        loading: false,
        error: null,
        addSuccess: false,
      };

    case SITE_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: true,
        site: payload,
        sites: state.sites.concat(payload),
        addSuccess: true,
      };

    case SITE_EDIT:
      return {
        ...state,
        loading: false,
        error: null,
        editSuccess: false,
      };
    case SITE_EDIT_SUCCESS:
      return {
        ...state,
        loading: true,
        sites: state.sites.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        site: payload,
        error: null,
        editSuccess: true,
      };
    case SITE_DELETE:
      return {
        ...state,
        loading: false,
        error: null,
        deleteSuccess: false,
      };
    case SITE_DELETE_SUCCESS:
      return {
        ...state,
        loading: true,
        sites: state.sites.filter(c => c.id !== payload),
        deleteSuccess: true,
      };
    case SITE_QUERY_ERROR:
    case SITE_DELETE_ERROR:
    case SITE_ADD_NEW_ERROR:
    case SITE_EDIT_ERROR:
    case SITE_GET_BY_ID_ERROR:
    case SITE_GET_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
