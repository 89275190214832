import { PROSPECT_GET_LIST, PROSPECT_GET_LIST_SUCCESS, PROSPECT_GET_LIST_ERROR } from '../actions';

const initialState = {
  items: [],
  loading: false,
  itemCount: 0,
  pageCount: 0,
  error: '',
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PROSPECT_GET_LIST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case PROSPECT_GET_LIST_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        loading: false,
        items: data,
        itemCount,
        pageCount,
      };

    case PROSPECT_GET_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
}
