import {
  JOBTYPE_GET_LIST,
  JOBTYPE_GET_LIST_SUCCESS,
  JOBTYPE_GET_LIST_ERROR,
  JOBTYPE_GET_LIST_WITH_FILTER,
  JOBTYPE_GET_LIST_WITH_ORDER,
  JOBTYPE_GET_LIST_SEARCH,
  JOBTYPE_SELECTED_ITEMS_CHANGE,
  JOBTYPE_DELETE_ITEM,
  JOBTYPE_DELETE_ITEM_SUCCESS,
  JOBTYPE_DELETE_ITEM_ERROR,
} from '../actions';

const INIT_STATE = {
  jobtypes: [],
  jobtype: [],
  error: '',
  filter: null,
  searchKeyword: '',
  orderColumn: null,
  loading: false,
  labels: [
    { label: 'EDUCATION', color: 'secondary' },
    { label: 'NEW FRAMEWORK', color: 'primary' },
    { label: 'PERSONAL', color: 'info' },
  ],
  orderColumns: [
    { column: 'id', label: 'Id' },
    { column: 'name', label: 'Name' },
    { column: 'hid', label: 'HST' },
    { column: 'pcc', label: 'PCC' },
    { column: 'note', label: 'Note' },
  ],
  categories: ['Flexbox', 'Sass', 'React'],
  selectedItems: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case JOBTYPE_GET_LIST:
      return { ...state, loading: false };

    case JOBTYPE_GET_LIST_SUCCESS:
      return { ...state, loading: true, jobtypes: action.payload, jobtype: action.payload };

    case JOBTYPE_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case JOBTYPE_GET_LIST_WITH_FILTER:
      if (action.payload.column === '' || action.payload.value === '') {
        return { ...state, loading: true, jobtype: state.jobtypes, filter: null };
      } else {
        const filteredItems = state.jobtypes.filter(
          item => item[action.payload.column] === action.payload.value
        );
        return {
          ...state,
          loading: true,
          jobtype: filteredItems,
          filter: {
            column: action.payload.column,
            value: action.payload.value,
          },
        };
      }

    case JOBTYPE_GET_LIST_WITH_ORDER:
      if (action.payload === '') {
        return { ...state, loading: true, jobtype: state.jobtype, orderColumn: null };
      } else {
        const sortedItems = state.jobtypes.sort((a, b) => {
          if (a[action.payload] < b[action.payload]) return -1;
          else if (a[action.payload] > b[action.payload]) return 1;
          return 0;
        });
        return {
          ...state,
          loading: true,
          jobtype: sortedItems,
          orderColumn: state.orderColumns.find(x => x.column === action.payload),
        };
      }

    case JOBTYPE_GET_LIST_SEARCH:
      if (action.payload === '') {
        return { ...state, jobtype: state.jobtypes };
      } else {
        const keyword = action.payload;
        const searchItems = state.jobtype.filter(
          item =>
            String(item.id).startsWith(keyword) ||
            String(item.name.toLowerCase()).startsWith(keyword) ||
            String(item.note.toLowerCase()).startsWith(keyword)
        );
        return { ...state, loading: true, jobtype: searchItems, searchKeyword: action.payload };
      }

    case JOBTYPE_SELECTED_ITEMS_CHANGE:
      return { ...state, loading: true, selectedItems: action.payload };
    default:
      return { ...state };

    case JOBTYPE_DELETE_ITEM:
      return { ...state, loading: false };

    case JOBTYPE_DELETE_ITEM_SUCCESS:
      return { ...state, loading: true, jobtypes: action.payload, jobtype: action.payload };

    case JOBTYPE_DELETE_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };
  }
};
