import {
  COMMUNITY_PARTNER_ADD_NEW,
  COMMUNITY_PARTNER_ADD_NEW_SUCCESS,
  COMMUNITY_PARTNER_ADD_NEW_ERROR,
  COMMUNITY_PARTNER_EDIT,
  COMMUNITY_PARTNER_EDIT_ERROR,
  COMMUNITY_PARTNER_EDIT_SUCCESS,
  COMMUNITY_PARTNER_GET_BY_QUERY,
  COMMUNITY_PARTNER_GET_BY_ID,
  COMMUNITY_PARTNER_GET_BY_QUERY_SUCCESS,
  COMMUNITY_PARTNER_GET_BY_QUERY_ERROR,
  COMMUNITY_PARTNER_GET_BY_ID_SUCCESS,
  COMMUNITY_PARTNER_ADD_NEW_REQUEST,
  COMMUNITY_PARTNER_DELETE,
  COMMUNITY_PARTNER_DELETE_SUCCESS,
  COMMUNITY_PARTNER_DELETE_ERROR,
  COMMUNITY_PARTNER_GET_BY_ID_ERROR,
} from './types';

// CommunityPartner

export const addNewCommunityPartner = items => ({
  type: COMMUNITY_PARTNER_ADD_NEW,
  payload: items,
});

export const addNewCommunityPartnerRequest = () => ({
  type: COMMUNITY_PARTNER_ADD_NEW_REQUEST,
});

export const addNewCommunityPartnerSuccess = items => ({
  type: COMMUNITY_PARTNER_ADD_NEW_SUCCESS,
  payload: items,
});

export const addNewCommunityPartnerError = error => ({
  type: COMMUNITY_PARTNER_ADD_NEW_ERROR,
  payload: error,
});

export const editCommunityPartner = item => ({
  type: COMMUNITY_PARTNER_EDIT,
  payload: item,
});

export const editCommunityPartnerSuccess = item => ({
  type: COMMUNITY_PARTNER_EDIT_SUCCESS,
  payload: item,
});

export const editCommunityPartnerError = error => ({
  type: COMMUNITY_PARTNER_EDIT_ERROR,
  payload: error,
});

export const getCommunityPartnerByQuery = query => ({
  type: COMMUNITY_PARTNER_GET_BY_QUERY,
  payload: query,
});

export const getCommunityPartnerByQuerySuccess = item => ({
  type: COMMUNITY_PARTNER_GET_BY_QUERY_SUCCESS,
  payload: item,
});

export const getCommunityPartnerByQueryError = error => ({
  type: COMMUNITY_PARTNER_GET_BY_QUERY_ERROR,
  payload: error,
});

export const getCommunityPartnerById = id => ({
  type: COMMUNITY_PARTNER_GET_BY_ID,
  payload: id,
});

export const getCommunityPartnerByIdSuccess = item => ({
  type: COMMUNITY_PARTNER_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getCommunityPartnerByIdError = error => ({
  type: COMMUNITY_PARTNER_GET_BY_ID_ERROR,
  payload: error,
});

export const deleteCommunityPartnerById = id => ({
  type: COMMUNITY_PARTNER_DELETE,
  payload: id,
});

export const deleteCommunityPartnerByIdSuccess = item => ({
  type: COMMUNITY_PARTNER_DELETE_SUCCESS,
  payload: item,
});

export const deleteCommunityPartnerByIdErrorr = item => ({
  type: COMMUNITY_PARTNER_DELETE_ERROR,
  payload: item,
});
