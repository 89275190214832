import {
  JMPI_MATRIX_ADD_NEW,
  JMPI_MATRIX_ADD_NEW_SUCCESS,
  JMPI_MATRIX_ADD_NEW_ERROR,
  JMPI_MATRIX_EDIT,
  JMPI_MATRIX_EDIT_SUCCESS,
  JMPI_MATRIX_EDIT_ERROR,
  JMPI_MATRIX_GET_BY_QUERY,
  JMPI_MATRIX_GET_BY_ID,
  JMPI_MATRIX_GET_BY_ID_SUCCESS,
  JMPI_MATRIX_GET_BY_ID_ERROR,
  JMPI_MATRIX_GET_BY_QUERY_SUCCESS,
  JMPI_MATRIX_GET_BY_QUERY_ERROR,
  JMPI_MATRIX_ADD_NEW_REQUEST,
  JMPI_MATRIX_DELETE,
  JMPI_MATRIX_DELETE_ERROR,
} from './types';
// item
const initialState = {
  item: null,
  items: [],
  loading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
  total: 0,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case JMPI_MATRIX_ADD_NEW:
    case JMPI_MATRIX_DELETE:
    case JMPI_MATRIX_GET_BY_ID:
    case JMPI_MATRIX_GET_BY_QUERY:
      return {
        ...state,
        loading: true,
        error: null,
        item: null,
      };

    case JMPI_MATRIX_EDIT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case JMPI_MATRIX_EDIT_SUCCESS:
    case JMPI_MATRIX_ADD_NEW_REQUEST:
      return {
        ...state,
        loading: false,
        item: null,
        error: null,
      };
    case JMPI_MATRIX_ADD_NEW_SUCCESS:
    case JMPI_MATRIX_GET_BY_QUERY_SUCCESS:
      const { itemCount, pageCount, total, data } = payload;
      return {
        ...state,
        loading: false,
        items: data,
        total,
        itemCount,
        pageCount,
      };

    case JMPI_MATRIX_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
      };

    case JMPI_MATRIX_ADD_NEW_ERROR:
    case JMPI_MATRIX_DELETE_ERROR:
    case JMPI_MATRIX_EDIT_ERROR:
    case JMPI_MATRIX_GET_BY_ID_ERROR:
    case JMPI_MATRIX_GET_BY_QUERY_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
}
