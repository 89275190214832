import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  CLIENT_REFERRAL_ADD_NEW,
  CLIENT_REFERRAL_EDIT,
  CLIENT_REFERRAL_GET_BY_QUERY,
  CLIENT_REFERRAL_GET_BY_ID,
  CLIENT_REFERRAL_DELETE,
} from './types';

import {
  addNewClientReferralError,
  editClientReferralError,
  getClientReferralByQuerySuccess,
  getClientReferralByQueryError,
  getClientReferralByIdError,
  getClientReferralByIdSuccess,
  deleteClientReferralByIdErrorr,
} from './actions';

const addNewClientReferralReqeust = async items => {
  return axios.post(`${Config.apiServerHost}/api/ClientReferral`, items, await authHeader());
};

const editClientReferralRequest = async items => {
  return axios.put(`${Config.apiServerHost}/api/ClientReferral`, items, await authHeader());
};

const getClientReferralByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/ClientReferral/${id}`, await authHeader());
};

const getClientReferralByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/ClientReferral/fetch`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const deleteClientReferralRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/ClientReferral/${id}`, await authHeader());
};
function* addNewClientReferral({ payload }) {
  try {
    const { clientId, programId, servicePlanHeaderId, data } = payload;
    const query = {
      clientId,
      programId,
      servicePlanHeaderId,
    };

    let response = yield call(addNewClientReferralReqeust, data);
    response = yield call(getClientReferralByQueryRequest, query);
    yield put(getClientReferralByQuerySuccess(response.data));
  } catch (error) {
    yield put(addNewClientReferralError(error.response.statusText));
  }
}

function* editClientReferral({ payload }) {
  try {
    const { clientId, programId, servicePlanHeaderId, data } = payload;
    const query = {
      clientId,
      programId,
      servicePlanHeaderId,
    };

    let response = yield call(editClientReferralRequest, data);
    response = yield call(getClientReferralByQueryRequest, query);
    yield put(getClientReferralByQuerySuccess(response.data));
  } catch (error) {
    yield put(editClientReferralError(error.response.statusText));
  }
}

function* getClientReferralByQuery({ payload }) {
  try {
    const response = yield call(getClientReferralByQueryRequest, payload);
    yield put(getClientReferralByQuerySuccess(response.data));
  } catch (error) {
    yield put(getClientReferralByQueryError(error.response.statusText));
  }
}

function* getClientReferralById({ payload }) {
  try {
    const response = yield call(getClientReferralByIdRequest, payload);
    yield put(getClientReferralByIdSuccess(response.data));
  } catch (error) {
    yield put(getClientReferralByIdError(error.response.statusText));
  }
}

function* deleteClientReferral({ payload }) {
  try {
    const { programId, clientId, servicePlanHeaderId, data } = payload;
    const query = {
      programId,
      clientId,
      servicePlanHeaderId,
    };

    let response = yield call(deleteClientReferralRequest, data);
    response = yield call(getClientReferralByQueryRequest, query);
    yield put(getClientReferralByQuerySuccess(response.data));
  } catch (error) {
    yield put(deleteClientReferralByIdErrorr(error.response.statusText));
  }
}

export function* watchAddNewClientReferral() {
  yield takeEvery(CLIENT_REFERRAL_ADD_NEW, addNewClientReferral);
}
export function* watchEditClientReferral() {
  yield takeEvery(CLIENT_REFERRAL_EDIT, editClientReferral);
}

export function* watchGetClientReferralByQuery() {
  yield takeEvery(CLIENT_REFERRAL_GET_BY_QUERY, getClientReferralByQuery);
}

export function* watchGetClientReferralById() {
  yield takeEvery(CLIENT_REFERRAL_GET_BY_ID, getClientReferralById);
}

export function* watchDeleteClientReferral() {
  yield takeEvery(CLIENT_REFERRAL_DELETE, deleteClientReferral);
}

export default function* rootSaga() {
  yield all([
    fork(watchAddNewClientReferral),
    fork(watchEditClientReferral),
    fork(watchGetClientReferralByQuery),
    fork(watchDeleteClientReferral),
    fork(watchGetClientReferralById),
  ]);
}
