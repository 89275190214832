import axios from 'axios';
import { authHeader, handleResponse } from '../../helpers/auth-header';
import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  STAKE_HOLDER_EMAIL_ADD_NEW,
  STAKE_HOLDER_EMAIL_CHANGE_STATUS,
  STAKE_HOLDER_EMAIL_DELETE,
  STAKE_HOLDER_EMAIL_GET_BY_ID,
  STAKE_HOLDER_EMAIL_GET_PAGING,
  STAKE_HOLDER_EMAIL_UPDATE,
} from './types';

import {
  addStakeHolderEmailSuccess,
  addStakeHolderEmailError,
  getPagingStakeHolderEmailSuccess,
  getPagingStakeHolderEmailError,
  getStakeHolderEmailByIdSuccess,
  getStakeHolderEmailByIdError,
  updateStakeHolderEmailSuccess,
  updateStakeHolderEmailError,
} from './actions';

const addStakeHolderEmailRequest = async item => {
  return axios.post(`${Config.apiServerHost}/api/StakeHolderEmail`, item, {
    headers: { ...(await authHeader()).headers },
  });
};

const updateStakeHolderEmailRequest = async item => {
  return axios.put(`${Config.apiServerHost}/api/StakeHolderEmail`, item, {
    headers: { ...(await authHeader()).headers },
  });
};

const sendStakeHolderEmailRequest = async item => {
  return axios.post(`${Config.apiServerHost}/api/StakeHolderEmail/sendEmail`, item, {
    headers: { ...(await authHeader()).headers },
  });
};

const replyStakeHolderEmailRequest = async (id, item) => {
  return axios.post(`${Config.apiServerHost}/api/StakeHolderEmail/${id}/reply`, item, {
    headers: { ...(await authHeader()).headers },
  });
};

const replyAllStakeHolderEmailRequest = async (id, item) => {
  return axios.post(`${Config.apiServerHost}/api/StakeHolderEmail/${id}/replyAll`, item, {
    headers: { ...(await authHeader()).headers },
  });
};

const forwardStakeHolderEmailRequest = async (id, item) => {
  return axios.post(`${Config.apiServerHost}/api/StakeHolderEmail/${id}/forward`, item, {
    headers: { ...(await authHeader()).headers },
  });
};

const getPagingStakeHolderEmailRequest = async query => {
  console.log({ query });
  return axios.get(`${Config.apiServerHost}/api/StakeHolderEmail/list`, {
    params: query,
    headers: { ...(await authHeader()).headers },
  });
};

const getPagingStakeHolderEmailMessageAttachmentRequest = async query => {
  console.log({ query });
  return axios.get(`${Config.apiServerHost}/api/StakeHolderEmail/attachment`, {
    params: query,
    headers: { ...(await authHeader()).headers },
  });
};

const getStakeHolderEmailByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/StakeHolderEmail/${id}`, {
    headers: { ...(await authHeader()).headers },
  });
};

const changeStatusStakeHolderEmailRequest = async item => {
  return axios.get(`${Config.apiServerHost}/api/StakeHolderEmail/statusChange`, {
    params: item,
    headers: { ...(await authHeader()).headers },
  });
};

const deleteStakeHolderEmailRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/StakeHolderEmail/${id}`, await authHeader());
};

function* addNewStakeHolderEmail({ payload }) {
  try {
    const { data, stakeHolderType, stakeHolderId } = payload;
    let response = yield call(addStakeHolderEmailRequest, data);
    yield put(addStakeHolderEmailSuccess(response.data));

    response = yield call(getPagingStakeHolderEmailRequest, { stakeHolderType, stakeHolderId });
    yield put(getPagingStakeHolderEmailSuccess(response.data));
  } catch (error) {
    yield put(addStakeHolderEmailError(error.response.statusText));
  }
}

function* updateStakeHolderEmail({ payload }) {
  try {
    const { data, stakeHolderType, stakeHolderId } = payload;
    let response = yield call(updateStakeHolderEmailRequest, data);
    yield put(updateStakeHolderEmailSuccess(response.data));

    response = yield call(getPagingStakeHolderEmailRequest, { stakeHolderType, stakeHolderId });
    yield put(getPagingStakeHolderEmailSuccess(response.data));
  } catch (error) {
    yield put(updateStakeHolderEmailError(error.response.statusText));
  }
}

function* getPagingStakeHolderEmail({ payload }) {
  try {
    let response = yield call(getPagingStakeHolderEmailRequest, payload);
    yield put(getPagingStakeHolderEmailSuccess(response.data));
  } catch (error) {
    yield put(getPagingStakeHolderEmailError(error.response.statusText));
  }
}

function* getStakeHolderEmailById({ payload }) {
  try {
    let response = yield call(getStakeHolderEmailByIdRequest, payload);
    yield put(getStakeHolderEmailByIdSuccess(response.data));
  } catch (error) {
    yield put(getStakeHolderEmailByIdError(error.response.statusText));
  }
}

function* changeStatusStakeHolderEmail({ payload }) {
  try {
    const { data, stakeHolderType, stakeHolderId } = payload;
    let response = yield call(changeStatusStakeHolderEmailRequest, data);

    response = yield call(getPagingStakeHolderEmailRequest, { stakeHolderType, stakeHolderId });
    yield put(getPagingStakeHolderEmailSuccess(response.data));
  } catch (error) {
    yield put(getPagingStakeHolderEmailError(error.response.statusText));
  }
}

function* deleteStakeHolderEmail({ payload }) {
  try {
    const { data, stakeHolderType, stakeHolderId } = payload;
    let response = yield call(deleteStakeHolderEmailRequest, data);

    response = yield call(getPagingStakeHolderEmailRequest, { stakeHolderType, stakeHolderId });
    yield put(getPagingStakeHolderEmailSuccess(response.data));
  } catch (error) {
    yield put(getPagingStakeHolderEmailError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(STAKE_HOLDER_EMAIL_ADD_NEW, addNewStakeHolderEmail);
  yield takeEvery(STAKE_HOLDER_EMAIL_UPDATE, updateStakeHolderEmail);
  yield takeEvery(STAKE_HOLDER_EMAIL_GET_PAGING, getPagingStakeHolderEmail);
  yield takeEvery(STAKE_HOLDER_EMAIL_GET_BY_ID, getStakeHolderEmailById);
  yield takeEvery(STAKE_HOLDER_EMAIL_CHANGE_STATUS, changeStatusStakeHolderEmail);
  yield takeEvery(STAKE_HOLDER_EMAIL_DELETE, deleteStakeHolderEmail);
}

export {
  getPagingStakeHolderEmailRequest,
  sendStakeHolderEmailRequest,
  replyStakeHolderEmailRequest,
  replyAllStakeHolderEmailRequest,
  forwardStakeHolderEmailRequest,
  getPagingStakeHolderEmailMessageAttachmentRequest,
};
