import {
  GET_DOCUMENTS_BY_CLIENT_ID,
  GET_DOCUMENTS_BY_CLIENT_ID_SUCCESS,
  GET_DOCUMENTS_BY_CLIENT_ID_ERROR,
  SAVE_DOCUMENTS,
  SAVE_DOCUMENTS_SUCCESS,
  SAVE_DOCUMENTS_ERROR,
  DELETE_DOCUMENT_BY_ID,
  DELETE_DOCUMENT_BY_ID_SUCCESS,
  DELETE_DOCUMENT_BY_ID_ERROR,
  GET_DOCUMENTS_BY_ID,
  GET_DOCUMENTS_BY_ID_SUCCESS,
  GET_DOCUMENTS_BY_ID_ERROR,
  UPDATE_DOCUMENTS,
  UPDATE_DOCUMENTS_SUCCESS,
  UPDATE_DOCUMENTS_ERROR,
} from '../actions';

const initialState = {
  documents: [],
  document: null,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case DELETE_DOCUMENT_BY_ID:
    case SAVE_DOCUMENTS:
    case GET_DOCUMENTS_BY_CLIENT_ID:
    case GET_DOCUMENTS_BY_ID:
    case UPDATE_DOCUMENTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_DOCUMENT_BY_ID_SUCCESS:
    case SAVE_DOCUMENTS_SUCCESS:
    case GET_DOCUMENTS_BY_CLIENT_ID_SUCCESS:
    case UPDATE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: payload,
      };
    case GET_DOCUMENTS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        document: payload,
      };
    case GET_DOCUMENTS_BY_CLIENT_ID_ERROR:
    case SAVE_DOCUMENTS_ERROR:
    case DELETE_DOCUMENT_BY_ID_ERROR:
    case GET_DOCUMENTS_BY_ID_ERROR:
    case UPDATE_DOCUMENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
