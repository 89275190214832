import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../../helpers/auth-header';
import Config from '../../../config';
import axios from 'axios';

import { COJG_RECONCILIATION_GET_LIST } from '../../actions';

import { getCOJGReconciliationListSuccess, getCOJGReconciliationListError } from './actions';

const getCOJGReconciliationListRequest = async filter => {
  return await axios.get(
    `${Config.apiServerHost}/api/contract/cojg/contract-paid?fromDate=${filter.payload.fromDate}&toDate=${filter.payload.toDate}&fiscalYear=${filter.payload.fiscalYear}`,
    await authHeader()
  );
};

function* getCOJGReconciliationListItems(payload) {
  try {
    const response = yield call(getCOJGReconciliationListRequest, payload);
    yield put(getCOJGReconciliationListSuccess(response.data));
  } catch (error) {
    yield put(getCOJGReconciliationListError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(COJG_RECONCILIATION_GET_LIST, getCOJGReconciliationListItems);
}

export default function* rootSaga() {
  yield all([fork(watchGetList)]);
}
