import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  COMMUNITY_PARTNER_ADD_NEW,
  COMMUNITY_PARTNER_EDIT,
  COMMUNITY_PARTNER_GET_BY_QUERY,
  COMMUNITY_PARTNER_GET_BY_ID,
  COMMUNITY_PARTNER_DELETE,
} from './types';

import {
  addNewCommunityPartnerError,
  editCommunityPartnerError,
  getCommunityPartnerByQuerySuccess,
  getCommunityPartnerByQueryError,
  getCommunityPartnerByIdError,
  getCommunityPartnerByIdSuccess,
  deleteCommunityPartnerByIdErrorr,
} from './actions';

const addNewCommunityPartnerRequest = async items => {
  return axios.post(`${Config.apiServerHost}/api/CommunityPartner`, items, {
    headers: { ...(await authHeader()).headers },
  });
};

const editCommunityPartnerRequest = async items => {
  return axios.put(`${Config.apiServerHost}/api/CommunityPartner`, items, {
    headers: { ...(await authHeader()).headers },
  });
};

const getCommunityPartnerByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/CommunityPartner/${id}`, {
    headers: { ...(await authHeader()).headers },
  });
};

const getCommunityPartnerByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/CommunityPartner/paging`;
  return await axios.post(url, query, {
    headers: { ...(await authHeader()).headers },
  });
};

const deleteCommunityPartnerRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/CommunityPartner/${id}`, {
    headers: { ...(await authHeader()).headers },
  });
};

function* addNewCommunityPartner({ payload }) {
  try {
    const { clientId, employerId, data } = payload;

    let response = yield call(addNewCommunityPartnerRequest, data);
    response = yield call(getCommunityPartnerByQueryRequest, {
      clientId,
      employerId,
    });
    yield put(getCommunityPartnerByQuerySuccess(response.data));
  } catch (error) {
    yield put(addNewCommunityPartnerError(error.response.statusText));
  }
}

function* editCommunityPartner({ payload }) {
  try {
    const { clientId, employerId, data } = payload;

    let response = yield call(editCommunityPartnerRequest, data);
    response = yield call(getCommunityPartnerByQueryRequest, {
      clientId,
      employerId,
    });
    yield put(getCommunityPartnerByQuerySuccess(response.data));
  } catch (error) {
    yield put(editCommunityPartnerError(error.response.statusText));
  }
}

function* getCommunityPartnerByQuery({ payload }) {
  try {
    const response = yield call(getCommunityPartnerByQueryRequest, payload);
    yield put(getCommunityPartnerByQuerySuccess(response.data));
  } catch (error) {
    yield put(getCommunityPartnerByQueryError(error.response.statusText));
  }
}

function* getCommunityPartnerById({ payload }) {
  try {
    const response = yield call(getCommunityPartnerByIdRequest, payload);
    yield put(getCommunityPartnerByIdSuccess(response.data));
  } catch (error) {
    yield put(getCommunityPartnerByIdError(error.response.statusText));
  }
}

function* deleteCommunityPartner({ payload }) {
  try {
    const { clientId, employerId, data } = payload;

    let response = yield call(deleteCommunityPartnerRequest, data);
    response = yield call(getCommunityPartnerByQueryRequest, {
      clientId,
      employerId,
    });
    yield put(getCommunityPartnerByQuerySuccess(response.data));
  } catch (error) {
    yield put(deleteCommunityPartnerByIdErrorr(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(COMMUNITY_PARTNER_ADD_NEW, addNewCommunityPartner);
  yield takeEvery(COMMUNITY_PARTNER_EDIT, editCommunityPartner);
  yield takeEvery(COMMUNITY_PARTNER_GET_BY_QUERY, getCommunityPartnerByQuery);
  yield takeEvery(COMMUNITY_PARTNER_GET_BY_ID, getCommunityPartnerById);
  yield takeEvery(COMMUNITY_PARTNER_DELETE, deleteCommunityPartner);
}
