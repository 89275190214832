import {
  LOCATION_ADD_NEW,
  LOCATION_ADD_NEW_ERROR,
  LOCATION_ADD_NEW_SUCCESS,
  LOCATION_DELETE,
  LOCATION_DELETE_ERROR,
  LOCATION_DELETE_SUCCESS,
  LOCATION_GET_BY_ID,
  LOCATION_GET_BY_ID_ERROR,
  LOCATION_GET_BY_ID_SUCCESS,
  LOCATION_GET_PAGING,
  LOCATION_GET_PAGING_ERROR,
  LOCATION_GET_PAGING_SUCCESS,
  LOCATION_UPDATE,
  LOCATION_UPDATE_ERROR,
  LOCATION_UPDATE_SUCCESS,
} from './types';

// Location
export const getPagingLocation = items => ({
  type: LOCATION_GET_PAGING,
  payload: items,
});

export const getPagingLocationSuccess = items => ({
  type: LOCATION_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingLocationError = error => ({
  type: LOCATION_GET_PAGING_ERROR,
  payload: error,
});

export const addLocation = items => ({
  type: LOCATION_ADD_NEW,
  payload: items,
});

export const addLocationSuccess = items => ({
  type: LOCATION_ADD_NEW_SUCCESS,
  payload: items,
});

export const addLocationError = error => ({
  type: LOCATION_ADD_NEW_ERROR,
  payload: error,
});

export const getLocationById = items => ({
  type: LOCATION_GET_BY_ID,
  payload: items,
});

export const getLocationByIdSuccess = items => ({
  type: LOCATION_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getLocationByIdError = error => ({
  type: LOCATION_GET_BY_ID_ERROR,
  payload: error,
});

export const updateLocation = items => ({
  type: LOCATION_UPDATE,
  payload: items,
});

export const updateLocationSuccess = items => ({
  type: LOCATION_UPDATE_SUCCESS,
  payload: items,
});

export const updateLocationError = error => ({
  type: LOCATION_UPDATE_ERROR,
  payload: error,
});

export const deleteLocation = items => ({
  type: LOCATION_DELETE,
  payload: items,
});

export const deleteLocationSuccess = items => ({
  type: LOCATION_DELETE_SUCCESS,
  payload: items,
});

export const deleteLocationError = error => ({
  type: LOCATION_DELETE_ERROR,
  payload: error,
});
