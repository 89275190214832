import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../../helpers/auth-header';
import axios from 'axios';
import Config from '../../../config';

import { NOTIFICATION_REPORT_GET_LIST } from '../../actions';

import { getNotificationReportListSuccess, getNotificationReportListError } from './actions';

const getNotificationReportListRequest = async filter => {
  return await axios.get(
    `${Config.apiServerHost}/api/notification/notification-report?fromDate=${filter.payload.fromDate}&toDate=${filter.payload.toDate}`,
    await authHeader()
  );
};

function* getNotificationReportListItems(payload) {
  try {
    const response = yield call(getNotificationReportListRequest, payload);
    yield put(getNotificationReportListSuccess(response.data));
  } catch (error) {
    yield put(getNotificationReportListError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(NOTIFICATION_REPORT_GET_LIST, getNotificationReportListItems);
}

export default function* rootSaga() {
  yield all([fork(watchGetList)]);
}
