import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import FileSaver from 'file-saver';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  FINANCIAL_TRACKING_DELETE,
  FINANCIAL_TRACKING_EDIT,
  FINANCIAL_TRACKING_GET_BY_ID,
  FINANCIAL_TRACKING_ADD_NEW,
  FINANCIAL_TRACKING_GET_LIST,
} from './types';
import Config from '../../config';

import {
  getFinancialTrackingListSuccess,
  getFinancialTrackingByIdSuccess,
  postingError,
} from './actions';

// FinancialTracking
const getFinancialTrackingListRequest = async payload => {
  let url = `${Config.apiServerHost}/api/FinancialTracking`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const getFinancialTrackingListByClientIdRequest = async payload => {
  let url = `${Config.apiServerHost}/api/FinancialTracking/fetch`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const addNewFinancialTrackingReqeust = async actionPlan => {
  return axios.post(
    `${Config.apiServerHost}/api/FinancialTracking`,
    actionPlan,
    await authHeader()
  );
};

const editFinancialTrackingRequest = async actionPlan => {
  return axios.put(`${Config.apiServerHost}/api/FinancialTracking`, actionPlan, await authHeader());
};

const deleteFinancialTrackingRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/FinancialTracking/${id}`, await authHeader());
};

const getFinancialTrackingByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/FinancialTracking/${id}`, await authHeader());
};

const exportBulkFinancialTrackingToPDF = async (params, filename) => {
  const { headers } = await authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };

  return await fetch(
    buildUrl(`${Config.apiServerHost}/api/FinancialTracking/exportToPDF`, params),
    requestOptions
  )
    .then(response => response.blob())
    .then(data => {
      FileSaver.saveAs(new Blob([data]), filename);
    });
};

const exportFinancialTrackingToPDF = async (id, filename) => {
  const { headers } = await authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };

  const url = `${Config.apiServerHost}/api/FinancialTracking/exportToPDF/${id}`;

  return await fetch(url, requestOptions)
    .then(response => response.blob())
    .then(data => {
      FileSaver.saveAs(new Blob([data]), filename);
    });
};

function* getFinancialTrackingList({ payload }) {
  try {
    const response = yield call(getFinancialTrackingListByClientIdRequest, payload);
    yield put(getFinancialTrackingListSuccess(response.data));
  } catch (error) {
    yield put(postingError(error.response.statusText));
  }
}

function* addNewFinancialTracking({ payload }) {
  try {
    const { filter, data } = payload;
    const query = {
      filter: filter ?? '',
    };

    yield call(addNewFinancialTrackingReqeust, data);
    const response = yield call(getFinancialTrackingListByClientIdRequest, query);
    yield put(getFinancialTrackingListSuccess(response.data));
  } catch (error) {
    yield put(postingError(error.response.statusText));
  }
}

function* editFinancialTracking({ payload }) {
  try {
    const { filter, data } = payload;
    const query = {
      filter: filter ?? '',
    };

    yield call(editFinancialTrackingRequest, data);
    const response = yield call(getFinancialTrackingListByClientIdRequest, query);
    yield put(getFinancialTrackingListSuccess(response.data));
  } catch (error) {
    yield put(postingError(error.response.statusText));
  }
}

function* getFinancialTrackingById({ payload }) {
  try {
    const response = yield call(getFinancialTrackingByIdRequest, payload);
    yield put(getFinancialTrackingByIdSuccess(response.data));
  } catch (error) {
    yield put(postingError(error.response.statusText));
  }
}

function* deleteFinancialTracking({ payload }) {
  try {
    const { filter, data } = payload;
    const query = {
      filter: filter ?? '',
    };

    yield call(deleteFinancialTrackingRequest, data);
    const response = yield call(getFinancialTrackingListByClientIdRequest, query);
    yield put(getFinancialTrackingListSuccess(response.data));
  } catch (error) {
    yield put(postingError(error.response.statusText));
  }
}

export function* watchFinancialTrackingList() {
  yield takeEvery(FINANCIAL_TRACKING_GET_LIST, getFinancialTrackingList);
}

export function* watchAddNewFinancialTracking() {
  yield takeEvery(FINANCIAL_TRACKING_ADD_NEW, addNewFinancialTracking);
}

export function* watchFinancialTrackingById() {
  yield takeEvery(FINANCIAL_TRACKING_GET_BY_ID, getFinancialTrackingById);
}

export function* watchEditFinancialTracking() {
  yield takeEvery(FINANCIAL_TRACKING_EDIT, editFinancialTracking);
}

export function* watchDeleteFinancialTracking() {
  yield takeEvery(FINANCIAL_TRACKING_DELETE, deleteFinancialTracking);
}

export default function* rootSaga() {
  yield all([
    fork(watchFinancialTrackingList),
    fork(watchAddNewFinancialTracking),
    fork(watchFinancialTrackingById),
    fork(watchEditFinancialTracking),
    fork(watchDeleteFinancialTracking),
  ]);
}

export {
  getFinancialTrackingListByClientIdRequest,
  getFinancialTrackingListRequest,
  editFinancialTrackingRequest,
  exportBulkFinancialTrackingToPDF,
  exportFinancialTrackingToPDF,
};
