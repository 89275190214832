// COJG_CONTRACT_DOCUMENT
export const GET_COJG_CONTRACT_DOCUMENTS_BY_ID = 'GET_COJG_CONTRACT_DOCUMENTS_BY_ID';
export const GET_COJG_CONTRACT_DOCUMENTS_BY_ID_SUCCESS =
  'GET_COJG_CONTRACT_DOCUMENTS_BY_ID_SUCCESS';
export const GET_COJG_CONTRACT_DOCUMENTS_BY_QUERY = 'GET_COJG_CONTRACT_DOCUMENTS_BY_QUERY';
export const GET_COJG_CONTRACT_DOCUMENTS_BY_QUERY_SUCCESS =
  'GET_COJG_CONTRACT_DOCUMENTS_BY_QUERY_SUCCESS';
export const UPLOAD_COJG_CONTRACT_DOCUMENTS = 'UPLOAD_COJG_CONTRACT_DOCUMENTS';
export const EDIT_COJG_CONTRACT_DOCUMENTS = 'EDIT_COJG_CONTRACT_DOCUMENTS';
export const DELETE_COJG_CONTRACT_DOCUMENTS = 'DELETE_COJG_CONTRACT_DOCUMENTS';
export const COJG_CONTRACT_DOCUMENT_ERROR = 'COJG_CONTRACT_DOCUMENT_ERROR';
