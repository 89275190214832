import {
  GET_CLIENT_SUMMARY_LBS,
  GET_CLIENT_SUMMARY_LBS_SUCCESS,
  SAVE_CLIENT_SUMMARY_LBS,
  CLIENT_SUMMARY_LBS_ERROR,
} from './types';

const initialState = {
  item: null,
  notifications: [],
  loading: false,
  error: null,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CLIENT_SUMMARY_LBS:
    case SAVE_CLIENT_SUMMARY_LBS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_CLIENT_SUMMARY_LBS_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
      };

    case CLIENT_SUMMARY_LBS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
}
