import { logger } from '../logger';
import { Auth } from 'aws-amplify';
export async function authHeader() {
  // return authorization header with jwt token
  let token = localStorage.getItem('token');

  try {
    const newToken = (await Auth.currentSession()).getIdToken().getJwtToken(); // this automatically refreshes the token when its time

    if (newToken) {
      token = newToken;
      localStorage.setItem('token', newToken);

      try {
        let lastRefreshInSeconds = Number(localStorage.getItem('lastRefresh'));
        const dateInSecs = Math.round(new Date().getTime() / 1000);

        if (
          !lastRefreshInSeconds || //doesnt exist
          lastRefreshInSeconds > dateInSecs || // is somehow in the future
          lastRefreshInSeconds < dateInSecs - 15 // hasnt been called in the last 15 seconds
        ) {
          const cognitoUser = await Auth.currentAuthenticatedUser();
          const currentSession = await Auth.currentSession();
          localStorage.setItem('lastRefresh', dateInSecs);
          cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
            if (err) {
              logger.error('Refresh Token failed - Logging out user because: ', err);
              localStorage.setItem('token', null);
              window.location = '/user/login';
              return null;
            }
          });
        }
      } catch (e) {
        logger.debug('Unable to refresh Token', e);
      }
    } else {
      return null;
    }
  } catch (error) {
    logger.error('Error refreshing token', error);
    logger.debug('cannot refresh, redirect to login');
    if (
      !window.location.pathname.startsWith('/user') &&
      !window.location.pathname.startsWith('/unsubscribe')
    ) {
      logger.debug('does not start', window.location.pathname.startsWith('/user'));
      window.location = '/user/login';
    }
    return null;
  }

  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
}

export function handleResponse(response) {
  return response.text().then(text => {
    if (response.status >= 200 && response.status < 300) {
      const data = text && JSON.parse(text);
      return data;
    } else if (response.status === 400) {
      return Promise.reject(JSON.parse(text));
    } else if (response.status === 401) {
      // localStorage.removeItem('token');
      window.location = '/unauthorized';
    } else {
      //const error = response.statusText;
      return Promise.reject(text);
    }
  });
}

export const handleUnauthorized = response => {
  const { status } = response;

  if (status === 401) {
    // localStorage.removeItem('token');
    window.location = '/unauthorized';
  }
};
