import {
  MILEAGE_SETTING_ADD_NEW,
  MILEAGE_SETTING_ADD_NEW_SUCCESS,
  MILEAGE_SETTING_ADD_NEW_ERROR,
  MILEAGE_SETTING_DELETE,
  MILEAGE_SETTING_DELETE_ERROR,
  MILEAGE_SETTING_DELETE_SUCCESS,
  MILEAGE_SETTING_EDIT,
  MILEAGE_SETTING_EDIT_ERROR,
  MILEAGE_SETTING_EDIT_SUCCESS,
  MILEAGE_SETTING_GET_BY_ID,
  MILEAGE_SETTING_GET_BY_ID_ERROR,
  MILEAGE_SETTING_GET_BY_ID_SUCCESS,
  MILEAGE_SETTING_GET_LIST,
  MILEAGE_SETTING_GET_LIST_ERROR,
  MILEAGE_SETTING_GET_LIST_SUCCESS,
  MILEAGE_SETTING_ADD_NEW_REQUEST,
} from '../actions';

export const getMileageSettingList = () => ({
  type: MILEAGE_SETTING_GET_LIST,
});

export const getMileageSettingListSuccess = MileageSettings => ({
  type: MILEAGE_SETTING_GET_LIST_SUCCESS,
  payload: MileageSettings,
});

export const getMileageSettingListError = error => ({
  type: MILEAGE_SETTING_GET_LIST_ERROR,
  payload: error,
});

export const addNewMileageSetting = (MileageSetting, history) => ({
  type: MILEAGE_SETTING_ADD_NEW,
  payload: { MileageSetting, history },
});

export const addNewMileageSettingRequest = () => ({
  type: MILEAGE_SETTING_ADD_NEW_REQUEST,
});

export const addNewMileageSettingSuccess = MileageSetting => ({
  type: MILEAGE_SETTING_ADD_NEW_SUCCESS,
  payload: MileageSetting,
});

export const addNewMileageSettingError = error => ({
  type: MILEAGE_SETTING_ADD_NEW_ERROR,
  payload: error,
});

export const editMileageSetting = (MileageSetting, history) => ({
  type: MILEAGE_SETTING_EDIT,
  payload: { MileageSetting, history },
});

export const editMileageSettingSuccess = MileageSetting => ({
  type: MILEAGE_SETTING_EDIT_SUCCESS,
  payload: MileageSetting,
});

export const editMileageSettingError = error => ({
  type: MILEAGE_SETTING_EDIT_ERROR,
  payload: error,
});

export const deleteMileageSetting = id => ({
  type: MILEAGE_SETTING_DELETE,
  payload: id,
});

export const deleteMileageSettingSuccess = id => ({
  type: MILEAGE_SETTING_DELETE_SUCCESS,
  payload: id,
});

export const deleteMileageSettingError = error => ({
  type: MILEAGE_SETTING_DELETE_ERROR,
  payload: error,
});

export const getMileageSettingById = id => ({
  type: MILEAGE_SETTING_GET_BY_ID,
  payload: id,
});

export const getMileageSettingByIdSuccess = MileageSetting => ({
  type: MILEAGE_SETTING_GET_BY_ID_SUCCESS,
  payload: MileageSetting,
});

export const getMileageSettingByIdError = error => ({
  type: MILEAGE_SETTING_GET_BY_ID_ERROR,
  payload: error,
});
