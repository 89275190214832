import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  PLAN_CONTENT_ADD_NEW,
  PLAN_CONTENT_EDIT,
  PLAN_CONTENT_GET_BY_QUERY,
  PLAN_CONTENT_GET_BY_ID,
  PLAN_CONTENT_DELETE,
} from './types';

import {
  addNewPlanContentError,
  editPlanContentError,
  getPlanContentByQuerySuccess,
  getPlanContentByQueryError,
  getPlanContentByIdError,
  getPlanContentByIdSuccess,
  deletePlanContentByIdErrorr,
} from './actions';

const getPlanContentSubGoalItems = async () => {
  return axios.get(`${Config.apiServerHost}/api/PlanContent/subGoal`, await authHeader());
};

const addNewPlanContentReqeust = async items => {
  return axios.post(`${Config.apiServerHost}/api/PlanContent`, items, await authHeader());
};

const editPlanContentRequest = async items => {
  return axios.put(`${Config.apiServerHost}/api/PlanContent`, items, await authHeader());
};

const getPlanContentByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/PlanContent/${id}`, await authHeader());
};

const getPlanContentByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/PlanContent/search`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const deletePlanContentRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/PlanContent/${id}`, await authHeader());
};
function* addNewPlanContent({ payload }) {
  try {
    const { clientId, programId, servicePlanHeaderId } = payload;
    const query = {
      clientId,
      programId,
      servicePlanHeaderId,
    };

    let response = yield call(addNewPlanContentReqeust, payload);
    response = yield call(getPlanContentByQueryRequest, query);
    yield put(getPlanContentByQuerySuccess(response.data));
  } catch (error) {
    yield put(addNewPlanContentError(error.response.statusText));
  }
}

function* editPlanContent({ payload }) {
  try {
    const { clientId, programId, servicePlanHeaderId } = payload;
    const query = {
      clientId,
      programId,
      servicePlanHeaderId,
    };

    let response = yield call(editPlanContentRequest, payload);
    response = yield call(getPlanContentByQueryRequest, query);
    yield put(getPlanContentByQuerySuccess(response.data));
  } catch (error) {
    yield put(editPlanContentError(error.response.statusText));
  }
}

function* getPlanContentByQuery({ payload }) {
  try {
    const response = yield call(getPlanContentByQueryRequest, payload);
    yield put(getPlanContentByQuerySuccess(response.data));
  } catch (error) {
    yield put(getPlanContentByQueryError(error.response.statusText));
  }
}

function* getPlanContentById({ payload }) {
  try {
    const response = yield call(getPlanContentByIdRequest, payload);
    yield put(getPlanContentByIdSuccess(response.data));
  } catch (error) {
    yield put(getPlanContentByIdError(error.response.statusText));
  }
}

function* deletePlanContent({ payload }) {
  try {
    const { programId, clientId, servicePlanHeaderId, id } = payload;
    const query = {
      programId,
      clientId,
      servicePlanHeaderId,
    };

    let response = yield call(deletePlanContentRequest, id);
    response = yield call(getPlanContentByQueryRequest, query);
    yield put(getPlanContentByQuerySuccess(response.data));
  } catch (error) {
    yield put(deletePlanContentByIdErrorr(error.response.statusText));
  }
}

export function* watchAddNewPlanContent() {
  yield takeEvery(PLAN_CONTENT_ADD_NEW, addNewPlanContent);
}
export function* watchEditPlanContent() {
  yield takeEvery(PLAN_CONTENT_EDIT, editPlanContent);
}

export function* watchGetPlanContentByQuery() {
  yield takeEvery(PLAN_CONTENT_GET_BY_QUERY, getPlanContentByQuery);
}

export function* watchGetPlanContentById() {
  yield takeEvery(PLAN_CONTENT_GET_BY_ID, getPlanContentById);
}

export function* watchDeletePlanContent() {
  yield takeEvery(PLAN_CONTENT_DELETE, deletePlanContent);
}

export default function* rootSaga() {
  yield all([
    fork(watchAddNewPlanContent),
    fork(watchEditPlanContent),
    fork(watchGetPlanContentByQuery),
    fork(watchDeletePlanContent),
    fork(watchGetPlanContentById),
  ]);
}

export { getPlanContentSubGoalItems };
