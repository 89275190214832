import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  STAKE_HOLDER_ACTION_ADD_NEW,
  STAKE_HOLDER_ACTION_CHANGE_STATUS,
  STAKE_HOLDER_ACTION_DELETE,
  STAKE_HOLDER_ACTION_GET_BY_ID,
  STAKE_HOLDER_ACTION_GET_PAGING,
  STAKE_HOLDER_ACTION_UPDATE,
} from './types';

import {
  addStakeHolderActionSuccess,
  addStakeHolderActionError,
  getPagingStakeHolderActionSuccess,
  getPagingStakeHolderActionError,
  getStakeHolderActionByIdSuccess,
  getStakeHolderActionByIdError,
  updateStakeHolderActionSuccess,
  updateStakeHolderActionError,
} from './actions';

const addStakeHolderActionRequest = async item => {
  return axios.post(`${Config.apiServerHost}/api/StakeHolderAction`, item, {
    headers: { ...(await authHeader()).headers },
  });
};

const updateStakeHolderActionRequest = async item => {
  return axios.put(`${Config.apiServerHost}/api/StakeHolderAction`, item, {
    headers: { ...(await authHeader()).headers },
  });
};

const getPagingStakeHolderActionRequest = async query => {
  return axios.get(`${Config.apiServerHost}/api/StakeHolderAction/paging`, {
    params: query,
    headers: { ...(await authHeader()).headers },
  });
};

const getStakeHolderActionByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/StakeHolderAction/${id}`, {
    headers: { ...(await authHeader()).headers },
  });
};

const changeStatusStakeHolderActionRequest = async item => {
  return axios.get(`${Config.apiServerHost}/api/StakeHolderAction/statusChange`, {
    params: item,
    headers: { ...(await authHeader()).headers },
  });
};

const deleteStakeHolderActionRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/StakeHolderAction/${id}`, await authHeader());
};

function* addNewStakeHolderAction({ payload }) {
  try {
    const { data, stakeHolderType, stakeHolderId } = payload;
    let response = yield call(addStakeHolderActionRequest, data);
    yield put(addStakeHolderActionSuccess(response.data));

    response = yield call(getPagingStakeHolderActionRequest, { stakeHolderType, stakeHolderId });
    yield put(getPagingStakeHolderActionSuccess(response.data));
  } catch (error) {
    yield put(addStakeHolderActionError(error.response.statusText));
  }
}

function* updateStakeHolderAction({ payload }) {
  try {
    const { data, stakeHolderType, stakeHolderId } = payload;
    let response = yield call(updateStakeHolderActionRequest, data);
    yield put(updateStakeHolderActionSuccess(response.data));

    response = yield call(getPagingStakeHolderActionRequest, { stakeHolderType, stakeHolderId });
    yield put(getPagingStakeHolderActionSuccess(response.data));
  } catch (error) {
    yield put(updateStakeHolderActionError(error.response.statusText));
  }
}

function* getPagingStakeHolderAction({ payload }) {
  try {
    let response = yield call(getPagingStakeHolderActionRequest, payload);
    yield put(getPagingStakeHolderActionSuccess(response.data));
  } catch (error) {
    yield put(getPagingStakeHolderActionError(error.response.statusText));
  }
}

function* getStakeHolderActionById({ payload }) {
  try {
    let response = yield call(getStakeHolderActionByIdRequest, payload);
    yield put(getStakeHolderActionByIdSuccess(response.data));
  } catch (error) {
    yield put(getStakeHolderActionByIdError(error.response.statusText));
  }
}

function* changeStatusStakeHolderAction({ payload }) {
  try {
    const { data, stakeHolderType, stakeHolderId } = payload;
    let response = yield call(changeStatusStakeHolderActionRequest, data);

    response = yield call(getPagingStakeHolderActionRequest, { stakeHolderType, stakeHolderId });
    yield put(getPagingStakeHolderActionSuccess(response.data));
  } catch (error) {
    yield put(getPagingStakeHolderActionError(error.response.statusText));
  }
}

function* deleteStakeHolderAction({ payload }) {
  try {
    const { data, stakeHolderType, stakeHolderId } = payload;
    let response = yield call(deleteStakeHolderActionRequest, data);

    response = yield call(getPagingStakeHolderActionRequest, { stakeHolderType, stakeHolderId });
    yield put(getPagingStakeHolderActionSuccess(response.data));
  } catch (error) {
    yield put(getPagingStakeHolderActionError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(STAKE_HOLDER_ACTION_ADD_NEW, addNewStakeHolderAction);
  yield takeEvery(STAKE_HOLDER_ACTION_UPDATE, updateStakeHolderAction);
  yield takeEvery(STAKE_HOLDER_ACTION_GET_PAGING, getPagingStakeHolderAction);
  yield takeEvery(STAKE_HOLDER_ACTION_GET_BY_ID, getStakeHolderActionById);
  yield takeEvery(STAKE_HOLDER_ACTION_CHANGE_STATUS, changeStatusStakeHolderAction);
  yield takeEvery(STAKE_HOLDER_ACTION_DELETE, deleteStakeHolderAction);
}
