import {
  MILEAGE_ENTRY_ADD_NEW,
  MILEAGE_ENTRY_ADD_NEW_SUCCESS,
  MILEAGE_ENTRY_ADD_NEW_ERROR,
  MILEAGE_ENTRY_DELETE,
  MILEAGE_ENTRY_DELETE_ERROR,
  MILEAGE_ENTRY_DELETE_SUCCESS,
  MILEAGE_ENTRY_EDIT,
  MILEAGE_ENTRY_EDIT_ERROR,
  MILEAGE_ENTRY_EDIT_SUCCESS,
  MILEAGE_ENTRY_GET_BY_ID,
  MILEAGE_ENTRY_GET_BY_ID_ERROR,
  MILEAGE_ENTRY_GET_BY_ID_SUCCESS,
  MILEAGE_ENTRY_GET_LIST_BY_MILEAGE_ID,
  MILEAGE_ENTRY_GET_LIST_BY_MILEAGE_ID_ERROR,
  MILEAGE_ENTRY_GET_LIST_BY_MILEAGE_ID_SUCCESS,
  MILEAGE_ENTRY_ADD_NEW_REQUEST,
} from '../actions';

export const getMileageEntryList = mileageId => ({
  type: MILEAGE_ENTRY_GET_LIST_BY_MILEAGE_ID,
  payload: mileageId,
});

export const getMileageEntryListSuccess = entries => ({
  type: MILEAGE_ENTRY_GET_LIST_BY_MILEAGE_ID_SUCCESS,
  payload: entries,
});

export const getMileageEntryListError = error => ({
  type: MILEAGE_ENTRY_GET_LIST_BY_MILEAGE_ID_ERROR,
  payload: error,
});

export const addNewMileageEntry = entry => ({
  type: MILEAGE_ENTRY_ADD_NEW,
  payload: entry,
});

export const addNewMileageEntryRequest = () => ({
  type: MILEAGE_ENTRY_ADD_NEW_REQUEST,
});

export const addNewMileageEntrySuccess = mileageEntry => ({
  type: MILEAGE_ENTRY_ADD_NEW_SUCCESS,
  payload: mileageEntry,
});

export const addNewMileageEntryError = error => ({
  type: MILEAGE_ENTRY_ADD_NEW_ERROR,
  payload: error,
});

export const editMileageEntry = entry => ({
  type: MILEAGE_ENTRY_EDIT,
  payload: entry,
});

export const editMileageEntrySuccess = entry => ({
  type: MILEAGE_ENTRY_EDIT_SUCCESS,
  payload: entry,
});

export const editMileageEntryError = error => ({
  type: MILEAGE_ENTRY_EDIT_ERROR,
  payload: error,
});

export const deleteMileageEntry = id => ({
  type: MILEAGE_ENTRY_DELETE,
  payload: id,
});

export const deleteMileageEntrySuccess = id => ({
  type: MILEAGE_ENTRY_DELETE_SUCCESS,
  payload: id,
});

export const deleteMileageEntryError = error => ({
  type: MILEAGE_ENTRY_DELETE_ERROR,
  payload: error,
});

export const getMileageEntryById = id => ({
  type: MILEAGE_ENTRY_GET_BY_ID,
  payload: id,
});

export const getMileageEntryByIdSuccess = entry => ({
  type: MILEAGE_ENTRY_GET_BY_ID_SUCCESS,
  payload: entry,
});

export const getMileageEntryByIdError = error => ({
  type: MILEAGE_ENTRY_GET_BY_ID_ERROR,
  payload: error,
});
