import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { call, put, takeEvery } from 'redux-saga/effects';
import { GET_IES_INTERVENTION } from './types';
import Config from '../../config';

import { getIESInterventionSuccess, getIESInterventionError } from './actions';

const getDashboardReportOptionsRequest = async () => {
  return axios.get(`${Config.apiServerHost}/api/Intervention/dashboardReport`, {
    headers: { ...(await authHeader()).headers },
  });
};

const updateDashboardReportOptionsRequest = async data => {
  return axios.post(`${Config.apiServerHost}/api/Intervention/dashboardReport`, data, {
    headers: { ...(await authHeader()).headers },
  });
};

const getIESInterventionRequest = async payload => {
  return axios.post(`${Config.apiServerHost}/api/Intervention`, payload, {
    headers: { ...(await authHeader()).headers },
  });
};

function* getIESIntervention({ payload }) {
  try {
    const response = yield call(getIESInterventionRequest, payload);
    yield put(getIESInterventionSuccess(response.data));
  } catch (error) {
    yield put(getIESInterventionError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_IES_INTERVENTION, getIESIntervention);
}

export { getDashboardReportOptionsRequest, updateDashboardReportOptionsRequest };
