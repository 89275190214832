import {
  CAMS_IMPORT_ADD_NEW,
  CAMS_IMPORT_ADD_NEW_SUCCESS,
  CAMS_IMPORT_ADD_NEW_ERROR,
  CAMS_IMPORT_DELETE,
  CAMS_IMPORT_DELETE_ERROR,
  CAMS_IMPORT_DELETE_SUCCESS,
  CAMS_IMPORT_EDIT,
  CAMS_IMPORT_EDIT_ERROR,
  CAMS_IMPORT_EDIT_SUCCESS,
  CAMS_IMPORT_GET_BY_ID,
  CAMS_IMPORT_GET_BY_ID_ERROR,
  CAMS_IMPORT_GET_BY_ID_SUCCESS,
  CAMS_IMPORT_GET_LIST,
  CAMS_IMPORT_GET_LIST_ERROR,
  CAMS_IMPORT_GET_LIST_SUCCESS,
  CAMS_IMPORT_ADD_NEW_REQUEST,
  CAMS_IMPORT_READ_FILENAME,
  CAMS_IMPORT_READ_FILENAME_ERROR,
  CAMS_IMPORT_READ_FILENAME_SUCCESS,
  CAMS_IMPORT_UPDATE_CLIENT,
  CAMS_IMPORT_UPDATE_DFECLT,
  CAMS_IMPORT_IMPORT_ALL_CLIENTS,
  CAMS_IMPORT_IMPORT_ALL_CLIENTS_SUCCESS,
  CAMS_IMPORT_IMPORT_ALL_CLIENTS_ERROR,
  CAMS_IMPORT_CLOSE_NOTIFICATION,
  CAMS_IMPORT_FILTER_CLIENT_DATA,
  CAMS_IMPORT_IMPORT_DFECLT,
  CAMS_IMPORT_IMPORT_DFECLT_ERROR,
  CAMS_IMPORT_IMPORT_DFECLT_SUCCESS,
  CAMS_IMPORT_FILTER_DFECLT_DATA,
  CAMS_IMPORT_IMPORT_DFESPN,
  CAMS_IMPORT_IMPORT_DFESPN_SUCCESS,
  CAMS_IMPORT_IMPORT_DFESPN_ERROR,
  CAMS_IMPORT_UPDATE_DFESPN,
  CAMS_IMPORT_IMPORT_DFFOL,
  CAMS_IMPORT_IMPORT_DFFOL_SUCCESS,
  CAMS_IMPORT_IMPORT_DFFOL_ERROR,
  CAMS_IMPORT_UPDATE_DFFOL,
  CAMS_IMPORT_IMPORT_ESDFRIP,
  CAMS_IMPORT_IMPORT_ESDFRIP_SUCCESS,
  CAMS_IMPORT_IMPORT_ESDFRIP_ERROR,
  CAMS_IMPORT_UPDATE_ESDFRIP,
  CAMS_IMPORT_IMPORT_ESDFRIC,
  CAMS_IMPORT_IMPORT_ESDFRIC_SUCCESS,
  CAMS_IMPORT_IMPORT_ESDFRIC_ERROR,
  CAMS_IMPORT_UPDATE_ESDFRIC,
  CAMS_SYNC_DATA_COJG_DFTCLT_CLIENTS,
  CAMS_SYNC_DATA_COJG_DFTCLT_CLIENTS_SUCCESS,
  CAMS_SYNC_DATA_COJG_DFTCLT_CLIENTS_ERROR,
  CAMS_SYNC_DATA_COJG_DFTSPN,
  CAMS_SYNC_DATA_COJG_DFTSPN_SUCCESS,
  CAMS_SYNC_DATA_COJG_DFTSPN_ERROR,
  CAMS_SYNC_DATA_ES_DFSPI,
  CAMS_SYNC_DATA_ES_DFSPI_SUCCESS,
  CAMS_SYNC_DATA_ES_DFSPI_ERROR,
  CAMS_IMPORT_UPDATE_ESDFSPI,
  CAMS_SYNC_DATA_ES_DFSPN,
  CAMS_SYNC_DATA_ES_DFSPN_SUCCESS,
  CAMS_SYNC_DATA_ES_DFSPN_ERROR,
  CAMS_SYNC_DATA_ES_DFRIS,
  CAMS_SYNC_DATA_ES_DFRIS_SUCCESS,
  CAMS_SYNC_DATA_ES_DFRIS_ERROR,
  CAMS_IMPORT_DETAIL,
  CAMS_IMPORT_DETAIL_SUCCESS,
  CAMS_IMPORT_DETAIL_ERROR,
  CAMS_IMPORT_GET_CLIENT_LIST_BY_PARAMS,
  CAMS_IMPORT_GET_CLIENT_LIST_BY_PARAMS_SUCCESS,
  CAMS_IMPORT_GET_CLIENT_LIST_BY_PARAMS_ERROR,
  CAMS_IMPORT_GET_EMPLOYER_LIST_BY_PARAMS,
  CAMS_IMPORT_GET_EMPLOYER_LIST_BY_PARAMS_SUCCESS,
  CAMS_IMPORT_GET_EMPLOYER_LIST_BY_PARAMS_ERROR,
} from '../actions';

const initialState = {
  fileName: '',
  camsImports: [],
  clients: [],
  clientsFilter: [],
  foundClients: [],
  foundEmployers: [],
  employers: [],
  employersFilter: [],
  dataFilter: null,
  data: null,
  camsImport: null,
  loading: false,
  error: null,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
  modalConfirm: false,
  existClient: false,
  successedImport: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CAMS_IMPORT_GET_EMPLOYER_LIST_BY_PARAMS_SUCCESS:
      return {
        ...state,
        loading: true,
        foundEmployers: payload,
      };
    case CAMS_IMPORT_GET_CLIENT_LIST_BY_PARAMS_SUCCESS:
      return {
        ...state,
        loading: true,
        foundClients: payload,
      };

    case CAMS_IMPORT_FILTER_DFECLT_DATA:
      let tempData;
      if (payload === 'true') {
        tempData = state.employersFilter.filter(a => a.exist === true);
      } else if (payload === 'false') {
        tempData = state.employersFilter.filter(a => a.exist === false);
      } else {
        tempData = state.employersFilter;
      }

      return {
        ...state,
        loading: true,
        employers: tempData,
      };

    case CAMS_IMPORT_FILTER_CLIENT_DATA:
      if (payload === 'true') {
        tempData = state.clientsFilter.filter(a => a.exist === true);
      } else if (payload === 'false') {
        tempData = state.clientsFilter.filter(a => a.exist === false);
      } else {
        tempData = state.clientsFilter;
      }

      return {
        ...state,
        loading: true,
        clients: tempData,
      };

    case CAMS_IMPORT_UPDATE_DFECLT:
      return {
        ...state,
        loading: true,
        employers: state.employers.map(c => {
          return c.employerId === payload.employerId ? payload : c;
        }),
      };

    case CAMS_IMPORT_UPDATE_DFESPN:
      return {
        ...state,
        loading: true,
        data: state.data.map(c => {
          return c.servicePlanId === payload.servicePlanId ? payload : c;
        }),
      };

    case CAMS_IMPORT_UPDATE_DFFOL:
    case CAMS_IMPORT_UPDATE_ESDFRIP:
    case CAMS_IMPORT_UPDATE_ESDFRIC:
    case CAMS_IMPORT_UPDATE_ESDFSPI:
      return {
        ...state,
        loading: true,
        data: state.data.map(c => {
          return c.id === payload.id ? payload : c;
        }),
      };

    case CAMS_IMPORT_UPDATE_CLIENT:
      return {
        ...state,
        loading: true,
        clients: state.clients.map(c => {
          return c.clientId === payload.clientId ? payload : c;
        }),
      };

    case CAMS_IMPORT_GET_BY_ID:
    case CAMS_IMPORT_DETAIL:
      return {
        ...state,
        loading: false,
        error: null,
        editSuccess: false,
        successedImport: false,
      };

    case CAMS_IMPORT_GET_EMPLOYER_LIST_BY_PARAMS:
    case CAMS_IMPORT_GET_CLIENT_LIST_BY_PARAMS:
    case CAMS_IMPORT_IMPORT_DFESPN:
    case CAMS_IMPORT_IMPORT_DFFOL:
    case CAMS_IMPORT_IMPORT_ESDFRIP:
    case CAMS_IMPORT_IMPORT_ESDFRIC:
    case CAMS_IMPORT_IMPORT_DFECLT:
    case CAMS_SYNC_DATA_COJG_DFTCLT_CLIENTS:
    case CAMS_SYNC_DATA_COJG_DFTSPN:
    case CAMS_SYNC_DATA_ES_DFSPI:
    case CAMS_SYNC_DATA_ES_DFSPN:
    case CAMS_SYNC_DATA_ES_DFRIS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CAMS_IMPORT_IMPORT_DFESPN_SUCCESS:
    case CAMS_IMPORT_IMPORT_DFFOL_SUCCESS:
    case CAMS_IMPORT_IMPORT_ESDFRIP_SUCCESS:
    case CAMS_IMPORT_IMPORT_ESDFRIC_SUCCESS:
    case CAMS_IMPORT_IMPORT_DFECLT_SUCCESS:
    case CAMS_SYNC_DATA_COJG_DFTCLT_CLIENTS_SUCCESS:
    case CAMS_SYNC_DATA_COJG_DFTSPN_SUCCESS:
    case CAMS_SYNC_DATA_ES_DFSPI_SUCCESS:
    case CAMS_SYNC_DATA_ES_DFSPN_SUCCESS:
    case CAMS_SYNC_DATA_ES_DFRIS_SUCCESS:
      return {
        ...state,
        loading: true,
        successedImport: payload,
      };

    case CAMS_IMPORT_IMPORT_ALL_CLIENTS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CAMS_IMPORT_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: true,
        camsImport: payload,
      };

    case CAMS_IMPORT_IMPORT_ALL_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: true,
        successedImport: payload,
      };

    case CAMS_IMPORT_CLOSE_NOTIFICATION:
      return {
        ...state,
        loading: true,
        successedImport: false,
      };

    case CAMS_IMPORT_ADD_NEW_REQUEST:
      return {
        ...state,
        addSuccess: false,
      };

    case CAMS_IMPORT_READ_FILENAME:
      return {
        ...state,
        error: null,
        loading: false,
        fileName: payload.originalFileName,
      };
    case CAMS_IMPORT_GET_LIST:
      return {
        ...state,
        error: null,
        loading: false,
      };

    case CAMS_IMPORT_GET_LIST_SUCCESS:
      return {
        ...state,
        camsImports: payload,
        loading: true,
      };

    case CAMS_IMPORT_READ_FILENAME_SUCCESS:
      return {
        ...state,
        data: payload,
        dataFilter: payload,
        loading: true,
      };
    case CAMS_IMPORT_ADD_NEW:
      return {
        ...state,
        loading: false,
        error: null,
        addSuccess: false,
      };

    case CAMS_IMPORT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: true,
        camsImport: payload.camsImport,
        clients: payload.clients,
        clientsFilter: payload.clients,
        employers: payload.employers,
        employersFilter: payload.employers,
      };
    case CAMS_IMPORT_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: true,
        camsImport: payload,
        camsImports: state.camsImports.concat(payload),
        addSuccess: true,
      };

    case CAMS_IMPORT_EDIT:
      return {
        ...state,
        loading: false,
        error: null,
        editSuccess: false,
      };
    case CAMS_IMPORT_EDIT_SUCCESS:
      return {
        ...state,
        camsImports: state.camsImports.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        camsImport: payload,
        error: null,
        editSuccess: true,
      };
    case CAMS_IMPORT_DELETE:
      return {
        ...state,
        loading: false,
        error: null,
        deleteSuccess: false,
      };
    case CAMS_IMPORT_DELETE_SUCCESS:
      return {
        ...state,
        loading: true,
        camsImports: state.camsImports.filter(c => c.id !== payload),
        deleteSuccess: true,
      };

    case CAMS_IMPORT_GET_EMPLOYER_LIST_BY_PARAMS_ERROR:
    case CAMS_IMPORT_GET_CLIENT_LIST_BY_PARAMS_ERROR:
    case CAMS_IMPORT_IMPORT_ALL_CLIENTS_ERROR:
    case CAMS_IMPORT_READ_FILENAME_ERROR:
    case CAMS_IMPORT_DELETE_ERROR:
    case CAMS_IMPORT_ADD_NEW_ERROR:
    case CAMS_IMPORT_EDIT_ERROR:
    case CAMS_IMPORT_GET_BY_ID_ERROR:
    case CAMS_IMPORT_GET_LIST_ERROR:
    case CAMS_IMPORT_IMPORT_DFECLT_ERROR:
    case CAMS_IMPORT_IMPORT_DFESPN_ERROR:
    case CAMS_IMPORT_IMPORT_DFFOL_ERROR:
    case CAMS_IMPORT_IMPORT_ESDFRIP_ERROR:
    case CAMS_IMPORT_IMPORT_ESDFRIC_ERROR:
    case CAMS_SYNC_DATA_COJG_DFTCLT_CLIENTS_ERROR:
    case CAMS_SYNC_DATA_COJG_DFTSPN_ERROR:
    case CAMS_SYNC_DATA_ES_DFSPI_ERROR:
    case CAMS_SYNC_DATA_ES_DFSPN_ERROR:
    case CAMS_SYNC_DATA_ES_DFRIS_ERROR:
    case CAMS_IMPORT_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        successedImport: false,
      };

    default:
      return { ...state };
  }
};
