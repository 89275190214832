import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { call, put, takeEvery } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import Config from '../../config';
import {
  CP_REFERRER_ADD_NEW_REQUEST,
  GET_CP_REFERRER_LIST,
  GET_CP_REFERRER_BY_ID,
  GET_SP_LIST,
  CP_REFERRER_EDIT,
} from './types';
import {
  getCpReferrerListError,
  getCpReferrerListSuccess,
  getCpReferrerByIdError,
  getCpReferrerByIdSuccess,
  addNewCpReferrerSuccess,
  addNewCpReferrerError,
  getSpListSuccess,
  getSpListError,
} from './actions';

const getCpReferrerByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/CpReferrer/paging`;
  return await axios.post(url, query, {
    headers: { ...(await authHeader()).headers },
  });
};

const verifyCpReferralRequest = async query => {
  let url = `${Config.apiServerHost}/api/CPReferrer/verify`;
  return await axios.get(url, {
    params: query,
    headers: { ...(await authHeader()).headers },
  });
};

const notEligibleSubmitRequest = async data => {
  let url = `${Config.apiServerHost}/api/CPReferrer/notEligible`;
  return await axios.post(url, data, {
    headers: { ...(await authHeader()).headers },
  });
};

const addCpReferrerRequest = async data => {
  let url = `${Config.apiServerHost}/api/CPReferrer`;
  return await axios.post(url, data, {
    headers: { ...(await authHeader()).headers },
  });
};

const updateCpReferrerRequest = async data => {
  let url = `${Config.apiServerHost}/api/CPReferrer`;
  return await axios.put(url, data, {
    headers: { ...(await authHeader()).headers },
  });
};

function* addNewCpReferrer({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(addCpReferrerRequest, data);
    yield put(addNewCpReferrerSuccess(response.data));

    response = yield call(getCpReferrerByQueryRequest, queryParams);
    yield put(getCpReferrerListSuccess(response.data));
  } catch (error) {
    yield put(addNewCpReferrerError(error.response.statusText));
  }
}

function* updateCpReferrer({ payload }) {
  try {
    const { data, queryParams } = payload;
    yield call(updateCpReferrerRequest, data);

    let response = yield call(getCpReferrerByQueryRequest, queryParams);
    yield put(getCpReferrerListSuccess(response.data));
  } catch (error) {
    yield put(addNewCpReferrerError(error.response.statusText));
  }
}

const getSpListRequest = async query => {
  let url = `${Config.apiServerHost}/api/cpreferrer/serviceProviderList`;
  return await axios.post(url, query, {
    headers: { ...(await authHeader()).headers },
  });
};

const getCpReferrerByIdRequest = async payload => {
  const { id } = payload;
  return axios.get(`${Config.apiServerHost}/api/cpreferrer/${id}`, await authHeader());
};

const viewSpReferrerByIdRequest = async payload => {
  const { id } = payload;
  return axios.get(`${Config.apiServerHost}/api/cpreferrer/spView/${id}`, await authHeader());
};

const getCountUnsendRequest = async () => {
  return axios.get(`${Config.apiServerHost}/api/cpreferrer/unsend`, await authHeader());
};

function* getCpReferrerById({ payload }) {
  try {
    let response = yield call(getCpReferrerByIdRequest, payload);
    yield put(getCpReferrerByIdSuccess(response.data));
  } catch (error) {
    yield put(getCpReferrerByIdError(error.response.statusText));
  }
}

function* getCpReferrerList({ payload }) {
  try {
    let response = yield call(getCpReferrerByQueryRequest, payload);
    yield put(getCpReferrerListSuccess(response.data));
  } catch (error) {
    yield put(getCpReferrerListError(error.response.statusText));
  }
}

function* getSpList({ payload }) {
  try {
    let response = yield call(getSpListRequest, payload);
    yield put(getSpListSuccess(response.data));
  } catch (error) {
    yield put(getSpListError(error.response.statusText));
  }
}

const exportCPRXlsRequest = async (query, fileName) => {
  return axios
    .post(`${Config.apiServerHost}/api/cpreferrer/exportToXls`, query, {
      headers: { ...(await authHeader()).headers },
      responseType: 'blob',
    })
    .then(res => {
      if (res) {
        const { data } = res;
        saveAs(new Blob([data]), fileName);
      }
    });
};

const exportServiceProviderXlsRequest = async (query, fileName) => {
  return axios
    .post(`${Config.apiServerHost}/api/cpreferrer/exportSpToXls`, query, {
      headers: { ...(await authHeader()).headers },
      responseType: 'blob',
    })
    .then(res => {
      if (res) {
        const { data } = res;
        saveAs(new Blob([data]), fileName);
      }
    });
};

export default function* rootSaga() {
  yield takeEvery(CP_REFERRER_ADD_NEW_REQUEST, addNewCpReferrer);
  yield takeEvery(CP_REFERRER_EDIT, updateCpReferrer);
  yield takeEvery(GET_CP_REFERRER_LIST, getCpReferrerList);
  yield takeEvery(GET_SP_LIST, getSpList);
  yield takeEvery(GET_CP_REFERRER_BY_ID, getCpReferrerById);
}

export {
  addCpReferrerRequest,
  exportCPRXlsRequest,
  getCpReferrerByQueryRequest,
  getCountUnsendRequest,
  getSpListRequest,
  verifyCpReferralRequest,
  exportServiceProviderXlsRequest,
  notEligibleSubmitRequest,
  viewSpReferrerByIdRequest,
};
