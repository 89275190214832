// EMPLOYER_CASE_NOTE

export const EMPLOYER_CASE_NOTE_GET_PAGING = 'EMPLOYER_CASE_NOTE_GET_PAGING';
export const EMPLOYER_CASE_NOTE_GET_PAGING_SUCCESS = 'EMPLOYER_CASE_NOTE_GET_PAGING_SUCCESS';
export const EMPLOYER_CASE_NOTE_GET_PAGING_ERROR = 'EMPLOYER_CASE_NOTE_GET_PAGING_ERROR';

export const EMPLOYER_CASE_NOTE_ADD_NEW = 'EMPLOYER_CASE_NOTE_ADD_NEW';
export const EMPLOYER_CASE_NOTE_ADD_NEW_SUCCESS = 'EMPLOYER_CASE_NOTE_ADD_NEW_SUCCESS';
export const EMPLOYER_CASE_NOTE_ADD_NEW_ERROR = 'EMPLOYER_CASE_NOTE_ADD_NEW_ERROR';
export const EMPLOYER_CASE_NOTE_ADD_NEW_REQUEST = 'EMPLOYER_CASE_NOTE_ADD_NEW_REQUEST';

export const EMPLOYER_CASE_NOTE_GET_BY_ID = 'EMPLOYER_CASE_NOTE_GET_BY_ID';
export const EMPLOYER_CASE_NOTE_GET_BY_ID_SUCCESS = 'EMPLOYER_CASE_NOTE_GET_BY_ID_SUCCESS';
export const EMPLOYER_CASE_NOTE_GET_BY_ID_ERROR = 'EMPLOYER_CASE_NOTE_GET_BY_ID_ERROR';

export const EMPLOYER_CASE_NOTE_EDIT = 'EMPLOYER_CASE_NOTE_EDIT';
export const EMPLOYER_CASE_NOTE_EDIT_SUCCESS = 'EMPLOYER_CASE_NOTE_EDIT_SUCCESS';
export const EMPLOYER_CASE_NOTE_EDIT_ERROR = 'EMPLOYER_CASE_NOTE_EDIT_ERROR';

export const EMPLOYER_CASE_NOTE_DELETE = 'EMPLOYER_CASE_NOTE_DELETE';
export const EMPLOYER_CASE_NOTE_DELETE_SUCCESS = 'EMPLOYER_CASE_NOTE_DELETE_SUCCESS';
export const EMPLOYER_CASE_NOTE_DELETE_ERROR = 'EMPLOYER_CASE_NOTE_DELETE_ERROR';
