import {
  EXPENSE_ADD_NEW,
  EXPENSE_ADD_NEW_SUCCESS,
  EXPENSE_ADD_NEW_ERROR,
  EXPENSE_DELETE,
  EXPENSE_DELETE_ERROR,
  EXPENSE_DELETE_SUCCESS,
  EXPENSE_EDIT,
  EXPENSE_EDIT_ERROR,
  EXPENSE_EDIT_SUCCESS,
  EXPENSE_GET_BY_ID,
  EXPENSE_GET_BY_ID_ERROR,
  EXPENSE_GET_BY_ID_SUCCESS,
  EXPENSE_GET_LIST,
  EXPENSE_GET_LIST_SUCCESS,
  EXPENSE_GET_LIST_ERROR,
  EXPENSE_GET_LIST_BY_USER_ID,
  EXPENSE_GET_LIST_BY_USER_ID_SUCCESS,
  EXPENSE_GET_LIST_BY_USER_ID_ERROR,
  EXPENSE_GET_LIST_BY_STATUS,
  EXPENSE_GET_LIST_BY_STATUS_SUCCESS,
  EXPENSE_GET_LIST_BY_STATUS_ERROR,
  EXPENSE_SUBMIT,
  EXPENSE_SUBMIT_SUCCESS,
  EXPENSE_SUBMIT_ERROR,
  EXPENSE_APPROVE_SUBMIT,
  EXPENSE_APPROVE_SUBMIT_SUCCESS,
  EXPENSE_APPROVE_SUBMIT_ERROR,
  EXPENSE_ADD_NEW_REQUEST,
  EXPENSE_GET_BY_QUERY,
  EXPENSE_GET_BY_QUERY_SUCCESS,
  EXPENSE_GET_BY_QUERY_ERROR,
} from '../actions';

export const getExpenseByQuery = query => ({
  type: EXPENSE_GET_BY_QUERY,
  payload: query,
});

export const getExpenseByQuerySuccess = expenses => ({
  type: EXPENSE_GET_BY_QUERY_SUCCESS,
  payload: expenses,
});

export const getExpenseByQueryError = error => ({
  type: EXPENSE_GET_BY_QUERY_ERROR,
  payload: error,
});

export const getExpenseList = userId => ({
  type: EXPENSE_GET_LIST,
  payload: userId,
});

export const getExpenseListSuccess = expenses => ({
  type: EXPENSE_GET_LIST_SUCCESS,
  payload: expenses,
});

export const getExpenseListError = error => ({
  type: EXPENSE_GET_LIST_ERROR,
  payload: error,
});

export const getExpenseListByUserId = userId => ({
  type: EXPENSE_GET_LIST_BY_USER_ID,
  payload: userId,
});

export const getExpenseListByUserIdSuccess = expenses => ({
  type: EXPENSE_GET_LIST_BY_USER_ID_SUCCESS,
  payload: expenses,
});

export const getExpenseListByUserIdError = error => ({
  type: EXPENSE_GET_LIST_BY_USER_ID_ERROR,
  payload: error,
});

export const getExpenseListByStatus = param => ({
  type: EXPENSE_GET_LIST_BY_STATUS,
  payload: param,
});

export const getExpenseListByStatusSuccess = expenses => ({
  type: EXPENSE_GET_LIST_BY_STATUS_SUCCESS,
  payload: expenses,
});

export const getExpenseListByStatusError = error => ({
  type: EXPENSE_GET_LIST_BY_STATUS_ERROR,
  payload: error,
});

export const addNewExpense = (expense, history) => ({
  type: EXPENSE_ADD_NEW,
  payload: { expense, history },
});

export const addNewExpenseRequest = () => ({
  type: EXPENSE_ADD_NEW_REQUEST,
});

export const addNewExpenseSuccess = expense => ({
  type: EXPENSE_ADD_NEW_SUCCESS,
  payload: expense,
});

export const addNewExpenseError = error => ({
  type: EXPENSE_ADD_NEW_ERROR,
  payload: error,
});

export const editExpense = (expense, history) => ({
  type: EXPENSE_EDIT,
  payload: { expense, history },
});

export const editExpenseSuccess = expense => ({
  type: EXPENSE_EDIT_SUCCESS,
  payload: expense,
});

export const editExpenseError = error => ({
  type: EXPENSE_EDIT_ERROR,
  payload: error,
});

export const deleteExpense = id => ({
  type: EXPENSE_DELETE,
  payload: id,
});

export const deleteExpenseSuccess = id => ({
  type: EXPENSE_DELETE_SUCCESS,
  payload: id,
});

export const deleteExpenseError = error => ({
  type: EXPENSE_DELETE_ERROR,
  payload: error,
});

export const getExpenseById = id => ({
  type: EXPENSE_GET_BY_ID,
  payload: id,
});

export const getExpenseByIdSuccess = expense => ({
  type: EXPENSE_GET_BY_ID_SUCCESS,
  payload: expense,
});

export const getExpenseByIdError = error => ({
  type: EXPENSE_GET_BY_ID_ERROR,
  payload: error,
});

export const submitExpense = data => ({
  type: EXPENSE_SUBMIT,
  payload: data,
});

export const submitExpenseSuccess = expense => ({
  type: EXPENSE_SUBMIT_SUCCESS,
  payload: expense,
});

export const submitExpenseError = error => ({
  type: EXPENSE_SUBMIT_ERROR,
  payload: error,
});

export const submitApproveExpense = data => ({
  type: EXPENSE_APPROVE_SUBMIT,
  payload: data,
});

export const submitApproveExpenseSuccess = expense => ({
  type: EXPENSE_APPROVE_SUBMIT_SUCCESS,
  payload: expense,
});

export const submitApproveExpenseError = error => ({
  type: EXPENSE_APPROVE_SUBMIT_ERROR,
  payload: error,
});
