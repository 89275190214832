import {
  IES_CONTRACT_GET_LIST,
  IES_CONTRACT_GET_LIST_SUCCESS,
  IES_CONTRACT_GET_LIST_ERROR,
  IES_CONTRACT_GET_BY_ID,
  IES_CONTRACT_GET_BY_ID_SUCCESS,
  IES_CONTRACT_GET_BY_ID_ERROR,
  IES_CONTRACT_ADD_ITEM,
  IES_CONTRACT_ADD_ITEM_SUCCESS,
  IES_CONTRACT_ADD_ITEM_ERROR,
  IES_CONTRACT_EDIT_ITEM,
  IES_CONTRACT_EDIT_ITEM_SUCCESS,
  IES_CONTRACT_EDIT_ITEM_ERROR,
  IES_CONTRACT_DELETE_ITEM,
  IES_CONTRACT_DELETE_ITEM_SUCCESS,
  IES_CONTRACT_DELETE_ITEM_ERROR,
} from '../actions';

// IESContract

export const getIESContractList = filter => ({
  type: IES_CONTRACT_GET_LIST,
  payload: filter,
});

export const getIESContractListSuccess = items => ({
  type: IES_CONTRACT_GET_LIST_SUCCESS,
  payload: items,
});

export const getIESContractListError = error => ({
  type: IES_CONTRACT_GET_LIST_ERROR,
  payload: error,
});

export const getIESContractById = id => ({
  type: IES_CONTRACT_GET_BY_ID,
  payload: id,
});

export const getIESContractByIdSuccess = item => ({
  type: IES_CONTRACT_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getIESContractByIdError = error => ({
  type: IES_CONTRACT_GET_BY_ID_ERROR,
  payload: error,
});

export const addIESContractItem = (item, history) => ({
  type: IES_CONTRACT_ADD_ITEM,
  payload: { item, history },
});

export const addIESContractItemSuccess = items => ({
  type: IES_CONTRACT_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addIESContractItemError = error => ({
  type: IES_CONTRACT_ADD_ITEM_ERROR,
  payload: error,
});

export const editIESContractItem = (item, history) => ({
  type: IES_CONTRACT_EDIT_ITEM,
  payload: { item, history },
});

export const editIESContractItemSuccess = items => ({
  type: IES_CONTRACT_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editIESContractItemError = error => ({
  type: IES_CONTRACT_EDIT_ITEM_ERROR,
  payload: error,
});

export const deleteIESContractItem = item => ({
  type: IES_CONTRACT_DELETE_ITEM,
  payload: item,
});

export const deleteIESContractItemSuccess = items => ({
  type: IES_CONTRACT_DELETE_ITEM_SUCCESS,
  payload: items,
});

export const deleteIESContractItemError = error => ({
  type: IES_CONTRACT_DELETE_ITEM_ERROR,
  payload: error,
});
