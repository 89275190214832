import {
  SYSTEM_SETTING_GET_BY_ID,
  SYSTEM_SETTING_GET_BY_ID_SUCCESS,
  SYSTEM_SETTING_GET_BY_ID_ERROR,
  SYSTEM_SETTING_SAVE,
  SYSTEM_SETTING_SAVE_SUCCESS,
  SYSTEM_SETTING_SAVE_ERROR,
} from '../actions';

export const getSettingById = id => ({
  type: SYSTEM_SETTING_GET_BY_ID,
  payload: id,
});

export const getSettingByIdSuccess = setting => ({
  type: SYSTEM_SETTING_GET_BY_ID_SUCCESS,
  payload: setting,
});

export const getSettingByIdError = error => ({
  type: SYSTEM_SETTING_GET_BY_ID_ERROR,
  payload: error,
});

export const saveSetting = setting => ({
  type: SYSTEM_SETTING_SAVE,
  payload: setting,
});

export const saveSettingSuccess = setting => ({
  type: SYSTEM_SETTING_SAVE_SUCCESS,
  payload: setting,
});

export const saveSettingError = error => ({
  type: SYSTEM_SETTING_SAVE_ERROR,
  payload: error,
});
