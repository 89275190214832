// EIDM
export const EIDM_GET_BY_ID = 'EIDM_GET_BY_ID';
export const EIDM_GET_BY_ID_SUCCESS = 'EIDM_GET_BY_ID_SUCCESS';
export const EIDM_GET_BY_ID_ERROR = 'EIDM_GET_BY_ID_ERROR';

export const EIDM_GET_LIST = 'EIDM_GET_LIST';
export const EIDM_GET_LIST_SUCCESS = 'EIDM_GET_LIST_SUCCESS';
export const EIDM_GET_LIST_ERROR = 'EIDM_GET_LIST_ERROR';

export const EIDM_EDIT = 'EIDM_EDIT';
export const EIDM_EDIT_SUCCESS = 'EIDM_EDIT_SUCCESS';
export const EIDM_EDIT_ERROR = 'EIDM_EDIT_ERROR';

export const EIDM_ADD_NEW = 'EIDM_ADD_NEW';
export const EIDM_ADD_NEW_ERROR = 'EIDM_ADD_NEW_ERROR';
export const EIDM_ADD_NEW_REQUEST = 'EIDM_ADD_NEW_REQUEST';

export const EIDM_DELETE = 'EIDM_DELETE';
export const EIDM_DELETE_SUCCESS = 'EIDM_DELETE_SUCCESS';

export const EIDM_GET_BY_QUERY = 'EIDM_GET_BY_QUERY';
export const EIDM_GET_BY_QUERY_SUCCESS = 'EIDM_GET_BY_QUERY_SUCCESS';
export const EIDM_GET_BY_QUERY_ERROR = 'EIDM_GET_BY_QUERY_ERROR';
