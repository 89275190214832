import {
  CAMS_CLIENT_FETCH,
  CAMS_CLIENT_FETCH_SUCCESS,
  CAMS_CLIENT_FETCH_ERROR,
  CAMS_OVERWRITE_UPDATE,
  CAMS_OVERWRITE_UPDATE_SUCCESS,
  CAMS_OVERWRITE_UPDATE_ERROR,
  CAMS_SELECTED_PLANNED_ITEM,
  CAMS_SELECTED_ACTIVE_TAB,
  CAMS_TOGGLE_SUBGOAL,
  CAMS_SELECT_OUTCOME_JOB,
  CAMS_SELECT_CHECKPOINT_JOB,
  CAMS_EMPLOYER_FETCH,
  CAMS_EMPLOYER_FETCH_SUCCESS,
  CAMS_EMPLOYER_FETCH_ERROR,
  CAMS_EMPLOYER_OVERWRITE_UPDATE,
  CAMS_EMPLOYER_OVERWRITE_UPDATE_SUCCESS,
  CAMS_EMPLOYER_OVERWRITE_UPDATE_ERROR,
} from '../actions';

export const getCamsClient = data => ({
  type: CAMS_CLIENT_FETCH,
  payload: data,
});

export const getCamsClientSuccess = data => ({
  type: CAMS_CLIENT_FETCH_SUCCESS,
  payload: data,
});

export const getCamsClientError = error => ({
  type: CAMS_CLIENT_FETCH_ERROR,
  payload: error,
});

export const overwriteCams = data => ({
  type: CAMS_OVERWRITE_UPDATE,
  payload: data,
});

export const overwriteCamsSuccess = data => ({
  type: CAMS_OVERWRITE_UPDATE_SUCCESS,
  payload: data,
});

export const overwriteCamsError = data => ({
  type: CAMS_OVERWRITE_UPDATE_ERROR,
  payload: data,
});

export const selectCamsPlannedItem = data => ({
  type: CAMS_SELECTED_PLANNED_ITEM,
  payload: data,
});

export const selectCamsActiveTab = data => ({
  type: CAMS_SELECTED_ACTIVE_TAB,
  payload: data,
});

export const camsToggleSubGoal = data => ({
  type: CAMS_TOGGLE_SUBGOAL,
  payload: data,
});

export const selectOutcomeJob = data => ({
  type: CAMS_SELECT_OUTCOME_JOB,
  payload: data,
});

export const selectCheckpointJob = data => ({
  type: CAMS_SELECT_CHECKPOINT_JOB,
  payload: data,
});

export const getCamsEmployer = data => ({
  type: CAMS_EMPLOYER_FETCH,
  payload: data,
});

export const getCamsEmployerSuccess = data => ({
  type: CAMS_EMPLOYER_FETCH_SUCCESS,
  payload: data,
});

export const getCamsEmployerError = error => ({
  type: CAMS_EMPLOYER_FETCH_ERROR,
  payload: error,
});

export const employerOverwriteCams = data => ({
  type: CAMS_EMPLOYER_OVERWRITE_UPDATE,
  payload: data,
});

export const employerOverwriteCamsSuccess = data => ({
  type: CAMS_EMPLOYER_OVERWRITE_UPDATE_SUCCESS,
  payload: data,
});

export const employerOverwriteCamsError = data => ({
  type: CAMS_EMPLOYER_OVERWRITE_UPDATE_ERROR,
  payload: data,
});
