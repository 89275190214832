import {
  YJC_CHEQUE_ADD_NEW,
  YJC_CHEQUE_ADD_NEW_SUCCESS,
  YJC_CHEQUE_ADD_NEW_ERROR,
  YJC_CHEQUE_DELETE,
  YJC_CHEQUE_DELETE_ERROR,
  YJC_CHEQUE_DELETE_SUCCESS,
  YJC_CHEQUE_EDIT,
  YJC_CHEQUE_EDIT_ERROR,
  YJC_CHEQUE_EDIT_SUCCESS,
  YJC_CHEQUE_GET_BY_ID,
  YJC_CHEQUE_GET_BY_ID_ERROR,
  YJC_CHEQUE_GET_BY_ID_SUCCESS,
  YJC_CHEQUE_ADD_NEW_REQUEST,
  YJC_CHEQUE_QUERY,
  YJC_CHEQUE_QUERY_SUCCESS,
  YJC_CHEQUE_QUERY_ERROR,
} from '../actions';

// YJCCheque
export const getYJCChequeQuery = query => ({
  type: YJC_CHEQUE_QUERY,
  payload: query,
});

export const getYJCChequeQuerySuccess = caseNotes => ({
  type: YJC_CHEQUE_QUERY_SUCCESS,
  payload: caseNotes,
});

export const getYJCChequeQueryError = error => ({
  type: YJC_CHEQUE_QUERY_ERROR,
  payload: error,
});

export const addNewYJCCheque = caseNote => ({
  type: YJC_CHEQUE_ADD_NEW,
  payload: caseNote,
});

export const addNewYJCChequeRequest = () => ({
  type: YJC_CHEQUE_ADD_NEW_REQUEST,
});

export const addNewYJCChequeSuccess = CaseNote => ({
  type: YJC_CHEQUE_ADD_NEW_SUCCESS,
  payload: CaseNote,
});

export const addNewYJCChequeError = error => ({
  type: YJC_CHEQUE_ADD_NEW_ERROR,
  payload: error,
});

export const editYJCCheque = CaseNote => ({
  type: YJC_CHEQUE_EDIT,
  payload: CaseNote,
});

export const editYJCChequeSuccess = caseNote => ({
  type: YJC_CHEQUE_EDIT_SUCCESS,
  payload: caseNote,
});

export const editYJCChequeError = error => ({
  type: YJC_CHEQUE_EDIT_ERROR,
  payload: error,
});

export const deleteYJCCheque = id => ({
  type: YJC_CHEQUE_DELETE,
  payload: id,
});

export const deleteYJCChequeSuccess = id => ({
  type: YJC_CHEQUE_DELETE_SUCCESS,
  payload: id,
});

export const deleteYJCChequeError = error => ({
  type: YJC_CHEQUE_DELETE_ERROR,
  payload: error,
});

export const getYJCChequeById = id => ({
  type: YJC_CHEQUE_GET_BY_ID,
  payload: id,
});

export const getYJCChequeByIdSuccess = caseNote => ({
  type: YJC_CHEQUE_GET_BY_ID_SUCCESS,
  payload: caseNote,
});

export const getYJCChequeByIdError = error => ({
  type: YJC_CHEQUE_GET_BY_ID_ERROR,
  payload: error,
});
