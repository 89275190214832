import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import axios from 'axios';
import Config from '../../config';

import {
  ROLE_GET_LIST,
  ROLE_ADD_OR_UPDATE_ITEM,
  ROLE_DELETE_ITEM,
  ROLE_GET_BY_ID,
  GET_PERMISSION_LIST,
} from '../actions';

import {
  getRoleListSuccess,
  getRoleListError,
  AddorUpdateRoleItemError,
  deleteRoleItemError,
  getRoleByIdSuccess,
  getRoleByIdError,
  getPermissionListSuccess,
  getPermissionListError,
} from './actions';

const getPermissionListRequest = async () => {
  return await axios.get(`${Config.apiServerHost}/api/account/permission`, await authHeader());
};

const getRolesRequest = async () => {
  return await axios.get(`${Config.apiServerHost}/api/Role`, {
    headers: { ...(await authHeader()).headers },
  });
};

const getRoleListRequest = async payload => {
  let url = `${Config.apiServerHost}/api/Role/fetch`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const getRoleByIdRequest = async id => {
  return await axios.get(`${Config.apiServerHost}/api/account/role/${id}`, await authHeader());
};

const poshRoleListRequest = async role => {
  return await axios.post(`${Config.apiServerHost}/api/account/role`, role, await authHeader());
};

const deleteRoleListRequest = async id => {
  return await axios.delete(`${Config.apiServerHost}/api/Role/${id}`, await authHeader());
};

function* getPermissionList() {
  try {
    const response = yield call(getPermissionListRequest);
    yield put(getPermissionListSuccess(response.data));
  } catch (err) {
    yield put(getPermissionListError(err.response));
  }
}

function* getRoleById({ payload }) {
  try {
    const response = yield call(getRoleByIdRequest, payload);
    yield put(getRoleByIdSuccess(response.data));
  } catch (err) {
    yield put(getRoleByIdError(err.response));
  }
}

function* getRoleListItems({ payload }) {
  try {
    const response = yield call(getRoleListRequest, payload);
    yield put(getRoleListSuccess(response.data));
  } catch (err) {
    yield put(getRoleListError(err.response));
  }
}

function* AddorUpdateRoleItem({ payload }) {
  try {
    const { item, history } = payload;
    yield call(poshRoleListRequest, item);
    const res = yield call(getRoleListRequest);
    yield put(getRoleListSuccess(res.data));
    history.push('/app/role');
  } catch (error) {
    yield put(AddorUpdateRoleItemError(error));
  }
}

function* deleteRoleItem({ payload }) {
  try {
    yield call(deleteRoleListRequest, payload);
    const res = yield call(getRoleListRequest);
    yield put(getRoleListSuccess(res.data));
  } catch (error) {
    const { status, data } = error.response;
    const err = {
      status,
      data,
    };
    yield put(deleteRoleItemError(err));
  }
}

export function* watchGetPermissionList() {
  yield takeEvery(GET_PERMISSION_LIST, getPermissionList);
}

export function* watchGetRoleById() {
  yield takeEvery(ROLE_GET_BY_ID, getRoleById);
}

export function* watchGetList() {
  yield takeEvery(ROLE_GET_LIST, getRoleListItems);
}

export function* wathcADD_OR_UPDATEItem() {
  yield takeEvery(ROLE_ADD_OR_UPDATE_ITEM, AddorUpdateRoleItem);
}
export function* wathcDeleteItem() {
  yield takeEvery(ROLE_DELETE_ITEM, deleteRoleItem);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetPermissionList),
    fork(watchGetList),
    fork(watchGetRoleById),
    fork(wathcADD_OR_UPDATEItem),
    fork(wathcDeleteItem),
  ]);
}

export { getRolesRequest, getRoleByIdRequest, poshRoleListRequest };
