import {
  CP_REFERRER_ADD_NEW_REQUEST,
  GET_CP_REFERRER_LIST,
  GET_CP_REFERRER_LIST_SUCCESS,
  GET_CP_REFERRER_LIST_ERROR,
  GET_CP_REFERRER_BY_ID,
  GET_CP_REFERRER_BY_ID_SUCCESS,
  GET_CP_REFERRER_BY_ID_ERROR,
  CP_REFERRER_ADD_NEW_REQUEST_SUCCESS,
  CP_REFERRER_ADD_NEW_REQUEST_ERROR,
  GET_SP_LIST_ERROR,
  GET_SP_LIST_SUCCESS,
  GET_SP_LIST,
  CP_REFERRER_EDIT,
  CP_REFERRER_EDIT_SUCCESS,
} from './types';

export const addNewCpReferrer = payload => ({
  type: CP_REFERRER_ADD_NEW_REQUEST,
  payload,
});

export const addNewCpReferrerSuccess = payload => ({
  type: CP_REFERRER_ADD_NEW_REQUEST_SUCCESS,
  payload,
});

export const editCpReferrer = payload => ({
  type: CP_REFERRER_EDIT,
  payload,
});

export const editCpReferrerSuccess = payload => ({
  type: CP_REFERRER_EDIT_SUCCESS,
  payload,
});

export const addNewCpReferrerError = payload => ({
  type: CP_REFERRER_ADD_NEW_REQUEST_ERROR,
  payload,
});

export const getCpReferrerList = payload => ({
  type: GET_CP_REFERRER_LIST,
  payload,
});

export const getCpReferrerListSuccess = payload => ({
  type: GET_CP_REFERRER_LIST_SUCCESS,
  payload,
});

export const getCpReferrerListError = payload => ({
  type: GET_CP_REFERRER_LIST_ERROR,
  payload,
});

export const getSpList = payload => ({
  type: GET_SP_LIST,
  payload,
});

export const getSpListSuccess = payload => ({
  type: GET_SP_LIST_SUCCESS,
  payload,
});

export const getSpListError = payload => ({
  type: GET_SP_LIST_ERROR,
  payload,
});

export const getCpReferrerById = id => ({
  type: GET_CP_REFERRER_BY_ID,
  payload: { id },
});

export const getCpReferrerByIdSuccess = payload => ({
  type: GET_CP_REFERRER_BY_ID_SUCCESS,
  payload,
});

export const getCpReferrerByIdError = payload => ({
  type: GET_CP_REFERRER_BY_ID_ERROR,
  payload,
});
