import {
  JOB_MATCHING_ADD_NEW,
  JOB_MATCHING_DELETE,
  JOB_MATCHING_EDIT,
  JOB_MATCHING_GET_BY_ID,
  JOB_MATCHING_GET_BY_ID_SUCCESS,
  JOB_MATCHING_GET_LIST,
  JOB_MATCHING_GET_LIST_SUCCESS,
  JOB_MATCHING_ADD_NEW_REQUEST,
  JOB_MATCHING_RESULT_CHANGE,
  JOB_MATCHING_ERROR,
} from './types';

// item
const initialState = {
  items: [],
  item: null,
  notifications: [],
  loading: false,
  error: null,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
  itemCount: 0,
  pageCount: 0,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case JOB_MATCHING_RESULT_CHANGE:
    case JOB_MATCHING_GET_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case JOB_MATCHING_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
      };

    case JOB_MATCHING_ADD_NEW_REQUEST:
      return {
        ...state,
        addSuccess: false,
      };
    case JOB_MATCHING_GET_LIST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case JOB_MATCHING_GET_LIST_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        items: data,
        loading: false,
        itemCount,
        pageCount,
        addSuccess: true,
        editSuccess: true,
        deleteSuccess: true,
      };
    case JOB_MATCHING_ADD_NEW:
      return {
        ...state,
        loading: true,
        error: null,
        addSuccess: false,
      };

    case JOB_MATCHING_EDIT:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case JOB_MATCHING_DELETE:
      return {
        ...state,
        loading: true,
        error: null,
        deleteSuccess: false,
      };

    case JOB_MATCHING_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
