import {
  REFERRAL_ADD_NEW,
  REFERRAL_ADD_NEW_SUCCESS,
  REFERRAL_ADD_NEW_ERROR,
  REFERRAL_DELETE,
  REFERRAL_DELETE_ERROR,
  REFERRAL_DELETE_SUCCESS,
  REFERRAL_EDIT,
  REFERRAL_EDIT_ERROR,
  REFERRAL_EDIT_SUCCESS,
  REFERRAL_GET_BY_ID,
  REFERRAL_GET_BY_ID_ERROR,
  REFERRAL_GET_BY_ID_SUCCESS,
  REFERRAL_GET_LIST,
  REFERRAL_GET_LIST_ERROR,
  REFERRAL_GET_LIST_SUCCESS,
  REFERRAL_ADD_NEW_REQUEST,
  REFERRAL_GET_LIST_BY_QUERY,
  REFERRAL_GET_LIST_BY_QUERY_SUCCESS,
  REFERRAL_GET_LIST_BY_QUERY_ERROR,
} from '../actions';
// referral
const initialState = {
  referrals: [],
  referral: null,
  loading: false,
  error: null,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REFERRAL_GET_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case REFERRAL_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        referral: payload,
      };

    case REFERRAL_ADD_NEW_REQUEST:
      return {
        ...state,
        addSuccess: false,
      };
    case REFERRAL_GET_LIST_BY_QUERY:
    case REFERRAL_GET_LIST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case REFERRAL_GET_LIST_BY_QUERY_SUCCESS:
    case REFERRAL_GET_LIST_SUCCESS:
      return {
        ...state,
        referrals: payload,
        loading: false,
      };
    case REFERRAL_ADD_NEW:
      return {
        ...state,
        loading: true,
        error: null,
        addSuccess: false,
      };

    case REFERRAL_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        referral: payload,
        referrals: state.referrals.concat(payload),
        addSuccess: true,
      };

    case REFERRAL_EDIT:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case REFERRAL_EDIT_SUCCESS:
      return {
        ...state,
        referrals: state.referrals.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        referral: payload,
        error: null,
        loading: false,
        editSuccess: true,
      };
    case REFERRAL_DELETE:
      return {
        ...state,
        loading: true,
        error: null,
        deleteSuccess: false,
      };
    case REFERRAL_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        referrals: state.referrals.filter(c => c.id !== payload),
        deleteSuccess: true,
      };
    case REFERRAL_GET_LIST_BY_QUERY_ERROR:
    case REFERRAL_DELETE_ERROR:
    case REFERRAL_ADD_NEW_ERROR:
    case REFERRAL_EDIT_ERROR:
    case REFERRAL_GET_BY_ID_ERROR:
    case REFERRAL_GET_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
