import {
  BUDGET_GET_LIST,
  BUDGET_GET_LIST_SUCCESS,
  BUDGET_GET_LIST_ERROR,
  BUDGET_GET_LIST_WITH_FILTER,
  BUDGET_GET_LIST_WITH_ORDER,
  BUDGET_GET_LIST_SEARCH,
  BUDGET_ADD_ITEM,
  BUDGET_ADD_ITEM_SUCCESS,
  BUDGET_ADD_ITEM_ERROR,
  BUDGET_SELECTED_ITEMS_CHANGE,
  BUDGET_EDIT_ITEM,
  BUDGET_EDIT_ITEM_SUCCESS,
  BUDGET_EDIT_ITEM_ERROR,
  BUDGET_DELETE_ITEM,
  BUDGET_DELETE_ITEM_SUCCESS,
  BUDGET_DELETE_ITEM_ERROR,
} from '../../actions';

export const getBudgetList = query => ({
  type: BUDGET_GET_LIST,
  payload: query,
});

export const getBudgetListSuccess = items => ({
  type: BUDGET_GET_LIST_SUCCESS,
  payload: items,
});

export const getBudgetListError = error => ({
  type: BUDGET_GET_LIST_ERROR,
  payload: error,
});

export const getBudgetListWithFilter = (column, value) => ({
  type: BUDGET_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getBudgetListWithOrder = column => ({
  type: BUDGET_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getBudgetListSearch = keyword => ({
  type: BUDGET_GET_LIST_SEARCH,
  payload: keyword,
});

export const addBudgetItem = item => ({
  type: BUDGET_ADD_ITEM,
  payload: item,
});
export const addBudgetItemSuccess = items => ({
  type: BUDGET_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addBudgetItemError = error => ({
  type: BUDGET_ADD_ITEM_ERROR,
  payload: error,
});

export const editBudgetItem = item => ({
  type: BUDGET_EDIT_ITEM,
  payload: item,
});

export const editBudgetItemSuccess = items => ({
  type: BUDGET_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editBudgetItemError = error => ({
  type: BUDGET_EDIT_ITEM_ERROR,
  payload: error,
});

export const selectedBudgetItemsChange = selectedItems => ({
  type: BUDGET_SELECTED_ITEMS_CHANGE,
  payload: selectedItems,
});

export const deleteBudgetItem = item => ({
  type: BUDGET_DELETE_ITEM,
  payload: item,
});

export const deleteBudgetItemSuccess = items => ({
  type: BUDGET_DELETE_ITEM_SUCCESS,
  payload: items,
});

export const deleteBudgetItemError = error => ({
  type: BUDGET_DELETE_ITEM_ERROR,
  payload: error,
});
