import {
  CLIENT_AGGREGATE_UPDATE,
  CLIENT_AGGREGATE_UPDATE_SUCCESS,
  CLIENT_AGGREGATE_UPDATE_ERROR,
  CLIENT_AGGREGATE_GET_BY_ID,
  CLIENT_AGGREGATE_GET_BY_ID_SUCCESS,
  CLIENT_AGGREGATE_GET_BY_ID_ERROR,
} from '../actions';

export const updateClientAggregate = clientAggregate => ({
  type: CLIENT_AGGREGATE_UPDATE,
  payload: clientAggregate,
});

export const updateClientAggregateError = error => ({
  type: CLIENT_AGGREGATE_UPDATE_ERROR,
  payload: error,
});

export const updateClientAggregateSuccess = clientAggregate => ({
  type: CLIENT_AGGREGATE_UPDATE_SUCCESS,
  payload: clientAggregate,
});

export const getClientAggregateById = id => ({
  type: CLIENT_AGGREGATE_GET_BY_ID,
  payload: id,
});

export const getClientAggregateByIdError = error => ({
  type: CLIENT_AGGREGATE_GET_BY_ID_ERROR,
  payload: error,
});

export const getClientAggregateByIdSuccess = clientAggregate => ({
  type: CLIENT_AGGREGATE_GET_BY_ID_SUCCESS,
  payload: clientAggregate,
});
