import {
  CLIENT_SUPPORT_ADD_NEW,
  CLIENT_SUPPORT_ADD_NEW_ERROR,
  CLIENT_SUPPORT_DELETE,
  CLIENT_SUPPORT_DELETE_ERROR,
  CLIENT_SUPPORT_EDIT,
  CLIENT_SUPPORT_EDIT_ERROR,
  CLIENT_SUPPORT_EDIT_SUCCESS,
  CLIENT_SUPPORT_GET_BY_ID,
  CLIENT_SUPPORT_GET_BY_ID_ERROR,
  CLIENT_SUPPORT_GET_BY_ID_SUCCESS,
  CLIENT_SUPPORT_GET_LIST,
  CLIENT_SUPPORT_GET_LIST_ERROR,
  CLIENT_SUPPORT_GET_LIST_SUCCESS,
  CLIENT_SUPPORT_ADD_NEW_REQUEST,
  CLIENT_SUPPORT_LOG_LIST,
  CLIENT_SUPPORT_LOG_LIST_SUCCESS,
  CLIENT_SUPPORT_LOG_LIST_ERROR,
  CLIENT_SUPPORT_DELETE_ATTACHMENT,
  CLIENT_SUPPORT_DELETE_ATTACHMENT_SUCCESS,
  CLIENT_SUPPORT_DELETE_ATTACHMENT_ERROR,
  CLIENT_SUPPORT_REPORT,
  CLIENT_SUPPORT_REPORT_SUCCESS,
  CLIENT_SUPPORT_REPORT_ERROR,
} from './types';

export const getClientSupportReport = query => ({
  type: CLIENT_SUPPORT_REPORT,
  payload: query,
});

export const getClientSupportReportSuccess = ClientSupports => ({
  type: CLIENT_SUPPORT_REPORT_SUCCESS,
  payload: ClientSupports,
});

export const getClientSupportReportError = error => ({
  type: CLIENT_SUPPORT_REPORT_ERROR,
  payload: error,
});

export const getClientSupportLogList = ClientSupportId => ({
  type: CLIENT_SUPPORT_LOG_LIST,
  payload: ClientSupportId,
});

export const getClientSupportLogListSuccess = ClientSupportLogs => ({
  type: CLIENT_SUPPORT_LOG_LIST_SUCCESS,
  payload: ClientSupportLogs,
});

export const getClientSupportLogListError = error => ({
  type: CLIENT_SUPPORT_LOG_LIST_ERROR,
  payload: error,
});

export const getClientSupportList = query => ({
  type: CLIENT_SUPPORT_GET_LIST,
  payload: query,
});

export const getClientSupportListSuccess = ClientSupports => ({
  type: CLIENT_SUPPORT_GET_LIST_SUCCESS,
  payload: ClientSupports,
});

export const getClientSupportListError = error => ({
  type: CLIENT_SUPPORT_GET_LIST_ERROR,
  payload: error,
});

export const addNewClientSupport = ClientSupport => ({
  type: CLIENT_SUPPORT_ADD_NEW,
  payload: ClientSupport,
});

export const addNewClientSupportError = error => ({
  type: CLIENT_SUPPORT_ADD_NEW_ERROR,
  payload: error,
});

export const addNewClientSupportRequest = () => ({
  type: CLIENT_SUPPORT_ADD_NEW_REQUEST,
});

export const editClientSupport = ClientSupport => ({
  type: CLIENT_SUPPORT_EDIT,
  payload: ClientSupport,
});

export const editClientSupportSuccess = ClientSupport => ({
  type: CLIENT_SUPPORT_EDIT_SUCCESS,
  payload: ClientSupport,
});

export const editClientSupportError = error => ({
  type: CLIENT_SUPPORT_EDIT_ERROR,
  payload: error,
});

export const deleteClientSupport = id => ({
  type: CLIENT_SUPPORT_DELETE,
  payload: id,
});

export const deleteClientSupportError = error => ({
  type: CLIENT_SUPPORT_DELETE_ERROR,
  payload: error,
});

export const getClientSupportById = id => ({
  type: CLIENT_SUPPORT_GET_BY_ID,
  payload: id,
});

export const getClientSupportByIdSuccess = ClientSupport => ({
  type: CLIENT_SUPPORT_GET_BY_ID_SUCCESS,
  payload: ClientSupport,
});

export const getClientSupportByIdError = error => ({
  type: CLIENT_SUPPORT_GET_BY_ID_ERROR,
  payload: error,
});

export const deleteClientSupportAttachment = id => ({
  type: CLIENT_SUPPORT_DELETE_ATTACHMENT,
  payload: id,
});

export const deleteClientSupportAttachmentSuccess = data => ({
  type: CLIENT_SUPPORT_DELETE_ATTACHMENT_SUCCESS,
  payload: data,
});

export const deleteClientSupportAttachmentError = error => ({
  type: CLIENT_SUPPORT_DELETE_ATTACHMENT_ERROR,
  payload: error,
});
