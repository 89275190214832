import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  ADDITIONAL_INFO_EDIT,
  ADDITIONAL_INFO_GET_BY_ID,
  ADDITIONAL_INFO_ADD_NEW,
  ADDITIONAL_INFO_GET_LIST,
} from '../actions';
import Config from '../../config';

import {
  getAdditionalInfoListSuccess,
  getAdditionalInfoListError,
  editAdditionalInfoSuccess,
  editAdditionalInfoError,
  addNewAdditionalInfoSuccess,
  addNewAdditionalInfoError,
  getAdditionalInfoByIdSuccess,
} from './actions';

const getAdditionalInfoListRequest = async payload => {
  let url = `${Config.apiServerHost}/api/AdditionalInfo`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const getAdditionalInfoListByClientIdRequest = async payload => {
  let url = `${Config.apiServerHost}/api/AdditionalInfo/search`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const addNewAdditionalInfoReqeust = async item => {
  return axios.post(`${Config.apiServerHost}/api/AdditionalInfo`, item, await authHeader());
};

const editAdditionalInfoRequest = async item => {
  return axios.put(`${Config.apiServerHost}/api/AdditionalInfo`, item, await authHeader());
};

const getAdditionalInfoByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/AdditionalInfo/${id}`, await authHeader());
};

function* getAdditionalInfoList({ payload }) {
  try {
    const response = yield call(getAdditionalInfoListByClientIdRequest, payload);
    yield put(getAdditionalInfoListSuccess(response.data));
  } catch (error) {
    yield put(getAdditionalInfoListError(error.response.statusText));
  }
}

function* addNewAdditionalInfo({ payload }) {
  try {
    let response = yield call(addNewAdditionalInfoReqeust, payload);
    response = yield call(getAdditionalInfoByIdRequest, response.data);
    yield put(addNewAdditionalInfoSuccess(response.data));
  } catch (error) {
    yield put(addNewAdditionalInfoError(error.response.statusText));
  }
}

function* editAdditionalInfo({ payload }) {
  try {
    let response = yield call(editAdditionalInfoRequest, payload);
    response = yield call(getAdditionalInfoByIdRequest, response.data);
    yield put(editAdditionalInfoSuccess(response.data));
  } catch (error) {
    yield put(editAdditionalInfoError(error.response.statusText));
  }
}

function* getAdditionalInfoById({ payload }) {
  try {
    const response = yield call(getAdditionalInfoByIdRequest, payload);
    yield put(getAdditionalInfoByIdSuccess(response.data));
  } catch (error) {
    yield put(editAdditionalInfoError(error.response.statusText));
  }
}

export function* watchAdditionalInfoList() {
  yield takeEvery(ADDITIONAL_INFO_GET_LIST, getAdditionalInfoList);
}

export function* watchAddNewAdditionalInfo() {
  yield takeEvery(ADDITIONAL_INFO_ADD_NEW, addNewAdditionalInfo);
}

export function* watchAdditionalInfoById() {
  yield takeEvery(ADDITIONAL_INFO_GET_BY_ID, getAdditionalInfoById);
}

export function* watchEditAdditionalInfo() {
  yield takeEvery(ADDITIONAL_INFO_EDIT, editAdditionalInfo);
}

export default function* rootSaga() {
  yield all([
    fork(watchAdditionalInfoList),
    fork(watchAddNewAdditionalInfo),
    fork(watchAdditionalInfoById),
    fork(watchEditAdditionalInfo),
  ]);
}

export { getAdditionalInfoListByClientIdRequest, getAdditionalInfoListRequest };
