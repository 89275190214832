import { logger } from '../logger';
import { defaultDirection } from '../constants/defaultValues';
import jwt from 'jsonwebtoken';
import { Redirect, Route } from 'react-router-dom';
import { NotificationManager } from '../components/common/react-notifications';
import moment from 'moment';
import { Badge } from 'reactstrap';
import React from 'react';
import IntlMessages from './IntlMessages';

export const phoneNumberValidate = phoneNumber => {
  const validateRegx = /^[0-9]{1}[0-9]{2}-[0-9]{1}[0-9]{2}-[0-9]{4}$/;
  return validateRegx.test(phoneNumber);
};

export const validatePhoneNoAreaCode = phoneNumber => {
  const validateRegx = /^[0-9]{1}[0-9]{2}-[0-9]{4}$/;
  return validateRegx.test(phoneNumber);
};

export const emailValidate = email => {
  const validateRegx =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validateRegx.test(email);
};

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key],
      B = b[key];
    if (order.indexOf(A + '') > order.indexOf(B + '')) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};

export const isStringEmpty = (
  x //don't put newline after return
) =>
  typeof x == 'undefined' ||
  x == null ||
  x == false || //same as: !x
  x.length == 0 ||
  x == 0 || // note this line, you might not need this.
  x == '' ||
  x.replace(/\s/g, '') == '' ||
  !/[^\s]/.test(x) ||
  /^\s*$/.test(x);

export const getCutoffDate = () => {
  return moment('2023-08-21', 'YYYY-MM-DD');
};

export const isFormEligibleForPdfExport = fileName => {
  const eligiblePdfExportForms = ['ES3006E-Final', 'YJC3006E-Final'];
  const matchArray = eligiblePdfExportForms.filter(e => e === fileName);
  return matchArray && matchArray.length > 0;
};

export const formatErrorMessageFromObject = obj => {
  if (obj) {
    const err = obj?.message ?? obj;
    if (typeof err === 'string' || err instanceof String) {
      return err;
    }
  }
  return 'There was a problem detected. Please contact support.';
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '.' + mm + '.' + yyyy;
};

export const getCurrentTime = () => {
  const now = new Date();
  return now.getHours() + ':' + now.getMinutes();
};

export const getDayDifference = (date1, date2) => {
  // To calculate the time difference of two dates
  var differenceInTime = date1.getTime() - date2.getTime();

  // To calculate the no. of days between two dates
  var differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return Math.floor(differenceInDays);
};

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem('direction')) {
    const localValue = localStorage.getItem('direction');
    if (localValue === 'rtl' || localValue === 'ltr') {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};

export const setDirection = localValue => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  localStorage.setItem('direction', direction);
};

export const buildUrl = (url, parameters) => {
  let qs = '';
  for (const key in parameters) {
    if (parameters.hasOwnProperty(key)) {
      const value = parameters[key];
      if (Array.isArray(value)) {
        // eslint-disable-next-line no-undef
        for (let i = 0; i < value.length; i++) {
          qs += encodeURIComponent(key) + '=' + encodeURIComponent(value[i]) + '&';
        }
      } else {
        qs += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
      }
    }
  }
  if (qs.length > 0) {
    qs = qs.substring(0, qs.length - 1); //chop off last "&"
    url = url + '?' + qs;
  }

  return url;
};

export const getProgramName = id => {
  switch (id) {
    case 1:
      return 'GO';
    case 2:
      return 'ES';
    case 3:
      return 'SE';
    case 4:
      return 'COJG';
    case 5:
      return 'ODSP';
    case 6:
      return 'YESS';
    case 7:
      return 'YJC';
    case 8:
      return 'YJC-S';
    case 9:
      return 'LBS';
    case 10:
      return 'ARI';
    case 11:
      return 'HUB';
    case 12:
      return 'SAO';
    case 13:
      return 'OW-EP';
    case 14:
      return 'IES';
    case 15:
      return 'UNASSISTED';
    default:
      return 'ERROR';
  }
};

export const getCommunicationType = id => {
  switch (id) {
    case 0:
      return 'Note';
    case 1:
      return 'Email';
    case 2:
      return 'Phone';
    case 3:
      return 'Text';
    case 4:
      return 'Virtual';
    default:
      return 'N/A';
  }
};

export const getProgramData = () => {
  var programData = [];
  programData.push({
    id: 15,
    program: 'unassisted',
    icon: 'simple-icon-plane',
    title: 'UNASSISTED Program',
    detail: 'Default program to start.',
  });
  programData.push({
    id: 1,
    program: 'go',
    icon: 'simple-icon-plane',
    title: 'GO Program',
    detail: 'This is a program that will help you obtain a job.',
  });

  programData.push({
    id: 2,
    program: 'es',
    icon: 'iconsminds-male',
    title: 'ES Program',
    detail: 'This is a program that will help you obtain a job.',
  });

  programData.push({
    id: 3,
    program: 'se',
    icon: 'iconsminds-male',
    title: 'SE Program',
    detail: 'This is a program that will help you obtain a job.',
  });

  programData.push({
    id: 4,
    program: 'cojg',
    icon: 'iconsminds-male',
    title: 'COJG Program',
    detail:
      'Canada-Ontario Job Grant (COJG) provides opportunities for employers, individually or in groups, to invest in their workforce, with help from the government.',
  });

  programData.push({
    id: 5,
    program: 'odsp',
    icon: 'iconsminds-male',
    title: 'ODSP Program',
    detail:
      'If you don’t have enough money for food and housing, or if you have a disability and are in financial need, you can apply for financial and employment assistance through Ontario Works or the Ontario Disability Support Program (ODSP).',
  });

  programData.push({
    id: 6,
    program: 'yess',
    icon: 'iconsminds-male',
    title: 'YESS Program',
    detail: 'This is a program that will help you obtain a job.',
  });

  programData.push({
    id: 7,
    program: 'yjc',
    icon: 'iconsminds-male',
    title: 'YJC Program',
    detail:
      'Youth Job Connection is open to all eligible youth, focusing on those with the greatest employment needs and experiencing significant employment barriers.',
  });

  programData.push({
    id: 8,
    program: 'yjc-s',
    icon: 'iconsminds-male',
    title: 'YJC-S Program',
    detail:
      'Youth Job Connection is open to all eligible youth, focusing on those with the greatest employment needs and experiencing significant employment barriers.',
  });

  programData.push({
    id: 9,
    program: 'lbs',
    icon: 'iconsminds-male',
    title: 'LBS Program',
    detail: 'This is a program that will help you obtain a job.',
  });

  programData.push({
    id: 10,
    program: 'ari',
    icon: 'iconsminds-male',
    title: 'ARI Program',
    detail: 'This is a program that will help you obtain a job.',
  });

  programData.push({
    id: 11,
    program: 'hub',
    icon: 'iconsminds-male',
    title: 'HUB Program',
    detail: 'This is a program that will help you obtain a job.',
  });

  programData.push({
    id: 12,
    program: 'sao',
    icon: 'iconsminds-male',
    title: 'SAO Program',
    detail: 'This is a program that will help you obtain a job.',
  });

  programData.push({
    id: 13,
    program: 'ow-ep',
    icon: 'iconsminds-male',
    title: 'OW-EP Program',
    detail: 'This is a program that will help you obtain a job.',
  });

  programData.push({
    id: 14,
    program: 'ies',
    icon: 'iconsminds-male',
    title: 'IES Program',
    detail: 'This is a program that will help you obtain a job.',
  });

  return {
    en: programData,
  };
};

export const programData = {
  en: [
    {
      id: 15,
      program: 'unassisted',
      icon: 'simple-icon-plane',
      title: 'UNASSISTED Program',
      detail: 'Default program to start.',
    },
    {
      id: 1,
      program: 'go',
      icon: 'simple-icon-plane',
      title: 'GO Program',
      detail: 'This is a program that will help you obtain a job.',
    },
    {
      id: 2,
      program: 'es',
      icon: 'iconsminds-male',
      title: 'ES Program',
      detail: 'This is a program that will help you obtain a job.',
    },
    {
      id: 3,
      program: 'se',
      icon: 'iconsminds-male',
      title: 'SE Program',
      detail: 'This is a program that will help you obtain a job.',
    },
    {
      id: 4,
      program: 'cojg',
      icon: 'iconsminds-male',
      title: 'COJG Program',
      detail:
        'Canada-Ontario Job Grant (COJG) provides opportunities for employers, individually or in groups, to invest in their workforce, with help from the government.',
    },
    {
      id: 5,
      program: 'odsp',
      icon: 'iconsminds-male',
      title: 'ODSP Program',
      detail:
        'If you don’t have enough money for food and housing, or if you have a disability and are in financial need, you can apply for financial and employment assistance through Ontario Works or the Ontario Disability Support Program (ODSP).',
    },
    {
      id: 6,
      program: 'yess',
      icon: 'iconsminds-male',
      title: 'YESS Program',
      detail: 'This is a program that will help you obtain a job.',
    },
    {
      id: 7,
      program: 'yjc',
      icon: 'iconsminds-male',
      title: 'YJC Program',
      detail:
        'Youth Job Connection is open to all eligible youth, focusing on those with the greatest employment needs and experiencing significant employment barriers.',
    },
    {
      id: 8,
      program: 'yjc-s',
      icon: 'iconsminds-male',
      title: 'YJC-S Program',
      detail:
        'Youth Job Connection is open to all eligible youth, focusing on those with the greatest employment needs and experiencing significant employment barriers.',
    },
    {
      id: 9,
      program: 'lbs',
      icon: 'iconsminds-male',
      title: 'LBS Program',
      detail: 'This is a program that will help you obtain a job.',
    },
    {
      id: 10,
      program: 'ari',
      icon: 'iconsminds-male',
      title: 'ARI Program',
      detail: 'This is a program that will help you obtain a job.',
    },
    {
      id: 11,
      program: 'hub',
      icon: 'iconsminds-male',
      title: 'HUB Program',
      detail: 'This is a program that will help you obtain a job.',
    },
    {
      id: 12,
      program: 'sao',
      icon: 'iconsminds-male',
      title: 'SAO Program',
      detail: 'This is a program that will help you obtain a job.',
    },
    {
      id: 13,
      program: 'ow-ep',
      icon: 'iconsminds-male',
      title: 'OW-EP Program',
      detail: 'This is a program that will help you obtain a job.',
    },
    {
      id: 14,
      program: 'ies',
      icon: 'iconsminds-male',
      title: 'IES Program',
      detail: 'This is a program that will help you obtain a job.',
    },
  ],
};

export const getActionPlanTabId = programId => {
  switch (programId) {
    case 3:
      return 5;
    case 14:
      return 7;
    default:
      return 4;
  }
};

export const getCompanyClass = () => {
  if (document.location.host.includes('meta.')) {
    return 'meta';
  }
  if (document.location.host.includes('employnext.')) {
    return 'employnext';
  }
  if (document.location.host.includes('escases.azurewebsites.net')) {
    return 'meta';
  }
  if (document.location.host.includes('sandbox2.')) {
    return 'sandbox2';
  }
  if (document.location.host.includes('durham-sandbox.')) {
    return 'durham-sandbox';
  }
  if (document.location.host.includes('durham')) {
    return 'durham';
  }
  if (document.location.host.includes('fedcap')) {
    return 'fedcap';
  }
  // if (document.location.host.includes('localhost:')) {
  //   return 'sandbox2';
  // }
  return '';
};

export const getCompanyTitle = () => {
  if (getCompanyName()) {
    return `${getCompanyName().toUpperCase()} Case Management`;
  } else {
    return 'ESCASES Case Management';
  }
};

export const isCompanyAllowed = arrayOfAllowedCompanies => {
  var currentCompany = getCompanyName();
  const hasCompanyInArray = arrayOfAllowedCompanies.includes(currentCompany);
  return hasCompanyInArray;
};

export const getCompanyName = () => {
  if (document.location.host.includes('meta.')) {
    return 'meta';
  }
  if (document.location.host.includes('employnext.')) {
    return 'employnext';
  }

  if (document.location.host.includes('escases.azurewebsites.net')) {
    return 'meta';
  }

  if (document.location.host.includes('giag.')) {
    return 'giag';
  }

  if (document.location.host.includes('keys.')) {
    return 'keys';
  }

  if (document.location.host.includes('restart.')) {
    return 'restart';
  }

  if (document.location.host.includes('sandbox.')) {
    return 'sandbox';
  }

  if (document.location.host.includes('sandbox2.')) {
    return 'sandbox2';
  }

  if (document.location.host.includes('durham-sandbox.')) {
    return 'durham-sandbox';
  }

  if (document.location.host.includes('durham.')) {
    return 'durham';
  }

  if (document.location.host.includes('serco-sandbox.')) {
    return 'serco-sandbox';
  }

  if (document.location.host.includes('cdssab.')) {
    return 'cdssab';
  }

  if (document.location.host.includes('lacle.')) {
    return 'lacle';
  }

  if (document.location.host.includes('amdsb.')) {
    return 'amdsb';
  }

  if (document.location.host.includes('loyalist.')) {
    return 'loyalist';
  }
  if (document.location.host.includes('vccs.')) {
    return 'vccs';
  }
  if (document.location.host.includes('ysb.')) {
    return 'ysb';
  }
  if (document.location.host.includes('agilec.')) {
    return 'agilec';
  }
  if (document.location.host.includes('ymca-ceo.')) {
    return 'ymca-ceo';
  }
  if (document.location.host.includes('ymca-sm.')) {
    return 'ymca-sm';
  }
  if (document.location.host.includes('fleming-crew-hal.')) {
    return 'fleming-crew-hal';
  }
  if (document.location.host.includes('fleming-crew-ptbo.')) {
    return 'fleming-crew-ptbo';
  }
  if (document.location.host.includes('fleming-lbs-lindsay.')) {
    return 'fleming-lbs-lindsay';
  }
  if (document.location.host.includes('epcjobs.')) {
    return 'epcjobs';
  }
  if (document.location.host.includes('clrn.')) {
    return 'clrn';
  }
  if (document.location.host.includes('careeredge.')) {
    return 'careeredge';
  }
  if (document.location.host.includes('leadsservices.')) {
    return 'leadsservices';
  }
  if (document.location.host.includes('fedcap.')) {
    return 'fedcap';
  }
  return '';
};

export const decodeJwt = token => {
  if (!token) {
    logger.debug('Cannot decode empty token.');
    return null;
  }
  const decoded = jwt.decode(token);
  decoded.unique_name = decoded['custom:unique_name'];
  decoded.fullName = decoded['custom:fullName'];
  decoded.role = decoded['custom:role'];
  decoded.avatar = decoded['custom:avatar'];
  return decoded;
};

export const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    // Trim the text to the specified length and add an ellipsis
    return text.substring(0, maxLength) + '...';
  } else {
    // If the text is already shorter than the specified length, return it as is
    return text;
  }
};

export const hasAccess = (permissions, permission) => {
  if (!permission) {
    return false;
  }
  if (!permissions) {
    logger.debug('unknown permissions, denying access', permission);
    return false;
  }
  let isGranted =
    Array.isArray(permissions) && (permissions.includes(permission) || permissions.includes('*'));
  if (!isGranted && !Array.isArray(permissions)) {
    if (permissions['*']) {
      isGranted = true;
    } else {
      for (const key of Object.keys(permissions)) {
        const perms = permissions[key];
        if (perms && Array.isArray(perms) && (perms.includes(permission) || perms.includes('*'))) {
          isGranted = true;
        }
      }
    }
  }
  if (!isGranted) {
    // logger.debug(`This user does not have ${permission} permission`);
  }

  return isGranted;
};

export const AuthRoute = ({ component: Component, requiredPermissions, permissions, ...rest }) => {
  let isAllowed = true;
  if (requiredPermissions && requiredPermissions.length > 0) {
    for (let permission of requiredPermissions) {
      if (!hasAccess(permissions, permission)) {
        isAllowed = false;
        break;
      }
    }
  }

  return (
    <Route
      {...rest}
      render={props =>
        isAllowed ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/unauthorized',
              state: { requiredPermissions: requiredPermissions },
            }}
          />
        )
      }
    />
  );
};

export const getProgramColor = item => {
  let color = 'primary';
  switch (item) {
    case 'GO':
      color = 'primary';
      break;

    case 'ES':
      color = 'success';
      break;

    case 'SE':
      color = 'danger';
      break;

    case 'COJG':
      color = 'warning';
      break;

    case 'ODSP':
      color = 'info';
      break;

    case 'YESS':
      color = 'dark';
      break;
    case 'Employer':
      color = 'info';
      break;

    default:
      color = 'primary';
      break;
  }

  return color;
};

export const getStatusColor = item => {
  let color = 'primary';
  switch (item) {
    case 1: //active
      color = 'success';
      break;

    case 2: //open
      color = 'warning';
      break;
    case 3: //closed
      color = 'dark';
      break;
    case 4: //IR
      color = 'success';
      break;
    case 5: //Active – On Hold
      color = 'success';
      break;
    case 6: //Active – Outcomes
      color = 'success';
      break;
    case 7: //Unassigned
      color = 'info';
      break;
    case 8: //Unassigned
      color = 'danger';
      break;
    case 15:
      color = 'light';
      break;
    default:
      color = 'success';
  }
  return color;
};

export const getAvatarFromContact = item => {
  if (item) {
    var avatar = `${item.firstName.charAt(0)}${item.lastName.charAt(0)}`;
    return avatar.toUpperCase();
  }
  return '';
};

export const isAdmin = roles => {
  if (!Array.isArray(roles)) {
    return false;
  }

  if (!roles || roles.length <= 0) {
    return false;
  }

  const adminRoles = ['Administrator', 'System Administrator', 'LearningHUB Admin'];
  return roles.some(e => adminRoles.includes(e));
};

export const isServiceProviderManager = (siteRoles, allowableSiteCode) => {
  if (!allowableSiteCode) {
    return false;
  }

  if (!siteRoles || siteRoles.length <= 0) {
    return false;
  }

  const roles = siteRoles[allowableSiteCode];
  if (!roles || roles.length <= 0) {
    return false;
  }

  const serviceProviderManagerRoles = ['Service Provider Manager'];
  return roles.some(e => serviceProviderManagerRoles.includes(e));
};

export const isSiteUserAllowed = siteRoles => {
  const deniedSiteCode = ['3059T'];

  if (!siteRoles || siteRoles.length <= 0) {
    return false;
  }

  const roles = siteRoles[deniedSiteCode];
  if (!roles || roles.length <= 0) {
    return true;
  } else {
    return false;
  }
};

export const timestampFileName = () => {
  let today = new Date();
  return `${today.getFullYear()}${
    today.getMonth() + 1
  }${today.getDate()}${today.getHours()}${today.getMinutes()}`;
};

export const subfixTimeFileName = () => {
  var today = new Date();
  const fileName = `${today.getFullYear()}${
    today.getMonth() + 1
  }${today.getDate()}${today.getHours()}${today.getMinutes()}${today.getMilliseconds()}`;

  return fileName;
};

export const getCounsellorLabel = programId => {
  return programId === 11 ? 'practitioner' : 'caseManager'; // 11 is Hub
};

export const getJobDeveloperLabel = programId => {
  return programId === 11 ? 'practitioner' : 'employerLiaison'; // 11 is Hub
};

export const getMaxValue = values => {
  return Math.max.apply(Math, values);
};

export const getCurrentFiscalYear = () => {
  let currentDate = new Date();
  let month = currentDate.getMonth();
  let year = currentDate.getFullYear();
  return month < 3 ? `${year - 1}-${year}` : `${year}-${year + 1}`;
};

export const nocCodeOptions = [
  { key: 0, value: '0 Management occupations', label: '0 Management occupations' },
  {
    key: 1,
    value: '1 Business, finance and administration occupations',
    label: '1 Business, finance and administration occupations',
  },
  {
    key: 2,
    value: '2 Natural and applied sciences and related occupations',
    label: '2 Natural and applied sciences and related occupations',
  },
  { key: 3, value: '3 Health occupations', label: '3 Health occupations' },
  {
    key: 4,
    value: '4 Occupations in education, law and social, community and government services',
    label: '4 Occupations in education, law and social, community and government services',
  },
  {
    key: 5,
    value: '5 Occupations in art, culture, recreation and sport',
    label: '5 Occupations in art, culture, recreation and sport',
  },
  { key: 6, value: '6 Sales and service occupations', label: '6 Sales and service occupations' },
  {
    key: 7,
    value: '7 Trades, transport and equipment operators and related occupations',
    label: '7 Trades, transport and equipment operators and related occupations',
  },
  {
    key: 8,
    value: '8 Natural resources, agriculture and related production occupations',
    label: '8 Natural resources, agriculture and related production occupations',
  },
  {
    key: 9,
    value: '9 Occupations in manufacturing and utilities',
    label: '9 Occupations in manufacturing and utilities',
  },
];

export const jobTypeOptions = [
  { key: 1, value: 'Permanent (Full-time)', label: 'Permanent (Full-time)' },
  { key: 2, value: 'Permanent (Part-time)', label: 'Permanent (Part-time)' },
  { key: 3, value: 'Contract', label: 'Contract' },
  { key: 4, value: 'Seasonal', label: 'Seasonal' },
  { key: 5, value: 'Temporary (Full-time)', label: 'Temporary (Full-time)' },
  { key: 6, value: 'Temporary (Part-time)', label: 'Temporary (Part-time)' },
  { key: 7, value: 'Casual. ', label: 'Casual' },
];

export const titleOptions = () => {
  return [{ key: 0, value: 'Mr', label: 'Mr' }];
};

export const SEVERITY = {
  SUCCESS: 'SEVERITY_SUCCESS',
  INFO: 'SEVERITY_INFO',
  WARNING: 'SEVERITY_WARNING',
  DANGER: 'SEVERITY_DANGER',
};

export const displayMessage = error => {
  const { title, message, severity } = error;

  switch (severity) {
    case SEVERITY.INFO:
      NotificationManager.info(message, title, 3000, null, null, '');
      break;
    case SEVERITY.DANGER:
      NotificationManager.error(message, title, 3000, null, null, '');
      break;
    case SEVERITY.SUCCESS:
      NotificationManager.success(message, title, 3000, null, null, '');
      break;
    case SEVERITY.WARNING:
      NotificationManager.warning(message, title, 3000, null, null, '');
      break;
    default:
      NotificationManager.info(message, title, 3000, null, null, '');
      break;
  }
};

export const isValidEmail = mail => {
  // if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
  //   return true;
  // }
  return true;
};

export const getFiscalYearDropdown = () => {
  var today = new Date();
  let beginYear = today.getFullYear() - 10;
  let years = [];

  for (var year = 1; year <= 10; year++) {
    years.push(beginYear + year);
  }

  let options = years
    .sort((a, b) => {
      return a > b ? -1 : 1;
    })
    .map((year, index) => {
      return {
        key: index,
        value: `${year}-${year + 1}`,
        label: `${year}-${year + 1}`,
      };
    });

  return options;
};

export const validateEmail = email => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const validateEmailEx = emailAddress => {
  if (validateEmail(emailAddress)) {
    let splitEmail = emailAddress?.split('@');
    if (splitEmail.length === 2) {
      return splitEmail[1].split('.').length >= 2;
    }
  }

  return false;
};

export const getDocumentTypeOptions = () => {
  return {
    en: [
      { key: 1, value: 'Paystub', label: 'Paystub' },
      { key: 2, value: 'Letter from Employer', label: 'Letter from Employer' },
      { key: 3, value: 'Attestation', label: 'Attestation' },
      { key: 4, value: 'Receipt', label: 'Receipt' },
      { key: 5, value: 'Resume', label: 'Resume' },
      { key: 6, value: 'Other', label: 'Other' },
    ],
    fr: [
      { key: 1, value: 'Paystub', label: 'Talon de paie' },
      { key: 2, value: 'Letter from Employer', label: "Lettre de l'employeur" },
      { key: 3, value: 'Attestation', label: 'Attestation' },
      { key: 4, value: 'Receipt', label: 'Reçu' },
      { key: 5, value: 'Resume', label: 'CV' },
      { key: 6, value: 'Other', label: 'Autre' },
    ],
  };
};

export const getExtension = path => {
  let baseName = path.split(/[\\/]/).pop(), // extracts file name from full path
    // (supports separators `\\` and `/`)
    pos = baseName.lastIndexOf('.'); // gets the last position of `.`
  if (baseName === '' || pos < 1)
    // if the file name is empty or ...
    return ''; // the dot not found (-1) or comes first (0)
  return baseName.slice(pos + 1); // extracts extension ignoring "."
};

export const s1ReferralOptions = [
  { key: 1, value: 'S1ReferralOpt1', label: 'Under “Life Stabilization”' },
  { key: 2, value: 'S1ReferralOpt2', label: 'Basic needs- Housing' },
  { key: 3, value: 'S1ReferralOpt3', label: 'Basic needs-Financial Support' },
  { key: 4, value: 'S1ReferralOpt4', label: 'Basic needs-Food Security' },
  { key: 5, value: 'S1ReferralOpt5', label: 'Basic needs -Transportation' },
];

export const s1ActivityOptions = [
  {
    key: 1,
    value: 'S1ActivityOpt1',
    label: 'Under “Employment-Related Financial Supports for Job seekers and Employers”',
  },
  { key: 2, value: 'S1ActivityOpt2', label: 'Jobseeker – Employment-related Transportation' },
  { key: 3, value: 'S1ActivityOpt3', label: 'Under “Enhanced Referrals for Skills Development”' },
  { key: 4, value: 'S1ActivityOpt4', label: 'Referral to Literacy & Basic Skills' },
  { key: 5, value: 'S1ActivityOpt5', label: 'Under “Employment Assistance Services – General”' },
  { key: 6, value: 'S1ActivityOpt6', label: 'Resource and information services (R & I) – general' },
  { key: 7, value: 'S1ActivityOpt7', label: 'Employability skills training' },
];

export const s2ReferralOptions = [
  { key: 1, value: 'S2ReferralOpt1', label: 'Under “Life Stabilization”' },
  { key: 2, value: 'S2ReferralOpt2', label: 'Basic skills - Self-efficacy' },
  { key: 3, value: 'S2ReferralOpt3', label: 'Community Supports – Justice and legal support' },
  { key: 4, value: 'S2ReferralOpt4', label: 'Community Supports – Cultural transition' },
  { key: 5, value: 'S2ReferralOpt5', label: 'Under “Employment Assistance Services –General”' },
  { key: 6, value: 'S2ReferralOpt6', label: 'Referral for self-employment and entrepreneurship' },
];

export const s2ActivityOptions = [
  { key: 1, value: 'S2ActivityOpt1', label: 'Under “Employment Assistance Services – General”' },
  {
    key: 2,
    value: 'S2ActivityOpt2',
    label: 'Case management for self-employment and entrepreneurship',
  },
  { key: 3, value: 'S2ActivityOpt3', label: 'Resource and information services (R & I) – general' },
  {
    key: 4,
    value: 'S2ActivityOpt4',
    label: 'Resource and information services (R & I) – digital services',
  },
  { key: 5, value: 'S2ActivityOpt5', label: 'Client counselling' },
  { key: 6, value: 'S2ActivityOpt6', label: 'Employability skills training' },
  { key: 7, value: 'S2ActivityOpt7', label: 'Career exploration, planning and management' },
  { key: 8, value: 'S2ActivityOpt8', label: 'Job Search' },
  { key: 9, value: 'S2ActivityOpt9', label: 'Job matching and development' },
  { key: 10, value: 'S2ActivityOpt10', label: 'Volunteering' },
  { key: 11, value: 'S2ActivityOpt11', label: 'Employer - Job trials' },
  { key: 12, value: 'S2ActivityOpt12', label: 'Employer – Job placements' },
  { key: 13, value: 'S2ActivityOpt13', label: 'Employer coaching' },
  { key: 14, value: 'S2ActivityOpt14', label: 'Job coaching' },
  { key: 15, value: 'S2ActivityOpt15', label: 'Under “Retention Services”' },
  { key: 16, value: 'S2ActivityOpt16', label: 'Retention support planning' },
  {
    key: 17,
    value: 'S2ActivityOpt17',
    label: 'Accessible workplace consultation for clients with a disability',
  },
  { key: 18, value: 'S2ActivityOpt18', label: 'Ongoing job coaching' },
  { key: 19, value: 'S2ActivityOpt19', label: 'On-the-job training' },
  { key: 20, value: 'S2ActivityOpt20', label: 'Mentoring' },
  { key: 21, value: 'S2ActivityOpt21', label: 'Job retention crisis response' },
  {
    key: 22,
    value: 'S2ActivityOpt22',
    label: 'Supporting changes in work activities and supervision',
  },
  { key: 23, value: 'S2ActivityOpt23', label: 'Employer retention coaching' },
  { key: 24, value: 'S2ActivityOpt24', label: 'Additional services to find another job' },
  { key: 25, value: 'S2ActivityOpt25', label: 'Under “Specialized Services”' },
  { key: 26, value: 'S2ActivityOpt26', label: 'Employment services for people with disabilities' },
  {
    key: 27,
    value: 'S2ActivityOpt27',
    label: 'Workplace consultation for clients with a disability(ies)',
  },
  { key: 28, value: 'S2ActivityOpt28', label: 'Employer job carving' },
  { key: 29, value: 'S2ActivityOpt29', label: 'Employer education and training' },
  {
    key: 30,
    value: 'S2ActivityOpt30',
    label: 'Culturally-appropriate employment services for Indigenous peoples',
  },
  { key: 31, value: 'S2ActivityOpt31', label: 'Employment services for Francophones' },
  {
    key: 32,
    value: 'S2ActivityOpt32',
    label: 'Employment services for youth with higher support needs',
  },
  { key: 33, value: 'S2ActivityOpt33', label: 'Employment services for newcomers' },
  {
    key: 34,
    value: 'S2ActivityOpt34',
    label: 'Under “Employment-Related Financial Supports for Job seekers and Employers”',
  },
  {
    key: 35,
    value: 'S2ActivityOpt35',
    label: 'Job seeker - Accommodation needs -assistive devices and adaptive technology',
  },
  {
    key: 36,
    value: 'S2ActivityOpt36',
    label: 'Job seeker - Accommodation needs - on-the-job supports',
  },
  { key: 37, value: 'S2ActivityOpt37', label: 'Job seeker - Work clothing and/or grooming' },
  {
    key: 38,
    value: 'S2ActivityOpt38',
    label: 'Job seeker – Employment-related special equipment and supplies',
  },
  { key: 39, value: 'S2ActivityOpt39', label: 'Job seeker – Specialized hardware' },
  { key: 40, value: 'S2ActivityOpt40', label: 'Under “Employer Financial Supports”' },
  { key: 41, value: 'S2ActivityOpt41', label: 'Employer - Job trials with financial supports' },
  { key: 42, value: 'S2ActivityOpt42', label: 'Employer - Job placements with financial supports' },
  { key: 43, value: 'S2ActivityOpt43', label: 'Employer - Job accommodation' },
];

export const s3ReferralOptions = [
  { key: 1, value: 'S3ReferralOpt1', label: 'Under ” Enhanced Referrals for Skills Development”' },
  { key: 2, value: 'S3ReferralOpt2', label: 'Referral to Apprenticeship' },
  {
    key: 3,
    value: 'S3ReferralOpt3',
    label: 'Referral to Feepayer* - Completed and signed application',
  },
  {
    key: 4,
    value: 'S3ReferralOpt4',
    label: 'Referral to Feepayer - Letter of acceptance from training provider',
  },
  {
    key: 5,
    value: 'S3ReferralOpt5',
    label: 'Referral to Feepayer - Recommendation to register in Feepayer',
  },
  {
    key: 6,
    value: 'S3ReferralOpt6',
    label: 'Referral to Feepayer – Submit application to Ministry local office',
  },
  {
    key: 7,
    value: 'S3ReferralOpt7',
    label: 'Referral to Ontario Job Creation Partnerships - Completed and signed application',
  },
  {
    key: 8,
    value: 'S3ReferralOpt8',
    label:
      'Referral to Ontario Job Creation Partnerships - Recommendation to register in Job Creation Partnerships',
  },
  {
    key: 9,
    value: 'S3ReferralOpt9',
    label: 'Referral to Ontario Job Creation Partnerships - Resume',
  },
  {
    key: 10,
    value: 'S3ReferralOpt10',
    label:
      'Referral to Ontario Job Creation Partnerships - Submit application to ministry local office',
  },
  { key: 11, value: 'S3ReferralOpt11', label: 'Referral to SkillsAdvance Ontario' },
  { key: 12, value: 'S3ReferralOpt12', label: 'Referral to Micro-credentials' },
  { key: 13, value: 'S3ReferralOpt13', label: 'Referral to Literacy and Basic Skills' },
  { key: 14, value: 'S3ReferralOpt14', label: 'Referral to Canada-Ontario Job Grant' },
  { key: 15, value: 'S3ReferralOpt15', label: 'Referral to Ontario Bridge Training Program' },
  {
    key: 16,
    value: 'S3ReferralOpt16',
    label: 'Referral to Other Ministry Delivered or Funded Programs',
  },
  { key: 17, value: 'S3ReferralOpt17', label: 'Referral to Post-secondary Education' },
  { key: 18, value: 'S3ReferralOpt18', label: 'Referral to Secondary Education' },
  { key: 19, value: 'S3ReferralOpt19', label: 'Referral to Government Services - Federal' },
  { key: 20, value: 'S3ReferralOpt20', label: 'Referral to Government Services - Municipal' },
];

export const isSercoSandbox = () => {
  let host = document.location.host.toLowerCase();
  return ['serco-sandbox.escases.ca', 'localhost:5001'].includes(host);
};

export const isTrainingEnvironment = () => {
  let host = document.location.host.toLowerCase();
  return [
    'lres-sandbox.escases.ca',
    'sandbox2.escases.ca',
    'serco-sandbox.escases.ca',
    'localhost:5001',
  ].includes(host);
};

export const isSandbox2 = () => {
  let host = document.location.host.toLowerCase();
  return ['sandbox2.escases.ca'].includes(host);
};

export const isSerco = () => {
  let host = document.location.host.toLowerCase();
  return ['serco-sandbox.escases.ca', 'employnext.escases.ca', 'localhost:5001'].includes(host);
};

export const isTimesChange = () => {
  let host = document.location.host.toLowerCase();
  return ['timeschange.escases.ca'].includes(host);
};

export const s3ActivityOptions = [
  { key: 1, value: 'S3ActivityOpt1', label: 'Under “Employment Assistance Services – General”' },
  { key: 2, value: 'S3ActivityOpt2', label: 'Resource and information services (R & I) – general' },
  {
    key: 3,
    value: 'S3ActivityOpt3',
    label: 'Resource and information services (R & I) – digital services',
  },
  { key: 4, value: 'S3ActivityOpt4', label: 'Client counselling' },
  { key: 5, value: 'S3ActivityOpt5', label: 'Employability skills training' },
  { key: 6, value: 'S3ActivityOpt6', label: 'Career exploration, planning and management' },
  { key: 7, value: 'S3ActivityOpt7', label: 'Under “Retention Services”' },
  { key: 8, value: 'S3ActivityOpt8', label: 'On-the-job training' },
  {
    key: 9,
    value: 'S3ActivityOpt9',
    label: 'Under “Employment-Related Financial Supports for Job seekers and Employers”',
  },
  {
    key: 10,
    value: 'S3ActivityOpt10',
    label: 'Job seeker - Accommodation needs -job-specific communication skills training',
  },
  { key: 11, value: 'S3ActivityOpt11', label: 'Job seeker – Diagnostic assessment' },
  { key: 12, value: 'S3ActivityOpt12', label: 'Job seeker - Certification charges' },
  {
    key: 13,
    value: 'S3ActivityOpt13',
    label: 'Job seeker - Academic credential or professional accreditation assessment',
  },
  { key: 14, value: 'S3ActivityOpt14', label: 'Job seeker - Language skills assessment' },
  { key: 15, value: 'S3ActivityOpt15', label: 'Job seeker – Short-term skills training' },
  {
    key: 16,
    value: 'S3ActivityOpt16',
    label: 'Job seeker - Translation of international academic documents',
  },
];

export const s4ReferralOptions = [
  { key: 1, value: 'S4ReferralOpt1', label: 'Under “Life Stabilization”' },
  { key: 2, value: 'S4ReferralOpt2', label: 'Basic Needs - Crisis resolution' },
  { key: 3, value: 'S4ReferralOpt3', label: 'Community Supports – Dependent care' },
  { key: 4, value: 'S4ReferralOpt4', label: 'Community Supports – Cultural connections' },
  { key: 5, value: 'S4ReferralOpt5', label: 'Community Supports – Cultural transition' },
  { key: 6, value: 'S4ReferralOpt6', label: 'Community Supports – Newcomer services' },
];

export const s4ActivityOptions = [
  { key: 1, value: 'S4ActivityOpt1', label: 'Under “Employment Assistance Services – General”' },
  { key: 2, value: 'S4ActivityOpt2', label: 'Resource and information services (R & I) – general' },
  { key: 3, value: 'S4ActivityOpt3', label: 'Client counselling' },
  { key: 4, value: 'S4ActivityOpt4', label: 'Employability skills training' },
  { key: 5, value: 'S4ActivityOpt5', label: 'Volunteering' },
  { key: 6, value: 'S4ActivityOpt6', label: 'Under “Retention Services”' },
  { key: 7, value: 'S4ActivityOpt7', label: 'Retention support planning' },
  { key: 8, value: 'S4ActivityOpt8', label: 'Job retention crisis response' },
  {
    key: 9,
    value: 'S4ActivityOpt9',
    label: 'Under “Employment-Related Financial Supports for Job seekers and Employers”',
  },
  { key: 10, value: 'S4ActivityOpt10', label: 'Job seeker - Emergency/infrequent child care' },
];

export const s5ReferralOptions = [
  { key: 1, value: 'S5ReferralOpt1', label: 'Under “Life Stablization”' },
  { key: 2, value: 'S5ReferralOpt2', label: 'Basic Needs - Crisis resolution' },
  { key: 3, value: 'S5ReferralOpt3', label: 'Basic Needs - Food security' },
  { key: 4, value: 'S5ReferralOpt4', label: 'Basic skills - Self-efficacy' },
  {
    key: 5,
    value: 'S5ReferralOpt5',
    label: 'Health Supports - Primary care and ongoing medical concerns',
  },
  { key: 6, value: 'S5ReferralOpt6', label: 'Health Supports - Mental health and addictions' },
  { key: 7, value: 'S5ReferralOpt7', label: 'Community Supports - Cultural connections' },
];

export const s5ActivityOptions = [
  { key: 1, value: 'S5ActivityOpt1', label: 'Under “Employment Assistance Services – General”' },
  { key: 2, value: 'S5ActivityOpt2', label: 'Resource and information services (R & I) – general' },
  { key: 3, value: 'S5ActivityOpt3', label: 'Client counselling' },
  { key: 4, value: 'S5ActivityOpt4', label: 'Employability skills training' },
  { key: 5, value: 'S5ActivityOpt5', label: 'Volunteering' },
  { key: 6, value: 'S5ActivityOpt6', label: 'Under “Retention Services”' },
  { key: 7, value: 'S5ActivityOpt7', label: 'Job retention crisis response' },
];

export const s6ReferralOptions = [
  { key: 1, value: 'S6ReferralOpt1', label: 'Under “Life Stablization”' },
  { key: 2, value: 'S6ReferralOpt2', label: 'Basic Needs - Crisis resolution' },
  { key: 3, value: 'S6ReferralOpt3', label: 'Basic skills - Self-efficacy' },
  { key: 4, value: 'S6ReferralOpt4', label: 'Community Supports – Cultural connections' },
  { key: 5, value: 'S6ReferralOpt5', label: 'Community Supports – Cultural transition' },
  { key: 6, value: 'S6ReferralOpt6', label: 'Community Supports - Justice and legal support' },
  { key: 7, value: 'S6ReferralOpt7', label: 'Community Supports – Newcomer services' },
];

export const s6ActivityOptions = [
  { key: 1, value: 'S6ActivityOpt1', label: 'Under “Employment Assistance Services – General”' },
  { key: 2, value: 'S6ActivityOpt2', label: 'Resource and information services (R & I) – general' },
  { key: 3, value: 'S6ActivityOpt3', label: 'Client counselling' },
  { key: 4, value: 'S6ActivityOpt4', label: 'Employer coaching' },
  { key: 5, value: 'S6ActivityOpt5', label: 'Job coaching' },
  { key: 6, value: 'S6ActivityOpt6', label: 'Under “Retention Services”' },
  { key: 7, value: 'S6ActivityOpt7', label: 'Retention support planning' },
  {
    key: 8,
    value: 'S6ActivityOpt8',
    label: 'Accessible workplace consultation for clients with a disability',
  },
  { key: 9, value: 'S6ActivityOpt9', label: 'Ongoing job coaching' },
  { key: 10, value: 'S6ActivityOpt10', label: 'Mentoring' },
  { key: 11, value: 'S6ActivityOpt11', label: 'Job retention crisis response' },
  { key: 12, value: 'S6ActivityOpt12', label: 'Employer retention coaching' },
  { key: 13, value: 'S6ActivityOpt13', label: 'Additional services to find another job' },
  { key: 14, value: 'S6ActivityOpt14', label: 'Under “Specialized Services”' },
  { key: 15, value: 'S6ActivityOpt15', label: 'Employment services for people with disabilities' },
  {
    key: 16,
    value: 'S6ActivityOpt16',
    label: 'Workplace consultation for clients with a disability(ies)',
  },
  { key: 17, value: 'S6ActivityOpt17', label: 'Employer education and training' },
  {
    key: 18,
    value: 'S6ActivityOpt18',
    label: 'Culturally-appropriate employment services for Indigenous peoples',
  },
  { key: 19, value: 'S6ActivityOpt19', label: 'Employment services for Francophones' },
  {
    key: 20,
    value: 'S6ActivityOpt20',
    label: 'Employment services for youth with higher support needs',
  },
  { key: 21, value: 'S6ActivityOpt21', label: 'Employment services for newcomers' },
];

export const getTypeOfBusinessOptions = [
  {
    key: 1,
    value: 'Agriculture, Forestry, Fishing and Hunting',
    label: 'Agriculture, Forestry, Fishing and Hunting',
  },
  {
    key: 2,
    value: 'Management of Companies and Enterprises',
    label: 'Management of Companies and Enterprises',
  },
  { key: 3, value: 'Manufacturing including food', label: 'Manufacturing including food' },
  { key: 4, value: 'Transportation and Warehousing', label: 'Transportation and Warehousing' },
  { key: 5, value: 'Finance and Insurance', label: 'Finance and Insurance' },
  { key: 6, value: 'Construction', label: 'Construction' },
  {
    key: 7,
    value: 'Administrative and Support, Waste Management and Remediation Services',
    label: 'Administrative and Support, Waste Management and Remediation Services',
  },
  {
    key: 8,
    value: 'Real Estate and Rental and Leasing',
    label: 'Real Estate and Rental and Leasing',
  },
  {
    key: 9,
    value: 'Health Care and Social Assistance',
    label: 'Health Care and Social Assistance',
  },
  { key: 10, value: 'Public Administration', label: 'Public Administration' },
  { key: 11, value: 'Utilities', label: 'Utilities' },
  { key: 12, value: 'Wholesale Trade', label: 'Wholesale Trade' },
  {
    key: 13,
    value: 'Information and Cultural Industries',
    label: 'Information and Cultural Industries',
  },
  { key: 14, value: 'Educational Services', label: 'Educational Services' },
  { key: 15, value: 'Accommodation and Food Services', label: 'Accommodation and Food Services' },
  { key: 16, value: 'Mining, Quarrying', label: 'Mining, Quarrying' },
  { key: 17, value: 'Retail Trade', label: 'Retail Trade' },
  {
    key: 18,
    value: 'Professional, Scientific and Technical Services',
    label: 'Professional, Scientific and Technical Services',
  },
  {
    key: 19,
    value: 'Arts, Entertainment and Recreation',
    label: 'Arts, Entertainment and Recreation',
  },
  {
    key: 20,
    value: 'Other Services (except Public Administration)',
    label: 'Other Services (except Public Administration)',
  },
];

export const communityPartnerParent = [
  { key: 1, value: 'Family/Household Circumstances', label: 'Family/Household Circumstances' },
  { key: 2, value: 'Food Security', label: 'Food Security' },
  { key: 3, value: 'Francophone', label: 'Francophone' },
  { key: 4, value: 'Housing/Shelter', label: 'Housing/Shelter' },
  { key: 5, value: 'Immigration/Newcomer', label: 'Immigration/Newcomer' },
  { key: 6, value: 'Indigenous', label: 'Indigenous' },
  { key: 7, value: 'Justice/Legal', label: 'Justice/Legal' },
  { key: 8, value: 'LGBTQ2S+', label: 'LGBTQ2S+' },
  { key: 9, value: 'Literacy & Basic Skills (LBS)', label: 'Literacy & Basic Skills (LBS)' },
  { key: 10, value: 'Medical/Healthcare', label: 'Medical/Healthcare' },
  { key: 11, value: 'Military', label: 'Military' },
  { key: 12, value: 'Person With Disability', label: 'Person With Disability' },
  { key: 13, value: 'Social Assistance', label: 'Social Assistance' },
  { key: 14, value: 'Other', label: 'Other' },
  { key: 15, value: 'Volunteer', label: 'Volunteer' },
];

export const getCommunityPartnerBusinessTypes = () => {
  return communityPartnerParent.map(e => ({
    key: e.key,
    value: e.value,
    label: toCommunityBusinessTypeLabeli18n(e.value),
  }));
};

export const toCommunityBusinessTypeLabeli18n = value => (
  <IntlMessages
    id={
      'community-partner.business_type_' +
      value?.toLocaleLowerCase().replaceAll(' ', '_').replaceAll('/', '_')
    }
  />
);

export const displayCampaignStatus = campaign => {
  const { statusName, status } = campaign;
  let color;

  switch (status) {
    case 1:
      color = 'success';
      break;
    case 2:
      color = 'light';
      break;
    default:
      color = 'info';
  }

  return (
    <Badge color={color} pill className="mb-0 py-2 btn-xs">
      <span>{statusName}</span>
    </Badge>
  );
};

export const stripHTMLTags = content => {
  const tagRegEx = /<(?:.|\n)*?>/gm;
  return content.replace(tagRegEx, '');
};

export const displayStakeHolderStatus = stakeHolder => {
  const { status, statusName } = stakeHolder;
  let color = 'info';

  switch (status) {
    case 0:
      color = 'info';
      break;

    case 1:
      color = 'primary';
      break;

    case 2:
      color = 'warning';
      break;

    case 3:
      color = 'success';
      break;

    case 4:
      color = 'danger';
      break;

    case 5:
      color = 'secondary';
      break;

    default:
      color = 'info';
      break;
  }

  return (
    <span className={`text-${color}`}>{status >= 0 ? toStakeHolderStatusi18n(status) : null}</span>
  );
};

export const getUniqueArrayObjects = arr => {
  return arr.filter((obj, index) => {
    return (
      index ===
      arr.findIndex(matchedObj => {
        return JSON.stringify(obj) === JSON.stringify(matchedObj);
      })
    );
  });
};

export const stakeHolderStatusOptions = [
  { key: 0, value: 0, label: 'None' },
  { key: 1, value: 1, label: 'Contacted' },
  { key: 2, value: 2, label: 'Not Contacted' },
  { key: 3, value: 3, label: 'Qualified' },
  { key: 4, value: 4, label: 'Not Qualified' },
  { key: 5, value: 5, label: 'Interested (Bad Timing)' },
];

export const getStakeHolderStatusOptions = () => {
  return stakeHolderStatusOptions.map(e => ({
    key: e.key,
    value: e.value,
    label: toStakeHolderStatusi18n(e.value),
  }));
};
export const toStakeHolderStatusi18n = value => (
  <IntlMessages id={'community-partner.stake-holder-status_' + value} />
);

export const esmEmployersStatusOptions = [
  { key: 0, value: 0, label: 'None' },
  { key: 1, value: 6, label: 'Contacted (waiting for reply)' },
  { key: 2, value: 2, label: 'Not contacted' },
  { key: 3, value: 7, label: 'No reply' },
  { key: 4, value: 8, label: 'Interested' },
  { key: 5, value: 9, label: 'Follow up required' },
  { key: 6, value: 10, label: 'Not interested' },
  { key: 7, value: 11, label: 'Completed' },
  { key: 8, value: 12, label: 'In conversations' },
  { key: 9, value: 13, label: 'Relationship established' },
];

export const getEsmEmployersStatusOptions = () => {
  return esmEmployersStatusOptions.map(e => ({
    key: e.key,
    value: e.value,
    label: toEsmEmployersStatusLabeli18n(e.value),
  }));
};
export const toEsmEmployersStatusLabeli18n = value => (
  <IntlMessages id={'community-partner.esm-employers-status_' + value} />
);

export const TODAY = 'Today';
export const YESTERDAY = 'Yesterday';

export const isSameDay = (firstDate, secondDate) => {
  return (
    firstDate.getFullYear() === secondDate.getFullYear() &&
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getDate() === secondDate.getDate()
  );
};

export const displayFriendlyDateOnEmail = sentDateTime => {
  let today = new Date();
  let input = new Date(sentDateTime);
  let dayLabel = '';
  // compare the date parts
  if (isSameDay(today, input)) {
    dayLabel = TODAY;
  }
  let yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  if (isSameDay(yesterday, input)) {
    dayLabel = YESTERDAY;
  }
  if (dayLabel === TODAY) {
    return getTimeAndHourAgo(input);
  } else if (dayLabel === YESTERDAY) {
    return YESTERDAY;
  } else {
    let diffTime = Math.abs(Number(today) - Number(input));
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays <= 5) {
      return `${diffDays} days ago`;
    } else {
      return moment.utc(sentDateTime).local().format('DD-MMM-yyyy h:mm:ss a');
    }
  }
};

export const getTimeAndHourAgo = date => {
  const diffMs = Date.now() - date.getTime();
  const diffSecs = Math.floor(diffMs / 1000);
  const diffMins = Math.floor(diffSecs / 60);
  const diffHours = Math.floor(diffMins / 60);

  if (diffMins === 0) return 'just now';
  else if (diffMins === 1) return '1 minute ago';
  else if (diffMins < 60) {
    return diffMins + ' minutes ago';
  } else if (diffMins >= 60) {
    if (diffHours === 0) return 'less than an hour ago';
    else if (diffHours === 1) return '1 hour ago';
    return `${diffHours} hours ago`;
  }
};

export const getWeekOfMonth = m => {
  if (m == null || m == undefined) return '';
  return moment(m).week() - moment(m).month() * 4;
};

export const isNullOrEmpty = value => {
  return value === null || (typeof value === 'string' && value.trim() === '');
};

export const generateKey = pre => {
  return `${pre}_${new Date().getTime()}`;
};

export const getColorPercentage = (source, target) => {
  target = target === '' ? 0 : Number(target);

  if (target === 0) return '';

  const percentage = target === 0 ? 0 : (source / target) * 100;

  if (percentage >= 0 && percentage < 50) return 'text-danger';
  else if (percentage >= 50 && percentage < 100) return 'text-warning';
  else if (percentage >= 100) return 'text-success';
};

export const getAllIcons = menus => {
  let icons = [];

  function extractIcon(node) {
    if (node.icon) {
      icons.push(node.icon);
    }
    if (node.subs) {
      node.subs.forEach(subNode => extractIcon(subNode));
    }
  }

  menus.forEach(menu => extractIcon(menu));

  return icons;
};

export const validatePostalCode = postalCode => {
  // Canadian postal code regex pattern
  const postalCodePattern = /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/;

  // Check if the postal code matches the pattern
  return postalCodePattern.test(postalCode);
};

export const hasClientServices = () => {
  return localStorage.getItem('hasClientServices') === 'true';
};

export const getBackgroundServerBaseDomain = () => {
  return localStorage.getItem('backgroundServerBaseDomain');
};

export const getExportBaseDomain = () => {
  return localStorage.getItem('exportBaseDomain');
};

export const getBusRequestTypeName = id => {
  switch (id) {
    case 1:
      return <IntlMessages id="cam-progress.step-submit" />;
    case 2:
      return <IntlMessages id="sent" />;
    case 3:
      return <IntlMessages id="cam-progress.step-queued" />;
    case 4:
      return <IntlMessages id="network-progress.success" />;
    case 5:
      return <IntlMessages id="network-progress.error" />;
    default:
      return <IntlMessages id="prospect.unknown" />;
  }
};

export const getBatchProcessingSearchTypeName = id => {
  switch (id) {
    case 1:
      return <IntlMessages id="clientReferenceNumber" />;
    case 2:
      return <IntlMessages id="eapReferenceNumber" />;
    case 3:
      return <IntlMessages id="outcomeReferenceNumber" />;
    case 4:
      return <IntlMessages id="outcomeCkpReferenceNumber" />;
    default:
      return <IntlMessages id="prospect.unknown" />;
  }
};

export const getBatchProcessingUpdateTypeName = id => {
  switch (id) {
    case 1:
      return <IntlMessages id="client-update" />;
    case 2:
      return <IntlMessages id="eap-update" />;
    case 3:
      return <IntlMessages id="outcome-update" />;
    case 4:
      return <IntlMessages id="checkpoint-update" />;
    default:
      return <IntlMessages id="all-updates" />;
  }
};

export const getBatchProcessingStatusName = id => {
  switch (id) {
    case 1:
      return <IntlMessages id="network-progress.queued" />;
    case 2:
      return <IntlMessages id="network-progress.success" />;
    case 3:
      return <IntlMessages id="network-progress.error" />;
    case 101:
      return <IntlMessages id="cam-progress.step-submit" />;
    default:
      return <IntlMessages id="all-statuses" />;
  }
};

export const isEnableBatchProcessing = () => {
  // Retrieve the value from localStorage
  let localValue = localStorage.getItem('enableBatchProcessing');

  // Convert the localValue to a boolean
  let booleanValue = localValue === 'true';
  return booleanValue;
};
