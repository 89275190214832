import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../helpers/auth-header';
import axios from 'axios';
import Config from '../../config';

import { SECTOR_GET_LIST, SECTOR_EDIT_ITEM, SECTOR_DELETE_ITEM } from '../actions';

import { getSectorListSuccess, getSectorListError, editSectorItemError } from './actions';

const getSectorListRequest = async () => {
  return await axios.get(`${Config.apiServerHost}/api/common/sector`, await authHeader());
};

function* getSectorListItems() {
  try {
    const response = yield call(getSectorListRequest);
    yield put(getSectorListSuccess(response.data));
  } catch (error) {
    yield put(getSectorListError(error));
  }
}

const poshSectorListRequest = async sector => {
  return await axios.post(`${Config.apiServerHost}/api/common/sector`, sector, await authHeader());
};

function* editSectorItem({ payload }) {
  try {
    yield call(poshSectorListRequest, payload);
    const res = yield call(getSectorListRequest);
    yield put(getSectorListSuccess(res.data));
  } catch (error) {
    yield put(editSectorItemError(error));
  }
}

const deleteSectorListRequest = async id => {
  return await axios.delete(`${Config.apiServerHost}/api/common/sector/${id}`, await authHeader());
};

function* deleteSectorItem({ payload }) {
  try {
    yield call(deleteSectorListRequest, payload);
    const res = yield call(getSectorListRequest);
    yield put(getSectorListSuccess(res.data));
  } catch (error) {
    yield put(editSectorItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(SECTOR_GET_LIST, getSectorListItems);
}

export function* wathcEditItem() {
  yield takeEvery(SECTOR_EDIT_ITEM, editSectorItem);
}

export function* wathcDeleteItem() {
  yield takeEvery(SECTOR_DELETE_ITEM, deleteSectorItem);
}

export default function* rootSaga() {
  yield all([fork(watchGetList), fork(wathcEditItem), fork(wathcDeleteItem)]);
}
