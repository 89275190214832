import {
  YJCS_CHEQUE_ADD_NEW,
  YJCS_CHEQUE_ADD_NEW_SUCCESS,
  YJCS_CHEQUE_ADD_NEW_ERROR,
  YJCS_CHEQUE_DELETE,
  YJCS_CHEQUE_DELETE_ERROR,
  YJCS_CHEQUE_DELETE_SUCCESS,
  YJCS_CHEQUE_EDIT,
  YJCS_CHEQUE_EDIT_ERROR,
  YJCS_CHEQUE_EDIT_SUCCESS,
  YJCS_CHEQUE_GET_BY_ID,
  YJCS_CHEQUE_GET_BY_ID_ERROR,
  YJCS_CHEQUE_GET_BY_ID_SUCCESS,
  YJCS_CHEQUE_ADD_NEW_REQUEST,
  YJCS_CHEQUE_QUERY,
  YJCS_CHEQUE_QUERY_SUCCESS,
  YJCS_CHEQUE_QUERY_ERROR,
} from '../actions';

// YJCSCheque
export const getYJCSChequeQuery = query => ({
  type: YJCS_CHEQUE_QUERY,
  payload: query,
});

export const getYJCSChequeQuerySuccess = caseNotes => ({
  type: YJCS_CHEQUE_QUERY_SUCCESS,
  payload: caseNotes,
});

export const getYJCSChequeQueryError = error => ({
  type: YJCS_CHEQUE_QUERY_ERROR,
  payload: error,
});

export const addNewYJCSCheque = caseNote => ({
  type: YJCS_CHEQUE_ADD_NEW,
  payload: caseNote,
});

export const addNewYJCSChequeRequest = () => ({
  type: YJCS_CHEQUE_ADD_NEW_REQUEST,
});

export const addNewYJCSChequeSuccess = CaseNote => ({
  type: YJCS_CHEQUE_ADD_NEW_SUCCESS,
  payload: CaseNote,
});

export const addNewYJCSChequeError = error => ({
  type: YJCS_CHEQUE_ADD_NEW_ERROR,
  payload: error,
});

export const editYJCSCheque = CaseNote => ({
  type: YJCS_CHEQUE_EDIT,
  payload: CaseNote,
});

export const editYJCSChequeSuccess = caseNote => ({
  type: YJCS_CHEQUE_EDIT_SUCCESS,
  payload: caseNote,
});

export const editYJCSChequeError = error => ({
  type: YJCS_CHEQUE_EDIT_ERROR,
  payload: error,
});

export const deleteYJCSCheque = id => ({
  type: YJCS_CHEQUE_DELETE,
  payload: id,
});

export const deleteYJCSChequeSuccess = id => ({
  type: YJCS_CHEQUE_DELETE_SUCCESS,
  payload: id,
});

export const deleteYJCSChequeError = error => ({
  type: YJCS_CHEQUE_DELETE_ERROR,
  payload: error,
});

export const getYJCSChequeById = id => ({
  type: YJCS_CHEQUE_GET_BY_ID,
  payload: id,
});

export const getYJCSChequeByIdSuccess = caseNote => ({
  type: YJCS_CHEQUE_GET_BY_ID_SUCCESS,
  payload: caseNote,
});

export const getYJCSChequeByIdError = error => ({
  type: YJCS_CHEQUE_GET_BY_ID_ERROR,
  payload: error,
});
