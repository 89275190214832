import {
  CLIENT_NEW_MATCHING_GET_BY_ID,
  CLIENT_NEW_MATCHING_GET_BY_ID_SUCCESS,
  CLIENT_NEW_MATCHING_GET_LIST,
  CLIENT_NEW_MATCHING_GET_LIST_SUCCESS,
  CLIENT_NEW_MATCHING_ERROR,
  CLIENT_NEW_MATCHING_CLEAR_LIST,
} from './types';
// ClientNewMatching
export const getClientNewMatchingList = query => ({
  type: CLIENT_NEW_MATCHING_GET_LIST,
  payload: query,
});

export const getClientNewMatchingListSuccess = item => ({
  type: CLIENT_NEW_MATCHING_GET_LIST_SUCCESS,
  payload: item,
});

export const getClientNewMatchingClearList = () => ({
  type: CLIENT_NEW_MATCHING_CLEAR_LIST,
});

export const getClientNewMatchingById = id => ({
  type: CLIENT_NEW_MATCHING_GET_BY_ID,
  payload: id,
});

export const getClientNewMatchingByIdSuccess = item => ({
  type: CLIENT_NEW_MATCHING_GET_BY_ID_SUCCESS,
  payload: item,
});

export const clientNewMatchingError = error => ({
  type: CLIENT_NEW_MATCHING_ERROR,
  payload: error,
});
