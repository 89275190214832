import {
  SUB_GOAL_ADD_NEW,
  SUB_GOAL_ADD_NEW_SUCCESS,
  SUB_GOAL_ADD_NEW_ERROR,
  SUB_GOAL_EDIT,
  SUB_GOAL_EDIT_ERROR,
  SUB_GOAL_EDIT_SUCCESS,
  SUB_GOAL_GET_BY_ID,
  SUB_GOAL_GET_BY_ID_ERROR,
  SUB_GOAL_GET_BY_ID_SUCCESS,
  SUB_GOAL_GET_LIST,
  SUB_GOAL_GET_LIST_ERROR,
  SUB_GOAL_GET_LIST_SUCCESS,
  SUB_GOAL_ADD_NEW_REQUEST,
  SUB_GOAL_BY_CLIENT,
  SUB_GOAL_BY_CLIENT_SUCCESS,
  SUB_GOAL_BY_CLIENT_ERROR,
  SUB_GOAL_NOTIFICATION_CLOSED,
} from './types';

// items
const initialState = {
  items: [],
  item: null,
  loading: false,
  error: null,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
  showNotification: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SUB_GOAL_GET_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case SUB_GOAL_BY_CLIENT_SUCCESS:
    case SUB_GOAL_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
      };

    case SUB_GOAL_ADD_NEW_REQUEST:
      return {
        ...state,
        addSuccess: false,
      };

    case SUB_GOAL_GET_LIST:
    case SUB_GOAL_BY_CLIENT:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUB_GOAL_GET_LIST_SUCCESS:
      return {
        ...state,
        items: payload,
        loading: false,
      };
    case SUB_GOAL_ADD_NEW:
      return {
        ...state,
        loading: false,
        error: null,
        addSuccess: false,
      };

    case SUB_GOAL_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
        items: state.items.concat(payload),
        addSuccess: true,
        showNotification: true,
      };

    case SUB_GOAL_EDIT:
      return {
        ...state,
        loading: false,
        error: null,
        editSuccess: false,
      };
    case SUB_GOAL_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        item: payload,
        error: null,
        editSuccess: true,
      };

    case SUB_GOAL_NOTIFICATION_CLOSED:
      return {
        ...state,
        showNotification: false,
      };
    case SUB_GOAL_ADD_NEW_ERROR:
    case SUB_GOAL_EDIT_ERROR:
    case SUB_GOAL_GET_BY_ID_ERROR:
    case SUB_GOAL_GET_LIST_ERROR:
    case SUB_GOAL_BY_CLIENT_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
