import { logger } from '../../logger';
import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  EMPLOYER_INFO_SYNC,
  EMPLOYER_INFO_GET_BY_ID,
  EMPLOYER_INFO_UPDATE,
  EMPLOYER_INFO_ADD_NEW,
} from './types';

import {
  addNewEmployerInfoError,
  getEmployerInfoByIdError,
  getEmployerInfoByIdSuccess,
  updateEmployerInfoError,
  updateEmployerInfoSuccess,
} from './actions';
import { syncEmployerInfoError, syncEmployerInfoSuccess } from '../actions';

const getEmployerInfoByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/EmployerProfile/${id}`, await authHeader());
};

const getNaicsIndustryChart = async queryParam => {
  return axios.get(`${Config.apiServerHost}/api/EmployerProfile/naicsIndustryChart`, {
    params: queryParam,
    headers: { ...(await authHeader()).headers },
  });
};

const getTypeOfSectorChart = async queryParam => {
  return axios.get(`${Config.apiServerHost}/api/EmployerProfile/typeOfSectorChart`, {
    params: queryParam,
    headers: { ...(await authHeader()).headers },
  });
};

const getEmployerContractStatusChart = async queryParam => {
  return axios.get(`${Config.apiServerHost}/api/EmployerProfile/contractStatusChart`, {
    params: queryParam,
    headers: { ...(await authHeader()).headers },
  });
};

const getCAClientRecordRequest = async queryParam => {
  return axios.get(`${Config.apiServerHost}/api/EmployerProfile/caClientRecord`, {
    params: queryParam,
    headers: { ...(await authHeader()).headers },
  });
};

const getEmployerInfoByEmployerReferenceNumberRequest = async employerReferenceNumber => {
  return axios.get(
    `${Config.apiServerHost}/api/EmployerProfile/employerReferenceNumber/${employerReferenceNumber}`,
    await authHeader()
  );
};

const detectDiferenceEmployerInfoByEmployerReferenceNumberRequest =
  async employerReferenceNumber => {
    return axios.get(
      `${Config.apiServerHost}/api/EmployerProfile/detectDifference/${employerReferenceNumber}`,
      await authHeader()
    );
  };

const getTransactionLogsByClientIdRequest = async id => {
  return axios.get(
    `${Config.apiServerHost}/api/ClientProfile/client/${id}/transaction/logs`,
    await authHeader()
  );
};

const getTransactionErrorsByClientIdRequest = async id => {
  return axios.get(
    `${Config.apiServerHost}/api/ClientProfile/client/${id}/transaction/live`,
    await authHeader()
  );
};

const putEmployerInfoTransactionIgnoreRequest = async id => {
  return axios.put(
    `${Config.apiServerHost}/api/ClientProfile/client/transaction/${id}/ignore`,
    null,
    await authHeader()
  );
};

const syncEmployerInfoRequest = async (id, employerReferenceNumberOverride) => {
  return axios.get(
    `${Config.apiServerHost}/api/EmployerProfile/${id}/sync?employerReferenceNumberOverride=${employerReferenceNumberOverride}`,
    await authHeader()
  );
};

const getEmployerProfileRequest = async queryParam => {
  return axios.get(`${Config.apiServerHost}/api/EmployerProfile`, {
    params: queryParam,
    headers: { ...(await authHeader()).headers },
  });
};

const getEmployerProfilesRequest = async () => {
  return axios.get(`${Config.apiServerHost}/api/EmployerProfile/dropdown`, {
    headers: { ...(await authHeader()).headers },
  });
};

function* syncEmployerInfo({ payload }) {
  try {
    logger.debug(payload);
    const { id, employerReferenceNumberOverride } = payload;
    let response = yield call(syncEmployerInfoRequest, id, employerReferenceNumberOverride);
    yield put(syncEmployerInfoSuccess(response.data));
  } catch (error) {
    const message = {
      title: 'Sync Employer Info',
      text: error.response?.data?.message ?? 'something went wrong.',
    };
    yield put(syncEmployerInfoError(message));
  }
}

function* getEmployerInfoById({ payload }) {
  try {
    let response = yield call(getEmployerInfoByIdRequest, payload);
    yield put(getEmployerInfoByIdSuccess(response.data));
  } catch (error) {
    yield put(getEmployerInfoByIdError(error.response.statusText));
  }
}

const updateEmployerInfoRequest = async payload => {
  return axios.put(
    `${Config.apiServerHost}/api/EmployerProfile/${payload.employerId}`,
    payload,
    await authHeader()
  );
};

const addNewEmployerInfoRequest = async payload => {
  return axios.post(`${Config.apiServerHost}/api/EmployerProfile`, payload, await authHeader());
};

function* updateEmployerInfo({ payload }) {
  try {
    let response = yield call(updateEmployerInfoRequest, payload);
    yield put(updateEmployerInfoSuccess(response.data));
  } catch (error) {
    yield put(updateEmployerInfoError(error?.response?.statusText));
  }
}

function* addNewEmployerInfo({ payload }) {
  try {
    const { employerInfo, history, onSuccessHandler } = payload;
    let response = yield call(addNewEmployerInfoRequest, employerInfo);
    const id = response.data;
    response = yield call(getEmployerInfoByIdRequest, id);
    yield put(getEmployerInfoByIdSuccess(response.data));
    if (onSuccessHandler) {
      onSuccessHandler(id);
    }
  } catch (error) {
    yield put(addNewEmployerInfoError(error?.response?.statusText));
  }
}

export function* watchGettingClientById() {
  yield takeEvery(EMPLOYER_INFO_GET_BY_ID, getEmployerInfoById);
}

export function* watchUpdatingEmployerInfo() {
  yield takeEvery(EMPLOYER_INFO_UPDATE, updateEmployerInfo);
}

export function* watchAddingEmployerInfo() {
  yield takeEvery(EMPLOYER_INFO_ADD_NEW, addNewEmployerInfo);
}

export function* watchSyncingEmployerInfo() {
  yield takeEvery(EMPLOYER_INFO_SYNC, syncEmployerInfo);
}

export default function* rootSaga() {
  yield all([
    fork(watchGettingClientById),
    fork(watchUpdatingEmployerInfo),
    fork(watchSyncingEmployerInfo),
    fork(watchAddingEmployerInfo),
  ]);
}

export {
  getTransactionLogsByClientIdRequest,
  getTransactionErrorsByClientIdRequest,
  putEmployerInfoTransactionIgnoreRequest,
  getEmployerProfileRequest,
  getEmployerInfoByEmployerReferenceNumberRequest,
  getCAClientRecordRequest,
  detectDiferenceEmployerInfoByEmployerReferenceNumberRequest,
  getEmployerProfilesRequest,
  getNaicsIndustryChart,
  getTypeOfSectorChart,
  getEmployerContractStatusChart,
};
