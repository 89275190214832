import {
  CA_REFERRAL_GET_BY_ID,
  CA_REFERRAL_GET_BY_ID_ERROR,
  CA_REFERRAL_GET_BY_ID_SUCCESS,
  CA_REFERRAL_UPDATE,
  CA_REFERRAL_UPDATE_SUCCESS,
  CA_REFERRAL_UPDATE_ERROR,
} from './types';
// item
const initialState = {
  item: null,
  items: [],
  loading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
  total: 0,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CA_REFERRAL_GET_BY_ID:
    case CA_REFERRAL_UPDATE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CA_REFERRAL_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
      };
    case CA_REFERRAL_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CA_REFERRAL_GET_BY_ID_ERROR:
    case CA_REFERRAL_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
}
