import {
  OUTCOME_CHECKPOINT_GET_BY_QUERY,
  OUTCOME_CHECKPOINT_GET_BY_QUERY_ERROR,
  OUTCOME_CHECKPOINT_GET_BY_QUERY_SUCCESS,
  OUTCOME_CHECKPOINT_UPDATE,
  OUTCOME_CHECKPOINT_UPDATE_SUCCESS,
  OUTCOME_CHECKPOINT_UPDATE_ERROR,
  OUTCOME_CHECKPOINT_SYNC,
  OUTCOME_CHECKPOINT_SYNC_SUCCESS,
  OUTCOME_CHECKPOINT_SYNC_ERROR,
  OUTCOME_CHECKPOINT_CLEAR_NOTIFIED,
  GET_REFRESH_OUTCOME_CHECKPOINT_GET_BY_QUERY_SUCCESS,
  OUTCOME_CHECKPOINT_DETECT_DIFFERENCE_SUCCESS,
  OUTCOME_CHECKPOINT_SINGLE_UPDATE,
  GET_REFRESH_OUTCOME_CHECKPOINT_ATTACHMENT_GET_BY_QUERY_SUCCESS,
  GET_REFRESH_OUTCOME_CHECKPOINT_ATTACHMENT_GET_BY_QUERY,
} from './types';

// item
const initialState = {
  item: null,
  items: [],
  itemsWithAttachments: [],
  loading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
  total: 0,
  message: null,
  syncLoading: false,
  lastUpdate: null,
  difference: null,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_REFRESH_OUTCOME_CHECKPOINT_ATTACHMENT_GET_BY_QUERY:
      return {
        ...state,
        itemsWithAttachments: [],
      };
    case OUTCOME_CHECKPOINT_DETECT_DIFFERENCE_SUCCESS:
      return {
        ...state,
        difference: payload,
      };
    case OUTCOME_CHECKPOINT_UPDATE:
    case OUTCOME_CHECKPOINT_SINGLE_UPDATE:
    case OUTCOME_CHECKPOINT_GET_BY_QUERY:
      return {
        ...state,
        loading: true,
        error: null,
        itemsWithAttachments: [],
      };
    case OUTCOME_CHECKPOINT_SYNC:
      return {
        ...state,
        loading: false,
        error: null,
        syncLoading: true,
      };
    case OUTCOME_CHECKPOINT_UPDATE_SUCCESS:
      return {
        ...state,
        item: payload,
        loading: false,
        lastUpdate: new Date(),
        syncLoading: false,
      };
    case GET_REFRESH_OUTCOME_CHECKPOINT_GET_BY_QUERY_SUCCESS:
      return {
        ...state,
        items: payload,
        itemsWithAttachments: [],
      };

    case GET_REFRESH_OUTCOME_CHECKPOINT_ATTACHMENT_GET_BY_QUERY_SUCCESS:
      return {
        ...state,
        itemsWithAttachments: payload,
      };
    case OUTCOME_CHECKPOINT_SYNC_SUCCESS:
      return {
        ...state,
        loading: false,
        items: payload,
        lastUpdate: new Date(),
        syncLoading: false,
        difference: null,
      };
    case OUTCOME_CHECKPOINT_GET_BY_QUERY_SUCCESS:
      return {
        ...state,
        loading: false,
        items: payload,
        lastUpdate: new Date(),
        syncLoading: false,
      };
    case OUTCOME_CHECKPOINT_GET_BY_QUERY_ERROR:
    case OUTCOME_CHECKPOINT_SYNC_ERROR:
    case OUTCOME_CHECKPOINT_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        syncLoading: false,
      };
    case OUTCOME_CHECKPOINT_CLEAR_NOTIFIED:
      return {
        ...state,
        message: null,
        error: null,
        loading: false,
        syncLoading: false,
      };
    default:
      return { ...state };
  }
}
