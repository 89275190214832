import {
  COJG_RECONCILIATION_GET_LIST,
  COJG_RECONCILIATION_GET_LIST_SUCCESS,
  COJG_RECONCILIATION_GET_LIST_ERROR,
  COJG_RECONCILIATION_GET_LIST_WITH_FILTER,
  COJG_RECONCILIATION_GET_LIST_WITH_ORDER,
  COJG_RECONCILIATION_GET_LIST_SEARCH,
} from '../../actions';

export const getCOJGReconciliationList = filter => ({
  type: COJG_RECONCILIATION_GET_LIST,
  payload: filter,
});

export const getCOJGReconciliationListSuccess = items => ({
  type: COJG_RECONCILIATION_GET_LIST_SUCCESS,
  payload: items,
});

export const getCOJGReconciliationListError = error => ({
  type: COJG_RECONCILIATION_GET_LIST_ERROR,
  payload: error,
});

export const getCOJGReconciliationListWithFilter = (column, value) => ({
  type: COJG_RECONCILIATION_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getCOJGReconciliationListWithOrder = column => ({
  type: COJG_RECONCILIATION_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getCOJGReconciliationListSearch = keyword => ({
  type: COJG_RECONCILIATION_GET_LIST_SEARCH,
  payload: keyword,
});
