import {
  GET_WORKSHOP_EVENT_LIST,
  GET_WORKSHOP_EVENT_LIST_ERROR,
  GET_WORKSHOP_EVENT_LIST_SUCCESS,
} from './types';

export const getWorkshopEventList = query => ({
  type: GET_WORKSHOP_EVENT_LIST,
  payload: query,
});

export const getWorkshopEventListSuccess = item => ({
  type: GET_WORKSHOP_EVENT_LIST_SUCCESS,
  payload: item,
});

export const getWorkshopEventListError = error => ({
  type: GET_WORKSHOP_EVENT_LIST_ERROR,
  payload: error,
});
