import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  ES_CHEQUE_GET_BY_ESCONTRACT_ID,
  ES_CHEQUE_GET_BY_ID,
  ES_CHEQUE_ADD_NEW,
  ES_CHEQUE_EDIT,
  ES_CHEQUE_DELETE,
} from '../actions';

import {
  getESChequeByESContractIdSuccess,
  getESChequeByESContractIdError,
  getESChequeByIdSuccess,
  getESChequeByIdError,
  addNewESChequeSuccess,
  addNewESChequeError,
  editESChequeSuccess,
  editESChequeError,
  deleteESChequeSuccess,
  deleteESChequeError,
} from './actions';

const getESChequeByESContractIdRequest = async esContractId => {
  const url = `${Config.apiServerHost}/api/escheque/es-contract-id/${esContractId}`;
  return axios.get(url, await authHeader());
};

const getESChequeByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/escheque/${id}`, await authHeader());
};

const addNewESChequeReqeust = async item => {
  return axios.post(`${Config.apiServerHost}/api/escheque`, item, await authHeader());
};

const editESChequeRequest = async item => {
  return axios.put(`${Config.apiServerHost}/api/escheque`, item, await authHeader());
};

const deleteESChequeRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/escheque/${id}`, await authHeader());
};

function* getESChequeByESContractId({ payload }) {
  try {
    const response = yield call(getESChequeByESContractIdRequest, payload);
    yield put(getESChequeByESContractIdSuccess(response.data));
  } catch (error) {
    yield put(getESChequeByESContractIdError(error.response.statusText));
  }
}

function* getESChequeById({ payload }) {
  try {
    const response = yield call(getESChequeByIdRequest, payload);
    yield put(getESChequeByIdSuccess(response.data));
  } catch (error) {
    yield put(getESChequeByIdError(error.response.statusText));
  }
}

function* addNewESCheque({ payload }) {
  try {
    let response = yield call(addNewESChequeReqeust, payload);
    response = yield call(getESChequeByIdRequest, response.data);
    yield put(addNewESChequeSuccess(response.data));
  } catch (error) {
    yield put(addNewESChequeError(error.response.statusText));
  }
}

function* editESCheque({ payload }) {
  try {
    let response = yield call(editESChequeRequest, payload);
    response = yield call(getESChequeByIdRequest, response.data);
    yield put(editESChequeSuccess(response.data));
  } catch (error) {
    yield put(editESChequeError(error.response.statusText));
  }
}

function* deleteESCheque({ payload }) {
  try {
    const response = yield call(deleteESChequeRequest, payload);
    yield put(deleteESChequeSuccess(response.data));
  } catch (error) {
    yield put(deleteESChequeError(error.response.statusText));
  }
}

export function* watchGetESChequeByESContractId() {
  yield takeEvery(ES_CHEQUE_GET_BY_ESCONTRACT_ID, getESChequeByESContractId);
}

export function* watchGetESChequeById() {
  yield takeEvery(ES_CHEQUE_GET_BY_ID, getESChequeById);
}

export function* watchAddNewESCheque() {
  yield takeEvery(ES_CHEQUE_ADD_NEW, addNewESCheque);
}

export function* watchEditESCheque() {
  yield takeEvery(ES_CHEQUE_EDIT, editESCheque);
}

export function* watchDeleteESCheque() {
  yield takeEvery(ES_CHEQUE_DELETE, deleteESCheque);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetESChequeByESContractId),
    fork(watchGetESChequeById),
    fork(watchAddNewESCheque),
    fork(watchEditESCheque),
    fork(watchDeleteESCheque),
  ]);
}

export { getESChequeByESContractIdRequest };
