import {
  SUB_GOAL_ADD_NEW,
  SUB_GOAL_ADD_NEW_SUCCESS,
  SUB_GOAL_ADD_NEW_ERROR,
  SUB_GOAL_EDIT,
  SUB_GOAL_EDIT_ERROR,
  SUB_GOAL_EDIT_SUCCESS,
  SUB_GOAL_GET_BY_ID,
  SUB_GOAL_GET_BY_ID_ERROR,
  SUB_GOAL_GET_BY_ID_SUCCESS,
  SUB_GOAL_GET_LIST,
  SUB_GOAL_GET_LIST_ERROR,
  SUB_GOAL_GET_LIST_SUCCESS,
  SUB_GOAL_ADD_NEW_REQUEST,
  SUB_GOAL_BY_CLIENT,
  SUB_GOAL_BY_CLIENT_SUCCESS,
  SUB_GOAL_BY_CLIENT_ERROR,
  SUB_GOAL_NOTIFICATION_CLOSED,
} from './types';

/** SubGoal */

export const getSubGoalByClient = query => ({
  type: SUB_GOAL_BY_CLIENT,
  payload: query,
});

export const getSubGoalByClientSuccess = caseNotes => ({
  type: SUB_GOAL_BY_CLIENT_SUCCESS,
  payload: caseNotes,
});

export const getSubGoalByClientError = error => ({
  type: SUB_GOAL_BY_CLIENT_ERROR,
  payload: error,
});

export const getSubGoalList = clientId => ({
  type: SUB_GOAL_GET_LIST,
  payload: clientId,
});

export const getSubGoalListSuccess = CaseNotes => ({
  type: SUB_GOAL_GET_LIST_SUCCESS,
  payload: CaseNotes,
});

export const getSubGoalListError = error => ({
  type: SUB_GOAL_GET_LIST_ERROR,
  payload: error,
});

export const addNewSubGoal = caseNote => ({
  type: SUB_GOAL_ADD_NEW,
  payload: caseNote,
});

export const addNewSubGoalRequest = () => ({
  type: SUB_GOAL_ADD_NEW_REQUEST,
});

export const addNewSubGoalSuccess = CaseNote => ({
  type: SUB_GOAL_ADD_NEW_SUCCESS,
  payload: CaseNote,
});

export const addNewSubGoalError = error => ({
  type: SUB_GOAL_ADD_NEW_ERROR,
  payload: error,
});

export const editSubGoal = CaseNote => ({
  type: SUB_GOAL_EDIT,
  payload: CaseNote,
});

export const editSubGoalSuccess = caseNote => ({
  type: SUB_GOAL_EDIT_SUCCESS,
  payload: caseNote,
});

export const editSubGoalError = error => ({
  type: SUB_GOAL_EDIT_ERROR,
  payload: error,
});

export const getSubGoalById = id => ({
  type: SUB_GOAL_GET_BY_ID,
  payload: id,
});

export const getSubGoalByIdSuccess = caseNote => ({
  type: SUB_GOAL_GET_BY_ID_SUCCESS,
  payload: caseNote,
});

export const getSubGoalByIdError = error => ({
  type: SUB_GOAL_GET_BY_ID_ERROR,
  payload: error,
});

export const subGoalNotificationClosed = () => ({
  type: SUB_GOAL_NOTIFICATION_CLOSED,
});
