import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import { CAMPAIGN_DASHBOARD_GET_PAGIN } from './types';

import { getCampaignDashboardSuccess, getCampaignDashboardError } from './actions';

const queryCampaignDashboard = async item => {
  return axios.post(`${Config.apiServerHost}/api/CecDashboard/list`, item, await authHeader());
};

const getIndividualActivityChartDataRequest = async item => {
  return axios.post(
    `${Config.apiServerHost}/api/CecDashboard/individualActivityOverTimeChart`,
    item,
    await authHeader()
  );
};

function* getDashboardCampaign({ payload }) {
  try {
    const resp = yield call(queryCampaignDashboard, payload);
    yield put(getCampaignDashboardSuccess(resp.data));
  } catch (error) {
    yield put(getCampaignDashboardError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(CAMPAIGN_DASHBOARD_GET_PAGIN, getDashboardCampaign);
}

export { getIndividualActivityChartDataRequest };
