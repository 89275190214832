import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import trainerApp from './employers/reducer';
import employer from './employers/employer-reducer';
import client from './client/reducer';
import actionPlan from './action-plan/reducer';
import activity from './activity/reducer';
import event from './event/reducer';
import jobSeeker from './job-seeker/reducer';
import cojgContract from './contract/cojg/reducer';
import cheque from './contract/cojg/cheque-reducer';
import budget from './contract/budget/reducer';
import geography from './geography/reducer';
import shift from './shifts/reducer';
import jobtype from './job-type/reducer';
import sector from './sector/reducer';
import cojgFinancial from './report/cojg/reducer';
import contractsPaid from './report/contracts-paid/reducer';
import cojgFund from './report/cojg-fund/reducer';
import cojgReconciliation from './report/cojg-reconciliation/reducer';
import esContractReport from './report/es/reducer';
import allESContractOustanding from './report/all-es-contract-oustanding/reducer';
import role from './role/reducer';
import userList from './user/reducer';
import assessement from './assessement/reducer';
import clientAggregate from './client-aggregate/reducer';
import aggregate from './aggregate/reducer';
import notificationReport from './report/notification/reducer';
import clientFund from './client-fund/reducer';
import caseNote2 from './case-note2/reducer';
import resume from './resume/reducer';
import camsImport from './cams-import/reducer';
import employerCaseNote from './employer-case-note/reducer';
import todoApp from './todo/reducer';
import caseSummary from './case-summary/reducer';
import mileage from './mileage/reducer';
import expense from './expense/reducer';
import mileageEntry from './mileage-entry/reducer';
import expenseEntry from './expense-entry/reducer';
import mileageSetting from './mileage-setting/reducer';
import clientFundSetting from './client-fund-setting/reducer';
import cojgCaseNote from './cojg-case-note/reducer';
import seContract from './se-contract/reducer';
import esContract from './es-contract/reducer';
import iesContract from './ies-contract/reducer';
import document from './document/reducer';
import setting from './system-setting/reducer';
import esCheque from './es-cheque/reducer';
import seCheque from './se-cheque/reducer';
import esCaseNote from './es-case-note/reducer';
import seCaseNote from './se-case-note/reducer';
import mileageSupervisor from './mileage-supervisor/reducer';
import eventCalendar from './event-calendar/reducer';
import formDocument from './form-document/reducer';
import additionalInfo from './additional-info/reducer';
import site from './site/reducer';
import employmentEarning from './employment-earning/reducer';
import contact from './contact/reducer';
import posting from './posting/reducer';
import eventParticipant from './event-participant/reducer';
import cecContact from './cec-contact/reducer';
import cecList from './cec-list/reducer';
import yjcContract from './yjc-contract/reducer';
import yjcCheque from './yjc-cheque/reducer';
import yjcsContract from './yjcs-contract/reducer';
import yjcsCheque from './yjcs-cheque/reducer';
import prospectReceiveNotification from './prospect-receive-notification/reducer';
import servicePlan from './service-plan/reducer';
import alert from './alert/reducer';
import referral from './referral/reducer';
import clientFundSupervisor from './client-fund-supervisor/reducer';
import employmentActionPlan from './employment-action-plan/reducer';
import clientSupport from './client-support/reducer';
import employerDocument from './employer-document/reducer';
import outreach from './outreach/reducer';
import outcomes from './outcomes/reducer';
import subGoal from './sub-goal/reducer';
import clientSummary from './client-summary/reducer';
import financialTracking from './financial-tracking/reducer';
import jobMatching from './job-matching/reducer';
import interventionFollowupIES from './intervention-followup-ies/reducer';
import prospect from './prospect/reducer';
import eidm from './eidm/reducer';
import subGoalLBS from './subgoal-lbs/reducer';
import clientSummaryLBS from './client-summary-lbs/reducer';
import clientTSA from './client-tsa/reducer';
import ClientReferral from './client-referral/reducer';
import planContent from './plan-content/reducer';
import cojgContractDocument from './cojg-contract-document/reducer';
import clientCourse from './client-course/reducer';
import clientSummaryYJC from './client-summary-yjc/reducer';
import applicant from './applicant/reducer';
import caseAudit from './case-audit/reducer';
import clientInfo from './client-info/reducer';
import eap from './eap/reducer';
import caReferral from './ca-referral/reducer';
import outcomeQuestionaire from './outcome-questionaire/reducer';
import outcomeCheckpoint from './outcome-checkpoint/reducer';
import cams from './cams/reducer';
import employerInfo from './employer-info/reducer';
import clientJobMatch from './client-job-match/reducer';
import clientNewMatching from './client-new-matching/reducer';
import postingSentToClient from './posting-sent-to-client/reducer';
import postingNewMatching from './posting-new-matching/reducer';
import postingMatchingSentToClient from './posting-matching-sent-to-client/reducer';
import taskManagement from './task-management/reducer';
import note from './note/reducer';
import invitation from './invitation/reducer';
import esuite from './esuite/reducer';
import esuiteActivity from './esuite-activity/reducer';
import transactionNotification from './transaction-notification/reducer';
import iesDashboard from './ies-dashboard/reducer';
import iesChartDetailReport from './ies-chart-detail-report/reducer';
import targetScheme from './target-scheme/reducer';
import userNotification from './user-notification/reducer';
import intervention from './intervention/reducer';
import jobSeekerRetainedEmployer from './job-seeker-retained-employer/reducer';
import actionPlanForStaff from './action-plan-for-staff/reducer';
import actionPlanningTool from './action-planning-tool/reducer';
import jmpiMatrix from './jmpi-matrix/reducer';
import stakeHolder from './stake-holder/reducer';
import stakeHolderAction from './stake-holder-action/reducer';
import stakeHolderContact from './stake-holder-contact/reducer';
import stakeHolderEmail from './stake-holder-email/reducer';
import stakeHolderMeetingNote from './stake-holder-meeting-note/reducer';
import stakeHolderDocument from './stake-holder-document/reducer';
import communityPartner from './community-partner/reducer';
import emailTemplate from './email-template/reducer';
import location from './location/reducer';
import campaign from './campaign/reducer';
import campdashboard from './camp-dashboard/reducer';
import trfCaReferral from './trf-ca-referral/reducer';
import weeklyreport from './report/ies-weekly/reducer';
import budgetTracking from './budget-tracking/reducer';
import surveyNotification from './survey-notification-setting/reducer';
import cpReferrer from './cp-referrer/reducer';
import workshopEvent from './workshop-event/reducer';
import userDocument from './user-document/reducer';
import journeyAck from './journey-ack/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  trainerApp,
  employer,
  contact,
  client,
  actionPlan,
  activity,
  event,
  jobSeeker,
  esContract,
  iesContract,
  cojgContract,
  cheque,
  budget,
  geography,
  shift,
  jobtype,
  sector,
  cojgFinancial,
  contractsPaid,
  cojgFund,
  cojgReconciliation,
  esContractReport,
  allESContractOustanding,
  role,
  userList,
  assessement,
  clientAggregate,
  aggregate,
  notificationReport,
  clientFund,
  caseNote2,
  resume,
  camsImport,
  employerCaseNote,
  todoApp,
  caseSummary,
  mileage,
  expense,
  mileageEntry,
  expenseEntry,
  mileageSetting,
  clientFundSetting,
  cojgCaseNote,
  seContract,
  document,
  setting,
  esCheque,
  seCheque,
  esCaseNote,
  seCaseNote,
  mileageSupervisor,
  eventCalendar,
  formDocument,
  additionalInfo,
  site,
  employmentEarning,
  posting,
  eventParticipant,
  cecContact,
  cecList,
  yjcContract,
  yjcCheque,
  yjcsContract,
  yjcsCheque,
  prospectReceiveNotification,
  servicePlan,
  alert,
  referral,
  clientFundSupervisor,
  employmentActionPlan,
  clientSupport,
  employerDocument,
  outreach,
  outcomes,
  subGoal,
  clientSummary,
  financialTracking,
  jobMatching,
  interventionFollowupIES,
  prospect,
  eidm,
  subGoalLBS,
  clientSummaryLBS,
  clientTSA,
  ClientReferral,
  clientCourse,
  planContent,
  cojgContractDocument,
  clientSummaryYJC,
  applicant,
  caseAudit,
  clientInfo,
  eap,
  caReferral,
  outcomeQuestionaire,
  outcomeCheckpoint,
  cams,
  employerInfo,
  clientJobMatch,
  clientNewMatching,
  postingSentToClient,
  postingNewMatching,
  postingMatchingSentToClient,
  note,
  taskManagement,
  invitation,
  esuite,
  esuiteActivity,
  transactionNotification,
  iesDashboard,
  iesChartDetailReport,
  targetScheme,
  userNotification,
  intervention,
  jobSeekerRetainedEmployer,
  actionPlanForStaff,
  actionPlanningTool,
  jmpiMatrix,
  stakeHolder,
  stakeHolderAction,
  stakeHolderContact,
  stakeHolderEmail,
  stakeHolderMeetingNote,
  stakeHolderDocument,
  communityPartner,
  emailTemplate,
  location,
  campaign,
  campdashboard,
  trfCaReferral,
  weeklyreport,
  budgetTracking,
  cpReferrer,
  surveyNotification,
  workshopEvent,
  userDocument,
  journeyAck,
});

export default reducers;
