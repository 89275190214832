import log4js from 'log4js';

// Create a logger instance
export const logger = log4js.getLogger();
// Configure Log4js based on the environment
if (
  document.location.host.toLowerCase().includes('sandbox.escases.ca') ||
  document.location.host.toLowerCase().includes('localhost')
) {
  log4js.configure({
    appenders: {
      console: {
        type: 'console',
        layout: {
          type: 'pattern',
          pattern: `%[%d] [%p] %c - %m\n%f:%l`,
        },
      },
    },
    categories: { default: { appenders: ['console'], level: 'debug' } },
  });
} else {
  log4js.configure({
    appenders: {
      console: {
        type: 'console',
        layout: {
          type: 'pattern',
          pattern: `%[%d] [%p] %c - %m\n%f:%l`,
        },
      },
    },
    categories: { default: { appenders: ['console'], level: 'error' } },
  });
}
