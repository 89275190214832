export const GET_NOTIFICATION_BY_QUERY = 'GET_NOTIFICATION_BY_QUERY';
export const GET_NOTIFICATION_BY_QUERY_SUCCESS = 'GET_NOTIFICATION_BY_QUERY_SUCCESS';
export const GET_NOTIFICATION_BY_QUERY_ERROR = 'GET_NOTIFICATION_BY_QUERY_ERROR';

export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const READ_NOTIFICATION_SUCCESS = 'READ_NOTIFICATION_SUCCESS';
export const READ_NOTIFICATION_ERROR = 'READ_NOTIFICATION_ERROR';

export const VIEW_NOTIFICATION = 'VIEW_NOTIFICATION';
export const VIEW_NOTIFICATION_SUCCESS = 'VIEW_NOTIFICATION_SUCCESS';
export const VIEW_NOTIFICATION_ERROR = 'VIEW_NOTIFICATION_ERROR';

export const DISMISS_ALL_NOTIFICATION = 'DISMISS_ALL_NOTIFICATION';
