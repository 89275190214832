// TASK_MANGEMENT
export const TASK_MANGEMENT_GET_BY_ID = 'TASK_MANGEMENT_GET_BY_ID';
export const TASK_MANGEMENT_GET_BY_ID_SUCCESS = 'TASK_MANGEMENT_GET_BY_ID_SUCCESS';
export const TASK_MANGEMENT_GET_BY_ID_ERROR = 'TASK_MANGEMENT_GET_BY_ID_ERROR';

export const TASK_MANGEMENT_GET_LIST = 'TASK_MANGEMENT_GET_LIST';
export const TASK_MANGEMENT_GET_LIST_SUCCESS = 'TASK_MANGEMENT_GET_LIST_SUCCESS';
export const TASK_MANGEMENT_GET_LIST_ERROR = 'TASK_MANGEMENT_GET_LIST_ERROR';

export const TASK_MANGEMENT_EDIT = 'TASK_MANGEMENT_EDIT';
export const TASK_MANGEMENT_EDIT_SUCCESS = 'TASK_MANGEMENT_EDIT_SUCCESS';
export const TASK_MANGEMENT_EDIT_ERROR = 'TASK_MANGEMENT_EDIT_ERROR';

export const TASK_MANGEMENT_ADD_NEW = 'TASK_MANGEMENT_ADD_NEW';
export const TASK_MANGEMENT_ADD_NEW_SUCCESS = 'TASK_MANGEMENT_ADD_NEW_SUCCESS';
export const TASK_MANGEMENT_ADD_NEW_ERROR = 'TASK_MANGEMENT_ADD_NEW_ERROR';
export const TASK_MANGEMENT_ADD_NEW_REQUEST = 'TASK_MANGEMENT_ADD_NEW_REQUEST';

export const TASK_MANGEMENT_DELETE = 'TASK_MANGEMENT_DELETE';
export const TASK_MANGEMENT_DELETE_SUCCESS = 'TASK_MANGEMENT_DELETE_SUCCESS';
export const TASK_MANGEMENT_DELETE_ERROR = 'TASK_MANGEMENT_DELETE_ERROR';

export const TASK_MANGEMENT_GET_BY_QUERY = 'TASK_MANGEMENT_GET_BY_QUERY';
export const TASK_MANGEMENT_GET_BY_QUERY_SUCCESS = 'TASK_MANGEMENT_GET_BY_QUERY_SUCCESS';
export const TASK_MANGEMENT_GET_BY_QUERY_ERROR = 'TASK_MANGEMENT_GET_BY_QUERY_ERROR';

export const TASK_MANGEMENT_STATUS_CHANGE = 'TASK_MANGEMENT_STATUS_CHANGE';
export const TASK_MANGEMENT_STATUS_CHANGE_SUCCESS = 'TASK_MANGEMENT_STATUS_CHANGE_SUCCESS';
export const TASK_MANGEMENT_STATUS_CHANGE_ERROR = 'TASK_MANGEMENT_STATUS_CHANGE_ERROR';
