// INTERVENTION_FOLLOWUP_IES

export const INTERVENTION_FOLLOWUP_IES_GET_BY_ID = 'INTERVENTION_FOLLOWUP_IES_GET_BY_ID';
export const INTERVENTION_FOLLOWUP_IES_GET_BY_ID_SUCCESS =
  'INTERVENTION_FOLLOWUP_IES_GET_BY_ID_SUCCESS';
export const INTERVENTION_FOLLOWUP_IES_GET_BY_ID_ERROR =
  'INTERVENTION_FOLLOWUP_IES_GET_BY_ID_ERROR';

export const INTERVENTION_FOLLOWUP_IES_GET_LIST = 'INTERVENTION_FOLLOWUP_IES_GET_LIST';
export const INTERVENTION_FOLLOWUP_IES_GET_LIST_SUCCESS =
  'INTERVENTION_FOLLOWUP_IES_GET_LIST_SUCCESS';
export const INTERVENTION_FOLLOWUP_IES_GET_LIST_ERROR = 'INTERVENTION_FOLLOWUP_IES_GET_LIST_ERROR';

export const INTERVENTION_FOLLOWUP_IES_EDIT = 'INTERVENTION_FOLLOWUP_IES_EDIT';
export const INTERVENTION_FOLLOWUP_IES_EDIT_SUCCESS = 'INTERVENTION_FOLLOWUP_IES_EDIT_SUCCESS';
export const INTERVENTION_FOLLOWUP_IES_EDIT_ERROR = 'INTERVENTION_FOLLOWUP_IES_EDIT_ERROR';
