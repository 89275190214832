import {
  ACTION_PLAN_ADD_NEW,
  ACTION_PLAN_ADD_NEW_SUCCESS,
  ACTION_PLAN_ADD_NEW_ERROR,
  ACTION_PLAN_DELETE,
  ACTION_PLAN_DELETE_ERROR,
  ACTION_PLAN_DELETE_SUCCESS,
  ACTION_PLAN_EDIT,
  ACTION_PLAN_EDIT_ERROR,
  ACTION_PLAN_EDIT_SUCCESS,
  ACTION_PLAN_GET_BY_ID,
  ACTION_PLAN_GET_BY_ID_ERROR,
  ACTION_PLAN_GET_BY_ID_SUCCESS,
  ACTION_PLAN_GET_LIST,
  ACTION_PLAN_GET_LIST_ERROR,
  ACTION_PLAN_GET_LIST_SUCCESS,
  ACTION_PLAN_ADD_NEW_REQUEST,
  NOTIFICATION_GET_LIST_SUCCESS,
  ACTION_PLAN_STATUS_CHANGE,
  ACTION_PLAN_STATUS_CHANGE_SUCCESS,
  ACTION_PLAN_STATUS_CHANGE_ERROR,
} from '../actions';

const initialState = {
  actionPlans: [],
  actionPlan: null,
  notifications: [],
  loading: false,
  error: null,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTION_PLAN_GET_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case ACTION_PLAN_STATUS_CHANGE:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case ACTION_PLAN_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        actionPlans: state.actionPlans.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        actionPlan: payload,
        error: null,
        loading: false,
      };
    case ACTION_PLAN_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        actionPlan: payload,
      };

    case ACTION_PLAN_ADD_NEW_REQUEST:
      return {
        ...state,
        addSuccess: false,
      };
    case ACTION_PLAN_GET_LIST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ACTION_PLAN_GET_LIST_SUCCESS:
      return {
        ...state,
        actionPlans: payload,
        loading: false,
      };
    case ACTION_PLAN_ADD_NEW:
      return {
        ...state,
        loading: true,
        error: null,
        addSuccess: false,
      };

    case ACTION_PLAN_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        actionPlan: payload,
        actionPlans: state.actionPlans.concat(payload),
        addSuccess: true,
      };

    case NOTIFICATION_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: payload,
        addSuccess: true,
      };

    case ACTION_PLAN_EDIT:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case ACTION_PLAN_EDIT_SUCCESS:
      return {
        ...state,
        actionPlans: state.actionPlans
          .filter(a =>
            a.id === payload.id && a.servicePlanHeaderId !== payload.servicePlanHeaderId
              ? false
              : true
          )
          .map(c => {
            return c.id === payload.id ? payload : c;
          }),
        actionPlan: payload,
        error: null,
        loading: false,
        editSuccess: true,
      };
    case ACTION_PLAN_DELETE:
      return {
        ...state,
        loading: true,
        error: null,
        deleteSuccess: false,
      };
    case ACTION_PLAN_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        actionPlans: state.actionPlans.filter(c => c.id !== payload),
        deleteSuccess: true,
      };

    case ACTION_PLAN_DELETE_ERROR:
    case ACTION_PLAN_ADD_NEW_ERROR:
    case ACTION_PLAN_EDIT_ERROR:
    case ACTION_PLAN_GET_BY_ID_ERROR:
    case ACTION_PLAN_GET_LIST_ERROR:
    case ACTION_PLAN_STATUS_CHANGE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
