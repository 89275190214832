import {
  STAKE_HOLDER_ACTION_ADD_NEW,
  STAKE_HOLDER_ACTION_ADD_NEW_ERROR,
  STAKE_HOLDER_ACTION_ADD_NEW_SUCCESS,
  STAKE_HOLDER_ACTION_CHANGE_STATUS,
  STAKE_HOLDER_ACTION_CHANGE_STATUS_ERROR,
  STAKE_HOLDER_ACTION_CHANGE_STATUS_SUCCESS,
  STAKE_HOLDER_ACTION_DELETE,
  STAKE_HOLDER_ACTION_DELETE_ERROR,
  STAKE_HOLDER_ACTION_DELETE_SUCCESS,
  STAKE_HOLDER_ACTION_GET_BY_ID,
  STAKE_HOLDER_ACTION_GET_BY_ID_ERROR,
  STAKE_HOLDER_ACTION_GET_BY_ID_SUCCESS,
  STAKE_HOLDER_ACTION_GET_PAGING,
  STAKE_HOLDER_ACTION_GET_PAGING_ERROR,
  STAKE_HOLDER_ACTION_GET_PAGING_SUCCESS,
  STAKE_HOLDER_ACTION_UPDATE,
  STAKE_HOLDER_ACTION_UPDATE_ERROR,
  STAKE_HOLDER_ACTION_UPDATE_SUCCESS,
} from './types';

export const getPagingStakeHolderAction = items => ({
  type: STAKE_HOLDER_ACTION_GET_PAGING,
  payload: items,
});

export const getPagingStakeHolderActionSuccess = items => ({
  type: STAKE_HOLDER_ACTION_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingStakeHolderActionError = error => ({
  type: STAKE_HOLDER_ACTION_GET_PAGING_ERROR,
  payload: error,
});

export const addStakeHolderAction = items => ({
  type: STAKE_HOLDER_ACTION_ADD_NEW,
  payload: items,
});

export const addStakeHolderActionSuccess = items => ({
  type: STAKE_HOLDER_ACTION_ADD_NEW_SUCCESS,
  payload: items,
});

export const addStakeHolderActionError = error => ({
  type: STAKE_HOLDER_ACTION_ADD_NEW_ERROR,
  payload: error,
});

export const getStakeHolderActionById = items => ({
  type: STAKE_HOLDER_ACTION_GET_BY_ID,
  payload: items,
});

export const getStakeHolderActionByIdSuccess = items => ({
  type: STAKE_HOLDER_ACTION_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getStakeHolderActionByIdError = error => ({
  type: STAKE_HOLDER_ACTION_GET_BY_ID_ERROR,
  payload: error,
});

export const updateStakeHolderAction = items => ({
  type: STAKE_HOLDER_ACTION_UPDATE,
  payload: items,
});

export const updateStakeHolderActionSuccess = items => ({
  type: STAKE_HOLDER_ACTION_UPDATE_SUCCESS,
  payload: items,
});

export const updateStakeHolderActionError = error => ({
  type: STAKE_HOLDER_ACTION_UPDATE_ERROR,
  payload: error,
});

export const changeStatusStakeHolderAction = items => ({
  type: STAKE_HOLDER_ACTION_CHANGE_STATUS,
  payload: items,
});

export const changeStatusStakeHolderActionSuccess = items => ({
  type: STAKE_HOLDER_ACTION_CHANGE_STATUS_SUCCESS,
  payload: items,
});

export const changeStatusStakeHolderActionError = error => ({
  type: STAKE_HOLDER_ACTION_CHANGE_STATUS_ERROR,
  payload: error,
});

export const deleteStakeHolderAction = items => ({
  type: STAKE_HOLDER_ACTION_DELETE,
  payload: items,
});

export const deleteStakeHolderActionSuccess = items => ({
  type: STAKE_HOLDER_ACTION_DELETE_SUCCESS,
  payload: items,
});

export const deleteStakeHolderActionError = error => ({
  type: STAKE_HOLDER_ACTION_DELETE_ERROR,
  payload: error,
});
