export const ESUITE_ACTIVITY_APPROVE = 'ESUITE_ACTIVITY_APPROVE';
export const ESUITE_ACTIVITY_APPROVE_SUCCESS = 'ESUITE_ACTIVITY_APPROVE_SUCCESS';
export const ESUITE_ACTIVITY_APPROVE_ERROR = 'ESUITE_ACTIVITY_APPROVE_ERROR';

export const ESUITE_ACTIVITY_REJECT = 'ESUITE_ACTIVITY_REJECT';
export const ESUITE_ACTIVITY_REJECT_SUCCESS = 'ESUITE_ACTIVITY_REJECT_SUCCESS';
export const ESUITE_ACTIVITY_REJECT_ERROR = 'ESUITE_ACTIVITY_REJECT_ERROR';

export const ESUITE_ACTIVITY_GET_BY_QUERY = 'ESUITE_ACTIVITY_GET_BY_QUERY';
export const ESUITE_ACTIVITY_GET_BY_QUERY_SUCCESS = 'ESUITE_ACTIVITY_GET_BY_QUERY_SUCCESS';
export const ESUITE_ACTIVITY_GET_BY_QUERY_ERROR = 'ESUITE_ACTIVITY_GET_BY_QUERY_ERROR';

export const ESUITE_ACTIVITY_SAVE_AND_APPROVE = 'ESUITE_ACTIVITY_SAVE_AND_APPROVE';
export const ESUITE_ACTIVITY_SAVE_AND_APPROVE_SUCCESS = 'ESUITE_ACTIVITY_SAVE_AND_APPROVE_SUCCESS';
export const ESUITE_ACTIVITY_SAVE_AND_APPROVE_ERROR = 'ESUITE_ACTIVITY_SAVE_AND_APPROVE_ERROR';

export const CLEAR_ESUITE_ACTIVITY_MESSAGE = 'CLEAR_ESUITE_ACTIVITY_MESSAGE';
