import {
  CP_REFERRER_ADD_NEW_REQUEST,
  CP_REFERRER_ADD_NEW_REQUEST_SUCCESS,
  CP_REFERRER_ADD_NEW_REQUEST_ERROR,
  GET_CP_REFERRER_LIST,
  GET_CP_REFERRER_LIST_SUCCESS,
  GET_CP_REFERRER_BY_ID,
  GET_CP_REFERRER_BY_ID_SUCCESS,
  GET_CP_REFERRER_BY_ID_ERROR,
  GET_SP_LIST_ERROR,
  GET_SP_LIST,
  GET_SP_LIST_SUCCESS,
  CP_REFERRER_EDIT,
} from './types';
import { NotificationManager } from '../../components/common/react-notifications';

const initialState = {
  item: null,
  newItemCount: 0,
  items: [],
  loading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
  total: 0,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CP_REFERRER_ADD_NEW_REQUEST:
    case CP_REFERRER_EDIT:
      return {
        ...state,
        loading: true,
        addSuccess: false,
      };
    case CP_REFERRER_ADD_NEW_REQUEST_SUCCESS:
      NotificationManager.success(
        `Congratulations! Your referral has been successfully created and sent to your employment agency partner.`,
        `Community Partner Referrer`,
        5000,
        null,
        null,
        ''
      );
      return {
        ...state,
        loading: false,
        addSuccess: true,
      };
    case GET_SP_LIST:
    case GET_CP_REFERRER_BY_ID:
    case GET_CP_REFERRER_LIST:
      return {
        ...state,
        loading: true,
        payload,
      };
    case GET_SP_LIST_ERROR:
    case GET_CP_REFERRER_BY_ID_ERROR:
    case CP_REFERRER_ADD_NEW_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        addSuccess: false,
      };
    case GET_CP_REFERRER_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        item: payload,
      };
    }
    case GET_SP_LIST_SUCCESS:
    case GET_CP_REFERRER_LIST_SUCCESS:
      const { itemCount, pageCount, total, data, newItemCount } = payload;
      return {
        ...state,
        loading: false,
        items: data,
        newItemCount,
        total,
        itemCount,
        pageCount,
      };
    default:
      return { ...state };
  }
};
