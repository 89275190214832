import {
  ES_CASE_NOTE_ADD_NEW,
  ES_CASE_NOTE_ADD_NEW_SUCCESS,
  ES_CASE_NOTE_ADD_NEW_ERROR,
  ES_CASE_NOTE_DELETE,
  ES_CASE_NOTE_DELETE_ERROR,
  ES_CASE_NOTE_DELETE_SUCCESS,
  ES_CASE_NOTE_EDIT,
  ES_CASE_NOTE_EDIT_ERROR,
  ES_CASE_NOTE_EDIT_SUCCESS,
  ES_CASE_NOTE_GET_BY_ID,
  ES_CASE_NOTE_GET_BY_ID_ERROR,
  ES_CASE_NOTE_GET_BY_ID_SUCCESS,
  ES_CASE_NOTE_GET_LIST,
  ES_CASE_NOTE_GET_LIST_ERROR,
  ES_CASE_NOTE_GET_LIST_SUCCESS,
  ES_CASE_NOTE_ADD_NEW_REQUEST,
  ES_CASE_NOTE_QUERY,
  ES_CASE_NOTE_QUERY_SUCCESS,
  ES_CASE_NOTE_QUERY_ERROR,
} from '../actions';

// ESCaseNote
export const getESCaseNoteQuery = query => ({
  type: ES_CASE_NOTE_QUERY,
  payload: query,
});

export const getESCaseNoteQuerySuccess = caseNotes => ({
  type: ES_CASE_NOTE_QUERY_SUCCESS,
  payload: caseNotes,
});

export const getESCaseNoteQueryError = error => ({
  type: ES_CASE_NOTE_QUERY_ERROR,
  payload: error,
});

export const getESCaseNoteList = clientId => ({
  type: ES_CASE_NOTE_GET_LIST,
  payload: clientId,
});

export const getESCaseNoteListSuccess = CaseNotes => ({
  type: ES_CASE_NOTE_GET_LIST_SUCCESS,
  payload: CaseNotes,
});

export const getESCaseNoteListError = error => ({
  type: ES_CASE_NOTE_GET_LIST_ERROR,
  payload: error,
});

export const addNewESCaseNote = caseNote => ({
  type: ES_CASE_NOTE_ADD_NEW,
  payload: caseNote,
});

export const addNewESCaseNoteRequest = () => ({
  type: ES_CASE_NOTE_ADD_NEW_REQUEST,
});

export const addNewESCaseNoteSuccess = CaseNote => ({
  type: ES_CASE_NOTE_ADD_NEW_SUCCESS,
  payload: CaseNote,
});

export const addNewESCaseNoteError = error => ({
  type: ES_CASE_NOTE_ADD_NEW_ERROR,
  payload: error,
});

export const editESCaseNote = CaseNote => ({
  type: ES_CASE_NOTE_EDIT,
  payload: CaseNote,
});

export const editESCaseNoteSuccess = caseNote => ({
  type: ES_CASE_NOTE_EDIT_SUCCESS,
  payload: caseNote,
});

export const editESCaseNoteError = error => ({
  type: ES_CASE_NOTE_EDIT_ERROR,
  payload: error,
});

export const deleteESCaseNote = id => ({
  type: ES_CASE_NOTE_DELETE,
  payload: id,
});

export const deleteESCaseNoteSuccess = id => ({
  type: ES_CASE_NOTE_DELETE_SUCCESS,
  payload: id,
});

export const deleteESCaseNoteError = error => ({
  type: ES_CASE_NOTE_DELETE_ERROR,
  payload: error,
});

export const getESCaseNoteById = id => ({
  type: ES_CASE_NOTE_GET_BY_ID,
  payload: id,
});

export const getESCaseNoteByIdSuccess = caseNote => ({
  type: ES_CASE_NOTE_GET_BY_ID_SUCCESS,
  payload: caseNote,
});

export const getESCaseNoteByIdError = error => ({
  type: ES_CASE_NOTE_GET_BY_ID_ERROR,
  payload: error,
});
