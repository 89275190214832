import {
  CLIENT_REFERRAL_ADD_NEW,
  CLIENT_REFERRAL_ADD_NEW_SUCCESS,
  CLIENT_REFERRAL_ADD_NEW_ERROR,
  CLIENT_REFERRAL_EDIT,
  CLIENT_REFERRAL_EDIT_ERROR,
  CLIENT_REFERRAL_EDIT_SUCCESS,
  CLIENT_REFERRAL_GET_BY_QUERY,
  CLIENT_REFERRAL_GET_BY_ID,
  CLIENT_REFERRAL_GET_BY_QUERY_SUCCESS,
  CLIENT_REFERRAL_GET_BY_QUERY_ERROR,
  CLIENT_REFERRAL_GET_BY_ID_SUCCESS,
  CLIENT_REFERRAL_ADD_NEW_REQUEST,
  CLIENT_REFERRAL_DELETE,
  CLIENT_REFERRAL_DELETE_SUCCESS,
  CLIENT_REFERRAL_DELETE_ERROR,
  CLIENT_REFERRAL_GET_BY_ID_ERROR,
} from './types';

// ClientReferral

export const addNewClientReferral = items => ({
  type: CLIENT_REFERRAL_ADD_NEW,
  payload: items,
});

export const addNewClientReferralRequest = () => ({
  type: CLIENT_REFERRAL_ADD_NEW_REQUEST,
});

export const addNewClientReferralSuccess = items => ({
  type: CLIENT_REFERRAL_ADD_NEW_SUCCESS,
  payload: items,
});

export const addNewClientReferralError = error => ({
  type: CLIENT_REFERRAL_ADD_NEW_ERROR,
  payload: error,
});

export const editClientReferral = item => ({
  type: CLIENT_REFERRAL_EDIT,
  payload: item,
});

export const editClientReferralSuccess = item => ({
  type: CLIENT_REFERRAL_EDIT_SUCCESS,
  payload: item,
});

export const editClientReferralError = error => ({
  type: CLIENT_REFERRAL_EDIT_ERROR,
  payload: error,
});

export const getClientReferralByQuery = query => ({
  type: CLIENT_REFERRAL_GET_BY_QUERY,
  payload: query,
});

export const getClientReferralByQuerySuccess = item => ({
  type: CLIENT_REFERRAL_GET_BY_QUERY_SUCCESS,
  payload: item,
});

export const getClientReferralByQueryError = error => ({
  type: CLIENT_REFERRAL_GET_BY_QUERY_ERROR,
  payload: error,
});

export const getClientReferralById = id => ({
  type: CLIENT_REFERRAL_GET_BY_ID,
  payload: id,
});

export const getClientReferralByIdSuccess = item => ({
  type: CLIENT_REFERRAL_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getClientReferralByIdError = error => ({
  type: CLIENT_REFERRAL_GET_BY_ID_ERROR,
  payload: error,
});

export const deleteClientReferralById = id => ({
  type: CLIENT_REFERRAL_DELETE,
  payload: id,
});

export const deleteClientReferralByIdSuccess = item => ({
  type: CLIENT_REFERRAL_DELETE_SUCCESS,
  payload: item,
});

export const deleteClientReferralByIdErrorr = item => ({
  type: CLIENT_REFERRAL_DELETE_ERROR,
  payload: item,
});
