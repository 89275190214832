import { SEVERITY } from '../../helpers/Utils';
import {
  CLEAR_ESUITE_ACTIVITY_MESSAGE,
  ESUITE_ACTIVITY_REJECT_SUCCESS,
  ESUITE_ACTIVITY_APPROVE_SUCCESS,
  ESUITE_ACTIVITY_GET_BY_QUERY,
  ESUITE_ACTIVITY_GET_BY_QUERY_SUCCESS,
  ESUITE_ACTIVITY_SAVE_AND_APPROVE_SUCCESS,
} from './types';
// item
const initialState = {
  item: null,
  items: [],
  pendingCount: 0,
  secondItems: [],
  loading: false,
  itemCount: 0,
  pageCount: 0,
  message: null,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ESUITE_ACTIVITY_GET_BY_QUERY:
      // case ESUITE_ACTIVITY_APPROVE:
      // case ESUITE_ACTIVITY_REJECT:
      return {
        ...state,
        loading: true,
        item: null,
      };

    case ESUITE_ACTIVITY_GET_BY_QUERY_SUCCESS:
      const { itemCount, pageCount, pendingCount, data } = payload;
      return {
        ...state,
        loading: false,
        items: data,
        itemCount,
        pageCount,
        pendingCount,
      };
    case CLEAR_ESUITE_ACTIVITY_MESSAGE:
      return {
        ...state,
        loading: false,
        message: null,
      };
    case ESUITE_ACTIVITY_REJECT_SUCCESS:
      return {
        ...state,
        // loading: false,
        items: payload.data,
        itemCount: payload.itemCount,
        pageCount: payload.pageCount,
        message: {
          title: 'Posting Publish Request',
          message: 'The posting publish request has been rejected.',
          severity: SEVERITY.DANGER,
        },
      };
    case ESUITE_ACTIVITY_APPROVE_SUCCESS:
      return {
        ...state,
        items: payload.data,
        itemCount: payload.itemCount,
        pageCount: payload.pageCount,
        message: {
          title: 'Posting Publish Request',
          message: 'Job posting Publish request has been approved.',
          severity: SEVERITY.SUCCESS,
        },
      };

    case ESUITE_ACTIVITY_SAVE_AND_APPROVE_SUCCESS:
      return {
        ...state,
        items: payload.data,
        itemCount: payload.itemCount,
        pageCount: payload.pageCount,
        message: {
          title: 'Posting Publish Request',
          message: 'Job posting Publish request has been approved.',
          severity: SEVERITY.SUCCESS,
        },
      };

    default:
      return { ...state };
  }
}
