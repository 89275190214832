import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  MILEAGE_SETTING_DELETE,
  MILEAGE_SETTING_EDIT,
  MILEAGE_SETTING_GET_BY_ID,
  MILEAGE_SETTING_ADD_NEW,
  MILEAGE_SETTING_GET_LIST,
} from '../actions';

import {
  getMileageSettingListSuccess,
  getMileageSettingListError,
  editMileageSettingSuccess,
  editMileageSettingError,
  addNewMileageSettingSuccess,
  addNewMileageSettingError,
  deleteMileageSettingSuccess,
  deleteMileageSettingError,
  getMileageSettingByIdSuccess,
} from './actions';

const getMileageSettingListRequest = async () => {
  return axios.get(`${Config.apiServerHost}/api/Mileage/setting`, await authHeader());
};

const addNewMileageSettingReqeust = async MileageSetting => {
  return axios.post(
    `${Config.apiServerHost}/api/Mileage/setting`,
    MileageSetting,
    await authHeader()
  );
};

const editMileageSettingRequest = async MileageSetting => {
  return axios.put(
    `${Config.apiServerHost}/api/Mileage/setting`,
    MileageSetting,
    await authHeader()
  );
};

const deleteMileageSettingRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/Mileage/setting/${id}`, await authHeader());
};

const getMileageSettingByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/Mileage/setting/${id}`, await authHeader());
};

function* getMileageSettingList() {
  try {
    const response = yield call(getMileageSettingListRequest);
    yield put(getMileageSettingListSuccess(response.data));
  } catch (error) {
    yield put(getMileageSettingListError(error.response.statusText));
  }
}

function* addNewMileageSetting({ payload }) {
  try {
    const { MileageSetting } = payload;
    let response = yield call(addNewMileageSettingReqeust, MileageSetting);
    response = yield call(getMileageSettingByIdRequest, response.data);
    yield put(addNewMileageSettingSuccess(response.data));
  } catch (error) {
    yield put(addNewMileageSettingError(error.response.statusText));
  }
}

function* editMileageSetting({ payload }) {
  try {
    const { MileageSetting } = payload;
    let response = yield call(editMileageSettingRequest, MileageSetting);
    response = yield call(getMileageSettingByIdRequest, MileageSetting.id);
    yield put(editMileageSettingSuccess(response.data));
  } catch (error) {
    yield put(editMileageSettingError(error.response.statusText));
  }
}

function* getMileageSettingById({ payload }) {
  try {
    const response = yield call(getMileageSettingByIdRequest, payload);
    yield put(getMileageSettingByIdSuccess(response.data));
  } catch (error) {
    yield put(editMileageSettingError(error.response.statusText));
  }
}

function* deleteMileageSetting({ payload }) {
  try {
    const response = yield call(deleteMileageSettingRequest, payload);
    yield put(deleteMileageSettingSuccess(response.data));
  } catch (error) {
    yield put(deleteMileageSettingError(error.response.statusText));
  }
}

export function* watchMileageSettingList() {
  yield takeEvery(MILEAGE_SETTING_GET_LIST, getMileageSettingList);
}

export function* watchAddNewMileageSetting() {
  yield takeEvery(MILEAGE_SETTING_ADD_NEW, addNewMileageSetting);
}

export function* watchMileageSettingById() {
  yield takeEvery(MILEAGE_SETTING_GET_BY_ID, getMileageSettingById);
}

export function* watchEditMileageSetting() {
  yield takeEvery(MILEAGE_SETTING_EDIT, editMileageSetting);
}

export function* watchDeleteMileageSetting() {
  yield takeEvery(MILEAGE_SETTING_DELETE, deleteMileageSetting);
}

export default function* rootSaga() {
  yield all([
    fork(watchMileageSettingList),
    fork(watchAddNewMileageSetting),
    fork(watchMileageSettingById),
    fork(watchEditMileageSetting),
    fork(watchDeleteMileageSetting),
  ]);
}
