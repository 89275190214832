// TRF_CA_REFERRAL
export const TRF_CA_REFERRAL_ADD_NEW = 'TRF_CA_REFERRAL_ADD_NEW';
export const TRF_CA_REFERRAL_ADD_NEW_SUCCESS = 'TRF_CA_REFERRAL_ADD_NEW_SUCCESS';
export const TRF_CA_REFERRAL_ADD_NEW_ERROR = 'TRF_CA_REFERRAL_ADD_NEW_ERROR';

export const TRF_CA_REFERRAL_GET_PAGING = 'TRF_CA_REFERRAL_GET_PAGING';
export const TRF_CA_REFERRAL_GET_PAGING_SUCCESS = 'TRF_CA_REFERRAL_GET_PAGING_SUCCESS';
export const TRF_CA_REFERRAL_GET_PAGING_ERROR = 'TRF_CA_REFERRAL_GET_PAGING_ERROR';

export const TRF_CA_REFERRAL_GET_BY_ID = 'TRF_CA_REFERRAL_GET_BY_ID';
export const TRF_CA_REFERRAL_GET_BY_ID_SUCCESS = 'TRF_CA_REFERRAL_GET_BY_ID_SUCCESS';
export const TRF_CA_REFERRAL_GET_BY_ID_ERROR = 'TRF_CA_REFERRAL_GET_BY_ID_ERROR';

export const TRF_CA_REFERRAL_UPDATE = 'TRF_CA_REFERRAL_UPDATE';
export const TRF_CA_REFERRAL_UPDATE_SUCCESS = 'TRF_CA_REFERRAL_UPDATE_SUCCESS';
export const TRF_CA_REFERRAL_UPDATE_ERROR = 'TRF_CA_REFERRAL_UPDATE_ERROR';

export const TRF_CA_REFERRAL_CHANGE_STATUS = 'TRF_CA_REFERRAL_CHANGE_STATUS';
export const TRF_CA_REFERRAL_CHANGE_STATUS_SUCCESS = 'TRF_CA_REFERRAL_CHANGE_STATUS_SUCCESS';
export const TRF_CA_REFERRAL_CHANGE_STATUS_ERROR = 'TRF_CA_REFERRAL_CHANGE_STATUS_ERROR';

export const TRF_CA_REFERRAL_DELETE = 'TRF_CA_REFERRAL_DELETE';
export const TRF_CA_REFERRAL_DELETE_SUCCESS = 'TRF_CA_REFERRAL_DELETE_SUCCESS';
export const TRF_CA_REFERRAL_DELETE_ERROR = 'TRF_CA_REFERRAL_DELETE_ERROR';

export const TRF_CA_REFERRAL_ASSIGN_REFERRAL = 'TRF_CA_REFERRAL_ASSIGN_REFERRAL';
export const TRF_CA_REFERRAL_ASSIGN_REFERRAL_SUCCESS = 'TRF_CA_REFERRAL_ASSIGN_REFERRAL_SUCCESS';
export const TRF_CA_REFERRAL_ASSIGN_REFERRAL_ERROR = 'TRF_CA_REFERRAL_ASSIGN_REFERRAL_ERROR';
