import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  ESUITE_ADD_NEW,
  ESUITE_EDIT,
  ESUITE_GET_BY_QUERY,
  ESUITE_GET_BY_ID,
  ESUITE_DELETE,
} from './types';

import {
  addNewESuiteError,
  editESuiteError,
  getESuiteByQuerySuccess,
  getESuiteByQueryError,
  getESuiteByIdError,
  getESuiteByIdSuccess,
  deleteESuiteByIdErrorr,
} from './actions';

const addNewESuiteReqeust = async items => {
  return axios.post(`${Config.apiServerHost}/api/EmployerSuite`, items, await authHeader());
};

const editESuiteRequest = async items => {
  return axios.put(`${Config.apiServerHost}/api/EmployerSuite`, items, await authHeader());
};

const getESuiteByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/EmployerSuite/${id}`, await authHeader());
};

const getESuiteByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/EmployerSuite/fetch`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getApproveConnectionRequest = async (requestId, employerId) => {
  return axios.get(
    `${Config.apiServerHost}/api/EmployerSuite/approve/${requestId}/employer/${employerId}`,
    await authHeader()
  );
};

const getRejectConnectionRequest = async (requestId, employerId) => {
  return axios.get(
    `${Config.apiServerHost}/api/EmployerSuite/reject/${requestId}`,
    await authHeader()
  );
};

const getESuitePostingRequestByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/EmployerSuite/posting-request`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getESuitePostingRequestByRequestIdRequest = async requestId => {
  return axios.get(
    `${Config.apiServerHost}/api/EmployerSuite/posting-request/view/${requestId}`,
    await authHeader()
  );
};

const getApprovePostingRequest = async requestId => {
  return axios.get(
    `${Config.apiServerHost}/api/EmployerSuite/posting-request/approve/${requestId}`,
    await authHeader()
  );
};

const getRejectPostingRequest = async requestId => {
  return axios.get(
    `${Config.apiServerHost}/api/EmployerSuite/posting-request/reject/${requestId}`,
    await authHeader()
  );
};

const deleteESuiteRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/EmployerSuite/${id}`, await authHeader());
};
function* addNewESuite({ payload }) {
  try {
    const { clientId, programId, servicePlanHeaderId, data } = payload;
    const query = {
      clientId,
      programId,
      servicePlanHeaderId,
    };

    let response = yield call(addNewESuiteReqeust, data);
    response = yield call(getESuiteByQueryRequest, query);
    yield put(getESuiteByQuerySuccess(response.data));
  } catch (error) {
    yield put(addNewESuiteError(error.response.statusText));
  }
}

function* editESuite({ payload }) {
  try {
    const { clientId, programId, servicePlanHeaderId, data } = payload;
    const query = {
      clientId,
      programId,
      servicePlanHeaderId,
    };

    let response = yield call(editESuiteRequest, data);
    response = yield call(getESuiteByQueryRequest, query);
    yield put(getESuiteByQuerySuccess(response.data));
  } catch (error) {
    yield put(editESuiteError(error.response.statusText));
  }
}

function* getESuiteByQuery({ payload }) {
  try {
    const response = yield call(getESuiteByQueryRequest, payload);
    yield put(getESuiteByQuerySuccess(response.data));
  } catch (error) {
    yield put(getESuiteByQueryError(error.response.statusText));
  }
}

function* getESuiteById({ payload }) {
  try {
    const response = yield call(getESuiteByIdRequest, payload);
    yield put(getESuiteByIdSuccess(response.data));
  } catch (error) {
    yield put(getESuiteByIdError(error.response.statusText));
  }
}

function* deleteESuite({ payload }) {
  try {
    const { programId, clientId, servicePlanHeaderId, data } = payload;
    const query = {
      programId,
      clientId,
      servicePlanHeaderId,
    };

    let response = yield call(deleteESuiteRequest, data);
    response = yield call(getESuiteByQueryRequest, query);
    yield put(getESuiteByQuerySuccess(response.data));
  } catch (error) {
    yield put(deleteESuiteByIdErrorr(error.response.statusText));
  }
}

export function* watchAddNewESuite() {
  yield takeEvery(ESUITE_ADD_NEW, addNewESuite);
}
export function* watchEditESuite() {
  yield takeEvery(ESUITE_EDIT, editESuite);
}

export function* watchGetESuiteByQuery() {
  yield takeEvery(ESUITE_GET_BY_QUERY, getESuiteByQuery);
}

export function* watchGetESuiteById() {
  yield takeEvery(ESUITE_GET_BY_ID, getESuiteById);
}

export function* watchDeleteESuite() {
  yield takeEvery(ESUITE_DELETE, deleteESuite);
}

export default function* rootSaga() {
  yield all([
    fork(watchAddNewESuite),
    fork(watchEditESuite),
    fork(watchGetESuiteByQuery),
    fork(watchDeleteESuite),
    fork(watchGetESuiteById),
  ]);
}

export {
  getApproveConnectionRequest,
  getRejectConnectionRequest,
  getESuitePostingRequestByQueryRequest,
  getESuitePostingRequestByRequestIdRequest,
  getApprovePostingRequest,
  getRejectPostingRequest,
};
