import {
  COJG_CONTRACT_GET_LIST,
  COJG_CONTRACT_GET_LIST_SUCCESS,
  COJG_CONTRACT_GET_LIST_ERROR,
  COJG_CONTRACT_GET_LIST_WITH_FILTER,
  COJG_CONTRACT_ADD_ITEM,
  COJG_CONTRACT_ADD_ITEM_SUCCESS,
  COJG_CONTRACT_ADD_ITEM_ERROR,
  COJG_CONTRACT_EDIT_ITEM,
  COJG_CONTRACT_EDIT_ITEM_SUCCESS,
  COJG_CONTRACT_EDIT_ITEM_ERROR,
  COJG_CONTRACT_SELECTED_ITEMS_CHANGE,
  COJG_CONTRACT_DELETE_ITEM,
  COJG_CONTRACT_DELETE_ITEM_SUCCESS,
  COJG_CONTRACT_DELETE_ITEM_ERROR,
  COJG_CONTRACT_GET_BY_ID,
  COJG_CONTRACT_GET_BY_ID_SUCCESS,
  COJG_CONTRACT_GET_BY_ID_ERROR,
  COJG_CONTRACT_GET_BY_EMPLOYER,
  COJG_CONTRACT_GET_BY_EMPLOYER_SUCCESS,
  COJG_CONTRACT_GET_BY_EMPLOYER_ERROR,
} from '../../actions';

const INIT_STATE = {
  allCOJGContractItems: [],
  cojgContractItems: [],
  cojgContract: null,
  error: '',
  filter: null,
  searchKeyword: '',
  orderColumn: null,
  loading: false,

  selectedItems: [],
};

export default (state = INIT_STATE, action) => {
  const { payload, type } = action;
  switch (type) {
    case COJG_CONTRACT_GET_BY_EMPLOYER:
      return { ...state, loading: false };

    case COJG_CONTRACT_GET_BY_EMPLOYER_SUCCESS:
      return {
        ...state,
        loading: true,
        cojgContractItems: payload,
      };

    case COJG_CONTRACT_EDIT_ITEM:
    case COJG_CONTRACT_GET_LIST:
      return { ...state, loading: false };

    case COJG_CONTRACT_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        allCOJGContractItems: payload,
        cojgContractItems: payload,
      };

    case COJG_CONTRACT_EDIT_ITEM_SUCCESS:
      return {
        ...state,
        loading: true,
        cojgContractItems: state.cojgContractItems.map(a => {
          return a.id === payload.id ? payload : a;
        }),
        cojgContract: payload,
      };

    case COJG_CONTRACT_GET_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case COJG_CONTRACT_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: true,
        cojgContract: payload,
      };

    case COJG_CONTRACT_GET_BY_ID_ERROR:
    case COJG_CONTRACT_DELETE_ITEM_ERROR:
    case COJG_CONTRACT_EDIT_ITEM_ERROR:
    case COJG_CONTRACT_GET_LIST_ERROR:
    case COJG_CONTRACT_GET_BY_EMPLOYER_ERROR:
      return { ...state, loading: true, error: action.payload };

    case COJG_CONTRACT_GET_LIST_WITH_FILTER:
      if (action.payload.column === '' || action.payload.value === '') {
        return {
          ...state,
          loading: true,
          cojgContractItems: state.allCOJGContractItems,
          filter: null,
        };
      } else {
        const filteredItems = state.allCOJGContractItems.filter(
          item => item[action.payload.column] === action.payload.value
        );
        return {
          ...state,
          loading: true,
          cojgContractItems: filteredItems,
          filter: {
            column: action.payload.column,
            value: action.payload.value,
          },
        };
      }

    case COJG_CONTRACT_ADD_ITEM:
      return { ...state, loading: false };

    case COJG_CONTRACT_ADD_ITEM_SUCCESS:
      return {
        ...state,
        loading: true,
        allCOJGContractItems: action.payload,
        cojgContractItems: action.payload,
      };

    case COJG_CONTRACT_ADD_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };

    case COJG_CONTRACT_SELECTED_ITEMS_CHANGE:
      return { ...state, loading: true, selectedItems: action.payload };
    default:
      return { ...state };

    case COJG_CONTRACT_DELETE_ITEM:
      return { ...state, loading: false };

    case COJG_CONTRACT_DELETE_ITEM_SUCCESS:
      return {
        ...state,
        loading: true,
        allCOJGContractItems: action.payload,
        cojgContractItems: action.payload,
      };
  }
};
