// JMPI_MATRIX
export const JMPI_MATRIX_GET_BY_ID = 'JMPI_MATRIX_GET_BY_ID';
export const JMPI_MATRIX_GET_BY_ID_SUCCESS = 'JMPI_MATRIX_GET_BY_ID_SUCCESS';
export const JMPI_MATRIX_GET_BY_ID_ERROR = 'JMPI_MATRIX_GET_BY_ID_ERROR';

export const JMPI_MATRIX_GET_LIST = 'JMPI_MATRIX_GET_LIST';
export const JMPI_MATRIX_GET_LIST_SUCCESS = 'JMPI_MATRIX_GET_LIST_SUCCESS';
export const JMPI_MATRIX_GET_LIST_ERROR = 'JMPI_MATRIX_GET_LIST_ERROR';

export const JMPI_MATRIX_EDIT = 'JMPI_MATRIX_EDIT';
export const JMPI_MATRIX_EDIT_SUCCESS = 'JMPI_MATRIX_EDIT_SUCCESS';
export const JMPI_MATRIX_EDIT_ERROR = 'JMPI_MATRIX_EDIT_ERROR';

export const JMPI_MATRIX_ADD_NEW = 'JMPI_MATRIX_ADD_NEW';
export const JMPI_MATRIX_ADD_NEW_SUCCESS = 'JMPI_MATRIX_ADD_NEW_SUCCESS';
export const JMPI_MATRIX_ADD_NEW_ERROR = 'JMPI_MATRIX_ADD_NEW_ERROR';
export const JMPI_MATRIX_ADD_NEW_REQUEST = 'JMPI_MATRIX_ADD_NEW_REQUEST';

export const JMPI_MATRIX_DELETE = 'JMPI_MATRIX_DELETE';
export const JMPI_MATRIX_DELETE_SUCCESS = 'JMPI_MATRIX_DELETE_SUCCESS';
export const JMPI_MATRIX_DELETE_ERROR = 'JMPI_MATRIX_DELETE_ERROR';

export const JMPI_MATRIX_GET_BY_QUERY = 'JMPI_MATRIX_GET_BY_QUERY';
export const JMPI_MATRIX_GET_BY_QUERY_SUCCESS = 'JMPI_MATRIX_GET_BY_QUERY_SUCCESS';
export const JMPI_MATRIX_GET_BY_QUERY_ERROR = 'JMPI_MATRIX_GET_BY_QUERY_ERROR';
