import {
  YJCS_CONTRACT_GET_LIST,
  YJCS_CONTRACT_GET_LIST_SUCCESS,
  YJCS_CONTRACT_GET_LIST_ERROR,
  YJCS_CONTRACT_GET_BY_ID,
  YJCS_CONTRACT_GET_BY_ID_SUCCESS,
  YJCS_CONTRACT_GET_BY_ID_ERROR,
  YJCS_CONTRACT_ADD_ITEM,
  YJCS_CONTRACT_ADD_ITEM_SUCCESS,
  YJCS_CONTRACT_ADD_ITEM_ERROR,
  YJCS_CONTRACT_EDIT_ITEM,
  YJCS_CONTRACT_EDIT_ITEM_SUCCESS,
  YJCS_CONTRACT_EDIT_ITEM_ERROR,
  YJCS_CONTRACT_DELETE_ITEM,
  YJCS_CONTRACT_DELETE_ITEM_SUCCESS,
  YJCS_CONTRACT_DELETE_ITEM_ERROR,
  YJCS_CONTRACT_GET_BY_EMPLOYER,
  YJCS_CONTRACT_GET_BY_EMPLOYER_SUCCESS,
  YJCS_CONTRACT_GET_BY_EMPLOYER_ERROR,
  YJCS_CONTRACT_GET_BY_CLIENT,
  YJCS_CONTRACT_GET_BY_CLIENT_SUCCESS,
  YJCS_CONTRACT_GET_BY_CLIENT_ERROR,
} from '../actions';

// YJCSContract

export const getYJCSContractByClient = query => ({
  type: YJCS_CONTRACT_GET_BY_CLIENT,
  payload: query,
});

export const getYJCSContractByClientSuccess = items => ({
  type: YJCS_CONTRACT_GET_BY_CLIENT_SUCCESS,
  payload: items,
});

export const getYJCSContractByClientError = error => ({
  type: YJCS_CONTRACT_GET_BY_CLIENT_ERROR,
  payload: error,
});

export const getYJCSContractByEmployer = query => ({
  type: YJCS_CONTRACT_GET_BY_EMPLOYER,
  payload: query,
});

export const getYJCSContractByEmployerSuccess = items => ({
  type: YJCS_CONTRACT_GET_BY_EMPLOYER_SUCCESS,
  payload: items,
});

export const getYJCSContractByEmployerError = error => ({
  type: YJCS_CONTRACT_GET_BY_EMPLOYER_ERROR,
  payload: error,
});

export const getYJCSContractList = filter => ({
  type: YJCS_CONTRACT_GET_LIST,
  payload: filter,
});

export const getYJCSContractListSuccess = items => ({
  type: YJCS_CONTRACT_GET_LIST_SUCCESS,
  payload: items,
});

export const getYJCSContractListError = error => ({
  type: YJCS_CONTRACT_GET_LIST_ERROR,
  payload: error,
});

export const getYJCSContractById = id => ({
  type: YJCS_CONTRACT_GET_BY_ID,
  payload: id,
});

export const getYJCSContractByIdSuccess = item => ({
  type: YJCS_CONTRACT_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getYJCSContractByIdError = error => ({
  type: YJCS_CONTRACT_GET_BY_ID_ERROR,
  payload: error,
});

export const addYJCSContractItem = (item, history) => ({
  type: YJCS_CONTRACT_ADD_ITEM,
  payload: { item, history },
});

export const addYJCSContractItemSuccess = items => ({
  type: YJCS_CONTRACT_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addYJCSContractItemError = error => ({
  type: YJCS_CONTRACT_ADD_ITEM_ERROR,
  payload: error,
});

export const editYJCSContractItem = (item, history) => ({
  type: YJCS_CONTRACT_EDIT_ITEM,
  payload: { item, history },
});

export const editYJCSContractItemSuccess = items => ({
  type: YJCS_CONTRACT_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editYJCSContractItemError = error => ({
  type: YJCS_CONTRACT_EDIT_ITEM_ERROR,
  payload: error,
});

export const deleteYJCSContractItem = item => ({
  type: YJCS_CONTRACT_DELETE_ITEM,
  payload: item,
});

export const deleteYJCSContractItemSuccess = items => ({
  type: YJCS_CONTRACT_DELETE_ITEM_SUCCESS,
  payload: items,
});

export const deleteYJCSContractItemError = error => ({
  type: YJCS_CONTRACT_DELETE_ITEM_ERROR,
  payload: error,
});
