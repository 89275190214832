import { logger } from '../../logger';
import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { NotificationManager } from '../../components/common/react-notifications';
import FileSaver from 'file-saver';
import {
  CLIENT_GET_LIST,
  CLIENT_GET_BY_ID,
  CLIENT_ADD_NEW,
  CLIENT_EDIT,
  CLIENT_DELETE,
  CLIENT_FILTER,
  CLIENT_MANAGER_VIEW_UPDATE,
  CLIENT_COMPLETED_RESULT,
  GENERATE_CLIENT_REPORT,
  CLIENT_FILE_ACIVITY_UPDATE,
  CLIENT_FILE_ACIVITY_HUB_UPDATE,
  GET_CLIENT_FILE_ACIVITY,
  GET_CLIENT_FILE_ACIVITY_HUB,
  SAVE_CLIENT_PARTICIPANT,
  SAVE_CLIENT_PARTICIPANT_ARI,
  SAVE_CLIENT_PARTICIPANT_HUB,
  SAVE_CLIENT_PARTICIPANT_LBS,
  GET_CLIENT_PARTICIPANT,
  GET_CLIENT_PARTICIPANT_HUB,
  SAVE_CLIENT_ASSESSMENT,
  SAVE_CLIENT_ASSESSMENT_LBS,
  SAVE_CLIENT_SPECIFIC_ASSESSMENT,
  GET_CLIENT_ASSESSMENT_LBS,
  GET_CLIENT_ASSESSMENT,
  GET_CLIENT_SPECIFIC_ASSESSMENT,
  CLIENT_ERROR_REPORT,
  GENERATE_PERFORMANCE_INDICATOR_REPORT,
  CLIENT_SAVE_LEARNER_PLAN,
  CLIENT_GET_LEARNER_PLAN,
  SAVE_STUDENT_CHECKLIST_LBS,
  GET_STUDENT_CHECKLIST_LBS,
  GET_CLIENT_REDIRECT,
  GET_CLIENT_SERVICEPLANS,
  ADD_CLIENT_SERVICEPLANS,
  GET_CLIENT_SERVICEPLAN,
  UPDATE_CLIENT_SERVICEPLAN,
  GET_CLIENT_PARTICIPANT_ARI,
  GET_CLIENT_PARTICIPANT_LBS,
  GET_CLIENT_KPI,
  CLIENT_SAVE_LEARNER_PLAN_LBS,
  GET_CLIENT_LEFT_SIDEBAR,
  SAVE_CLIENT_COMMENT,
} from '../actions';
import {
  getClientListSuccess,
  getClientByIdSuccess,
  getClientByIdError,
  getClientListError,
  addNewClientSuccess,
  addNewClientError,
  editClientSuccess,
  editClientError,
  deleteClientByIdSuccess,
  deleteClientByIdError,
  getClientFilterSuccess,
  getClientFilterError,
  generateClientReportSuccess,
  generateClientReportError,
  updateClientFileActivitySuccess,
  updateClientFileActivityError,
  updateClientFileActivityHubSuccess,
  updateClientFileActivityHubError,
  getClientFileActivitySuccess,
  getClientFileActivityError,
  getClientFileActivityHubSuccess,
  getClientFileActivityHubError,
  saveClientParticipantSuccess,
  saveClientParticipantHubSuccess,
  saveClientParticipantError,
  saveClientParticipantHubError,
  getClientParticipantSuccess,
  getClientParticipantError,
  getClientServicePlansSuccess,
  getClientServicePlansError,
  getClientParticipantHubSuccess,
  getClientParticipantHubError,
  saveClientAssessmentSuccess,
  saveClientAssessmentError,
  saveClientSpecificAssessmentSuccess,
  saveClientSpecificAssessmentError,
  getClientAssessmentSuccess,
  getClientAssessmentError,
  getClientSpecificAssessmentSuccess,
  getClientSpecificAssessmentError,
  generateClientErrorReportSuccess,
  generateClientErrorReportError,
  generatePerformanceIndicatorReportSuccess,
  generatePerformanceIndicatorReportError,
  saveClientLearnerPlanSuccess,
  saveClientLearnerPlanError,
  getClientLearnerPlanSuccess,
  getClientLearnerPlanError,
  getStudentCheckListLBSError,
  getStudentCheckListLBSSuccess,
  saveStudentCheckListLBSError,
  saveStudentCheckListLBSSuccess,
  getClientServicePlanSuccess,
  getClientServicePlanError,
  saveClientParticipantARISuccess,
  saveClientParticipantARIError,
  getClientParticipantARISuccess,
  getClientParticipantARIError,
  getClientAssessmentLBSError,
  getClientAssessmentLBSSuccess,
  saveClientAssessmentLBSSuccess,
  saveClientAssessmentLBSError,
  getClientParticipantLBSError,
  getClientParticipantLBSSuccess,
  saveClientParticipantLBSSuccess,
  saveClientParticipantLBSError,
  getClientKPISuccess,
  saveClientLearnerPlanLBSSuccess,
  saveClientLearnerPlanLBSError,
  getClientLeftSidebarSuccess,
  getClientLeftSidebarError,
  getClientRedirectError,
} from './actions';

const getClientLeftSidebarRequest = async query => {
  let url = `${Config.apiServerHost}/api/client/leftSidebar`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getClientLastContactRequest = async payload => {
  return await axios.post(
    `${Config.apiServerHost}/api/client/lastContactList`,
    payload,
    await authHeader()
  );
};

const exportClientLastContactXlsRequest = async (query, fileName) => {
  return axios
    .post(`${Config.apiServerHost}/api/client/exportClientLastContactXls`, query, {
      headers: { ...(await authHeader()).headers },
      responseType: 'blob',
    })
    .then(res => {
      if (res) {
        const { data } = res;
        FileSaver.saveAs(new Blob([data]), fileName);
      }
    });
};

const exportClientProfileByStageXlsRequest = async (query, fileName) => {
  return axios
    .post(`${Config.apiServerHost}/api/client/exportClientProfileByStageXls`, query, {
      headers: { ...(await authHeader()).headers },
      responseType: 'blob',
    })
    .then(res => {
      if (res) {
        const { data } = res;
        FileSaver.saveAs(new Blob([data]), fileName);
      }
    });
};

const exportClientProfileByStatusXlsRequest = async (query, fileName) => {
  return axios
    .post(`${Config.apiServerHost}/api/client/exportClientProfileByStatusXls`, query, {
      headers: { ...(await authHeader()).headers },
      responseType: 'blob',
    })
    .then(res => {
      if (res) {
        const { data } = res;
        FileSaver.saveAs(new Blob([data]), fileName);
      }
    });
};

const getClientProfileByStatusRequest = async payload => {
  return await axios.post(`${Config.apiServerHost}/api/client/clientProfileByStatus`, payload, {
    headers: { ...(await authHeader()).headers },
  });
};

const getClientProfileByStageRequest = async payload => {
  return await axios.post(`${Config.apiServerHost}/api/client/clientProfileByStage`, payload, {
    headers: { ...(await authHeader()).headers },
  });
};

const saveClientCommentRequest = async payload => {
  return await axios.post(
    `${Config.apiServerHost}/api/client/saveComment`,
    payload,
    await authHeader()
  );
};

const getManagerDashboard = async query => {
  let url = `${Config.apiServerHost}/api/client/managerDashBoard`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getManagerDashboardSetting = async query => {
  let url = `${Config.apiServerHost}/api/client/managerDashBoard/setting`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const updateManagerDashboardSetting = async data => {
  return await axios.post(
    `${Config.apiServerHost}/api/client/managerDashBoard/setting`,
    data,
    await authHeader()
  );
};

const getClientStreamPBFIndicatorRequest = async () => {
  return await axios.get(
    `${Config.apiServerHost}/api/client/streamPBFIndicator`,
    await authHeader()
  );
};

const sendPostingToClientRequest = async data => {
  return await axios.post(
    `${Config.apiServerHost}/api/Client/sendPostingToClient`,
    data,
    await authHeader()
  );
};

const updateClientStreamPBFIndicatorRequest = async payload => {
  return await axios.post(
    `${Config.apiServerHost}/api/client/streamPBFIndicator`,
    payload,
    await authHeader()
  );
};

const getPBFTReportRequest = async query => {
  return await axios.post(`${Config.apiServerHost}/api/client/pbft`, query, await authHeader());
};

const getMonthPBFTReportRequest = async id => {
  let url = `${Config.apiServerHost}/api/client/monthPBFT/${id}`;
  return await axios.get(url, await authHeader());
};

const updateMonthPBFTReportRequest = async payload => {
  return await axios.post(
    `${Config.apiServerHost}/api/client/monthPBFT`,
    payload,
    await authHeader()
  );
};

const quickCreateClientRequest = async client => {
  return await axios.post(
    `${Config.apiServerHost}/api/client/quickCreate`,
    client,
    await authHeader()
  );
};

const quickCreateClientWithServicePlanRequest = async client => {
  return await axios.post(
    `${Config.apiServerHost}/api/client/quickCreateWithServicePlan`,
    client,
    await authHeader()
  );
};

const getAssignClientAuditLogRequest = async query => {
  let url = `${Config.apiServerHost}/api/AssignClientAuditLog/search`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getClientListRequest = async () => {
  return await axios.get(`${Config.apiServerHost}/api/client`, await authHeader());
};

const getClientByIdRequest = async id => {
  return await axios.get(`${Config.apiServerHost}/api/client/${id}`, await authHeader());
};

const getProspectsRequest = async id => {
  return await axios.get(`${Config.apiServerHost}/api/client/prospective`, await authHeader());
};

const getProspectByIdRequest = async id => {
  return await axios.get(
    `${Config.apiServerHost}/api/client/prospective/${id}`,
    await authHeader()
  );
};

const deleteProspectsRequest = async id => {
  return await axios.delete(
    `${Config.apiServerHost}/api/client/prospective/${id}`,
    await authHeader()
  );
};

const readProspectsRequest = async id => {
  return await axios.post(
    `${Config.apiServerHost}/api/client/prospective/${id}/read`,
    null,
    await authHeader()
  );
};

const getHubProspectsRequest = async id => {
  return await axios.get(`${Config.apiServerHost}/api/client/prospective/hub`, await authHeader());
};

const verifyHubProspectsRequest = async ({ prospectId, clientId }) => {
  return await axios.post(
    `${Config.apiServerHost}/api/client/prospective/hub/${prospectId}/verify?clientId=${
      clientId || ''
    }`,
    null,
    await authHeader()
  );
};

const verifyProspectsRequest = async ({ prospectId, programId, clientId }) => {
  const url = `${Config.apiServerHost}/api/client/prospective/${prospectId}/verify`;
  return await axios.post(buildUrl(url, { programId, clientId }), null, await authHeader());
};

const deleteHubProspectsRequest = async id => {
  return await axios.delete(
    `${Config.apiServerHost}/api/client/prospective/hub/${id}`,
    await authHeader()
  );
};

const readHubProspectsRequest = async id => {
  return await axios.post(
    `${Config.apiServerHost}/api/client/prospective/hub/${id}/read`,
    null,
    await authHeader()
  );
};

const getHubProspectsSin = async id => {
  return await axios.get(
    `${Config.apiServerHost}/api/client/prospective/hub/${id}/sin`,
    await authHeader()
  );
};

const getClientInfoSin = async clientId => {
  return await axios.get(
    `${Config.apiServerHost}/api/ClientProfile/${clientId}/sin`,
    await authHeader()
  );
};

const addNewClientRequest = async payload => {
  return await axios.post(`${Config.apiServerHost}/api/client`, payload.client, await authHeader());
};

const editClientRequest = async payload => {
  return await axios.put(`${Config.apiServerHost}/api/client`, payload.client, await authHeader());
};

const activateProgram = async (id, programId) => {
  return await axios.put(
    `${Config.apiServerHost}/api/client/${id}/program/${programId}/activate`,
    null,
    await authHeader()
  );
};

const registerProgram = async (id, programId) => {
  return await axios.put(
    `${Config.apiServerHost}/api/client/${id}/program/${programId}/register`,
    null,
    await authHeader()
  );
};

const unregisteredProgram = async (id, programId) => {
  return await axios.put(
    `${Config.apiServerHost}/api/client/${id}/program/${programId}/unregistered`,
    null,
    await authHeader()
  );
};

const ManagerViewUpdateRequest = async payload => {
  return await axios.put(
    `${Config.apiServerHost}/api/client/ManagerViewUpdate`,
    payload.client,
    await authHeader()
  );
};

const clientCompletedResultRequest = async client => {
  return await axios.put(
    `${Config.apiServerHost}/api/client/completed-result/${client.id}`,
    client,
    await authHeader()
  );
};

const deleteClientByIdRequest = async id => {
  return await axios.delete(`${Config.apiServerHost}/api/client/${id}`, await authHeader());
};

const getThriveUserRegistrationRequest = async id => {
  return await axios.get(
    `${Config.apiServerHost}/api/thrive/user_registration/${id}`,
    await authHeader()
  );
};

const getThriveUserTasksRequest = async id => {
  return await axios.get(`${Config.apiServerHost}/api/thrive/user_tasks/${id}`, await authHeader());
};

const getCoachControlRequest = async id => {
  return await axios.get(
    `${Config.apiServerHost}/api/thrive/coach_control/${id}`,
    await authHeader()
  );
};

const getAdminControlRequest = async () => {
  return await axios.get(`${Config.apiServerHost}/api/thrive/admin_control`, await authHeader());
};

const getThriveCustomerRequest = async id => {
  return await axios.get(`${Config.apiServerHost}/api/thrive/customer/${id}`, await authHeader());
};

const getClientFilterRequest = async filter => {
  let url = `${Config.apiServerHost}/api/client/search`;
  return await axios.post(url, filter, {
    headers: { ...(await authHeader()).headers },
  });
};

const getMergeClientFilterRequest = async params => {
  logger.debug(params);
  return await axios.get(`${Config.apiServerHost}/api/client/mergeClients`, {
    params: params,
    headers: { ...(await authHeader()).headers },
  });
};

const getClientSearchByName = async filter => {
  logger.debug(filter);

  if (!filter.firstName) {
    delete filter.firstName;
  }
  if (!filter.lastName) {
    delete filter.lastName;
  }

  let url = `${Config.apiServerHost}/api/client/searchByName`;
  return await axios.get(buildUrl(url, filter), await authHeader());
};

const generateClientReportRequest = async query => {
  let url = `${Config.apiServerHost}/api/client/reports`;
  return axios.post(url, query, await authHeader());
};

const generateClientErrorReportRequest = async params => {
  let url = `${Config.apiServerHost}/api/client/error-report`;
  return await axios.get(buildUrl(url, params), await authHeader());
};

const generatePerformanceIndicatorReportRequest = async params => {
  let url = `${Config.apiServerHost}/api/client/performanceIndicatorIntake`;
  return await axios.get(buildUrl(url, params), await authHeader());
};

const updateClientFileActivityHubRequest = async payload => {
  return await axios.post(
    `${Config.apiServerHost}/api/client/fileActivityHub`,
    payload,
    await authHeader()
  );
};

const updateClientFileActivityRequest = async payload => {
  return await axios.post(
    `${Config.apiServerHost}/api/client/fileActivity`,
    payload,
    await authHeader()
  );
};

const getClientFileActivityRequest = async query => {
  let url = `${Config.apiServerHost}/api/client/fileActivity`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getClientFileActivityHubRequest = async query => {
  let url = `${Config.apiServerHost}/api/client/fileActivityHub`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const saveClientParticipantHubRequest = async payload => {
  return await axios.post(
    `${Config.apiServerHost}/api/client/participantHub`,
    payload,
    await authHeader()
  );
};

const saveClientParticipantARIRequest = async payload => {
  return await axios.post(
    `${Config.apiServerHost}/api/client/participantARI`,
    payload,
    await authHeader()
  );
};

const saveClientParticipantLBSRequest = async payload => {
  return await axios.post(
    `${Config.apiServerHost}/api/client/participantLBS`,
    payload,
    await authHeader()
  );
};

const saveClientParticipantRequest = async payload => {
  return await axios.post(
    `${Config.apiServerHost}/api/client/go-program-participant`,
    payload,
    await authHeader()
  );
};

const getClientParticipantHubRequest = async ({ clientId, servicePlanHeaderId }) => {
  return await axios.get(
    `${Config.apiServerHost}/api/client/${clientId}/participantHub?servicePlanHeaderId=${servicePlanHeaderId}`,
    await authHeader()
  );
};

const getClientParticipantARIRequest = async ({ clientId, servicePlanHeaderId }) => {
  return await axios.get(
    `${Config.apiServerHost}/api/client/${clientId}/participantARI?servicePlanHeaderId=${servicePlanHeaderId}`,
    await authHeader()
  );
};

const getClientParticipantLBSRequest = async ({ clientId, servicePlanHeaderId, programId }) => {
  return await axios.get(
    `${Config.apiServerHost}/api/client/${clientId}/participantLBS?servicePlanHeaderId=${servicePlanHeaderId}&programId=${programId}`,
    await authHeader()
  );
};

const getStudentCheckListLBSRequest = async ({ clientId, servicePlanHeaderId }) => {
  return await axios.get(
    `${Config.apiServerHost}/api/client/${clientId}/studentCheckList?servicePlanHeaderId=${servicePlanHeaderId}`,
    await authHeader()
  );
};

const saveStudentCheckListLBSRequest = async payload => {
  return await axios.post(
    `${Config.apiServerHost}/api/client/studentCheckList`,
    payload,
    await authHeader()
  );
};

const getClientParticipantSin = async clientId => {
  return await axios.get(`${Config.apiServerHost}/api/client/${clientId}/sin`, await authHeader());
};

const getClientParticipantRequest = async ({ clientId, programId, servicePlanHeaderId }) => {
  return await axios.get(
    `${Config.apiServerHost}/api/client/${clientId}/go-program-participant?programId=${programId}&servicePlanHeaderId=${servicePlanHeaderId}`,
    await authHeader()
  );
};

const saveClientAssessmentRequest = async payload => {
  return await axios.post(`${Config.apiServerHost}/api/Assessment`, payload, await authHeader());
};

const saveClientSpecificAssessmentRequest = async payload => {
  return await axios.post(
    `${Config.apiServerHost}/api/Assessment/specific`,
    payload,
    await authHeader()
  );
};

const getClientKPIRequest = async query => {
  let url = `${Config.apiServerHost}/api/client/kpis`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const saveClientAssessmentLBSRequest = async payload => {
  return await axios.post(
    `${Config.apiServerHost}/api/Assessment/lbs`,
    payload,
    await authHeader()
  );
};

const getClientAssessmentLBSRequest = async query => {
  let url = `${Config.apiServerHost}/api/Assessment/lbs`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const getClientSpecificAssessmentRequest = async query => {
  let url = `${Config.apiServerHost}/api/Assessment/specific`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const getClientAssessmentRequest = async query => {
  let url = `${Config.apiServerHost}/api/Assessment/search`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const getClientLearnerPlanRequest = async ({ clientId, servicePlanHeaderId }) => {
  return await axios.get(
    `${Config.apiServerHost}/api/client/learnerPlan/${clientId}?servicePlanHeaderId=${servicePlanHeaderId}`,
    await authHeader()
  );
};

const exportClientLearnerPlanToPdfRequest = async ({ clientId, servicePlanHeaderId }) => {
  return await axios.get(
    `${Config.apiServerHost}/api/client/learnerPlan/${clientId}/exportToPdf?servicePlanHeaderId=${servicePlanHeaderId}`,
    await authHeader()
  );
};

const getClientLearnerPlanLBSRequest = async ({ clientId, servicePlanHeaderId }) => {
  return await axios.get(
    `${Config.apiServerHost}/api/client/learnerPlanLBS/${clientId}?servicePlanHeaderId=${servicePlanHeaderId}`,
    await authHeader()
  );
};

const getClientHistorialNotesRequest = async query => {
  let url = `${Config.apiServerHost}/api/HistoricalNote/search`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const saveClientLearnerPlanRequest = async learnerPlan => {
  return await axios.post(
    `${Config.apiServerHost}/api/client/learnerPlan`,
    learnerPlan,
    await authHeader()
  );
};

const mergeClientsRequest = async params => {
  return await axios.post(
    `${Config.apiServerHost}/api/client/mergeClients`,
    params,
    await authHeader()
  );
};

const mergeServicePlanHeaderRequest = async params => {
  const { id, idsToBeMerged } = params;
  return await axios.put(
    `${Config.apiServerHost}/api/client/mergeServicePlan/${id}`,
    idsToBeMerged,
    { headers: { ...(await authHeader()).headers } }
  );
};

const saveClientLearnerPlanLBSRequest = async learnerPlanLBS => {
  return await axios.post(
    `${Config.apiServerHost}/api/client/learnerPlanLBS`,
    learnerPlanLBS,
    await authHeader()
  );
};

const getClientRedirectRequest = async query => {
  let url = `${Config.apiServerHost}/api/client/redirect`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const getClientServicePlansRequest = async clientId => {
  let url = `${Config.apiServerHost}/api/client/${clientId}/serviceplan`;
  return axios.get(url, await authHeader());
};

const getClientServicePlanHeadersRequest = async ({ clientId, programId }) => {
  let url = `${Config.apiServerHost}/api/client/${clientId}/serviceplan?programId=${programId}`;
  return axios.get(url, await authHeader());
};

const getEmploymentOntarioClientRequest = async ({ clientId }) => {
  let url = `${Config.apiServerHost}/api/client/${clientId}/employment-ontario-client`;
  return axios.get(url, await authHeader());
};

const addClientServicePlansRequest = async servicePlan => {
  return await axios.post(
    `${Config.apiServerHost}/api/client/${servicePlan.clientId}/serviceplan`,
    servicePlan,
    await authHeader()
  );
};

const updateClientServicePlansRequest = async servicePlan => {
  return await axios.put(
    `${Config.apiServerHost}/api/client/${servicePlan.clientId}/serviceplan`,
    servicePlan,
    await authHeader()
  );
};

const getClientServicePlanRequest = async servicePlan => {
  let url = `${Config.apiServerHost}/api/client/${servicePlan.clientId}/serviceplan/${servicePlan.id}`;
  return axios.get(url, await authHeader());
};

const deleteClientServicePlanHeaderRequest = async ({ clientId, servicePlanHeaderId }) => {
  let url = `${Config.apiServerHost}/api/client/${clientId}/serviceplan/${servicePlanHeaderId}`;
  return axios.delete(url, await authHeader());
};

function* getClientRedirect({ payload }) {
  try {
    const { history, clientId, programId } = payload;
    const data = { clientId, programId };
    const { data: dataResponse } = yield call(getClientRedirectRequest, data);
    const { url } = dataResponse;

    logger.debug('history.push -->', url);

    if (!url) {
      NotificationManager.error(
        'The requested service plan could not be found.',
        'Service Plan Error. Please contact support.',
        3000,
        null,
        null,
        ''
      );
      history.push('/app/client');
    } else {
      history.push(url);
    }
  } catch (error) {
    logger.debug(error);
    let errorText = error?.response?.data?.message ?? 'Something was wrong.';
    yield put(getClientRedirectError(errorText));
  }
}

function* getClientLearnerPlan({ payload }) {
  try {
    const response = yield call(getClientLearnerPlanRequest, payload);
    yield put(getClientLearnerPlanSuccess(response.data));
  } catch (error) {
    yield put(getClientLearnerPlanError(error.response.statusText));
  }
}

function* saveClientLearnerPlan({ payload }) {
  try {
    let response = yield call(saveClientLearnerPlanRequest, payload);
    const { clientId, servicePlanHeaderId } = response.data;

    const query = { clientId, servicePlanHeaderId };
    response = yield call(getClientLearnerPlanRequest, query);
    yield put(saveClientLearnerPlanSuccess(response.data));
  } catch (error) {
    yield put(saveClientLearnerPlanError(error.response.statusText));
  }
}

function* saveClientLearnerPlanLBS({ payload }) {
  try {
    let response = yield call(saveClientLearnerPlanLBSRequest, payload);
    const { clientId, servicePlanHeaderId } = response.data;

    const query = { clientId, servicePlanHeaderId };
    response = yield call(getClientLearnerPlanLBSRequest, query);
    yield put(saveClientLearnerPlanLBSSuccess(response.data));
  } catch (error) {
    yield put(saveClientLearnerPlanLBSError(error.response.statusText));
  }
}

function* clientCompletedResult({ payload }) {
  try {
    const { history, client } = payload;

    var response = yield call(clientCompletedResultRequest, client);
    if (response.data) {
      response = yield call(getClientByIdRequest, response.data);

      if (response) {
        logger.debug(response.data);
        yield put(editClientSuccess(response.data));
      }
    }
    history.push('/app/client');
  } catch (error) {
    yield put(editClientError(error.response));
  }
}

function* addNewClient({ payload }) {
  try {
    const { history } = payload;
    const response = yield call(addNewClientRequest, payload);
    yield put(addNewClientSuccess(response.data));
    // let res = yield call(getNotificationReqeust, payload);
    // yield put(getNotificationListSuccess(res.data));
    history.push(`/app/client/${response.data}`);
  } catch (error) {
    yield put(addNewClientError(error.response.statusText));
  }
}

function* editClient({ payload }) {
  try {
    const { history } = payload;
    var response = yield call(editClientRequest, payload);
    if (response.data) {
      response = yield call(getClientByIdRequest, response.data);

      if (response) {
        yield put(editClientSuccess(response.data));
      }
    }
    // let res = yield call(getNotificationReqeust, payload);
    // yield put(getNotificationListSuccess(res.data));
    history.push('/app/client');
  } catch (error) {
    yield put(editClientError(error.response.statusText));
  }
}

function* ManagerViewUpdate({ payload }) {
  try {
    const { history } = payload;
    var response = yield call(ManagerViewUpdateRequest, payload);
    if (response.data) {
      response = yield call(getClientByIdRequest, response.data);

      if (response) {
        yield put(editClientSuccess(response.data));
      }
    }
    history.push('/app/client');
  } catch (error) {
    yield put(editClientError(error.response));
  }
}

function* deleteClientById({ payload }) {
  try {
    const response = yield call(deleteClientByIdRequest, payload);
    yield put(deleteClientByIdSuccess(response.data));
  } catch (error) {
    yield put(deleteClientByIdError(error));
  }
}

function* getClientList() {
  try {
    const response = yield call(getClientListRequest);
    yield put(getClientListSuccess(response.data));
  } catch (error) {
    yield put(getClientListError(error.response.statusText));
  }
}

function* getClientKPI({ payload }) {
  try {
    const response = yield call(getClientKPIRequest, payload);
    yield put(getClientKPISuccess(response.data));
  } catch (error) {
    yield put(getClientListError(error.response.statusText));
  }
}

function* getClientById({ payload }) {
  try {
    const response = yield call(getClientByIdRequest, payload);
    yield put(getClientByIdSuccess(response.data));
  } catch (error) {
    yield put(getClientByIdError(error.response.statusText));
  }
}

function* getClientFilter({ payload }) {
  try {
    var response = null;
    if (payload) {
      response = yield call(getClientFilterRequest, payload);
    } else {
      response = yield call(getClientListRequest);
    }
    yield put(getClientFilterSuccess(response.data));
  } catch (error) {
    yield put(getClientFilterError(error.response.statusText));
  }
}

function* generateClientReport({ payload }) {
  try {
    var response = yield call(generateClientReportRequest, payload);
    yield put(generateClientReportSuccess(response.data));
  } catch (error) {
    yield put(generateClientReportError(error.response.statusText));
  }
}

function* generatePerformanceIndicatorReport({ payload }) {
  try {
    var response = yield call(generatePerformanceIndicatorReportRequest, payload);
    yield put(generatePerformanceIndicatorReportSuccess(response.data));
  } catch (error) {
    yield put(generatePerformanceIndicatorReportError(error.response.statusText));
  }
}

function* generateClientErrorReport({ payload }) {
  try {
    var response = yield call(generateClientErrorReportRequest, payload);
    yield put(generateClientErrorReportSuccess(response.data));
  } catch (error) {
    yield put(generateClientErrorReportError(error.response.statusText));
  }
}

function* updateClientFileActivityHub({ payload }) {
  try {
    let response = yield call(updateClientFileActivityHubRequest, payload);
    const { clientId, programId, servicePlanHeaderId } = response.data;

    const query = {
      clientId,
      programId,
      servicePlanHeaderId: servicePlanHeaderId ?? '',
    };
    response = yield call(getClientFileActivityHubRequest, query);
    yield put(updateClientFileActivityHubSuccess(response.data));
  } catch (error) {
    const { data } = error.response;
    yield put(updateClientFileActivityHubError(data));
  }
}

function* updateClientFileActivity({ payload }) {
  try {
    let response = yield call(updateClientFileActivityRequest, payload);
    const { clientId, programId, servicePlanHeaderId } = response.data;
    const query = {
      clientId,
      programId,
      servicePlanHeaderId: servicePlanHeaderId ?? '',
    };
    response = yield call(getClientFileActivityRequest, query);

    yield put(updateClientFileActivitySuccess(response.data));
  } catch (error) {
    const { data } = error.response;
    yield put(updateClientFileActivityError(data));
  }
}

function* getClientFileActivity({ payload }) {
  try {
    const response = yield call(getClientFileActivityRequest, payload);
    yield put(getClientFileActivitySuccess(response.data));
  } catch (error) {
    yield put(getClientFileActivityError(error.response.statusText));
  }
}

function* getClientFileActivityHub({ payload }) {
  try {
    const response = yield call(getClientFileActivityHubRequest, payload);
    yield put(getClientFileActivityHubSuccess(response.data));
  } catch (error) {
    yield put(getClientFileActivityHubError(error.response.statusText));
  }
}

function* saveStudentCheckListLBS({ payload }) {
  try {
    let response = yield call(saveStudentCheckListLBSRequest, payload);
    const { clientId, servicePlanHeaderId } = response.data;
    const query = {
      clientId,
      servicePlanHeaderId: servicePlanHeaderId ?? '',
    };
    response = yield call(getStudentCheckListLBSRequest, query);
    yield put(saveStudentCheckListLBSSuccess(response.data));
  } catch (error) {
    yield put(saveStudentCheckListLBSError(error.response.statusText));
  }
}

function* getStudentCheckListLBS({ payload }) {
  try {
    let response = yield call(getStudentCheckListLBSRequest, payload);
    yield put(getStudentCheckListLBSSuccess(response.data));
  } catch (error) {
    yield put(getStudentCheckListLBSError(error.response.statusText));
  }
}

function* saveClientParticipantHub({ payload }) {
  try {
    const { client, history, activeProgram } = payload;
    let response = yield call(saveClientParticipantHubRequest, client);

    if (client.id === 0) {
      switch (activeProgram) {
        case 11: // ARI
          history.push(`/app/client/program/hub/${response.data}`);
          break;
        default:
          history.push(`/app/client/${response.data}`);
          break;
      }
    } else {
      const data = { clientId: response.data, servicePlanHeaderId: client.servicePlanHeaderId };
      response = yield call(getClientParticipantHubRequest, data);
      yield put(saveClientParticipantHubSuccess(response.data));
    }
  } catch (error) {
    yield put(saveClientParticipantHubError(error.response.statusText));
  }
}

function* saveClientParticipantARI({ payload }) {
  try {
    const { client, history, activeProgram } = payload;
    let response = yield call(saveClientParticipantARIRequest, client);

    if (client.id === 0) {
      switch (activeProgram) {
        case 10: // ARI
          history.push(`/app/client/program/ari/${response.data}`);
          break;
        default:
          history.push(`/app/client/${response.data}`);
          break;
      }
    } else {
      const data = { clientId: response.data, servicePlanHeaderId: client.servicePlanHeaderId };
      response = yield call(getClientParticipantARIRequest, data);
      yield put(saveClientParticipantARISuccess(response.data));
    }
  } catch (error) {
    yield put(saveClientParticipantARIError(error.response.statusText));
  }
}

function* saveClientParticipantLBS({ payload }) {
  try {
    const { client, history, activeProgram } = payload;
    let response = yield call(saveClientParticipantLBSRequest, client);

    if (client.id === 0) {
      switch (activeProgram) {
        case 9: // ARI
          history.push(`/app/client/program/lbs/${response.data}`);
          break;
        default:
          history.push(`/app/client/${response.data}`);
          break;
      }
    } else {
      const data = {
        clientId: response.data,
        servicePlanHeaderId: client.servicePlanHeaderId,
        programId: client.programId,
      };
      response = yield call(getClientParticipantLBSRequest, data);
      yield put(saveClientParticipantLBSSuccess(response.data));
    }
  } catch (error) {
    yield put(saveClientParticipantLBSError(error.response.statusText));
  }
}

function* saveClientParticipant({ payload }) {
  try {
    const { client, history, activeProgram } = payload;
    const { programId, servicePlanHeaderId, id } = client;
    let response = yield call(saveClientParticipantRequest, client);

    if (client.id === 0) {
      switch (activeProgram) {
        case 1: // GO
          history.push(`/app/client/program/go/${id}`);
          break;
        case 2: // ES
          history.push(`/app/client/program/es/${id}`);
          break;
        case 7: // YJC
          history.push(`/app/client/program/yjc/${id}`);
          break;
        case 8: // YJC-S
          history.push(`/app/client/program/yjc-s/${id}`);
          break;
        case 9: // LBS
          history.push(`/app/client/program/lbs/${id}`);
          break;
        case 10: // ARI
          history.push(`/app/client/program/ari/${id}`);
          break;
        case 12: // SAO
          history.push(`/app/client/program/sao/${id}`);
          break;
        case 13: // OW-EP
          history.push(`/app/client/program/ow-ep/${id}`);
          break;
        case 14: // IES
          history.push(`/app/client/ssm/ies/${id}`);
          break;
        case 15: // UNASSISTED
          history.push(`/app/client/program/unassisted/${id}`);
          break;
        default:
          history.push(`/app/client/${id}`);
          break;
      }
    } else {
      response = yield call(getClientParticipantRequest, {
        programId,
        servicePlanHeaderId,
        clientId: id,
      });
      yield put(saveClientParticipantSuccess(response.data));
    }
  } catch (error) {
    yield put(saveClientParticipantError(error.response.statusText));
  }
}

function* getClientParticipant({ payload }) {
  try {
    // logger.debug(payload);
    const response = yield call(getClientParticipantRequest, payload);
    yield put(getClientParticipantSuccess(response.data));
  } catch (error) {
    yield put(getClientParticipantError(error.response.statusText));
  }
}

function* getClientServicePlans({ payload }) {
  try {
    const response = yield call(getClientServicePlansRequest, payload);
    yield put(getClientServicePlansSuccess(response.data));
  } catch (error) {
    yield put(getClientServicePlansError(error.response.statusText));
  }
}

function* getClientServicePlan({ payload }) {
  try {
    const response = yield call(getClientServicePlanRequest, payload);
    yield put(getClientServicePlanSuccess(response.data));
  } catch (error) {
    yield put(getClientServicePlanError(error.response.statusText));
  }
}

function* addClientServicePlans({ payload }) {
  logger.debug('payload', payload);
  const { servicePlan, history } = payload;

  try {
    const addResult = yield call(addClientServicePlansRequest, servicePlan);
    const response = yield call(getClientServicePlansRequest, servicePlan.clientId);
    yield put(getClientServicePlansSuccess(response.data));

    const { clientId, programId, id: servicePlanHeaderId } = addResult.data;
    const data = { clientId, programId, servicePlanHeaderId };
    const { data: dataResponse } = yield call(getClientRedirectRequest, data);
    const { url } = dataResponse;
    history.push(url);
  } catch (error) {
    yield put(getClientServicePlansError(error.response.statusText));
  }
}

function* updateClientServicePlan({ payload }) {
  try {
    yield call(updateClientServicePlansRequest, payload);

    let response = yield call(getClientServicePlanRequest, payload);
    yield put(getClientServicePlanSuccess(response.data));

    const plansResponse = yield call(getClientServicePlansRequest, payload.clientId);
    yield put(getClientServicePlansSuccess(plansResponse.data));

    let query = {
      clientId: payload.clientId,
      servicePlanHeaderId: payload.id,
      programId: payload.programId,
    };

    response = yield call(getClientKPIRequest, query);
    yield put(getClientKPISuccess(response.data));
  } catch (error) {
    yield put(getClientServicePlanError(error.response.statusText));
  }
}

function* getClientParticipantHub({ payload }) {
  try {
    const response = yield call(getClientParticipantHubRequest, payload);
    yield put(getClientParticipantHubSuccess(response.data));
  } catch (error) {
    yield put(getClientParticipantHubError(error.response.statusText));
  }
}

function* getClientParticipantARI({ payload }) {
  try {
    const response = yield call(getClientParticipantARIRequest, payload);
    yield put(getClientParticipantARISuccess(response.data));
  } catch (error) {
    yield put(getClientParticipantARIError(error.response.statusText));
  }
}

function* getClientParticipantLBS({ payload }) {
  try {
    const response = yield call(getClientParticipantLBSRequest, payload);
    yield put(getClientParticipantLBSSuccess(response.data));
  } catch (error) {
    yield put(getClientParticipantLBSError(error.response.statusText));
  }
}

function* saveClientAssessment({ payload }) {
  try {
    let response = yield call(saveClientAssessmentRequest, payload);
    const { clientId, programId, servicePlanHeaderId } = payload;
    const query = {
      clientId,
      programId,
      servicePlanHeaderId,
    };
    response = yield call(getClientAssessmentRequest, query);
    // var response1 = yield call(getClientFileActivityRequest, response.data);
    yield put(saveClientAssessmentSuccess(response.data));
    // yield put(updateClientFileActivitySuccess(response1.data))
  } catch (error) {
    yield put(saveClientAssessmentError(error.response.statusText));
  }
}

function* saveClientAssessmentLBS({ payload }) {
  try {
    yield call(saveClientAssessmentLBSRequest, payload);
    const { clientId, servicePlanHeaderId } = payload;
    const query = {
      clientId,
      servicePlanHeaderId,
    };
    let response = yield call(getClientAssessmentLBSRequest, query);

    yield put(saveClientAssessmentLBSSuccess(response.data));
  } catch (error) {
    yield put(saveClientAssessmentLBSError(error.response.statusText));
  }
}

function* saveClientSpecificAssessment({ payload }) {
  try {
    let response = yield call(saveClientSpecificAssessmentRequest, payload);
    const { clientId, programId, servicePlanHeaderId } = payload;
    const query = {
      clientId,
      programId: programId ?? '',
      servicePlanHeaderId: servicePlanHeaderId ?? '',
    };
    response = yield call(getClientSpecificAssessmentRequest, query);
    yield put(saveClientSpecificAssessmentSuccess(response.data));
    response = yield call(getClientKPIRequest, query);
    yield put(getClientKPISuccess(response.data));
  } catch (error) {
    yield put(saveClientSpecificAssessmentError(error.response.statusText));
  }
}

function* getClientAssessment({ payload }) {
  try {
    const response = yield call(getClientAssessmentRequest, payload);
    yield put(getClientAssessmentSuccess(response.data));
  } catch (error) {
    yield put(getClientAssessmentError(error.response.statusText));
  }
}

function* getClientAssessmentLBS({ payload }) {
  try {
    const response = yield call(getClientAssessmentLBSRequest, payload);
    yield put(getClientAssessmentLBSSuccess(response.data));
  } catch (error) {
    yield put(getClientAssessmentLBSError(error.response.statusText));
  }
}

function* getClientSpecificAssessment({ payload }) {
  try {
    const response = yield call(getClientSpecificAssessmentRequest, payload);
    yield put(getClientSpecificAssessmentSuccess(response.data));
  } catch (error) {
    yield put(getClientSpecificAssessmentError(error.response.statusText));
  }
}

function* getClientLeftSidebar({ payload }) {
  try {
    const response = yield call(getClientLeftSidebarRequest, payload);
    yield put(getClientLeftSidebarSuccess(response.data));
  } catch (error) {
    yield put(getClientLeftSidebarError(error.response.statusText));
  }
}

function* saveClientComment({ payload }) {
  try {
    const response = yield call(saveClientCommentRequest, payload);
    yield put(getClientLeftSidebarSuccess(response.data));
  } catch (error) {
    yield put(getClientLeftSidebarError(error.response.statusText));
  }
}

export function* watchClientLearnerPlanGetting() {
  yield takeEvery(CLIENT_GET_LEARNER_PLAN, getClientLearnerPlan);
}

export function* watchClientRedirect() {
  yield takeEvery(GET_CLIENT_REDIRECT, getClientRedirect);
}

export function* watchClientLearnerPlanSaving() {
  yield takeEvery(CLIENT_SAVE_LEARNER_PLAN, saveClientLearnerPlan);
}

export function* watchClientLearnerPlanLBSSaving() {
  yield takeEvery(CLIENT_SAVE_LEARNER_PLAN_LBS, saveClientLearnerPlanLBS);
}

export function* watchAddNewClient() {
  yield takeEvery(CLIENT_ADD_NEW, addNewClient);
}

export function* watchEditClient() {
  yield takeEvery(CLIENT_EDIT, editClient);
}

export function* watchGetClientList() {
  yield takeEvery(CLIENT_GET_LIST, getClientList);
}

export function* watchClientById() {
  yield takeEvery(CLIENT_GET_BY_ID, getClientById);
}

export function* watchDeleteClientById() {
  yield takeEvery(CLIENT_DELETE, deleteClientById);
}

export function* watchGetClientFilter() {
  yield takeEvery(CLIENT_FILTER, getClientFilter);
}

export function* watchManagerViewUpdateClient() {
  yield takeEvery(CLIENT_MANAGER_VIEW_UPDATE, ManagerViewUpdate);
}

export function* watchClientCompletedResult() {
  yield takeEvery(CLIENT_COMPLETED_RESULT, clientCompletedResult);
}

export function* watchClientReport() {
  yield takeEvery(GENERATE_CLIENT_REPORT, generateClientReport);
}

export function* watchClientFileActivityUpdate() {
  yield takeEvery(CLIENT_FILE_ACIVITY_UPDATE, updateClientFileActivity);
}

export function* watchClientFileActivityHubUpdate() {
  yield takeEvery(CLIENT_FILE_ACIVITY_HUB_UPDATE, updateClientFileActivityHub);
}

export function* watchClientFileActivity() {
  yield takeEvery(GET_CLIENT_FILE_ACIVITY, getClientFileActivity);
}

export function* watchClientFileActivityHub() {
  yield takeEvery(GET_CLIENT_FILE_ACIVITY_HUB, getClientFileActivityHub);
}

export function* watchClientParticipantSaving() {
  yield takeEvery(SAVE_CLIENT_PARTICIPANT, saveClientParticipant);
}

export function* watchClientParticipantHubSaving() {
  yield takeEvery(SAVE_CLIENT_PARTICIPANT_HUB, saveClientParticipantHub);
}

export function* watchClientParticipantARISaving() {
  yield takeEvery(SAVE_CLIENT_PARTICIPANT_ARI, saveClientParticipantARI);
}

export function* watchClientParticipantLBSSaving() {
  yield takeEvery(SAVE_CLIENT_PARTICIPANT_LBS, saveClientParticipantLBS);
}

export function* watchClientParticipant() {
  yield takeEvery(GET_CLIENT_PARTICIPANT, getClientParticipant);
}

export function* watchClientServicePlans() {
  yield takeEvery(GET_CLIENT_SERVICEPLANS, getClientServicePlans);
}

export function* watchAddClientServicePlans() {
  yield takeEvery(ADD_CLIENT_SERVICEPLANS, addClientServicePlans);
}

export function* watchClientServicePlan() {
  yield takeEvery(GET_CLIENT_SERVICEPLAN, getClientServicePlan);
}

export function* watchUpdateClientServicePlan() {
  yield takeEvery(UPDATE_CLIENT_SERVICEPLAN, updateClientServicePlan);
}

export function* watchClientParticipantHub() {
  yield takeEvery(GET_CLIENT_PARTICIPANT_HUB, getClientParticipantHub);
}

export function* watchClientParticipantARI() {
  yield takeEvery(GET_CLIENT_PARTICIPANT_ARI, getClientParticipantARI);
}

export function* watchClientParticipantLBS() {
  yield takeEvery(GET_CLIENT_PARTICIPANT_LBS, getClientParticipantLBS);
}

export function* watchClientAssessmentLBSSaving() {
  yield takeEvery(SAVE_CLIENT_ASSESSMENT_LBS, saveClientAssessmentLBS);
}

export function* watchClientAssessmentSaving() {
  yield takeEvery(SAVE_CLIENT_ASSESSMENT, saveClientAssessment);
}

export function* watchClientSpecificAssessmentSaving() {
  yield takeEvery(SAVE_CLIENT_SPECIFIC_ASSESSMENT, saveClientSpecificAssessment);
}

export function* watchClientAssessment() {
  yield takeEvery(GET_CLIENT_ASSESSMENT, getClientAssessment);
}

export function* watchClientAssessmentLBS() {
  yield takeEvery(GET_CLIENT_ASSESSMENT_LBS, getClientAssessmentLBS);
}

export function* watchClientSpecificAssessment() {
  yield takeEvery(GET_CLIENT_SPECIFIC_ASSESSMENT, getClientSpecificAssessment);
}

export function* watchClientErrorReport() {
  yield takeEvery(CLIENT_ERROR_REPORT, generateClientErrorReport);
}

export function* watchPerformanceIndicatorReport() {
  yield takeEvery(GENERATE_PERFORMANCE_INDICATOR_REPORT, generatePerformanceIndicatorReport);
}

export function* watchStudentCheckListLBSSaving() {
  yield takeEvery(SAVE_STUDENT_CHECKLIST_LBS, saveStudentCheckListLBS);
}

export function* watchStudentCheckListLBS() {
  yield takeEvery(GET_STUDENT_CHECKLIST_LBS, getStudentCheckListLBS);
}

export function* watchGettingClientKPI() {
  yield takeEvery(GET_CLIENT_KPI, getClientKPI);
}

export function* watchGettingClientLeftSidebar() {
  yield takeEvery(GET_CLIENT_LEFT_SIDEBAR, getClientLeftSidebar);
}

export function* watchSavingClientComment() {
  yield takeEvery(SAVE_CLIENT_COMMENT, saveClientComment);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetClientList),
    fork(watchClientById),
    fork(watchAddNewClient),
    fork(watchEditClient),
    fork(watchDeleteClientById),
    fork(watchGetClientFilter),
    fork(watchManagerViewUpdateClient),
    fork(watchClientCompletedResult),
    fork(watchClientReport),
    fork(watchClientFileActivityUpdate),
    fork(watchClientFileActivityHubUpdate),
    fork(watchClientFileActivity),
    fork(watchClientFileActivityHub),
    fork(watchClientParticipantSaving),
    fork(watchClientParticipantHubSaving),
    fork(watchClientParticipantARISaving),
    fork(watchClientParticipant),

    fork(watchClientServicePlans),
    fork(watchAddClientServicePlans),
    fork(watchClientServicePlan),
    fork(watchUpdateClientServicePlan),

    fork(watchClientParticipantHub),
    fork(watchClientParticipantARI),
    fork(watchClientAssessmentSaving),
    fork(watchClientSpecificAssessmentSaving),
    fork(watchClientAssessment),
    fork(watchClientSpecificAssessment),
    fork(watchClientErrorReport),
    fork(watchPerformanceIndicatorReport),
    fork(watchClientLearnerPlanGetting),
    fork(watchClientLearnerPlanSaving),
    fork(watchClientLearnerPlanLBSSaving),
    fork(watchStudentCheckListLBSSaving),
    fork(watchStudentCheckListLBS),
    fork(watchClientRedirect),
    fork(watchClientAssessmentLBSSaving),
    fork(watchClientAssessmentLBS),
    fork(watchClientParticipantLBSSaving),
    fork(watchClientParticipantLBS),
    fork(watchGettingClientKPI),
    fork(watchGettingClientLeftSidebar),
    fork(watchSavingClientComment),
  ]);
}

export {
  getAssignClientAuditLogRequest,
  getClientByIdRequest,
  getClientFileActivityRequest,
  getProspectsRequest,
  getProspectByIdRequest,
  verifyProspectsRequest,
  deleteProspectsRequest,
  readProspectsRequest,
  getHubProspectsRequest,
  verifyHubProspectsRequest,
  deleteHubProspectsRequest,
  readHubProspectsRequest,
  addNewClientRequest,
  registerProgram,
  activateProgram,
  unregisteredProgram,
  getClientFilterRequest,
  quickCreateClientRequest,
  getClientParticipantSin,
  getClientLearnerPlanRequest,
  getClientLearnerPlanLBSRequest,
  getHubProspectsSin,
  quickCreateClientWithServicePlanRequest,
  getClientHistorialNotesRequest,
  getClientServicePlansRequest,
  getClientServicePlanHeadersRequest,
  getPBFTReportRequest,
  getMonthPBFTReportRequest,
  updateMonthPBFTReportRequest,
  getClientStreamPBFIndicatorRequest,
  updateClientStreamPBFIndicatorRequest,
  getClientSearchByName,
  getClientParticipantRequest,
  getClientParticipantHubRequest,
  getClientParticipantARIRequest,
  getClientParticipantLBSRequest,
  deleteClientServicePlanHeaderRequest,
  getClientRedirectRequest,
  getManagerDashboard,
  getManagerDashboardSetting,
  updateManagerDashboardSetting,
  getEmploymentOntarioClientRequest,
  sendPostingToClientRequest,
  exportClientLearnerPlanToPdfRequest,
  getClientLastContactRequest,
  getClientInfoSin,
  getClientServicePlanRequest,
  getClientLeftSidebarRequest,
  mergeClientsRequest,
  mergeServicePlanHeaderRequest,
  getMergeClientFilterRequest,
  getThriveUserRegistrationRequest,
  getThriveCustomerRequest,
  getCoachControlRequest,
  getThriveUserTasksRequest,
  getClientProfileByStatusRequest,
  getClientProfileByStageRequest,
  getAdminControlRequest,
  exportClientLastContactXlsRequest,
  exportClientProfileByStageXlsRequest,
  exportClientProfileByStatusXlsRequest,
  addClientServicePlansRequest,
};
