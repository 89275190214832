import {
  JOB_SEEKER_ADD_NEW,
  JOB_SEEKER_ADD_NEW_SUCCESS,
  JOB_SEEKER_ADD_NEW_ERROR,
  JOB_SEEKER_DELETE,
  JOB_SEEKER_DELETE_ERROR,
  JOB_SEEKER_DELETE_SUCCESS,
  JOB_SEEKER_EDIT,
  JOB_SEEKER_EDIT_ERROR,
  JOB_SEEKER_EDIT_SUCCESS,
  JOB_SEEKER_GET_BY_ID,
  JOB_SEEKER_GET_BY_ID_ERROR,
  JOB_SEEKER_GET_BY_ID_SUCCESS,
  JOB_SEEKER_GET_LIST,
  JOB_SEEKER_GET_LIST_ERROR,
  JOB_SEEKER_GET_LIST_SUCCESS,
  JOB_SEEKER_ADD_NEW_REQUEST,
} from '../actions';

export const getJobSeekerList = query => ({
  type: JOB_SEEKER_GET_LIST,
  payload: query,
});

export const getJobSeekerListSuccess = jobSeekers => ({
  type: JOB_SEEKER_GET_LIST_SUCCESS,
  payload: jobSeekers,
});

export const getJobSeekerListError = error => ({
  type: JOB_SEEKER_GET_LIST_ERROR,
  payload: error,
});

export const addNewJobSeeker = jobSeeker => ({
  type: JOB_SEEKER_ADD_NEW,
  payload: jobSeeker,
});

export const addNewJobSeekerRequest = () => ({
  type: JOB_SEEKER_ADD_NEW_REQUEST,
});

export const addNewJobSeekerSuccess = jobSeeker => ({
  type: JOB_SEEKER_ADD_NEW_SUCCESS,
  payload: jobSeeker,
});

export const addNewJobSeekerError = error => ({
  type: JOB_SEEKER_ADD_NEW_ERROR,
  payload: error,
});

export const editJobSeeker = jobSeeker => ({
  type: JOB_SEEKER_EDIT,
  payload: jobSeeker,
});

export const editJobSeekerSuccess = jobSeeker => ({
  type: JOB_SEEKER_EDIT_SUCCESS,
  payload: jobSeeker,
});

export const editJobSeekerError = error => ({
  type: JOB_SEEKER_EDIT_ERROR,
  payload: error,
});

export const deleteJobSeeker = id => ({
  type: JOB_SEEKER_DELETE,
  payload: id,
});

export const deleteJobSeekerSuccess = id => ({
  type: JOB_SEEKER_DELETE_SUCCESS,
  payload: id,
});

export const deleteJobSeekerError = error => ({
  type: JOB_SEEKER_DELETE_ERROR,
  payload: error,
});

export const getJobSeekerById = id => ({
  type: JOB_SEEKER_GET_BY_ID,
  payload: id,
});

export const getJobSeekerByIdSuccess = jobSeeker => ({
  type: JOB_SEEKER_GET_BY_ID_SUCCESS,
  payload: jobSeeker,
});

export const getJobSeekerByIdError = error => ({
  type: JOB_SEEKER_GET_BY_ID_ERROR,
  payload: error,
});
