import {
  CONTRACTS_PAID_GET_LIST,
  CONTRACTS_PAID_GET_LIST_SUCCESS,
  CONTRACTS_PAID_GET_LIST_ERROR,
  CONTRACTS_PAID_GET_LIST_WITH_FILTER,
  CONTRACTS_PAID_GET_LIST_WITH_ORDER,
  CONTRACTS_PAID_GET_LIST_SEARCH,
} from '../../actions';

export const getContractsPaidList = filter => ({
  type: CONTRACTS_PAID_GET_LIST,
  payload: filter,
});

export const getContractsPaidListSuccess = items => ({
  type: CONTRACTS_PAID_GET_LIST_SUCCESS,
  payload: items,
});

export const getContractsPaidListError = error => ({
  type: CONTRACTS_PAID_GET_LIST_ERROR,
  payload: error,
});

export const getContractsPaidListWithFilter = (column, value) => ({
  type: CONTRACTS_PAID_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getContractsPaidListWithOrder = column => ({
  type: CONTRACTS_PAID_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getContractsPaidListSearch = keyword => ({
  type: CONTRACTS_PAID_GET_LIST_SEARCH,
  payload: keyword,
});
