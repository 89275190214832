import {
  INTERVENTION_FOLLOWUP_IES_EDIT,
  INTERVENTION_FOLLOWUP_IES_EDIT_ERROR,
  INTERVENTION_FOLLOWUP_IES_EDIT_SUCCESS,
  INTERVENTION_FOLLOWUP_IES_GET_BY_ID,
  INTERVENTION_FOLLOWUP_IES_GET_BY_ID_ERROR,
  INTERVENTION_FOLLOWUP_IES_GET_BY_ID_SUCCESS,
  INTERVENTION_FOLLOWUP_IES_GET_LIST,
  INTERVENTION_FOLLOWUP_IES_GET_LIST_ERROR,
  INTERVENTION_FOLLOWUP_IES_GET_LIST_SUCCESS,
} from './types';
// InterventionFollowupIES

export const getInterventionFollowupIESList = query => ({
  type: INTERVENTION_FOLLOWUP_IES_GET_LIST,
  payload: query,
});

export const getInterventionFollowupIESListSuccess = InterventionFollowupIESs => ({
  type: INTERVENTION_FOLLOWUP_IES_GET_LIST_SUCCESS,
  payload: InterventionFollowupIESs,
});

export const getInterventionFollowupIESListError = error => ({
  type: INTERVENTION_FOLLOWUP_IES_GET_LIST_ERROR,
  payload: error,
});

export const editInterventionFollowupIES = InterventionFollowupIES => ({
  type: INTERVENTION_FOLLOWUP_IES_EDIT,
  payload: InterventionFollowupIES,
});

export const editInterventionFollowupIESSuccess = InterventionFollowupIES => ({
  type: INTERVENTION_FOLLOWUP_IES_EDIT_SUCCESS,
  payload: InterventionFollowupIES,
});

export const editInterventionFollowupIESError = error => ({
  type: INTERVENTION_FOLLOWUP_IES_EDIT_ERROR,
  payload: error,
});

export const getInterventionFollowupIESById = id => ({
  type: INTERVENTION_FOLLOWUP_IES_GET_BY_ID,
  payload: id,
});

export const getInterventionFollowupIESByIdSuccess = InterventionFollowupIES => ({
  type: INTERVENTION_FOLLOWUP_IES_GET_BY_ID_SUCCESS,
  payload: InterventionFollowupIES,
});

export const getInterventionFollowupIESByIdError = error => ({
  type: INTERVENTION_FOLLOWUP_IES_GET_BY_ID_ERROR,
  payload: error,
});
