// INVITATION
export const INVITATION_GET_BY_ID = 'INVITATION_GET_BY_ID';
export const INVITATION_GET_BY_ID_SUCCESS = 'INVITATION_GET_BY_ID_SUCCESS';
export const INVITATION_GET_BY_ID_ERROR = 'INVITATION_GET_BY_ID_ERROR';

export const INVITATION_GET_LIST = 'INVITATION_GET_LIST';
export const INVITATION_GET_LIST_SUCCESS = 'INVITATION_GET_LIST_SUCCESS';
export const INVITATION_GET_LIST_ERROR = 'INVITATION_GET_LIST_ERROR';

export const INVITATION_EDIT = 'INVITATION_EDIT';
export const INVITATION_EDIT_SUCCESS = 'INVITATION_EDIT_SUCCESS';
export const INVITATION_EDIT_ERROR = 'INVITATION_EDIT_ERROR';

export const INVITATION_ADD_NEW = 'INVITATION_ADD_NEW';
export const INVITATION_ADD_NEW_SUCCESS = 'INVITATION_ADD_NEW_SUCCESS';
export const INVITATION_ADD_NEW_ERROR = 'INVITATION_ADD_NEW_ERROR';
export const INVITATION_ADD_NEW_REQUEST = 'INVITATION_ADD_NEW_REQUEST';

export const INVITATION_DELETE = 'INVITATION_DELETE';
export const INVITATION_DELETE_SUCCESS = 'INVITATION_DELETE_SUCCESS';
export const INVITATION_DELETE_ERROR = 'INVITATION_DELETE_ERROR';

export const INVITATION_GET_BY_QUERY = 'INVITATION_GET_BY_QUERY';
export const INVITATION_GET_BY_QUERY_SUCCESS = 'INVITATION_GET_BY_QUERY_SUCCESS';
export const INVITATION_GET_BY_QUERY_ERROR = 'INVITATION_GET_BY_QUERY_ERROR';

export const INVITATION_SEND_INVITATION = 'INVITATION_SEND_INVITATION';
export const INVITATION_SEND_INVITATION_SUCCESS = 'INVITATION_SEND_INVITATION_SUCCESS';
export const INVITATION_SEND_INVITATION_ERROR = 'INVITATION_SEND_INVITATION_ERROR';
