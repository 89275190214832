import {
  YJC_CONTRACT_GET_LIST,
  YJC_CONTRACT_GET_LIST_SUCCESS,
  YJC_CONTRACT_GET_LIST_ERROR,
  YJC_CONTRACT_GET_BY_ID,
  YJC_CONTRACT_GET_BY_ID_SUCCESS,
  YJC_CONTRACT_GET_BY_ID_ERROR,
  YJC_CONTRACT_ADD_ITEM,
  YJC_CONTRACT_ADD_ITEM_SUCCESS,
  YJC_CONTRACT_ADD_ITEM_ERROR,
  YJC_CONTRACT_EDIT_ITEM,
  YJC_CONTRACT_EDIT_ITEM_SUCCESS,
  YJC_CONTRACT_EDIT_ITEM_ERROR,
  YJC_CONTRACT_DELETE_ITEM,
  YJC_CONTRACT_DELETE_ITEM_SUCCESS,
  YJC_CONTRACT_DELETE_ITEM_ERROR,
  YJC_CONTRACT_GET_BY_EMPLOYER,
  YJC_CONTRACT_GET_BY_EMPLOYER_SUCCESS,
  YJC_CONTRACT_GET_BY_EMPLOYER_ERROR,
  YJC_CONTRACT_GET_BY_CLIENT,
  YJC_CONTRACT_GET_BY_CLIENT_SUCCESS,
  YJC_CONTRACT_GET_BY_CLIENT_ERROR,
} from '../actions';

//yjcContract
const initialState = {
  yjcContracts: [],
  yjcContract: null,
  error: '',
  loading: false,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
};

export default function reducerState(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case YJC_CONTRACT_GET_BY_ID:
    case YJC_CONTRACT_GET_LIST:
    case YJC_CONTRACT_GET_BY_EMPLOYER:
    case YJC_CONTRACT_GET_BY_CLIENT:
      return {
        ...state,
        loading: true,
        editSuccess: false,
      };

    case YJC_CONTRACT_GET_LIST_SUCCESS:
    case YJC_CONTRACT_GET_BY_EMPLOYER_SUCCESS:
    case YJC_CONTRACT_GET_BY_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        yjcContracts: payload,
      };

    case YJC_CONTRACT_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        yjcContract: payload,
      };

    case YJC_CONTRACT_ADD_ITEM:
      return {
        ...state,
        loading: true,
        addSuccess: false,
      };

    case YJC_CONTRACT_ADD_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        yjcContracts: payload,
        addSuccess: true,
      };
    case YJC_CONTRACT_EDIT_ITEM:
      return {
        ...state,
        loading: true,
        editSuccess: false,
      };

    case YJC_CONTRACT_EDIT_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        yjcContracts: payload,
        editSuccess: true,
      };

    case YJC_CONTRACT_DELETE_ITEM:
      return {
        ...state,
        loading: true,
      };
    case YJC_CONTRACT_DELETE_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        yjcContracts: payload,
        deleteSuccess: true,
      };

    case YJC_CONTRACT_GET_LIST_ERROR:
    case YJC_CONTRACT_ADD_ITEM_ERROR:
    case YJC_CONTRACT_EDIT_ITEM_ERROR:
    case YJC_CONTRACT_DELETE_ITEM_ERROR:
    case YJC_CONTRACT_GET_BY_ID_ERROR:
    case YJC_CONTRACT_GET_BY_EMPLOYER_ERROR:
    case YJC_CONTRACT_GET_BY_CLIENT_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
}
