import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  STAKE_HOLDER_DOCUMENT_ADD_NEW,
  STAKE_HOLDER_DOCUMENT_BULK_ADD_NEW,
  STAKE_HOLDER_DOCUMENT_CHANGE_STATUS,
  STAKE_HOLDER_DOCUMENT_DELETE,
  STAKE_HOLDER_DOCUMENT_GET_BY_ID,
  STAKE_HOLDER_DOCUMENT_GET_PAGING,
  STAKE_HOLDER_DOCUMENT_UPDATE,
} from './types';

import {
  addStakeHolderDocumentSuccess,
  addStakeHolderDocumentError,
  getPagingStakeHolderDocumentSuccess,
  getPagingStakeHolderDocumentError,
  getStakeHolderDocumentByIdSuccess,
  getStakeHolderDocumentByIdError,
  updateStakeHolderDocumentSuccess,
  updateStakeHolderDocumentError,
  bulkAddStakeHolderDocumentSuccess,
} from './actions';

const addStakeHolderDocumentRequest = async item => {
  return axios.post(`${Config.apiServerHost}/api/StakeHolderDocument`, item, {
    headers: { ...(await authHeader()).headers },
  });
};

const bulkAddStakeHolderDocumentRequest = async item => {
  return axios.post(`${Config.apiServerHost}/api/StakeHolderDocument/bulkAdd`, item, {
    headers: { ...(await authHeader()).headers },
  });
};

const updateStakeHolderDocumentRequest = async item => {
  return axios.put(`${Config.apiServerHost}/api/StakeHolderDocument`, item, {
    headers: { ...(await authHeader()).headers },
  });
};

const getPagingStakeHolderDocumentRequest = async query => {
  return axios.post(`${Config.apiServerHost}/api/StakeHolderDocument/paging`, query, {
    headers: { ...(await authHeader()).headers },
  });
};

const getStakeHolderDocumentByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/StakeHolderDocument/${id}`, {
    headers: { ...(await authHeader()).headers },
  });
};

const changeStatusStakeHolderDocumentRequest = async item => {
  return axios.get(`${Config.apiServerHost}/api/StakeHolderDocument/statusChange`, {
    params: item,
    headers: { ...(await authHeader()).headers },
  });
};

const deleteStakeHolderDocumentRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/StakeHolderDocument/${id}`, await authHeader());
};

function* addNewStakeHolderDocument({ payload }) {
  try {
    const { data, stakeHolderType, stakeHolderId } = payload;
    let response = yield call(addStakeHolderDocumentRequest, data);
    yield put(addStakeHolderDocumentSuccess(response.data));

    response = yield call(getPagingStakeHolderDocumentRequest, { stakeHolderType, stakeHolderId });
    yield put(getPagingStakeHolderDocumentSuccess(response.data));
  } catch (error) {
    yield put(addStakeHolderDocumentError(error.response.statusText));
  }
}

function* bulkAddNewStakeHolderDocument({ payload }) {
  try {
    const { data, stakeHolderType, stakeHolderId } = payload;
    let response = yield call(bulkAddStakeHolderDocumentRequest, data);
    yield put(bulkAddStakeHolderDocumentSuccess(response.data));

    response = yield call(getPagingStakeHolderDocumentRequest, { stakeHolderType, stakeHolderId });
    yield put(getPagingStakeHolderDocumentSuccess(response.data));
  } catch (error) {
    yield put(addStakeHolderDocumentError(error.response.statusText));
  }
}

function* updateStakeHolderDocument({ payload }) {
  try {
    const { data, stakeHolderType, stakeHolderId } = payload;
    let response = yield call(updateStakeHolderDocumentRequest, data);
    yield put(updateStakeHolderDocumentSuccess(response.data));

    response = yield call(getPagingStakeHolderDocumentRequest, { stakeHolderType, stakeHolderId });
    yield put(getPagingStakeHolderDocumentSuccess(response.data));
  } catch (error) {
    yield put(updateStakeHolderDocumentError(error.response.statusText));
  }
}

function* getPagingStakeHolderDocument({ payload }) {
  try {
    let response = yield call(getPagingStakeHolderDocumentRequest, payload);
    yield put(getPagingStakeHolderDocumentSuccess(response.data));
  } catch (error) {
    yield put(getPagingStakeHolderDocumentError(error.response.statusText));
  }
}

function* getStakeHolderDocumentById({ payload }) {
  try {
    let response = yield call(getStakeHolderDocumentByIdRequest, payload);
    yield put(getStakeHolderDocumentByIdSuccess(response.data));
  } catch (error) {
    yield put(getStakeHolderDocumentByIdError(error.response.statusText));
  }
}

function* changeStatusStakeHolderDocument({ payload }) {
  try {
    const { data, stakeHolderType, stakeHolderId } = payload;
    let response = yield call(changeStatusStakeHolderDocumentRequest, data);

    response = yield call(getPagingStakeHolderDocumentRequest, { stakeHolderType, stakeHolderId });
    yield put(getPagingStakeHolderDocumentSuccess(response.data));
  } catch (error) {
    yield put(getPagingStakeHolderDocumentError(error.response.statusText));
  }
}

function* deleteStakeHolderDocument({ payload }) {
  try {
    const { data, stakeHolderType, stakeHolderId } = payload;
    let response = yield call(deleteStakeHolderDocumentRequest, data);

    response = yield call(getPagingStakeHolderDocumentRequest, { stakeHolderType, stakeHolderId });
    yield put(getPagingStakeHolderDocumentSuccess(response.data));
  } catch (error) {
    yield put(getPagingStakeHolderDocumentError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(STAKE_HOLDER_DOCUMENT_ADD_NEW, addNewStakeHolderDocument);
  yield takeEvery(STAKE_HOLDER_DOCUMENT_BULK_ADD_NEW, bulkAddNewStakeHolderDocument);
  yield takeEvery(STAKE_HOLDER_DOCUMENT_UPDATE, updateStakeHolderDocument);
  yield takeEvery(STAKE_HOLDER_DOCUMENT_GET_PAGING, getPagingStakeHolderDocument);
  yield takeEvery(STAKE_HOLDER_DOCUMENT_GET_BY_ID, getStakeHolderDocumentById);
  yield takeEvery(STAKE_HOLDER_DOCUMENT_CHANGE_STATUS, changeStatusStakeHolderDocument);
  yield takeEvery(STAKE_HOLDER_DOCUMENT_DELETE, deleteStakeHolderDocument);
}
