import {
  GET_IES_CHART_DETAIL_BY_QUERY,
  GET_IES_CHART_DETAIL_BY_QUERY_SUCCESS,
  GET_IES_CHART_DETAIL_BY_QUERY_ERROR,
  GET_IES_EARLY_EXITS_DETAIL_BY_QUERY,
} from './types';

export const getIESChartDetailReportByQuery = query => ({
  type: GET_IES_CHART_DETAIL_BY_QUERY,
  payload: query,
});
export const getIESEarlyExitsDetailReportByQuery = query => ({
  type: GET_IES_EARLY_EXITS_DETAIL_BY_QUERY,
  payload: query,
});

export const getIESChartDetailReportByQuerySuccess = item => ({
  type: GET_IES_CHART_DETAIL_BY_QUERY_SUCCESS,
  payload: item,
});

export const getIESChartDetailReportByQueryError = item => ({
  type: GET_IES_CHART_DETAIL_BY_QUERY_ERROR,
  payload: item,
});
