import {
  IES_CONTRACT_GET_LIST,
  IES_CONTRACT_GET_LIST_SUCCESS,
  IES_CONTRACT_GET_LIST_ERROR,
  IES_CONTRACT_GET_BY_ID,
  IES_CONTRACT_GET_BY_ID_SUCCESS,
  IES_CONTRACT_GET_BY_ID_ERROR,
  IES_CONTRACT_ADD_ITEM,
  IES_CONTRACT_ADD_ITEM_SUCCESS,
  IES_CONTRACT_ADD_ITEM_ERROR,
  IES_CONTRACT_EDIT_ITEM,
  IES_CONTRACT_EDIT_ITEM_SUCCESS,
  IES_CONTRACT_EDIT_ITEM_ERROR,
  IES_CONTRACT_DELETE_ITEM,
  IES_CONTRACT_DELETE_ITEM_SUCCESS,
  IES_CONTRACT_DELETE_ITEM_ERROR,
} from '../actions';

//iesContract
const initialState = {
  items: [],
  item: null,
  itemCount: 0,
  pageCount: 0,
  error: '',
  totalContractAmount: 0,
  totalContractRevised: 0,
  totalOutstanding: 0,
  totalStreamA: 0,
  totalStreamB: 0,
  totalStreamC: 0,
  loading: false,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
};

export default function reducerState(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case IES_CONTRACT_GET_BY_ID:
    case IES_CONTRACT_GET_LIST:
      return {
        ...state,
        loading: true,
        editSuccess: false,
      };

    case IES_CONTRACT_GET_LIST_SUCCESS:
      const {
        totalContractAmount,
        totalContractRevised,
        totalOutstanding,
        totalStreamA,
        totalStreamB,
        totalStreamC,
        itemCount,
        pageCount,
        data,
      } = payload;
      return {
        ...state,
        loading: false,
        totalContractAmount,
        totalContractRevised,
        totalOutstanding,
        totalStreamA,
        totalStreamB,
        totalStreamC,
        itemCount,
        pageCount,
        items: data,
      };

    case IES_CONTRACT_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
      };

    case IES_CONTRACT_ADD_ITEM:
      return {
        ...state,
        loading: true,
        addSuccess: false,
      };

    case IES_CONTRACT_ADD_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        addSuccess: true,
      };
    case IES_CONTRACT_EDIT_ITEM:
      return {
        ...state,
        loading: true,
        editSuccess: false,
      };

    case IES_CONTRACT_EDIT_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        editSuccess: true,
      };

    case IES_CONTRACT_DELETE_ITEM:
      return {
        ...state,
        loading: true,
        deleteSuccess: false,
      };
    case IES_CONTRACT_DELETE_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteSuccess: true,
      };

    case IES_CONTRACT_GET_LIST_ERROR:
    case IES_CONTRACT_ADD_ITEM_ERROR:
    case IES_CONTRACT_EDIT_ITEM_ERROR:
    case IES_CONTRACT_DELETE_ITEM_ERROR:
    case IES_CONTRACT_GET_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
}
