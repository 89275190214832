import {
  SURVEY_NOTIFICATION_GET_PAGING,
  SURVEY_NOTIFICATION_GET_PAGING_ERROR,
  SURVEY_NOTIFICATION_GET_PAGING_SUCCESS,
} from './types';

const initialState = {
  items: [],
  item: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SURVEY_NOTIFICATION_GET_PAGING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case SURVEY_NOTIFICATION_GET_PAGING_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };

    case SURVEY_NOTIFICATION_GET_PAGING_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
