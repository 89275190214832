import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  INVITATION_ADD_NEW,
  INVITATION_EDIT,
  INVITATION_GET_BY_QUERY,
  INVITATION_GET_BY_ID,
  INVITATION_DELETE,
  INVITATION_SEND_INVITATION,
} from './types';

import {
  addNewInvitationError,
  editInvitationError,
  getInvitationByQuerySuccess,
  getInvitationByQueryError,
  getInvitationByIdError,
  getInvitationByIdSuccess,
  deleteInvitationByIdErrorr,
  sendInvitationError,
} from './actions';
import { get } from 'lodash';

const sendInvitationReqeust = async items => {
  return axios.post(`${Config.apiServerHost}/api/Invitation/sendInvitation`, items, {
    headers: { ...(await authHeader()).headers },
  });
};

const addNewInvitationReqeust = async items => {
  return axios.post(`${Config.apiServerHost}/api/Invitation`, items, await authHeader());
};

const editInvitationRequest = async items => {
  return axios.put(`${Config.apiServerHost}/api/Invitation`, items, await authHeader());
};

const getInvitationByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/Invitation/${id}`, await authHeader());
};

const getInvitationByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/Invitation/fetch`;
  return await axios.get(url, {
    params: query,
    headers: { ...(await authHeader()).headers },
  });
};

const deleteInvitationRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/Invitation/${id}`, await authHeader());
};

function* sendInvitation({ payload }) {
  try {
    let response = yield call(sendInvitationReqeust, payload);
    response = yield call(getInvitationByQueryRequest, {});
    yield put(getInvitationByQuerySuccess(response.data));
    // yield put(sendInvitationSuccess(response.data));
  } catch (error) {
    const message =
      typeof error === 'string'
        ? error
        : get(error, 'response.data.message', error.response.statusText);
    yield put(sendInvitationError(message));
  }
}

function* addNewInvitation({ payload }) {
  try {
    const { clientId, programId, servicePlanHeaderId, data } = payload;
    const query = {
      clientId,
      programId,
      servicePlanHeaderId,
    };

    let response = yield call(addNewInvitationReqeust, data);
    response = yield call(getInvitationByQueryRequest, query);
    yield put(getInvitationByQuerySuccess(response.data));
  } catch (error) {
    yield put(addNewInvitationError(error.response.statusText));
  }
}

function* editInvitation({ payload }) {
  try {
    const { clientId, programId, servicePlanHeaderId, data } = payload;
    const query = {
      clientId,
      programId,
      servicePlanHeaderId,
    };

    let response = yield call(editInvitationRequest, data);
    response = yield call(getInvitationByQueryRequest, query);
    yield put(getInvitationByQuerySuccess(response.data));
  } catch (error) {
    yield put(editInvitationError(error.response.statusText));
  }
}

function* getInvitationByQuery({ payload }) {
  try {
    const response = yield call(getInvitationByQueryRequest, payload);
    yield put(getInvitationByQuerySuccess(response.data));
  } catch (error) {
    yield put(getInvitationByQueryError(error.response.statusText));
  }
}

function* getInvitationById({ payload }) {
  try {
    const response = yield call(getInvitationByIdRequest, payload);
    yield put(getInvitationByIdSuccess(response.data));
  } catch (error) {
    yield put(getInvitationByIdError(error.response.statusText));
  }
}

function* deleteInvitation({ payload }) {
  try {
    let response = yield call(deleteInvitationRequest, payload);
    response = yield call(getInvitationByQueryRequest, {});
    yield put(getInvitationByQuerySuccess(response.data));
  } catch (error) {
    yield put(deleteInvitationByIdErrorr(error.response.statusText));
  }
}

export function* watchSendingInvitation() {
  yield takeEvery(INVITATION_SEND_INVITATION, sendInvitation);
}

export function* watchAddNewInvitation() {
  yield takeEvery(INVITATION_ADD_NEW, addNewInvitation);
}

export function* watchEditInvitation() {
  yield takeEvery(INVITATION_EDIT, editInvitation);
}

export function* watchGetInvitationByQuery() {
  yield takeEvery(INVITATION_GET_BY_QUERY, getInvitationByQuery);
}

export function* watchGetInvitationById() {
  yield takeEvery(INVITATION_GET_BY_ID, getInvitationById);
}

export function* watchDeleteInvitation() {
  yield takeEvery(INVITATION_DELETE, deleteInvitation);
}

export default function* rootSaga() {
  yield all([
    fork(watchSendingInvitation),
    fork(watchAddNewInvitation),
    fork(watchEditInvitation),
    fork(watchGetInvitationByQuery),
    fork(watchDeleteInvitation),
    fork(watchGetInvitationById),
  ]);
}
