import {
  COJG_CONTRACT_GET_LIST,
  COJG_CONTRACT_GET_LIST_SUCCESS,
  COJG_CONTRACT_GET_LIST_ERROR,
  COJG_CONTRACT_GET_LIST_WITH_FILTER,
  COJG_CONTRACT_GET_LIST_WITH_ORDER,
  COJG_CONTRACT_GET_LIST_SEARCH,
  COJG_CONTRACT_ADD_ITEM,
  COJG_CONTRACT_ADD_ITEM_SUCCESS,
  COJG_CONTRACT_ADD_ITEM_ERROR,
  COJG_CONTRACT_SELECTED_ITEMS_CHANGE,
  COJG_CONTRACT_EDIT_ITEM,
  COJG_CONTRACT_EDIT_ITEM_SUCCESS,
  COJG_CONTRACT_EDIT_ITEM_ERROR,
  COJG_CONTRACT_DELETE_ITEM,
  COJG_CONTRACT_DELETE_ITEM_SUCCESS,
  COJG_CONTRACT_DELETE_ITEM_ERROR,
  COJG_CONTRACT_GET_BY_ID,
  COJG_CONTRACT_GET_BY_ID_SUCCESS,
  COJG_CONTRACT_GET_BY_ID_ERROR,
  CHEQUE_GET_LIST,
  CHEQUE_GET_LIST_SUCCESS,
  CHEQUE_GET_LIST_ERROR,
  CHEQUE_GET_LIST_WITH_FILTER,
  CHEQUE_GET_LIST_WITH_ORDER,
  CHEQUE_GET_LIST_SEARCH,
  CHEQUE_ADD_ITEM,
  CHEQUE_ADD_ITEM_SUCCESS,
  CHEQUE_ADD_ITEM_ERROR,
  CHEQUE_SELECTED_ITEMS_CHANGE,
  CHEQUE_EDIT_ITEM,
  CHEQUE_EDIT_ITEM_SUCCESS,
  CHEQUE_EDIT_ITEM_ERROR,
  CHEQUE_DELETE_ITEM,
  CHEQUE_DELETE_ITEM_SUCCESS,
  CHEQUE_DELETE_ITEM_ERROR,
  CHEQUE_GET_BY_ID,
  CHEQUE_GET_BY_ID_SUCCESS,
  CHEQUE_GET_BY_ID_ERROR,
  COJG_CONTRACT_GET_BY_EMPLOYER,
  COJG_CONTRACT_GET_BY_EMPLOYER_SUCCESS,
  COJG_CONTRACT_GET_BY_EMPLOYER_ERROR,
} from '../../actions';

export const getCOJGContractByEmployer = query => ({
  type: COJG_CONTRACT_GET_BY_EMPLOYER,
  payload: query,
});

export const getCOJGContractByEmployerSuccess = items => ({
  type: COJG_CONTRACT_GET_BY_EMPLOYER_SUCCESS,
  payload: items,
});

export const getCOJGContractByEmployerError = error => ({
  type: COJG_CONTRACT_GET_BY_EMPLOYER_ERROR,
  payload: error,
});

export const getCOJGContractList = filter => ({
  type: COJG_CONTRACT_GET_LIST,
  payload: filter,
});

export const getCOJGContractListSuccess = items => ({
  type: COJG_CONTRACT_GET_LIST_SUCCESS,
  payload: items,
});

export const getCOJGContractListError = error => ({
  type: COJG_CONTRACT_GET_LIST_ERROR,
  payload: error,
});

export const getCOJGContractListWithFilter = (column, value) => ({
  type: COJG_CONTRACT_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getCOJGContractListWithOrder = column => ({
  type: COJG_CONTRACT_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getCOJGContractListSearch = keyword => ({
  type: COJG_CONTRACT_GET_LIST_SEARCH,
  payload: keyword,
});

export const addCOJGContractItem = item => ({
  type: COJG_CONTRACT_ADD_ITEM,
  payload: item,
});
export const addCOJGContractItemSuccess = items => ({
  type: COJG_CONTRACT_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addCOJGContractItemError = error => ({
  type: COJG_CONTRACT_ADD_ITEM_ERROR,
  payload: error,
});

export const editCOJGContractItem = (item, history) => ({
  type: COJG_CONTRACT_EDIT_ITEM,
  payload: { item, history },
});

export const editCOJGContractItemSuccess = items => ({
  type: COJG_CONTRACT_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editCOJGContractItemError = error => ({
  type: COJG_CONTRACT_EDIT_ITEM_ERROR,
  payload: error,
});

export const selectedCOJGContractItemsChange = selectedItems => ({
  type: COJG_CONTRACT_SELECTED_ITEMS_CHANGE,
  payload: selectedItems,
});

export const deleteCOJGContractItem = item => ({
  type: COJG_CONTRACT_DELETE_ITEM,
  payload: item,
});

export const deleteCOJGContractItemSuccess = items => ({
  type: COJG_CONTRACT_DELETE_ITEM_SUCCESS,
  payload: items,
});

export const deleteCOJGContractItemError = error => ({
  type: COJG_CONTRACT_DELETE_ITEM_ERROR,
  payload: error,
});

export const getCOJGContractById = id => ({
  type: COJG_CONTRACT_GET_BY_ID,
  payload: id,
});
export const getCOJGContractByIdError = error => ({
  type: COJG_CONTRACT_GET_BY_ID_ERROR,
  payload: error,
});
export const getCOJGContractByIdSuccess = cojgContract => ({
  type: COJG_CONTRACT_GET_BY_ID_SUCCESS,
  payload: cojgContract,
});

export const getChequeById = id => ({
  type: CHEQUE_GET_BY_ID,
  payload: id,
});

export const getChequeByIdSuccess = data => ({
  type: CHEQUE_GET_BY_ID_SUCCESS,
  payload: data,
});

export const getChequeByIdError = error => ({
  type: CHEQUE_GET_BY_ID_ERROR,
  payload: error,
});

export const getChequeList = cams => ({
  type: CHEQUE_GET_LIST,
  payload: cams,
});

export const getChequeListSuccess = items => ({
  type: CHEQUE_GET_LIST_SUCCESS,
  payload: items,
});

export const getChequeListError = error => ({
  type: CHEQUE_GET_LIST_ERROR,
  payload: error,
});

export const getChequeListWithFilter = (column, value) => ({
  type: CHEQUE_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getChequeListWithOrder = column => ({
  type: CHEQUE_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getChequeListSearch = keyword => ({
  type: CHEQUE_GET_LIST_SEARCH,
  payload: keyword,
});

export const addChequeItem = item => ({
  type: CHEQUE_ADD_ITEM,
  payload: item,
});
export const addChequeItemSuccess = items => ({
  type: CHEQUE_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addChequeItemError = error => ({
  type: CHEQUE_ADD_ITEM_ERROR,
  payload: error,
});

export const editChequeItem = item => ({
  type: CHEQUE_EDIT_ITEM,
  payload: item,
});

export const editChequeItemSuccess = items => ({
  type: CHEQUE_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editChequeItemError = error => ({
  type: CHEQUE_EDIT_ITEM_ERROR,
  payload: error,
});

export const selectedChequeItemsChange = selectedItems => ({
  type: CHEQUE_SELECTED_ITEMS_CHANGE,
  payload: selectedItems,
});

export const deleteChequeItem = id => ({
  type: CHEQUE_DELETE_ITEM,
  payload: id,
});

export const deleteChequeItemSuccess = items => ({
  type: CHEQUE_DELETE_ITEM_SUCCESS,
  payload: items,
});

export const deleteChequeItemError = error => ({
  type: CHEQUE_DELETE_ITEM_ERROR,
  payload: error,
});
