import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import { APT_GET_BY_CLIENT_ID, APT_SAVE } from './types';

import {
  getAptByParamsError,
  getAptByParamsSuccess,
  saveAptSuccess,
  saveAptError,
} from './actions';

const getAptByParamsRequest = async params => {
  return axios.get(`${Config.apiServerHost}/api/ActionPlanningTool/getByParams`, {
    params,
    headers: { ...(await authHeader()).headers },
  });
};

const saveAptRequest = async requestData => {
  return await axios.post(`${Config.apiServerHost}/api/ActionPlanningTool`, requestData, {
    headers: { ...(await authHeader()).headers },
  });
};

function* getAptByParams({ payload }) {
  try {
    let response = yield call(getAptByParamsRequest, payload);
    yield put(getAptByParamsSuccess(response.data));
  } catch (error) {
    const { data } = error.response;
    let errorMessage = {
      title: error?.response?.statusText ?? 'Error',
      message: data?.message ?? 'Something was wrong.',
    };
    yield put(getAptByParamsError(errorMessage));
  }
}

function* saveApt({ payload }) {
  try {
    const { clientId, servicePlanHeaderId, data } = payload;

    yield call(saveAptRequest, data);
    let response = yield call(getAptByParamsRequest, { clientId, servicePlanHeaderId });
    yield put(getAptByParamsSuccess(response.data));
    yield put(saveAptSuccess(response.data));
  } catch (error) {
    const message = {
      title: 'Update Client Info',
      text: error.response?.data?.message ?? 'something went wrong.',
    };
    yield put(saveAptError(message));
  }
}

export default function* rootSaga() {
  yield takeEvery(APT_GET_BY_CLIENT_ID, getAptByParams);
  yield takeEvery(APT_SAVE, saveApt);
}
