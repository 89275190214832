import {
  EVENT_CALENDAR_ADD_NEW,
  EVENT_CALENDAR_ADD_NEW_SUCCESS,
  EVENT_CALENDAR_ADD_NEW_ERROR,
  EVENT_CALENDAR_DELETE,
  EVENT_CALENDAR_DELETE_ERROR,
  EVENT_CALENDAR_DELETE_SUCCESS,
  EVENT_CALENDAR_EDIT,
  EVENT_CALENDAR_EDIT_ERROR,
  EVENT_CALENDAR_EDIT_SUCCESS,
  EVENT_CALENDAR_GET_BY_ID,
  EVENT_CALENDAR_GET_BY_ID_ERROR,
  EVENT_CALENDAR_GET_BY_ID_SUCCESS,
  EVENT_CALENDAR_GET_LIST,
  EVENT_CALENDAR_GET_LIST_ERROR,
  EVENT_CALENDAR_GET_LIST_SUCCESS,
  EVENT_CALENDAR_ADD_NEW_REQUEST,
  EVENT_CALENDAR_EDIT_REQUEST,
} from '../actions';

// EventCalendar
export const getEventCalendarList = userId => ({
  type: EVENT_CALENDAR_GET_LIST,
  payload: userId,
});

export const getEventCalendarListSuccess = events => ({
  type: EVENT_CALENDAR_GET_LIST_SUCCESS,
  payload: events,
});

export const getEventCalendarListError = error => ({
  type: EVENT_CALENDAR_GET_LIST_ERROR,
  payload: error,
});

export const addNewEventCalendar = event => ({
  type: EVENT_CALENDAR_ADD_NEW,
  payload: event,
});

export const addNewEventCalendarRequest = () => ({
  type: EVENT_CALENDAR_ADD_NEW_REQUEST,
});

export const addNewEventCalendarSuccess = CaseNote => ({
  type: EVENT_CALENDAR_ADD_NEW_SUCCESS,
  payload: CaseNote,
});

export const addNewEventCalendarError = error => ({
  type: EVENT_CALENDAR_ADD_NEW_ERROR,
  payload: error,
});

export const editEventCalendar = CaseNote => ({
  type: EVENT_CALENDAR_EDIT,
  payload: CaseNote,
});

export const editEventCalendarSuccess = caseNote => ({
  type: EVENT_CALENDAR_EDIT_SUCCESS,
  payload: caseNote,
});

export const editEventCalendarError = error => ({
  type: EVENT_CALENDAR_EDIT_ERROR,
  payload: error,
});

export const deleteEventCalendar = id => ({
  type: EVENT_CALENDAR_DELETE,
  payload: id,
});

export const deleteEventCalendarSuccess = id => ({
  type: EVENT_CALENDAR_DELETE_SUCCESS,
  payload: id,
});

export const deleteEventCalendarError = error => ({
  type: EVENT_CALENDAR_DELETE_ERROR,
  payload: error,
});

export const getEventCalendarById = id => ({
  type: EVENT_CALENDAR_GET_BY_ID,
  payload: id,
});

export const getEventCalendarByIdSuccess = caseNote => ({
  type: EVENT_CALENDAR_GET_BY_ID_SUCCESS,
  payload: caseNote,
});

export const getEventCalendarByIdError = error => ({
  type: EVENT_CALENDAR_GET_BY_ID_ERROR,
  payload: error,
});

export const editEventCalendarRequest = () => ({
  type: EVENT_CALENDAR_EDIT_REQUEST,
});
