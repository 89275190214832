import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { CA_REFERRAL_GET_BY_ID, CA_REFERRAL_UPDATE } from './types';

import { getCAReferralByIdError, getCAReferralByIdSuccess, updateCAReferralError } from './actions';

const getCAReferralByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/ClientProfile/client/${id}`, await authHeader());
};

function* getCAReferralById({ payload }) {
  try {
    let response = yield call(getCAReferralByIdRequest, payload);
    yield put(getCAReferralByIdSuccess(response.data));
  } catch (error) {
    yield put(getCAReferralByIdError(error.response.statusText));
  }
}

// const updateCAReferralRequest = async ({ servicePlanHeaderId, clientInfo }) => {
//   return axios.put(
//     `${Config.apiServerHost}/api/ClientProfile/client/${clientInfo.clientId}?servicePlanHeaderId=${servicePlanHeaderId}`,
//     clientInfo,
//     await authHeader()
//   );
// };

function* updateCAReferral({}) {
  try {
    // will implement with api after
    // logger.debug(payload);
    // let response = yield call(updateCAReferralRequest, payload);
    // logger.debug('ahhhhh--->', response);
    // yield put(updateCAReferralSuccess(response.data));
  } catch (error) {
    yield put(updateCAReferralError(error?.response?.statusText));
  }
}

export function* watchGettingClientById() {
  yield takeEvery(CA_REFERRAL_GET_BY_ID, getCAReferralById);
}

export function* watchUpdatingCAReferral() {
  yield takeEvery(CA_REFERRAL_UPDATE, updateCAReferral);
}

export default function* rootSaga() {
  yield all([fork(watchGettingClientById), fork(watchUpdatingCAReferral)]);
}
