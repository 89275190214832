import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  CLIENT_FUND_SUPERVISOR_GET_ALL,
  CLIENT_FUND_SUPERVISOR_GET_BY_ID,
  CLIENT_FUND_SUPERVISOR_ADD_NEW,
  CLIENT_FUND_SUPERVISOR_EDIT,
  CLIENT_FUND_SUPERVISOR_DELETE,
  CLIENT_FUND_SUPERVISOR_EMAIL_NOTIFICATION,
} from '../actions';

import {
  getClientFundSupervisorsSuccess,
  getClientFundSupervisorsError,
  getClientFundSupervisorByIdSuccess,
  getClientFundSupervisorByIdError,
  addNewClientFundSupervisorSuccess,
  addNewClientFundSupervisorError,
  editClientFundSupervisorSuccess,
  editClientFundSupervisorError,
  deleteClientFundSupervisorSuccess,
  deleteClientFundSupervisorError,
} from './actions';

let currentCancelToken;

const getClientFundSupervisorsRequest = async params => {
  // If there's an ongoing request, cancel it
  if (currentCancelToken) {
    currentCancelToken.cancel('Operation canceled due to new request.');
  }

  // Create a new CancelToken source
  currentCancelToken = axios.CancelToken.source();

  const url = `${Config.apiServerHost}/api/ClientFundSupervisor/paging`;
  return axios.post(url, params, {
    cancelToken: currentCancelToken.token,
    headers: { ...(await authHeader()).headers },
  });
};

const getClientFundSupervisorByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/ClientFundSupervisor/${id}`, await authHeader());
};

const addNewClientFundSupervisorReqeust = async item => {
  return axios.post(`${Config.apiServerHost}/api/ClientFundSupervisor`, item, await authHeader());
};

const editClientFundSupervisorRequest = async item => {
  return axios.put(`${Config.apiServerHost}/api/ClientFundSupervisor`, item, await authHeader());
};

const deleteClientFundSupervisorRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/ClientFundSupervisor/${id}`, await authHeader());
};

const onOrOffClientFundSupervisorEmailNotificationRequest = async id => {
  return axios.get(
    `${Config.apiServerHost}/api/ClientFundSupervisor/${id}/emailNotification`,
    await authHeader()
  );
};

function* getClientFundSupervisors({ payload }) {
  try {
    const response = yield call(getClientFundSupervisorsRequest, payload);
    yield put(getClientFundSupervisorsSuccess(response.data));
  } catch (error) {
    yield put(getClientFundSupervisorsError(error.response?.statusText));
  }
}

function* getClientFundSupervisorById({ payload }) {
  try {
    const response = yield call(getClientFundSupervisorByIdRequest, payload);
    yield put(getClientFundSupervisorByIdSuccess(response.data));
  } catch (error) {
    yield put(getClientFundSupervisorByIdError(error.response.statusText));
  }
}

function* addNewClientFundSupervisor({ payload }) {
  try {
    const { page, pageSize, siteIds, data } = payload;

    const query = {
      page,
      pageSize,
      siteIds,
    };
    yield call(addNewClientFundSupervisorReqeust, data);
    const response = yield call(getClientFundSupervisorsRequest, query);
    yield put(getClientFundSupervisorsSuccess(response.data));
  } catch (error) {
    yield put(addNewClientFundSupervisorError(error.response.statusText));
  }
}

function* editClientFundSupervisor({ payload }) {
  try {
    let response = yield call(editClientFundSupervisorRequest, payload);
    response = yield call(getClientFundSupervisorByIdRequest, response.data);
    yield put(editClientFundSupervisorSuccess(response.data));
  } catch (error) {
    yield put(editClientFundSupervisorError(error.response.statusText));
  }
}

function* deleteClientFundSupervisor({ payload }) {
  try {
    const { page, pageSize, siteIds, data } = payload;

    const query = {
      page,
      pageSize,
      siteIds,
    };
    yield call(deleteClientFundSupervisorRequest, data);
    const response = yield call(getClientFundSupervisorsRequest, query);
    yield put(getClientFundSupervisorsSuccess(response.data));
  } catch (error) {
    yield put(deleteClientFundSupervisorError(error.response.statusText));
  }
}

function* onOrOffClientFundSupervisorEmailNotification({ payload }) {
  try {
    const { page, pageSize, siteIds, data } = payload;
    let response = yield call(onOrOffClientFundSupervisorEmailNotificationRequest, data);

    response = yield call(getClientFundSupervisorsRequest, { page, pageSize, siteIds });
    yield put(getClientFundSupervisorsSuccess(response.data));
  } catch (error) {
    yield put(deleteClientFundSupervisorError(error.response.statusText));
  }
}

export function* watchGetClientFundSupervisors() {
  yield takeEvery(CLIENT_FUND_SUPERVISOR_GET_ALL, getClientFundSupervisors);
}

export function* watchGetClientFundSupervisorById() {
  yield takeEvery(CLIENT_FUND_SUPERVISOR_GET_BY_ID, getClientFundSupervisorById);
}

export function* watchAddNewClientFundSupervisor() {
  yield takeEvery(CLIENT_FUND_SUPERVISOR_ADD_NEW, addNewClientFundSupervisor);
}

export function* watchEditClientFundSupervisor() {
  yield takeEvery(CLIENT_FUND_SUPERVISOR_EDIT, editClientFundSupervisor);
}

export function* watchDeleteClientFundSupervisor() {
  yield takeEvery(CLIENT_FUND_SUPERVISOR_DELETE, deleteClientFundSupervisor);
}

export function* watchOnOrOffClientFundSupervisorEmailNotification() {
  yield takeEvery(
    CLIENT_FUND_SUPERVISOR_EMAIL_NOTIFICATION,
    onOrOffClientFundSupervisorEmailNotification
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchGetClientFundSupervisors),
    fork(watchGetClientFundSupervisorById),
    fork(watchAddNewClientFundSupervisor),
    fork(watchEditClientFundSupervisor),
    fork(watchDeleteClientFundSupervisor),
    fork(watchOnOrOffClientFundSupervisorEmailNotification),
  ]);
}
