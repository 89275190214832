import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { POSTING_SENT_TO_CLIENT_GET_BY_ID, POSTING_SENT_TO_CLIENT_GET_LIST } from './types';
import Config from '../../config';

import {
  getPostingSentToClientListSuccess,
  getPostingSentToClientByIdSuccess,
  postingSentToClientError,
} from './actions';

// PostingSentToClient

const getPostingSentToClientListByClientIdRequest = async payload => {
  return axios.post(`${Config.apiServerHost}/api/Posting/fetch`, payload, await authHeader());
};

const getPostingSentToClientByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/Posting/${id}`, await authHeader());
};

function* getPostingSentToClientList({ payload }) {
  try {
    const response = yield call(getPostingSentToClientListByClientIdRequest, payload);
    yield put(getPostingSentToClientListSuccess(response.data));
  } catch (error) {
    yield put(postingSentToClientError(error.response.statusText));
  }
}

function* getPostingSentToClientById({ payload }) {
  try {
    const response = yield call(getPostingSentToClientByIdRequest, payload);
    yield put(getPostingSentToClientByIdSuccess(response.data));
  } catch (error) {
    yield put(postingSentToClientError(error.response.statusText));
  }
}

export function* watchPostingSentToClientList() {
  yield takeEvery(POSTING_SENT_TO_CLIENT_GET_LIST, getPostingSentToClientList);
}

export function* watchPostingSentToClientById() {
  yield takeEvery(POSTING_SENT_TO_CLIENT_GET_BY_ID, getPostingSentToClientById);
}

export default function* rootSaga() {
  yield all([fork(watchPostingSentToClientList), fork(watchPostingSentToClientById)]);
}

export { getPostingSentToClientListByClientIdRequest };
