import { call, put, takeEvery } from 'redux-saga/effects';

import { notificationService } from '../../services/notification-service';
import { getNotificationByQueryError, getNotificationByQuerySuccess } from './actions';
import {
  DISMISS_ALL_NOTIFICATION,
  GET_NOTIFICATION_BY_QUERY,
  READ_NOTIFICATION,
  VIEW_NOTIFICATION,
} from './types';

function* getNotificationByQuery({ payload }) {
  try {
    let response = yield call(notificationService.getUnreadNotifications, payload);
    yield put(getNotificationByQuerySuccess(response.data));
  } catch (error) {
    const message = {
      title: 'Get Checkpoint',
      text: error.response?.data?.message ?? 'something went wrong.',
    };
    yield put(getNotificationByQueryError(message));
  }
}

function* readNotification({ payload }) {
  try {
    let response = yield call(notificationService.readNotificationRequest, payload);
    response = yield call(notificationService.getUnreadNotifications, {});
    yield put(getNotificationByQuerySuccess(response.data));
  } catch (error) {
    const message = {
      title: 'Get Checkpoint',
      text: error.response?.data?.message ?? 'something went wrong.',
    };
    yield put(getNotificationByQueryError(message));
  }
}

function* viewNotification({ payload }) {
  try {
    let response = yield call(notificationService.viewNotificationRequest, payload);
    response = yield call(notificationService.getUnreadNotifications, {});
    yield put(getNotificationByQuerySuccess(response.data));
  } catch (error) {
    const message = {
      title: 'Get Checkpoint',
      text: error.response?.data?.message ?? 'something went wrong.',
    };
    yield put(getNotificationByQueryError(message));
  }
}

function* dismissAllNotification({ payload }) {
  try {
    let response = yield call(notificationService.dismissAllNotifications, payload);
    response = yield call(notificationService.getUnreadNotifications, {});
    yield put(getNotificationByQuerySuccess(response.data));
  } catch (error) {
    const message = {
      title: 'Get Checkpoint',
      text: error.response?.data?.message ?? 'something went wrong.',
    };
    yield put(getNotificationByQueryError(message));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_NOTIFICATION_BY_QUERY, getNotificationByQuery);
  yield takeEvery(READ_NOTIFICATION, readNotification);
  yield takeEvery(VIEW_NOTIFICATION, viewNotification);
  yield takeEvery(DISMISS_ALL_NOTIFICATION, dismissAllNotification);
}

// import axios from 'axios';
// import { authHeader } from '../../helpers/auth-header';
// import Config from '../../config';
// import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
// import {
//   OUTCOME_CHECKPOINT_GET_BY_QUERY,
//   OUTCOME_CHECKPOINT_UPDATE,
//   OUTCOME_CHECKPOINT_SYNC,
//   GET_REFRESH_OUTCOME_CHECKPOINT_GET_BY_QUERY,
//   OUTCOME_CHECKPOINT_DETECT_DIFFERENCE,
// } from './types';

// import {
//   getOutcomeCheckpointByQueryError,
//   getOutcomeCheckpointByQuerySuccess,
//   updateOutcomeCheckpointError,
//   syncOutcomeCheckpointError,
//   getRefreshOutcomeCheckpointByQuerySuccess,
//   syncOutcomeCheckpointSuccess,
//   detectDifferenceOutcomeCheckpointSuccess,
// } from './actions';
// import { getClientServicePlanRequest } from '../client/saga';
// import { getClientServicePlanSuccess } from '../actions';

// const getLiveCheckpointTransactions = async () => {
//   return axios.get(
//     `${Config.apiServerHost}/api/OutcomeCheckpoint/transaction/live`,
//     await authHeader()
//   );
// };

// const getOutcomeCheckpointByIdRequest = async id => {
//   return axios.get(`${Config.apiServerHost}/api/OutcomeCheckpoint/${id}`, {
//     headers: { ...(await authHeader()).headers },
//   });
// };

// const updateSingleOutcomeCheckpointRequest = async payload => {
//   return axios.post(`${Config.apiServerHost}/api/OutcomeCheckpoint/updateSingle`, payload, {
//     headers: { ...(await authHeader()).headers },
//   });
// };

// const getOutcomeCheckpointByQueryRequest = async queryParam => {
//   return axios.get(`${Config.apiServerHost}/api/OutcomeCheckpoint`, {
//     params: queryParam,
//     headers: { ...(await authHeader()).headers },
//   });
// };

// function* getOutcomeCheckpointByQuery({ payload }) {
//   try {
//     let response = yield call(getOutcomeCheckpointByQueryRequest, payload);
//     yield put(getOutcomeCheckpointByQuerySuccess(response.data));
//   } catch (error) {
//     const message = {
//       title: 'Get Checkpoint',
//       text: error.response?.data?.message ?? 'something went wrong.',
//     };
//     yield put(getOutcomeCheckpointByQueryError(message));
//   }
// }

// function* getRefreshOutcomeCheckpointByQuery({ payload }) {
//   try {
//     let response = yield call(getOutcomeCheckpointByQueryRequest, payload);
//     yield put(getRefreshOutcomeCheckpointByQuerySuccess(response.data));
//   } catch (error) {
//     const message = {
//       title: 'Get Checkpoint',
//       text: error.response?.data?.message ?? 'something went wrong.',
//     };
//     yield put(getOutcomeCheckpointByQueryError(message));
//   }
// }

// const saveOutcomeCheckpointAttachmentRequest = async (attachments, documentType, description) => {
//   return axios.post(
//     `${Config.apiServerHost}/api/OutcomeCheckpoint/saveAttachment?documentType=${documentType}&description=${description}`,
//     attachments,
//     await authHeader()
//   );
// };

// const saveOutcomeCheckpointAttachmentExistingRequest = async (
//   attachments,
//   documentType,
//   description
// ) => {
//   return axios.post(
//     `${Config.apiServerHost}/api/OutcomeCheckpoint/saveAttachmentExisting?documentType=${documentType}&description=${description}`,
//     attachments,
//     await authHeader()
//   );
// };

// const detectDifferenceOutcomeCheckpointRequest = async queryParam => {
//   return axios.get(`${Config.apiServerHost}/api/OutcomeCheckpoint/detectDifference`, {
//     params: queryParam,
//     headers: { ...(await authHeader()).headers },
//   });
// };

// const updateOutcomeCheckpointRequest = async outcomeQuestionnaire => {
//   return axios.post(
//     `${Config.apiServerHost}/api/OutcomeCheckpoint`,
//     outcomeQuestionnaire,
//     await authHeader()
//   );
// };

// const syncOutcomeCheckpointRequest = async data => {
//   return axios.post(
//     `${Config.apiServerHost}/api/OutcomeCheckpoint/sync?clientId=${data.clientId}&servicePlanHeaderId=${data.servicePlanHeaderId}&clientReferenceNumberOverride=${data.clientReferenceNumberOverride}`,
//     '{}',
//     await authHeader()
//   );
// };

// function* detectDifferenceOutcomeCheckpoint({ payload }) {
//   try {
//     let response = yield call(detectDifferenceOutcomeCheckpointRequest, payload);
//     yield put(detectDifferenceOutcomeCheckpointSuccess(response.data));
//   } catch (error) {
//     const message = {
//       title: 'Update Checkpoint',
//       text: error.response?.data?.message ?? 'something went wrong.',
//     };
//     yield put(updateOutcomeCheckpointError(message));
//   }
// }

// function* updateOutcomeCheckpoint({ payload }) {
//   try {
//     const { clientId, servicePlanHeaderId, items } = payload;
//     let response = yield call(updateOutcomeCheckpointRequest, items);
//     response = yield call(getOutcomeCheckpointByQueryRequest, { clientId, servicePlanHeaderId });
//     yield put(getOutcomeCheckpointByQuerySuccess(response.data));
//   } catch (error) {
//     const message = {
//       title: 'Update Checkpoint',
//       text: error.response?.data?.message ?? 'something went wrong.',
//     };
//     yield put(updateOutcomeCheckpointError(message));
//   }
// }

// function* syncOutcomeCheckpoint({ payload }) {
//   try {
//     const { clientId, servicePlanHeaderId, clientReferenceNumberOverride } = payload;
//     let response = yield call(syncOutcomeCheckpointRequest, {
//       clientId,
//       servicePlanHeaderId,
//       clientReferenceNumberOverride,
//     });
//     response = yield call(getOutcomeCheckpointByQueryRequest, { clientId, servicePlanHeaderId });
//     yield put(getOutcomeCheckpointByQuerySuccess(response.data));
//     yield put(syncOutcomeCheckpointSuccess(response.data));
//     response = yield call(getClientServicePlanRequest, { clientId, id: servicePlanHeaderId });
//     yield put(getClientServicePlanSuccess(response.data));
//   } catch (error) {
//     const message = {
//       title: 'Sync Checkpoint',
//       text: error.response?.data?.message ?? 'something went wrong.',
//     };
//     yield put(syncOutcomeCheckpointError(message));
//   }
// }

// export default function* rootSaga() {
//   yield takeEvery(OUTCOME_CHECKPOINT_GET_BY_QUERY, getOutcomeCheckpointByQuery);
//   yield takeEvery(GET_REFRESH_OUTCOME_CHECKPOINT_GET_BY_QUERY, getRefreshOutcomeCheckpointByQuery);
//   yield takeEvery(OUTCOME_CHECKPOINT_UPDATE, updateOutcomeCheckpoint);
//   yield takeEvery(OUTCOME_CHECKPOINT_SYNC, syncOutcomeCheckpoint);
//   yield takeEvery(OUTCOME_CHECKPOINT_DETECT_DIFFERENCE, detectDifferenceOutcomeCheckpoint);
// }

// export {
//   saveOutcomeCheckpointAttachmentRequest,
//   saveOutcomeCheckpointAttachmentExistingRequest,
//   getLiveCheckpointTransactions,
//   getOutcomeCheckpointByIdRequest,
//   updateSingleOutcomeCheckpointRequest,
//   detectDifferenceOutcomeCheckpointRequest,
// };
