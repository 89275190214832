// COMMUNITY_PARTNER
export const COMMUNITY_PARTNER_GET_BY_ID = 'COMMUNITY_PARTNER_GET_BY_ID';
export const COMMUNITY_PARTNER_GET_BY_ID_SUCCESS = 'COMMUNITY_PARTNER_GET_BY_ID_SUCCESS';
export const COMMUNITY_PARTNER_GET_BY_ID_ERROR = 'COMMUNITY_PARTNER_GET_BY_ID_ERROR';

export const COMMUNITY_PARTNER_EDIT = 'COMMUNITY_PARTNER_EDIT';
export const COMMUNITY_PARTNER_EDIT_SUCCESS = 'COMMUNITY_PARTNER_EDIT_SUCCESS';
export const COMMUNITY_PARTNER_EDIT_ERROR = 'COMMUNITY_PARTNER_EDIT_ERROR';

export const COMMUNITY_PARTNER_ADD_NEW = 'COMMUNITY_PARTNER_ADD_NEW';
export const COMMUNITY_PARTNER_ADD_NEW_SUCCESS = 'COMMUNITY_PARTNER_ADD_NEW_SUCCESS';
export const COMMUNITY_PARTNER_ADD_NEW_ERROR = 'COMMUNITY_PARTNER_ADD_NEW_ERROR';
export const COMMUNITY_PARTNER_ADD_NEW_REQUEST = 'COMMUNITY_PARTNER_ADD_NEW_REQUEST';

export const COMMUNITY_PARTNER_DELETE = 'COMMUNITY_PARTNER_DELETE';
export const COMMUNITY_PARTNER_DELETE_SUCCESS = 'COMMUNITY_PARTNER_DELETE_SUCCESS';
export const COMMUNITY_PARTNER_DELETE_ERROR = 'COMMUNITY_PARTNER_DELETE_ERROR';

export const COMMUNITY_PARTNER_GET_BY_QUERY = 'COMMUNITY_PARTNER_GET_BY_QUERY';
export const COMMUNITY_PARTNER_GET_BY_QUERY_SUCCESS = 'COMMUNITY_PARTNER_GET_BY_QUERY_SUCCESS';
export const COMMUNITY_PARTNER_GET_BY_QUERY_ERROR = 'COMMUNITY_PARTNER_GET_BY_QUERY_ERROR';
