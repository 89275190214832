import {
  CLIENT_FUND_SETTING_ADD_NEW,
  CLIENT_FUND_SETTING_ADD_NEW_SUCCESS,
  CLIENT_FUND_SETTING_ADD_NEW_ERROR,
  CLIENT_FUND_SETTING_DELETE,
  CLIENT_FUND_SETTING_DELETE_ERROR,
  CLIENT_FUND_SETTING_DELETE_SUCCESS,
  CLIENT_FUND_SETTING_EDIT,
  CLIENT_FUND_SETTING_EDIT_ERROR,
  CLIENT_FUND_SETTING_EDIT_SUCCESS,
  CLIENT_FUND_SETTING_GET_BY_ID,
  CLIENT_FUND_SETTING_GET_BY_ID_ERROR,
  CLIENT_FUND_SETTING_GET_BY_ID_SUCCESS,
  CLIENT_FUND_SETTING_GET_LIST,
  CLIENT_FUND_SETTING_GET_LIST_ERROR,
  CLIENT_FUND_SETTING_GET_LIST_SUCCESS,
  CLIENT_FUND_SETTING_ADD_NEW_REQUEST,
} from '../actions';

export const getClientFundSettingList = () => ({
  type: CLIENT_FUND_SETTING_GET_LIST,
});

export const getClientFundSettingListSuccess = ClientFundSettings => ({
  type: CLIENT_FUND_SETTING_GET_LIST_SUCCESS,
  payload: ClientFundSettings,
});

export const getClientFundSettingListError = error => ({
  type: CLIENT_FUND_SETTING_GET_LIST_ERROR,
  payload: error,
});

export const addNewClientFundSetting = (ClientFundSetting, history) => ({
  type: CLIENT_FUND_SETTING_ADD_NEW,
  payload: { ClientFundSetting, history },
});

export const addNewClientFundSettingRequest = () => ({
  type: CLIENT_FUND_SETTING_ADD_NEW_REQUEST,
});

export const addNewClientFundSettingSuccess = ClientFundSetting => ({
  type: CLIENT_FUND_SETTING_ADD_NEW_SUCCESS,
  payload: ClientFundSetting,
});

export const addNewClientFundSettingError = error => ({
  type: CLIENT_FUND_SETTING_ADD_NEW_ERROR,
  payload: error,
});

export const editClientFundSetting = (ClientFundSetting, history) => ({
  type: CLIENT_FUND_SETTING_EDIT,
  payload: { ClientFundSetting, history },
});

export const editClientFundSettingSuccess = ClientFundSetting => ({
  type: CLIENT_FUND_SETTING_EDIT_SUCCESS,
  payload: ClientFundSetting,
});

export const editClientFundSettingError = error => ({
  type: CLIENT_FUND_SETTING_EDIT_ERROR,
  payload: error,
});

export const deleteClientFundSetting = id => ({
  type: CLIENT_FUND_SETTING_DELETE,
  payload: id,
});

export const deleteClientFundSettingSuccess = id => ({
  type: CLIENT_FUND_SETTING_DELETE_SUCCESS,
  payload: id,
});

export const deleteClientFundSettingError = error => ({
  type: CLIENT_FUND_SETTING_DELETE_ERROR,
  payload: error,
});

export const getClientFundSettingById = id => ({
  type: CLIENT_FUND_SETTING_GET_BY_ID,
  payload: id,
});

export const getClientFundSettingByIdSuccess = ClientFundSetting => ({
  type: CLIENT_FUND_SETTING_GET_BY_ID_SUCCESS,
  payload: ClientFundSetting,
});

export const getClientFundSettingByIdError = error => ({
  type: CLIENT_FUND_SETTING_GET_BY_ID_ERROR,
  payload: error,
});
