import {
  GET_COJG_CONTRACT_DOCUMENTS_BY_ID,
  GET_COJG_CONTRACT_DOCUMENTS_BY_ID_SUCCESS,
  GET_COJG_CONTRACT_DOCUMENTS_BY_QUERY,
  GET_COJG_CONTRACT_DOCUMENTS_BY_QUERY_SUCCESS,
  EDIT_COJG_CONTRACT_DOCUMENTS,
  UPLOAD_COJG_CONTRACT_DOCUMENTS,
  DELETE_COJG_CONTRACT_DOCUMENTS,
  COJG_CONTRACT_DOCUMENT_ERROR,
} from './types';

const initState = {
  items: [],
  item: null,
  loading: false,
  itemCount: 0,
  pageCount: 0,
  error: null,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
};

export default (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_COJG_CONTRACT_DOCUMENTS_BY_QUERY:
    case EDIT_COJG_CONTRACT_DOCUMENTS:
      return {
        ...state,
        loading: false,
      };

    case GET_COJG_CONTRACT_DOCUMENTS_BY_ID:
      return {
        ...state,
        loading: false,
        editSuccess: false,
      };

    case UPLOAD_COJG_CONTRACT_DOCUMENTS:
      return {
        ...state,
        loading: false,
        addSuccess: false,
      };

    case DELETE_COJG_CONTRACT_DOCUMENTS:
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      };

    case GET_COJG_CONTRACT_DOCUMENTS_BY_QUERY_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        loading: true,
        items: data,
        itemCount,
        pageCount,
        addSuccess: true,
        editSuccess: true,
        deleteSuccess: true,
      };

    case GET_COJG_CONTRACT_DOCUMENTS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: true,
        item: payload,
        error: null,
      };

    case COJG_CONTRACT_DOCUMENT_ERROR:
      return { ...state, loading: true, error: payload };

    default:
      return { ...state };
  }
};
