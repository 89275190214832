import { GET_IES_DASHBOARD, GET_IES_DASHBOARD_SUCCESS, GET_IES_DASHBOARD_ERROR } from './types';

// item
const initialState = {
  item: null,
  loading: false,
  error: null,
};

const iesDashboard = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_IES_DASHBOARD:
      localStorage.setItem('ies_dashboard_query', JSON.stringify(payload));
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_IES_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
      };

    case GET_IES_DASHBOARD_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return { ...state };
  }
};

export default iesDashboard;
