import {
  AGGREGATE_ADD_NEW,
  AGGREGATE_ADD_NEW_SUCCESS,
  AGGREGATE_ADD_NEW_ERROR,
  AGGREGATE_DELETE,
  AGGREGATE_DELETE_ERROR,
  AGGREGATE_DELETE_SUCCESS,
  AGGREGATE_EDIT,
  AGGREGATE_EDIT_ERROR,
  AGGREGATE_EDIT_SUCCESS,
  AGGREGATE_GET_BY_ID,
  AGGREGATE_GET_BY_ID_ERROR,
  AGGREGATE_GET_BY_ID_SUCCESS,
  AGGREGATE_GET_LIST,
  AGGREGATE_GET_LIST_ERROR,
  AGGREGATE_GET_LIST_SUCCESS,
  AGGREGATE_ADD_NEW_REQUEST,
  AGGREGATE_REPORT_EDIT,
  AGGREGATE_REPORT_EDIT_ERROR,
  AGGREGATE_REPORT_EDIT_SUCCESS,
  AGGREGATE_REPORT_GET_BY_ID,
  AGGREGATE_REPORT_GET_BY_ID_ERROR,
  AGGREGATE_REPORT_GET_BY_ID_SUCCESS,
  AGGREGATE_REPORT_GET_LIST,
  AGGREGATE_REPORT_GET_LIST_ERROR,
  AGGREGATE_REPORT_GET_LIST_SUCCESS,
} from '../actions';
const initialState = {
  aggregates: [],
  aggregatesReport: [],
  aggregate: null,
  aggregateReport: null,
  searchKeyword: '',
  loading: false,
  error: null,
  addSuccess: false,
  editSuccess: false,
  editReportSuccess: false,
  deleteSuccess: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case AGGREGATE_GET_BY_ID:
      return {
        ...state,
        loading: false,
        error: null,
        editSuccess: false,
      };
    case AGGREGATE_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: true,
        aggregate: payload,
      };

    case AGGREGATE_ADD_NEW_REQUEST:
      return {
        ...state,
        addSuccess: false,
      };
    case AGGREGATE_GET_LIST:
    case AGGREGATE_REPORT_GET_LIST:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case AGGREGATE_REPORT_GET_LIST_SUCCESS:
      return {
        ...state,
        aggregatesReport: payload,
        loading: true,
      };

    case AGGREGATE_GET_LIST_SUCCESS:
      return {
        ...state,
        aggregates: payload,
        loading: true,
      };
    case AGGREGATE_ADD_NEW:
      return {
        ...state,
        loading: false,
        error: null,
        addSuccess: false,
      };

    case AGGREGATE_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: true,
        aggregate: payload,
        aggregates: state.aggregates.concat(payload),
        addSuccess: true,
      };

    case AGGREGATE_EDIT:
      return {
        ...state,
        loading: false,
        error: null,
        editSuccess: false,
      };
    case AGGREGATE_EDIT_SUCCESS:
      return {
        ...state,
        aggregates: state.aggregates.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        loading: true,
        aggregate: payload,
        error: null,
        editSuccess: true,
      };

    case AGGREGATE_REPORT_GET_BY_ID:
      return {
        ...state,
        loading: false,
        error: null,
        editReportSuccess: false,
      };
    case AGGREGATE_REPORT_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: true,
        aggregateReport: payload,
      };

    case AGGREGATE_REPORT_EDIT:
      return {
        ...state,
        loading: false,
        error: null,
        editReportSuccess: false,
      };
    case AGGREGATE_REPORT_EDIT_SUCCESS:
      return {
        ...state,
        aggregatesReport: state.aggregatesReport.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        loading: true,
        aggregateReport: payload,
        error: null,
        editReportSuccess: true,
      };
    case AGGREGATE_DELETE:
      return {
        ...state,
        loading: false,
        error: null,
        deleteSuccess: false,
      };
    case AGGREGATE_DELETE_SUCCESS:
      return {
        ...state,
        loading: true,
        aggregates: state.aggregates.filter(c => c.id !== payload),
        deleteSuccess: true,
      };

    case AGGREGATE_DELETE_ERROR:
    case AGGREGATE_ADD_NEW_ERROR:
    case AGGREGATE_EDIT_ERROR:
    case AGGREGATE_GET_BY_ID_ERROR:
    case AGGREGATE_GET_LIST_ERROR:
    case AGGREGATE_REPORT_EDIT_ERROR:
    case AGGREGATE_REPORT_GET_BY_ID_ERROR:
    case AGGREGATE_REPORT_GET_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
