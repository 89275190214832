import {
  YJC_CHEQUE_ADD_NEW,
  YJC_CHEQUE_ADD_NEW_SUCCESS,
  YJC_CHEQUE_ADD_NEW_ERROR,
  YJC_CHEQUE_DELETE,
  YJC_CHEQUE_DELETE_ERROR,
  YJC_CHEQUE_DELETE_SUCCESS,
  YJC_CHEQUE_EDIT,
  YJC_CHEQUE_EDIT_ERROR,
  YJC_CHEQUE_EDIT_SUCCESS,
  YJC_CHEQUE_GET_BY_ID,
  YJC_CHEQUE_GET_BY_ID_ERROR,
  YJC_CHEQUE_GET_BY_ID_SUCCESS,
  YJC_CHEQUE_ADD_NEW_REQUEST,
  YJC_CHEQUE_QUERY,
  YJC_CHEQUE_QUERY_SUCCESS,
  YJC_CHEQUE_QUERY_ERROR,
} from '../actions';

// yjcCheques
const initialState = {
  yjcCheques: [],
  yjcCheque: null,
  loading: false,
  error: null,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case YJC_CHEQUE_GET_BY_ID:
      return {
        ...state,
        loading: false,
        error: null,
        editSuccess: false,
      };
    case YJC_CHEQUE_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: true,
        yjcCheque: payload,
      };

    case YJC_CHEQUE_ADD_NEW_REQUEST:
      return {
        ...state,
        addSuccess: false,
      };

    case YJC_CHEQUE_QUERY:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case YJC_CHEQUE_QUERY_SUCCESS:
      return {
        ...state,
        yjcCheques: payload,
        loading: true,
      };
    case YJC_CHEQUE_ADD_NEW:
      return {
        ...state,
        loading: false,
        error: null,
        addSuccess: false,
      };

    case YJC_CHEQUE_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: true,
        yjcCheque: payload,
        yjcCheques: state.yjcCheques.concat(payload),
        addSuccess: true,
      };

    case YJC_CHEQUE_EDIT:
      return {
        ...state,
        loading: false,
        error: null,
        editSuccess: false,
      };
    case YJC_CHEQUE_EDIT_SUCCESS:
      return {
        ...state,
        loading: true,
        yjcCheques: state.yjcCheques.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        yjcCheque: payload,
        error: null,
        editSuccess: true,
      };
    case YJC_CHEQUE_DELETE:
      return {
        ...state,
        loading: false,
        error: null,
        deleteSuccess: false,
      };
    case YJC_CHEQUE_DELETE_SUCCESS:
      return {
        ...state,
        loading: true,
        yjcCheques: state.yjcCheques.filter(c => c.id !== payload),
        deleteSuccess: true,
      };
    case YJC_CHEQUE_QUERY_ERROR:
    case YJC_CHEQUE_DELETE_ERROR:
    case YJC_CHEQUE_ADD_NEW_ERROR:
    case YJC_CHEQUE_EDIT_ERROR:
    case YJC_CHEQUE_GET_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
