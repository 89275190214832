import { logger } from '../../logger';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import axios from 'axios';
import Config from '../../config';

import {
  EMPLOYER_GET_LIST,
  EMPLOYER_ADD_ITEM,
  EMPLOYER_EDIT_ITEM,
  EMPLOYER_DELETE_ITEM,
  EMPLOYER_GET_BY_ID,
} from './employer.types';

import {
  getEmployerListSuccess,
  getEmployerListError,
  addEmployerItemError,
  editEmployerItemError,
  getEmployerByIdSuccess,
  getEmployerByIdError,
  deleteEmployerItemError,
} from './actions';

const addEmployerItemRequest = async employer => {
  return await axios.post(`${Config.apiServerHost}/api/Employer`, employer, await authHeader());
};

const poshEmployerListRequest = async employer => {
  return await axios.put(`${Config.apiServerHost}/api/Employer`, employer, await authHeader());
};

const deleteEmployerListRequest = async id => {
  return await axios.delete(`${Config.apiServerHost}/api/Employer/${id}`, await authHeader());
};

const deleteEmployerFlagRequest = async id => {
  return await axios.delete(`${Config.apiServerHost}/api/Employer/${id}`, await authHeader());
};

const getEmployerByIdRequest = async id => {
  return await axios.get(`${Config.apiServerHost}/api/employer/${id}`, await authHeader());
};

const getEmployerListRequest = async query => {
  let url = `${Config.apiServerHost}/api/Employer/fetch`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

function* getEmployerListItems({ payload }) {
  try {
    const response = yield call(getEmployerListRequest, payload);
    yield put(getEmployerListSuccess(response.data));
  } catch (error) {
    yield put(getEmployerListError(error));
  }
}

function* editEmployerItem({ payload }) {
  try {
    const { item, history } = payload;

    yield call(poshEmployerListRequest, item);
    const response = yield call(getEmployerListRequest, {});
    yield put(getEmployerListSuccess(response.data));
    history.push('/app/employers');
  } catch (error) {
    logger.debug(error);
    yield put(editEmployerItemError(error));
  }
}

function* deleteEmployerItem({ payload }) {
  try {
    const { id, isDeleted } = payload;
    let response;

    if (isDeleted) {
      response = yield call(deleteEmployerListRequest, id);
    } else {
      response = yield call(deleteEmployerFlagRequest, id);
    }
    response = yield call(getEmployerListRequest, {});
    yield put(getEmployerListSuccess(response.data));
  } catch (error) {
    yield put(deleteEmployerItemError(error));
  }
}

function* addEmployerItem({ payload }) {
  try {
    const { item, history } = payload;
    yield call(addEmployerItemRequest, item);
    const response = yield call(getEmployerListRequest, {});
    yield put(getEmployerListSuccess(response.data));
    history.push('/app/employers');
  } catch (error) {
    yield put(addEmployerItemError(error));
  }
}

function* getEmployerById({ payload }) {
  try {
    const response = yield call(getEmployerByIdRequest, payload);
    yield put(getEmployerByIdSuccess(response.data));
  } catch (error) {
    yield put(getEmployerByIdError(error));
  }
}

export function* watchEmployerById() {
  yield takeEvery(EMPLOYER_GET_BY_ID, getEmployerById);
}

export function* watchGetList() {
  yield takeEvery(EMPLOYER_GET_LIST, getEmployerListItems);
}

export function* wathcAddItem() {
  yield takeEvery(EMPLOYER_ADD_ITEM, addEmployerItem);
}
export function* wathcEditItem() {
  yield takeEvery(EMPLOYER_EDIT_ITEM, editEmployerItem);
}
export function* wathcDeleteItem() {
  yield takeEvery(EMPLOYER_DELETE_ITEM, deleteEmployerItem);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(wathcAddItem),
    fork(wathcEditItem),
    fork(wathcDeleteItem),
    fork(watchEmployerById),
  ]);
}
export { getEmployerListRequest };
