import {
  STAKE_HOLDER_CONTACT_ADD_NEW,
  STAKE_HOLDER_CONTACT_ADD_NEW_ERROR,
  STAKE_HOLDER_CONTACT_ADD_NEW_SUCCESS,
  STAKE_HOLDER_CONTACT_CHANGE_STATUS,
  STAKE_HOLDER_CONTACT_CHANGE_STATUS_ERROR,
  STAKE_HOLDER_CONTACT_CHANGE_STATUS_SUCCESS,
  STAKE_HOLDER_CONTACT_DELETE,
  STAKE_HOLDER_CONTACT_DELETE_ERROR,
  STAKE_HOLDER_CONTACT_DELETE_SUCCESS,
  STAKE_HOLDER_CONTACT_GET_BY_ID,
  STAKE_HOLDER_CONTACT_GET_BY_ID_ERROR,
  STAKE_HOLDER_CONTACT_GET_BY_ID_SUCCESS,
  STAKE_HOLDER_CONTACT_GET_PAGING,
  STAKE_HOLDER_CONTACT_GET_PAGING_ERROR,
  STAKE_HOLDER_CONTACT_GET_PAGING_SUCCESS,
  STAKE_HOLDER_CONTACT_UPDATE,
  STAKE_HOLDER_CONTACT_UPDATE_ERROR,
  STAKE_HOLDER_CONTACT_UPDATE_SUCCESS,
} from './types';

// StakeHolderContact

export const getPagingStakeHolderContact = items => ({
  type: STAKE_HOLDER_CONTACT_GET_PAGING,
  payload: items,
});

export const getPagingStakeHolderContactSuccess = items => ({
  type: STAKE_HOLDER_CONTACT_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingStakeHolderContactError = error => ({
  type: STAKE_HOLDER_CONTACT_GET_PAGING_ERROR,
  payload: error,
});

export const addStakeHolderContact = items => ({
  type: STAKE_HOLDER_CONTACT_ADD_NEW,
  payload: items,
});

export const addStakeHolderContactSuccess = items => ({
  type: STAKE_HOLDER_CONTACT_ADD_NEW_SUCCESS,
  payload: items,
});

export const addStakeHolderContactError = error => ({
  type: STAKE_HOLDER_CONTACT_ADD_NEW_ERROR,
  payload: error,
});

export const getStakeHolderContactById = items => ({
  type: STAKE_HOLDER_CONTACT_GET_BY_ID,
  payload: items,
});

export const getStakeHolderContactByIdSuccess = items => ({
  type: STAKE_HOLDER_CONTACT_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getStakeHolderContactByIdError = error => ({
  type: STAKE_HOLDER_CONTACT_GET_BY_ID_ERROR,
  payload: error,
});

export const updateStakeHolderContact = items => ({
  type: STAKE_HOLDER_CONTACT_UPDATE,
  payload: items,
});

export const updateStakeHolderContactSuccess = items => ({
  type: STAKE_HOLDER_CONTACT_UPDATE_SUCCESS,
  payload: items,
});

export const updateStakeHolderContactError = error => ({
  type: STAKE_HOLDER_CONTACT_UPDATE_ERROR,
  payload: error,
});

export const changeStatusStakeHolderContact = items => ({
  type: STAKE_HOLDER_CONTACT_CHANGE_STATUS,
  payload: items,
});

export const changeStatusStakeHolderContactSuccess = items => ({
  type: STAKE_HOLDER_CONTACT_CHANGE_STATUS_SUCCESS,
  payload: items,
});

export const changeStatusStakeHolderContactError = error => ({
  type: STAKE_HOLDER_CONTACT_CHANGE_STATUS_ERROR,
  payload: error,
});

export const deleteStakeHolderContact = items => ({
  type: STAKE_HOLDER_CONTACT_DELETE,
  payload: items,
});

export const deleteStakeHolderContactSuccess = items => ({
  type: STAKE_HOLDER_CONTACT_DELETE_SUCCESS,
  payload: items,
});

export const deleteStakeHolderContactError = error => ({
  type: STAKE_HOLDER_CONTACT_DELETE_ERROR,
  payload: error,
});
