import { CAMPAIGN_DASHBOARD_GET_PAGIN_ERROR, CAMPAIGN_DASHBOARD_GET_PAGIN_SUCCESS } from './types';

const initialState = {
  item: null,
  items: [],
  loading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
  total: 0,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CAMPAIGN_DASHBOARD_GET_PAGIN_SUCCESS: {
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        loading: false,
        error: null,
        itemCount,
        pageCount,
        items: data,
      };
    }
    case CAMPAIGN_DASHBOARD_GET_PAGIN_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
}
