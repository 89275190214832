import axios from 'axios';
import FileSaver from 'file-saver';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { GET_CLIENT_SUMMARY, SAVE_CLIENT_SUMMARY } from './types';
import Config from '../../config';

import { getClientSummarySuccess, clientSummaryError } from './actions';

const getClientSummaryRequest = async payload => {
  let url = `${Config.apiServerHost}/api/ClientSummary`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const saveClientSummaryReqeust = async clientSummary => {
  return axios.post(`${Config.apiServerHost}/api/ClientSummary`, clientSummary, await authHeader());
};

const exportClientSummaryToPDF = async (query, filename) => {
  const { headers } = await authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };

  return await fetch(
    buildUrl(`${Config.apiServerHost}/api/ClientSummary/exportToPDF`, query),
    requestOptions
  )
    .then(response => response.blob())
    .then(data => {
      FileSaver.saveAs(new Blob([data]), filename);
    });
};

function* getClientSummary({ payload }) {
  try {
    const response = yield call(getClientSummaryRequest, payload);
    yield put(getClientSummarySuccess(response.data));
  } catch (error) {
    yield put(clientSummaryError(error.response.statusText));
  }
}

function* saveClientSummary({ payload }) {
  try {
    const { clientId, programId, servicePlanHeaderId, data } = payload;
    yield call(saveClientSummaryReqeust, data);
    const query = {
      clientId: clientId ?? '',
      programId: programId ?? '',
      servicePlanHeaderId: servicePlanHeaderId ?? '',
    };
    let response = yield call(getClientSummaryRequest, query);
    yield put(getClientSummarySuccess(response.data));
  } catch (error) {
    yield put(clientSummaryError(error.response.statusText));
  }
}

export function* watchClientSummary() {
  yield takeEvery(GET_CLIENT_SUMMARY, getClientSummary);
}

export function* watchSaveClientSummary() {
  yield takeEvery(SAVE_CLIENT_SUMMARY, saveClientSummary);
}

export default function* rootSaga() {
  yield all([fork(watchClientSummary), fork(watchSaveClientSummary)]);
}

export { exportClientSummaryToPDF };
