import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  CLIENT_COURSE_ADD_NEW,
  CLIENT_COURSE_EDIT,
  CLIENT_COURSE_GET_BY_QUERY,
  CLIENT_COURSE_GET_BY_ID,
  CLIENT_COURSE_DELETE,
} from './types';

import {
  addNewClientCourseError,
  editClientCourseError,
  getClientCourseByQuerySuccess,
  getClientCourseByQueryError,
  getClientCourseByIdError,
  getClientCourseByIdSuccess,
  deleteClientCourseByIdErrorr,
} from './actions';

const addNewClientCourseReqeust = async items => {
  return axios.post(`${Config.apiServerHost}/api/ClientCourse`, items, await authHeader());
};

const editClientCourseRequest = async items => {
  return axios.put(`${Config.apiServerHost}/api/ClientCourse`, items, await authHeader());
};

const getClientCourseByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/ClientCourse/${id}`, await authHeader());
};

const getClientCourseByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/ClientCourse/fetch`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const deleteClientCourseRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/ClientCourse/${id}`, await authHeader());
};

function* addNewClientCourse({ payload }) {
  try {
    const { clientId, programId, servicePlanHeaderId, data } = payload;
    const query = {
      clientId,
      programId,
      servicePlanHeaderId,
    };

    let response = yield call(addNewClientCourseReqeust, data);
    response = yield call(getClientCourseByQueryRequest, query);
    yield put(getClientCourseByQuerySuccess(response.data));
  } catch (error) {
    yield put(addNewClientCourseError(error.response.statusText));
  }
}

function* editClientCourse({ payload }) {
  try {
    const { clientId, programId, servicePlanHeaderId, data } = payload;
    const query = {
      clientId,
      programId,
      servicePlanHeaderId,
    };

    let response = yield call(editClientCourseRequest, data);
    response = yield call(getClientCourseByQueryRequest, query);
    yield put(getClientCourseByQuerySuccess(response.data));
  } catch (error) {
    yield put(editClientCourseError(error.response.statusText));
  }
}

function* getClientCourseByQuery({ payload }) {
  try {
    const response = yield call(getClientCourseByQueryRequest, payload);
    yield put(getClientCourseByQuerySuccess(response.data));
  } catch (error) {
    yield put(getClientCourseByQueryError(error.response.statusText));
  }
}

function* getClientCourseById({ payload }) {
  try {
    const response = yield call(getClientCourseByIdRequest, payload);
    yield put(getClientCourseByIdSuccess(response.data));
  } catch (error) {
    yield put(getClientCourseByIdError(error.response.statusText));
  }
}

function* deleteClientCourse({ payload }) {
  try {
    const { programId, clientId, servicePlanHeaderId, data } = payload;
    const query = {
      programId,
      clientId,
      servicePlanHeaderId,
    };

    let response = yield call(deleteClientCourseRequest, data);
    response = yield call(getClientCourseByQueryRequest, query);
    yield put(getClientCourseByQuerySuccess(response.data));
  } catch (error) {
    yield put(deleteClientCourseByIdErrorr(error.response.statusText));
  }
}

export function* watchAddNewClientCourse() {
  yield takeEvery(CLIENT_COURSE_ADD_NEW, addNewClientCourse);
}

export function* watchEditClientCourse() {
  yield takeEvery(CLIENT_COURSE_EDIT, editClientCourse);
}

export function* watchGetClientCourseByQuery() {
  yield takeEvery(CLIENT_COURSE_GET_BY_QUERY, getClientCourseByQuery);
}

export function* watchGetClientCourseById() {
  yield takeEvery(CLIENT_COURSE_GET_BY_ID, getClientCourseById);
}

export function* watchDeleteClientCourse() {
  yield takeEvery(CLIENT_COURSE_DELETE, deleteClientCourse);
}

export default function* rootSaga() {
  yield all([
    fork(watchAddNewClientCourse),
    fork(watchEditClientCourse),
    fork(watchGetClientCourseByQuery),
    fork(watchDeleteClientCourse),
    fork(watchGetClientCourseById),
  ]);
}
