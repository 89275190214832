import {
  EMAIL_TEMPLATE_ADD_NEW,
  EMAIL_TEMPLATE_ADD_NEW_SUCCESS,
  EMAIL_TEMPLATE_ADD_NEW_ERROR,
  EMAIL_TEMPLATE_EDIT,
  EMAIL_TEMPLATE_EDIT_ERROR,
  EMAIL_TEMPLATE_EDIT_SUCCESS,
  EMAIL_TEMPLATE_GET_BY_QUERY,
  EMAIL_TEMPLATE_GET_BY_ID,
  EMAIL_TEMPLATE_GET_BY_QUERY_SUCCESS,
  EMAIL_TEMPLATE_GET_BY_QUERY_ERROR,
  EMAIL_TEMPLATE_GET_BY_ID_SUCCESS,
  EMAIL_TEMPLATE_ADD_NEW_REQUEST,
  EMAIL_TEMPLATE_DELETE,
  EMAIL_TEMPLATE_DELETE_SUCCESS,
  EMAIL_TEMPLATE_DELETE_ERROR,
  EMAIL_TEMPLATE_GET_BY_ID_ERROR,
} from './types';

// EmailTemplate

export const addNewEmailTemplate = items => ({
  type: EMAIL_TEMPLATE_ADD_NEW,
  payload: items,
});

export const addNewEmailTemplateRequest = () => ({
  type: EMAIL_TEMPLATE_ADD_NEW_REQUEST,
});

export const addNewEmailTemplateSuccess = items => ({
  type: EMAIL_TEMPLATE_ADD_NEW_SUCCESS,
  payload: items,
});

export const addNewEmailTemplateError = error => ({
  type: EMAIL_TEMPLATE_ADD_NEW_ERROR,
  payload: error,
});

export const editEmailTemplate = item => ({
  type: EMAIL_TEMPLATE_EDIT,
  payload: item,
});

export const editEmailTemplateSuccess = item => ({
  type: EMAIL_TEMPLATE_EDIT_SUCCESS,
  payload: item,
});

export const editEmailTemplateError = error => ({
  type: EMAIL_TEMPLATE_EDIT_ERROR,
  payload: error,
});

export const getEmailTemplateByQuery = query => ({
  type: EMAIL_TEMPLATE_GET_BY_QUERY,
  payload: query,
});

export const getEmailTemplateByQuerySuccess = item => ({
  type: EMAIL_TEMPLATE_GET_BY_QUERY_SUCCESS,
  payload: item,
});

export const getEmailTemplateByQueryError = error => ({
  type: EMAIL_TEMPLATE_GET_BY_QUERY_ERROR,
  payload: error,
});

export const getEmailTemplateById = id => ({
  type: EMAIL_TEMPLATE_GET_BY_ID,
  payload: id,
});

export const getEmailTemplateByIdSuccess = item => ({
  type: EMAIL_TEMPLATE_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getEmailTemplateByIdError = error => ({
  type: EMAIL_TEMPLATE_GET_BY_ID_ERROR,
  payload: error,
});

export const deleteEmailTemplateById = id => ({
  type: EMAIL_TEMPLATE_DELETE,
  payload: id,
});

export const deleteEmailTemplateByIdSuccess = item => ({
  type: EMAIL_TEMPLATE_DELETE_SUCCESS,
  payload: item,
});

export const deleteEmailTemplateByIdErrorr = item => ({
  type: EMAIL_TEMPLATE_DELETE_ERROR,
  payload: item,
});
