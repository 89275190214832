import {
  ESUITE_ACTIVITY_GET_BY_QUERY,
  ESUITE_ACTIVITY_GET_BY_QUERY_SUCCESS,
  ESUITE_ACTIVITY_GET_BY_QUERY_ERROR,
  ESUITE_ACTIVITY_APPROVE,
  ESUITE_ACTIVITY_APPROVE_SUCCESS,
  ESUITE_ACTIVITY_APPROVE_ERROR,
  CLEAR_ESUITE_ACTIVITY_MESSAGE,
  ESUITE_ACTIVITY_REJECT,
  ESUITE_ACTIVITY_REJECT_SUCCESS,
  ESUITE_ACTIVITY_REJECT_ERROR,
  ESUITE_ACTIVITY_SAVE_AND_APPROVE,
  ESUITE_ACTIVITY_SAVE_AND_APPROVE_SUCCESS,
  ESUITE_ACTIVITY_SAVE_AND_APPROVE_ERROR,
} from './types';

// ESuite

export const getESuiteActivityByQuery = query => ({
  type: ESUITE_ACTIVITY_GET_BY_QUERY,
  payload: query,
});

export const getESuiteActivityByQuerySuccess = item => ({
  type: ESUITE_ACTIVITY_GET_BY_QUERY_SUCCESS,
  payload: item,
});

export const getESuiteActivityByQueryError = error => ({
  type: ESUITE_ACTIVITY_GET_BY_QUERY_ERROR,
  payload: error,
});

export const saveAndApproveESuiteActivity = payload => ({
  type: ESUITE_ACTIVITY_SAVE_AND_APPROVE,
  payload,
});

export const saveAndApproveESuiteActivitySuccess = payload => ({
  type: ESUITE_ACTIVITY_SAVE_AND_APPROVE_SUCCESS,
  payload,
});

export const saveAndApproveESuiteActivityError = payload => ({
  type: ESUITE_ACTIVITY_SAVE_AND_APPROVE_ERROR,
  payload,
});

export const approveESuiteActivity = payload => ({
  type: ESUITE_ACTIVITY_APPROVE,
  payload,
});

export const approveESuiteActivitySuccess = payload => ({
  type: ESUITE_ACTIVITY_APPROVE_SUCCESS,
  payload,
});

export const approveESuiteActivityError = payload => ({
  type: ESUITE_ACTIVITY_APPROVE_ERROR,
  payload,
});

export const rejectESuiteActivity = payload => ({
  type: ESUITE_ACTIVITY_REJECT,
  payload,
});

export const rejectESuiteActivitySuccess = payload => ({
  type: ESUITE_ACTIVITY_REJECT_SUCCESS,
  payload,
});

export const rejectESuiteActivityError = payload => ({
  type: ESUITE_ACTIVITY_REJECT_ERROR,
  payload,
});

export const clearESuiteActivityMessage = () => ({
  type: CLEAR_ESUITE_ACTIVITY_MESSAGE,
});
