import {
  EVENT_PARTICIPANT_ADD_NEW,
  EVENT_PARTICIPANT_ADD_NEW_SUCCESS,
  EVENT_PARTICIPANT_ADD_NEW_ERROR,
  EVENT_PARTICIPANT_DELETE,
  EVENT_PARTICIPANT_DELETE_ERROR,
  EVENT_PARTICIPANT_DELETE_SUCCESS,
  EVENT_PARTICIPANT_EDIT,
  EVENT_PARTICIPANT_EDIT_ERROR,
  EVENT_PARTICIPANT_EDIT_SUCCESS,
  EVENT_PARTICIPANT_GET_BY_ID,
  EVENT_PARTICIPANT_GET_BY_ID_ERROR,
  EVENT_PARTICIPANT_GET_BY_ID_SUCCESS,
  EVENT_PARTICIPANT_GET_LIST,
  EVENT_PARTICIPANT_GET_LIST_ERROR,
  EVENT_PARTICIPANT_GET_LIST_SUCCESS,
  EVENT_PARTICIPANT_ADD_NEW_REQUEST,
} from '../actions';

// eventParticipants
const initialState = {
  eventParticipants: [],
  eventParticipant: null,
  notifications: [],
  loading: false,
  error: null,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case EVENT_PARTICIPANT_GET_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };

    case EVENT_PARTICIPANT_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        eventParticipant: payload,
      };

    case EVENT_PARTICIPANT_ADD_NEW_REQUEST:
      return {
        ...state,
        addSuccess: false,
      };
    case EVENT_PARTICIPANT_GET_LIST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case EVENT_PARTICIPANT_GET_LIST_SUCCESS:
      return {
        ...state,
        eventParticipants: payload,
        loading: false,
      };
    case EVENT_PARTICIPANT_ADD_NEW:
      return {
        ...state,
        loading: true,
        error: null,
        addSuccess: false,
      };

    case EVENT_PARTICIPANT_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        eventParticipant: payload,
        eventParticipants: state.eventParticipants.concat(payload),
        addSuccess: true,
      };

    case EVENT_PARTICIPANT_EDIT:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case EVENT_PARTICIPANT_EDIT_SUCCESS:
      return {
        ...state,
        eventParticipants: state.eventParticipants.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        eventParticipant: payload,
        error: null,
        loading: false,
        editSuccess: true,
      };
    case EVENT_PARTICIPANT_DELETE:
      return {
        ...state,
        loading: true,
        error: null,
        deleteSuccess: false,
      };
    case EVENT_PARTICIPANT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        eventParticipants: state.eventParticipants.filter(c => c.id !== payload),
        deleteSuccess: true,
      };

    case EVENT_PARTICIPANT_DELETE_ERROR:
    case EVENT_PARTICIPANT_ADD_NEW_ERROR:
    case EVENT_PARTICIPANT_EDIT_ERROR:
    case EVENT_PARTICIPANT_GET_BY_ID_ERROR:
    case EVENT_PARTICIPANT_GET_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
