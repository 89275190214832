import {
  TARGET_SCHEME_ADD_NEW,
  TARGET_SCHEME_ADD_NEW_SUCCESS,
  TARGET_SCHEME_ADD_NEW_ERROR,
  TARGET_SCHEME_EDIT,
  TARGET_SCHEME_EDIT_ERROR,
  TARGET_SCHEME_EDIT_SUCCESS,
  TARGET_SCHEME_GET_BY_QUERY,
  TARGET_SCHEME_GET_BY_ID,
  TARGET_SCHEME_GET_BY_QUERY_SUCCESS,
  TARGET_SCHEME_GET_BY_QUERY_ERROR,
  TARGET_SCHEME_GET_BY_ID_SUCCESS,
  TARGET_SCHEME_ADD_NEW_REQUEST,
  TARGET_SCHEME_DELETE,
  TARGET_SCHEME_DELETE_SUCCESS,
  TARGET_SCHEME_DELETE_ERROR,
  TARGET_SCHEME_GET_BY_ID_ERROR,
  TARGET_SCHEME_GET_OPTIONS,
  TARGET_SCHEME_GET_OPTIONS_SUCCESS,
} from './types';

// TargetScheme

export const addNewTargetScheme = items => ({
  type: TARGET_SCHEME_ADD_NEW,
  payload: items,
});

export const addNewTargetSchemeRequest = () => ({
  type: TARGET_SCHEME_ADD_NEW_REQUEST,
});

export const addNewTargetSchemeSuccess = items => ({
  type: TARGET_SCHEME_ADD_NEW_SUCCESS,
  payload: items,
});

export const addNewTargetSchemeError = error => ({
  type: TARGET_SCHEME_ADD_NEW_ERROR,
  payload: error,
});

export const editTargetScheme = item => ({
  type: TARGET_SCHEME_EDIT,
  payload: item,
});

export const editTargetSchemeSuccess = item => ({
  type: TARGET_SCHEME_EDIT_SUCCESS,
  payload: item,
});

export const editTargetSchemeError = error => ({
  type: TARGET_SCHEME_EDIT_ERROR,
  payload: error,
});

export const getTargetSchemeByQuery = query => ({
  type: TARGET_SCHEME_GET_BY_QUERY,
  payload: query,
});

export const getTargetSchemeByQuerySuccess = item => ({
  type: TARGET_SCHEME_GET_BY_QUERY_SUCCESS,
  payload: item,
});

export const getTargetSchemeByQueryError = error => ({
  type: TARGET_SCHEME_GET_BY_QUERY_ERROR,
  payload: error,
});

export const getTargetSchemeById = id => ({
  type: TARGET_SCHEME_GET_BY_ID,
  payload: id,
});

export const getTargetSchemeByIdSuccess = item => ({
  type: TARGET_SCHEME_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getTargetSchemeByIdError = error => ({
  type: TARGET_SCHEME_GET_BY_ID_ERROR,
  payload: error,
});

export const deleteTargetSchemeById = id => ({
  type: TARGET_SCHEME_DELETE,
  payload: id,
});

export const deleteTargetSchemeByIdSuccess = item => ({
  type: TARGET_SCHEME_DELETE_SUCCESS,
  payload: item,
});

export const deleteTargetSchemeByIdErrorr = item => ({
  type: TARGET_SCHEME_DELETE_ERROR,
  payload: item,
});

export const getTargetSchemeOptions = () => ({
  type: TARGET_SCHEME_GET_OPTIONS,
});

export const getTargetSchemeOptionsSuccess = options => ({
  type: TARGET_SCHEME_GET_OPTIONS_SUCCESS,
  payload: options,
});
