import {
  CLIENT_INFO_GET_BY_ID,
  CLIENT_INFO_GET_BY_ID_ERROR,
  CLIENT_INFO_GET_BY_ID_SUCCESS,
  CLIENT_INFO_UPDATE,
  CLIENT_INFO_UPDATE_SUCCESS,
  CLIENT_INFO_UPDATE_ERROR,
  CLIENT_INFO_SYNC,
  CLIENT_INFO_SYNC_SUCCESS,
  CLIENT_INFO_SYNC_ERROR,
  CLIENT_INFO_CLEAR_NOTIFY,
  CLIENT_INFO_REPAIR,
  CLIENT_INFO_REPAIR_SUCCESS,
  CLIENT_INFO_REPAIR_ERROR,
  CLIENT_INFO_RESET,
  CLIENT_INFO_RESET_SUCCESS,
  CLIENT_INFO_RESET_ERROR,
  CLIENT_INFO_DETECH_DIFFERENCE,
  CLIENT_INFO_DETECH_DIFFERENCE_SUCCESS,
} from './types';

export const repairClientInfoById = id => ({
  type: CLIENT_INFO_REPAIR,
  payload: id,
});

export const repairClientInfoByIdSuccess = clientInfo => ({
  type: CLIENT_INFO_REPAIR_SUCCESS,
  payload: clientInfo,
});

export const repairClientInfoByIdError = error => ({
  type: CLIENT_INFO_REPAIR_ERROR,
  payload: error,
});

export const resetClientInfo = payload => ({
  type: CLIENT_INFO_RESET,
  payload,
});

export const resetClientInfoSuccess = clientInfo => ({
  type: CLIENT_INFO_RESET_SUCCESS,
  payload: clientInfo,
});

export const resetClientInfoError = error => ({
  type: CLIENT_INFO_RESET_ERROR,
  payload: error,
});

export const getClientInfoById = id => ({
  type: CLIENT_INFO_GET_BY_ID,
  payload: id,
});

export const detectDifferenceClientInfo = payload => ({
  type: CLIENT_INFO_DETECH_DIFFERENCE,
  payload,
});

export const detectDifferenceClientInfoSuccess = payload => ({
  type: CLIENT_INFO_DETECH_DIFFERENCE_SUCCESS,
  payload,
});

export const getClientInfoByIdSuccess = clientInfo => ({
  type: CLIENT_INFO_GET_BY_ID_SUCCESS,
  payload: clientInfo,
});

export const getClientInfoByIdError = error => ({
  type: CLIENT_INFO_GET_BY_ID_ERROR,
  payload: error,
});

export const updateClientInfo = clientInfo => ({
  type: CLIENT_INFO_UPDATE,
  payload: clientInfo,
});

export const updateClientInfoSuccess = clientInfo => ({
  type: CLIENT_INFO_UPDATE_SUCCESS,
  payload: clientInfo,
});

export const updateClientInfoError = error => ({
  type: CLIENT_INFO_UPDATE_ERROR,
  payload: error,
});

export const syncClientInfo = (clientId, servicePlanHeaderId, clientReferenceNumberOverride) => ({
  type: CLIENT_INFO_SYNC,
  payload: { clientId, servicePlanHeaderId, clientReferenceNumberOverride },
});

export const syncClientInfoSuccess = clientInfo => ({
  type: CLIENT_INFO_SYNC_SUCCESS,
  payload: clientInfo,
});

export const syncClientInfoError = error => ({
  type: CLIENT_INFO_SYNC_ERROR,
  payload: error,
});

export const clearClientInfoNotify = () => ({
  type: CLIENT_INFO_CLEAR_NOTIFY,
});
