import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../../helpers/auth-header';
import Config from '../../../config';
import axios from 'axios';

import { COJG_FINANCIAL_GET_LIST } from '../../actions';

import { getCOJGFinancialListSuccess, getCOJGFinancialListError } from './actions';

const getCOJGFinancialListRequest = async filter => {
  return await axios.get(
    `${Config.apiServerHost}/api/contract/cojg/financial-report?fromDate=${filter.payload.fromDate}&toDate=${filter.payload.toDate}&fiscalYear=${filter.payload.fiscalYear}`,
    await authHeader()
  );
};

function* getCOJGFinancialListItems(payload) {
  try {
    const response = yield call(getCOJGFinancialListRequest, payload);
    yield put(getCOJGFinancialListSuccess(response.data));
  } catch (error) {
    yield put(getCOJGFinancialListError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(COJG_FINANCIAL_GET_LIST, getCOJGFinancialListItems);
}

export default function* rootSaga() {
  yield all([fork(watchGetList)]);
}
