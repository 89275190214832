import {
  GET_USER_DOCUMENTS,
  GET_USER_DOCUMENTS_SUCCESS,
  GET_USER_DOCUMENTS_ERROR,
  SAVE_USER_DOCUMENTS,
  SAVE_USER_DOCUMENTS_SUCCESS,
  SAVE_USER_DOCUMENTS_ERROR,
  DELETE_USER_DOCUMENT_BY_ID,
  DELETE_USER_DOCUMENT_BY_ID_SUCCESS,
  DELETE_USER_DOCUMENT_BY_ID_ERROR,
  GET_USER_DOCUMENTS_BY_ID,
  GET_USER_DOCUMENTS_BY_ID_SUCCESS,
  GET_USER_DOCUMENTS_BY_ID_ERROR,
  UPDATE_USER_DOCUMENTS,
  UPDATE_USER_DOCUMENTS_SUCCESS,
  UPDATE_USER_DOCUMENTS_ERROR,
} from './types';

const initialState = {
  documents: [],
  document: null,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case DELETE_USER_DOCUMENT_BY_ID:
    case SAVE_USER_DOCUMENTS:
    case GET_USER_DOCUMENTS:
    case GET_USER_DOCUMENTS_BY_ID:
    case UPDATE_USER_DOCUMENTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_USER_DOCUMENT_BY_ID_SUCCESS:
    case SAVE_USER_DOCUMENTS_SUCCESS:
    case GET_USER_DOCUMENTS_SUCCESS:
    case UPDATE_USER_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: payload,
      };
    case GET_USER_DOCUMENTS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        document: payload,
      };
    case GET_USER_DOCUMENTS_ERROR:
    case SAVE_USER_DOCUMENTS_ERROR:
    case DELETE_USER_DOCUMENT_BY_ID_ERROR:
    case GET_USER_DOCUMENTS_BY_ID_ERROR:
    case UPDATE_USER_DOCUMENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
