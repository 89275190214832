import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  AGGREGATE_DELETE,
  AGGREGATE_EDIT,
  AGGREGATE_GET_BY_ID,
  AGGREGATE_ADD_NEW,
  AGGREGATE_GET_LIST,
  AGGREGATE_REPORT_EDIT,
  AGGREGATE_REPORT_GET_BY_ID,
  AGGREGATE_REPORT_GET_LIST,
} from '../actions';

import {
  getAggregateListSuccess,
  getAggregateListError,
  editAggregateSuccess,
  editAggregateError,
  addNewAggregateSuccess,
  addNewAggregateError,
  deleteAggregateSuccess,
  deleteAggregateError,
  getAggregateByIdSuccess,
  getAggregateReportListSuccess,
  getAggregateReportListError,
  getAggregateReportByIdSuccess,
  getAggregateReportByIdError,
  updateAggregateReportSuccess,
  updateAggregateReportError,
} from './actions';

const getAggregateReportListRequest = async () => {
  return axios.get(`${Config.apiServerHost}/api/client/AggregateReport`, await authHeader());
};

const getAggregateReportByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/client/AggregateReport/${id}`, await authHeader());
};

const updateAggregateReportReqeust = async aggregateReport => {
  return axios.post(
    `${Config.apiServerHost}/api/client/aggregate`,
    aggregateReport,
    await authHeader()
  );
};

const getAggregateListRequest = async filter => {
  return axios.get(
    `${Config.apiServerHost}/api/client/aggregate?filter=${filter}`,
    await authHeader()
  );
};

const addNewAggregateReqeust = async caseNote => {
  return axios.post(`${Config.apiServerHost}/api/client/aggregate`, caseNote, await authHeader());
};

const editAggregateRequest = async caseNote => {
  return axios.put(`${Config.apiServerHost}/api/client/aggregate`, caseNote, await authHeader());
};

const deleteAggregateRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/client/aggregate/${id}`, await authHeader());
};

const getAggregateByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/client/aggregate/${id}`, await authHeader());
};

function* getAggregateReportList() {
  try {
    const response = yield call(getAggregateReportListRequest);
    yield put(getAggregateReportListSuccess(response.data));
  } catch (error) {
    yield put(getAggregateReportListError(error.response.statusText));
  }
}

function* getAggregateReportById({ payload }) {
  try {
    const response = yield call(getAggregateReportByIdRequest, payload);
    yield put(getAggregateReportByIdSuccess(response.data));
  } catch (error) {
    yield put(getAggregateReportByIdError(error.response.statusText));
  }
}

function* updateAggregateReport({ payload }) {
  try {
    let response = yield call(updateAggregateReportReqeust, payload);
    response = yield call(getAggregateReportByIdRequest, response.data);
    yield put(updateAggregateReportSuccess(response.data));
  } catch (error) {
    yield put(updateAggregateReportError(error.response.statusText));
  }
}

function* getAggregateList(payload) {
  try {
    const response = yield call(getAggregateListRequest, payload.payload.filter);
    yield put(getAggregateListSuccess(response.data));
  } catch (error) {
    yield put(getAggregateListError(error.response.statusText));
  }
}

function* addNewAggregate({ payload }) {
  try {
    let response = yield call(addNewAggregateReqeust, payload);
    response = yield call(getAggregateByIdRequest, response.data);
    yield put(addNewAggregateSuccess(response.data));
  } catch (error) {
    yield put(addNewAggregateError(error.response.statusText));
  }
}

function* editAggregate({ payload }) {
  try {
    let response = yield call(editAggregateRequest, payload);
    response = yield call(getAggregateByIdRequest, response.data);
    yield put(editAggregateSuccess(response.data));
  } catch (error) {
    yield put(editAggregateError(error.response.statusText));
  }
}

function* getAggregateById({ payload }) {
  try {
    const response = yield call(getAggregateByIdRequest, payload);
    yield put(getAggregateByIdSuccess(response.data));
  } catch (error) {
    yield put(editAggregateError(error.response.statusText));
  }
}

function* deleteAggregate({ payload }) {
  try {
    const response = yield call(deleteAggregateRequest, payload);
    yield put(deleteAggregateSuccess(response.data));
  } catch (error) {
    yield put(deleteAggregateError(error.response.statusText));
  }
}

export function* watchAggregateReportList() {
  yield takeEvery(AGGREGATE_REPORT_GET_LIST, getAggregateReportList);
}

export function* watchGetAggregateReportById() {
  yield takeEvery(AGGREGATE_REPORT_GET_BY_ID, getAggregateReportById);
}

export function* watchUpdateAggregateReport() {
  yield takeEvery(AGGREGATE_REPORT_EDIT, updateAggregateReport);
}

export function* watchAggregateList() {
  yield takeEvery(AGGREGATE_GET_LIST, getAggregateList);
}

export function* watchAddNewAggregate() {
  yield takeEvery(AGGREGATE_ADD_NEW, addNewAggregate);
}

export function* watchAggregateById() {
  yield takeEvery(AGGREGATE_GET_BY_ID, getAggregateById);
}

export function* watchEditAggregate() {
  yield takeEvery(AGGREGATE_EDIT, editAggregate);
}

export function* watchDeleteAggregate() {
  yield takeEvery(AGGREGATE_DELETE, deleteAggregate);
}

export default function* rootSaga() {
  yield all([
    fork(watchAggregateList),
    fork(watchAddNewAggregate),
    fork(watchAggregateById),
    fork(watchEditAggregate),
    fork(watchDeleteAggregate),
    fork(watchAggregateReportList),
    fork(watchGetAggregateReportById),
    fork(watchUpdateAggregateReport),
  ]);
}
