import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  GET_USER_DOCUMENTS,
  SAVE_USER_DOCUMENTS,
  DELETE_USER_DOCUMENT_BY_ID,
  GET_USER_DOCUMENTS_BY_ID,
  UPDATE_USER_DOCUMENTS,
} from './types';

import {
  getUserDocumentsSuccess,
  getUserDocumentsError,
  saveUserDocumentsError,
  deleteUserDocumentByIdError,
  getUserDocumentsByIdSuccess,
  getUserDocumentsByIdError,
} from './actions';

const getDocumentsRequest = async query => {
  let url = `${Config.apiServerHost}/api/UserDocument/search`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getDocumentsByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/UserDocument/searchDocumentsPaging`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getDocumentsByIdRequest = async id => {
  return await axios.get(`${Config.apiServerHost}/api/UserDocument/${id}`, await authHeader());
};

const updateDocumentsRequest = async payload => {
  return axios.put(`${Config.apiServerHost}/api/UserDocument`, payload, await authHeader());
};

const saveDocumentsRequest = async documents => {
  return axios.post(`${Config.apiServerHost}/api/UserDocument`, documents, await authHeader());
};

const createFileUploadSecureLinkRequest = async (clientId, servicePlanHeaderId) => {
  return axios.post(
    `${Config.apiServerHost}/api/fileUpload/securelink/client/${clientId}/servicePlan/${servicePlanHeaderId}`,
    null,
    await authHeader()
  );
};

const deleteDocumentByIdRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/UserDocument/${id}`, await authHeader());
};

function* getDocuments({ payload }) {
  try {
    const response = yield call(getDocumentsRequest, payload);
    yield put(getUserDocumentsSuccess(response.data));
  } catch (error) {
    yield put(getUserDocumentsError(error.response.statusText));
  }
}

function* getDocumentsById({ payload }) {
  try {
    const response = yield call(getDocumentsByIdRequest, payload);
    yield put(getUserDocumentsByIdSuccess(response.data));
  } catch (error) {
    yield put(getUserDocumentsByIdError(error.response.statusText));
  }
}

function* saveDocuments({ payload }) {
  try {
    const { documents } = payload;
    let response = yield call(saveDocumentsRequest, documents);
    response = yield call(getDocumentsRequest, response.data);
    yield put(getUserDocumentsSuccess(response.data));
  } catch (error) {
    yield put(saveUserDocumentsError(error.response.statusText));
  }
}

function* updateDocuments({ payload }) {
  try {
    const { document, page, pageSize, fitler, tagId } = payload;
    let response = yield call(updateDocumentsRequest, document);
    const query = {
      page,
      pageSize,
      fitler,
      tagId,
    };
    response = yield call(getDocumentsRequest, query);
    yield put(getUserDocumentsSuccess(response.data));
  } catch (error) {
    yield put(saveUserDocumentsError(error.response.statusText));
  }
}

function* deleteDocumentById({ payload }) {
  try {
    const { page, pageSize, filter, tagId, id } = payload;
    const query = {
      page,
      pageSize,
      filter,
      tagId,
    };
    let response = yield call(deleteDocumentByIdRequest, id);
    response = yield call(getDocumentsRequest, query);
    yield put(getUserDocumentsSuccess(response.data));
  } catch (error) {
    yield put(deleteUserDocumentByIdError(error.response.statusText));
  }
}

export function* watchDocuments() {
  yield takeEvery(GET_USER_DOCUMENTS, getDocuments);
}

export function* watchDocumentsById() {
  yield takeEvery(GET_USER_DOCUMENTS_BY_ID, getDocumentsById);
}

export function* watchSaveingDocuments() {
  yield takeEvery(SAVE_USER_DOCUMENTS, saveDocuments);
}

export function* watchUpdatingDocuments() {
  yield takeEvery(UPDATE_USER_DOCUMENTS, updateDocuments);
}

export function* watchDeleteDocumentById() {
  yield takeEvery(DELETE_USER_DOCUMENT_BY_ID, deleteDocumentById);
}

export default function* rootSaga() {
  yield all([
    fork(watchDocumentsById),
    fork(watchDocuments),
    fork(watchUpdatingDocuments),
    fork(watchSaveingDocuments),
    fork(watchDeleteDocumentById),
  ]);
}

export { getDocumentsByQueryRequest, createFileUploadSecureLinkRequest };
