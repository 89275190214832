import {
  JOURNEY_ACK_ADD_NEW,
  JOURNEY_ACK_ADD_NEW_ERROR,
  JOURNEY_ACK_ADD_NEW_SUCCESS,
  JOURNEY_ACK_GET_BY_ID_SUCCESS,
} from './types';
import { displayMessage, SEVERITY } from '../../helpers/Utils';
// item
const initialState = {
  item: null,
  loading: false,
  error: null,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case JOURNEY_ACK_ADD_NEW:
      return {
        ...state,
        loading: true,
        error: null,
        item: null,
      };
    case JOURNEY_ACK_ADD_NEW_SUCCESS:
      const lang = localStorage.getItem('currentLanguage')
        ? localStorage.getItem('currentLanguage')
        : 'en';

      displayMessage({
        title: lang === 'en' ? 'Journey Acknowledgment' : 'Voyage du Client',
        message:
          lang === 'en'
            ? 'Journey Acknowledgment has been successfully saved'
            : 'La reconnaissance du voyage a été enregistrée avec succès.',
        severity: SEVERITY.INFO,
      });
      return {
        ...state,
        loading: false,
        error: null,
        item: payload,
      };

    case JOURNEY_ACK_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        item: payload,
      };

    case JOURNEY_ACK_ADD_NEW_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
}
