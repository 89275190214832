import {
  CEC_CONTACT_ADD_NEW,
  CEC_CONTACT_ADD_NEW_SUCCESS,
  CEC_CONTACT_ADD_NEW_ERROR,
  CEC_CONTACT_DELETE,
  CEC_CONTACT_DELETE_ERROR,
  CEC_CONTACT_DELETE_SUCCESS,
  CEC_CONTACT_EDIT,
  CEC_CONTACT_EDIT_ERROR,
  CEC_CONTACT_EDIT_SUCCESS,
  CEC_CONTACT_GET_BY_ID,
  CEC_CONTACT_GET_BY_ID_ERROR,
  CEC_CONTACT_GET_BY_ID_SUCCESS,
  CEC_CONTACT_GET_LIST,
  CEC_CONTACT_GET_LIST_ERROR,
  CEC_CONTACT_GET_LIST_SUCCESS,
  CEC_CONTACT_ADD_NEW_REQUEST,
  CEC_CONTACT_FRONT_GET_BY_ID,
  CEC_CONTACT_SELECTED_ITEMS_CHANGE,
  CEC_CONTACT_BULK_DELETE,
  CEC_CONTACT_BULK_DELETE_SUCCESS,
  CEC_CONTACT_BULK_DELETE_ERROR,
  CEC_CONTACT_IMPORT_FROM_CLIENT,
  CEC_CONTACT_IMPORT_FROM_CLIENT_ERROR,
  CEC_CONTACT_IMPORT_FROM_CLIENT_SUCCESS,
  CEC_CONTACT_IMPORT_FROM_CSV,
  CEC_CONTACT_IMPORT_FROM_CSV_ERROR,
  CEC_CONTACT_IMPORT_FROM_CSV_SUCCESS,
} from '../actions';
// CECContact

export const getCECContactList = query => ({
  type: CEC_CONTACT_GET_LIST,
  payload: query,
});

export const getCECContactListSuccess = CECContacts => ({
  type: CEC_CONTACT_GET_LIST_SUCCESS,
  payload: CECContacts,
});

export const getCECContactListError = error => ({
  type: CEC_CONTACT_GET_LIST_ERROR,
  payload: error,
});

export const addNewCECContact = CECContact => ({
  type: CEC_CONTACT_ADD_NEW,
  payload: CECContact,
});

export const addNewCECContactRequest = () => ({
  type: CEC_CONTACT_ADD_NEW_REQUEST,
});

export const addNewCECContactSuccess = CECContact => ({
  type: CEC_CONTACT_ADD_NEW_SUCCESS,
  payload: CECContact,
});

export const addNewCECContactError = error => ({
  type: CEC_CONTACT_ADD_NEW_ERROR,
  payload: error,
});

export const editCECContact = CECContact => ({
  type: CEC_CONTACT_EDIT,
  payload: CECContact,
});

export const editCECContactSuccess = CECContact => ({
  type: CEC_CONTACT_EDIT_SUCCESS,
  payload: CECContact,
});

export const editCECContactError = error => ({
  type: CEC_CONTACT_EDIT_ERROR,
  payload: error,
});

export const deleteCECContact = id => ({
  type: CEC_CONTACT_DELETE,
  payload: id,
});

export const deleteCECContactSuccess = id => ({
  type: CEC_CONTACT_DELETE_SUCCESS,
  payload: id,
});

export const deleteCECContactError = error => ({
  type: CEC_CONTACT_DELETE_ERROR,
  payload: error,
});

export const bulkDeleteCECContact = query => ({
  type: CEC_CONTACT_BULK_DELETE,
  payload: query,
});

export const bulkDeleteCECContactSuccess = data => ({
  type: CEC_CONTACT_BULK_DELETE_SUCCESS,
  payload: data,
});

export const bulkDeleteCECContactError = error => ({
  type: CEC_CONTACT_BULK_DELETE_ERROR,
  payload: error,
});

export const getCECContactById = id => ({
  type: CEC_CONTACT_GET_BY_ID,
  payload: id,
});

export const getCECContactByIdSuccess = CECContact => ({
  type: CEC_CONTACT_GET_BY_ID_SUCCESS,
  payload: CECContact,
});

export const getCECContactByIdError = error => ({
  type: CEC_CONTACT_GET_BY_ID_ERROR,
  payload: error,
});

export const getCECContactFrontById = id => ({
  type: CEC_CONTACT_FRONT_GET_BY_ID,
  payload: id,
});

export const selectedCECContactItemsChange = items => ({
  type: CEC_CONTACT_SELECTED_ITEMS_CHANGE,
  payload: items,
});

export const importCECContactFromClient = ids => ({
  type: CEC_CONTACT_IMPORT_FROM_CLIENT,
  payload: ids,
});

export const importCECContactFromClientSuccess = items => ({
  type: CEC_CONTACT_IMPORT_FROM_CLIENT_SUCCESS,
  payload: items,
});

export const importCECContactFromClientError = error => ({
  type: CEC_CONTACT_IMPORT_FROM_CLIENT_ERROR,
  payload: error,
});

export const importCECContactFromCSV = ids => ({
  type: CEC_CONTACT_IMPORT_FROM_CSV,
  payload: ids,
});

export const importCECContactFromCSVSuccess = items => ({
  type: CEC_CONTACT_IMPORT_FROM_CSV_SUCCESS,
  payload: items,
});

export const importCECContactFromCSVError = error => ({
  type: CEC_CONTACT_IMPORT_FROM_CSV_ERROR,
  payload: error,
});
