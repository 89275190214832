import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  ASSESSEMENT_DELETE,
  ASSESSEMENT_EDIT,
  ASSESSEMENT_GET_BY_ID,
  ASSESSEMENT_ADD_NEW,
  ASSESSEMENT_GET_LIST,
} from '../actions';

import {
  getAssessementListSuccess,
  getAssessementListError,
  editAssessementSuccess,
  editAssessementError,
  addNewAssessementSuccess,
  addNewAssessementError,
  deleteAssessementSuccess,
  deleteAssessementError,
  getAssessementByIdSuccess,
} from './actions';

const getAssessementListRequest = async query => {
  let url = `${Config.apiServerHost}/api/Assessment/search`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const addNewAssessementReqeust = async assessement => {
  return axios.post(`${Config.apiServerHost}/api/Assessment`, assessement, await authHeader());
};

const editAssessementRequest = async caseNote => {
  return axios.put(`${Config.apiServerHost}/api/Assessment`, caseNote, await authHeader());
};

const deleteAssessementRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/Assessment/${id}`, await authHeader());
};

const getAssessementByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/Assessment/${id}`, await authHeader());
};

function* getAssessementList({ payload }) {
  try {
    const response = yield call(getAssessementListRequest, payload);
    yield put(getAssessementListSuccess(response.data));
  } catch (error) {
    yield put(getAssessementListError(error));
  }
}

function* addNewAssessement({ payload }) {
  try {
    let response = yield call(addNewAssessementReqeust, payload);
    response = yield call(getAssessementByIdRequest, response.data);
    yield put(addNewAssessementSuccess(response.data));
  } catch (error) {
    yield put(addNewAssessementError(error.response.statusText));
  }
}

function* editAssessement({ payload }) {
  try {
    let response = yield call(editAssessementRequest, payload);
    response = yield call(getAssessementByIdRequest, payload.id);
    yield put(editAssessementSuccess(response.data));
  } catch (error) {
    yield put(editAssessementError(error.response.statusText));
  }
}

function* getAssessementById({ payload }) {
  try {
    const response = yield call(getAssessementByIdRequest, payload);
    yield put(getAssessementByIdSuccess(response.data));
  } catch (error) {
    yield put(editAssessementError(error.response.statusText));
  }
}

function* deleteAssessement({ payload }) {
  try {
    const response = yield call(deleteAssessementRequest, payload);
    yield put(deleteAssessementSuccess(response.data));
  } catch (error) {
    yield put(deleteAssessementError(error.response.statusText));
  }
}

export function* watchAssessementList() {
  yield takeEvery(ASSESSEMENT_GET_LIST, getAssessementList);
}

export function* watchAddNewAssessement() {
  yield takeEvery(ASSESSEMENT_ADD_NEW, addNewAssessement);
}

export function* watchAssessementById() {
  yield takeEvery(ASSESSEMENT_GET_BY_ID, getAssessementById);
}

export function* watchEditAssessement() {
  yield takeEvery(ASSESSEMENT_EDIT, editAssessement);
}

export function* watchDeleteAssessement() {
  yield takeEvery(ASSESSEMENT_DELETE, deleteAssessement);
}

export default function* rootSaga() {
  yield all([
    fork(watchAssessementList),
    fork(watchAddNewAssessement),
    fork(watchAssessementById),
    fork(watchEditAssessement),
    fork(watchDeleteAssessement),
  ]);
}
