import { CLIENT_AGGREGATE_UPDATE, CLIENT_AGGREGATE_GET_BY_ID } from '../actions';
import { authHeader } from '../../helpers/auth-header';
import axios from 'axios';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  updateClientAggregateError,
  getClientAggregateByIdError,
  getClientAggregateByIdSuccess,
  updateClientAggregateSuccess,
} from './actions';

const getClientAggregateByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/client/ClientAggregate/${id}`, await authHeader());
};

const updateClientAggregateRequest = async clientAggregate => {
  return axios.post(
    `${Config.apiServerHost}/api/client/ClientAggregate`,
    clientAggregate,
    await authHeader()
  );
};

function* updateClientAggregate({ payload }) {
  try {
    let response = yield call(updateClientAggregateRequest, payload);
    response = yield call(getClientAggregateByIdRequest, response.data);
    yield put(updateClientAggregateSuccess(response.data));
  } catch (error) {
    yield put(updateClientAggregateError(error.response.statusText));
  }
}

function* getClientAggregateById({ payload }) {
  try {
    const response = yield call(getClientAggregateByIdRequest, payload);
    yield put(getClientAggregateByIdSuccess(response.data));
  } catch (error) {
    yield put(getClientAggregateByIdError(error.response.statusText));
  }
}

export function* watchUpdateClientAggregate() {
  yield takeEvery(CLIENT_AGGREGATE_UPDATE, updateClientAggregate);
}

export function* watchGetClientAggregateById() {
  yield takeEvery(CLIENT_AGGREGATE_GET_BY_ID, getClientAggregateById);
}

export default function* rootSaga() {
  yield all([fork(watchUpdateClientAggregate), fork(watchGetClientAggregateById)]);
}
