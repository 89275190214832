import { logger } from '../../logger';
import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import FileSaver from 'file-saver';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  POSTING_DELETE,
  POSTING_EDIT,
  POSTING_GET_BY_ID,
  POSTING_ADD_NEW,
  POSTING_GET_LIST,
  POSTING_RESULT_CHANGE,
  REMOVE_POSTING_CLIENT,
  FILL_POSTING_POSITION,
  POSTING_CLIENT_LIST,
} from './types';
import Config from '../../config';

import {
  getPostingListSuccess,
  getPostingByIdSuccess,
  postingError,
  addNewPostingSuccess,
  editPostingSuccess,
  getPostingInExternalClientListSuccess,
} from './actions';

// Posting
const getPostingListRequest = async payload => {
  let url = `${Config.apiServerHost}/api/Posting`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const getUpcommingExpiringJobPostingRequest = async payload => {
  let url = `${Config.apiServerHost}/api/Posting/upcommingExpiringJobPosting`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const getPostingDashboardRequest = async payload => {
  let url = `${Config.apiServerHost}/api/Posting/dashboard`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const getCurrentIndustryPostingRequest = async payload => {
  let url = `${Config.apiServerHost}/api/Posting/currentIndustryPosting`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const getPostingListByClientIdRequest = async payload => {
  return axios.post(`${Config.apiServerHost}/api/Posting/fetch`, payload, await authHeader());
};

const getPostingInExternalClientRequest = async payload => {
  return axios.post(`${Config.apiServerHost}/api/Posting/fetchClient`, payload, await authHeader());
};

const getPostingCountByClientIdRequest = async payload => {
  let url = `${Config.apiServerHost}/api/Posting/count`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const addNewPostingReqeust = async actionPlan => {
  return axios.post(`${Config.apiServerHost}/api/Posting`, actionPlan, await authHeader());
};

const editPostingRequest = async actionPlan => {
  return axios.put(`${Config.apiServerHost}/api/Posting`, actionPlan, await authHeader());
};

const fillPostingPositionRequest = async ({ id, positions }) => {
  return axios.put(
    `${Config.apiServerHost}/api/Posting/${id}/fillPosition`,
    positions,
    await authHeader()
  );
};

const deletePostingRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/Posting/${id}`, await authHeader());
};

const removePostingClientRequest = async query => {
  let url = `${Config.apiServerHost}/api/Posting/removeClient`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const getPostingByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/Posting/${id}`, await authHeader());
};

const postingResultChangeRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/Posting/${id}/resultChange`, await authHeader());
};

const exportBulkPostingToPDF = async (params, filename) => {
  const { headers } = await authHeader();
  const requestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify(params),
  };

  return await fetch(`${Config.apiServerHost}/api/Posting/exportToPDF`, requestOptions)
    .then(response => response.blob())
    .then(data => {
      FileSaver.saveAs(new Blob([data]), filename);
    });
};

const exportPostingToPDF = async (id, filename) => {
  const { headers } = await authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };

  const url = `${Config.apiServerHost}/api/Posting/exportToPDF/${id}`;

  return await fetch(url, requestOptions)
    .then(response => response.blob())
    .then(data => {
      FileSaver.saveAs(new Blob([data]), filename);
    });
};

function* getPostingList({ payload }) {
  try {
    const response = yield call(getPostingListByClientIdRequest, payload);
    const { isPrintLoad, oldPageSize } = payload;
    const item = { ...response.data, isPrintLoad };
    yield put(getPostingListSuccess(item));
  } catch (error) {
    yield put(postingError(error.response.statusText));
  }
}

function* getPostingInExternalClientList({ payload }) {
  try {
    const response = yield call(getPostingInExternalClientRequest, payload);
    const { isPrintLoad, oldPageSize } = payload;
    const item = { ...response.data, isPrintLoad };
    yield put(getPostingInExternalClientListSuccess(item));
  } catch (error) {
    yield put(postingError(error.response.statusText));
  }
}

function* addNewPosting({ payload }) {
  try {
    const { item, history } = payload;

    let response = yield call(addNewPostingReqeust, item);
    yield put(addNewPostingSuccess(response.data));
    if (history) {
      history.push('/app/job-posting/default');
    } else {
      const { employerId, filter, data } = item;
      const query = {
        filter: filter ?? '',
        employerId: employerId ?? '',
      };

      yield call(addNewPostingReqeust, data);
      const response = yield call(getPostingListByClientIdRequest, query);
      yield put(getPostingListSuccess(response.data));
    }
  } catch (error) {
    let err = error.response?.data?.message ?? 'something went wrong.';
    yield put(postingError(err));
  }
}

function* editPosting({ payload }) {
  try {
    const { item, history } = payload;
    if (history) {
      let response = yield call(editPostingRequest, item);
      yield put(editPostingSuccess(response.data));
      history.push('/app/job-posting/default');
    } else {
      const { employerId, clientId, filter, data } = item;
      const query = {
        filter: filter ?? '',
        clientId: clientId,
        employerId: employerId ?? '',
      };
      yield call(editPostingRequest, data);
      const response = yield call(getPostingListByClientIdRequest, query);
      yield put(getPostingListSuccess(response.data));
    }
  } catch (error) {
    let err = error.response?.data?.message ?? 'something went wrong.';
    yield put(postingError(err));
  }
}

function* fillPostingPosition({ payload }) {
  try {
    const { id, positions } = payload;
    let response = yield call(fillPostingPositionRequest, { id, positions });
    response = yield call(getPostingListByClientIdRequest, {});
    yield put(getPostingListSuccess(response.data));
  } catch (error) {
    let err = error.response?.data?.message ?? 'something went wrong.';
    yield put(postingError(err));
  }
}

function* getPostingById({ payload }) {
  try {
    const response = yield call(getPostingByIdRequest, payload);
    yield put(getPostingByIdSuccess(response.data));
  } catch (error) {
    let err = error.response?.data?.message ?? 'something went wrong.';
    yield put(postingError(err));
  }
}

function* postingResultChange({ payload }) {
  try {
    const { filter, page, pageSize, data } = payload;
    const query = {
      filter: filter ?? '',
      page,
      pageSize,
    };
    yield call(postingResultChangeRequest, data);
    const response = yield call(getPostingListByClientIdRequest, query);
    yield put(getPostingListSuccess(response.data));
  } catch (error) {
    let err = error.response?.data?.message ?? 'something went wrong.';
    yield put(postingError(err));
  }
}

function* deletePosting({ payload }) {
  try {
    const { employerId, clientId, filter, data } = payload;
    const query = {
      filter: filter ?? null,
      clientId: clientId ?? null,
      employerId: employerId ?? null,
    };

    yield call(deletePostingRequest, data);
    const response = yield call(getPostingListByClientIdRequest, query);
    yield put(getPostingListSuccess(response.data));
  } catch (error) {
    let err = error.response?.data?.message ?? 'something went wrong.';
    yield put(postingError(err));
  }
}

function* removePostingClient({ payload }) {
  try {
    const { clientId, postingId } = payload;
    const data = {
      clientId: clientId ?? '',
      postingId: postingId ?? '',
    };

    const query = {
      clientId: clientId ?? '',
    };

    yield call(removePostingClientRequest, data);
    const response = yield call(getPostingListByClientIdRequest, query);
    yield put(getPostingListSuccess(response.data));
  } catch (error) {
    let err = error.response?.data?.message ?? 'something went wrong.';
    yield put(postingError(err));
  }
}

export default function* rootSaga() {
  yield takeEvery(POSTING_RESULT_CHANGE, postingResultChange);
  yield takeEvery(POSTING_GET_LIST, getPostingList);
  yield takeEvery(POSTING_CLIENT_LIST, getPostingInExternalClientList);
  yield takeEvery(POSTING_ADD_NEW, addNewPosting);
  yield takeEvery(POSTING_GET_BY_ID, getPostingById);
  yield takeEvery(POSTING_EDIT, editPosting);
  yield takeEvery(POSTING_DELETE, deletePosting);
  yield takeEvery(REMOVE_POSTING_CLIENT, removePostingClient);
  yield takeEvery(FILL_POSTING_POSITION, fillPostingPosition);
}

export {
  getPostingListByClientIdRequest,
  getPostingListRequest,
  editPostingRequest,
  getPostingCountByClientIdRequest,
  exportBulkPostingToPDF,
  exportPostingToPDF,
  getPostingDashboardRequest,
  getUpcommingExpiringJobPostingRequest,
  getCurrentIndustryPostingRequest,
};
