import {
  CEC_CONTACT_ADD_NEW,
  CEC_CONTACT_ADD_NEW_SUCCESS,
  CEC_CONTACT_ADD_NEW_ERROR,
  CEC_CONTACT_DELETE,
  CEC_CONTACT_DELETE_ERROR,
  CEC_CONTACT_DELETE_SUCCESS,
  CEC_CONTACT_EDIT,
  CEC_CONTACT_EDIT_ERROR,
  CEC_CONTACT_EDIT_SUCCESS,
  CEC_CONTACT_GET_BY_ID,
  CEC_CONTACT_GET_BY_ID_ERROR,
  CEC_CONTACT_GET_BY_ID_SUCCESS,
  CEC_CONTACT_GET_LIST,
  CEC_CONTACT_GET_LIST_ERROR,
  CEC_CONTACT_GET_LIST_SUCCESS,
  CEC_CONTACT_ADD_NEW_REQUEST,
  CEC_CONTACT_FRONT_GET_BY_ID,
  CEC_CONTACT_SELECTED_ITEMS_CHANGE,
  CEC_CONTACT_BULK_DELETE,
  CEC_CONTACT_BULK_DELETE_SUCCESS,
  CEC_CONTACT_BULK_DELETE_ERROR,
  CEC_CONTACT_IMPORT_FROM_CLIENT,
  CEC_CONTACT_IMPORT_FROM_CLIENT_ERROR,
  CEC_CONTACT_IMPORT_FROM_CLIENT_SUCCESS,
  CEC_CONTACT_IMPORT_FROM_CSV,
  CEC_CONTACT_IMPORT_FROM_CSV_ERROR,
  CEC_CONTACT_IMPORT_FROM_CSV_SUCCESS,
} from '../actions';
// cecContacts
const initialState = {
  cecContacts: [],
  cecContact: null,
  notifications: [],
  loading: false,
  error: null,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
  importSuccess: false,
  selectedItems: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CEC_CONTACT_SELECTED_ITEMS_CHANGE:
      return {
        ...state,
        loading: false,
        error: null,
        selectedItems: payload,
      };
    case CEC_CONTACT_FRONT_GET_BY_ID:
      return {
        ...state,
        loading: false,
        cecContact: state.cecContacts.find(e => e.id === payload),
      };
    case CEC_CONTACT_GET_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case CEC_CONTACT_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        cecContact: payload,
      };

    case CEC_CONTACT_ADD_NEW_REQUEST:
      return {
        ...state,
        addSuccess: false,
      };
    case CEC_CONTACT_IMPORT_FROM_CLIENT:
    case CEC_CONTACT_IMPORT_FROM_CSV:
    case CEC_CONTACT_GET_LIST:
      return {
        ...state,
        error: null,
        loading: true,
        importSuccess: false,
      };
    case CEC_CONTACT_IMPORT_FROM_CLIENT_SUCCESS:
    case CEC_CONTACT_IMPORT_FROM_CSV_SUCCESS:
    case CEC_CONTACT_GET_LIST_SUCCESS:
      return {
        ...state,
        cecContacts: payload,
        loading: false,
        importSuccess: true,
      };
    case CEC_CONTACT_ADD_NEW:
      return {
        ...state,
        loading: true,
        error: null,
        addSuccess: false,
      };

    case CEC_CONTACT_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        cecContact: payload,
        cecContacts: state.cecContacts.concat(payload),
        addSuccess: true,
      };

    case CEC_CONTACT_EDIT:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case CEC_CONTACT_EDIT_SUCCESS:
      return {
        ...state,
        cecContacts: state.cecContacts.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        cecContact: payload,
        error: null,
        loading: false,
        editSuccess: true,
      };
    case CEC_CONTACT_DELETE:
    case CEC_CONTACT_BULK_DELETE:
      return {
        ...state,
        loading: true,
        error: null,
        deleteSuccess: false,
      };
    case CEC_CONTACT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        cecContacts: state.cecContacts.filter(c => c.id !== payload),
        deleteSuccess: true,
      };

    case CEC_CONTACT_BULK_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        cecContacts: state.cecContacts.filter(c => !payload.includes(c.id)),
        deleteSuccess: true,
      };

    case CEC_CONTACT_DELETE_ERROR:
    case CEC_CONTACT_ADD_NEW_ERROR:
    case CEC_CONTACT_EDIT_ERROR:
    case CEC_CONTACT_GET_BY_ID_ERROR:
    case CEC_CONTACT_GET_LIST_ERROR:
    case CEC_CONTACT_BULK_DELETE_ERROR:
    case CEC_CONTACT_IMPORT_FROM_CLIENT_ERROR:
    case CEC_CONTACT_IMPORT_FROM_CSV_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
