import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildUrl } from '../../helpers/Utils';
import {
  YJCS_CHEQUE_DELETE,
  YJCS_CHEQUE_EDIT,
  YJCS_CHEQUE_GET_BY_ID,
  YJCS_CHEQUE_ADD_NEW,
  YJCS_CHEQUE_QUERY,
} from '../actions';
import Config from '../../config';

import {
  editYJCSChequeSuccess,
  editYJCSChequeError,
  addNewYJCSChequeSuccess,
  addNewYJCSChequeError,
  deleteYJCSChequeSuccess,
  deleteYJCSChequeError,
  getYJCSChequeByIdSuccess,
  getYJCSChequeQuerySuccess,
  getYJCSChequeQueryError,
} from './actions';

const getYJCSChequeQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/YJCSCheque/search`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const addNewYJCSChequeReqeust = async caseNote => {
  return axios.post(`${Config.apiServerHost}/api/YJCSCheque`, caseNote, await authHeader());
};

const editYJCSChequeRequest = async caseNote => {
  return axios.put(`${Config.apiServerHost}/api/YJCSCheque`, caseNote, await authHeader());
};

const deleteYJCSChequeRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/YJCSCheque/${id}`, await authHeader());
};

const getYJCSChequeByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/YJCSCheque/${id}`, await authHeader());
};

function* getYJCSChequeQuery({ payload }) {
  try {
    const response = yield call(getYJCSChequeQueryRequest, payload);
    yield put(getYJCSChequeQuerySuccess(response.data));
  } catch (error) {
    yield put(getYJCSChequeQueryError(error.response.statusText));
  }
}

function* addNewYJCSCheque({ payload }) {
  try {
    let response = yield call(addNewYJCSChequeReqeust, payload);
    response = yield call(getYJCSChequeByIdRequest, response.data);
    yield put(addNewYJCSChequeSuccess(response.data));
  } catch (error) {
    yield put(addNewYJCSChequeError(error.response.statusText));
  }
}

function* editYJCSCheque({ payload }) {
  try {
    let response = yield call(editYJCSChequeRequest, payload);
    response = yield call(getYJCSChequeByIdRequest, payload.id);
    yield put(editYJCSChequeSuccess(response.data));
  } catch (error) {
    yield put(editYJCSChequeError(error.response.statusText));
  }
}

function* getYJCSChequeById({ payload }) {
  try {
    const response = yield call(getYJCSChequeByIdRequest, payload);
    yield put(getYJCSChequeByIdSuccess(response.data));
  } catch (error) {
    yield put(editYJCSChequeError(error.response.statusText));
  }
}

function* deleteYJCSCheque({ payload }) {
  try {
    const response = yield call(deleteYJCSChequeRequest, payload);
    yield put(deleteYJCSChequeSuccess(response.data));
  } catch (error) {
    yield put(deleteYJCSChequeError(error.response.statusText));
  }
}

export function* watchYJCSChequeQuery() {
  yield takeEvery(YJCS_CHEQUE_QUERY, getYJCSChequeQuery);
}

export function* watchAddNewYJCSCheque() {
  yield takeEvery(YJCS_CHEQUE_ADD_NEW, addNewYJCSCheque);
}

export function* watchYJCSChequeById() {
  yield takeEvery(YJCS_CHEQUE_GET_BY_ID, getYJCSChequeById);
}

export function* watchEditYJCSCheque() {
  yield takeEvery(YJCS_CHEQUE_EDIT, editYJCSCheque);
}

export function* watchDeleteYJCSCheque() {
  yield takeEvery(YJCS_CHEQUE_DELETE, deleteYJCSCheque);
}

export default function* rootSaga() {
  yield all([
    fork(watchYJCSChequeQuery),
    fork(watchAddNewYJCSCheque),
    fork(watchYJCSChequeById),
    fork(watchEditYJCSCheque),
    fork(watchDeleteYJCSCheque),
  ]);
}
