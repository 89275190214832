// Outcomes
import {
  OUTCOMES_GET_BY_CLIENT_AND_PROGRAM,
  OUTCOMES_GET_BY_CLIENT_AND_PROGRAM_SUCCESS,
  OUTCOMES_SAVE,
  OUTCOMES_ERROR,
} from './types';

export const getOutcomesByClientAndProgram = params => ({
  type: OUTCOMES_GET_BY_CLIENT_AND_PROGRAM,
  payload: params,
});

export const getOutcomesByClientAndProgramSuccess = data => ({
  type: OUTCOMES_GET_BY_CLIENT_AND_PROGRAM_SUCCESS,
  payload: data,
});

export const saveOutcomes = data => ({
  type: OUTCOMES_SAVE,
  payload: data,
});

export const outcomesError = error => ({
  type: OUTCOMES_ERROR,
  payload: error,
});
