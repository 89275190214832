import {
  EXPENSE_ADD_NEW,
  EXPENSE_ADD_NEW_SUCCESS,
  EXPENSE_ADD_NEW_ERROR,
  EXPENSE_DELETE,
  EXPENSE_DELETE_ERROR,
  EXPENSE_DELETE_SUCCESS,
  EXPENSE_EDIT,
  EXPENSE_EDIT_ERROR,
  EXPENSE_EDIT_SUCCESS,
  EXPENSE_GET_BY_ID,
  EXPENSE_GET_BY_ID_ERROR,
  EXPENSE_GET_BY_ID_SUCCESS,
  EXPENSE_GET_LIST,
  EXPENSE_GET_LIST_SUCCESS,
  EXPENSE_GET_LIST_ERROR,
  EXPENSE_GET_LIST_BY_USER_ID,
  EXPENSE_GET_LIST_BY_USER_ID_SUCCESS,
  EXPENSE_GET_LIST_BY_USER_ID_ERROR,
  EXPENSE_GET_LIST_BY_STATUS,
  EXPENSE_GET_LIST_BY_STATUS_SUCCESS,
  EXPENSE_GET_LIST_BY_STATUS_ERROR,
  EXPENSE_SUBMIT,
  EXPENSE_SUBMIT_SUCCESS,
  EXPENSE_SUBMIT_ERROR,
  EXPENSE_APPROVE_SUBMIT,
  EXPENSE_APPROVE_SUBMIT_SUCCESS,
  EXPENSE_APPROVE_SUBMIT_ERROR,
  EXPENSE_ADD_NEW_REQUEST,
  EXPENSE_GET_BY_QUERY,
  EXPENSE_GET_BY_QUERY_SUCCESS,
  EXPENSE_GET_BY_QUERY_ERROR,
} from '../actions';

const initialState = {
  myExpenses: [],
  userExpenses: [],
  expenseReports: [],
  expenses: [],
  expense: null,
  loading: false,
  error: null,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
  userId: 0,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case EXPENSE_SUBMIT:
    case EXPENSE_APPROVE_SUBMIT:
    case EXPENSE_GET_BY_ID:
    case EXPENSE_GET_LIST_BY_STATUS:
    case EXPENSE_GET_BY_QUERY:
      return {
        ...state,
        loading: false,
        error: null,
        editSuccess: false,
      };

    case EXPENSE_GET_LIST_BY_STATUS_SUCCESS:
      return {
        ...state,
        loading: true,
        expenseReports: payload,
      };

    case EXPENSE_APPROVE_SUBMIT_SUCCESS:
    case EXPENSE_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: true,
        expense: payload,
      };

    case EXPENSE_ADD_NEW_REQUEST:
      return {
        ...state,
        addSuccess: false,
      };

    case EXPENSE_GET_LIST:
    case EXPENSE_GET_LIST_BY_USER_ID:
      return {
        ...state,
        error: null,
        userId: payload,
        loading: false,
      };

    case EXPENSE_GET_BY_QUERY_SUCCESS:
    case EXPENSE_GET_LIST_SUCCESS:
    case EXPENSE_GET_LIST_BY_USER_ID_SUCCESS:
      return {
        ...state,
        expenses: payload,
        myExpenses: payload.filter(a => a.userId === Number(state.userId)),
        userExpenses: payload.filter(a => a.userId !== Number(state.userId)),
        loading: true,
      };

    case EXPENSE_ADD_NEW:
      return {
        ...state,
        loading: false,
        error: null,
        addSuccess: false,
      };

    case EXPENSE_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: true,
        expense: payload,
        expenses: state.expenses.concat(payload),
        myExpenses: state.myExpenses.concat(payload),
        addSuccess: true,
      };

    case EXPENSE_EDIT:
      return {
        ...state,
        loading: false,
        error: null,
        editSuccess: false,
      };

    case EXPENSE_SUBMIT_SUCCESS:
    case EXPENSE_EDIT_SUCCESS:
      return {
        ...state,
        expenses: state.expenses.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        myExpenses: state.myExpenses.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        userExpenses: state.userExpenses.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        expense: payload,
        error: null,
        loading: true,
        editSuccess: true,
      };

    case EXPENSE_DELETE:
      return {
        ...state,
        loading: false,
        error: null,
        deleteSuccess: false,
      };
    case EXPENSE_DELETE_SUCCESS:
      return {
        ...state,
        loading: true,
        expenses: state.expenses.filter(c => c.id !== payload),
        myExpenses: state.myExpenses.filter(c => c.id !== payload),
        userExpenses: state.userExpenses.filter(c => c.id !== payload),
        deleteSuccess: true,
      };

    case EXPENSE_GET_BY_QUERY_ERROR:
    case EXPENSE_DELETE_ERROR:
    case EXPENSE_ADD_NEW_ERROR:
    case EXPENSE_EDIT_ERROR:
    case EXPENSE_GET_BY_ID_ERROR:
    case EXPENSE_GET_LIST_BY_USER_ID_ERROR:
    case EXPENSE_GET_LIST_ERROR:
    case EXPENSE_SUBMIT_ERROR:
    case EXPENSE_APPROVE_SUBMIT_ERROR:
    case EXPENSE_GET_LIST_BY_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
