import {
  OUTCOME_QUESTIONAIRE_GET_BY_ID,
  OUTCOME_QUESTIONAIRE_GET_BY_ID_ERROR,
  OUTCOME_QUESTIONAIRE_GET_BY_ID_SUCCESS,
  OUTCOME_QUESTIONAIRE_UPDATE,
  OUTCOME_QUESTIONAIRE_UPDATE_SUCCESS,
  OUTCOME_QUESTIONAIRE_UPDATE_ERROR,
  OUTCOME_QUESTIONAIRE_SYNC,
  OUTCOME_QUESTIONAIRE_SYNC_SUCCESS,
  OUTCOME_QUESTIONAIRE_SYNC_ERROR,
  OUTCOME_QUESTIONAIRE_CLEAR_NOTIFIED,
} from './types';

// OutcomeQuestionaire
export const getOutcomeQuestionaireById = id => ({
  type: OUTCOME_QUESTIONAIRE_GET_BY_ID,
  payload: id,
});

export const getOutcomeQuestionaireByIdSuccess = clientInfo => ({
  type: OUTCOME_QUESTIONAIRE_GET_BY_ID_SUCCESS,
  payload: clientInfo,
});

export const getOutcomeQuestionaireByIdError = error => ({
  type: OUTCOME_QUESTIONAIRE_GET_BY_ID_ERROR,
  payload: error,
});

export const updateOutcomeQuestionaire = clientInfo => ({
  type: OUTCOME_QUESTIONAIRE_UPDATE,
  payload: clientInfo,
});

export const updateOutcomeQuestionaireSuccess = clientInfo => ({
  type: OUTCOME_QUESTIONAIRE_UPDATE_SUCCESS,
  payload: clientInfo,
});

export const updateOutcomeQuestionaireError = error => ({
  type: OUTCOME_QUESTIONAIRE_UPDATE_ERROR,
  payload: error,
});

export const syncOutcomeQuestionaire = (
  clientId,
  servicePlanHeaderId,
  clientReferenceNumberOverride
) => ({
  type: OUTCOME_QUESTIONAIRE_SYNC,
  payload: { clientId, servicePlanHeaderId, clientReferenceNumberOverride },
});

export const syncOutcomeQuestionaireSuccess = clientInfo => ({
  type: OUTCOME_QUESTIONAIRE_SYNC_SUCCESS,
  payload: clientInfo,
});

export const syncOutcomeQuestionaireError = error => ({
  type: OUTCOME_QUESTIONAIRE_SYNC_ERROR,
  payload: error,
});

export const clearOutcomeQuestionnaireNotify = () => ({
  type: OUTCOME_QUESTIONAIRE_CLEAR_NOTIFIED,
});
