import {
  RESUME_ADD_NEW,
  RESUME_ADD_NEW_SUCCESS,
  RESUME_ADD_NEW_ERROR,
  RESUME_ADD_NEW_REQUEST,
  RESUME_DELETE,
  RESUME_DELETE_ERROR,
  RESUME_DELETE_SUCCESS,
  RESUME_GET_BY_CLIENT_ID,
  RESUME_GET_BY_CLIENT_ID_ERROR,
  RESUME_GET_BY_CLIENT_ID_SUCCESS,
} from '../actions';

export const addNewResume = resume => ({
  type: RESUME_ADD_NEW,
  payload: { resume },
});

export const addNewResumeRequest = () => ({
  type: RESUME_ADD_NEW_REQUEST,
});

export const addNewResumeSuccess = resume => ({
  type: RESUME_ADD_NEW_SUCCESS,
  payload: resume,
});

export const addNewResumeError = error => ({
  type: RESUME_ADD_NEW_ERROR,
  payload: error,
});

export const deleteResume = id => ({
  type: RESUME_DELETE,
  payload: id,
});

export const deleteResumeSuccess = id => ({
  type: RESUME_DELETE_SUCCESS,
  payload: id,
});

export const deleteResumeError = error => ({
  type: RESUME_DELETE_ERROR,
  payload: error,
});

export const getResumeByClientId = clientId => ({
  type: RESUME_GET_BY_CLIENT_ID,
  payload: clientId,
});

export const getResumeByClientIdSuccess = resume => ({
  type: RESUME_GET_BY_CLIENT_ID_SUCCESS,
  payload: resume,
});

export const getResumeByClientIdError = error => ({
  type: RESUME_GET_BY_CLIENT_ID_ERROR,
  payload: error,
});
