import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  EMPLOYMENT_EARNING_DELETE,
  EMPLOYMENT_EARNING_EDIT,
  EMPLOYMENT_EARNING_GET_BY_ID,
  EMPLOYMENT_EARNING_ADD_NEW,
  EMPLOYMENT_EARNING_GET_LIST,
  EMPLOYMENT_EARNING_QUERY,
} from '../actions';
import Config from '../../config';

import {
  getEmploymentEarningListSuccess,
  getEmploymentEarningListError,
  editEmploymentEarningSuccess,
  editEmploymentEarningError,
  addNewEmploymentEarningSuccess,
  addNewEmploymentEarningError,
  deleteEmploymentEarningSuccess,
  deleteEmploymentEarningError,
  getEmploymentEarningByIdSuccess,
  getEmploymentEarningQuerySuccess,
  getEmploymentEarningQueryError,
} from './actions';

const getEmploymentEarningQueryRequest = async query => {
  const url = `${Config.apiServerHost}/api/EmploymentEarning/searchByClient`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const getEmploymentEarningListRequest = async query => {
  const url = `${Config.apiServerHost}/api/EmploymentEarning/search`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const addNewEmploymentEarningReqeust = async caseNote => {
  return axios.post(`${Config.apiServerHost}/api/EmploymentEarning`, caseNote, await authHeader());
};

const editEmploymentEarningRequest = async caseNote => {
  return axios.put(`${Config.apiServerHost}/api/EmploymentEarning`, caseNote, await authHeader());
};

const deleteEmploymentEarningRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/EmploymentEarning/${id}`, await authHeader());
};

const getEmploymentEarningByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/EmploymentEarning/${id}`, await authHeader());
};

function* getEmploymentEarningList({ payload }) {
  try {
    const response = yield call(getEmploymentEarningListRequest, payload);
    yield put(getEmploymentEarningListSuccess(response.data));
  } catch (error) {
    yield put(getEmploymentEarningListError(error.response.statusText));
  }
}

function* getEmploymentEarningQuery({ payload }) {
  try {
    const response = yield call(getEmploymentEarningQueryRequest, payload);
    yield put(getEmploymentEarningQuerySuccess(response.data));
  } catch (error) {
    yield put(getEmploymentEarningQueryError(error.response.statusText));
  }
}

function* addNewEmploymentEarning({ payload }) {
  try {
    let response = yield call(addNewEmploymentEarningReqeust, payload);
    response = yield call(getEmploymentEarningByIdRequest, response.data);
    yield put(addNewEmploymentEarningSuccess(response.data));
  } catch (error) {
    yield put(addNewEmploymentEarningError(error.response.statusText));
  }
}

function* editEmploymentEarning({ payload }) {
  try {
    let response = yield call(editEmploymentEarningRequest, payload);
    response = yield call(getEmploymentEarningByIdRequest, payload.id);
    yield put(editEmploymentEarningSuccess(response.data));
  } catch (error) {
    yield put(editEmploymentEarningError(error.response.statusText));
  }
}

function* getEmploymentEarningById({ payload }) {
  try {
    const response = yield call(getEmploymentEarningByIdRequest, payload);
    yield put(getEmploymentEarningByIdSuccess(response.data));
  } catch (error) {
    yield put(editEmploymentEarningError(error.response.statusText));
  }
}

function* deleteEmploymentEarning({ payload }) {
  try {
    const response = yield call(deleteEmploymentEarningRequest, payload);
    yield put(deleteEmploymentEarningSuccess(response.data));
  } catch (error) {
    yield put(deleteEmploymentEarningError(error.response.statusText));
  }
}

export function* watchEmploymentEarningQuery() {
  yield takeEvery(EMPLOYMENT_EARNING_QUERY, getEmploymentEarningQuery);
}

export function* watchEmploymentEarningList() {
  yield takeEvery(EMPLOYMENT_EARNING_GET_LIST, getEmploymentEarningList);
}

export function* watchAddNewEmploymentEarning() {
  yield takeEvery(EMPLOYMENT_EARNING_ADD_NEW, addNewEmploymentEarning);
}

export function* watchEmploymentEarningById() {
  yield takeEvery(EMPLOYMENT_EARNING_GET_BY_ID, getEmploymentEarningById);
}

export function* watchEditEmploymentEarning() {
  yield takeEvery(EMPLOYMENT_EARNING_EDIT, editEmploymentEarning);
}

export function* watchDeleteEmploymentEarning() {
  yield takeEvery(EMPLOYMENT_EARNING_DELETE, deleteEmploymentEarning);
}

export default function* rootSaga() {
  yield all([
    fork(watchEmploymentEarningQuery),
    fork(watchEmploymentEarningList),
    fork(watchAddNewEmploymentEarning),
    fork(watchEmploymentEarningById),
    fork(watchEditEmploymentEarning),
    fork(watchDeleteEmploymentEarning),
  ]);
}
