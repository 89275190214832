import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  SERVICE_PLAN_DELETE,
  SERVICE_PLAN_EDIT,
  SERVICE_PLAN_GET_BY_ID,
  SERVICE_PLAN_ADD_NEW,
  SERVICE_PLAN_GET_LIST,
  SERVICE_PLAN_STATUS_CHANGE,
} from '../actions';
import Config from '../../config';
import FileSaver from 'file-saver';

import {
  getServicePlanListSuccess,
  getServicePlanListError,
  editServicePlanSuccess,
  editServicePlanError,
  addNewServicePlanSuccess,
  addNewServicePlanError,
  deleteServicePlanSuccess,
  deleteServicePlanError,
  getServicePlanByIdSuccess,
  changeServicePlanStatusSuccess,
  changeServicePlanStatusError,
} from './actions';

const getServicePlanListRequest = async payload => {
  let url = `${Config.apiServerHost}/api/ClientServicePlanOverview`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const getServicePlanListByClientIdRequest = async payload => {
  let url = `${Config.apiServerHost}/api/ClientServicePlanOverview/search`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const addNewServicePlanReqeust = async ServicePlan => {
  return axios.post(
    `${Config.apiServerHost}/api/ClientServicePlanOverview`,
    ServicePlan,
    await authHeader()
  );
};

const editServicePlanRequest = async ServicePlan => {
  return axios.put(
    `${Config.apiServerHost}/api/ClientServicePlanOverview`,
    ServicePlan,
    await authHeader()
  );
};

const deleteServicePlanRequest = async id => {
  return axios.delete(
    `${Config.apiServerHost}/api/ClientServicePlanOverview/${id}`,
    await authHeader()
  );
};

const getServicePlanByIdRequest = async id => {
  return axios.get(
    `${Config.apiServerHost}/api/ClientServicePlanOverview/${id}`,
    await authHeader()
  );
};

const changeServicePlanStatusRequest = async id => {
  return axios.put(
    `${Config.apiServerHost}/api/ServicePlan/${id}/statusChange`,
    null,
    await authHeader()
  );
};

const exportServicePlanToPDF = async (id, programId, filename) => {
  const { headers } = await authHeader();
  const requestOptions = {
    method: 'GET',
    headers,
  };

  return await fetch(
    buildUrl(
      `${Config.apiServerHost}/api/ClientServicePlanOverview/exportToPDF/${id}/${programId}`
    ),
    requestOptions
  )
    .then(response => response.blob())
    .then(data => {
      FileSaver.saveAs(new Blob([data]), filename);
    });
};

function* getServicePlanList({ payload }) {
  try {
    const response = yield call(getServicePlanListByClientIdRequest, payload);
    yield put(getServicePlanListSuccess(response.data));
  } catch (error) {
    yield put(getServicePlanListError(error.response.statusText));
  }
}

function* addNewServicePlan({ payload }) {
  try {
    let response = yield call(addNewServicePlanReqeust, payload);
    response = yield call(getServicePlanByIdRequest, response.data);
    yield put(addNewServicePlanSuccess(response.data));
  } catch (error) {
    yield put(addNewServicePlanError(error.response.statusText));
  }
}

function* editServicePlan({ payload }) {
  try {
    let response = yield call(editServicePlanRequest, payload);
    response = yield call(getServicePlanByIdRequest, response.data);
    yield put(editServicePlanSuccess(response.data));
  } catch (error) {
    yield put(editServicePlanError(error.response.statusText));
  }
}

function* getServicePlanById({ payload }) {
  try {
    const response = yield call(getServicePlanByIdRequest, payload);
    yield put(getServicePlanByIdSuccess(response.data));
  } catch (error) {
    yield put(editServicePlanError(error.response.statusText));
  }
}

function* changeServicePlanStatus({ payload }) {
  try {
    let response = yield call(changeServicePlanStatusRequest, payload);
    response = yield call(getServicePlanByIdRequest, response.data);
    yield put(changeServicePlanStatusSuccess(response.data));
  } catch (error) {
    yield put(changeServicePlanStatusError(error.response.statusText));
  }
}

function* deleteServicePlan({ payload }) {
  try {
    const response = yield call(deleteServicePlanRequest, payload);
    yield put(deleteServicePlanSuccess(response.data));
  } catch (error) {
    yield put(deleteServicePlanError(error.response.statusText));
  }
}

export function* watchServicePlanList() {
  yield takeEvery(SERVICE_PLAN_GET_LIST, getServicePlanList);
}

export function* watchAddNewServicePlan() {
  yield takeEvery(SERVICE_PLAN_ADD_NEW, addNewServicePlan);
}

export function* watchServicePlanById() {
  yield takeEvery(SERVICE_PLAN_GET_BY_ID, getServicePlanById);
}

export function* watchEditServicePlan() {
  yield takeEvery(SERVICE_PLAN_EDIT, editServicePlan);
}

export function* watchDeleteServicePlan() {
  yield takeEvery(SERVICE_PLAN_DELETE, deleteServicePlan);
}

export function* watchChangeServicePlanStatus() {
  yield takeEvery(SERVICE_PLAN_STATUS_CHANGE, changeServicePlanStatus);
}

export default function* rootSaga() {
  yield all([
    fork(watchServicePlanList),
    fork(watchAddNewServicePlan),
    fork(watchServicePlanById),
    fork(watchEditServicePlan),
    fork(watchDeleteServicePlan),
    fork(watchChangeServicePlanStatus),
  ]);
}

export {
  getServicePlanListByClientIdRequest,
  getServicePlanListRequest,
  editServicePlanRequest,
  exportServicePlanToPDF,
};
