import {
  SECTOR_GET_LIST,
  SECTOR_GET_LIST_SUCCESS,
  SECTOR_GET_LIST_ERROR,
  SECTOR_GET_LIST_WITH_FILTER,
  SECTOR_GET_LIST_WITH_ORDER,
  SECTOR_GET_LIST_SEARCH,
  SECTOR_SELECTED_ITEMS_CHANGE,
  SECTOR_EDIT_ITEM,
  SECTOR_EDIT_ITEM_SUCCESS,
  SECTOR_EDIT_ITEM_ERROR,
  SECTOR_DELETE_ITEM,
  SECTOR_DELETE_ITEM_SUCCESS,
  SECTOR_DELETE_ITEM_ERROR,
} from '../actions';

export const getSectorList = () => ({
  type: SECTOR_GET_LIST,
});

export const getSectorListSuccess = items => ({
  type: SECTOR_GET_LIST_SUCCESS,
  payload: items,
});

export const getSectorListError = error => ({
  type: SECTOR_GET_LIST_ERROR,
  payload: error,
});

export const getSectorListWithFilter = (column, value) => ({
  type: SECTOR_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getSectorListWithOrder = column => ({
  type: SECTOR_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getSectorListSearch = keyword => ({
  type: SECTOR_GET_LIST_SEARCH,
  payload: keyword,
});

export const editSectorItem = item => ({
  type: SECTOR_EDIT_ITEM,
  payload: item,
});

export const editSectorItemSuccess = items => ({
  type: SECTOR_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editSectorItemError = error => ({
  type: SECTOR_EDIT_ITEM_ERROR,
  payload: error,
});

export const selectedSectorItemsChange = selectedItems => ({
  type: SECTOR_SELECTED_ITEMS_CHANGE,
  payload: selectedItems,
});

export const deleteSectorItem = item => ({
  type: SECTOR_DELETE_ITEM,
  payload: item,
});

export const deleteSectorItemSuccess = items => ({
  type: SECTOR_DELETE_ITEM_SUCCESS,
  payload: items,
});

export const deleteSectorItemError = error => ({
  type: SECTOR_DELETE_ITEM_ERROR,
  payload: error,
});
