import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  ESUITE_ACTIVITY_APPROVE,
  ESUITE_ACTIVITY_GET_BY_QUERY,
  ESUITE_ACTIVITY_REJECT,
  ESUITE_ACTIVITY_SAVE_AND_APPROVE,
} from './types';

import {
  getESuiteActivityByQuerySuccess,
  getESuiteActivityByQueryError,
  approveESuiteActivitySuccess,
  approveESuiteActivityError,
  rejectESuiteActivitySuccess,
  rejectESuiteActivityError,
  saveAndApproveESuiteActivitySuccess,
} from './actions';
import { getTransactionNotificationByQueryRequest } from '../transaction-notification/saga';
import { getTransactionNotificationByQuerySuccess } from '../actions';

const getESuiteActivityByQueryRequest = async query => {
  return await axios.get(`${Config.apiServerHost}/api/EmployerSuite/posting-request`, {
    params: query,
    headers: { ...(await authHeader()).headers },
  });
};

const approveESuiteActivityRequest = async requestId => {
  return axios.get(
    `${Config.apiServerHost}/api/EmployerSuite/posting-request/approve/${requestId}`,
    await authHeader()
  );
};

const saveAndApproveESuiteActivityRequest = async ({ requestId, data }) => {
  return axios.post(
    `${Config.apiServerHost}/api/EmployerSuite/posting-request/saveAndApprove/${requestId}`,
    data,
    {
      headers: { ...(await authHeader()).headers },
    }
  );
};

const rejectESuiteActivityRequest = async payload => {
  const { requestId, comment } = payload;
  return axios.get(
    `${Config.apiServerHost}/api/EmployerSuite/posting-request/reject/${requestId}`,
    {
      params: {
        comment,
      },
      headers: { ...(await authHeader()).headers },
    }
  );
};

function* getESuiteActivityByQuery({ payload }) {
  try {
    const response = yield call(getESuiteActivityByQueryRequest, payload);
    yield put(getESuiteActivityByQuerySuccess(response.data));
  } catch (error) {
    yield put(getESuiteActivityByQueryError(error.response.statusText));
  }
}

function* approveESuiteActivity({ payload }) {
  try {
    const response = yield call(approveESuiteActivityRequest, payload);
    yield put(approveESuiteActivitySuccess(response.data));
    const secondResponse = yield call(getTransactionNotificationByQueryRequest);
    yield put(getTransactionNotificationByQuerySuccess(secondResponse.data));
  } catch (error) {
    yield put(approveESuiteActivityError(error.response.statusText));
  }
}

function* saveAndApproveESuiteActivity({ payload }) {
  try {
    const response = yield call(saveAndApproveESuiteActivityRequest, payload);
    yield put(saveAndApproveESuiteActivitySuccess(response.data));
    const secondResponse = yield call(getTransactionNotificationByQueryRequest);
    yield put(getTransactionNotificationByQuerySuccess(secondResponse.data));
  } catch (error) {
    yield put(approveESuiteActivityError(error.response.statusText));
  }
}

function* rejectESuiteActivity({ payload }) {
  try {
    const response = yield call(rejectESuiteActivityRequest, payload);
    yield put(rejectESuiteActivitySuccess(response.data));
    // yield put(getESuiteActivityByQuerySuccess(response.data));

    // call to refresh transaction notificaiton bubble
    const secondResponse = yield call(getTransactionNotificationByQueryRequest);
    yield put(getTransactionNotificationByQuerySuccess(secondResponse.data));
  } catch (error) {
    yield put(rejectESuiteActivityError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(ESUITE_ACTIVITY_GET_BY_QUERY, getESuiteActivityByQuery);
  yield takeEvery(ESUITE_ACTIVITY_APPROVE, approveESuiteActivity);
  yield takeEvery(ESUITE_ACTIVITY_SAVE_AND_APPROVE, saveAndApproveESuiteActivity);
  yield takeEvery(ESUITE_ACTIVITY_REJECT, rejectESuiteActivity);
}
