import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  REFERRAL_DELETE,
  REFERRAL_EDIT,
  REFERRAL_GET_BY_ID,
  REFERRAL_ADD_NEW,
  REFERRAL_GET_LIST,
  REFERRAL_GET_LIST_BY_QUERY,
} from '../actions';
import Config from '../../config';
// Referral
import {
  getReferralListSuccess,
  getReferralListError,
  editReferralSuccess,
  editReferralError,
  addNewReferralSuccess,
  addNewReferralError,
  deleteReferralSuccess,
  deleteReferralError,
  getReferralByIdSuccess,
  getReferralListByQuerySuccess,
  getReferralListByQueryError,
} from './actions';

const getReferralListByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/Referral/search`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getReferralListRequest = async () => {
  return axios.get(`${Config.apiServerHost}/api/Referral`, await authHeader());
};

const addNewReferralReqeust = async actionPlan => {
  return axios.post(`${Config.apiServerHost}/api/Referral`, actionPlan, await authHeader());
};

const editReferralRequest = async actionPlan => {
  return axios.put(`${Config.apiServerHost}/api/Referral`, actionPlan, await authHeader());
};

const deleteReferralRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/Referral/${id}`, await authHeader());
};

const getReferralByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/Referral/${id}`, await authHeader());
};

function* getReferralListByQuery({ payload }) {
  try {
    const response = yield call(getReferralListByQueryRequest, payload);
    yield put(getReferralListByQuerySuccess(response.data));
  } catch (error) {
    yield put(getReferralListByQueryError(error.response.statusText));
  }
}

function* getReferralList() {
  try {
    const response = yield call(getReferralListRequest);
    yield put(getReferralListSuccess(response.data));
  } catch (error) {
    yield put(getReferralListError(error.response.statusText));
  }
}

function* addNewReferral({ payload }) {
  try {
    let response = yield call(addNewReferralReqeust, payload);
    response = yield call(getReferralByIdRequest, response.data);
    yield put(addNewReferralSuccess(response.data));
  } catch (error) {
    yield put(addNewReferralError(error.response.statusText));
  }
}

function* editReferral({ payload }) {
  try {
    let response = yield call(editReferralRequest, payload);
    response = yield call(getReferralByIdRequest, response.data);
    yield put(editReferralSuccess(response.data));
  } catch (error) {
    yield put(editReferralError(error.response.statusText));
  }
}

function* getReferralById({ payload }) {
  try {
    const response = yield call(getReferralByIdRequest, payload);
    yield put(getReferralByIdSuccess(response.data));
  } catch (error) {
    yield put(editReferralError(error.response.statusText));
  }
}

function* deleteReferral({ payload }) {
  try {
    const response = yield call(deleteReferralRequest, payload);
    yield put(deleteReferralSuccess(response.data));
  } catch (error) {
    yield put(deleteReferralError(error.response.statusText));
  }
}

export function* watchReferralListByQuery() {
  yield takeEvery(REFERRAL_GET_LIST_BY_QUERY, getReferralListByQuery);
}

export function* watchReferralList() {
  yield takeEvery(REFERRAL_GET_LIST, getReferralList);
}

export function* watchAddNewReferral() {
  yield takeEvery(REFERRAL_ADD_NEW, addNewReferral);
}

export function* watchReferralById() {
  yield takeEvery(REFERRAL_GET_BY_ID, getReferralById);
}

export function* watchEditReferral() {
  yield takeEvery(REFERRAL_EDIT, editReferral);
}

export function* watchDeleteReferral() {
  yield takeEvery(REFERRAL_DELETE, deleteReferral);
}

export default function* rootSaga() {
  yield all([
    fork(watchReferralListByQuery),
    fork(watchReferralList),
    fork(watchAddNewReferral),
    fork(watchReferralById),
    fork(watchEditReferral),
    fork(watchDeleteReferral),
  ]);
}
