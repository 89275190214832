import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  JOB_MATCHING_DELETE,
  JOB_MATCHING_EDIT,
  JOB_MATCHING_GET_BY_ID,
  JOB_MATCHING_ADD_NEW,
  JOB_MATCHING_GET_LIST,
} from './types';
import Config from '../../config';

import { getJobMatchingListSuccess, getJobMatchingByIdSuccess, jobMatchingError } from './actions';

// JobMatching

const getJobMatchingListByClientIdRequest = async payload => {
  let url = `${Config.apiServerHost}/api/JobMatching/fetch`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const addNewJobMatchingReqeust = async actionPlan => {
  return axios.post(`${Config.apiServerHost}/api/JobMatching`, actionPlan, await authHeader());
};

const editJobMatchingRequest = async actionPlan => {
  return axios.put(`${Config.apiServerHost}/api/JobMatching`, actionPlan, await authHeader());
};

const deleteJobMatchingRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/JobMatching/${id}`, await authHeader());
};

const getJobMatchingByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/JobMatching/${id}`, await authHeader());
};

function* getJobMatchingList({ payload }) {
  try {
    const response = yield call(getJobMatchingListByClientIdRequest, payload);
    yield put(getJobMatchingListSuccess(response.data));
  } catch (error) {
    yield put(jobMatchingError(error.response.statusText));
  }
}

function* addNewJobMatching({ payload }) {
  try {
    const { filter, data } = payload;
    const query = {
      filter: filter ?? '',
    };

    yield call(addNewJobMatchingReqeust, data);
    const response = yield call(getJobMatchingListByClientIdRequest, query);
    yield put(getJobMatchingListSuccess(response.data));
  } catch (error) {
    yield put(jobMatchingError(error.response.statusText));
  }
}

function* editJobMatching({ payload }) {
  try {
    const { filter, data } = payload;
    const query = {
      filter: filter ?? '',
    };

    yield call(editJobMatchingRequest, data);
    const response = yield call(getJobMatchingListByClientIdRequest, query);
    yield put(getJobMatchingListSuccess(response.data));
  } catch (error) {
    yield put(jobMatchingError(error.response.statusText));
  }
}

function* getJobMatchingById({ payload }) {
  try {
    const response = yield call(getJobMatchingByIdRequest, payload);
    yield put(getJobMatchingByIdSuccess(response.data));
  } catch (error) {
    yield put(jobMatchingError(error.response.statusText));
  }
}

function* deleteJobMatching({ payload }) {
  try {
    const { filter, data } = payload;
    const query = {
      filter: filter ?? '',
    };

    yield call(deleteJobMatchingRequest, data);
    const response = yield call(getJobMatchingListByClientIdRequest, query);
    yield put(getJobMatchingListSuccess(response.data));
  } catch (error) {
    yield put(jobMatchingError(error.response.statusText));
  }
}

export function* watchJobMatchingList() {
  yield takeEvery(JOB_MATCHING_GET_LIST, getJobMatchingList);
}

export function* watchAddNewJobMatching() {
  yield takeEvery(JOB_MATCHING_ADD_NEW, addNewJobMatching);
}

export function* watchJobMatchingById() {
  yield takeEvery(JOB_MATCHING_GET_BY_ID, getJobMatchingById);
}

export function* watchEditJobMatching() {
  yield takeEvery(JOB_MATCHING_EDIT, editJobMatching);
}

export function* watchDeleteJobMatching() {
  yield takeEvery(JOB_MATCHING_DELETE, deleteJobMatching);
}

export default function* rootSaga() {
  yield all([
    fork(watchJobMatchingList),
    fork(watchAddNewJobMatching),
    fork(watchJobMatchingById),
    fork(watchEditJobMatching),
    fork(watchDeleteJobMatching),
  ]);
}
