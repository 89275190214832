import {
  CLIENT_AGGREGATE_UPDATE,
  CLIENT_AGGREGATE_UPDATE_SUCCESS,
  CLIENT_AGGREGATE_UPDATE_ERROR,
  CLIENT_AGGREGATE_GET_BY_ID,
  CLIENT_AGGREGATE_GET_BY_ID_SUCCESS,
  CLIENT_AGGREGATE_GET_BY_ID_ERROR,
} from '../actions';

const initialState = {
  clientAggregate: {},
  loading: false,
  error: null,
  updated: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CLIENT_AGGREGATE_UPDATE:
      return {
        ...state,
        loading: false,
        error: null,
        updated: false,
      };
    case CLIENT_AGGREGATE_UPDATE_SUCCESS:
      return {
        ...state,
        clientAggregate: payload,
        error: null,
        updated: true,
      };

    case CLIENT_AGGREGATE_GET_BY_ID:
      return {
        ...state,
        loading: false,
        error: null,
        updated: false,
      };
    case CLIENT_AGGREGATE_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: true,
        clientAggregate: payload,
        updated: false,
      };

    case CLIENT_AGGREGATE_UPDATE_ERROR:
    case CLIENT_AGGREGATE_GET_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        updated: false,
      };

    default:
      return { ...state };
  }
};
