import {
  ES_CONTRACT_REPORT_GET_LIST,
  ES_CONTRACT_REPORT_GET_LIST_SUCCESS,
  ES_CONTRACT_REPORT_GET_LIST_ERROR,
  ES_CONTRACT_REPORT_GET_LIST_WITH_FILTER,
  ES_CONTRACT_REPORT_GET_LIST_WITH_ORDER,
  ES_CONTRACT_REPORT_GET_LIST_SEARCH,
} from '../../actions';

export const getESContractReportList = filter => ({
  type: ES_CONTRACT_REPORT_GET_LIST,
  payload: filter,
});

export const getESContractReportListSuccess = items => ({
  type: ES_CONTRACT_REPORT_GET_LIST_SUCCESS,
  payload: items,
});

export const getESContractReportListError = error => ({
  type: ES_CONTRACT_REPORT_GET_LIST_ERROR,
  payload: error,
});

export const getESContractReportListWithFilter = (column, value) => ({
  type: ES_CONTRACT_REPORT_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getESContractReportListWithOrder = column => ({
  type: ES_CONTRACT_REPORT_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getESContractReportListSearch = keyword => ({
  type: ES_CONTRACT_REPORT_GET_LIST_SEARCH,
  payload: keyword,
});
