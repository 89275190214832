import FileSaver from 'file-saver';
import Config from '../config';
import { authHeader, handleResponse } from '../helpers/auth-header';
import axios from 'axios';
import { buildUrl } from '../helpers/Utils';

const error = async payload => {
  return await axios.post(`${Config.apiServerHost}/api/logging/log`, payload, {
    headers: { ...(await authHeader()).headers },
  });
};

export const loggingService = {
  error,
};
