import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  ACTION_PLAN_DELETE,
  ACTION_PLAN_EDIT,
  ACTION_PLAN_GET_BY_ID,
  ACTION_PLAN_ADD_NEW,
  ACTION_PLAN_GET_LIST,
  ACTION_PLAN_STATUS_CHANGE,
} from '../actions';
import Config from '../../config';

import {
  getActionPlanListSuccess,
  getActionPlanListError,
  editActionPlanError,
  addNewActionPlanError,
  deleteActionPlanSuccess,
  deleteActionPlanError,
  getActionPlanByIdSuccess,
  changeActionPlanStatusSuccess,
  changeActionPlanStatusError,
} from './actions';

const getActionPlanListRequest = async payload => {
  let url = `${Config.apiServerHost}/api/ActionPlan`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const getTaskCompletedTrainingRequest = async payload => {
  let url = `${Config.apiServerHost}/api/ActionPlan/taskCompletedTraining`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const getActionPlanListByClientIdRequest = async payload => {
  let url = `${Config.apiServerHost}/api/ActionPlan/search`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const getActionPlanCountByClientIdRequest = async payload => {
  let url = `${Config.apiServerHost}/api/ActionPlan/count`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const addNewActionPlanReqeust = async actionPlan => {
  return axios.post(`${Config.apiServerHost}/api/ActionPlan`, actionPlan, await authHeader());
};

const editActionPlanRequest = async actionPlan => {
  return axios.put(`${Config.apiServerHost}/api/ActionPlan`, actionPlan, await authHeader());
};

const deleteActionPlanRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/ActionPlan/${id}`, await authHeader());
};

const getActionPlanByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/ActionPlan/${id}`, await authHeader());
};

const changeActionPlanStatusRequest = async payload => {
  let url = `${Config.apiServerHost}/api/ActionPlan/statusChange`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const getFileClosureFollowupRequest = async payload => {
  let url = `${Config.apiServerHost}/api/ActionPlan/fileClosureFollowup`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const updateFileClosureFollowupRequest = async item => {
  return axios.post(
    `${Config.apiServerHost}/api/ActionPlan/updateFileClosureFollowup`,
    item,
    await authHeader()
  );
};

function* getActionPlanList({ payload }) {
  try {
    const response = yield call(getActionPlanListByClientIdRequest, payload);
    yield put(getActionPlanListSuccess(response.data));
  } catch (error) {
    yield put(getActionPlanListError(error.response.statusText));
  }
}

function* addNewActionPlan({ payload }) {
  try {
    let response = yield call(addNewActionPlanReqeust, payload);
    const { clientId, servicePlanHeaderId, programId } = payload;
    response = yield call(getActionPlanListByClientIdRequest, {
      clientId,
      servicePlanHeaderId,
      programId,
    });
    yield put(getActionPlanListSuccess(response.data));
  } catch (error) {
    yield put(addNewActionPlanError(error.response.statusText));
  }
}

function* editActionPlan({ payload }) {
  try {
    let response = yield call(editActionPlanRequest, payload);
    const { clientId, servicePlanHeaderId, programId } = payload;
    response = yield call(getActionPlanListByClientIdRequest, {
      clientId,
      servicePlanHeaderId,
      programId,
    });
    yield put(getActionPlanListSuccess(response.data));
  } catch (error) {
    yield put(editActionPlanError(error.response.statusText));
  }
}

function* getActionPlanById({ payload }) {
  try {
    const response = yield call(getActionPlanByIdRequest, payload);
    yield put(getActionPlanByIdSuccess(response.data));
  } catch (error) {
    yield put(editActionPlanError(error.response.statusText));
  }
}

function* changeActionPlanStatus({ payload }) {
  try {
    let response = yield call(changeActionPlanStatusRequest, payload);
    response = yield call(getActionPlanByIdRequest, response.data);
    yield put(changeActionPlanStatusSuccess(response.data));
  } catch (error) {
    yield put(changeActionPlanStatusError(error.response.statusText));
  }
}

function* deleteActionPlan({ payload }) {
  try {
    const response = yield call(deleteActionPlanRequest, payload);
    yield put(deleteActionPlanSuccess(response.data));
  } catch (error) {
    yield put(deleteActionPlanError(error.response.statusText));
  }
}

export function* watchActionPlanList() {
  yield takeEvery(ACTION_PLAN_GET_LIST, getActionPlanList);
}

export function* watchAddNewActionPlan() {
  yield takeEvery(ACTION_PLAN_ADD_NEW, addNewActionPlan);
}

export function* watchActionPlanById() {
  yield takeEvery(ACTION_PLAN_GET_BY_ID, getActionPlanById);
}

export function* watchEditActionPlan() {
  yield takeEvery(ACTION_PLAN_EDIT, editActionPlan);
}

export function* watchDeleteActionPlan() {
  yield takeEvery(ACTION_PLAN_DELETE, deleteActionPlan);
}

export function* watchChangeActionPlanStatus() {
  yield takeEvery(ACTION_PLAN_STATUS_CHANGE, changeActionPlanStatus);
}

export default function* rootSaga() {
  yield all([
    fork(watchActionPlanList),
    fork(watchAddNewActionPlan),
    fork(watchActionPlanById),
    fork(watchEditActionPlan),
    fork(watchDeleteActionPlan),
    fork(watchChangeActionPlanStatus),
  ]);
}

export {
  getActionPlanListByClientIdRequest,
  getActionPlanListRequest,
  editActionPlanRequest,
  getActionPlanCountByClientIdRequest,
  getFileClosureFollowupRequest,
  updateFileClosureFollowupRequest,
  getActionPlanByIdRequest,
  changeActionPlanStatusRequest,
  getTaskCompletedTrainingRequest,
};
