import {
  CLIENT_JOB_MATCH_ADD_NEW,
  CLIENT_JOB_MATCH_ADD_NEW_SUCCESS,
  CLIENT_JOB_MATCH_ADD_NEW_ERROR,
  CLIENT_JOB_MATCH_EDIT,
  CLIENT_JOB_MATCH_EDIT_ERROR,
  CLIENT_JOB_MATCH_EDIT_SUCCESS,
  CLIENT_JOB_MATCH_GET_BY_QUERY,
  CLIENT_JOB_MATCH_GET_BY_ID,
  CLIENT_JOB_MATCH_GET_SINGLE_BY_QUERY,
  CLIENT_JOB_MATCH_GET_BY_QUERY_SUCCESS,
  CLIENT_JOB_MATCH_GET_BY_QUERY_ERROR,
  CLIENT_JOB_MATCH_GET_BY_ID_SUCCESS,
  CLIENT_JOB_MATCH_ADD_NEW_REQUEST,
  CLIENT_JOB_MATCH_DELETE,
  CLIENT_JOB_MATCH_DELETE_SUCCESS,
  CLIENT_JOB_MATCH_DELETE_ERROR,
  CLIENT_JOB_MATCH_GET_BY_ID_ERROR,
} from './types';

// ClientJobMatch

export const addNewClientJobMatch = items => ({
  type: CLIENT_JOB_MATCH_ADD_NEW,
  payload: items,
});

export const addNewClientJobMatchRequest = () => ({
  type: CLIENT_JOB_MATCH_ADD_NEW_REQUEST,
});

export const addNewClientJobMatchSuccess = items => ({
  type: CLIENT_JOB_MATCH_ADD_NEW_SUCCESS,
  payload: items,
});

export const addNewClientJobMatchError = error => ({
  type: CLIENT_JOB_MATCH_ADD_NEW_ERROR,
  payload: error,
});

export const editClientJobMatch = item => ({
  type: CLIENT_JOB_MATCH_EDIT,
  payload: item,
});

export const editClientJobMatchSuccess = item => ({
  type: CLIENT_JOB_MATCH_EDIT_SUCCESS,
  payload: item,
});

export const editClientJobMatchError = error => ({
  type: CLIENT_JOB_MATCH_EDIT_ERROR,
  payload: error,
});

export const getSingleClientJobMatchByQuery = query => ({
  type: CLIENT_JOB_MATCH_GET_SINGLE_BY_QUERY,
  payload: query,
});

export const getClientJobMatchByQuery = query => ({
  type: CLIENT_JOB_MATCH_GET_BY_QUERY,
  payload: query,
});

export const getClientJobMatchByQuerySuccess = item => ({
  type: CLIENT_JOB_MATCH_GET_BY_QUERY_SUCCESS,
  payload: item,
});

export const getClientJobMatchByQueryError = error => ({
  type: CLIENT_JOB_MATCH_GET_BY_QUERY_ERROR,
  payload: error,
});

export const getClientJobMatchById = id => ({
  type: CLIENT_JOB_MATCH_GET_BY_ID,
  payload: id,
});

export const getClientJobMatchByIdSuccess = item => ({
  type: CLIENT_JOB_MATCH_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getClientJobMatchByIdError = error => ({
  type: CLIENT_JOB_MATCH_GET_BY_ID_ERROR,
  payload: error,
});

export const deleteClientJobMatchById = id => ({
  type: CLIENT_JOB_MATCH_DELETE,
  payload: id,
});

export const deleteClientJobMatchByIdSuccess = item => ({
  type: CLIENT_JOB_MATCH_DELETE_SUCCESS,
  payload: item,
});

export const deleteClientJobMatchByIdErrorr = item => ({
  type: CLIENT_JOB_MATCH_DELETE_ERROR,
  payload: item,
});
