import {
  STAKE_HOLDER_EMAIL_ADD_NEW,
  STAKE_HOLDER_EMAIL_ADD_NEW_ERROR,
  STAKE_HOLDER_EMAIL_ADD_NEW_SUCCESS,
  STAKE_HOLDER_EMAIL_CHANGE_STATUS,
  STAKE_HOLDER_EMAIL_CHANGE_STATUS_ERROR,
  STAKE_HOLDER_EMAIL_CHANGE_STATUS_SUCCESS,
  STAKE_HOLDER_EMAIL_DELETE,
  STAKE_HOLDER_EMAIL_DELETE_ERROR,
  STAKE_HOLDER_EMAIL_DELETE_SUCCESS,
  STAKE_HOLDER_EMAIL_GET_BY_ID,
  STAKE_HOLDER_EMAIL_GET_BY_ID_ERROR,
  STAKE_HOLDER_EMAIL_GET_BY_ID_SUCCESS,
  STAKE_HOLDER_EMAIL_GET_PAGING,
  STAKE_HOLDER_EMAIL_GET_PAGING_ERROR,
  STAKE_HOLDER_EMAIL_GET_PAGING_SUCCESS,
  STAKE_HOLDER_EMAIL_UPDATE,
  STAKE_HOLDER_EMAIL_UPDATE_ERROR,
  STAKE_HOLDER_EMAIL_UPDATE_SUCCESS,
} from './types';

// StakeHolderEmail

export const getPagingStakeHolderEmail = items => ({
  type: STAKE_HOLDER_EMAIL_GET_PAGING,
  payload: items,
});

export const getPagingStakeHolderEmailSuccess = items => ({
  type: STAKE_HOLDER_EMAIL_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingStakeHolderEmailError = error => ({
  type: STAKE_HOLDER_EMAIL_GET_PAGING_ERROR,
  payload: error,
});

export const addStakeHolderEmail = items => ({
  type: STAKE_HOLDER_EMAIL_ADD_NEW,
  payload: items,
});

export const addStakeHolderEmailSuccess = items => ({
  type: STAKE_HOLDER_EMAIL_ADD_NEW_SUCCESS,
  payload: items,
});

export const addStakeHolderEmailError = error => ({
  type: STAKE_HOLDER_EMAIL_ADD_NEW_ERROR,
  payload: error,
});

export const getStakeHolderEmailById = items => ({
  type: STAKE_HOLDER_EMAIL_GET_BY_ID,
  payload: items,
});

export const getStakeHolderEmailByIdSuccess = items => ({
  type: STAKE_HOLDER_EMAIL_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getStakeHolderEmailByIdError = error => ({
  type: STAKE_HOLDER_EMAIL_GET_BY_ID_ERROR,
  payload: error,
});

export const updateStakeHolderEmail = items => ({
  type: STAKE_HOLDER_EMAIL_UPDATE,
  payload: items,
});

export const updateStakeHolderEmailSuccess = items => ({
  type: STAKE_HOLDER_EMAIL_UPDATE_SUCCESS,
  payload: items,
});

export const updateStakeHolderEmailError = error => ({
  type: STAKE_HOLDER_EMAIL_UPDATE_ERROR,
  payload: error,
});

export const changeStatusStakeHolderEmail = items => ({
  type: STAKE_HOLDER_EMAIL_CHANGE_STATUS,
  payload: items,
});

export const changeStatusStakeHolderEmailSuccess = items => ({
  type: STAKE_HOLDER_EMAIL_CHANGE_STATUS_SUCCESS,
  payload: items,
});

export const changeStatusStakeHolderEmailError = error => ({
  type: STAKE_HOLDER_EMAIL_CHANGE_STATUS_ERROR,
  payload: error,
});

export const deleteStakeHolderEmail = items => ({
  type: STAKE_HOLDER_EMAIL_DELETE,
  payload: items,
});

export const deleteStakeHolderEmailSuccess = items => ({
  type: STAKE_HOLDER_EMAIL_DELETE_SUCCESS,
  payload: items,
});

export const deleteStakeHolderEmailError = error => ({
  type: STAKE_HOLDER_EMAIL_DELETE_ERROR,
  payload: error,
});
