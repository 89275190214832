import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../../helpers/auth-header';
import Config from '../../../config';
import axios from 'axios';

import { CONTRACTS_PAID_GET_LIST } from '../../actions';

import { getContractsPaidListSuccess, getContractsPaidListError } from './actions';

const getContractsPaidListRequest = async filter => {
  return await axios.get(
    `${Config.apiServerHost}/api/contract/cojg/contract-paid?fromDate=${filter.payload.fromDate}&toDate=${filter.payload.toDate}&fiscalYear=${filter.payload.fiscalYear}`,
    await authHeader()
  );
};

function* getContractsPaidListItems(payload) {
  try {
    const response = yield call(getContractsPaidListRequest, payload);
    yield put(getContractsPaidListSuccess(response.data));
  } catch (error) {
    yield put(getContractsPaidListError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(CONTRACTS_PAID_GET_LIST, getContractsPaidListItems);
}

export default function* rootSaga() {
  yield all([fork(watchGetList)]);
}
