import {
  INVITATION_ADD_NEW,
  INVITATION_ADD_NEW_SUCCESS,
  INVITATION_ADD_NEW_ERROR,
  INVITATION_EDIT,
  INVITATION_EDIT_SUCCESS,
  INVITATION_EDIT_ERROR,
  INVITATION_GET_BY_QUERY,
  INVITATION_GET_BY_ID,
  INVITATION_GET_BY_ID_SUCCESS,
  INVITATION_GET_BY_ID_ERROR,
  INVITATION_GET_BY_QUERY_SUCCESS,
  INVITATION_GET_BY_QUERY_ERROR,
  INVITATION_ADD_NEW_REQUEST,
  INVITATION_DELETE,
  INVITATION_DELETE_ERROR,
  INVITATION_SEND_INVITATION,
  INVITATION_SEND_INVITATION_ERROR,
} from './types';
// item
const initialState = {
  item: null,
  items: [],
  loading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
  total: 0,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case INVITATION_ADD_NEW:
    case INVITATION_DELETE:
    case INVITATION_GET_BY_ID:
    case INVITATION_GET_BY_QUERY:
    case INVITATION_SEND_INVITATION:
      return {
        ...state,
        loading: true,
        error: null,
        item: null,
      };

    case INVITATION_EDIT:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: true,
      };
    case INVITATION_EDIT_SUCCESS:
    case INVITATION_ADD_NEW_REQUEST:
      return {
        ...state,
        loading: false,
        item: null,
        error: null,
      };
    case INVITATION_ADD_NEW_SUCCESS:
    case INVITATION_GET_BY_QUERY_SUCCESS:
      const { itemCount, pageCount, total, data } = payload;
      return {
        ...state,
        loading: false,
        items: data,
        total,
        itemCount,
        pageCount,
      };

    case INVITATION_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
      };

    case INVITATION_ADD_NEW_ERROR:
    case INVITATION_DELETE_ERROR:
    case INVITATION_EDIT_ERROR:
    case INVITATION_GET_BY_ID_ERROR:
    case INVITATION_GET_BY_QUERY_ERROR:
    case INVITATION_SEND_INVITATION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
}
