import {
  GET_USER_DOCUMENTS,
  GET_USER_DOCUMENTS_SUCCESS,
  GET_USER_DOCUMENTS_ERROR,
  GET_USER_DOCUMENTS_BY_ID,
  GET_USER_DOCUMENTS_BY_ID_SUCCESS,
  GET_USER_DOCUMENTS_BY_ID_ERROR,
  UPDATE_USER_DOCUMENTS,
  UPDATE_USER_DOCUMENTS_SUCCESS,
  UPDATE_USER_DOCUMENTS_ERROR,
  SAVE_USER_DOCUMENTS,
  SAVE_USER_DOCUMENTS_SUCCESS,
  SAVE_USER_DOCUMENTS_ERROR,
  DELETE_USER_DOCUMENT_BY_ID,
  DELETE_USER_DOCUMENT_BY_ID_SUCCESS,
  DELETE_USER_DOCUMENT_BY_ID_ERROR,
} from './types';

export const getUserDocuments = query => ({
  type: GET_USER_DOCUMENTS,
  payload: query,
});

export const getUserDocumentsSuccess = documents => ({
  type: GET_USER_DOCUMENTS_SUCCESS,
  payload: documents,
});

export const getUserDocumentsError = error => ({
  type: GET_USER_DOCUMENTS_ERROR,
  payload: error,
});

export const saveUserDocuments = documents => ({
  type: SAVE_USER_DOCUMENTS,
  payload: { documents },
});

export const saveUserDocumentsSuccess = userId => ({
  type: SAVE_USER_DOCUMENTS_SUCCESS,
  payload: userId,
});

export const saveUserDocumentsError = error => ({
  type: SAVE_USER_DOCUMENTS_ERROR,
  payload: error,
});

export const deleteUserDocumentById = payload => ({
  type: DELETE_USER_DOCUMENT_BY_ID,
  payload,
});

export const deleteUserDocumentByIdSuccess = userId => ({
  type: DELETE_USER_DOCUMENT_BY_ID_SUCCESS,
  payload: userId,
});

export const deleteUserDocumentByIdError = error => ({
  type: DELETE_USER_DOCUMENT_BY_ID_ERROR,
  payload: error,
});

export const getUserDocumentsById = id => ({
  type: GET_USER_DOCUMENTS_BY_ID,
  payload: id,
});

export const getUserDocumentsByIdSuccess = document => ({
  type: GET_USER_DOCUMENTS_BY_ID_SUCCESS,
  payload: document,
});

export const getUserDocumentsByIdError = error => ({
  type: GET_USER_DOCUMENTS_BY_ID_ERROR,
  payload: error,
});

export const updateUserDocument = document => ({
  type: UPDATE_USER_DOCUMENTS,
  payload: document,
});

export const updateUserDocumentSuccess = document => ({
  type: UPDATE_USER_DOCUMENTS_SUCCESS,
  payload: document,
});

export const updateUserDocumentError = error => ({
  type: UPDATE_USER_DOCUMENTS_ERROR,
  payload: error,
});
