// TARGET_SCHEME
export const TARGET_SCHEME_GET_BY_ID = 'TARGET_SCHEME_GET_BY_ID';
export const TARGET_SCHEME_GET_BY_ID_SUCCESS = 'TARGET_SCHEME_GET_BY_ID_SUCCESS';
export const TARGET_SCHEME_GET_BY_ID_ERROR = 'TARGET_SCHEME_GET_BY_ID_ERROR';

export const TARGET_SCHEME_GET_LIST = 'TARGET_SCHEME_GET_LIST';
export const TARGET_SCHEME_GET_LIST_SUCCESS = 'TARGET_SCHEME_GET_LIST_SUCCESS';
export const TARGET_SCHEME_GET_LIST_ERROR = 'TARGET_SCHEME_GET_LIST_ERROR';

export const TARGET_SCHEME_EDIT = 'TARGET_SCHEME_EDIT';
export const TARGET_SCHEME_EDIT_SUCCESS = 'TARGET_SCHEME_EDIT_SUCCESS';
export const TARGET_SCHEME_EDIT_ERROR = 'TARGET_SCHEME_EDIT_ERROR';

export const TARGET_SCHEME_ADD_NEW = 'TARGET_SCHEME_ADD_NEW';
export const TARGET_SCHEME_ADD_NEW_SUCCESS = 'TARGET_SCHEME_ADD_NEW_SUCCESS';
export const TARGET_SCHEME_ADD_NEW_ERROR = 'TARGET_SCHEME_ADD_NEW_ERROR';
export const TARGET_SCHEME_ADD_NEW_REQUEST = 'TARGET_SCHEME_ADD_NEW_REQUEST';

export const TARGET_SCHEME_DELETE = 'TARGET_SCHEME_DELETE';
export const TARGET_SCHEME_DELETE_SUCCESS = 'TARGET_SCHEME_DELETE_SUCCESS';
export const TARGET_SCHEME_DELETE_ERROR = 'TARGET_SCHEME_DELETE_ERROR';

export const TARGET_SCHEME_GET_BY_QUERY = 'TARGET_SCHEME_GET_BY_QUERY';
export const TARGET_SCHEME_GET_BY_QUERY_SUCCESS = 'TARGET_SCHEME_GET_BY_QUERY_SUCCESS';
export const TARGET_SCHEME_GET_BY_QUERY_ERROR = 'TARGET_SCHEME_GET_BY_QUERY_ERROR';

export const TARGET_SCHEME_GET_OPTIONS = 'TARGET_SCHEME_GET_OPTIONS';
export const TARGET_SCHEME_GET_OPTIONS_SUCCESS = 'TARGET_SCHEME_GET_OPTIONS_SUCCESS';
