import {
  GET_CLIENT_SUMMARY_LBS,
  GET_CLIENT_SUMMARY_LBS_SUCCESS,
  SAVE_CLIENT_SUMMARY_LBS,
  CLIENT_SUMMARY_LBS_ERROR,
} from './types';
export const getClientSummaryLBS = payload => ({
  type: GET_CLIENT_SUMMARY_LBS,
  payload,
});

export const getClientSummaryLBSSuccess = data => ({
  type: GET_CLIENT_SUMMARY_LBS_SUCCESS,
  payload: data,
});

export const saveClientSummaryLBS = data => ({
  type: SAVE_CLIENT_SUMMARY_LBS,
  payload: data,
});

export const clientSummaryLBSError = error => ({
  type: CLIENT_SUMMARY_LBS_ERROR,
  payload: error,
});
