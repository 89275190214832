import {
  CONTACT_ADD_NEW,
  CONTACT_ADD_NEW_SUCCESS,
  CONTACT_ADD_NEW_ERROR,
  CONTACT_DELETE,
  CONTACT_DELETE_ERROR,
  CONTACT_DELETE_SUCCESS,
  CONTACT_EDIT,
  CONTACT_EDIT_ERROR,
  CONTACT_EDIT_SUCCESS,
  CONTACT_GET_BY_ID,
  CONTACT_GET_BY_ID_ERROR,
  CONTACT_GET_BY_ID_SUCCESS,
  CONTACT_GET_LIST,
  CONTACT_GET_LIST_ERROR,
  CONTACT_GET_LIST_SUCCESS,
  CONTACT_ADD_NEW_REQUEST,
  CONTACT_QUERY,
  CONTACT_QUERY_SUCCESS,
  CONTACT_QUERY_ERROR,
} from '../actions';

// Contact
export const getContactQuery = query => ({
  type: CONTACT_QUERY,
  payload: query,
});

export const getContactQuerySuccess = caseNotes => ({
  type: CONTACT_QUERY_SUCCESS,
  payload: caseNotes,
});

export const getContactQueryError = error => ({
  type: CONTACT_QUERY_ERROR,
  payload: error,
});

export const getContactList = clientId => ({
  type: CONTACT_GET_LIST,
  payload: clientId,
});

export const getContactListSuccess = CaseNotes => ({
  type: CONTACT_GET_LIST_SUCCESS,
  payload: CaseNotes,
});

export const getContactListError = error => ({
  type: CONTACT_GET_LIST_ERROR,
  payload: error,
});

export const addNewContact = caseNote => ({
  type: CONTACT_ADD_NEW,
  payload: caseNote,
});

export const addNewContactRequest = () => ({
  type: CONTACT_ADD_NEW_REQUEST,
});

export const addNewContactSuccess = CaseNote => ({
  type: CONTACT_ADD_NEW_SUCCESS,
  payload: CaseNote,
});

export const addNewContactError = error => ({
  type: CONTACT_ADD_NEW_ERROR,
  payload: error,
});

export const editContact = CaseNote => ({
  type: CONTACT_EDIT,
  payload: CaseNote,
});

export const editContactSuccess = caseNote => ({
  type: CONTACT_EDIT_SUCCESS,
  payload: caseNote,
});

export const editContactError = error => ({
  type: CONTACT_EDIT_ERROR,
  payload: error,
});

export const deleteContact = id => ({
  type: CONTACT_DELETE,
  payload: id,
});

export const deleteContactSuccess = id => ({
  type: CONTACT_DELETE_SUCCESS,
  payload: id,
});

export const deleteContactError = error => ({
  type: CONTACT_DELETE_ERROR,
  payload: error,
});

export const getContactById = id => ({
  type: CONTACT_GET_BY_ID,
  payload: id,
});

export const getContactByIdSuccess = caseNote => ({
  type: CONTACT_GET_BY_ID_SUCCESS,
  payload: caseNote,
});

export const getContactByIdError = error => ({
  type: CONTACT_GET_BY_ID_ERROR,
  payload: error,
});
