import {
  CHEQUE_GET_LIST,
  CHEQUE_GET_LIST_SUCCESS,
  CHEQUE_GET_LIST_ERROR,
  CHEQUE_ADD_ITEM,
  CHEQUE_EDIT_ITEM,
  CHEQUE_EDIT_ITEM_SUCCESS,
  CHEQUE_EDIT_ITEM_ERROR,
  CHEQUE_ADD_ITEM_SUCCESS,
  CHEQUE_ADD_ITEM_ERROR,
  CHEQUE_DELETE_ITEM,
  CHEQUE_DELETE_ITEM_SUCCESS,
  CHEQUE_DELETE_ITEM_ERROR,
  CHEQUE_GET_BY_ID,
  CHEQUE_GET_BY_ID_SUCCESS,
  CHEQUE_GET_BY_ID_ERROR,
} from '../../actions';

const INIT_STATE = {
  chequeItems: [],
  chequeItem: null,
  error: '',
  filter: null,
  searchKeyword: '',
  orderColumn: null,
  loading: false,

  selectedItems: [],
};

export default (state = INIT_STATE, action) => {
  const { payload, type } = action;
  switch (type) {
    case CHEQUE_GET_LIST:
    case CHEQUE_ADD_ITEM:
    case CHEQUE_EDIT_ITEM:
    case CHEQUE_DELETE_ITEM:
    case CHEQUE_GET_BY_ID:
      return {
        ...state,
        loading: true,
      };

    case CHEQUE_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        chequeItems: payload,
      };

    case CHEQUE_ADD_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        chequeItems: state.chequeItems.concat(payload),
        chequeItem: payload,
      };

    case CHEQUE_EDIT_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        chequeItems: state.chequeItems.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        chequeItem: payload,
      };

    case CHEQUE_DELETE_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        chequeItems: state.chequeItems.filter(c => c.id !== payload),
      };

    case CHEQUE_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        chequeItem: payload,
      };

    case CHEQUE_ADD_ITEM_ERROR:
    case CHEQUE_DELETE_ITEM_ERROR:
    case CHEQUE_GET_LIST_ERROR:
    case CHEQUE_EDIT_ITEM_ERROR:
    case CHEQUE_GET_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
