import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { GET_CLIENT_SUMMARY_LBS, SAVE_CLIENT_SUMMARY_LBS } from './types';
import Config from '../../config';

import { getClientSummaryLBSSuccess, clientSummaryLBSError } from './actions';

const getClientSummaryLBSRequest = async payload => {
  let url = `${Config.apiServerHost}/api/ClientSummaryLBS`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const saveClientSummaryLBSReqeust = async clientSummary => {
  return axios.post(
    `${Config.apiServerHost}/api/ClientSummaryLBS`,
    clientSummary,
    await authHeader()
  );
};

function* getClientSummaryLBS({ payload }) {
  try {
    const response = yield call(getClientSummaryLBSRequest, payload);
    yield put(getClientSummaryLBSSuccess(response.data));
  } catch (error) {
    yield put(clientSummaryLBSError(error.response.statusText));
  }
}

function* saveClientSummaryLBS({ payload }) {
  try {
    const { clientId, servicePlanHeaderId, data } = payload;
    yield call(saveClientSummaryLBSReqeust, data);
    const query = {
      clientId: clientId ?? '',
      servicePlanHeaderId: servicePlanHeaderId ?? '',
    };
    let response = yield call(getClientSummaryLBSRequest, query);
    yield put(getClientSummaryLBSSuccess(response.data));
  } catch (error) {
    yield put(clientSummaryLBSError(error.response.statusText));
  }
}

export function* watchClientSummaryLBS() {
  yield takeEvery(GET_CLIENT_SUMMARY_LBS, getClientSummaryLBS);
}

export function* watchSaveClientSummaryLBS() {
  yield takeEvery(SAVE_CLIENT_SUMMARY_LBS, saveClientSummaryLBS);
}

export default function* rootSaga() {
  yield all([fork(watchClientSummaryLBS), fork(watchSaveClientSummaryLBS)]);
}
