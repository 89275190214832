import { logger } from '../../logger';
import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  CAMS_CLIENT_FETCH,
  CAMS_OVERWRITE_UPDATE,
  CAMS_EMPLOYER_FETCH,
  CAMS_EMPLOYER_OVERWRITE_UPDATE,
} from '../actions';
import { buildUrl } from '../../helpers/Utils';
import {
  getCamsClientSuccess,
  getCamsClientError,
  overwriteCamsSuccess,
  overwriteCamsError,
  getCamsEmployerSuccess,
  getCamsEmployerError,
  employerOverwriteCamsSuccess,
  employerOverwriteCamsError,
} from './actions';

const getCamsClientRequest = async data => {
  let url = `${Config.apiServerHost}/api/CamsApi/full`;
  return await axios.get(buildUrl(url, data), await authHeader());
};

const getValidateClientRefRequest = async data => {
  let url = `${Config.apiServerHost}/api/CamsApi/validate-client-reference`;
  return await axios.get(buildUrl(url, data), await authHeader());
};

const getCamsEmployerRequest = async data => {
  let url = `${Config.apiServerHost}/api/CamsApi/employer/full`;
  return await axios.get(buildUrl(url, data), await authHeader());
};

const getCamsStatus = async data => {
  let url = `${Config.apiServerHost}/api/CamsApi/is-online`;
  return await axios.get(buildUrl(url, data), await authHeader());
};

const getNetworkActivity = async data => {
  let url = `${Config.apiServerHost}/api/CamsApi/network-activity-requests`;
  return await axios.get(buildUrl(url, data), await authHeader());
};

const getStatusCheck = async requestId => {
  let url = `${Config.apiServerHost}/api/CamsApi/checkstatus/request/${requestId}`;
  return await axios.get(url, await authHeader());
};

const getFinalizePackage = async busPackageId => {
  let url = `${Config.apiServerHost}/api/Queue/finalize-package?packageId=${busPackageId}`;
  return await axios.get(url, await authHeader());
};

const hasNetworkActivity = async data => {
  let url = `${Config.apiServerHost}/api/CamsApi/network-activity`;
  return await axios.get(buildUrl(url, data), await authHeader());
};

const removeRequest = async packageId => {
  logger.debug('removeRequest-->', packageId);
  let url = `${Config.apiServerHost}/api/CamsApi/request/${packageId}/remove`;
  return await axios.get(url, await authHeader());
};

const restartRequest = async packageId => {
  let url = `${Config.apiServerHost}/api/CamsApi/request/${packageId}/restart`;
  return await axios.get(url, await authHeader());
};

const getCamsOverwriteUpdateRequest = async data => {
  let url = `${Config.apiServerHost}/api/CamsApi/overwrite`;
  return await axios.post(url, data, await authHeader());
};

const getCamsEmployerOverwriteUpdateRequest = async data => {
  let url = `${Config.apiServerHost}/api/CamsApi/employer/overwrite`;
  return await axios.post(url, data, await authHeader());
};

function* getCamsClient({ payload }) {
  try {
    const response = yield call(getCamsClientRequest, payload);
    yield put(getCamsClientSuccess(response.data));
  } catch (err) {
    const { statusText, data, status } = err.response;
    const error = {
      status,
      title: statusText,
      message: data,
    };
    yield put(getCamsClientError(error));
  }
}

function* getCamsEmployer({ payload }) {
  try {
    const response = yield call(getCamsEmployerRequest, payload);
    yield put(getCamsEmployerSuccess(response.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(getCamsEmployerError(error));
  }
}

function* getCamsOverwriteUpdate({ payload }) {
  try {
    const response = yield call(getCamsOverwriteUpdateRequest, payload);
    yield put(overwriteCamsSuccess(response.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(overwriteCamsError(error));
  }
}

function* getCamsEmployerOverwriteUpdate({ payload }) {
  try {
    const response = yield call(getCamsEmployerOverwriteUpdateRequest, payload);
    yield put(employerOverwriteCamsSuccess(response.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(employerOverwriteCamsError(error));
  }
}

export function* watchGetCamsClientRequest() {
  yield takeEvery(CAMS_CLIENT_FETCH, getCamsClient);
}

export function* watchCamsOverwriteUpdateRequest() {
  yield takeEvery(CAMS_OVERWRITE_UPDATE, getCamsOverwriteUpdate);
}

export function* watchGetCamsEmployerRequest() {
  yield takeEvery(CAMS_EMPLOYER_FETCH, getCamsEmployer);
}

export function* watchCamsEmployerOverwriteUpdateRequest() {
  yield takeEvery(CAMS_EMPLOYER_OVERWRITE_UPDATE, getCamsEmployerOverwriteUpdate);
}

export default function* rootSaga() {
  yield all([fork(watchGetCamsClientRequest)]);
  yield all([fork(watchCamsOverwriteUpdateRequest)]);
  yield all([fork(watchGetCamsEmployerRequest)]);
  yield all([fork(watchCamsEmployerOverwriteUpdateRequest)]);
}

export {
  getCamsStatus,
  hasNetworkActivity,
  getNetworkActivity,
  removeRequest,
  restartRequest,
  getCamsClientRequest,
  getStatusCheck,
  getFinalizePackage,
  getValidateClientRefRequest,
};
