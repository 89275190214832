import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  TARGET_SCHEME_ADD_NEW,
  TARGET_SCHEME_EDIT,
  TARGET_SCHEME_GET_BY_QUERY,
  TARGET_SCHEME_GET_BY_ID,
  TARGET_SCHEME_DELETE,
  TARGET_SCHEME_GET_OPTIONS,
} from './types';

import {
  addNewTargetSchemeError,
  editTargetSchemeError,
  getTargetSchemeByQuerySuccess,
  getTargetSchemeByQueryError,
  getTargetSchemeByIdError,
  getTargetSchemeByIdSuccess,
  deleteTargetSchemeByIdErrorr,
  getTargetSchemeOptionsSuccess,
} from './actions';

const addNewTargetSchemeReqeust = async items => {
  return axios.post(`${Config.apiServerHost}/api/TargetScheme`, items, await authHeader());
};

const editTargetSchemeRequest = async items => {
  return axios.put(`${Config.apiServerHost}/api/TargetScheme`, items, await authHeader());
};

const getTargetSchemeByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/TargetScheme/${id}`, await authHeader());
};

const getTargetSchemeDropdownSelectRequest = async () => {
  return axios.get(`${Config.apiServerHost}/api/TargetScheme/dropdownSelect`, await authHeader());
};

const getTargetSchemeByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/TargetScheme/fetch`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const deleteTargetSchemeRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/TargetScheme/${id}`, await authHeader());
};
function* addNewTargetScheme({ payload }) {
  try {
    let response = yield call(addNewTargetSchemeReqeust, payload);
    response = yield call(getTargetSchemeByIdRequest, response.data);
    yield put(getTargetSchemeByIdSuccess(response.data));

    response = yield call(getTargetSchemeDropdownSelectRequest, payload);
    yield put(getTargetSchemeOptionsSuccess(response.data));
  } catch (error) {
    yield put(addNewTargetSchemeError(error.response.statusText));
  }
}

function* editTargetScheme({ payload }) {
  try {
    let response = yield call(editTargetSchemeRequest, payload);
    response = yield call(getTargetSchemeByIdRequest, response.data);
    yield put(getTargetSchemeByIdSuccess(response.data));
  } catch (error) {
    yield put(editTargetSchemeError(error.response.statusText));
  }
}

function* getTargetSchemeByQuery({ payload }) {
  try {
    const response = yield call(getTargetSchemeByQueryRequest, payload);
    yield put(getTargetSchemeByQuerySuccess(response.data));
  } catch (error) {
    yield put(getTargetSchemeByQueryError(error.response.statusText));
  }
}

function* getTargetSchemeById({ payload }) {
  try {
    const response = yield call(getTargetSchemeByIdRequest, payload);
    yield put(getTargetSchemeByIdSuccess(response.data));
  } catch (error) {
    yield put(getTargetSchemeByIdError(error.response.statusText));
  }
}

function* getTargetSchemeOptions({ payload }) {
  try {
    const response = yield call(getTargetSchemeDropdownSelectRequest, payload);
    yield put(getTargetSchemeOptionsSuccess(response.data));

    let options = response.data;
    if (options && options.length > 0) {
      let defaultOption = options[0];
      const response = yield call(getTargetSchemeByIdRequest, defaultOption.value);
      yield put(getTargetSchemeByIdSuccess(response.data));
    }
  } catch (error) {
    yield put(getTargetSchemeByIdError(error.response.statusText));
  }
}

function* deleteTargetScheme({ payload }) {
  try {
    const { programId, clientId, servicePlanHeaderId, data } = payload;
    const query = {
      programId,
      clientId,
      servicePlanHeaderId,
    };

    let response = yield call(deleteTargetSchemeRequest, data);
    response = yield call(getTargetSchemeByQueryRequest, query);
    yield put(getTargetSchemeByQuerySuccess(response.data));
  } catch (error) {
    yield put(deleteTargetSchemeByIdErrorr(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(TARGET_SCHEME_ADD_NEW, addNewTargetScheme);
  yield takeEvery(TARGET_SCHEME_EDIT, editTargetScheme);
  yield takeEvery(TARGET_SCHEME_GET_BY_QUERY, getTargetSchemeByQuery);
  yield takeEvery(TARGET_SCHEME_GET_BY_ID, getTargetSchemeById);
  yield takeEvery(TARGET_SCHEME_DELETE, deleteTargetScheme);
  yield takeEvery(TARGET_SCHEME_GET_OPTIONS, getTargetSchemeOptions);
}

export { getTargetSchemeDropdownSelectRequest };
