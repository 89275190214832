import {
  ALL_ES_CONTRACT_OUSTANDING_GET_LIST,
  ALL_ES_CONTRACT_OUSTANDING_GET_LIST_SUCCESS,
  ALL_ES_CONTRACT_OUSTANDING_GET_LIST_ERROR,
  ALL_ES_CONTRACT_OUSTANDING_GET_LIST_WITH_FILTER,
  ALL_ES_CONTRACT_OUSTANDING_GET_LIST_WITH_ORDER,
  ALL_ES_CONTRACT_OUSTANDING_GET_LIST_SEARCH,
} from '../../actions';

const INIT_STATE = {
  allESContractOustandings: [],
  allESContractOustanding: [],
  error: '',
  filter: null,
  searchKeyword: '',
  orderColumn: null,
  loading: false,
  labels: [
    { label: 'EDUCATION', color: 'secondary' },
    { label: 'NEW FRAMEWORK', color: 'primary' },
    { label: 'PERSONAL', color: 'info' },
  ],
  orderColumns: [
    { column: 'id', label: 'Id' },
    { column: 'name', label: 'Name' },
    { column: 'hid', label: 'HST' },
    { column: 'pcc', label: 'PCC' },
    { column: 'note', label: 'Note' },
  ],
  categories: ['Flexbox', 'Sass', 'React'],
  selectedItems: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ALL_ES_CONTRACT_OUSTANDING_GET_LIST:
      return { ...state, loading: false };

    case ALL_ES_CONTRACT_OUSTANDING_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        allESContractOustandings: action.payload,
        allESContractOustanding: action.payload,
      };

    case ALL_ES_CONTRACT_OUSTANDING_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case ALL_ES_CONTRACT_OUSTANDING_GET_LIST_WITH_FILTER:
      if (action.payload.column === '' || action.payload.value === '') {
        return {
          ...state,
          loading: true,
          allESContractOustanding: state.allESContractOustandings,
          filter: null,
        };
      } else {
        const filteredItems = state.allESContractOustandings.filter(
          item => item[action.payload.column] === action.payload.value
        );
        return {
          ...state,
          loading: true,
          esContractReport: filteredItems,
          filter: {
            column: action.payload.column,
            value: action.payload.value,
          },
        };
      }

    case ALL_ES_CONTRACT_OUSTANDING_GET_LIST_WITH_ORDER:
      if (action.payload === '') {
        return {
          ...state,
          loading: true,
          esContractReport: state.esContractReport,
          orderColumn: null,
        };
      } else {
        const sortedItems = state.esContractReport.sort((a, b) => {
          if (a[action.payload] < b[action.payload]) return -1;
          else if (a[action.payload] > b[action.payload]) return 1;
          return 0;
        });
        return {
          ...state,
          loading: true,
          esContractReport: sortedItems,
          orderColumn: state.orderColumns.find(x => x.column === action.payload),
        };
      }

    case ALL_ES_CONTRACT_OUSTANDING_GET_LIST_SEARCH:
      if (action.payload === '') {
        return { ...state, allESContractOustanding: state.allESContractOustanding };
      } else {
        const keyword = action.payload.toLowerCase();
        const searchItems = state.esContractReport.allESContractOustandings.filter(
          item =>
            String(item.id).startsWith(keyword) ||
            String(item.company.toLowerCase()).startsWith(keyword) ||
            String(item.trainingStartDate).startsWith(keyword) ||
            String(item.contractTier).startsWith(keyword) ||
            String(item.caMs).startsWith(keyword) ||
            String(item.revisedInKind).startsWith(keyword) ||
            String(item.revisedContractAmount).startsWith(keyword)
        );
        return {
          ...state,
          loading: true,
          esContractReport: searchItems,
          searchKeyword: action.payload,
        };
      }
    default:
      return { ...state };
  }
};
