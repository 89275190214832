import {
  GET_IES_INTERVENTION,
  GET_IES_INTERVENTION_SUCCESS,
  GET_IES_INTERVENTION_ERROR,
} from './types';

// item
const initialState = {
  items: null,
  loading: false,
  error: null,
};

const iesDashboard = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_IES_INTERVENTION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_IES_INTERVENTION_SUCCESS:
      return {
        ...state,
        loading: false,
        items: payload,
      };

    case GET_IES_INTERVENTION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return { ...state };
  }
};

export default iesDashboard;
