import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  OUTCOME_QUESTIONAIRE_GET_BY_ID,
  OUTCOME_QUESTIONAIRE_UPDATE,
  OUTCOME_QUESTIONAIRE_SYNC,
} from './types';

import {
  getOutcomeQuestionaireByIdError,
  getOutcomeQuestionaireByIdSuccess,
  updateOutcomeQuestionaireError,
  syncOutcomeQuestionaireError,
} from './actions';
import { getClientServicePlanRequest } from '../client/saga';
import { getClientServicePlanSuccess } from '../actions';

const getOutcomeQuestionaireByIdRequest = async queryParam => {
  return axios.get(`${Config.apiServerHost}/api/OutcomeQuestionnaire`, {
    params: queryParam,
    headers: { ...(await authHeader()).headers },
  });
};

const getLiveQuestionaireTransactions = async () => {
  return axios.get(
    `${Config.apiServerHost}/api/OutcomeQuestionnaire/transaction/live`,
    await authHeader()
  );
};

const detectDifferenceOutcomeQuestionnaireRequest = async queryParam => {
  return axios.get(`${Config.apiServerHost}/api/OutcomeQuestionnaire/detectDifference`, {
    params: queryParam,
    headers: { ...(await authHeader()).headers },
  });
};

function* getOutcomeQuestionaireById({ payload }) {
  try {
    let response = yield call(getOutcomeQuestionaireByIdRequest, payload);
    yield put(getOutcomeQuestionaireByIdSuccess(response.data));
  } catch (error) {
    const message = {
      title: 'Outcomes',
      text: error.response?.data?.message ?? 'something went wrong.',
    };
    yield put(getOutcomeQuestionaireByIdError(message));
  }
}

const updateOutcomeQuestionaireRequest = async (
  outcomeQuestionnaire,
  clientId,
  servicePlanHeaderId
) => {
  return axios.post(
    `${Config.apiServerHost}/api/OutcomeQuestionnaire?clientId=${clientId}&servicePlanHeaderId=${servicePlanHeaderId}`,
    outcomeQuestionnaire,
    await authHeader()
  );
};

const syncOutcomeQuestionaireRequest = async data => {
  return axios.post(
    `${Config.apiServerHost}/api/OutcomeQuestionnaire/sync?clientId=${data.clientId}&servicePlanHeaderId=${data.servicePlanHeaderId}&clientReferenceNumberOverride=${data.clientReferenceNumberOverride}`,
    '{}',
    await authHeader()
  );
};

function* updateOutcomeQuestionaire({ payload }) {
  try {
    const { clientId, servicePlanHeaderId, items } = payload;
    let response = yield call(
      updateOutcomeQuestionaireRequest,
      items,
      clientId,
      servicePlanHeaderId
    );

    yield put(getOutcomeQuestionaireByIdSuccess(response.data));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage
      ? errorMessage
      : errorJSON
      ? JSON.stringify(errorJSON)
      : 'Something was wrong';

    const message = {
      items: payload.items,
      title: 'Outcomes',
      text: err,
    };
    yield put(updateOutcomeQuestionaireError(message));
  }
}

function* syncOutcomeQuestionaire({ payload }) {
  try {
    const { clientId, servicePlanHeaderId, clientReferenceNumberOverride } = payload;
    yield call(syncOutcomeQuestionaireRequest, {
      clientId,
      servicePlanHeaderId,
      clientReferenceNumberOverride,
    });
    let response = yield call(getOutcomeQuestionaireByIdRequest, { clientId, servicePlanHeaderId });
    yield put(getOutcomeQuestionaireByIdSuccess(response.data));

    response = yield call(getClientServicePlanRequest, { clientId, id: servicePlanHeaderId });
    yield put(getClientServicePlanSuccess(response.data));
  } catch (error) {
    const message = {
      title: 'Outcomes',
      text: error.response?.data?.message ?? 'something went wrong.',
    };
    yield put(syncOutcomeQuestionaireError(message));
  }
}

export function* watchGettingClientById() {
  yield takeEvery(OUTCOME_QUESTIONAIRE_GET_BY_ID, getOutcomeQuestionaireById);
}

export function* watchUpdatingOutcomeQuestionaire() {
  yield takeEvery(OUTCOME_QUESTIONAIRE_UPDATE, updateOutcomeQuestionaire);
}

export function* watchSyncingOutcomeQuestionaire() {
  yield takeEvery(OUTCOME_QUESTIONAIRE_SYNC, syncOutcomeQuestionaire);
}

export default function* rootSaga() {
  yield all([
    fork(watchGettingClientById),
    fork(watchUpdatingOutcomeQuestionaire),
    fork(watchSyncingOutcomeQuestionaire),
  ]);
}

export { getLiveQuestionaireTransactions, detectDifferenceOutcomeQuestionnaireRequest };
