// STAKE_HOLDER
export const STAKE_HOLDER_ADD_NEW = 'STAKE_HOLDER_ADD_NEW';
export const STAKE_HOLDER_ADD_NEW_SUCCESS = 'STAKE_HOLDER_ADD_NEW_SUCCESS';
export const STAKE_HOLDER_ADD_NEW_ERROR = 'STAKE_HOLDER_ADD_NEW_ERROR';

export const STAKE_HOLDER_GET_PAGING = 'STAKE_HOLDER_GET_PAGING';
export const STAKE_HOLDER_GET_PAGING_SUCCESS = 'STAKE_HOLDER_GET_PAGING_SUCCESS';
export const STAKE_HOLDER_GET_PAGING_ERROR = 'STAKE_HOLDER_GET_PAGING_ERROR';

export const STAKE_HOLDER_GET_BY_ID = 'STAKE_HOLDER_GET_BY_ID';
export const STAKE_HOLDER_GET_BY_ID_SUCCESS = 'STAKE_HOLDER_GET_BY_ID_SUCCESS';
export const STAKE_HOLDER_GET_BY_ID_ERROR = 'STAKE_HOLDER_GET_BY_ID_ERROR';

export const STAKE_HOLDER_UPDATE = 'STAKE_HOLDER_UPDATE';
export const STAKE_HOLDER_UPDATE_SUCCESS = 'STAKE_HOLDER_UPDATE_SUCCESS';
export const STAKE_HOLDER_UPDATE_ERROR = 'STAKE_HOLDER_UPDATE_ERROR';

export const STAKE_HOLDER_CHANGE_STATUS = 'STAKE_HOLDER_CHANGE_STATUS';
export const STAKE_HOLDER_CHANGE_STATUS_SUCCESS = 'STAKE_HOLDER_CHANGE_STATUS_SUCCESS';
export const STAKE_HOLDER_CHANGE_STATUS_ERROR = 'STAKE_HOLDER_CHANGE_STATUS_ERROR';

export const STAKE_HOLDER_DELETE = 'STAKE_HOLDER_DELETE';
export const STAKE_HOLDER_DELETE_SUCCESS = 'STAKE_HOLDER_DELETE_SUCCESS';
export const STAKE_HOLDER_DELETE_ERROR = 'STAKE_HOLDER_DELETE_ERROR';
