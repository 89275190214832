import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SUB_GOAL_LBS_ADD_NEW, SUB_GOAL_LBS_BY_CLIENT } from './types';
import Config from '../../config';

import {
  addNewSubGoalLBSError,
  getSubGoalLBSByClientSuccess,
  getSubGoalLBSByClientError,
} from './actions';

const getSubGoalLBSListRequest = async query => {
  const url = `${Config.apiServerHost}/api/SubGoalLBS/search`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const addNewSubGoalLBSReqeust = async caseNote => {
  return axios.post(`${Config.apiServerHost}/api/SubGoalLBS`, caseNote, await authHeader());
};

function* getSubGoalLBSByClient({ payload }) {
  try {
    const response = yield call(getSubGoalLBSListRequest, payload);
    const { data } = response;
    if (data) {
      yield put(getSubGoalLBSByClientSuccess(data[0] ?? null)); // get only one element of item
    }
  } catch (error) {
    yield put(getSubGoalLBSByClientError(error.response.statusText));
  }
}

function* addNewSubGoalLBS({ payload }) {
  try {
    let response = yield call(addNewSubGoalLBSReqeust, payload);
    const { clientId, servicePlanHeaderId } = payload;
    const query = {
      clientId,
      servicePlanHeaderId,
    };
    response = yield call(getSubGoalLBSListRequest, query);
    const { data } = response;
    if (data) {
      yield put(getSubGoalLBSByClientSuccess(data[0] ?? null)); // get only one element of item
    }
  } catch (error) {
    yield put(addNewSubGoalLBSError(error.response.statusText));
  }
}

export function* watchSubGoalLBSByClient() {
  yield takeEvery(SUB_GOAL_LBS_BY_CLIENT, getSubGoalLBSByClient);
}

export function* watchAddNewSubGoalLBS() {
  yield takeEvery(SUB_GOAL_LBS_ADD_NEW, addNewSubGoalLBS);
}

export default function* rootSaga() {
  yield all([fork(watchSubGoalLBSByClient), fork(watchAddNewSubGoalLBS)]);
}
