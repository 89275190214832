import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../../helpers/auth-header';
import Config from '../../../config';
import axios from 'axios';

import { ALL_ES_CONTRACT_OUSTANDING_GET_LIST } from '../../actions';

import {
  getAllESContractOustandingListSuccess,
  getAllESContractOustandingListError,
} from './actions';

const getAllESContractOustandingListRequest = async filter => {
  return await axios.get(
    `${Config.apiServerHost}/api/contract/es/all-es-contract-oustanding-report?fromDate=${filter.payload.fromDate}&toDate=${filter.payload.toDate}&fiscalYear=${filter.payload.fiscalYear}`,
    await authHeader()
  );
};

function* getAllESContractOustandingListItems(payload) {
  try {
    const response = yield call(getAllESContractOustandingListRequest, payload);
    yield put(getAllESContractOustandingListSuccess(response.data));
  } catch (error) {
    yield put(getAllESContractOustandingListError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(ALL_ES_CONTRACT_OUSTANDING_GET_LIST, getAllESContractOustandingListItems);
}

export default function* rootSaga() {
  yield all([fork(watchGetList)]);
}
