import {
  SE_CONTRACT_GET_LIST,
  SE_CONTRACT_GET_LIST_SUCCESS,
  SE_CONTRACT_GET_LIST_ERROR,
  SE_CONTRACT_GET_BY_ID,
  SE_CONTRACT_GET_BY_ID_SUCCESS,
  SE_CONTRACT_GET_BY_ID_ERROR,
  SE_CONTRACT_ADD_ITEM,
  SE_CONTRACT_ADD_ITEM_SUCCESS,
  SE_CONTRACT_ADD_ITEM_ERROR,
  SE_CONTRACT_EDIT_ITEM,
  SE_CONTRACT_EDIT_ITEM_SUCCESS,
  SE_CONTRACT_EDIT_ITEM_ERROR,
  SE_CONTRACT_DELETE_ITEM,
  SE_CONTRACT_DELETE_ITEM_SUCCESS,
  SE_CONTRACT_DELETE_ITEM_ERROR,
  SE_CONTRACT_REPORT_GET_LIST,
  SE_CONTRACT_REPORT_GET_LIST_SUCCESS,
  SE_CONTRACT_REPORT_GET_LIST_ERROR,
  SE_CONTRACT_REPORT_GET_LIST_WITH_FILTER,
  SE_CONTRACT_REPORT_GET_LIST_WITH_ORDER,
  SE_CONTRACT_REPORT_GET_LIST_SEARCH,
} from '../actions';

export const getSEContractList = filter => ({
  type: SE_CONTRACT_GET_LIST,
  payload: filter,
});

export const getSEContractListSuccess = items => ({
  type: SE_CONTRACT_GET_LIST_SUCCESS,
  payload: items,
});

export const getSEContractListError = error => ({
  type: SE_CONTRACT_GET_LIST_ERROR,
  payload: error,
});

export const getSEContractById = id => ({
  type: SE_CONTRACT_GET_BY_ID,
  payload: id,
});

export const getSEContractByIdSuccess = item => ({
  type: SE_CONTRACT_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getSEContractByIdError = error => ({
  type: SE_CONTRACT_GET_BY_ID_ERROR,
  payload: error,
});

export const addSEContractItem = (item, history) => ({
  type: SE_CONTRACT_ADD_ITEM,
  payload: { item, history },
});

export const addSEContractItemSuccess = items => ({
  type: SE_CONTRACT_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addSEContractItemError = error => ({
  type: SE_CONTRACT_ADD_ITEM_ERROR,
  payload: error,
});

export const editSEContractItem = (item, history) => ({
  type: SE_CONTRACT_EDIT_ITEM,
  payload: { item, history },
});

export const editSEContractItemSuccess = items => ({
  type: SE_CONTRACT_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editSEContractItemError = error => ({
  type: SE_CONTRACT_EDIT_ITEM_ERROR,
  payload: error,
});

export const deleteSEContractItem = item => ({
  type: SE_CONTRACT_DELETE_ITEM,
  payload: item,
});

export const deleteSEContractItemSuccess = items => ({
  type: SE_CONTRACT_DELETE_ITEM_SUCCESS,
  payload: items,
});

export const deleteSEContractItemError = error => ({
  type: SE_CONTRACT_DELETE_ITEM_ERROR,
  payload: error,
});

export const getSEContractReportList = filter => ({
  type: SE_CONTRACT_REPORT_GET_LIST,
  payload: filter,
});

export const getSEContractReportListSuccess = items => ({
  type: SE_CONTRACT_REPORT_GET_LIST_SUCCESS,
  payload: items,
});

export const getSEContractReportListError = error => ({
  type: SE_CONTRACT_REPORT_GET_LIST_ERROR,
  payload: error,
});

export const getSEContractReportListWithFilter = (column, value) => ({
  type: SE_CONTRACT_REPORT_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getSEContractReportListWithOrder = column => ({
  type: SE_CONTRACT_REPORT_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getSEContractReportListSearch = keyword => ({
  type: SE_CONTRACT_REPORT_GET_LIST_SEARCH,
  payload: keyword,
});
