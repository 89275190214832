import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildUrl } from '../../helpers/Utils';
import {
  YJC_CHEQUE_DELETE,
  YJC_CHEQUE_EDIT,
  YJC_CHEQUE_GET_BY_ID,
  YJC_CHEQUE_ADD_NEW,
  YJC_CHEQUE_QUERY,
} from '../actions';
import Config from '../../config';

import {
  editYJCChequeSuccess,
  editYJCChequeError,
  addNewYJCChequeSuccess,
  addNewYJCChequeError,
  deleteYJCChequeSuccess,
  deleteYJCChequeError,
  getYJCChequeByIdSuccess,
  getYJCChequeQuerySuccess,
  getYJCChequeQueryError,
} from './actions';

const getYJCChequeQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/YJCCheque/search`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const addNewYJCChequeReqeust = async caseNote => {
  return axios.post(`${Config.apiServerHost}/api/YJCCheque`, caseNote, await authHeader());
};

const editYJCChequeRequest = async caseNote => {
  return axios.put(`${Config.apiServerHost}/api/YJCCheque`, caseNote, await authHeader());
};

const deleteYJCChequeRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/YJCCheque/${id}`, await authHeader());
};

const getYJCChequeByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/YJCCheque/${id}`, await authHeader());
};

function* getYJCChequeQuery({ payload }) {
  try {
    const response = yield call(getYJCChequeQueryRequest, payload);
    yield put(getYJCChequeQuerySuccess(response.data));
  } catch (error) {
    yield put(getYJCChequeQueryError(error.response.statusText));
  }
}

function* addNewYJCCheque({ payload }) {
  try {
    let response = yield call(addNewYJCChequeReqeust, payload);
    response = yield call(getYJCChequeByIdRequest, response.data);
    yield put(addNewYJCChequeSuccess(response.data));
  } catch (error) {
    yield put(addNewYJCChequeError(error.response.statusText));
  }
}

function* editYJCCheque({ payload }) {
  try {
    let response = yield call(editYJCChequeRequest, payload);
    response = yield call(getYJCChequeByIdRequest, payload.id);
    yield put(editYJCChequeSuccess(response.data));
  } catch (error) {
    yield put(editYJCChequeError(error.response.statusText));
  }
}

function* getYJCChequeById({ payload }) {
  try {
    const response = yield call(getYJCChequeByIdRequest, payload);
    yield put(getYJCChequeByIdSuccess(response.data));
  } catch (error) {
    yield put(editYJCChequeError(error.response.statusText));
  }
}

function* deleteYJCCheque({ payload }) {
  try {
    const response = yield call(deleteYJCChequeRequest, payload);
    yield put(deleteYJCChequeSuccess(response.data));
  } catch (error) {
    yield put(deleteYJCChequeError(error.response.statusText));
  }
}

export function* watchYJCChequeQuery() {
  yield takeEvery(YJC_CHEQUE_QUERY, getYJCChequeQuery);
}

export function* watchAddNewYJCCheque() {
  yield takeEvery(YJC_CHEQUE_ADD_NEW, addNewYJCCheque);
}

export function* watchYJCChequeById() {
  yield takeEvery(YJC_CHEQUE_GET_BY_ID, getYJCChequeById);
}

export function* watchEditYJCCheque() {
  yield takeEvery(YJC_CHEQUE_EDIT, editYJCCheque);
}

export function* watchDeleteYJCCheque() {
  yield takeEvery(YJC_CHEQUE_DELETE, deleteYJCCheque);
}

export default function* rootSaga() {
  yield all([
    fork(watchYJCChequeQuery),
    fork(watchAddNewYJCCheque),
    fork(watchYJCChequeById),
    fork(watchEditYJCCheque),
    fork(watchDeleteYJCCheque),
  ]);
}
