import {
  ES_CONTRACT_GET_LIST,
  ES_CONTRACT_GET_LIST_SUCCESS,
  ES_CONTRACT_GET_LIST_ERROR,
  ES_CONTRACT_GET_BY_ID,
  ES_CONTRACT_GET_BY_ID_SUCCESS,
  ES_CONTRACT_GET_BY_ID_ERROR,
  ES_CONTRACT_ADD_ITEM,
  ES_CONTRACT_ADD_ITEM_SUCCESS,
  ES_CONTRACT_ADD_ITEM_ERROR,
  ES_CONTRACT_EDIT_ITEM,
  ES_CONTRACT_EDIT_ITEM_SUCCESS,
  ES_CONTRACT_EDIT_ITEM_ERROR,
  ES_CONTRACT_DELETE_ITEM,
  ES_CONTRACT_DELETE_ITEM_SUCCESS,
  ES_CONTRACT_DELETE_ITEM_ERROR,
} from '../actions';

// ESContract

export const getESContractList = filter => ({
  type: ES_CONTRACT_GET_LIST,
  payload: filter,
});

export const getESContractListSuccess = items => ({
  type: ES_CONTRACT_GET_LIST_SUCCESS,
  payload: items,
});

export const getESContractListError = error => ({
  type: ES_CONTRACT_GET_LIST_ERROR,
  payload: error,
});

export const getESContractById = id => ({
  type: ES_CONTRACT_GET_BY_ID,
  payload: id,
});

export const getESContractByIdSuccess = item => ({
  type: ES_CONTRACT_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getESContractByIdError = error => ({
  type: ES_CONTRACT_GET_BY_ID_ERROR,
  payload: error,
});

export const addESContractItem = (item, history) => ({
  type: ES_CONTRACT_ADD_ITEM,
  payload: { item, history },
});

export const addESContractItemSuccess = items => ({
  type: ES_CONTRACT_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addESContractItemError = error => ({
  type: ES_CONTRACT_ADD_ITEM_ERROR,
  payload: error,
});

export const editESContractItem = (item, history) => ({
  type: ES_CONTRACT_EDIT_ITEM,
  payload: { item, history },
});

export const editESContractItemSuccess = items => ({
  type: ES_CONTRACT_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editESContractItemError = error => ({
  type: ES_CONTRACT_EDIT_ITEM_ERROR,
  payload: error,
});

export const deleteESContractItem = item => ({
  type: ES_CONTRACT_DELETE_ITEM,
  payload: item,
});

export const deleteESContractItemSuccess = items => ({
  type: ES_CONTRACT_DELETE_ITEM_SUCCESS,
  payload: items,
});

export const deleteESContractItemError = error => ({
  type: ES_CONTRACT_DELETE_ITEM_ERROR,
  payload: error,
});
