// OUTCOME_CHECKPOINT

export const OUTCOME_CHECKPOINT_GET_BY_ID = 'OUTCOME_CHECKPOINT_GET_BY_ID';
export const OUTCOME_CHECKPOINT_GET_BY_ID_SUCCESS = 'OUTCOME_CHECKPOINT_GET_BY_ID_SUCCESS';
export const OUTCOME_CHECKPOINT_GET_BY_ID_ERROR = 'OUTCOME_CHECKPOINT_GET_BY_ID_ERROR';

export const OUTCOME_CHECKPOINT_GET_BY_QUERY = 'OUTCOME_CHECKPOINT_GET_BY_QUERY';
export const OUTCOME_CHECKPOINT_GET_BY_QUERY_SUCCESS = 'OUTCOME_CHECKPOINT_GET_BY_QUERY_SUCCESS';
export const OUTCOME_CHECKPOINT_GET_BY_QUERY_ERROR = 'OUTCOME_CHECKPOINT_GET_BY_QUERY_ERROR';

export const GET_REFRESH_OUTCOME_CHECKPOINT_GET_BY_QUERY =
  'GET_REFRESH_OUTCOME_CHECKPOINT_GET_BY_QUERY';
export const GET_REFRESH_OUTCOME_CHECKPOINT_GET_BY_QUERY_SUCCESS =
  'GET_REFRESH_OUTCOME_CHECKPOINT_GET_BY_QUERY_SUCCESS';

export const OUTCOME_CHECKPOINT_DETECT_DIFFERENCE = 'OUTCOME_CHECKPOINT_DETECT_DIFFERENCE';
export const OUTCOME_CHECKPOINT_DETECT_DIFFERENCE_SUCCESS =
  'OUTCOME_CHECKPOINT_DETECT_DIFFERENCE_SUCCESS';

export const OUTCOME_CHECKPOINT_UPDATE = 'OUTCOME_CHECKPOINT_UPDATE';
export const OUTCOME_CHECKPOINT_UPDATE_SUCCESS = 'OUTCOME_CHECKPOINT_UPDATE_SUCCESS';
export const OUTCOME_CHECKPOINT_UPDATE_ERROR = 'OUTCOME_CHECKPOINT_UPDATE_ERROR';
export const OUTCOME_CHECKPOINT_CLEAR_NOTIFIED = 'OUTCOME_CHECKPOINT_CLEAR_NOTIFIED';

export const OUTCOME_CHECKPOINT_SINGLE_UPDATE = 'OUTCOME_CHECKPOINT_SINGLE_UPDATE';
export const OUTCOME_CHECKPOINT_SINGLE_UPDATE_SUCCESS = 'OUTCOME_CHECKPOINT_SINGLE_UPDATE_SUCCESS';
export const OUTCOME_CHECKPOINT_SINGLE_UPDATE_ERROR = 'OUTCOME_CHECKPOINT_SINGLE_UPDATE_ERROR';

export const OUTCOME_CHECKPOINT_SYNC = 'OUTCOME_CHECKPOINT_SYNC';
export const OUTCOME_CHECKPOINT_SYNC_SUCCESS = 'OUTCOME_CHECKPOINT_SYNC_SUCCESS';
export const OUTCOME_CHECKPOINT_SYNC_ERROR = 'OUTCOME_CHECKPOINT_SYNC_ERROR';

export const GET_REFRESH_OUTCOME_CHECKPOINT_ATTACHMENT_GET_BY_QUERY =
  'GET_REFRESH_OUTCOME_CHECKPOINT_ATTACHMENT_GET_BY_QUERY';
export const GET_REFRESH_OUTCOME_CHECKPOINT_ATTACHMENT_GET_BY_QUERY_SUCCESS =
  'GET_REFRESH_OUTCOME_CHECKPOINT_ATTACHMENT_GET_BY_QUERY_SUCCESS';
