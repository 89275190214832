import {
  USER_GET_LIST,
  USER_GET_LIST_SUCCESS,
  USER_GET_LIST_ERROR,
  ALL_USER_GET_LIST,
  ALL_USER_GET_LIST_SUCCESS,
  ALL_USER_GET_LIST_ERROR,
  USER_SELECTED_ITEMS_CHANGE,
  USER_ADD_OR_UPDATE_ITEM,
  USER_ADD_OR_UPDATE_ITEM_SUCCESS,
  USER_ADD_OR_UPDATE_ITEM_ERROR,
  USER_DELETE_ITEM,
  USER_DELETE_ITEM_SUCCESS,
  USER_DELETE_ITEM_ERROR,
  USER_ACTIVATE,
  USER_RESET_PASSWORD,
  USER_RESET_PASSWORD_ERROR,
  USER_RESET_PASSWORD_SUCCESS,
  USER_ROLE_GET_BY_ID,
  USER_ROLE_GET_BY_ID_SUCCESS,
  USER_ROLE_GET_BY_ID_ERROR,
  USER_ROLE_UPDATE,
  USER_ROLE_UPDATE_ERROR,
  USER_ROLE_UPDATE_SUCCESS,
  USER_ROLE_DELETE_LIST,
  USER_ROLE_DELETE_LIST_ERROR,
  USER_ROLE_DELETE_LIST_SUCCESS,
  USER_PROFILE_GET_BY_ID,
  USER_PROFILE_GET_BY_ID_SUCCESS,
  USER_PROFILE_GET_BY_ID_ERROR,
  USER_PROFILE_PICTURE_UPDATE,
  USER_PROFILE_PICTURE_UPDATE_ERROR,
  USER_PROFILE_PICTURE_UPDATE_SUCCESS,
  USER_PROFILE_CLOSE_ERROR_MESSAGE,
  USER_DISABLED_OR_ENABLED,
  USER_DISABLED_OR_ENABLED_ERROR,
  USER_DISABLED_OR_ENABLED_SUCCESS,
  FORCE_GLOBAL_SIGNOUT,
  FORCE_GLOBAL_SIGNOUT_ERROR,
  FORCE_GLOBAL_SIGNOUT_SUCCESS,
  UPDATE_USER_IS_ADMIN,
  USER_PROFILE_UPDATE,
} from '../actions';

export const updateUserProfile = data => ({
  type: USER_PROFILE_UPDATE,
  payload: data,
});

export const getUserList = filter => ({
  type: USER_GET_LIST,
  payload: filter,
});

export const getUserListSuccess = items => ({
  type: USER_GET_LIST_SUCCESS,
  payload: items,
});

export const getUserListError = error => ({
  type: USER_GET_LIST_ERROR,
  payload: error,
});

export const getAllUserList = filter => ({
  type: ALL_USER_GET_LIST,
  payload: filter,
});

export const getAllUserListSuccess = items => ({
  type: ALL_USER_GET_LIST_SUCCESS,
  payload: items,
});

export const getAllUserListError = error => ({
  type: ALL_USER_GET_LIST_ERROR,
  payload: error,
});

export const getUserRoleById = id => ({
  type: USER_ROLE_GET_BY_ID,
  payload: id,
});

export const getUserRoleByIdSuccess = client => ({
  type: USER_ROLE_GET_BY_ID_SUCCESS,
  payload: client,
});

export const getUserRoleByIdError = error => ({
  type: USER_ROLE_GET_BY_ID_ERROR,
  payload: error,
});

export const AddorUpdateUserItem = payload => ({
  type: USER_ADD_OR_UPDATE_ITEM,
  payload,
});

export const AddorUpdateUserItemSuccess = items => ({
  type: USER_ADD_OR_UPDATE_ITEM_SUCCESS,
  payload: items,
});

export const AddorUpdateUserItemError = error => ({
  type: USER_ADD_OR_UPDATE_ITEM_ERROR,
  payload: error,
});

export const UpdateUserPwdRequest = item => ({
  type: USER_RESET_PASSWORD,
  payload: item,
});

export const UpdateUserPwdRequestSuccess = items => ({
  type: USER_RESET_PASSWORD_SUCCESS,
  payload: items,
});

export const UpdateUserPwdRequestError = error => ({
  type: USER_RESET_PASSWORD_ERROR,
  payload: error,
});

export const selectedUserItemsChange = selectedItems => ({
  type: USER_SELECTED_ITEMS_CHANGE,
  payload: selectedItems,
});

export const deleteUserItem = item => ({
  type: USER_DELETE_ITEM,
  payload: item,
});

export const deleteUserItemSuccess = items => ({
  type: USER_DELETE_ITEM_SUCCESS,
  payload: items,
});

export const deleteUserItemError = error => ({
  type: USER_DELETE_ITEM_ERROR,
  payload: error,
});

export const activateUserAction = item => ({
  type: USER_ACTIVATE,
  payload: item,
});

export const editUserRole = item => ({
  type: USER_ROLE_UPDATE,
  payload: item,
});

export const editUserRoleSuccess = items => ({
  type: USER_ROLE_UPDATE_SUCCESS,
  payload: items,
});

export const editUserRoleError = error => ({
  type: USER_ROLE_UPDATE_ERROR,
  payload: error,
});

export const deleteUserRoleItem = item => ({
  type: USER_ROLE_DELETE_LIST,
  payload: item,
});

export const deleteUserRoleItemSuccess = items => ({
  type: USER_ROLE_DELETE_LIST_SUCCESS,
  payload: items,
});

export const deleteUserRoleItemError = error => ({
  type: USER_ROLE_DELETE_LIST_ERROR,
  payload: error,
});

export const getUserProfileById = id => ({
  type: USER_PROFILE_GET_BY_ID,
  payload: id,
});

export const getUserProfileByIdSuccess = client => ({
  type: USER_PROFILE_GET_BY_ID_SUCCESS,
  payload: client,
});

export const getUserProfileByIdError = error => ({
  type: USER_PROFILE_GET_BY_ID_ERROR,
  payload: error,
});

export const handleUpdateUserPicture = item => ({
  type: USER_PROFILE_PICTURE_UPDATE,
  payload: item,
});

export const handleUpdateUserPictureSuccess = items => ({
  type: USER_PROFILE_PICTURE_UPDATE_SUCCESS,
  payload: items,
});

export const handleUpdateUserPictureError = error => ({
  type: USER_PROFILE_PICTURE_UPDATE_ERROR,
  payload: error,
});

export const closeUserProfileErrorMessage = () => ({
  type: USER_PROFILE_CLOSE_ERROR_MESSAGE,
});

export const disableOrEnableUserById = id => ({
  type: USER_DISABLED_OR_ENABLED,
  payload: id,
});

export const updateUserIsAdmin = item => ({
  type: UPDATE_USER_IS_ADMIN,
  payload: item,
});

export const disableOrEnableUserByIdSuccess = item => ({
  type: USER_DISABLED_OR_ENABLED_SUCCESS,
  payload: item,
});

export const disableOrEnableUserByIdError = error => ({
  type: USER_DISABLED_OR_ENABLED_ERROR,
  payload: error,
});

export const forceGlobalSignout = id => ({
  type: FORCE_GLOBAL_SIGNOUT,
  payload: id,
});

export const forceGlobalSignoutSuccess = item => ({
  type: FORCE_GLOBAL_SIGNOUT_SUCCESS,
  payload: item,
});

export const forceGlobalSignoutError = error => ({
  type: FORCE_GLOBAL_SIGNOUT_ERROR,
  payload: error,
});
