import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  MIlEAGE_SUPERVISOR_GET_ALL,
  MIlEAGE_SUPERVISOR_GET_BY_ID,
  MIlEAGE_SUPERVISOR_ADD_NEW,
  MIlEAGE_SUPERVISOR_EDIT,
  MIlEAGE_SUPERVISOR_DELETE,
} from '../actions';

import {
  getMileageSupervisorsSuccess,
  getMileageSupervisorsError,
  getMileageSupervisorByIdSuccess,
  getMileageSupervisorByIdError,
  addNewMileageSupervisorSuccess,
  addNewMileageSupervisorError,
  editMileageSupervisorSuccess,
  editMileageSupervisorError,
  deleteMileageSupervisorSuccess,
  deleteMileageSupervisorError,
} from './actions';

const getMileageSupervisorsRequest = async () => {
  const url = `${Config.apiServerHost}/api/MileageSupervisor`;
  return axios.get(url, await authHeader());
};

const getMileageSupervisorByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/MileageSupervisor/${id}`, await authHeader());
};

const addNewMileageSupervisorReqeust = async item => {
  return axios.post(`${Config.apiServerHost}/api/MileageSupervisor`, item, await authHeader());
};

const editMileageSupervisorRequest = async item => {
  return axios.put(`${Config.apiServerHost}/api/MileageSupervisor`, item, await authHeader());
};

const deleteMileageSupervisorRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/MileageSupervisor/${id}`, await authHeader());
};

function* getMileageSupervisors() {
  try {
    const response = yield call(getMileageSupervisorsRequest);
    yield put(getMileageSupervisorsSuccess(response.data));
  } catch (error) {
    yield put(getMileageSupervisorsError(error.response.statusText));
  }
}

function* getMileageSupervisorById({ payload }) {
  try {
    const response = yield call(getMileageSupervisorByIdRequest, payload);
    yield put(getMileageSupervisorByIdSuccess(response.data));
  } catch (error) {
    yield put(getMileageSupervisorByIdError(error.response.statusText));
  }
}

function* addNewMileageSupervisor({ payload }) {
  try {
    let response = yield call(addNewMileageSupervisorReqeust, payload);
    response = yield call(getMileageSupervisorByIdRequest, response.data);
    yield put(addNewMileageSupervisorSuccess(response.data));
  } catch (error) {
    yield put(addNewMileageSupervisorError(error.response.statusText));
  }
}

function* editMileageSupervisor({ payload }) {
  try {
    let response = yield call(editMileageSupervisorRequest, payload);
    response = yield call(getMileageSupervisorByIdRequest, response.data);
    yield put(editMileageSupervisorSuccess(response.data));
  } catch (error) {
    yield put(editMileageSupervisorError(error.response.statusText));
  }
}

function* deleteMileageSupervisor({ payload }) {
  try {
    const response = yield call(deleteMileageSupervisorRequest, payload);
    yield put(deleteMileageSupervisorSuccess(response.data));
  } catch (error) {
    yield put(deleteMileageSupervisorError(error.response.statusText));
  }
}

export function* watchGetMileageSupervisors() {
  yield takeEvery(MIlEAGE_SUPERVISOR_GET_ALL, getMileageSupervisors);
}

export function* watchGetMileageSupervisorById() {
  yield takeEvery(MIlEAGE_SUPERVISOR_GET_BY_ID, getMileageSupervisorById);
}

export function* watchAddNewMileageSupervisor() {
  yield takeEvery(MIlEAGE_SUPERVISOR_ADD_NEW, addNewMileageSupervisor);
}

export function* watchEditMileageSupervisor() {
  yield takeEvery(MIlEAGE_SUPERVISOR_EDIT, editMileageSupervisor);
}

export function* watchDeleteMileageSupervisor() {
  yield takeEvery(MIlEAGE_SUPERVISOR_DELETE, deleteMileageSupervisor);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetMileageSupervisors),
    fork(watchGetMileageSupervisorById),
    fork(watchAddNewMileageSupervisor),
    fork(watchEditMileageSupervisor),
    fork(watchDeleteMileageSupervisor),
  ]);
}
