import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { TODO_GET_LIST, TODO_ADD_ITEM, TODO_UPDATE_STATUS_ITEM } from '../actions';

import { getTodoListSuccess, getTodoListError, addTodoItemError } from './actions';

const getTodoListRequest = async payload => {
  return axios.get(
    `${Config.apiServerHost}/api/ToDo/getToDoList/${payload.payload}`,
    await authHeader()
  );
};

function* getTodoListItems(payload) {
  try {
    const response = yield call(getTodoListRequest, payload);
    yield put(getTodoListSuccess(response.data));
  } catch (error) {
    yield put(getTodoListError(error));
  }
}

const addTodoItemRequest = async payload => {
  return axios.post(`${Config.apiServerHost}/api/ToDo`, payload, await authHeader());
};
const editTodoItemRequest = async payload => {
  return axios.put(`${Config.apiServerHost}/api/ToDo`, payload, await authHeader());
};

const getTodoListRequestSuccess = async payload => {
  return axios.get(
    `${Config.apiServerHost}/api/ToDo/getToDoList/${payload.reporter}`,
    await authHeader()
  );
};
function* addTodoItem({ payload }) {
  try {
    if (payload.id > 0) {
      yield call(editTodoItemRequest, payload);
    } else {
      yield call(addTodoItemRequest, payload);
    }
    const res = yield call(getTodoListRequestSuccess, payload);
    yield put(getTodoListSuccess(res.data));
  } catch (error) {
    yield put(addTodoItemError(error));
  }
}

const tdoUpdateStatusItemRequest = async payload => {
  return await axios.get(
    `${Config.apiServerHost}/api/ToDo/update-status?id=${payload.id}&status=${payload.status}`,
    await authHeader()
  );
};

function* todoUpdateStatus({ payload }) {
  try {
    yield call(tdoUpdateStatusItemRequest, payload);
    const res = yield call(getTodoListRequest);
    yield put(getTodoListSuccess(res.data));
  } catch (error) {
    yield put(addTodoItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(TODO_GET_LIST, getTodoListItems);
}

export function* wathcAddItem() {
  yield takeEvery(TODO_ADD_ITEM, addTodoItem);
}

export function* wathcUpdateStatusItem() {
  yield takeEvery(TODO_UPDATE_STATUS_ITEM, todoUpdateStatus);
}

export default function* rootSaga() {
  yield all([fork(watchGetList), fork(wathcAddItem), fork(wathcUpdateStatusItem)]);
}
