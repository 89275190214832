// LOCATION
export const LOCATION_ADD_NEW = 'LOCATION_ADD_NEW';
export const LOCATION_ADD_NEW_SUCCESS = 'LOCATION_ADD_NEW_SUCCESS';
export const LOCATION_ADD_NEW_ERROR = 'LOCATION_ADD_NEW_ERROR';

export const LOCATION_GET_PAGING = 'LOCATION_GET_PAGING';
export const LOCATION_GET_PAGING_SUCCESS = 'LOCATION_GET_PAGING_SUCCESS';
export const LOCATION_GET_PAGING_ERROR = 'LOCATION_GET_PAGING_ERROR';

export const LOCATION_GET_BY_ID = 'LOCATION_GET_BY_ID';
export const LOCATION_GET_BY_ID_SUCCESS = 'LOCATION_GET_BY_ID_SUCCESS';
export const LOCATION_GET_BY_ID_ERROR = 'LOCATION_GET_BY_ID_ERROR';

export const LOCATION_UPDATE = 'LOCATION_UPDATE';
export const LOCATION_UPDATE_SUCCESS = 'LOCATION_UPDATE_SUCCESS';
export const LOCATION_UPDATE_ERROR = 'LOCATION_UPDATE_ERROR';

export const LOCATION_DELETE = 'LOCATION_DELETE';
export const LOCATION_DELETE_SUCCESS = 'LOCATION_DELETE_SUCCESS';
export const LOCATION_DELETE_ERROR = 'LOCATION_DELETE_ERROR';
