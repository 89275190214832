import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../helpers/auth-header';
import axios from 'axios';
import Config from '../../config';

import {
  GEOGRAPHY_GET_LIST,
  GEOGRAPHY_ADD_OR_UPDATE_ITEM,
  GEOGRAPHY_DELETE_ITEM,
} from '../actions';

import {
  getGeographyListSuccess,
  getGeographyListError,
  AddorUpdateGeographyItemError,
  deleteGeographyItemError,
} from './actions';

const getGeographyListRequest = async () => {
  return await axios.get(`${Config.apiServerHost}/api/common/geography`, await authHeader());
};

function* getGeographyListItems() {
  try {
    const response = yield call(getGeographyListRequest);
    yield put(getGeographyListSuccess(response.data));
  } catch (error) {
    yield put(getGeographyListError(error));
  }
}

const poshGeographyListRequest = async geography => {
  return await axios.post(
    `${Config.apiServerHost}/api/common/geography`,
    geography,
    await authHeader()
  );
};

function* AddorUpdateGeographyItem({ payload }) {
  try {
    yield call(poshGeographyListRequest, payload);
    const res = yield call(getGeographyListRequest);
    yield put(getGeographyListSuccess(res.data));
  } catch (error) {
    yield put(AddorUpdateGeographyItemError(error));
  }
}

const deleteGeographyListRequest = async id => {
  return await axios.delete(
    `${Config.apiServerHost}/api/common/geography/${id}`,
    await authHeader()
  );
};
function* deleteGeographyItem({ payload }) {
  try {
    yield call(deleteGeographyListRequest, payload);
    const res = yield call(getGeographyListRequest);
    yield put(getGeographyListSuccess(res.data));
  } catch (error) {
    yield put(deleteGeographyItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(GEOGRAPHY_GET_LIST, getGeographyListItems);
}

export function* wathcADD_OR_UPDATEItem() {
  yield takeEvery(GEOGRAPHY_ADD_OR_UPDATE_ITEM, AddorUpdateGeographyItem);
}
export function* wathcDeleteItem() {
  yield takeEvery(GEOGRAPHY_DELETE_ITEM, deleteGeographyItem);
}

export default function* rootSaga() {
  yield all([fork(watchGetList), fork(wathcADD_OR_UPDATEItem), fork(wathcDeleteItem)]);
}
