export const ACTION_PLAN_FOR_STAFF_ADD_NEW = 'ACTION_PLAN_FOR_STAFF_ADD_NEW';
export const ACTION_PLAN_FOR_STAFF_ADD_NEW_SUCCESS = 'ACTION_PLAN_FOR_STAFF_ADD_NEW_SUCCESS';
export const ACTION_PLAN_FOR_STAFF_ADD_NEW_ERROR = 'ACTION_PLAN_FOR_STAFF_ADD_NEW_ERROR';

export const ACTION_PLAN_FOR_STAFF_GET_PAGING = 'ACTION_PLAN_FOR_STAFF_GET_PAGING';
export const ACTION_PLAN_FOR_STAFF_GET_PAGING_SUCCESS = 'ACTION_PLAN_FOR_STAFF_GET_PAGING_SUCCESS';
export const ACTION_PLAN_FOR_STAFF_GET_PAGING_ERROR = 'ACTION_PLAN_FOR_STAFF_GET_PAGING_ERROR';

export const ACTION_PLAN_FOR_STAFF_GET_BY_ID = 'ACTION_PLAN_FOR_STAFF_GET_BY_ID';
export const ACTION_PLAN_FOR_STAFF_GET_BY_ID_SUCCESS = 'ACTION_PLAN_FOR_STAFF_GET_BY_ID_SUCCESS';
export const ACTION_PLAN_FOR_STAFF_GET_BY_ID_ERROR = 'ACTION_PLAN_FOR_STAFF_GET_BY_ID_ERROR';

export const ACTION_PLAN_FOR_STAFF_UPDATE = 'ACTION_PLAN_FOR_STAFF_UPDATE';
export const ACTION_PLAN_FOR_STAFF_UPDATE_SUCCESS = 'ACTION_PLAN_FOR_STAFF_UPDATE_SUCCESS';
export const ACTION_PLAN_FOR_STAFF_UPDATE_ERROR = 'ACTION_PLAN_FOR_STAFF_UPDATE_ERROR';

export const ACTION_PLAN_FOR_STAFF_CHANGE_STATUS = 'ACTION_PLAN_FOR_STAFF_CHANGE_STATUS';
export const ACTION_PLAN_FOR_STAFF_CHANGE_STATUS_SUCCESS =
  'ACTION_PLAN_FOR_STAFF_CHANGE_STATUS_SUCCESS';
export const ACTION_PLAN_FOR_STAFF_CHANGE_STATUS_ERROR =
  'ACTION_PLAN_FOR_STAFF_CHANGE_STATUS_ERROR';

export const ACTION_PLAN_FOR_STAFF_DELETE = 'ACTION_PLAN_FOR_STAFF_DELETE';
export const ACTION_PLAN_FOR_STAFF_DELETE_SUCCESS = 'ACTION_PLAN_FOR_STAFF_DELETE_SUCCESS';
export const ACTION_PLAN_FOR_STAFF_DELETE_ERROR = 'ACTION_PLAN_FOR_STAFF_DELETE_ERROR';
