import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  JOB_SEEKER_DELETE,
  JOB_SEEKER_EDIT,
  JOB_SEEKER_GET_BY_ID,
  JOB_SEEKER_ADD_NEW,
  JOB_SEEKER_GET_LIST,
} from '../actions';

import {
  getJobSeekerListSuccess,
  getJobSeekerListError,
  editJobSeekerSuccess,
  editJobSeekerError,
  addNewJobSeekerSuccess,
  addNewJobSeekerError,
  deleteJobSeekerSuccess,
  deleteJobSeekerError,
  getJobSeekerByIdSuccess,
} from './actions';

const getJobSeekerListRequest = async query => {
  let url = `${Config.apiServerHost}/api/client/searchJobSeeker`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const addNewJobSeekerReqeust = async jobSeeker => {
  return axios.post(`${Config.apiServerHost}/api/client/JobSeeker`, jobSeeker, await authHeader());
};

const editJobSeekerRequest = async jobSeeker => {
  return axios.put(`${Config.apiServerHost}/api/client/JobSeeker`, jobSeeker, await authHeader());
};

const deleteJobSeekerRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/client/JobSeeker/${id}`, await authHeader());
};

const getJobSeekerByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/client/JobSeeker/${id}`, await authHeader());
};

function* getJobSeekerList({ payload }) {
  try {
    const response = yield call(getJobSeekerListRequest, payload);
    yield put(getJobSeekerListSuccess(response.data));
  } catch (error) {
    yield put(getJobSeekerListError(error.response.statusText));
  }
}

function* addNewJobSeeker({ payload }) {
  try {
    let response = yield call(addNewJobSeekerReqeust, payload);
    response = yield call(getJobSeekerByIdRequest, response.data);
    yield put(addNewJobSeekerSuccess(response.data));
  } catch (error) {
    yield put(addNewJobSeekerError(error.response.statusText));
  }
}

function* editJobSeeker({ payload }) {
  try {
    let response = yield call(editJobSeekerRequest, payload);
    response = yield call(getJobSeekerByIdRequest, payload.id);
    yield put(editJobSeekerSuccess(response.data));
  } catch (error) {
    yield put(editJobSeekerError(error.response.statusText));
  }
}

function* getJobSeekerById({ payload }) {
  try {
    const response = yield call(getJobSeekerByIdRequest, payload);
    yield put(getJobSeekerByIdSuccess(response.data));
  } catch (error) {
    yield put(editJobSeekerError(error.response.statusText));
  }
}

function* deleteJobSeeker({ payload }) {
  try {
    const response = yield call(deleteJobSeekerRequest, payload);
    yield put(deleteJobSeekerSuccess(response.data));
  } catch (error) {
    yield put(deleteJobSeekerError(error.response.statusText));
  }
}

export function* watchJobSeekerList() {
  yield takeEvery(JOB_SEEKER_GET_LIST, getJobSeekerList);
}

export function* watchAddNewJobSeeker() {
  yield takeEvery(JOB_SEEKER_ADD_NEW, addNewJobSeeker);
}

export function* watchJobSeekerById() {
  yield takeEvery(JOB_SEEKER_GET_BY_ID, getJobSeekerById);
}

export function* watchEditJobSeeker() {
  yield takeEvery(JOB_SEEKER_EDIT, editJobSeeker);
}

export function* watchDeleteJobSeeker() {
  yield takeEvery(JOB_SEEKER_DELETE, deleteJobSeeker);
}

export default function* rootSaga() {
  yield all([
    fork(watchJobSeekerList),
    fork(watchAddNewJobSeeker),
    fork(watchJobSeekerById),
    fork(watchEditJobSeeker),
    fork(watchDeleteJobSeeker),
  ]);
}
