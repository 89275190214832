// APPLICANT
export const APPLICANT_GET_BY_ID = 'APPLICANT_GET_BY_ID';
export const APPLICANT_GET_BY_ID_SUCCESS = 'APPLICANT_GET_BY_ID_SUCCESS';
export const APPLICANT_GET_BY_ID_ERROR = 'APPLICANT_GET_BY_ID_ERROR';

export const APPLICANT_GET_LIST = 'APPLICANT_GET_LIST';
export const APPLICANT_GET_LIST_SUCCESS = 'APPLICANT_GET_LIST_SUCCESS';
export const APPLICANT_GET_LIST_ERROR = 'APPLICANT_GET_LIST_ERROR';

export const APPLICANT_EDIT = 'APPLICANT_EDIT';
export const APPLICANT_EDIT_SUCCESS = 'APPLICANT_EDIT_SUCCESS';
export const APPLICANT_EDIT_ERROR = 'APPLICANT_EDIT_ERROR';

export const APPLICANT_ADD_NEW = 'APPLICANT_ADD_NEW';
export const APPLICANT_ADD_NEW_SUCCESS = 'APPLICANT_ADD_NEW_SUCCESS';
export const APPLICANT_ADD_NEW_ERROR = 'APPLICANT_ADD_NEW_ERROR';
export const APPLICANT_ADD_NEW_REQUEST = 'APPLICANT_ADD_NEW_REQUEST';

export const APPLICANT_DELETE = 'APPLICANT_DELETE';
export const APPLICANT_DELETE_SUCCESS = 'APPLICANT_DELETE_SUCCESS';
export const APPLICANT_DELETE_ERROR = 'APPLICANT_DELETE_ERROR';

export const APPLICANT_GET_BY_QUERY = 'APPLICANT_GET_BY_QUERY';
export const APPLICANT_GET_BY_QUERY_SUCCESS = 'APPLICANT_GET_BY_QUERY_SUCCESS';
export const APPLICANT_GET_BY_QUERY_ERROR = 'APPLICANT_GET_BY_QUERY_ERROR';

export const APPLICANT_COUNT_NEW = 'APPLICANT_COUNT_NEW';
export const APPLICANT_COUNT_NEW_SUCCESS = 'APPLICANT_COUNT_NEW_SUCCESS';

export const APPLICANT_REMOVE_ATTACHMENT = 'APPLICANT_REMOVE_ATTACHMENT';
export const APPLICANT_REMOVE_ATTACHMENT_SUCCESS = 'APPLICANT_REMOVE_ATTACHMENT_SUCCESS';
