import {
  ES_CHEQUE_GET_BY_ESCONTRACT_ID,
  ES_CHEQUE_GET_BY_ESCONTRACT_ID_SUCCESS,
  ES_CHEQUE_GET_BY_ESCONTRACT_ID_ERROR,
  ES_CHEQUE_GET_BY_ID,
  ES_CHEQUE_GET_BY_ID_SUCCESS,
  ES_CHEQUE_GET_BY_ID_ERROR,
  ES_CHEQUE_ADD_NEW,
  ES_CHEQUE_ADD_NEW_SUCCESS,
  ES_CHEQUE_ADD_NEW_ERROR,
  ES_CHEQUE_EDIT,
  ES_CHEQUE_EDIT_SUCCESS,
  ES_CHEQUE_EDIT_ERROR,
  ES_CHEQUE_DELETE,
  ES_CHEQUE_DELETE_SUCCESS,
  ES_CHEQUE_DELETE_ERROR,
} from '../actions';

export const getESChequeByESContractId = esContractId => ({
  type: ES_CHEQUE_GET_BY_ESCONTRACT_ID,
  payload: esContractId,
});

export const getESChequeByESContractIdSuccess = items => ({
  type: ES_CHEQUE_GET_BY_ESCONTRACT_ID_SUCCESS,
  payload: items,
});

export const getESChequeByESContractIdError = error => ({
  type: ES_CHEQUE_GET_BY_ESCONTRACT_ID_ERROR,
  payload: error,
});

export const getESChequeById = id => ({
  type: ES_CHEQUE_GET_BY_ID,
  payload: id,
});

export const getESChequeByIdSuccess = item => ({
  type: ES_CHEQUE_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getESChequeByIdError = error => ({
  type: ES_CHEQUE_GET_BY_ID_ERROR,
  payload: error,
});

export const addNewESCheque = item => ({
  type: ES_CHEQUE_ADD_NEW,
  payload: item,
});

export const addNewESChequeSuccess = item => ({
  type: ES_CHEQUE_ADD_NEW_SUCCESS,
  payload: item,
});

export const addNewESChequeError = error => ({
  type: ES_CHEQUE_ADD_NEW_ERROR,
  payload: error,
});

export const editESCheque = item => ({
  type: ES_CHEQUE_EDIT,
  payload: item,
});

export const editESChequeSuccess = item => ({
  type: ES_CHEQUE_EDIT_SUCCESS,
  payload: item,
});

export const editESChequeError = error => ({
  type: ES_CHEQUE_EDIT_ERROR,
  payload: error,
});

export const deleteESCheque = id => ({
  type: ES_CHEQUE_DELETE,
  payload: id,
});

export const deleteESChequeSuccess = item => ({
  type: ES_CHEQUE_DELETE_SUCCESS,
  payload: item,
});

export const deleteESChequeError = error => ({
  type: ES_CHEQUE_DELETE_ERROR,
  payload: error,
});
