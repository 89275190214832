import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { POSTING_NEW_MATCHING_GET_BY_ID, POSTING_NEW_MATCHING_GET_LIST } from './types';
import Config from '../../config';

import {
  getPostingNewMatchingListSuccess,
  getPostingNewMatchingByIdSuccess,
  postingNewMatchingError,
} from './actions';

// PostingNewMatching

const getPostingNewMatchingListByClientIdRequest = async payload => {
  return axios.post(
    `${Config.apiServerHost}/api/Client/clientJobMatching`,
    payload,
    await authHeader()
  );
};

const getPostingNewMatchingByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/Posting/${id}`, await authHeader());
};

function* getPostingNewMatchingList({ payload }) {
  try {
    const response = yield call(getPostingNewMatchingListByClientIdRequest, payload);
    yield put(getPostingNewMatchingListSuccess(response.data));
  } catch (error) {
    yield put(postingNewMatchingError(error.response.statusText));
  }
}

function* getPostingNewMatchingById({ payload }) {
  try {
    const response = yield call(getPostingNewMatchingByIdRequest, payload);
    yield put(getPostingNewMatchingByIdSuccess(response.data));
  } catch (error) {
    yield put(postingNewMatchingError(error.response.statusText));
  }
}

export function* watchPostingNewMatchingList() {
  yield takeEvery(POSTING_NEW_MATCHING_GET_LIST, getPostingNewMatchingList);
}

export function* watchPostingNewMatchingById() {
  yield takeEvery(POSTING_NEW_MATCHING_GET_BY_ID, getPostingNewMatchingById);
}

export default function* rootSaga() {
  yield all([fork(watchPostingNewMatchingList), fork(watchPostingNewMatchingById)]);
}

export { getPostingNewMatchingListByClientIdRequest };
