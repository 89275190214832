export const CLIENT_SUPPORT_GET_BY_ID = 'CLIENT_SUPPORT_GET_BY_ID';
export const CLIENT_SUPPORT_GET_BY_ID_SUCCESS = 'CLIENT_SUPPORT_GET_BY_ID_SUCCESS';
export const CLIENT_SUPPORT_GET_BY_ID_ERROR = 'CLIENT_SUPPORT_GET_BY_ID_ERROR';

export const CLIENT_SUPPORT_GET_LIST = 'CLIENT_SUPPORT_GET_LIST';
export const CLIENT_SUPPORT_GET_LIST_SUCCESS = 'CLIENT_SUPPORT_GET_LIST_SUCCESS';
export const CLIENT_SUPPORT_GET_LIST_ERROR = 'CLIENT_SUPPORT_GET_LIST_ERROR';

export const CLIENT_SUPPORT_EDIT = 'CLIENT_SUPPORT_EDIT';
export const CLIENT_SUPPORT_EDIT_SUCCESS = 'CLIENT_SUPPORT_EDIT_SUCCESS';
export const CLIENT_SUPPORT_EDIT_ERROR = 'CLIENT_SUPPORT_EDIT_ERROR';

export const CLIENT_SUPPORT_ADD_NEW = 'CLIENT_SUPPORT_ADD_NEW';
export const CLIENT_SUPPORT_ADD_NEW_ERROR = 'CLIENT_SUPPORT_ADD_NEW_ERROR';
export const CLIENT_SUPPORT_ADD_NEW_REQUEST = 'CLIENT_SUPPORT_ADD_NEW_REQUEST';

export const CLIENT_SUPPORT_DELETE = 'CLIENT_SUPPORT_DELETE';
export const CLIENT_SUPPORT_DELETE_ERROR = 'CLIENT_SUPPORT_DELETE_ERROR';

export const CLIENT_SUPPORT_LOG_LIST = 'CLIENT_SUPPORT_LOG_LIST';
export const CLIENT_SUPPORT_LOG_LIST_SUCCESS = 'CLIENT_SUPPORT_LOG_LIST_SUCCESS';
export const CLIENT_SUPPORT_LOG_LIST_ERROR = 'CLIENT_SUPPORT_LOG_LIST_ERROR';

export const CLIENT_SUPPORT_DELETE_ATTACHMENT = 'CLIENT_SUPPORT_DELETE_ATTACHMENT';
export const CLIENT_SUPPORT_DELETE_ATTACHMENT_SUCCESS = 'CLIENT_SUPPORT_DELETE_ATTACHMENT_SUCCESS';
export const CLIENT_SUPPORT_DELETE_ATTACHMENT_ERROR = 'CLIENT_SUPPORT_DELETE_ATTACHMENT_ERROR';

export const CLIENT_SUPPORT_REPORT = 'CLIENT_SUPPORT_REPORT';
export const CLIENT_SUPPORT_REPORT_SUCCESS = 'CLIENT_SUPPORT_REPORT_SUCCESS';
export const CLIENT_SUPPORT_REPORT_ERROR = 'CLIENT_SUPPORT_REPORT_ERROR';
