import {
  TRANSACTION_NOTIFICATION_GET_BY_QUERY,
  TRANSACTION_NOTIFICATION_GET_BY_QUERY_SUCCESS,
  TRANSACTION_NOTIFICATION_GET_BY_QUERY_ERROR,
  IGNORE_TRANSACTION_NOTIFICATION,
  IGNORE_TRANSACTION_NOTIFICATION_SUCCESS,
  IGNORE_TRANSACTION_NOTIFICATION_ERROR,
  CLEAR_TRANSACTION_NOTIFICATION_MESSAGE,
} from './types';

// TransactionNotification

export const getTransactionNotificationByQuery = query => ({
  type: TRANSACTION_NOTIFICATION_GET_BY_QUERY,
  payload: query,
});

export const getTransactionNotificationByQuerySuccess = item => ({
  type: TRANSACTION_NOTIFICATION_GET_BY_QUERY_SUCCESS,
  payload: item,
});

export const getTransactionNotificationByQueryError = error => ({
  type: TRANSACTION_NOTIFICATION_GET_BY_QUERY_ERROR,
  payload: error,
});

export const ignoreTransactionNotification = payload => ({
  type: IGNORE_TRANSACTION_NOTIFICATION,
  payload,
});

export const ignoreTransactionNotificationSuccess = payload => ({
  type: IGNORE_TRANSACTION_NOTIFICATION_SUCCESS,
  payload,
});

export const ignoreTransactionNotificationError = payload => ({
  type: IGNORE_TRANSACTION_NOTIFICATION_ERROR,
  payload,
});

export const clearTransactionNotificationMessage = () => ({
  type: CLEAR_TRANSACTION_NOTIFICATION_MESSAGE,
});
