import {
  POSTING_ADD_NEW,
  POSTING_DELETE,
  POSTING_EDIT,
  POSTING_GET_BY_ID,
  POSTING_GET_BY_ID_SUCCESS,
  POSTING_GET_LIST,
  POSTING_GET_LIST_SUCCESS,
  POSTING_CLIENT_LIST,
  POSTING_CLIENT_LIST_SUCCESS,
  POSTING_RESULT_CHANGE,
  POSTING_ADD_NEW_REQUEST,
  POSTING_ADD_NEW_SUCCESS,
  POSTING_ERROR,
  REMOVE_POSTING_CLIENT,
  FILL_POSTING_POSITION,
  POSTING_CLEAR_MESSAGE,
  POSTING_EDIT_SUCCESS,
} from './types';

export const getPostingList = query => ({
  type: POSTING_GET_LIST,
  payload: query,
});

export const getPostingListSuccess = item => ({
  type: POSTING_GET_LIST_SUCCESS,
  payload: item,
});

export const getPostingInExternalClientList = query => ({
  type: POSTING_CLIENT_LIST,
  payload: query,
});

export const getPostingInExternalClientListSuccess = item => ({
  type: POSTING_CLIENT_LIST_SUCCESS,
  payload: item,
});

export const addNewPosting = (item, history) => ({
  type: POSTING_ADD_NEW,
  payload: { item, history },
});

export const addNewPostingSuccess = payload => ({
  type: POSTING_ADD_NEW_SUCCESS,
  payload,
});

export const addNewPostingRequest = () => ({
  type: POSTING_ADD_NEW_REQUEST,
});

export const editPosting = (item, history) => ({
  type: POSTING_EDIT,
  payload: { item, history },
});

export const editPostingSuccess = payload => ({
  type: POSTING_EDIT_SUCCESS,
  payload,
});

export const deletePosting = id => ({
  type: POSTING_DELETE,
  payload: id,
});

export const getPostingById = id => ({
  type: POSTING_GET_BY_ID,
  payload: id,
});

export const getPostingByIdSuccess = item => ({
  type: POSTING_GET_BY_ID_SUCCESS,
  payload: item,
});

export const postingResultChange = item => ({
  type: POSTING_RESULT_CHANGE,
  payload: item,
});

export const fillPostingPosition = item => ({
  type: FILL_POSTING_POSITION,
  payload: item,
});

export const postingError = error => ({
  type: POSTING_ERROR,
  payload: error,
});

export const removePostingClient = param => ({
  type: REMOVE_POSTING_CLIENT,
  payload: param,
});

export const clearPostingMessage = () => ({
  type: POSTING_CLEAR_MESSAGE,
});
