import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  CASE_AUDIT_ADD_NEW,
  CASE_AUDIT_EDIT,
  CASE_AUDIT_GET_BY_QUERY,
  CASE_AUDIT_GET_BY_ID,
  CASE_AUDIT_DELETE,
} from './types';

import {
  addNewCaseAuditError,
  editCaseAuditError,
  getCaseAuditByQuerySuccess,
  getCaseAuditByQueryError,
  getCaseAuditByIdError,
  getCaseAuditByIdSuccess,
  deleteCaseAuditByIdErrorr,
} from './actions';

const addNewCaseAuditReqeust = async items => {
  return axios.post(`${Config.apiServerHost}/api/CaseAudit`, items, await authHeader());
};

const editCaseAuditRequest = async items => {
  return axios.put(`${Config.apiServerHost}/api/CaseAudit`, items, await authHeader());
};

const getCaseAuditByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/CaseAudit/${id}`, await authHeader());
};

const getCaseAuditByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/CaseAudit/fetch`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const deleteCaseAuditRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/CaseAudit/${id}`, await authHeader());
};
function* addNewCaseAudit({ payload }) {
  try {
    const { clientId, programId, servicePlanHeaderId, data } = payload;
    const query = {
      clientId,
      programId,
      servicePlanHeaderId,
    };

    let response = yield call(addNewCaseAuditReqeust, data);
    response = yield call(getCaseAuditByQueryRequest, query);
    yield put(getCaseAuditByQuerySuccess(response.data));
  } catch (error) {
    yield put(addNewCaseAuditError(error.response.statusText));
  }
}

function* editCaseAudit({ payload }) {
  try {
    const { clientId, programId, servicePlanHeaderId, data } = payload;
    const query = {
      clientId,
      programId,
      servicePlanHeaderId,
    };

    let response = yield call(editCaseAuditRequest, data);
    response = yield call(getCaseAuditByQueryRequest, query);
    yield put(getCaseAuditByQuerySuccess(response.data));
  } catch (error) {
    yield put(editCaseAuditError(error.response.statusText));
  }
}

function* getCaseAuditByQuery({ payload }) {
  try {
    const response = yield call(getCaseAuditByQueryRequest, payload);
    yield put(getCaseAuditByQuerySuccess(response.data));
  } catch (error) {
    yield put(getCaseAuditByQueryError(error.response.statusText));
  }
}

function* getCaseAuditById({ payload }) {
  try {
    const response = yield call(getCaseAuditByIdRequest, payload);
    yield put(getCaseAuditByIdSuccess(response.data));
  } catch (error) {
    yield put(getCaseAuditByIdError(error.response.statusText));
  }
}

function* deleteCaseAudit({ payload }) {
  try {
    const { programId, clientId, servicePlanHeaderId, data } = payload;
    const query = {
      programId,
      clientId,
      servicePlanHeaderId,
    };

    let response = yield call(deleteCaseAuditRequest, data);
    response = yield call(getCaseAuditByQueryRequest, query);
    yield put(getCaseAuditByQuerySuccess(response.data));
  } catch (error) {
    yield put(deleteCaseAuditByIdErrorr(error.response.statusText));
  }
}

export function* watchAddNewCaseAudit() {
  yield takeEvery(CASE_AUDIT_ADD_NEW, addNewCaseAudit);
}
export function* watchEditCaseAudit() {
  yield takeEvery(CASE_AUDIT_EDIT, editCaseAudit);
}

export function* watchGetCaseAuditByQuery() {
  yield takeEvery(CASE_AUDIT_GET_BY_QUERY, getCaseAuditByQuery);
}

export function* watchGetCaseAuditById() {
  yield takeEvery(CASE_AUDIT_GET_BY_ID, getCaseAuditById);
}

export function* watchDeleteCaseAudit() {
  yield takeEvery(CASE_AUDIT_DELETE, deleteCaseAudit);
}

export default function* rootSaga() {
  yield all([
    fork(watchAddNewCaseAudit),
    fork(watchEditCaseAudit),
    fork(watchGetCaseAuditByQuery),
    fork(watchDeleteCaseAudit),
    fork(watchGetCaseAuditById),
  ]);
}

export { getCaseAuditByQueryRequest };
