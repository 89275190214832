import {
  SHIFTS_GET_LIST,
  SHIFTS_GET_LIST_SUCCESS,
  SHIFTS_GET_LIST_ERROR,
  SHIFTS_GET_LIST_WITH_FILTER,
  SHIFTS_GET_LIST_WITH_ORDER,
  SHIFTS_GET_LIST_SEARCH,
  SHIFTS_SELECTED_ITEMS_CHANGE,
  SHIFTS_DELETE_ITEM,
  SHIFTS_DELETE_ITEM_SUCCESS,
  SHIFTS_DELETE_ITEM_ERROR,
} from '../actions';

const INIT_STATE = {
  shifts: [],
  shift: [],
  error: '',
  filter: null,
  searchKeyword: '',
  orderColumn: null,
  loading: false,
  labels: [
    { label: 'EDUCATION', color: 'secondary' },
    { label: 'NEW FRAMEWORK', color: 'primary' },
    { label: 'PERSONAL', color: 'info' },
  ],
  orderColumns: [
    { column: 'id', label: 'Id' },
    { column: 'name', label: 'Name' },
    { column: 'hid', label: 'HST' },
    { column: 'pcc', label: 'PCC' },
    { column: 'note', label: 'Note' },
  ],
  categories: ['Flexbox', 'Sass', 'React'],
  selectedItems: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHIFTS_GET_LIST:
      return { ...state, loading: false };

    case SHIFTS_GET_LIST_SUCCESS:
      return { ...state, loading: true, shifts: action.payload, shift: action.payload };

    case SHIFTS_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case SHIFTS_GET_LIST_WITH_FILTER:
      if (action.payload.column === '' || action.payload.value === '') {
        return { ...state, loading: true, shift: state.shifts, filter: null };
      } else {
        const filteredItems = state.shifts.filter(
          item => item[action.payload.column] === action.payload.value
        );
        return {
          ...state,
          loading: true,
          shift: filteredItems,
          filter: {
            column: action.payload.column,
            value: action.payload.value,
          },
        };
      }

    case SHIFTS_GET_LIST_WITH_ORDER:
      if (action.payload === '') {
        return { ...state, loading: true, shift: state.shift, orderColumn: null };
      } else {
        const sortedItems = state.shifts.sort((a, b) => {
          if (a[action.payload] < b[action.payload]) return -1;
          else if (a[action.payload] > b[action.payload]) return 1;
          return 0;
        });
        return {
          ...state,
          loading: true,
          shift: sortedItems,
          orderColumn: state.orderColumns.find(x => x.column === action.payload),
        };
      }

    case SHIFTS_GET_LIST_SEARCH:
      if (action.payload === '') {
        return { ...state, shift: state.shifts };
      } else {
        const keyword = action.payload;
        const searchItems = state.shifts.filter(
          item =>
            String(item.id).startsWith(keyword) ||
            String(item.name.toLowerCase()).startsWith(keyword) ||
            String(item.note.toLowerCase()).startsWith(keyword)
        );
        return { ...state, loading: true, shift: searchItems, searchKeyword: action.payload };
      }

    case SHIFTS_SELECTED_ITEMS_CHANGE:
      return { ...state, loading: true, selectedItems: action.payload };
    default:
      return { ...state };

    case SHIFTS_DELETE_ITEM:
      return { ...state, loading: false };

    case SHIFTS_DELETE_ITEM_SUCCESS:
      return { ...state, loading: true, shifts: action.payload, shift: action.payload };

    case SHIFTS_DELETE_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };
  }
};
