import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import { GET_WORKSHOP_EVENT_LIST } from './types';

import {
  getWorkshopEventList,
  getWorkshopEventListError,
  getWorkshopEventListSuccess,
} from './actions';

const getWorkshopEventListRequest = async query => {
  let url = `${Config.apiServerHost}/api/event/client-workshop-event/paging`;
  return await axios.get(url, {
    params: query,
    headers: { ...(await authHeader()).headers },
  });
};

function* getWorkshopEventListQuery({ payload }) {
  try {
    const response = yield call(getWorkshopEventListRequest, payload);
    yield put(getWorkshopEventListSuccess(response.data));
  } catch (error) {
    yield put(getWorkshopEventListError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_WORKSHOP_EVENT_LIST, getWorkshopEventListQuery);
}
