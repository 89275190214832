import {
  CLEAR_TRANSACTION_NOTIFICATION_MESSAGE,
  IGNORE_TRANSACTION_NOTIFICATION,
  IGNORE_TRANSACTION_NOTIFICATION_SUCCESS,
  TRANSACTION_NOTIFICATION_GET_BY_QUERY,
  TRANSACTION_NOTIFICATION_GET_BY_QUERY_SUCCESS,
} from './types';
// item
const initialState = {
  items: [],
  loading: false,
  message: null,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case IGNORE_TRANSACTION_NOTIFICATION:
    case TRANSACTION_NOTIFICATION_GET_BY_QUERY:
      return {
        ...state,
        loading: true,
        item: null,
      };

    case IGNORE_TRANSACTION_NOTIFICATION_SUCCESS:
    case TRANSACTION_NOTIFICATION_GET_BY_QUERY_SUCCESS:
      return {
        ...state,
        loading: false,
        items: payload,
      };
    case CLEAR_TRANSACTION_NOTIFICATION_MESSAGE:
      return {
        ...state,
        loading: false,
        message: null,
      };

    default:
      return { ...state };
  }
}
