import {
  GET_CLIENT_SUMMARY_YJC,
  GET_CLIENT_SUMMARY_YJC_SUCCESS,
  SAVE_CLIENT_SUMMARY_YJC,
  CLIENT_SUMMARY_YJC_ERROR,
} from './types';
export const getClientSummaryYJC = payload => ({
  type: GET_CLIENT_SUMMARY_YJC,
  payload,
});

export const getClientSummaryYJCSuccess = data => ({
  type: GET_CLIENT_SUMMARY_YJC_SUCCESS,
  payload: data,
});

export const saveClientSummaryYJC = data => ({
  type: SAVE_CLIENT_SUMMARY_YJC,
  payload: data,
});

export const clientSummaryYJCError = error => ({
  type: CLIENT_SUMMARY_YJC_ERROR,
  payload: error,
});
