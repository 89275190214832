import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  PROSPECT_RECEIVE_NOTIFICATION_GET_ALL,
  PROSPECT_RECEIVE_NOTIFICATION_GET_BY_ID,
  PROSPECT_RECEIVE_NOTIFICATION_ADD_NEW,
  PROSPECT_RECEIVE_NOTIFICATION_EDIT,
  PROSPECT_RECEIVE_NOTIFICATION_DELETE,
} from '../actions';

import {
  getProspectReceiveNotificationsSuccess,
  getProspectReceiveNotificationsError,
  getProspectReceiveNotificationByIdSuccess,
  getProspectReceiveNotificationByIdError,
  addNewProspectReceiveNotificationSuccess,
  addNewProspectReceiveNotificationError,
  editProspectReceiveNotificationSuccess,
  editProspectReceiveNotificationError,
  deleteProspectReceiveNotificationSuccess,
  deleteProspectReceiveNotificationError,
} from './actions';

const getProspectReceiveNotificationsRequest = async () => {
  const url = `${Config.apiServerHost}/api/ProspectReceiveNotification`;
  return axios.get(url, await authHeader());
};

const getProspectReceiveNotificationByIdRequest = async id => {
  return axios.get(
    `${Config.apiServerHost}/api/ProspectReceiveNotification/${id}`,
    await authHeader()
  );
};

const addNewProspectReceiveNotificationReqeust = async item => {
  return axios.post(
    `${Config.apiServerHost}/api/ProspectReceiveNotification`,
    item,
    await authHeader()
  );
};

const editProspectReceiveNotificationRequest = async item => {
  return axios.put(
    `${Config.apiServerHost}/api/ProspectReceiveNotification`,
    item,
    await authHeader()
  );
};

const deleteProspectReceiveNotificationRequest = async id => {
  return axios.delete(
    `${Config.apiServerHost}/api/ProspectReceiveNotification/${id}`,
    await authHeader()
  );
};

function* getProspectReceiveNotifications() {
  try {
    const response = yield call(getProspectReceiveNotificationsRequest);
    yield put(getProspectReceiveNotificationsSuccess(response.data));
  } catch (error) {
    yield put(getProspectReceiveNotificationsError(error.response.statusText));
  }
}

function* getProspectReceiveNotificationById({ payload }) {
  try {
    const response = yield call(getProspectReceiveNotificationByIdRequest, payload);
    yield put(getProspectReceiveNotificationByIdSuccess(response.data));
  } catch (error) {
    yield put(getProspectReceiveNotificationByIdError(error.response.statusText));
  }
}

function* addNewProspectReceiveNotification({ payload }) {
  try {
    let response = yield call(addNewProspectReceiveNotificationReqeust, payload);
    response = yield call(getProspectReceiveNotificationByIdRequest, response.data);
    yield put(addNewProspectReceiveNotificationSuccess(response.data));
  } catch (error) {
    yield put(addNewProspectReceiveNotificationError(error.response.statusText));
  }
}

function* editProspectReceiveNotification({ payload }) {
  try {
    let response = yield call(editProspectReceiveNotificationRequest, payload);
    response = yield call(getProspectReceiveNotificationByIdRequest, response.data);
    yield put(editProspectReceiveNotificationSuccess(response.data));
  } catch (error) {
    yield put(editProspectReceiveNotificationError(error.response.statusText));
  }
}

function* deleteProspectReceiveNotification({ payload }) {
  try {
    const response = yield call(deleteProspectReceiveNotificationRequest, payload);
    yield put(deleteProspectReceiveNotificationSuccess(response.data));
  } catch (error) {
    yield put(deleteProspectReceiveNotificationError(error.response.statusText));
  }
}

export function* watchGetProspectReceiveNotifications() {
  yield takeEvery(PROSPECT_RECEIVE_NOTIFICATION_GET_ALL, getProspectReceiveNotifications);
}

export function* watchGetProspectReceiveNotificationById() {
  yield takeEvery(PROSPECT_RECEIVE_NOTIFICATION_GET_BY_ID, getProspectReceiveNotificationById);
}

export function* watchAddNewProspectReceiveNotification() {
  yield takeEvery(PROSPECT_RECEIVE_NOTIFICATION_ADD_NEW, addNewProspectReceiveNotification);
}

export function* watchEditProspectReceiveNotification() {
  yield takeEvery(PROSPECT_RECEIVE_NOTIFICATION_EDIT, editProspectReceiveNotification);
}

export function* watchDeleteProspectReceiveNotification() {
  yield takeEvery(PROSPECT_RECEIVE_NOTIFICATION_DELETE, deleteProspectReceiveNotification);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetProspectReceiveNotifications),
    fork(watchGetProspectReceiveNotificationById),
    fork(watchAddNewProspectReceiveNotification),
    fork(watchEditProspectReceiveNotification),
    fork(watchDeleteProspectReceiveNotification),
  ]);
}

export { getProspectReceiveNotificationsRequest };
