import {
  EMPLOYMENT_EARNING_ADD_NEW,
  EMPLOYMENT_EARNING_ADD_NEW_SUCCESS,
  EMPLOYMENT_EARNING_ADD_NEW_ERROR,
  EMPLOYMENT_EARNING_DELETE,
  EMPLOYMENT_EARNING_DELETE_ERROR,
  EMPLOYMENT_EARNING_DELETE_SUCCESS,
  EMPLOYMENT_EARNING_EDIT,
  EMPLOYMENT_EARNING_EDIT_ERROR,
  EMPLOYMENT_EARNING_EDIT_SUCCESS,
  EMPLOYMENT_EARNING_GET_BY_ID,
  EMPLOYMENT_EARNING_GET_BY_ID_ERROR,
  EMPLOYMENT_EARNING_GET_BY_ID_SUCCESS,
  EMPLOYMENT_EARNING_GET_LIST,
  EMPLOYMENT_EARNING_GET_LIST_ERROR,
  EMPLOYMENT_EARNING_GET_LIST_SUCCESS,
  EMPLOYMENT_EARNING_ADD_NEW_REQUEST,
  EMPLOYMENT_EARNING_QUERY,
  EMPLOYMENT_EARNING_QUERY_SUCCESS,
  EMPLOYMENT_EARNING_QUERY_ERROR,
} from '../actions';

/** EmploymentEarning */
export const getEmploymentEarningQuery = query => ({
  type: EMPLOYMENT_EARNING_QUERY,
  payload: query,
});

export const getEmploymentEarningQuerySuccess = caseNotes => ({
  type: EMPLOYMENT_EARNING_QUERY_SUCCESS,
  payload: caseNotes,
});

export const getEmploymentEarningQueryError = error => ({
  type: EMPLOYMENT_EARNING_QUERY_ERROR,
  payload: error,
});

export const getEmploymentEarningList = clientId => ({
  type: EMPLOYMENT_EARNING_GET_LIST,
  payload: clientId,
});

export const getEmploymentEarningListSuccess = CaseNotes => ({
  type: EMPLOYMENT_EARNING_GET_LIST_SUCCESS,
  payload: CaseNotes,
});

export const getEmploymentEarningListError = error => ({
  type: EMPLOYMENT_EARNING_GET_LIST_ERROR,
  payload: error,
});

export const addNewEmploymentEarning = caseNote => ({
  type: EMPLOYMENT_EARNING_ADD_NEW,
  payload: caseNote,
});

export const addNewEmploymentEarningRequest = () => ({
  type: EMPLOYMENT_EARNING_ADD_NEW_REQUEST,
});

export const addNewEmploymentEarningSuccess = CaseNote => ({
  type: EMPLOYMENT_EARNING_ADD_NEW_SUCCESS,
  payload: CaseNote,
});

export const addNewEmploymentEarningError = error => ({
  type: EMPLOYMENT_EARNING_ADD_NEW_ERROR,
  payload: error,
});

export const editEmploymentEarning = CaseNote => ({
  type: EMPLOYMENT_EARNING_EDIT,
  payload: CaseNote,
});

export const editEmploymentEarningSuccess = caseNote => ({
  type: EMPLOYMENT_EARNING_EDIT_SUCCESS,
  payload: caseNote,
});

export const editEmploymentEarningError = error => ({
  type: EMPLOYMENT_EARNING_EDIT_ERROR,
  payload: error,
});

export const deleteEmploymentEarning = id => ({
  type: EMPLOYMENT_EARNING_DELETE,
  payload: id,
});

export const deleteEmploymentEarningSuccess = id => ({
  type: EMPLOYMENT_EARNING_DELETE_SUCCESS,
  payload: id,
});

export const deleteEmploymentEarningError = error => ({
  type: EMPLOYMENT_EARNING_DELETE_ERROR,
  payload: error,
});

export const getEmploymentEarningById = id => ({
  type: EMPLOYMENT_EARNING_GET_BY_ID,
  payload: id,
});

export const getEmploymentEarningByIdSuccess = caseNote => ({
  type: EMPLOYMENT_EARNING_GET_BY_ID_SUCCESS,
  payload: caseNote,
});

export const getEmploymentEarningByIdError = error => ({
  type: EMPLOYMENT_EARNING_GET_BY_ID_ERROR,
  payload: error,
});
