import {
  JOURNEY_ACK_ADD_NEW,
  JOURNEY_ACK_ADD_NEW_ERROR,
  JOURNEY_ACK_ADD_NEW_SUCCESS,
  JOURNEY_ACK_GET_BY_ID,
  JOURNEY_ACK_GET_BY_ID_ERROR,
  JOURNEY_ACK_GET_BY_ID_SUCCESS,
} from './types';

export const addJourneyAck = items => ({
  type: JOURNEY_ACK_ADD_NEW,
  payload: items,
});

export const addJourneyAckSuccess = items => ({
  type: JOURNEY_ACK_ADD_NEW_SUCCESS,
  payload: items,
});

export const addJourneyAckError = error => ({
  type: JOURNEY_ACK_ADD_NEW_ERROR,
  payload: error,
});

export const getJourneyAckById = items => ({
  type: JOURNEY_ACK_GET_BY_ID,
  payload: items,
});

export const getJourneyAckByIdSuccess = items => ({
  type: JOURNEY_ACK_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getJourneyAckByIdError = error => ({
  type: JOURNEY_ACK_GET_BY_ID_ERROR,
  payload: error,
});
