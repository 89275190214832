import {
  POSTING_ADD_NEW,
  POSTING_DELETE,
  POSTING_EDIT,
  POSTING_GET_BY_ID,
  POSTING_GET_BY_ID_SUCCESS,
  POSTING_GET_LIST,
  POSTING_GET_LIST_SUCCESS,
  POSTING_CLIENT_LIST,
  POSTING_CLIENT_LIST_SUCCESS,
  POSTING_ADD_NEW_REQUEST,
  POSTING_RESULT_CHANGE,
  POSTING_ADD_NEW_SUCCESS,
  POSTING_ERROR,
  POSTING_CLEAR_MESSAGE,
  POSTING_EDIT_SUCCESS,
} from './types';
import { displayMessage, SEVERITY } from '../../helpers/Utils';

const initialState = {
  postings: [],
  posting: null,
  notifications: [],
  loading: false,
  error: null,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
  itemCount: 0,
  pageCount: 0,
  totalActive: 0,
  totalDraft: 0,
  totalClosed: 0,
  message: null,
  saving: false,
  isPrintLoad: false,
  isPrint: false,
  oldPageSize: 0,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;
  state = { ...state };
  switch (type) {
    case POSTING_CLEAR_MESSAGE:
      return {
        ...state,
        message: null,
        loading: false,
      };
    case POSTING_RESULT_CHANGE:
    case POSTING_GET_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
        posting: null,
      };
    case POSTING_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        posting: payload,
      };

    case POSTING_ADD_NEW_REQUEST:
      return {
        ...state,
        addSuccess: false,
        posting: null,
      };

    case POSTING_ADD_NEW_SUCCESS:
      displayMessage({
        title: 'Posting',
        message: 'Your posting has been successfully created.',
        severity: SEVERITY.SUCCESS,
      });

      return {
        ...state,
        loading: false,
        saving: false,
      };

    case POSTING_CLIENT_LIST:
    case POSTING_GET_LIST: {
      const { isPrintLoad, oldPageSize } = payload;
      return {
        ...state,
        error: null,
        loading: true,
        posting: null,
        isPrint: false,
        isPrintLoad: isPrintLoad ?? false,
      };
    }

    case POSTING_CLIENT_LIST_SUCCESS:
    case POSTING_GET_LIST_SUCCESS: {
      const { itemCount, pageCount, data, totalActive, totalDraft, totalClosed, isPrintLoad } =
        payload;
      return {
        ...state,
        postings: data,
        loading: false,
        posting: null,
        itemCount,
        pageCount,
        addSuccess: true,
        editSuccess: true,
        deleteSuccess: true,
        removeSuccess: true,
        totalActive,
        totalDraft,
        totalClosed,
        isPrint: isPrintLoad ?? false,
        isPrintLoad: false,
      };
    }
    case POSTING_ADD_NEW:
      return {
        ...state,
        loading: true,
        saving: true,
        error: null,
        addSuccess: false,
        posting: null,
      };

    case POSTING_EDIT:
      return {
        ...state,
        posting: null,
        loading: false,
        error: null,
        saving: true,
        editSuccess: false,
      };

    case POSTING_EDIT_SUCCESS:
      displayMessage({
        title: 'Posting',
        message: 'Your posting has been successfully updated.',
        severity: SEVERITY.SUCCESS,
      });

      return {
        ...state,
        loading: true,
        saving: false,
      };
    case POSTING_DELETE:
      return {
        ...state,
        loading: true,
        error: null,
        deleteSuccess: false,
        posting: null,
      };
    case POSTING_ERROR:
      displayMessage({
        title: 'Posting',
        message: payload,
        severity: SEVERITY.DANGER,
      });
      return {
        ...state,
        loading: false,
        error: payload,
        posting: null,
        saving: false,
      };

    default:
      return { ...state };
  }
}
