import {
  CLIENT_TSA_ADD_NEW,
  CLIENT_TSA_ADD_NEW_SUCCESS,
  CLIENT_TSA_ADD_NEW_ERROR,
  CLIENT_TSA_EDIT,
  CLIENT_TSA_EDIT_ERROR,
  CLIENT_TSA_EDIT_SUCCESS,
  CLIENT_TSA_GET_BY_QUERY,
  CLIENT_TSA_GET_BY_ID,
  CLIENT_TSA_GET_BY_QUERY_SUCCESS,
  CLIENT_TSA_GET_BY_QUERY_ERROR,
  CLIENT_TSA_GET_BY_ID_SUCCESS,
  CLIENT_TSA_ADD_NEW_REQUEST,
  CLIENT_TSA_DELETE,
  CLIENT_TSA_DELETE_SUCCESS,
  CLIENT_TSA_DELETE_ERROR,
  CLIENT_TSA_GET_BY_ID_ERROR,
} from './types';

// ClientTSA

export const addNewClientTSA = items => ({
  type: CLIENT_TSA_ADD_NEW,
  payload: items,
});

export const addNewClientTSARequest = () => ({
  type: CLIENT_TSA_ADD_NEW_REQUEST,
});

export const addNewClientTSASuccess = items => ({
  type: CLIENT_TSA_ADD_NEW_SUCCESS,
  payload: items,
});

export const addNewClientTSAError = error => ({
  type: CLIENT_TSA_ADD_NEW_ERROR,
  payload: error,
});

export const editClientTSA = item => ({
  type: CLIENT_TSA_EDIT,
  payload: item,
});

export const editClientTSASuccess = item => ({
  type: CLIENT_TSA_EDIT_SUCCESS,
  payload: item,
});

export const editClientTSAError = error => ({
  type: CLIENT_TSA_EDIT_ERROR,
  payload: error,
});

export const getClientTSAByQuery = query => ({
  type: CLIENT_TSA_GET_BY_QUERY,
  payload: query,
});

export const getClientTSAByQuerySuccess = item => ({
  type: CLIENT_TSA_GET_BY_QUERY_SUCCESS,
  payload: item,
});

export const getClientTSAByQueryError = error => ({
  type: CLIENT_TSA_GET_BY_QUERY_ERROR,
  payload: error,
});

export const getClientTSAById = id => ({
  type: CLIENT_TSA_GET_BY_ID,
  payload: id,
});

export const getClientTSAByIdSuccess = item => ({
  type: CLIENT_TSA_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getClientTSAByIdError = error => ({
  type: CLIENT_TSA_GET_BY_ID_ERROR,
  payload: error,
});

export const deleteClientTSAById = id => ({
  type: CLIENT_TSA_DELETE,
  payload: id,
});

export const deleteClientTSAByIdSuccess = item => ({
  type: CLIENT_TSA_DELETE_SUCCESS,
  payload: item,
});

export const deleteClientTSAByIdErrorr = item => ({
  type: CLIENT_TSA_DELETE_ERROR,
  payload: item,
});
