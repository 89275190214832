import { Auth } from 'aws-amplify';
export async function authAccessHeader() {
  const token = (await Auth.currentSession()).getAccessToken().getJwtToken();

  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
}
