import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../../helpers/auth-header';
import axios from 'axios';
import Config from '../../../config';
import { buildUrl } from '../../../helpers/Utils';

import {
  GET_CUSTOMER_SATISFACTION_REPORT,
  GET_CLIENT_OUTCOMES_REPORT,
  GET_CLIENT_VOLUMES_REPORT as GET_CLIENT_VOLUMES_REPORT,
  SAVE_TARGET_CLIENT_VOLUMES_REPORT,
  SAVE_TARGET_CUSTOMER_SATISFACTION_REPORT,
  SAVE_TARGET_CLIENT_OUTCOMES_REPORT,
} from './types';

import {
  getCustomerSatisfactionReportSuccess,
  getCustomerSatisfactionReportError,
  getClientOutcomesReportSuccess,
  getClientOutcomesReportError,
  getClientVolumesReportSuccess as getClientVolumesReportSuccess,
  getClientVolumesReportError as getClientVolumesReportError,
} from './actions';

const getCustomerSatisfactionReportRequest = async criteria => {
  return await axios.post(
    `${Config.apiServerHost}/api/weeklyreport/customerSatisfaction`,
    criteria,
    await authHeader()
  );
};

function* getCustomerSatisfactionReport({ payload }) {
  try {
    const response = yield call(getCustomerSatisfactionReportRequest, payload);
    yield put(getCustomerSatisfactionReportSuccess(response.data));
  } catch (error) {
    yield put(getCustomerSatisfactionReportError(error));
  }
}

const getClientOutcomesReportRequest = async criteria => {
  return await axios.post(
    `${Config.apiServerHost}/api/weeklyreport/clientOutcomes`,
    criteria,
    await authHeader()
  );
};

function* getClientOutcomesReport({ payload }) {
  try {
    const response = yield call(getClientOutcomesReportRequest, payload);
    yield put(getClientOutcomesReportSuccess(response.data));
  } catch (error) {
    yield put(getClientOutcomesReportError(error));
  }
}

const getClientVolumesReportRequest = async criteria => {
  return await axios.post(
    `${Config.apiServerHost}/api/WeeklyReport/clientVolume`,
    criteria,
    await authHeader()
  );
};

const saveTargetClientVolumeReportRequest = async data => {
  return await axios.post(
    `${Config.apiServerHost}/api/WeeklyReport/saveTargetClientVolume`,
    data,
    await authHeader()
  );
};

const saveTargetCustomerSatisfactionReportRequest = async data => {
  return await axios.post(
    `${Config.apiServerHost}/api/WeeklyReport/saveTargetCustomerSatisfaction`,
    data,
    await authHeader()
  );
};

const saveTargetClientOutcomeRequest = async data => {
  return await axios.post(
    `${Config.apiServerHost}/api/WeeklyReport/saveTargetClientOutcomes`,
    data,
    await authHeader()
  );
};

function* getClientVolumesReport({ payload }) {
  try {
    const response = yield call(getClientVolumesReportRequest, payload);
    yield put(getClientVolumesReportSuccess(response.data));
  } catch (error) {
    yield put(getClientVolumesReportError(error));
  }
}

function* saveTargetClientVolumesReport({ payload }) {
  try {
    const { data, queryParams } = payload;
    yield call(saveTargetClientVolumeReportRequest, data);
    const response = yield call(getClientVolumesReportRequest, queryParams);
    yield put(getClientVolumesReportSuccess(response.data));
  } catch (error) {
    yield put(getClientVolumesReportError(error));
  }
}

function* saveTargetCustomerSatisfactionReport({ payload }) {
  try {
    const { data, queryParams } = payload;
    yield call(saveTargetCustomerSatisfactionReportRequest, data);

    const response = yield call(getCustomerSatisfactionReportRequest, queryParams);
    yield put(getCustomerSatisfactionReportSuccess(response.data));
  } catch (error) {
    yield put(getClientVolumesReportError(error));
  }
}

function* saveTargetClientOutcome({ payload }) {
  try {
    const { data, queryParams } = payload;
    yield call(saveTargetClientOutcomeRequest, data);

    const response = yield call(getClientOutcomesReportRequest, queryParams);
    yield put(getClientOutcomesReportSuccess(response.data));
  } catch (error) {
    yield put(getClientVolumesReportError(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_CUSTOMER_SATISFACTION_REPORT, getCustomerSatisfactionReport);
  yield takeEvery(GET_CLIENT_OUTCOMES_REPORT, getClientOutcomesReport);
  yield takeEvery(GET_CLIENT_VOLUMES_REPORT, getClientVolumesReport);
  yield takeEvery(SAVE_TARGET_CLIENT_VOLUMES_REPORT, saveTargetClientVolumesReport);
  yield takeEvery(SAVE_TARGET_CUSTOMER_SATISFACTION_REPORT, saveTargetCustomerSatisfactionReport);
  yield takeEvery(SAVE_TARGET_CLIENT_OUTCOMES_REPORT, saveTargetClientOutcome);
}
