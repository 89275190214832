import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { OUTCOMES_GET_BY_CLIENT_AND_PROGRAM, OUTCOMES_SAVE } from './types';

import { getOutcomesByClientAndProgramSuccess, outcomesError } from './actions';

const getOutcomesByClientAndProgramRequest = async query => {
  let url = `${Config.apiServerHost}/api/ActionPlan/getOutcomes`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const saveOutcomesRequst = async item => {
  return axios.post(
    `${Config.apiServerHost}/api/ActionPlan/updateOutcomes`,
    item,
    await authHeader()
  );
};

function* getOutcomesByClientAndProgram({ payload }) {
  try {
    const response = yield call(getOutcomesByClientAndProgramRequest, payload);
    yield put(getOutcomesByClientAndProgramSuccess(response.data));
  } catch (error) {
    yield put(outcomesError(error.response.statusText));
  }
}

function* saveOutcomes({ payload }) {
  try {
    const { clientId, programId } = payload;
    const query = {
      clientId,
      programId,
    };

    yield call(saveOutcomesRequst, payload);
    const response = yield call(getOutcomesByClientAndProgramRequest, query);
    yield put(getOutcomesByClientAndProgramSuccess(response.data));
  } catch (error) {
    yield put(outcomesError(error.response.statusText));
  }
}

export function* watchOutcomesByClientAndProgram() {
  yield takeEvery(OUTCOMES_GET_BY_CLIENT_AND_PROGRAM, getOutcomesByClientAndProgram);
}

export function* watchOutcomesSaving() {
  yield takeEvery(OUTCOMES_SAVE, saveOutcomes);
}

export default function* rootSaga() {
  yield all([fork(watchOutcomesByClientAndProgram), fork(watchOutcomesSaving)]);
}
