import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  EXPENSE_ENTRY_DELETE,
  EXPENSE_ENTRY_RECEIPT_DELETE,
  EXPENSE_ENTRY_EDIT,
  EXPENSE_ENTRY_GET_BY_ID,
  EXPENSE_ENTRY_ADD_NEW,
  EXPENSE_ENTRY_GET_LIST_BY_EXPENSE_ID,
} from '../actions';

import {
  getExpenseEntryListSuccess,
  getExpenseEntryListError,
  editExpenseEntrySuccess,
  editExpenseEntryError,
  addNewExpenseEntrySuccess,
  addNewExpenseEntryError,
  deleteExpenseEntrySuccess,
  deleteExpenseEntryError,
  getExpenseEntryByIdSuccess,
  deleteExpenseEntryReceiptSuccess,
  deleteExpenseEntryReceiptError,
} from './actions';

const getExpenseEntryListRequest = async params => {
  let url = `${Config.apiServerHost}/api/expenseEntry/search`;

  return axios.get(buildUrl(url, params), await authHeader());
};

const addNewExpenseEntryReqeust = async entry => {
  return axios.post(`${Config.apiServerHost}/api/expenseEntry`, entry, await authHeader());
};

const editExpenseEntryRequest = async entry => {
  return axios.put(
    `${Config.apiServerHost}/api/expenseEntry/${entry.id}`,
    entry,
    await authHeader()
  );
};

const deleteExpenseEntryRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/expenseEntry/${id}`, await authHeader());
};

const deleteExpenseEntryReceiptRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/expenseEntry/receipt/${id}`, await authHeader());
};

const getExpenseEntryByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/expenseEntry/${id}`, await authHeader());
};

function* getExpenseEntryList({ payload }) {
  try {
    const response = yield call(getExpenseEntryListRequest, payload);
    yield put(getExpenseEntryListSuccess(response.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(getExpenseEntryListError(error));
  }
}

function* addNewExpenseEntry({ payload }) {
  try {
    let response = yield call(addNewExpenseEntryReqeust, payload);
    response = yield call(getExpenseEntryByIdRequest, response.data.data);
    yield put(addNewExpenseEntrySuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(addNewExpenseEntryError(error));
  }
}

function* editExpenseEntry({ payload }) {
  try {
    let response = yield call(editExpenseEntryRequest, payload);
    response = yield call(getExpenseEntryByIdRequest, payload.id);
    yield put(editExpenseEntrySuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(editExpenseEntryError(error));
  }
}

function* getExpenseEntryById({ payload }) {
  try {
    const response = yield call(getExpenseEntryByIdRequest, payload);
    yield put(getExpenseEntryByIdSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(editExpenseEntryError(error));
  }
}

function* deleteExpenseEntry({ payload }) {
  try {
    const response = yield call(deleteExpenseEntryRequest, payload);
    yield put(deleteExpenseEntrySuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(deleteExpenseEntryError(error));
  }
}

function* deleteExpenseEntryReceipt({ payload }) {
  try {
    const response = yield call(deleteExpenseEntryReceiptRequest, payload);
    yield put(deleteExpenseEntryReceiptSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(deleteExpenseEntryReceiptError(error));
  }
}

export function* watchExpenseEntryList() {
  yield takeEvery(EXPENSE_ENTRY_GET_LIST_BY_EXPENSE_ID, getExpenseEntryList);
}

export function* watchAddNewExpenseEntry() {
  yield takeEvery(EXPENSE_ENTRY_ADD_NEW, addNewExpenseEntry);
}

export function* watchExpenseEntryById() {
  yield takeEvery(EXPENSE_ENTRY_GET_BY_ID, getExpenseEntryById);
}

export function* watchEditExpenseEntry() {
  yield takeEvery(EXPENSE_ENTRY_EDIT, editExpenseEntry);
}

export function* watchDeleteExpenseEntry() {
  yield takeEvery(EXPENSE_ENTRY_DELETE, deleteExpenseEntry);
}

export function* watchDeleteExpenseEntryReceipt() {
  yield takeEvery(EXPENSE_ENTRY_RECEIPT_DELETE, deleteExpenseEntryReceipt);
}

export default function* rootSaga() {
  yield all([
    fork(watchExpenseEntryList),
    fork(watchAddNewExpenseEntry),
    fork(watchExpenseEntryById),
    fork(watchEditExpenseEntry),
    fork(watchDeleteExpenseEntry),
    fork(watchDeleteExpenseEntryReceipt),
  ]);
}
