import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import axios from 'axios';
import Config from '../../config';

import {
  SE_CONTRACT_GET_LIST,
  SE_CONTRACT_ADD_ITEM,
  SE_CONTRACT_EDIT_ITEM,
  SE_CONTRACT_DELETE_ITEM,
  SE_CONTRACT_GET_BY_ID,
  SE_CONTRACT_REPORT_GET_LIST,
} from '../actions';

import {
  getSEContractListSuccess,
  getSEContractListError,
  addSEContractItemError,
  editSEContractItemError,
  deleteSEContractItemSuccess,
  deleteSEContractItemError,
  getSEContractByIdSuccess,
  getSEContractByIdError,
  getSEContractReportListSuccess,
  getSEContractReportListError,
  addSEContractItemSuccess,
  editSEContractItemSuccess,
} from './actions';

const getSEContractsRequest = async () => {
  return await axios.get(`${Config.apiServerHost}/api/SEContract`, await authHeader());
};

const getSEContractListRequest = async query => {
  let url = `${Config.apiServerHost}/api/SEContract/search`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getSEContractRequest = async id => {
  return await axios.get(`${Config.apiServerHost}/api/SEContract/${id}`, await authHeader());
};

const addNewSEContractRequest = async data => {
  return await axios.post(`${Config.apiServerHost}/api/SEContract`, data, await authHeader());
};

const editSEContractRequest = async data => {
  return await axios.put(`${Config.apiServerHost}/api/SEContract`, data, await authHeader());
};

const deleteSEContractRequest = async id => {
  return await axios.delete(`${Config.apiServerHost}/api/SEContract/${id}`, await authHeader());
};

const getSEContractReportListRequest = async filter => {
  return await axios.get(
    `${Config.apiServerHost}/api/SEContract/report?fromDate=${filter.payload.fromDate}&toDate=${filter.payload.toDate}&fiscalYear=${filter.payload.fiscalYear}`,
    await authHeader()
  );
};

function* getSEContractById({ payload }) {
  try {
    const response = yield call(getSEContractRequest, payload);
    yield put(getSEContractByIdSuccess(response.data));
  } catch (error) {
    yield put(getSEContractByIdError(error));
  }
}

function* getSEContractList({ payload }) {
  try {
    const response = yield call(getSEContractListRequest, payload);
    yield put(getSEContractListSuccess(response.data));
  } catch (error) {
    yield put(getSEContractListError(error));
  }
}

function* addNewSEContract({ payload }) {
  try {
    const { item, history } = payload;

    if (history) {
      yield call(addNewSEContractRequest, item);
      history.push('/app/contract/se');
    } else {
      const { data, clientId, employerId, servicePlanHeaderId } = item;
      const query = {
        clientId: clientId ?? '',
        employerId: employerId ?? '',
        servicePlanHeaderId: servicePlanHeaderId ?? '',
      };

      yield call(addNewSEContractRequest, data);
      const response = yield call(getSEContractListRequest, query);
      yield put(addSEContractItemSuccess(response.data));
    }
  } catch (error) {
    yield put(addSEContractItemError(error));
  }
}

function* editSEContract({ payload }) {
  try {
    const { item, history } = payload;
    if (history) {
      yield call(editSEContractRequest, item);
      history.push('/app/contract/se');
    } else {
      const { data, clientId, employerId, servicePlanHeaderId } = item;
      const query = {
        clientId: clientId ?? '',
        employerId: employerId ?? '',
        servicePlanHeaderId: servicePlanHeaderId ?? '',
      };
      yield call(editSEContractRequest, data);
      const response = yield call(getSEContractListRequest, query);
      yield put(editSEContractItemSuccess(response.data));
    }
  } catch (error) {
    yield put(editSEContractItemError(error));
  }
}

function* deleteSEContract({ payload }) {
  try {
    const { clientId, id, employerId, servicePlanHeaderId } = payload;
    const query = {
      clientId: clientId ?? '',
      employerId: employerId ?? '',
      servicePlanHeaderId: servicePlanHeaderId ?? '',
    };
    yield call(deleteSEContractRequest, id);
    const response = yield call(getSEContractListRequest, query);
    yield put(deleteSEContractItemSuccess(response.data));
  } catch (error) {
    yield put(deleteSEContractItemError(error));
  }
}

function* getSEContractReportListItems(payload) {
  try {
    const response = yield call(getSEContractReportListRequest, payload);
    yield put(getSEContractReportListSuccess(response.data));
  } catch (error) {
    yield put(getSEContractReportListError(error));
  }
}

export function* watchSEContactList() {
  yield takeEvery(SE_CONTRACT_GET_LIST, getSEContractList);
}

export function* watchSEContactGetById() {
  yield takeEvery(SE_CONTRACT_GET_BY_ID, getSEContractById);
}

export function* watchSEContractAddNew() {
  yield takeEvery(SE_CONTRACT_ADD_ITEM, addNewSEContract);
}
export function* watchSEContractEdit() {
  yield takeEvery(SE_CONTRACT_EDIT_ITEM, editSEContract);
}
export function* watchSEContractDelete() {
  yield takeEvery(SE_CONTRACT_DELETE_ITEM, deleteSEContract);
}

export function* watchGetList() {
  yield takeEvery(SE_CONTRACT_REPORT_GET_LIST, getSEContractReportListItems);
}

export default function* rootSaga() {
  yield all([
    fork(watchSEContactList),
    fork(watchSEContactGetById),
    fork(watchSEContractAddNew),
    fork(watchSEContractEdit),
    fork(watchSEContractDelete),
    fork(watchGetList),
  ]);
}

export { getSEContractsRequest };
