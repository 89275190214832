import {
  ES_CONTRACT_REPORT_GET_LIST,
  ES_CONTRACT_REPORT_GET_LIST_SUCCESS,
  ES_CONTRACT_REPORT_GET_LIST_ERROR,
  ES_CONTRACT_REPORT_GET_LIST_SEARCH,
} from '../../actions';

const INIT_STATE = {
  esContract: null,
  error: '',
  filter: null,
  searchKeyword: '',
  orderColumn: null,
  loading: false,

  selectedItems: [],
};

export default (state = INIT_STATE, action) => {
  const { payload, type } = action;
  switch (type) {
    case ES_CONTRACT_REPORT_GET_LIST:
      return { ...state, loading: true };

    case ES_CONTRACT_REPORT_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        esContract: payload,
        error: null,
      };

    case ES_CONTRACT_REPORT_GET_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case ES_CONTRACT_REPORT_GET_LIST_SEARCH:
      if (action.payload === '') {
        return { ...state, esContract: state.esContractReport };
      } else {
        const keyword = action.payload.toLowerCase();
        const searchItems = state.esContractReport.esContractReports.filter(
          item =>
            String(item.id).startsWith(keyword) ||
            String(item.company.toLowerCase()).startsWith(keyword) ||
            String(item.trainingStartDate).startsWith(keyword) ||
            String(item.contractTier).startsWith(keyword) ||
            String(item.caMs).startsWith(keyword) ||
            String(item.revisedInKind).startsWith(keyword) ||
            String(item.revisedContractAmount).startsWith(keyword)
        );
        return {
          ...state,
          loading: true,
          esContractReport: searchItems,
          searchKeyword: action.payload,
        };
      }
    default:
      return { ...state };
  }
};
