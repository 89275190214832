import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../../helpers/auth-header';
import Config from '../../../config';
import axios from 'axios';

import { COJG_FUND_GET_LIST } from '../../actions';

import { getCOJGFundListSuccess, getCOJGFundListError } from './actions';

const getCOJGFundListRequest = async filter => {
  return await axios.get(
    `${Config.apiServerHost}/api/contract/cojg/fund-report?fromDate=${filter.payload.fromDate}&toDate=${filter.payload.toDate}&fiscalYear=${filter.payload.fiscalYear}`,
    await authHeader()
  );
};

function* getCOJGFundListItems(payload) {
  try {
    const response = yield call(getCOJGFundListRequest, payload);
    yield put(getCOJGFundListSuccess(response.data));
  } catch (error) {
    yield put(getCOJGFundListError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(COJG_FUND_GET_LIST, getCOJGFundListItems);
}

export default function* rootSaga() {
  yield all([fork(watchGetList)]);
}
