import {
  STAKE_HOLDER_MEETING_NOTE_ADD_NEW,
  STAKE_HOLDER_MEETING_NOTE_ADD_NEW_ERROR,
  STAKE_HOLDER_MEETING_NOTE_ADD_NEW_SUCCESS,
  STAKE_HOLDER_MEETING_NOTE_CHANGE_STATUS,
  STAKE_HOLDER_MEETING_NOTE_CHANGE_STATUS_ERROR,
  STAKE_HOLDER_MEETING_NOTE_CHANGE_STATUS_SUCCESS,
  STAKE_HOLDER_MEETING_NOTE_DELETE,
  STAKE_HOLDER_MEETING_NOTE_DELETE_ERROR,
  STAKE_HOLDER_MEETING_NOTE_DELETE_SUCCESS,
  STAKE_HOLDER_MEETING_NOTE_GET_BY_ID,
  STAKE_HOLDER_MEETING_NOTE_GET_BY_ID_ERROR,
  STAKE_HOLDER_MEETING_NOTE_GET_BY_ID_SUCCESS,
  STAKE_HOLDER_MEETING_NOTE_GET_PAGING,
  STAKE_HOLDER_MEETING_NOTE_GET_PAGING_ERROR,
  STAKE_HOLDER_MEETING_NOTE_GET_PAGING_SUCCESS,
  STAKE_HOLDER_MEETING_NOTE_UPDATE,
  STAKE_HOLDER_MEETING_NOTE_UPDATE_ERROR,
  STAKE_HOLDER_MEETING_NOTE_UPDATE_SUCCESS,
} from './types';

// StakeHolderMeetingNote
export const getPagingStakeHolderMeetingNote = items => ({
  type: STAKE_HOLDER_MEETING_NOTE_GET_PAGING,
  payload: items,
});

export const getPagingStakeHolderMeetingNoteSuccess = items => ({
  type: STAKE_HOLDER_MEETING_NOTE_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingStakeHolderMeetingNoteError = error => ({
  type: STAKE_HOLDER_MEETING_NOTE_GET_PAGING_ERROR,
  payload: error,
});

export const addStakeHolderMeetingNote = items => ({
  type: STAKE_HOLDER_MEETING_NOTE_ADD_NEW,
  payload: items,
});

export const addStakeHolderMeetingNoteSuccess = items => ({
  type: STAKE_HOLDER_MEETING_NOTE_ADD_NEW_SUCCESS,
  payload: items,
});

export const addStakeHolderMeetingNoteError = error => ({
  type: STAKE_HOLDER_MEETING_NOTE_ADD_NEW_ERROR,
  payload: error,
});

export const getStakeHolderMeetingNoteById = items => ({
  type: STAKE_HOLDER_MEETING_NOTE_GET_BY_ID,
  payload: items,
});

export const getStakeHolderMeetingNoteByIdSuccess = items => ({
  type: STAKE_HOLDER_MEETING_NOTE_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getStakeHolderMeetingNoteByIdError = error => ({
  type: STAKE_HOLDER_MEETING_NOTE_GET_BY_ID_ERROR,
  payload: error,
});

export const updateStakeHolderMeetingNote = items => ({
  type: STAKE_HOLDER_MEETING_NOTE_UPDATE,
  payload: items,
});

export const updateStakeHolderMeetingNoteSuccess = items => ({
  type: STAKE_HOLDER_MEETING_NOTE_UPDATE_SUCCESS,
  payload: items,
});

export const updateStakeHolderMeetingNoteError = error => ({
  type: STAKE_HOLDER_MEETING_NOTE_UPDATE_ERROR,
  payload: error,
});

export const changeStatusStakeHolderMeetingNote = items => ({
  type: STAKE_HOLDER_MEETING_NOTE_CHANGE_STATUS,
  payload: items,
});

export const changeStatusStakeHolderMeetingNoteSuccess = items => ({
  type: STAKE_HOLDER_MEETING_NOTE_CHANGE_STATUS_SUCCESS,
  payload: items,
});

export const changeStatusStakeHolderMeetingNoteError = error => ({
  type: STAKE_HOLDER_MEETING_NOTE_CHANGE_STATUS_ERROR,
  payload: error,
});

export const deleteStakeHolderMeetingNote = items => ({
  type: STAKE_HOLDER_MEETING_NOTE_DELETE,
  payload: items,
});

export const deleteStakeHolderMeetingNoteSuccess = items => ({
  type: STAKE_HOLDER_MEETING_NOTE_DELETE_SUCCESS,
  payload: items,
});

export const deleteStakeHolderMeetingNoteError = error => ({
  type: STAKE_HOLDER_MEETING_NOTE_DELETE_ERROR,
  payload: error,
});
