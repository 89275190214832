import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { saveAs } from 'file-saver';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  STAKE_HOLDER_ADD_NEW,
  STAKE_HOLDER_CHANGE_STATUS,
  STAKE_HOLDER_DELETE,
  STAKE_HOLDER_GET_BY_ID,
  STAKE_HOLDER_GET_PAGING,
  STAKE_HOLDER_UPDATE,
} from './types';

import {
  addStakeHolderSuccess,
  addStakeHolderError,
  getPagingStakeHolderSuccess,
  getPagingStakeHolderError,
  getStakeHolderByIdSuccess,
  getStakeHolderByIdError,
  updateStakeHolderSuccess,
  updateStakeHolderError,
} from './actions';

const addStakeHolderRequest = async item => {
  return axios.post(`${Config.apiServerHost}/api/StakeHolder`, item, {
    headers: { ...(await authHeader()).headers },
  });
};

const updateStakeHolderRequest = async item => {
  return axios.put(`${Config.apiServerHost}/api/StakeHolder`, item, {
    headers: { ...(await authHeader()).headers },
  });
};

const getPagingStakeHolderRequest = async query => {
  return axios.post(`${Config.apiServerHost}/api/StakeHolder/paging`, query, {
    headers: { ...(await authHeader()).headers },
  });
};

const getPagingStakeHolderByIdsRequest = async query => {
  return axios.post(`${Config.apiServerHost}/api/StakeHolder/fetchByIds`, query, {
    headers: { ...(await authHeader()).headers },
  });
};

const getStakeHolderByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/StakeHolder/${id}`, {
    headers: { ...(await authHeader()).headers },
  });
};

const changeStatusStakeHolderRequest = async item => {
  return axios.get(`${Config.apiServerHost}/api/StakeHolder/statusChange`, {
    params: item,
    headers: { ...(await authHeader()).headers },
  });
};

const deleteStakeHolderRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/StakeHolder/${id}`, await authHeader());
};

const exportStakeHolderToExcelRequest = async (params, fileName) => {
  axios
    .get(`${Config.apiServerHost}/api/StakeHolder/exportToExcel`, {
      params,
      responseType: 'blob',
      headers: {
        ...(await authHeader()).headers,
      },
    })
    .then(res => {
      if (res.data) {
        saveAs(new Blob([res.data]), fileName);
      }
    });
};

const exportStakeHolderLastContactXls = async (payload, fileName) => {
  axios
    .post(`${Config.apiServerHost}/api/StakeHolder/lastContactAnalytic/export`, payload, {
      responseType: 'blob',
      headers: {
        ...(await authHeader()).headers,
      },
    })
    .then(res => {
      if (res.data) {
        saveAs(new Blob([res.data]), fileName);
      }
    });
};

function* addNewStakeHolder({ payload }) {
  try {
    const { data, stakeHolderType } = payload;
    let response = yield call(addStakeHolderRequest, data);
    yield put(addStakeHolderSuccess(response.data));

    response = yield call(getPagingStakeHolderRequest, { stakeHolderType });
    yield put(getPagingStakeHolderSuccess(response.data));
  } catch (error) {
    yield put(addStakeHolderError(error.response.statusText));
  }
}

function* updateStakeHolder({ payload }) {
  try {
    const { data, stakeHolderType } = payload;
    let response = yield call(updateStakeHolderRequest, data);
    yield put(updateStakeHolderSuccess(response.data));

    response = yield call(getPagingStakeHolderRequest, { stakeHolderType });
    yield put(getPagingStakeHolderSuccess(response.data));
  } catch (error) {
    yield put(updateStakeHolderError(error.response.statusText));
  }
}

function* getPagingStakeHolder({ payload }) {
  try {
    let response = yield call(getPagingStakeHolderRequest, payload);
    yield put(getPagingStakeHolderSuccess(response.data));
  } catch (error) {
    yield put(getPagingStakeHolderError(error.response.statusText));
  }
}

function* getStakeHolderById({ payload }) {
  try {
    let response = yield call(getStakeHolderByIdRequest, payload);
    yield put(getStakeHolderByIdSuccess(response.data));
  } catch (error) {
    yield put(getStakeHolderByIdError(error.response.statusText));
  }
}

function* changeStatusStakeHolder({ payload }) {
  try {
    const { data, stakeHolderType } = payload;
    let response = yield call(changeStatusStakeHolderRequest, data);

    response = yield call(getPagingStakeHolderRequest, { stakeHolderType });
    yield put(getPagingStakeHolderSuccess(response.data));
  } catch (error) {
    yield put(getPagingStakeHolderError(error.response.statusText));
  }
}

function* deleteStakeHolder({ payload }) {
  try {
    const { data, stakeHolderType } = payload;
    let response = yield call(deleteStakeHolderRequest, data);

    response = yield call(getPagingStakeHolderRequest, { stakeHolderType });
    yield put(getPagingStakeHolderSuccess(response.data));
  } catch (error) {
    yield put(getPagingStakeHolderError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(STAKE_HOLDER_ADD_NEW, addNewStakeHolder);
  yield takeEvery(STAKE_HOLDER_UPDATE, updateStakeHolder);
  yield takeEvery(STAKE_HOLDER_GET_PAGING, getPagingStakeHolder);
  yield takeEvery(STAKE_HOLDER_GET_BY_ID, getStakeHolderById);
  yield takeEvery(STAKE_HOLDER_CHANGE_STATUS, changeStatusStakeHolder);
  yield takeEvery(STAKE_HOLDER_DELETE, deleteStakeHolder);
}

export {
  exportStakeHolderToExcelRequest,
  getPagingStakeHolderByIdsRequest,
  exportStakeHolderLastContactXls,
};
