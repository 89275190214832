import {
  ROLE_GET_LIST,
  ROLE_GET_LIST_SUCCESS,
  ROLE_GET_LIST_ERROR,
  ROLE_GET_LIST_SEARCH,
  ROLE_GET_BY_ID,
  ROLE_GET_BY_ID_SUCCESS,
  ROLE_GET_BY_ID_ERROR,
  ROLE_ADD_OR_UPDATE_ITEM,
  ROLE_ADD_OR_UPDATE_ITEM_SUCCESS,
  ROLE_ADD_OR_UPDATE_ITEM_ERROR,
  ROLE_DELETE_ITEM,
  ROLE_DELETE_ITEM_SUCCESS,
  ROLE_DELETE_ITEM_ERROR,
  CLOSE_ROLE_ERROR_NOTIFICATION,
  GET_PERMISSION_LIST,
  GET_PERMISSION_LIST_SUCCESS,
  GET_PERMISSION_LIST_ERROR,
} from '../actions';

export const getRoleList = option => ({
  type: ROLE_GET_LIST,
  payload: option,
});

export const getRoleListSuccess = items => ({
  type: ROLE_GET_LIST_SUCCESS,
  payload: items,
});

export const getRoleListError = error => ({
  type: ROLE_GET_LIST_ERROR,
  payload: error,
});

export const getRoleById = id => ({
  type: ROLE_GET_BY_ID,
  payload: id,
});

export const getRoleByIdSuccess = data => ({
  type: ROLE_GET_BY_ID_SUCCESS,
  payload: data,
});

export const getRoleByIdError = error => ({
  type: ROLE_GET_BY_ID_ERROR,
  payload: error,
});

export const getRoleListSearch = keyword => ({
  type: ROLE_GET_LIST_SEARCH,
  payload: keyword,
});

export const AddorUpdateRoleItem = (item, history) => ({
  type: ROLE_ADD_OR_UPDATE_ITEM,
  payload: { item, history },
});

export const AddorUpdateRoleItemSuccess = items => ({
  type: ROLE_ADD_OR_UPDATE_ITEM_SUCCESS,
  payload: items,
});

export const AddorUpdateRoleItemError = error => ({
  type: ROLE_ADD_OR_UPDATE_ITEM_ERROR,
  payload: error,
});

export const deleteRoleItem = item => ({
  type: ROLE_DELETE_ITEM,
  payload: item,
});

export const deleteRoleItemSuccess = items => ({
  type: ROLE_DELETE_ITEM_SUCCESS,
  payload: items,
});

export const deleteRoleItemError = error => ({
  type: ROLE_DELETE_ITEM_ERROR,
  payload: error,
});

export const closeRoleErrorNotification = () => ({
  type: CLOSE_ROLE_ERROR_NOTIFICATION,
});

export const getPermissionList = () => ({
  type: GET_PERMISSION_LIST,
});

export const getPermissionListSuccess = permissions => ({
  type: GET_PERMISSION_LIST_SUCCESS,
  payload: permissions,
});

export const getPermissionListError = error => ({
  type: GET_PERMISSION_LIST_ERROR,
  payload: error,
});
