// ESUITE
export const ESUITE_GET_BY_ID = 'ESUITE_GET_BY_ID';
export const ESUITE_GET_BY_ID_SUCCESS = 'ESUITE_GET_BY_ID_SUCCESS';
export const ESUITE_GET_BY_ID_ERROR = 'ESUITE_GET_BY_ID_ERROR';

export const ESUITE_GET_LIST = 'ESUITE_GET_LIST';
export const ESUITE_GET_LIST_SUCCESS = 'ESUITE_GET_LIST_SUCCESS';
export const ESUITE_GET_LIST_ERROR = 'ESUITE_GET_LIST_ERROR';

export const ESUITE_EDIT = 'ESUITE_EDIT';
export const ESUITE_EDIT_SUCCESS = 'ESUITE_EDIT_SUCCESS';
export const ESUITE_EDIT_ERROR = 'ESUITE_EDIT_ERROR';

export const ESUITE_ADD_NEW = 'ESUITE_ADD_NEW';
export const ESUITE_ADD_NEW_SUCCESS = 'ESUITE_ADD_NEW_SUCCESS';
export const ESUITE_ADD_NEW_ERROR = 'ESUITE_ADD_NEW_ERROR';
export const ESUITE_ADD_NEW_REQUEST = 'ESUITE_ADD_NEW_REQUEST';

export const ESUITE_DELETE = 'ESUITE_DELETE';
export const ESUITE_DELETE_SUCCESS = 'ESUITE_DELETE_SUCCESS';
export const ESUITE_DELETE_ERROR = 'ESUITE_DELETE_ERROR';

export const ESUITE_GET_BY_QUERY = 'ESUITE_GET_BY_QUERY';
export const ESUITE_GET_BY_QUERY_SUCCESS = 'ESUITE_GET_BY_QUERY_SUCCESS';
export const ESUITE_GET_BY_QUERY_ERROR = 'ESUITE_GET_BY_QUERY_ERROR';
