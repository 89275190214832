import {
  COJG_CASE_NOTE_ADD_NEW,
  COJG_CASE_NOTE_ADD_NEW_SUCCESS,
  COJG_CASE_NOTE_ADD_NEW_ERROR,
  COJG_CASE_NOTE_DELETE,
  COJG_CASE_NOTE_DELETE_ERROR,
  COJG_CASE_NOTE_DELETE_SUCCESS,
  COJG_CASE_NOTE_EDIT,
  COJG_CASE_NOTE_EDIT_ERROR,
  COJG_CASE_NOTE_EDIT_SUCCESS,
  COJG_CASE_NOTE_GET_BY_ID,
  COJG_CASE_NOTE_GET_BY_ID_ERROR,
  COJG_CASE_NOTE_GET_BY_ID_SUCCESS,
  COJG_CASE_NOTE_GET_LIST,
  COJG_CASE_NOTE_GET_LIST_ERROR,
  COJG_CASE_NOTE_GET_LIST_SUCCESS,
  COJG_CASE_NOTE_ADD_NEW_REQUEST,
  COJG_CASE_NOTE_QUERY,
  COJG_CASE_NOTE_QUERY_SUCCESS,
  COJG_CASE_NOTE_QUERY_ERROR,
} from '../actions';

// COJGCaseNote
export const getCOJGCaseNoteQuery = query => ({
  type: COJG_CASE_NOTE_QUERY,
  payload: query,
});

export const getCOJGCaseNoteQuerySuccess = caseNotes => ({
  type: COJG_CASE_NOTE_QUERY_SUCCESS,
  payload: caseNotes,
});

export const getCOJGCaseNoteQueryError = error => ({
  type: COJG_CASE_NOTE_QUERY_ERROR,
  payload: error,
});

export const getCOJGCaseNoteList = clientId => ({
  type: COJG_CASE_NOTE_GET_LIST,
  payload: clientId,
});

export const getCOJGCaseNoteListSuccess = CaseNotes => ({
  type: COJG_CASE_NOTE_GET_LIST_SUCCESS,
  payload: CaseNotes,
});

export const getCOJGCaseNoteListError = error => ({
  type: COJG_CASE_NOTE_GET_LIST_ERROR,
  payload: error,
});

export const addNewCOJGCaseNote = caseNote => ({
  type: COJG_CASE_NOTE_ADD_NEW,
  payload: caseNote,
});

export const addNewCOJGCaseNoteRequest = () => ({
  type: COJG_CASE_NOTE_ADD_NEW_REQUEST,
});

export const addNewCOJGCaseNoteSuccess = CaseNote => ({
  type: COJG_CASE_NOTE_ADD_NEW_SUCCESS,
  payload: CaseNote,
});

export const addNewCOJGCaseNoteError = error => ({
  type: COJG_CASE_NOTE_ADD_NEW_ERROR,
  payload: error,
});

export const editCOJGCaseNote = CaseNote => ({
  type: COJG_CASE_NOTE_EDIT,
  payload: CaseNote,
});

export const editCOJGCaseNoteSuccess = caseNote => ({
  type: COJG_CASE_NOTE_EDIT_SUCCESS,
  payload: caseNote,
});

export const editCOJGCaseNoteError = error => ({
  type: COJG_CASE_NOTE_EDIT_ERROR,
  payload: error,
});

export const deleteCOJGCaseNote = id => ({
  type: COJG_CASE_NOTE_DELETE,
  payload: id,
});

export const deleteCOJGCaseNoteSuccess = id => ({
  type: COJG_CASE_NOTE_DELETE_SUCCESS,
  payload: id,
});

export const deleteCOJGCaseNoteError = error => ({
  type: COJG_CASE_NOTE_DELETE_ERROR,
  payload: error,
});

export const getCOJGCaseNoteById = id => ({
  type: COJG_CASE_NOTE_GET_BY_ID,
  payload: id,
});

export const getCOJGCaseNoteByIdSuccess = caseNote => ({
  type: COJG_CASE_NOTE_GET_BY_ID_SUCCESS,
  payload: caseNote,
});

export const getCOJGCaseNoteByIdError = error => ({
  type: COJG_CASE_NOTE_GET_BY_ID_ERROR,
  payload: error,
});
