import {
  BUDGET_TRACKING_GET_LIST,
  BUDGET_TRACKING_GET_LIST_ERROR,
  BUDGET_TRACKING_GET_LIST_SUCCESS,
  BUDGET_TRACKING_ADD_CLIENT_SUPPORT,
  BUDGET_TRACKING_ADD_PLACEMENT,
  BUDGET_TRACKING_GET_CLIENT_SUPPORT_BY_ID,
  BUDGET_TRACKING_GET_PLACEMENT_BY_ID,
  BUDGET_TRACKING_GET_CLIENT_SUPPORT_BY_ID_SUCCESS,
  BUDGET_TRACKING_GET_PLACEMENT_BY_ID_SUCCESS,
  BUDGET_TRACKING_EDIT_CLIENT_SUPPORT,
  BUDGET_TRACKING_EDIT_PLACEMENT,
  BUDGET_TRACKING_DELETE_CLIENT_SUPPORT,
  BUDGET_TRACKING_DELETE_PLACEMENT,
} from './types';

// BudgetTracking

export const getBudgetTrackingList = query => ({
  type: BUDGET_TRACKING_GET_LIST,
  payload: query,
});

export const getBudgetTrackingListSuccess = BudgetTrackings => ({
  type: BUDGET_TRACKING_GET_LIST_SUCCESS,
  payload: BudgetTrackings,
});

export const getBudgetTrackingListError = error => ({
  type: BUDGET_TRACKING_GET_LIST_ERROR,
  payload: error,
});

export const addBudgetTrackingClientSupport = payload => ({
  type: BUDGET_TRACKING_ADD_CLIENT_SUPPORT,
  payload,
});

export const addBudgetTrackingPlacement = payload => ({
  type: BUDGET_TRACKING_ADD_PLACEMENT,
  payload,
});

export const getBudgetTrackingClientSupportById = payload => ({
  type: BUDGET_TRACKING_GET_CLIENT_SUPPORT_BY_ID,
  payload,
});

export const getBudgetTrackingPlacementById = payload => ({
  type: BUDGET_TRACKING_GET_PLACEMENT_BY_ID,
  payload,
});

export const getBudgetTrackingClientSupportByIdSuccess = payload => ({
  type: BUDGET_TRACKING_GET_CLIENT_SUPPORT_BY_ID_SUCCESS,
  payload,
});

export const getBudgetTrackingPlacementByIdSuccess = payload => ({
  type: BUDGET_TRACKING_GET_PLACEMENT_BY_ID_SUCCESS,
  payload,
});

export const editBudgetTrackingClientSupport = payload => ({
  type: BUDGET_TRACKING_EDIT_CLIENT_SUPPORT,
  payload,
});

export const editBudgetTrackingPlacement = payload => ({
  type: BUDGET_TRACKING_EDIT_PLACEMENT,
  payload,
});

export const deleteBudgetTrackingClientSupport = payload => ({
  type: BUDGET_TRACKING_DELETE_CLIENT_SUPPORT,
  payload,
});

export const deleteBudgetTrackingPlacement = payload => ({
  type: BUDGET_TRACKING_DELETE_PLACEMENT,
  payload,
});
