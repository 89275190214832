import {
  MILEAGE_ADD_NEW,
  MILEAGE_ADD_NEW_SUCCESS,
  MILEAGE_ADD_NEW_ERROR,
  MILEAGE_DELETE,
  MILEAGE_DELETE_ERROR,
  MILEAGE_DELETE_SUCCESS,
  MILEAGE_EDIT,
  MILEAGE_EDIT_ERROR,
  MILEAGE_EDIT_SUCCESS,
  MILEAGE_GET_BY_ID,
  MILEAGE_GET_BY_ID_ERROR,
  MILEAGE_GET_BY_ID_SUCCESS,
  MILEAGE_GET_LIST,
  MILEAGE_GET_LIST_SUCCESS,
  MILEAGE_GET_LIST_ERROR,
  MILEAGE_GET_LIST_BY_USER_ID,
  MILEAGE_GET_LIST_BY_USER_ID_SUCCESS,
  MILEAGE_GET_LIST_BY_USER_ID_ERROR,
  MILEAGE_GET_LIST_BY_STATUS,
  MILEAGE_GET_LIST_BY_STATUS_SUCCESS,
  MILEAGE_GET_LIST_BY_STATUS_ERROR,
  MILEAGE_SUBMIT,
  MILEAGE_SUBMIT_SUCCESS,
  MILEAGE_SUBMIT_ERROR,
  MILEAGE_APPROVE_SUBMIT,
  MILEAGE_APPROVE_SUBMIT_SUCCESS,
  MILEAGE_APPROVE_SUBMIT_ERROR,
  MILEAGE_ADD_NEW_REQUEST,
  MILEAGE_GET_BY_QUERY,
  MILEAGE_GET_BY_QUERY_SUCCESS,
  MILEAGE_GET_BY_QUERY_ERROR,
  MILEAGE_PREVIOUS_SUBMISSION,
  MILEAGE_PREVIOUS_SUBMISSION_SUCCESS,
  MILEAGE_PREVIOUS_SUBMISSION_ERROR,
  MILEAGE_PAID_OR_UNPAID,
  MILEAGE_PAID_OR_UNPAID_SUCCESS,
  MILEAGE_PAID_OR_UNPAID_ERROR,
  MILEAGE_REJECT_SUBMIT,
  MILEAGE_REJECT_SUBMIT_SUCCESS,
  MILEAGE_REJECT_SUBMIT_ERROR,
} from '../actions';

const initialState = {
  myMileages: [],
  userMileages: [],
  mileageReports: [],
  prevAssigned: null,
  mileages: [],
  mileage: null,
  loading: false,
  error: null,
  addSuccess: false,
  editSuccess: false,
  itemCount: 0,
  pageCount: 0,
  deleteSuccess: false,
  userId: 0,
  role: '',
};

const mileage = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case MILEAGE_PREVIOUS_SUBMISSION:
    case MILEAGE_SUBMIT:
    case MILEAGE_APPROVE_SUBMIT:
    case MILEAGE_REJECT_SUBMIT:
    case MILEAGE_GET_BY_ID:
    case MILEAGE_GET_LIST_BY_STATUS:
    case MILEAGE_GET_BY_QUERY:
    case MILEAGE_PAID_OR_UNPAID:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case MILEAGE_PREVIOUS_SUBMISSION_SUCCESS:
      return {
        ...state,
        prevAssigned: payload,
      };

    case MILEAGE_GET_LIST_BY_STATUS_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        loading: false,
        mileageReports: data,
        itemCount,
        pageCount,
      };

    case MILEAGE_PAID_OR_UNPAID_SUCCESS:
    case MILEAGE_APPROVE_SUBMIT_SUCCESS:
    case MILEAGE_REJECT_SUBMIT_SUCCESS:
    case MILEAGE_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        mileage: payload,
        mileageReports: state.mileageReports.map(a => {
          return a.id === payload.id ? payload : a;
        }),
      };

    case MILEAGE_ADD_NEW_REQUEST:
      return {
        ...state,
        addSuccess: false,
      };

    case MILEAGE_GET_LIST:
    case MILEAGE_GET_LIST_BY_USER_ID:
      let { role, userId } = payload;
      return {
        ...state,
        error: null,
        userId: userId,
        role: role,
        loading: true,
      };

    case MILEAGE_GET_BY_QUERY_SUCCESS:
    case MILEAGE_GET_LIST_SUCCESS:
    case MILEAGE_GET_LIST_BY_USER_ID_SUCCESS:
      return {
        ...state,
        mileages: payload,
        myMileages: payload.filter(a => a.userId === Number(state.userId)),
        userMileages:
          state.role === 'Accounting Administrator'
            ? payload.filter(a => a.userId !== Number(state.userId))
            : payload.filter(a => a.assignTo === Number(state.userId)),
        loading: false,
      };

    case MILEAGE_ADD_NEW:
      return {
        ...state,
        loading: true,
        error: null,
        addSuccess: false,
      };

    case MILEAGE_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        mileage: payload,
        mileages: state.mileages.concat(payload),
        myMileages: state.myMileages.concat(payload),
        addSuccess: true,
      };

    case MILEAGE_EDIT:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };

    case MILEAGE_SUBMIT_SUCCESS:
    case MILEAGE_EDIT_SUCCESS:
      return {
        ...state,
        mileages: state.mileages.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        myMileages: state.myMileages.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        userMileages: state.userMileages.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        mileage: payload,
        error: null,
        loading: false,
        editSuccess: true,
      };

    case MILEAGE_DELETE:
      return {
        ...state,
        loading: true,
        error: null,
        deleteSuccess: false,
      };
    case MILEAGE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        mileages: state.mileages.filter(c => c.id !== payload),
        myMileages: state.myMileages.filter(c => c.id !== payload),
        userMileages: state.userMileages.filter(c => c.id !== payload),
        deleteSuccess: true,
      };

    case MILEAGE_GET_BY_QUERY_ERROR:
    case MILEAGE_DELETE_ERROR:
    case MILEAGE_ADD_NEW_ERROR:
    case MILEAGE_EDIT_ERROR:
    case MILEAGE_GET_BY_ID_ERROR:
    case MILEAGE_GET_LIST_BY_USER_ID_ERROR:
    case MILEAGE_GET_LIST_ERROR:
    case MILEAGE_SUBMIT_ERROR:
    case MILEAGE_APPROVE_SUBMIT_ERROR:
    case MILEAGE_GET_LIST_BY_STATUS_ERROR:
    case MILEAGE_PREVIOUS_SUBMISSION_ERROR:
    case MILEAGE_PAID_OR_UNPAID_ERROR:
    case MILEAGE_REJECT_SUBMIT_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};

export default mileage;
