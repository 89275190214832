import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../../helpers/auth-header';
import axios from 'axios';
import Config from '../../../config';
import { buildUrl } from '../../../helpers/Utils';

import { ES_CONTRACT_REPORT_GET_LIST } from '../../actions';

import { getESContractReportListSuccess, getESContractReportListError } from './actions';

const getESContractReportListRequest = async query => {
  let url = `${Config.apiServerHost}/api/ESContract/report`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

function* getESContractReportListItems({ payload }) {
  try {
    const response = yield call(getESContractReportListRequest, payload);
    yield put(getESContractReportListSuccess(response.data));
  } catch (error) {
    yield put(getESContractReportListError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(ES_CONTRACT_REPORT_GET_LIST, getESContractReportListItems);
}

export default function* rootSaga() {
  yield all([fork(watchGetList)]);
}
