import {
  GET_CUSTOMER_SATISFACTION_REPORT,
  GET_CUSTOMER_SATISFACTION_REPORT_SUCCESS,
  GET_CUSTOMER_SATISFACTION_REPORT_ERROR,
  GET_CLIENT_OUTCOMES_REPORT,
  GET_CLIENT_OUTCOMES_REPORT_SUCCESS,
  GET_CLIENT_OUTCOMES_REPORT_ERROR,
  GET_CLIENT_VOLUMES_REPORT,
  GET_CLIENT_VOLUMES_REPORT_ERROR,
  GET_CLIENT_VOLUMES_REPORT_SUCCESS,
  SAVE_TARGET_CLIENT_VOLUMES_REPORT,
  SAVE_TARGET_CLIENT_OUTCOMES_REPORT,
  SAVE_TARGET_CUSTOMER_SATISFACTION_REPORT,
} from './types';

const INIT_STATE = {
  customerSatisfactionReport: null,
  error: '',
  filter: null,
  searchKeyword: '',
  orderColumn: null,
  loading: false,
};

export default (state = INIT_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_CLIENT_OUTCOMES_REPORT:
    case GET_CLIENT_VOLUMES_REPORT:
    case GET_CUSTOMER_SATISFACTION_REPORT:
    case SAVE_TARGET_CLIENT_VOLUMES_REPORT:
    case SAVE_TARGET_CLIENT_OUTCOMES_REPORT:
    case SAVE_TARGET_CUSTOMER_SATISFACTION_REPORT:
      return { ...state, loading: true };

    case GET_CLIENT_VOLUMES_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        clientVolumesReport: payload,
        error: null,
      };

    case GET_CLIENT_OUTCOMES_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        clientOutcomesReport: payload,
        error: null,
      };

    case GET_CUSTOMER_SATISFACTION_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        customerSatisfactionReport: payload,
        error: null,
      };

    case GET_CLIENT_VOLUMES_REPORT_ERROR:
    case GET_CLIENT_OUTCOMES_REPORT_ERROR:
    case GET_CUSTOMER_SATISFACTION_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return { ...state };
  }
};
