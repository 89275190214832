import {
  RESUME_ADD_NEW,
  RESUME_ADD_NEW_SUCCESS,
  RESUME_ADD_NEW_ERROR,
  RESUME_ADD_NEW_REQUEST,
  RESUME_DELETE,
  RESUME_DELETE_ERROR,
  RESUME_DELETE_SUCCESS,
  RESUME_GET_BY_CLIENT_ID,
  RESUME_GET_BY_CLIENT_ID_ERROR,
  RESUME_GET_BY_CLIENT_ID_SUCCESS,
} from '../actions';

const initialState = {
  resumes: [],
  resume: [],
  loading: false,
  error: null,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RESUME_GET_BY_CLIENT_ID:
      return {
        ...state,
        loading: false,
        error: null,
        editSuccess: false,
      };
    case RESUME_GET_BY_CLIENT_ID_SUCCESS:
      return {
        ...state,
        loading: true,
        resumes: payload,
      };

    case RESUME_ADD_NEW_REQUEST:
      return {
        ...state,
        addSuccess: false,
      };
    case RESUME_ADD_NEW:
      return {
        ...state,
        loading: false,
        error: null,
        addSuccess: false,
      };

    case RESUME_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: true,
        resume: payload,
        resumes: state.resumes.concat(payload),
        addSuccess: true,
      };

    case RESUME_DELETE:
      return {
        ...state,
        loading: false,
        error: null,
        deleteSuccess: false,
      };
    case RESUME_DELETE_SUCCESS:
      return {
        ...state,
        loading: true,
        resumes: state.resumes.filter(c => c.id !== payload),
        deleteSuccess: true,
      };

    case RESUME_DELETE_ERROR:
    case RESUME_ADD_NEW_ERROR:
    case RESUME_GET_BY_CLIENT_ID_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
