import {
  OUTCOME_QUESTIONAIRE_GET_BY_ID,
  OUTCOME_QUESTIONAIRE_GET_BY_ID_ERROR,
  OUTCOME_QUESTIONAIRE_GET_BY_ID_SUCCESS,
  OUTCOME_QUESTIONAIRE_UPDATE,
  OUTCOME_QUESTIONAIRE_UPDATE_SUCCESS,
  OUTCOME_QUESTIONAIRE_UPDATE_ERROR,
  OUTCOME_QUESTIONAIRE_SYNC,
  OUTCOME_QUESTIONAIRE_SYNC_SUCCESS,
  OUTCOME_QUESTIONAIRE_SYNC_ERROR,
  OUTCOME_QUESTIONAIRE_CLEAR_NOTIFIED,
} from './types';

// item
const initialState = {
  item: null,
  items: [],
  loading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
  total: 0,
  message: null,
  syncLoading: false,
  lastUpdate: null,
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case OUTCOME_QUESTIONAIRE_GET_BY_ID:
    case OUTCOME_QUESTIONAIRE_UPDATE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case OUTCOME_QUESTIONAIRE_SYNC:
      return {
        ...state,
        loading: false,
        error: null,
        syncLoading: true,
      };
    case OUTCOME_QUESTIONAIRE_SYNC_SUCCESS:
    case OUTCOME_QUESTIONAIRE_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        items: payload,
        syncLoading: false,
        lastUpdate: new Date(),
      };
    case OUTCOME_QUESTIONAIRE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
        syncLoading: false,
        lastUpdate: new Date(),
      };
    case OUTCOME_QUESTIONAIRE_GET_BY_ID_ERROR:
    case OUTCOME_QUESTIONAIRE_SYNC_ERROR:
      return {
        ...state,
        loading: false,
        syncLoading: false,
        error: payload,
      };
    case OUTCOME_QUESTIONAIRE_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        syncLoading: false,
        error: payload,
        items: payload.items,
      };
    case OUTCOME_QUESTIONAIRE_CLEAR_NOTIFIED:
      return {
        ...state,
        message: null,
        error: null,
        loading: false,
        syncLoading: false,
      };
    default:
      return { ...state };
  }
}
