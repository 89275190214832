import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  POSTING_MATCHING_SENT_TO_CLIENT_GET_BY_ID,
  POSTING_MATCHING_SENT_TO_CLIENT_GET_LIST,
} from './types';
import Config from '../../config';

import {
  getPostingMatchingSentToClientListSuccess,
  getPostingMatchingSentToClientByIdSuccess,
  postingMatchingSentToClientError,
} from './actions';

// PostingMatchingSentToClient

const getPostingMatchingSentToClientListByClientIdRequest = async payload => {
  return axios.post(
    `${Config.apiServerHost}/api/Client/clientJobMatching`,
    payload,
    await authHeader()
  );
};

const getPostingMatchingSentToClientByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/Posting/${id}`, await authHeader());
};

function* getPostingMatchingSentToClientList({ payload }) {
  try {
    const response = yield call(getPostingMatchingSentToClientListByClientIdRequest, payload);
    yield put(getPostingMatchingSentToClientListSuccess(response.data));
  } catch (error) {
    yield put(postingMatchingSentToClientError(error.response.statusText));
  }
}

function* getPostingMatchingSentToClientById({ payload }) {
  try {
    const response = yield call(getPostingMatchingSentToClientByIdRequest, payload);
    yield put(getPostingMatchingSentToClientByIdSuccess(response.data));
  } catch (error) {
    yield put(postingMatchingSentToClientError(error.response.statusText));
  }
}

export function* watchPostingMatchingSentToClientList() {
  yield takeEvery(POSTING_MATCHING_SENT_TO_CLIENT_GET_LIST, getPostingMatchingSentToClientList);
}

export function* watchPostingMatchingSentToClientById() {
  yield takeEvery(POSTING_MATCHING_SENT_TO_CLIENT_GET_BY_ID, getPostingMatchingSentToClientById);
}

export default function* rootSaga() {
  yield all([
    fork(watchPostingMatchingSentToClientList),
    fork(watchPostingMatchingSentToClientById),
  ]);
}

export { getPostingMatchingSentToClientListByClientIdRequest };
