// JOB_MATCHING
export const JOB_MATCHING_GET_LIST = 'JOB_MATCHING_GET_LIST';
export const JOB_MATCHING_GET_LIST_SUCCESS = 'JOB_MATCHING_GET_LIST_SUCCESS';
export const JOB_MATCHING_GET_BY_ID = 'JOB_MATCHING_GET_BY_ID';
export const JOB_MATCHING_GET_BY_ID_SUCCESS = 'JOB_MATCHING_GET_BY_ID_SUCCESS';

export const JOB_MATCHING_ADD_NEW = 'JOB_MATCHING_ADD_NEW';
export const JOB_MATCHING_ADD_NEW_REQUEST = 'JOB_MATCHING_ADD_NEW_REQUEST';

export const JOB_MATCHING_STATUS_CHANGE = 'JOB_MATCHING_STATUS_CHANGE';
export const JOB_MATCHING_STATUS_CHANGE_SUCCESS = 'JOB_MATCHING_STATUS_CHANGE_SUCCESS';
export const JOB_MATCHING_RESULT_CHANGE = 'JOB_MATCHING_RESULT_CHANGE';
export const JOB_MATCHING_EDIT = 'JOB_MATCHING_EDIT';
export const JOB_MATCHING_DELETE = 'JOB_MATCHING_DELETE';
export const JOB_MATCHING_ERROR = 'JOB_MATCHING_ERROR';
