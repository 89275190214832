import axios from 'axios';
import { authHeader, handleUnauthorized } from '../../helpers/auth-header';
import Config from '../../config';
import { buildUrl } from '../../helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  MILEAGE_DELETE,
  MILEAGE_EDIT,
  MILEAGE_GET_BY_ID,
  MILEAGE_ADD_NEW,
  MILEAGE_GET_LIST,
  MILEAGE_GET_LIST_BY_USER_ID,
  MILEAGE_GET_LIST_BY_STATUS,
  MILEAGE_SUBMIT,
  MILEAGE_APPROVE_SUBMIT,
  MILEAGE_GET_BY_QUERY,
  MILEAGE_PREVIOUS_SUBMISSION,
  MILEAGE_PAID_OR_UNPAID,
  MILEAGE_REJECT_SUBMIT,
} from '../actions';

import {
  getMileageListByUserIdSuccess,
  getMileageListByUserIdError,
  editMileageSuccess,
  editMileageError,
  addNewMileageSuccess,
  addNewMileageError,
  deleteMileageSuccess,
  deleteMileageError,
  getMileageByIdSuccess,
  getMileageListSuccess,
  getMileageListError,
  submitMileageSuccess,
  submitMileageError,
  submitApproveMileageSuccess,
  getMileageListByStatusSuccess,
  getMileageListByStatusError,
  submitApproveMileageError,
  getMileagesByQuerySuccess,
  getMileagesByQueryError,
  getPreviousSubmissionSuccess,
  getPreviousSubmissionError,
  mileagePaidOrUnpaidSubmissionSuccess,
  mileagePaidOrUnpaidSubmissionError,
  submitRejectMileageSuccess,
  submitRejectMileageError,
} from './actions';
const getMileagesByQueryRequest = async query => {
  let url = `${Config.apiServerHost}/api/Mileage/search`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getMileageListRequest = async () => {
  return axios.get(`${Config.apiServerHost}/api/Mileage`, await authHeader());
};

const getMileageListByStatusRequest = async payload => {
  let url = `${Config.apiServerHost}/api/Mileage/fetch`;
  return axios.get(buildUrl(url, payload), await authHeader());
};

const getMileageListByUserIdRequest = async userId => {
  return axios.get(
    `${Config.apiServerHost}/api/Mileage/fetch-by-user/${userId}`,
    await authHeader()
  );
};

const addNewMileageReqeust = async mileage => {
  return axios.post(`${Config.apiServerHost}/api/Mileage`, mileage, await authHeader());
};

const editMileageRequest = async mileage => {
  return axios.put(
    `${Config.apiServerHost}/api/Mileage/${mileage.id}`,
    mileage,
    await authHeader()
  );
};

const deleteMileageRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/Mileage/${id}`, await authHeader());
};

const getMileageByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/Mileage/${id}`, await authHeader());
};

const submitMileageRequest = async data => {
  return axios.put(
    `${Config.apiServerHost}/api/Mileage/${data.id}/submit`,
    data.assignedTo,
    await authHeader()
  );
};

const submitApproveMileageRequest = async data => {
  return axios.put(
    `${Config.apiServerHost}/api/Mileage/${data.id}/approve`,
    data.approvedBy,
    await authHeader()
  );
};

const submitRejectMileageRequest = async data => {
  return axios.put(
    `${Config.apiServerHost}/api/Mileage/${data.id}/reject`,
    data.approvedBy,
    await authHeader()
  );
};

const getPreviousSubmissionRequest = async id => {
  return axios.get(
    `${Config.apiServerHost}/api/Mileage/${id}/previous-submited`,
    await authHeader()
  );
};

const paidOrUnpaidSubmissionRequest = async data => {
  return axios.get(
    `${Config.apiServerHost}/api/Mileage/${data.id}/paid-or-unpaid/${data.paid}`,
    await authHeader()
  );
};

function* getMileagesByQuery({ payload }) {
  try {
    const response = yield call(getMileagesByQueryRequest, payload);

    yield put(getMileagesByQuerySuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(getMileagesByQueryError(error));
  }
}

function* getMileageList() {
  try {
    const response = yield call(getMileageListRequest);

    yield put(getMileageListSuccess(response.data.data));
  } catch (err) {
    const { response } = err;
    const { statusText, data } = response;
    handleUnauthorized(response);
    const error = {
      title: statusText,
      message: data,
    };
    yield put(getMileageListError(error));
  }
}

function* getMileageListByStatus({ payload }) {
  try {
    const response = yield call(getMileageListByStatusRequest, payload);
    yield put(getMileageListByStatusSuccess(response.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(getMileageListByStatusError(error));
  }
}

function* getMileageListByUserId({ payload }) {
  try {
    const response = yield call(getMileageListByUserIdRequest, payload);

    yield put(getMileageListByUserIdSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(getMileageListByUserIdError(error));
  }
}

function* addNewMileage({ payload }) {
  try {
    const { mileage } = payload;
    let response = yield call(addNewMileageReqeust, mileage);
    response = yield call(getMileageByIdRequest, response.data.data);
    yield put(addNewMileageSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(addNewMileageError(error));
  }
}

function* editMileage({ payload }) {
  try {
    const { mileage } = payload;
    let response = yield call(editMileageRequest, mileage);
    response = yield call(getMileageByIdRequest, mileage.id);
    yield put(editMileageSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(editMileageError(error));
  }
}

function* getMileageById({ payload }) {
  try {
    const response = yield call(getMileageByIdRequest, payload);
    yield put(getMileageByIdSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(editMileageError(error));
  }
}

function* submitMileage({ payload }) {
  try {
    let response = yield call(submitMileageRequest, payload);
    response = yield call(getMileageByIdRequest, payload.id);
    yield put(submitMileageSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(submitMileageError(error));
  }
}

function* submitApproveMileage({ payload }) {
  try {
    let response = yield call(submitApproveMileageRequest, payload);
    response = yield call(getMileageByIdRequest, payload.id);
    yield put(submitApproveMileageSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(submitApproveMileageError(error));
  }
}

function* submitRejectMileage({ payload }) {
  try {
    let response = yield call(submitRejectMileageRequest, payload);
    response = yield call(getMileageByIdRequest, payload.id);
    yield put(submitRejectMileageSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(submitRejectMileageError(error));
  }
}

function* paidOrUnpaidSubmission({ payload }) {
  try {
    let response = yield call(paidOrUnpaidSubmissionRequest, payload);
    response = yield call(getMileageByIdRequest, payload.id);
    yield put(mileagePaidOrUnpaidSubmissionSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(mileagePaidOrUnpaidSubmissionError(error));
  }
}

function* deleteMileage({ payload }) {
  try {
    const response = yield call(deleteMileageRequest, payload);
    yield put(deleteMileageSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(deleteMileageError(error));
  }
}

function* getPreviousSubmission({ payload }) {
  try {
    const response = yield call(getPreviousSubmissionRequest, payload);
    yield put(getPreviousSubmissionSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(getPreviousSubmissionError(error));
  }
}

export function* watchMileagesByQuery() {
  yield takeEvery(MILEAGE_GET_BY_QUERY, getMileagesByQuery);
}

export function* watchMileageList() {
  yield takeEvery(MILEAGE_GET_LIST, getMileageList);
}

export function* watchMileageListByUserId() {
  yield takeEvery(MILEAGE_GET_LIST_BY_USER_ID, getMileageListByUserId);
}

export function* watchMileageListByStatus() {
  yield takeEvery(MILEAGE_GET_LIST_BY_STATUS, getMileageListByStatus);
}

export function* watchAddNewMileage() {
  yield takeEvery(MILEAGE_ADD_NEW, addNewMileage);
}

export function* watchMileageById() {
  yield takeEvery(MILEAGE_GET_BY_ID, getMileageById);
}

export function* watchEditMileage() {
  yield takeEvery(MILEAGE_EDIT, editMileage);
}

export function* watchDeleteMileage() {
  yield takeEvery(MILEAGE_DELETE, deleteMileage);
}

export function* watchSubmitMileage() {
  yield takeEvery(MILEAGE_SUBMIT, submitMileage);
}

export function* watchSubmitApproveMileage() {
  yield takeEvery(MILEAGE_APPROVE_SUBMIT, submitApproveMileage);
}

export function* watchSubmitRejectMileage() {
  yield takeEvery(MILEAGE_REJECT_SUBMIT, submitRejectMileage);
}

export function* watchPreviousSubmission() {
  yield takeEvery(MILEAGE_PREVIOUS_SUBMISSION, getPreviousSubmission);
}

export function* watchPaidOrUnpaidSubmission() {
  yield takeEvery(MILEAGE_PAID_OR_UNPAID, paidOrUnpaidSubmission);
}

export default function* rootSaga() {
  yield all([
    fork(watchMileagesByQuery),
    fork(watchMileageList),
    fork(watchMileageListByStatus),
    fork(watchMileageListByUserId),
    fork(watchAddNewMileage),
    fork(watchMileageById),
    fork(watchEditMileage),
    fork(watchDeleteMileage),
    fork(watchSubmitMileage),
    fork(watchSubmitApproveMileage),
    fork(watchSubmitRejectMileage),
    fork(watchPreviousSubmission),
    fork(watchPaidOrUnpaidSubmission),
  ]);
}

export { getMileageByIdRequest };
