import {
  EVENT_CALENDAR_ADD_NEW,
  EVENT_CALENDAR_ADD_NEW_SUCCESS,
  EVENT_CALENDAR_ADD_NEW_ERROR,
  EVENT_CALENDAR_DELETE,
  EVENT_CALENDAR_DELETE_ERROR,
  EVENT_CALENDAR_DELETE_SUCCESS,
  EVENT_CALENDAR_EDIT,
  EVENT_CALENDAR_EDIT_ERROR,
  EVENT_CALENDAR_EDIT_SUCCESS,
  EVENT_CALENDAR_GET_BY_ID,
  EVENT_CALENDAR_GET_BY_ID_ERROR,
  EVENT_CALENDAR_GET_BY_ID_SUCCESS,
  EVENT_CALENDAR_GET_LIST,
  EVENT_CALENDAR_GET_LIST_ERROR,
  EVENT_CALENDAR_GET_LIST_SUCCESS,
  EVENT_CALENDAR_ADD_NEW_REQUEST,
  EVENT_CALENDAR_EDIT_REQUEST,
} from '../actions';

const initialState = {
  events: [],
  event: null,
  loading: false,
  error: null,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case EVENT_CALENDAR_EDIT_REQUEST:
      return {
        ...state,
        editSuccess: false,
        deleteSuccess: false,
      };
    case EVENT_CALENDAR_GET_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case EVENT_CALENDAR_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        event: payload,
      };

    case EVENT_CALENDAR_ADD_NEW_REQUEST:
      return {
        ...state,
        addSuccess: false,
      };

    case EVENT_CALENDAR_GET_LIST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case EVENT_CALENDAR_GET_LIST_SUCCESS:
      return {
        ...state,
        events: payload,
        loading: false,
      };
    case EVENT_CALENDAR_ADD_NEW:
      return {
        ...state,
        loading: true,
        error: null,
        addSuccess: false,
      };

    case EVENT_CALENDAR_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        event: payload,
        events: state.events.concat(payload),
        addSuccess: true,
      };

    case EVENT_CALENDAR_EDIT:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case EVENT_CALENDAR_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        events: state.events.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        event: payload,
        error: null,
        editSuccess: true,
      };
    case EVENT_CALENDAR_DELETE:
      return {
        ...state,
        loading: true,
        error: null,
        deleteSuccess: false,
      };
    case EVENT_CALENDAR_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        events: state.events.filter(c => c.id !== payload),
        deleteSuccess: true,
      };
    case EVENT_CALENDAR_DELETE_ERROR:
    case EVENT_CALENDAR_ADD_NEW_ERROR:
    case EVENT_CALENDAR_EDIT_ERROR:
    case EVENT_CALENDAR_GET_BY_ID_ERROR:
    case EVENT_CALENDAR_GET_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
