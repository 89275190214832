import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../helpers/auth-header';
import axios from 'axios';
import Config from '../../config';

import { JOBTYPE_GET_LIST, JOBTYPE_EDIT_ITEM, JOBTYPE_DELETE_ITEM } from '../actions';

import { getJobtypeListSuccess, getJobtypeListError, editJobtypeItemError } from './actions';

const getJobtypeListRequest = async () => {
  return await axios.get(`${Config.apiServerHost}/api/common/jobtype`, await authHeader());
};

function* getJobtypeListItems() {
  try {
    const response = yield call(getJobtypeListRequest);
    yield put(getJobtypeListSuccess(response.data));
  } catch (error) {
    yield put(getJobtypeListError(error));
  }
}

const poshJobtypeListRequest = async jobtype => {
  return await axios.post(
    `${Config.apiServerHost}/api/common/jobtype`,
    jobtype,
    await authHeader()
  );
};

function* editJobtypeItem({ payload }) {
  try {
    yield call(poshJobtypeListRequest, payload);
    const res = yield call(getJobtypeListRequest);
    yield put(getJobtypeListSuccess(res.data));
  } catch (error) {
    yield put(editJobtypeItemError(error));
  }
}

const deleteJobtypeListRequest = async id => {
  return await axios.delete(`${Config.apiServerHost}/api/common/jobtype/${id}`, await authHeader());
};
function* deleteJobtypeItem({ payload }) {
  try {
    yield call(deleteJobtypeListRequest, payload);
    const res = yield call(getJobtypeListRequest);
    yield put(getJobtypeListSuccess(res.data));
  } catch (error) {
    yield put(editJobtypeItemError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(JOBTYPE_GET_LIST, getJobtypeListItems);
}

export function* wathcEditItem() {
  yield takeEvery(JOBTYPE_EDIT_ITEM, editJobtypeItem);
}
export function* wathcDeleteItem() {
  yield takeEvery(JOBTYPE_DELETE_ITEM, deleteJobtypeItem);
}

export default function* rootSaga() {
  yield all([fork(watchGetList), fork(wathcEditItem), fork(wathcDeleteItem)]);
}
