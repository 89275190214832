export const EMPLOYER_INFO_GET_BY_ID = 'EMPLOYER_INFO_GET_BY_ID';
export const EMPLOYER_INFO_GET_BY_ID_SUCCESS = 'EMPLOYER_INFO_GET_BY_ID_SUCCESS';
export const EMPLOYER_INFO_GET_BY_ID_ERROR = 'EMPLOYER_INFO_GET_BY_ID_ERROR';

export const EMPLOYER_INFO_UPDATE = 'EMPLOYER_INFO_UPDATE';
export const EMPLOYER_INFO_UPDATE_SUCCESS = 'EMPLOYER_INFO_UPDATE_SUCCESS';
export const EMPLOYER_INFO_UPDATE_ERROR = 'EMPLOYER_INFO_UPDATE_ERROR';

export const EMPLOYER_INFO_ADD_NEW = 'EMPLOYER_INFO_ADD_NEW';
export const EMPLOYER_INFO_ADD_NEW_SUCCESS = 'EMPLOYER_INFO_ADD_NEW_SUCCESS';
export const EMPLOYER_INFO_ADD_NEW_ERROR = 'EMPLOYER_INFO_ADD_NEW_ERROR';

export const EMPLOYER_INFO_SYNC = 'EMPLOYER_INFO_SYNC';
export const EMPLOYER_INFO_SYNC_SUCCESS = 'EMPLOYER_INFO_SYNC_SUCCESS';
export const EMPLOYER_INFO_SYNC_ERROR = 'EMPLOYER_INFO_SYNC_ERROR';
export const EMPLOYER_INFO_CLEAR_NOTIFY = 'EMPLOYER_INFO_CLEAR_NOTIFY';
