import {
  AGGREGATE_ADD_NEW,
  AGGREGATE_ADD_NEW_SUCCESS,
  AGGREGATE_ADD_NEW_ERROR,
  AGGREGATE_DELETE,
  AGGREGATE_DELETE_ERROR,
  AGGREGATE_DELETE_SUCCESS,
  AGGREGATE_EDIT,
  AGGREGATE_EDIT_ERROR,
  AGGREGATE_EDIT_SUCCESS,
  AGGREGATE_GET_BY_ID,
  AGGREGATE_GET_BY_ID_ERROR,
  AGGREGATE_GET_BY_ID_SUCCESS,
  AGGREGATE_GET_LIST,
  AGGREGATE_GET_LIST_ERROR,
  AGGREGATE_GET_LIST_SUCCESS,
  AGGREGATE_ADD_NEW_REQUEST,
  AGGREGATE_REPORT_EDIT,
  AGGREGATE_REPORT_EDIT_ERROR,
  AGGREGATE_REPORT_EDIT_SUCCESS,
  AGGREGATE_REPORT_GET_BY_ID,
  AGGREGATE_REPORT_GET_BY_ID_ERROR,
  AGGREGATE_REPORT_GET_BY_ID_SUCCESS,
  AGGREGATE_REPORT_GET_LIST,
  AGGREGATE_REPORT_GET_LIST_ERROR,
  AGGREGATE_REPORT_GET_LIST_SUCCESS,
} from '../actions';

export const getAggregateReportList = () => ({
  type: AGGREGATE_REPORT_GET_LIST,
});

export const getAggregateReportListSuccess = aggregatesReport => ({
  type: AGGREGATE_REPORT_GET_LIST_SUCCESS,
  payload: aggregatesReport,
});

export const getAggregateReportListError = error => ({
  type: AGGREGATE_REPORT_GET_LIST_ERROR,
  payload: error,
});

export const getAggregateReportById = id => ({
  type: AGGREGATE_REPORT_GET_BY_ID,
  payload: id,
});

export const getAggregateReportByIdSuccess = aggregateReport => ({
  type: AGGREGATE_REPORT_GET_BY_ID_SUCCESS,
  payload: aggregateReport,
});

export const getAggregateReportByIdError = error => ({
  type: AGGREGATE_REPORT_GET_BY_ID_ERROR,
  payload: error,
});

export const updateAggregateReport = id => ({
  type: AGGREGATE_REPORT_EDIT,
  payload: id,
});

export const updateAggregateReportSuccess = aggregateReport => ({
  type: AGGREGATE_REPORT_EDIT_SUCCESS,
  payload: aggregateReport,
});

export const updateAggregateReportError = error => ({
  type: AGGREGATE_REPORT_EDIT_ERROR,
  payload: error,
});

export const getAggregateList = filter => ({
  type: AGGREGATE_GET_LIST,
  payload: filter,
});

export const getAggregateListSuccess = aggregates => ({
  type: AGGREGATE_GET_LIST_SUCCESS,
  payload: aggregates,
});

export const getAggregateListError = error => ({
  type: AGGREGATE_GET_LIST_ERROR,
  payload: error,
});

export const addNewAggregate = aggregate => ({
  type: AGGREGATE_ADD_NEW,
  payload: aggregate,
});

export const addNewAggregateRequest = () => ({
  type: AGGREGATE_ADD_NEW_REQUEST,
});

export const addNewAggregateSuccess = aggregate => ({
  type: AGGREGATE_ADD_NEW_SUCCESS,
  payload: aggregate,
});

export const addNewAggregateError = error => ({
  type: AGGREGATE_ADD_NEW_ERROR,
  payload: error,
});

export const editAggregate = aggregate => ({
  type: AGGREGATE_EDIT,
  payload: aggregate,
});

export const editAggregateSuccess = aggregate => ({
  type: AGGREGATE_EDIT_SUCCESS,
  payload: aggregate,
});

export const editAggregateError = error => ({
  type: AGGREGATE_EDIT_ERROR,
  payload: error,
});

export const deleteAggregate = id => ({
  type: AGGREGATE_DELETE,
  payload: id,
});

export const deleteAggregateSuccess = id => ({
  type: AGGREGATE_DELETE_SUCCESS,
  payload: id,
});

export const deleteAggregateError = error => ({
  type: AGGREGATE_DELETE_ERROR,
  payload: error,
});

export const getAggregateById = id => ({
  type: AGGREGATE_GET_BY_ID,
  payload: id,
});

export const getAggregateByIdSuccess = aggregate => ({
  type: AGGREGATE_GET_BY_ID_SUCCESS,
  payload: aggregate,
});

export const getAggregateByIdError = error => ({
  type: AGGREGATE_GET_BY_ID_ERROR,
  payload: error,
});
