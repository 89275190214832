import {
  TRF_CA_REFERRAL_ADD_NEW,
  TRF_CA_REFERRAL_ADD_NEW_ERROR,
  TRF_CA_REFERRAL_ADD_NEW_SUCCESS,
  TRF_CA_REFERRAL_CHANGE_STATUS,
  TRF_CA_REFERRAL_CHANGE_STATUS_ERROR,
  TRF_CA_REFERRAL_CHANGE_STATUS_SUCCESS,
  TRF_CA_REFERRAL_DELETE,
  TRF_CA_REFERRAL_DELETE_ERROR,
  TRF_CA_REFERRAL_DELETE_SUCCESS,
  TRF_CA_REFERRAL_GET_BY_ID,
  TRF_CA_REFERRAL_GET_BY_ID_ERROR,
  TRF_CA_REFERRAL_GET_BY_ID_SUCCESS,
  TRF_CA_REFERRAL_GET_PAGING,
  TRF_CA_REFERRAL_GET_PAGING_ERROR,
  TRF_CA_REFERRAL_GET_PAGING_SUCCESS,
  TRF_CA_REFERRAL_UPDATE,
  TRF_CA_REFERRAL_UPDATE_ERROR,
  TRF_CA_REFERRAL_UPDATE_SUCCESS,
  TRF_CA_REFERRAL_ASSIGN_REFERRAL,
  TRF_CA_REFERRAL_ASSIGN_REFERRAL_ERROR,
  TRF_CA_REFERRAL_ASSIGN_REFERRAL_SUCCESS,
} from './types';

export const getPagingTrfCaReferral = items => ({
  type: TRF_CA_REFERRAL_GET_PAGING,
  payload: items,
});

export const getPagingTrfCaReferralSuccess = items => ({
  type: TRF_CA_REFERRAL_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingTrfCaReferralError = error => ({
  type: TRF_CA_REFERRAL_GET_PAGING_ERROR,
  payload: error,
});

export const addTrfCaReferral = items => ({
  type: TRF_CA_REFERRAL_ADD_NEW,
  payload: items,
});

export const addTrfCaReferralSuccess = items => ({
  type: TRF_CA_REFERRAL_ADD_NEW_SUCCESS,
  payload: items,
});

export const addTrfCaReferralError = error => ({
  type: TRF_CA_REFERRAL_ADD_NEW_ERROR,
  payload: error,
});

export const assignRefferal = items => ({
  type: TRF_CA_REFERRAL_ASSIGN_REFERRAL,
  payload: items,
});

export const assignRefferalSuccess = items => ({
  type: TRF_CA_REFERRAL_ASSIGN_REFERRAL_SUCCESS,
  payload: items,
});

export const assignRefferalError = error => ({
  type: TRF_CA_REFERRAL_ASSIGN_REFERRAL_ERROR,
  payload: error,
});

export const getTrfCaReferralById = items => ({
  type: TRF_CA_REFERRAL_GET_BY_ID,
  payload: items,
});

export const getTrfCaReferralByIdSuccess = items => ({
  type: TRF_CA_REFERRAL_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getTrfCaReferralByIdError = error => ({
  type: TRF_CA_REFERRAL_GET_BY_ID_ERROR,
  payload: error,
});

export const updateTrfCaReferral = items => ({
  type: TRF_CA_REFERRAL_UPDATE,
  payload: items,
});

export const updateTrfCaReferralSuccess = items => ({
  type: TRF_CA_REFERRAL_UPDATE_SUCCESS,
  payload: items,
});

export const updateTrfCaReferralError = error => ({
  type: TRF_CA_REFERRAL_UPDATE_ERROR,
  payload: error,
});

export const changeStatusTrfCaReferral = items => ({
  type: TRF_CA_REFERRAL_CHANGE_STATUS,
  payload: items,
});

export const changeStatusTrfCaReferralSuccess = items => ({
  type: TRF_CA_REFERRAL_CHANGE_STATUS_SUCCESS,
  payload: items,
});

export const changeStatusTrfCaReferralError = error => ({
  type: TRF_CA_REFERRAL_CHANGE_STATUS_ERROR,
  payload: error,
});

export const deleteTrfCaReferral = items => ({
  type: TRF_CA_REFERRAL_DELETE,
  payload: items,
});

export const deleteTrfCaReferralSuccess = items => ({
  type: TRF_CA_REFERRAL_DELETE_SUCCESS,
  payload: items,
});

export const deleteTrfCaReferralError = error => ({
  type: TRF_CA_REFERRAL_DELETE_ERROR,
  payload: error,
});
