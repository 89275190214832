import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import { IGNORE_TRANSACTION_NOTIFICATION, TRANSACTION_NOTIFICATION_GET_BY_QUERY } from './types';

import {
  getTransactionNotificationByQuerySuccess,
  getTransactionNotificationByQueryError,
  ignoreTransactionNotificationSuccess,
  ignoreTransactionNotificationError,
} from './actions';

const getTransactionNotificationByQueryRequest = async query => {
  return await axios.get(`${Config.apiServerHost}/api/TransactionNotification/fetch`, {
    params: query,
    headers: { ...(await authHeader()).headers },
  });
};

const ignoreTransactionNotificationRequest = async id => {
  return axios.get(
    `${Config.apiServerHost}/api/TransactionNotification/${id}/ignore`,
    await authHeader()
  );
};

function* getTransactionNotificationByQuery({ payload }) {
  try {
    const response = yield call(getTransactionNotificationByQueryRequest, payload);
    yield put(getTransactionNotificationByQuerySuccess(response.data));
  } catch (error) {
    yield put(getTransactionNotificationByQueryError(error.response.statusText));
  }
}

function* ignoreTransactionNotification({ payload }) {
  try {
    const response = yield call(ignoreTransactionNotificationRequest, payload);
    yield put(ignoreTransactionNotificationSuccess(response.data));
  } catch (error) {
    yield put(ignoreTransactionNotificationError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(TRANSACTION_NOTIFICATION_GET_BY_QUERY, getTransactionNotificationByQuery);
  yield takeEvery(IGNORE_TRANSACTION_NOTIFICATION, ignoreTransactionNotification);
}

export { getTransactionNotificationByQueryRequest, ignoreTransactionNotificationRequest };
