import {
  POSTING_SENT_TO_CLIENT_GET_BY_ID,
  POSTING_SENT_TO_CLIENT_GET_BY_ID_SUCCESS,
  POSTING_SENT_TO_CLIENT_GET_LIST,
  POSTING_SENT_TO_CLIENT_GET_LIST_SUCCESS,
  POSTING_SENT_TO_CLIENT_ERROR,
} from './types';
// PostingSentToClient
export const getPostingSentToClientList = query => ({
  type: POSTING_SENT_TO_CLIENT_GET_LIST,
  payload: query,
});

export const getPostingSentToClientListSuccess = item => ({
  type: POSTING_SENT_TO_CLIENT_GET_LIST_SUCCESS,
  payload: item,
});

export const getPostingSentToClientById = id => ({
  type: POSTING_SENT_TO_CLIENT_GET_BY_ID,
  payload: id,
});

export const getPostingSentToClientByIdSuccess = item => ({
  type: POSTING_SENT_TO_CLIENT_GET_BY_ID_SUCCESS,
  payload: item,
});

export const postingSentToClientError = error => ({
  type: POSTING_SENT_TO_CLIENT_ERROR,
  payload: error,
});
