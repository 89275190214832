import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  CLIENT_FUND_DELETE,
  CLIENT_FUND_EDIT,
  CLIENT_FUND_GET_BY_ID,
  CLIENT_FUND_ADD_NEW,
  CLIENT_FUND_GET_LIST,
  CLIENT_FUND_LOG_LIST,
  CLIENT_FUND_DELETE_ATTACHMENT,
  CLIENT_FUND_REPORT,
} from '../actions';

import {
  getClientFundListSuccess,
  getClientFundListError,
  editClientFundSuccess,
  editClientFundError,
  addNewClientFundSuccess,
  addNewClientFundError,
  deleteClientFundSuccess,
  deleteClientFundError,
  getClientFundByIdSuccess,
  getClientFundLogListSuccess,
  getClientFundReportSuccess,
  getClientFundLogListError,
  getClientFundReportError,
  deleteClientFundAttachmentError,
  deleteClientFundAttachmentSuccess,
} from './actions';

const getClientFundLogListRequest = async clientFundId => {
  return axios.get(
    `${Config.apiServerHost}/api/ClientFund/log/${clientFundId}`,
    await authHeader()
  );
};

const getClientFundReportRequest = async query => {
  let url = `${Config.apiServerHost}/api/ClientFund/report`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getClientFundListRequest = async query => {
  let url = `${Config.apiServerHost}/api/ClientFund/search`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const addNewClientFundReqeust = async clientFund => {
  return axios.post(`${Config.apiServerHost}/api/ClientFund`, clientFund, await authHeader());
};

const editClientFundRequest = async clientFund => {
  return axios.put(`${Config.apiServerHost}/api/ClientFund`, clientFund, await authHeader());
};

const deleteClientFundRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/ClientFund/${id}`, await authHeader());
};

const getClientFundByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/ClientFund/${id}`, await authHeader());
};

const deleteClientFundAttachmentRequest = async id => {
  return axios.delete(
    `${Config.apiServerHost}/api/ClientFund/deleteAttachment/${id}`,
    await authHeader()
  );
};

const getSupportTypeRequest = async query => {
  let url = `${Config.apiServerHost}/api/ClientFund/supportType`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getSupportPaymentRequest = async () => {
  return await axios.get(
    `${Config.apiServerHost}/api/ClientFund/supportPayment`,
    await authHeader()
  );
};

function* getClientFundReport({ payload }) {
  try {
    const response = yield call(getClientFundReportRequest, payload);
    yield put(getClientFundReportSuccess(response.data));
  } catch (error) {
    yield put(getClientFundReportError(error.response.statusText));
  }
}

function* getClientFundList({ payload }) {
  try {
    const response = yield call(getClientFundListRequest, payload);
    yield put(getClientFundListSuccess(response.data));
  } catch (error) {
    yield put(getClientFundListError(error.response.statusText));
  }
}

function* getClientFundLogList({ payload }) {
  try {
    const response = yield call(getClientFundLogListRequest, payload);
    yield put(getClientFundLogListSuccess(response.data));
  } catch (error) {
    yield put(getClientFundLogListError(error.response.statusText));
  }
}

function* addNewClientFund({ payload }) {
  try {
    let response = yield call(addNewClientFundReqeust, payload);
    response = yield call(getClientFundByIdRequest, response.data);
    yield put(addNewClientFundSuccess(response.data));
  } catch (error) {
    yield put(addNewClientFundError(error.response.statusText));
  }
}

function* editClientFund({ payload }) {
  try {
    const { clientId, programId, isEditReport, data } = payload;
    const query = {
      clientId: clientId ?? '',
      programId: programId ?? '',
    };

    let response = yield call(editClientFundRequest, data);
    if (isEditReport) {
      response = yield call(getClientFundReportRequest, query);
      yield put(getClientFundReportSuccess(response.data));
    } else {
      response = yield call(getClientFundByIdRequest, response.data);
      yield put(editClientFundSuccess(response.data));
    }
  } catch (error) {
    yield put(editClientFundError(error.response.statusText));
  }
}

function* getClientFundById({ payload }) {
  try {
    const response = yield call(getClientFundByIdRequest, payload);
    yield put(getClientFundByIdSuccess(response.data));
  } catch (error) {
    yield put(editClientFundError(error.response.statusText));
  }
}

function* deleteClientFund({ payload }) {
  try {
    const { programId, isFromReport, data } = payload;
    const query = {
      programId: programId ?? '',
    };

    let response = yield call(deleteClientFundRequest, data);
    if (isFromReport) {
      response = yield call(getClientFundReportRequest, query);
      yield put(getClientFundReportSuccess(response.data));
    } else {
      yield put(deleteClientFundSuccess(response.data));
    }
  } catch (error) {
    yield put(deleteClientFundError(error.response.statusText));
  }
}

function* deleteClientFundAttachment({ payload }) {
  try {
    const response = yield call(deleteClientFundAttachmentRequest, payload);
    yield put(deleteClientFundAttachmentSuccess(response.data));
  } catch (error) {
    yield put(deleteClientFundAttachmentError(error.response.statusText));
  }
}

export function* watchClientFundReport() {
  yield takeEvery(CLIENT_FUND_REPORT, getClientFundReport);
}

export function* watchClientFundLogList() {
  yield takeEvery(CLIENT_FUND_LOG_LIST, getClientFundLogList);
}

export function* watchClientFundList() {
  yield takeEvery(CLIENT_FUND_GET_LIST, getClientFundList);
}

export function* watchAddNewClientFund() {
  yield takeEvery(CLIENT_FUND_ADD_NEW, addNewClientFund);
}

export function* watchClientFundById() {
  yield takeEvery(CLIENT_FUND_GET_BY_ID, getClientFundById);
}

export function* watchEditClientFund() {
  yield takeEvery(CLIENT_FUND_EDIT, editClientFund);
}

export function* watchDeleteClientFund() {
  yield takeEvery(CLIENT_FUND_DELETE, deleteClientFund);
}

export function* watchDeleteClientFundAttachment() {
  yield takeEvery(CLIENT_FUND_DELETE_ATTACHMENT, deleteClientFundAttachment);
}

export default function* rootSaga() {
  yield all([
    fork(watchClientFundReport),
    fork(watchClientFundLogList),
    fork(watchClientFundList),
    fork(watchAddNewClientFund),
    fork(watchClientFundById),
    fork(watchEditClientFund),
    fork(watchDeleteClientFund),
    fork(watchDeleteClientFundAttachment),
  ]);
}

export {
  getSupportTypeRequest,
  getSupportPaymentRequest,
  getClientFundByIdRequest,
  editClientFundRequest,
};
