import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  EXPENSE_DELETE,
  EXPENSE_EDIT,
  EXPENSE_GET_BY_ID,
  EXPENSE_ADD_NEW,
  EXPENSE_GET_LIST,
  EXPENSE_GET_LIST_BY_USER_ID,
  EXPENSE_GET_LIST_BY_STATUS,
  EXPENSE_SUBMIT,
  EXPENSE_APPROVE_SUBMIT,
  EXPENSE_GET_BY_QUERY,
} from '../actions';

import {
  getExpenseListByUserIdSuccess,
  getExpenseListByUserIdError,
  editExpenseSuccess,
  editExpenseError,
  addNewExpenseSuccess,
  addNewExpenseError,
  deleteExpenseSuccess,
  deleteExpenseError,
  getExpenseByIdSuccess,
  getExpenseListSuccess,
  getExpenseListError,
  submitExpenseSuccess,
  submitExpenseError,
  submitApproveExpenseSuccess,
  getExpenseListByStatusSuccess,
  getExpenseListByStatusError,
  submitApproveExpenseError,
  getExpenseByQuerySuccess,
  getExpenseByQueryError,
} from './actions';
const getExpenseByQueryRequest = async query => {
  return axios.get(
    `${Config.apiServerHost}/api/expense/search?search=${query}`,
    await authHeader()
  );
};

const getExpenseListRequest = async () => {
  return axios.get(`${Config.apiServerHost}/api/expense`, await authHeader());
};

const getExpenseListByStatusRequest = async param => {
  const { search, status } = param;
  return axios.get(
    `${Config.apiServerHost}/api/expense/fetch-by-query?search=${search}&status=${status}`,
    await authHeader()
  );
};

const getExpenseListByUserIdRequest = async userId => {
  return axios.get(
    `${Config.apiServerHost}/api/expense/fetch-by-user/${userId}`,
    await authHeader()
  );
};

const addNewExpenseReqeust = async expense => {
  return axios.post(`${Config.apiServerHost}/api/expense`, expense, await authHeader());
};

const editExpenseRequest = async expense => {
  return axios.put(
    `${Config.apiServerHost}/api/expense/${expense.id}`,
    expense,
    await authHeader()
  );
};

const deleteExpenseRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/expense/${id}`, await authHeader());
};

const getExpenseByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/expense/${id}`, await authHeader());
};

const submitExpenseRequest = async data => {
  return axios.put(
    `${Config.apiServerHost}/api/expense/${data.id}/submit`,
    data.assignedTo,
    await authHeader()
  );
};

const submitApproveExpenseRequest = async data => {
  return axios.put(
    `${Config.apiServerHost}/api/expense/${data.id}/approve`,
    data.assignedTo,
    await authHeader()
  );
};

function* getExpenseByQuery({ payload }) {
  try {
    const response = yield call(getExpenseByQueryRequest, payload);

    yield put(getExpenseByQuerySuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(getExpenseByQueryError(error));
  }
}

function* getExpenseList() {
  try {
    const response = yield call(getExpenseListRequest);

    yield put(getExpenseListSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(getExpenseListError(error));
  }
}

function* getExpenseListByStatus({ payload }) {
  try {
    const response = yield call(getExpenseListByStatusRequest, payload);

    yield put(getExpenseListByStatusSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(getExpenseListByStatusError(error));
  }
}

function* getExpenseListByUserId({ payload }) {
  try {
    const response = yield call(getExpenseListByUserIdRequest, payload);

    yield put(getExpenseListByUserIdSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(getExpenseListByUserIdError(error));
  }
}

function* addNewExpense({ payload }) {
  try {
    const { expense } = payload;
    let response = yield call(addNewExpenseReqeust, expense);
    response = yield call(getExpenseByIdRequest, response.data.data);
    yield put(addNewExpenseSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(addNewExpenseError(error));
  }
}

function* editExpense({ payload }) {
  try {
    const { expense } = payload;
    let response = yield call(editExpenseRequest, expense);
    response = yield call(getExpenseByIdRequest, expense.id);
    yield put(editExpenseSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(editExpenseError(error));
  }
}

function* getExpenseById({ payload }) {
  try {
    const response = yield call(getExpenseByIdRequest, payload);
    yield put(getExpenseByIdSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(editExpenseError(error));
  }
}

function* submitExpense({ payload }) {
  try {
    let response = yield call(submitExpenseRequest, payload);
    response = yield call(getExpenseByIdRequest, payload.id);
    yield put(submitExpenseSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(submitExpenseError(error));
  }
}

function* submitApproveExpense({ payload }) {
  try {
    let response = yield call(submitApproveExpenseRequest, payload);
    response = yield call(getExpenseByIdRequest, payload.id);
    yield put(submitApproveExpenseSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(submitApproveExpenseError(error));
  }
}

function* deleteExpense({ payload }) {
  try {
    const response = yield call(deleteExpenseRequest, payload);
    yield put(deleteExpenseSuccess(response.data.data));
  } catch (err) {
    const { statusText, data } = err.response;
    const error = {
      title: statusText,
      message: data,
    };
    yield put(deleteExpenseError(error));
  }
}

export function* watchExpenseByQuery() {
  yield takeEvery(EXPENSE_GET_BY_QUERY, getExpenseByQuery);
}

export function* watchExpenseList() {
  yield takeEvery(EXPENSE_GET_LIST, getExpenseList);
}

export function* watchExpenseListByUserId() {
  yield takeEvery(EXPENSE_GET_LIST_BY_USER_ID, getExpenseListByUserId);
}

export function* watchExpenseListByStatus() {
  yield takeEvery(EXPENSE_GET_LIST_BY_STATUS, getExpenseListByStatus);
}

export function* watchAddNewExpense() {
  yield takeEvery(EXPENSE_ADD_NEW, addNewExpense);
}

export function* watchExpenseById() {
  yield takeEvery(EXPENSE_GET_BY_ID, getExpenseById);
}

export function* watchEditExpense() {
  yield takeEvery(EXPENSE_EDIT, editExpense);
}

export function* watchDeleteExpense() {
  yield takeEvery(EXPENSE_DELETE, deleteExpense);
}

export function* watchSubmitExpense() {
  yield takeEvery(EXPENSE_SUBMIT, submitExpense);
}

export function* watchSubmitApproveExpense() {
  yield takeEvery(EXPENSE_APPROVE_SUBMIT, submitApproveExpense);
}

export default function* rootSaga() {
  yield all([
    fork(watchExpenseByQuery),
    fork(watchExpenseList),
    fork(watchExpenseListByStatus),
    fork(watchExpenseListByUserId),
    fork(watchAddNewExpense),
    fork(watchExpenseById),
    fork(watchEditExpense),
    fork(watchDeleteExpense),
    fork(watchSubmitExpense),
    fork(watchSubmitApproveExpense),
  ]);
}

export { getExpenseByIdRequest };
