import {
  COJG_FUND_GET_LIST,
  COJG_FUND_GET_LIST_SUCCESS,
  COJG_FUND_GET_LIST_ERROR,
  COJG_FUND_GET_LIST_WITH_FILTER,
  COJG_FUND_GET_LIST_WITH_ORDER,
  COJG_FUND_GET_LIST_SEARCH,
} from '../../actions';

export const getCOJGFundList = filter => ({
  type: COJG_FUND_GET_LIST,
  payload: filter,
});

export const getCOJGFundListSuccess = items => ({
  type: COJG_FUND_GET_LIST_SUCCESS,
  payload: items,
});

export const getCOJGFundListError = error => ({
  type: COJG_FUND_GET_LIST_ERROR,
  payload: error,
});

export const getCOJGFundListWithFilter = (column, value) => ({
  type: COJG_FUND_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getCOJGFundistWithOrder = column => ({
  type: COJG_FUND_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getCOJGFundListSearch = keyword => ({
  type: COJG_FUND_GET_LIST_SEARCH,
  payload: keyword,
});
