import {
  EVENT_ADD_NEW,
  EVENT_ADD_NEW_SUCCESS,
  EVENT_ADD_NEW_ERROR,
  EVENT_DELETE,
  EVENT_DELETE_ERROR,
  EVENT_DELETE_SUCCESS,
  EVENT_EDIT,
  EVENT_EDIT_ERROR,
  EVENT_EDIT_SUCCESS,
  EVENT_GET_BY_ID,
  EVENT_GET_BY_ID_ERROR,
  EVENT_GET_BY_ID_SUCCESS,
  EVENT_GET_LIST,
  EVENT_GET_LIST_ERROR,
  EVENT_GET_LIST_SUCCESS,
  EVENT_GET_LIST_BY_QUERY,
  EVENT_GET_LIST_BY_QUERY_SUCCESS,
  EVENT_GET_LIST_BY_QUERY_ERROR,
  EVENT_ADD_NEW_REQUEST,
  EVENT_STATUS_CHANGE,
  EVENT_STATUS_CHANGE_SUCCESS,
  EVENT_STATUS_CHANGE_ERROR,
} from '../actions';

/** Event */

export const getEventListByQuery = query => ({
  type: EVENT_GET_LIST_BY_QUERY,
  payload: query,
});

export const getEventListByQuerySuccess = workshops => ({
  type: EVENT_GET_LIST_BY_QUERY_SUCCESS,
  payload: workshops,
});

export const getEventListByQueryError = error => ({
  type: EVENT_GET_LIST_BY_QUERY_ERROR,
  payload: error,
});

export const getEventList = () => ({
  type: EVENT_GET_LIST,
});

export const getEventListSuccess = workshops => ({
  type: EVENT_GET_LIST_SUCCESS,
  payload: workshops,
});

export const getEventListError = error => ({
  type: EVENT_GET_LIST_ERROR,
  payload: error,
});

export const addNewEvent = workshop => ({
  type: EVENT_ADD_NEW,
  payload: workshop,
});

export const addNewEventRequest = () => ({
  type: EVENT_ADD_NEW_REQUEST,
});

export const addNewEventSuccess = workshop => ({
  type: EVENT_ADD_NEW_SUCCESS,
  payload: workshop,
});

export const addNewEventError = error => ({
  type: EVENT_ADD_NEW_ERROR,
  payload: error,
});

export const editEvent = workshop => ({
  type: EVENT_EDIT,
  payload: workshop,
});

export const editEventSuccess = workshop => ({
  type: EVENT_EDIT_SUCCESS,
  payload: workshop,
});

export const editEventError = error => ({
  type: EVENT_EDIT_ERROR,
  payload: error,
});

export const deleteEvent = id => ({
  type: EVENT_DELETE,
  payload: id,
});

export const deleteEventSuccess = id => ({
  type: EVENT_DELETE_SUCCESS,
  payload: id,
});

export const deleteEventError = error => ({
  type: EVENT_DELETE_ERROR,
  payload: error,
});

export const getEventById = id => ({
  type: EVENT_GET_BY_ID,
  payload: id,
});

export const getEventByIdSuccess = actionPlan => ({
  type: EVENT_GET_BY_ID_SUCCESS,
  payload: actionPlan,
});

export const getEventByIdError = error => ({
  type: EVENT_GET_BY_ID_ERROR,
  payload: error,
});
export const changeEventStatus = id => ({
  type: EVENT_STATUS_CHANGE,
  payload: id,
});

export const changeEventStatusSuccess = item => ({
  type: EVENT_STATUS_CHANGE_SUCCESS,
  payload: item,
});

export const changeEventStatusError = error => ({
  type: EVENT_STATUS_CHANGE_ERROR,
  payload: error,
});
