// CASE_AUDIT
export const CASE_AUDIT_GET_BY_ID = 'CASE_AUDIT_GET_BY_ID';
export const CASE_AUDIT_GET_BY_ID_SUCCESS = 'CASE_AUDIT_GET_BY_ID_SUCCESS';
export const CASE_AUDIT_GET_BY_ID_ERROR = 'CASE_AUDIT_GET_BY_ID_ERROR';

export const CASE_AUDIT_GET_LIST = 'CASE_AUDIT_GET_LIST';
export const CASE_AUDIT_GET_LIST_SUCCESS = 'CASE_AUDIT_GET_LIST_SUCCESS';
export const CASE_AUDIT_GET_LIST_ERROR = 'CASE_AUDIT_GET_LIST_ERROR';

export const CASE_AUDIT_EDIT = 'CASE_AUDIT_EDIT';
export const CASE_AUDIT_EDIT_SUCCESS = 'CASE_AUDIT_EDIT_SUCCESS';
export const CASE_AUDIT_EDIT_ERROR = 'CASE_AUDIT_EDIT_ERROR';

export const CASE_AUDIT_ADD_NEW = 'CASE_AUDIT_ADD_NEW';
export const CASE_AUDIT_ADD_NEW_SUCCESS = 'CASE_AUDIT_ADD_NEW_SUCCESS';
export const CASE_AUDIT_ADD_NEW_ERROR = 'CASE_AUDIT_ADD_NEW_ERROR';
export const CASE_AUDIT_ADD_NEW_REQUEST = 'CASE_AUDIT_ADD_NEW_REQUEST';

export const CASE_AUDIT_DELETE = 'CASE_AUDIT_DELETE';
export const CASE_AUDIT_DELETE_SUCCESS = 'CASE_AUDIT_DELETE_SUCCESS';
export const CASE_AUDIT_DELETE_ERROR = 'CASE_AUDIT_DELETE_ERROR';

export const CASE_AUDIT_GET_BY_QUERY = 'CASE_AUDIT_GET_BY_QUERY';
export const CASE_AUDIT_GET_BY_QUERY_SUCCESS = 'CASE_AUDIT_GET_BY_QUERY_SUCCESS';
export const CASE_AUDIT_GET_BY_QUERY_ERROR = 'CASE_AUDIT_GET_BY_QUERY_ERROR';
