import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  CAMPAIGN_ADD_NEW,
  CAMPAIGN_DASHBOARD_GET_PAGIN,
  CAMPAIGN_DELETE,
  CAMPAIGN_GET_BY_ID,
  CAMPAIGN_GET_PAGING,
  CAMPAIGN_STATUS_CHANGE,
  CAMPAIGN_UPDATE,
} from './types';

import {
  addCampaignSuccess,
  addCampaignError,
  getPagingCampaignSuccess,
  getPagingCampaignError,
  getCampaignByIdSuccess,
  getCampaignByIdError,
  updateCampaignSuccess,
  updateCampaignError,
  getCampaignDashboardSuccess,
  getCampaignDashboardError,
} from './actions';

const addCampaignRequest = async item => {
  return axios.post(`${Config.apiServerHost}/api/Campaign`, item, await authHeader());
};

const updateCampaignRequest = async item => {
  return axios.put(`${Config.apiServerHost}/api/Campaign`, item, await authHeader());
};

const getPagingCampaignRequest = async item => {
  return axios.post(`${Config.apiServerHost}/api/Campaign/paging`, item, await authHeader());
};

const createCampaignFromLastContactRequest = async item => {
  return axios.post(
    `${Config.apiServerHost}/api/Campaign/createCampaignFromLastContact`,
    item,
    await authHeader()
  );
};

const getCampaignByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/Campaign/${id}`, await authHeader());
};

const changeCampaignStatusRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/Campaign/changeStatus/${id}`, await authHeader());
};

const deleteCampaignRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/Campaign/${id}`, await authHeader());
};

function* addNewCampaign({ payload }) {
  try {
    let response = yield call(addCampaignRequest, payload);
    yield put(addCampaignSuccess(response.data));

    response = yield call(getPagingCampaignRequest, {});
    yield put(getPagingCampaignSuccess(response.data));
  } catch (error) {
    yield put(addCampaignError(error.response.statusText));
  }
}

function* updateCampaign({ payload }) {
  try {
    let response = yield call(updateCampaignRequest, payload);
    yield put(updateCampaignSuccess(response.data));

    response = yield call(getCampaignByIdRequest, payload.id);
    yield put(getCampaignByIdSuccess(response.data));
  } catch (error) {
    yield put(updateCampaignError(error.response.statusText));
  }
}

function* getPagingCampaign({ payload }) {
  try {
    let response = yield call(getPagingCampaignRequest, payload);
    yield put(getPagingCampaignSuccess(response.data));
  } catch (error) {
    yield put(getPagingCampaignError(error.response.statusText));
  }
}

function* getCampaignById({ payload }) {
  try {
    let response = yield call(getCampaignByIdRequest, payload);
    yield put(getCampaignByIdSuccess(response.data));
  } catch (error) {
    yield put(getCampaignByIdError(error.response.statusText));
  }
}

function* changeCampaignStatus({ payload }) {
  try {
    yield call(changeCampaignStatusRequest, payload);
    let response = yield call(getPagingCampaignRequest, {});
    yield put(getPagingCampaignSuccess(response.data));
  } catch (error) {
    yield put(getPagingCampaignError(error.response.statusText));
  }
}

function* deleteCampaign({ payload }) {
  try {
    let response = yield call(deleteCampaignRequest, payload);

    response = yield call(getPagingCampaignRequest, {});
    yield put(getPagingCampaignSuccess(response.data));
  } catch (error) {
    yield put(getPagingCampaignError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(CAMPAIGN_ADD_NEW, addNewCampaign);
  yield takeEvery(CAMPAIGN_UPDATE, updateCampaign);
  yield takeEvery(CAMPAIGN_GET_PAGING, getPagingCampaign);
  yield takeEvery(CAMPAIGN_GET_BY_ID, getCampaignById);
  yield takeEvery(CAMPAIGN_STATUS_CHANGE, changeCampaignStatus);
  yield takeEvery(CAMPAIGN_DELETE, deleteCampaign);
}

export { createCampaignFromLastContactRequest };
