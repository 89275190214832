import {
  CAMPAIGN_DASHBOARD_GET_PAGIN,
  CAMPAIGN_DASHBOARD_GET_PAGIN_ERROR,
  CAMPAIGN_DASHBOARD_GET_PAGIN_SUCCESS,
} from './types';

export const getCampaignDashboardPaging = payload => ({
  type: CAMPAIGN_DASHBOARD_GET_PAGIN,
  payload,
});

export const getCampaignDashboardSuccess = data => ({
  type: CAMPAIGN_DASHBOARD_GET_PAGIN_SUCCESS,
  payload: data,
});

export const getCampaignDashboardError = error => ({
  type: CAMPAIGN_DASHBOARD_GET_PAGIN_ERROR,
  payload: error,
});
