import {
  GET_COJG_CONTRACT_DOCUMENTS_BY_ID,
  GET_COJG_CONTRACT_DOCUMENTS_BY_ID_SUCCESS,
  GET_COJG_CONTRACT_DOCUMENTS_BY_QUERY,
  GET_COJG_CONTRACT_DOCUMENTS_BY_QUERY_SUCCESS,
  UPLOAD_COJG_CONTRACT_DOCUMENTS,
  EDIT_COJG_CONTRACT_DOCUMENTS,
  DELETE_COJG_CONTRACT_DOCUMENTS,
  COJG_CONTRACT_DOCUMENT_ERROR,
} from './types';
// COJGContractDocument
export const getCOJGContractDocumentById = id => ({
  type: GET_COJG_CONTRACT_DOCUMENTS_BY_ID,
  payload: id,
});

export const getCOJGContractDocumentByIdSuccess = employer => ({
  type: GET_COJG_CONTRACT_DOCUMENTS_BY_ID_SUCCESS,
  payload: employer,
});

export const getCOJGContractDocumentByQuery = query => ({
  type: GET_COJG_CONTRACT_DOCUMENTS_BY_QUERY,
  payload: query,
});

export const getCOJGContractDocumentByQuerySuccess = data => ({
  type: GET_COJG_CONTRACT_DOCUMENTS_BY_QUERY_SUCCESS,
  payload: data,
});

export const uploadCOJGContractDocument = item => ({
  type: UPLOAD_COJG_CONTRACT_DOCUMENTS,
  payload: item,
});

export const editCOJGContractDocument = item => ({
  type: EDIT_COJG_CONTRACT_DOCUMENTS,
  payload: item,
});

export const deleteCOJGContractDocument = id => ({
  type: DELETE_COJG_CONTRACT_DOCUMENTS,
  payload: id,
});

export const cojgContractDocumentDocumentError = error => ({
  type: COJG_CONTRACT_DOCUMENT_ERROR,
  payload: error,
});
