// CLIENT_JOB_MATCH
export const CLIENT_JOB_MATCH_GET_BY_ID = 'CLIENT_JOB_MATCH_GET_BY_ID';
export const CLIENT_JOB_MATCH_GET_BY_ID_SUCCESS = 'CLIENT_JOB_MATCH_GET_BY_ID_SUCCESS';
export const CLIENT_JOB_MATCH_GET_BY_ID_ERROR = 'CLIENT_JOB_MATCH_GET_BY_ID_ERROR';

export const CLIENT_JOB_MATCH_GET_LIST = 'CLIENT_JOB_MATCH_GET_LIST';
export const CLIENT_JOB_MATCH_GET_LIST_SUCCESS = 'CLIENT_JOB_MATCH_GET_LIST_SUCCESS';
export const CLIENT_JOB_MATCH_GET_LIST_ERROR = 'CLIENT_JOB_MATCH_GET_LIST_ERROR';

export const CLIENT_JOB_MATCH_EDIT = 'CLIENT_JOB_MATCH_EDIT';
export const CLIENT_JOB_MATCH_EDIT_SUCCESS = 'CLIENT_JOB_MATCH_EDIT_SUCCESS';
export const CLIENT_JOB_MATCH_EDIT_ERROR = 'CLIENT_JOB_MATCH_EDIT_ERROR';

export const CLIENT_JOB_MATCH_ADD_NEW = 'CLIENT_JOB_MATCH_ADD_NEW';
export const CLIENT_JOB_MATCH_ADD_NEW_SUCCESS = 'CLIENT_JOB_MATCH_ADD_NEW_SUCCESS';
export const CLIENT_JOB_MATCH_ADD_NEW_ERROR = 'CLIENT_JOB_MATCH_ADD_NEW_ERROR';
export const CLIENT_JOB_MATCH_ADD_NEW_REQUEST = 'CLIENT_JOB_MATCH_ADD_NEW_REQUEST';

export const CLIENT_JOB_MATCH_DELETE = 'CLIENT_JOB_MATCH_DELETE';
export const CLIENT_JOB_MATCH_DELETE_SUCCESS = 'CLIENT_JOB_MATCH_DELETE_SUCCESS';
export const CLIENT_JOB_MATCH_DELETE_ERROR = 'CLIENT_JOB_MATCH_DELETE_ERROR';

export const CLIENT_JOB_MATCH_GET_SINGLE_BY_QUERY = 'CLIENT_JOB_MATCH_GET_SINGLE_BY_QUERY';

export const CLIENT_JOB_MATCH_GET_BY_QUERY = 'CLIENT_JOB_MATCH_GET_BY_QUERY';
export const CLIENT_JOB_MATCH_GET_BY_QUERY_SUCCESS = 'CLIENT_JOB_MATCH_GET_BY_QUERY_SUCCESS';
export const CLIENT_JOB_MATCH_GET_BY_QUERY_ERROR = 'CLIENT_JOB_MATCH_GET_BY_QUERY_ERROR';
