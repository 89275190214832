// PLAN_CONTENT
export const PLAN_CONTENT_GET_BY_ID = 'PLAN_CONTENT_GET_BY_ID';
export const PLAN_CONTENT_GET_BY_ID_SUCCESS = 'PLAN_CONTENT_GET_BY_ID_SUCCESS';
export const PLAN_CONTENT_GET_BY_ID_ERROR = 'PLAN_CONTENT_GET_BY_ID_ERROR';

export const PLAN_CONTENT_GET_LIST = 'PLAN_CONTENT_GET_LIST';
export const PLAN_CONTENT_GET_LIST_SUCCESS = 'PLAN_CONTENT_GET_LIST_SUCCESS';
export const PLAN_CONTENT_GET_LIST_ERROR = 'PLAN_CONTENT_GET_LIST_ERROR';

export const PLAN_CONTENT_EDIT = 'PLAN_CONTENT_EDIT';
export const PLAN_CONTENT_EDIT_SUCCESS = 'PLAN_CONTENT_EDIT_SUCCESS';
export const PLAN_CONTENT_EDIT_ERROR = 'PLAN_CONTENT_EDIT_ERROR';

export const PLAN_CONTENT_ADD_NEW = 'PLAN_CONTENT_ADD_NEW';
export const PLAN_CONTENT_ADD_NEW_SUCCESS = 'PLAN_CONTENT_ADD_NEW_SUCCESS';
export const PLAN_CONTENT_ADD_NEW_ERROR = 'PLAN_CONTENT_ADD_NEW_ERROR';
export const PLAN_CONTENT_ADD_NEW_REQUEST = 'PLAN_CONTENT_ADD_NEW_REQUEST';

export const PLAN_CONTENT_DELETE = 'PLAN_CONTENT_DELETE';
export const PLAN_CONTENT_DELETE_SUCCESS = 'PLAN_CONTENT_DELETE_SUCCESS';
export const PLAN_CONTENT_DELETE_ERROR = 'PLAN_CONTENT_DELETE_ERROR';

export const PLAN_CONTENT_GET_BY_QUERY = 'PLAN_CONTENT_GET_BY_QUERY';
export const PLAN_CONTENT_GET_BY_QUERY_SUCCESS = 'PLAN_CONTENT_GET_BY_QUERY_SUCCESS';
export const PLAN_CONTENT_GET_BY_QUERY_ERROR = 'PLAN_CONTENT_GET_BY_QUERY_ERROR';
