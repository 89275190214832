import {
  ADDITIONAL_INFO_ADD_NEW,
  ADDITIONAL_INFO_ADD_NEW_SUCCESS,
  ADDITIONAL_INFO_ADD_NEW_ERROR,
  ADDITIONAL_INFO_EDIT,
  ADDITIONAL_INFO_EDIT_ERROR,
  ADDITIONAL_INFO_EDIT_SUCCESS,
  ADDITIONAL_INFO_GET_BY_ID,
  ADDITIONAL_INFO_GET_BY_ID_ERROR,
  ADDITIONAL_INFO_GET_BY_ID_SUCCESS,
  ADDITIONAL_INFO_GET_LIST,
  ADDITIONAL_INFO_GET_LIST_ERROR,
  ADDITIONAL_INFO_GET_LIST_SUCCESS,
  ADDITIONAL_INFO_ADD_NEW_REQUEST,
  ADDITIONAL_INFO_CLOSED_NOTIFICATION_UPDATE,
} from '../actions';

const initialState = {
  additionalInfo: null,
  showNotification: false,
  loading: false,
  error: null,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADDITIONAL_INFO_GET_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case ADDITIONAL_INFO_CLOSED_NOTIFICATION_UPDATE:
      return {
        ...state,
        loading: false,
        error: null,
        showNotification: false,
      };
    case ADDITIONAL_INFO_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        actionPlan: payload,
      };

    case ADDITIONAL_INFO_ADD_NEW_REQUEST:
      return {
        ...state,
        addSuccess: false,
      };
    case ADDITIONAL_INFO_GET_LIST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ADDITIONAL_INFO_GET_LIST_SUCCESS:
      return {
        ...state,
        additionalInfo: payload,
        loading: false,
      };
    case ADDITIONAL_INFO_ADD_NEW:
      return {
        ...state,
        loading: true,
        error: null,
        addSuccess: false,
      };

    case ADDITIONAL_INFO_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        additionalInfo: payload,
        showNotification: true,
      };

    case ADDITIONAL_INFO_EDIT:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case ADDITIONAL_INFO_EDIT_SUCCESS:
      return {
        ...state,
        additionalInfo: payload,
        error: null,
        loading: false,
        showNotification: true,
      };

    case ADDITIONAL_INFO_ADD_NEW_ERROR:
    case ADDITIONAL_INFO_EDIT_ERROR:
    case ADDITIONAL_INFO_GET_BY_ID_ERROR:
    case ADDITIONAL_INFO_GET_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
