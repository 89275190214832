import {
  NOTE_ADD_NEW,
  NOTE_ADD_NEW_SUCCESS,
  NOTE_ADD_NEW_ERROR,
  NOTE_EDIT,
  NOTE_EDIT_ERROR,
  NOTE_EDIT_SUCCESS,
  NOTE_GET_BY_QUERY,
  NOTE_GET_BY_ID,
  NOTE_GET_BY_QUERY_SUCCESS,
  NOTE_GET_BY_QUERY_ERROR,
  NOTE_GET_BY_ID_SUCCESS,
  NOTE_ADD_NEW_REQUEST,
  NOTE_DELETE,
  NOTE_DELETE_SUCCESS,
  NOTE_DELETE_ERROR,
  NOTE_GET_BY_ID_ERROR,
} from './types';

// Note

export const addNewNote = items => ({
  type: NOTE_ADD_NEW,
  payload: items,
});

export const addNewNoteRequest = () => ({
  type: NOTE_ADD_NEW_REQUEST,
});

export const addNewNoteSuccess = items => ({
  type: NOTE_ADD_NEW_SUCCESS,
  payload: items,
});

export const addNewNoteError = error => ({
  type: NOTE_ADD_NEW_ERROR,
  payload: error,
});

export const editNote = item => ({
  type: NOTE_EDIT,
  payload: item,
});

export const editNoteSuccess = item => ({
  type: NOTE_EDIT_SUCCESS,
  payload: item,
});

export const editNoteError = error => ({
  type: NOTE_EDIT_ERROR,
  payload: error,
});

export const getNoteByQuery = query => ({
  type: NOTE_GET_BY_QUERY,
  payload: query,
});

export const getNoteByQuerySuccess = item => ({
  type: NOTE_GET_BY_QUERY_SUCCESS,
  payload: item,
});

export const getNoteByQueryError = error => ({
  type: NOTE_GET_BY_QUERY_ERROR,
  payload: error,
});

export const getNoteById = id => ({
  type: NOTE_GET_BY_ID,
  payload: id,
});

export const getNoteByIdSuccess = item => ({
  type: NOTE_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getNoteByIdError = error => ({
  type: NOTE_GET_BY_ID_ERROR,
  payload: error,
});

export const deleteNoteById = id => ({
  type: NOTE_DELETE,
  payload: id,
});

export const deleteNoteByIdSuccess = item => ({
  type: NOTE_DELETE_SUCCESS,
  payload: item,
});

export const deleteNoteByIdErrorr = item => ({
  type: NOTE_DELETE_ERROR,
  payload: item,
});
