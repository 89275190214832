// FINANCIAL_TRACKING
export const FINANCIAL_TRACKING_GET_LIST = 'FINANCIAL_TRACKING_GET_LIST';
export const FINANCIAL_TRACKING_GET_LIST_SUCCESS = 'FINANCIAL_TRACKING_GET_LIST_SUCCESS';
export const FINANCIAL_TRACKING_GET_BY_ID = 'FINANCIAL_TRACKING_GET_BY_ID';
export const FINANCIAL_TRACKING_GET_BY_ID_SUCCESS = 'FINANCIAL_TRACKING_GET_BY_ID_SUCCESS';

export const FINANCIAL_TRACKING_ADD_NEW = 'FINANCIAL_TRACKING_ADD_NEW';
export const FINANCIAL_TRACKING_ADD_NEW_REQUEST = 'FINANCIAL_TRACKING_ADD_NEW_REQUEST';

export const FINANCIAL_TRACKING_STATUS_CHANGE = 'FINANCIAL_TRACKING_STATUS_CHANGE';
export const FINANCIAL_TRACKING_STATUS_CHANGE_SUCCESS = 'FINANCIAL_TRACKING_STATUS_CHANGE_SUCCESS';
export const FINANCIAL_TRACKING_RESULT_CHANGE = 'FINANCIAL_TRACKING_RESULT_CHANGE';
export const FINANCIAL_TRACKING_EDIT = 'FINANCIAL_TRACKING_EDIT';
export const FINANCIAL_TRACKING_DELETE = 'FINANCIAL_TRACKING_DELETE';
export const FINANCIAL_TRACKING_ERROR = 'FINANCIAL_TRACKING_ERROR';
