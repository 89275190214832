import {
  TASK_MANGEMENT_ADD_NEW,
  TASK_MANGEMENT_ADD_NEW_SUCCESS,
  TASK_MANGEMENT_ADD_NEW_ERROR,
  TASK_MANGEMENT_EDIT,
  TASK_MANGEMENT_EDIT_ERROR,
  TASK_MANGEMENT_EDIT_SUCCESS,
  TASK_MANGEMENT_GET_BY_QUERY,
  TASK_MANGEMENT_GET_BY_ID,
  TASK_MANGEMENT_GET_BY_QUERY_SUCCESS,
  TASK_MANGEMENT_GET_BY_QUERY_ERROR,
  TASK_MANGEMENT_GET_BY_ID_SUCCESS,
  TASK_MANGEMENT_ADD_NEW_REQUEST,
  TASK_MANGEMENT_DELETE,
  TASK_MANGEMENT_DELETE_SUCCESS,
  TASK_MANGEMENT_DELETE_ERROR,
  TASK_MANGEMENT_GET_BY_ID_ERROR,
  TASK_MANGEMENT_STATUS_CHANGE,
  TASK_MANGEMENT_STATUS_CHANGE_SUCCESS,
  TASK_MANGEMENT_STATUS_CHANGE_ERROR,
} from './types';

// TaskManagement

export const addNewTaskManagement = items => ({
  type: TASK_MANGEMENT_ADD_NEW,
  payload: items,
});

export const addNewTaskManagementRequest = () => ({
  type: TASK_MANGEMENT_ADD_NEW_REQUEST,
});

export const addNewTaskManagementSuccess = items => ({
  type: TASK_MANGEMENT_ADD_NEW_SUCCESS,
  payload: items,
});

export const addNewTaskManagementError = error => ({
  type: TASK_MANGEMENT_ADD_NEW_ERROR,
  payload: error,
});

export const editTaskManagement = item => ({
  type: TASK_MANGEMENT_EDIT,
  payload: item,
});

export const editTaskManagementSuccess = item => ({
  type: TASK_MANGEMENT_EDIT_SUCCESS,
  payload: item,
});

export const editTaskManagementError = error => ({
  type: TASK_MANGEMENT_EDIT_ERROR,
  payload: error,
});

export const getTaskManagementByQuery = query => ({
  type: TASK_MANGEMENT_GET_BY_QUERY,
  payload: query,
});

export const getTaskManagementByQuerySuccess = item => ({
  type: TASK_MANGEMENT_GET_BY_QUERY_SUCCESS,
  payload: item,
});

export const getTaskManagementByQueryError = error => ({
  type: TASK_MANGEMENT_GET_BY_QUERY_ERROR,
  payload: error,
});

export const getTaskManagementById = id => ({
  type: TASK_MANGEMENT_GET_BY_ID,
  payload: id,
});

export const getTaskManagementByIdSuccess = item => ({
  type: TASK_MANGEMENT_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getTaskManagementByIdError = error => ({
  type: TASK_MANGEMENT_GET_BY_ID_ERROR,
  payload: error,
});

export const deleteTaskManagementById = id => ({
  type: TASK_MANGEMENT_DELETE,
  payload: id,
});

export const deleteTaskManagementByIdSuccess = item => ({
  type: TASK_MANGEMENT_DELETE_SUCCESS,
  payload: item,
});

export const deleteTaskManagementByIdErrorr = item => ({
  type: TASK_MANGEMENT_DELETE_ERROR,
  payload: item,
});

export const changeTaskManagementStatus = id => ({
  type: TASK_MANGEMENT_STATUS_CHANGE,
  payload: id,
});

export const changeTaskManagementStatusSuccess = actionPlan => ({
  type: TASK_MANGEMENT_STATUS_CHANGE_SUCCESS,
  payload: actionPlan,
});

export const changeTaskManagementStatusError = error => ({
  type: TASK_MANGEMENT_STATUS_CHANGE_ERROR,
  payload: error,
});
