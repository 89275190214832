import {
  DISMISS_ALL_NOTIFICATION,
  GET_NOTIFICATION_BY_QUERY,
  GET_NOTIFICATION_BY_QUERY_SUCCESS,
  READ_NOTIFICATION,
  VIEW_NOTIFICATION,
} from './types';

const initState = {
  items: [],
  item: null,
  error: null,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

export default function reducerState(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case DISMISS_ALL_NOTIFICATION:
    case READ_NOTIFICATION:
    case VIEW_NOTIFICATION:
    case GET_NOTIFICATION_BY_QUERY:
      return {
        ...state,
        loading: true,
      };
    case GET_NOTIFICATION_BY_QUERY_SUCCESS: {
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        items: data,
        loading: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
