import {
  GET_DOCUMENTS_BY_CLIENT_ID,
  GET_DOCUMENTS_BY_CLIENT_ID_SUCCESS,
  GET_DOCUMENTS_BY_CLIENT_ID_ERROR,
  GET_DOCUMENTS_BY_ID,
  GET_DOCUMENTS_BY_ID_SUCCESS,
  GET_DOCUMENTS_BY_ID_ERROR,
  UPDATE_DOCUMENTS,
  UPDATE_DOCUMENTS_SUCCESS,
  UPDATE_DOCUMENTS_ERROR,
  SAVE_DOCUMENTS,
  SAVE_DOCUMENTS_SUCCESS,
  SAVE_DOCUMENTS_ERROR,
  DELETE_DOCUMENT_BY_ID,
  DELETE_DOCUMENT_BY_ID_SUCCESS,
  DELETE_DOCUMENT_BY_ID_ERROR,
} from '../actions';

export const getDocumentsByClientId = query => ({
  type: GET_DOCUMENTS_BY_CLIENT_ID,
  payload: query,
});

export const getDocumentsByClientIdSuccess = documents => ({
  type: GET_DOCUMENTS_BY_CLIENT_ID_SUCCESS,
  payload: documents,
});

export const getDocumentsByClientIdError = error => ({
  type: GET_DOCUMENTS_BY_CLIENT_ID_ERROR,
  payload: error,
});

export const saveDocuments = (clientId, documents) => ({
  type: SAVE_DOCUMENTS,
  payload: { clientId, documents },
});

export const saveDocumentsSuccess = clientId => ({
  type: SAVE_DOCUMENTS_SUCCESS,
  payload: clientId,
});

export const saveDocumentsError = error => ({
  type: SAVE_DOCUMENTS_ERROR,
  payload: error,
});

export const deleteDocumentById = (clientId, id, programId, servicePlanHeaderId) => ({
  type: DELETE_DOCUMENT_BY_ID,
  payload: { clientId, id, programId, servicePlanHeaderId },
});

export const deleteDocumentByIdSuccess = clientId => ({
  type: DELETE_DOCUMENT_BY_ID_SUCCESS,
  payload: clientId,
});

export const deleteDocumentByIdError = error => ({
  type: DELETE_DOCUMENT_BY_ID_ERROR,
  payload: error,
});

export const getDocumentsById = id => ({
  type: GET_DOCUMENTS_BY_ID,
  payload: id,
});

export const getDocumentsByIdSuccess = document => ({
  type: GET_DOCUMENTS_BY_ID_SUCCESS,
  payload: document,
});

export const getDocumentsByIdError = error => ({
  type: GET_DOCUMENTS_BY_ID_ERROR,
  payload: error,
});

export const updateDocument = document => ({
  type: UPDATE_DOCUMENTS,
  payload: document,
});

export const updateDocumentSuccess = document => ({
  type: UPDATE_DOCUMENTS_SUCCESS,
  payload: document,
});

export const updateDocumentError = error => ({
  type: UPDATE_DOCUMENTS_ERROR,
  payload: error,
});
