import { displayMessage, SEVERITY } from '../../helpers/Utils';
import IntlMessages from '../../helpers/IntlMessages';
import {
  EMPLOYER_CASE_NOTE_GET_PAGING,
  EMPLOYER_CASE_NOTE_GET_PAGING_ERROR,
  EMPLOYER_CASE_NOTE_GET_PAGING_SUCCESS,
  EMPLOYER_CASE_NOTE_ADD_NEW_SUCCESS,
  EMPLOYER_CASE_NOTE_ADD_NEW_ERROR,
  EMPLOYER_CASE_NOTE_GET_BY_ID,
  EMPLOYER_CASE_NOTE_GET_BY_ID_ERROR,
  EMPLOYER_CASE_NOTE_GET_BY_ID_SUCCESS,
  EMPLOYER_CASE_NOTE_EDIT_ERROR,
  EMPLOYER_CASE_NOTE_EDIT_SUCCESS,
  EMPLOYER_CASE_NOTE_DELETE,
  EMPLOYER_CASE_NOTE_DELETE_ERROR,
  EMPLOYER_CASE_NOTE_DELETE_SUCCESS,
} from './types';

const initialState = {
  items: [],
  item: null,
  loading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case EMPLOYER_CASE_NOTE_GET_PAGING:
    case EMPLOYER_CASE_NOTE_GET_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case EMPLOYER_CASE_NOTE_GET_PAGING_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        loading: false,
        itemCount,
        pageCount,
        items: data,
      };
    case EMPLOYER_CASE_NOTE_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        item: payload,
      };

    case EMPLOYER_CASE_NOTE_ADD_NEW_SUCCESS:
      displayMessage({
        title: <IntlMessages id="employerCaseNote" />,
        message: <IntlMessages id="caseNoteAddedMessage" />,
        severity: SEVERITY.INFO,
      });
      return {
        ...state,
        loading: false,
        item: payload,
      };
    case EMPLOYER_CASE_NOTE_EDIT_SUCCESS:
      displayMessage({
        title: <IntlMessages id="employerCaseNote" />,
        message: <IntlMessages id="caseNoteUpdatedMessage" />,
        severity: SEVERITY.INFO,
      });
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EMPLOYER_CASE_NOTE_DELETE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case EMPLOYER_CASE_NOTE_DELETE_SUCCESS:
      displayMessage({
        title: <IntlMessages id="employerCaseNote" />,
        message: <IntlMessages id="caseNoteDeletedMessage" />,
        severity: SEVERITY.INFO,
      });
      return {
        ...state,
        loading: true,
      };

    case EMPLOYER_CASE_NOTE_GET_PAGING_ERROR:
    case EMPLOYER_CASE_NOTE_DELETE_ERROR:
    case EMPLOYER_CASE_NOTE_ADD_NEW_ERROR:
    case EMPLOYER_CASE_NOTE_EDIT_ERROR:
    case EMPLOYER_CASE_NOTE_GET_BY_ID_ERROR:
      displayMessage({
        title: <IntlMessages id="employerCaseNote" />,
        message: payload,
        severity: SEVERITY.DANGER,
      });
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
