import {
  APT_GET_BY_CLIENT_ID,
  APT_GET_BY_CLIENT_ID_SUCCESS,
  APT_GET_BY_CLIENT_ID_ERROR,
  APT_SAVE,
  APT_SAVE_SUCCESS,
  APT_SAVE_ERROR,
} from './types';

export const getAptByParams = payload => ({
  type: APT_GET_BY_CLIENT_ID,
  payload,
});

export const getAptByParamsSuccess = payload => ({
  type: APT_GET_BY_CLIENT_ID_SUCCESS,
  payload,
});

export const getAptByParamsError = payload => ({
  type: APT_GET_BY_CLIENT_ID_ERROR,
  payload,
});

export const saveApt = payload => ({
  type: APT_SAVE,
  payload,
});

export const saveAptSuccess = payload => ({
  type: APT_SAVE_SUCCESS,
  payload,
});

export const saveAptError = payload => ({
  type: APT_SAVE_ERROR,
  payload,
});
