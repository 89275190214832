import {
  NOTIFICATION_REPORT_GET_LIST,
  NOTIFICATION_REPORT_GET_LIST_SUCCESS,
  NOTIFICATION_REPORT_GET_LIST_ERROR,
} from '../../actions';

export const getNotificationReportList = filter => ({
  type: NOTIFICATION_REPORT_GET_LIST,
  payload: filter,
});

export const getNotificationReportListSuccess = items => ({
  type: NOTIFICATION_REPORT_GET_LIST_SUCCESS,
  payload: items,
});

export const getNotificationReportListError = error => ({
  type: NOTIFICATION_REPORT_GET_LIST_ERROR,
  payload: error,
});
