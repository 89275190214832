import {
  SE_CASE_NOTE_ADD_NEW,
  SE_CASE_NOTE_ADD_NEW_SUCCESS,
  SE_CASE_NOTE_ADD_NEW_ERROR,
  SE_CASE_NOTE_DELETE,
  SE_CASE_NOTE_DELETE_ERROR,
  SE_CASE_NOTE_DELETE_SUCCESS,
  SE_CASE_NOTE_EDIT,
  SE_CASE_NOTE_EDIT_ERROR,
  SE_CASE_NOTE_EDIT_SUCCESS,
  SE_CASE_NOTE_GET_BY_ID,
  SE_CASE_NOTE_GET_BY_ID_ERROR,
  SE_CASE_NOTE_GET_BY_ID_SUCCESS,
  SE_CASE_NOTE_GET_LIST,
  SE_CASE_NOTE_GET_LIST_ERROR,
  SE_CASE_NOTE_GET_LIST_SUCCESS,
  SE_CASE_NOTE_ADD_NEW_REQUEST,
  SE_CASE_NOTE_QUERY,
  SE_CASE_NOTE_QUERY_SUCCESS,
  SE_CASE_NOTE_QUERY_ERROR,
} from '../actions';

// SECaseNote
export const getSECaseNoteQuery = query => ({
  type: SE_CASE_NOTE_QUERY,
  payload: query,
});

export const getSECaseNoteQuerySuccess = caseNotes => ({
  type: SE_CASE_NOTE_QUERY_SUCCESS,
  payload: caseNotes,
});

export const getSECaseNoteQueryError = error => ({
  type: SE_CASE_NOTE_QUERY_ERROR,
  payload: error,
});

export const getSECaseNoteList = clientId => ({
  type: SE_CASE_NOTE_GET_LIST,
  payload: clientId,
});

export const getSECaseNoteListSuccess = CaseNotes => ({
  type: SE_CASE_NOTE_GET_LIST_SUCCESS,
  payload: CaseNotes,
});

export const getSECaseNoteListError = error => ({
  type: SE_CASE_NOTE_GET_LIST_ERROR,
  payload: error,
});

export const addNewSECaseNote = caseNote => ({
  type: SE_CASE_NOTE_ADD_NEW,
  payload: caseNote,
});

export const addNewSECaseNoteRequest = () => ({
  type: SE_CASE_NOTE_ADD_NEW_REQUEST,
});

export const addNewSECaseNoteSuccess = CaseNote => ({
  type: SE_CASE_NOTE_ADD_NEW_SUCCESS,
  payload: CaseNote,
});

export const addNewSECaseNoteError = error => ({
  type: SE_CASE_NOTE_ADD_NEW_ERROR,
  payload: error,
});

export const editSECaseNote = CaseNote => ({
  type: SE_CASE_NOTE_EDIT,
  payload: CaseNote,
});

export const editSECaseNoteSuccess = caseNote => ({
  type: SE_CASE_NOTE_EDIT_SUCCESS,
  payload: caseNote,
});

export const editSECaseNoteError = error => ({
  type: SE_CASE_NOTE_EDIT_ERROR,
  payload: error,
});

export const deleteSECaseNote = id => ({
  type: SE_CASE_NOTE_DELETE,
  payload: id,
});

export const deleteSECaseNoteSuccess = id => ({
  type: SE_CASE_NOTE_DELETE_SUCCESS,
  payload: id,
});

export const deleteSECaseNoteError = error => ({
  type: SE_CASE_NOTE_DELETE_ERROR,
  payload: error,
});

export const getSECaseNoteById = id => ({
  type: SE_CASE_NOTE_GET_BY_ID,
  payload: id,
});

export const getSECaseNoteByIdSuccess = caseNote => ({
  type: SE_CASE_NOTE_GET_BY_ID_SUCCESS,
  payload: caseNote,
});

export const getSECaseNoteByIdError = error => ({
  type: SE_CASE_NOTE_GET_BY_ID_ERROR,
  payload: error,
});
