import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  FORM_DOCUMENT_DELETE,
  FORM_DOCUMENT_EDIT,
  FORM_DOCUMENT_GET_BY_ID,
  FORM_DOCUMENT_ADD_NEW,
  FORM_DOCUMENT_GET_LIST,
} from '../actions';

import {
  getFormDocumentListSuccess,
  getFormDocumentListError,
  editFormDocumentSuccess,
  editFormDocumentError,
  addNewFormDocumentSuccess,
  addNewFormDocumentError,
  deleteFormDocumentSuccess,
  deleteFormDocumentError,
  getFormDocumentByIdSuccess,
} from './actions';

const getFormDocumentListRequest = async () => {
  return axios.get(`${Config.apiServerHost}/api/FormDocument`, await authHeader());
};

const addNewFormDocumentReqeust = async caseNote => {
  return axios.post(`${Config.apiServerHost}/api/FormDocument`, caseNote, await authHeader());
};

const editFormDocumentRequest = async caseNote => {
  return axios.put(`${Config.apiServerHost}/api/FormDocument`, caseNote, await authHeader());
};

const deleteFormDocumentRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/FormDocument/${id}`, await authHeader());
};

const getFormDocumentByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/FormDocument/${id}`, await authHeader());
};

function* getFormDocumentList() {
  try {
    const response = yield call(getFormDocumentListRequest);
    yield put(getFormDocumentListSuccess(response.data));
  } catch (error) {
    yield put(getFormDocumentListError(error.response.statusText));
  }
}

function* addNewFormDocument({ payload }) {
  try {
    let response = yield call(addNewFormDocumentReqeust, payload);
    response = yield call(getFormDocumentByIdRequest, response.data);
    yield put(addNewFormDocumentSuccess(response.data));
  } catch (error) {
    yield put(addNewFormDocumentError(error.response.statusText));
  }
}

function* editFormDocument({ payload }) {
  try {
    let response = yield call(editFormDocumentRequest, payload);
    response = yield call(getFormDocumentByIdRequest, payload.id);
    yield put(editFormDocumentSuccess(response.data));
  } catch (error) {
    yield put(editFormDocumentError(error.response.statusText));
  }
}

function* getFormDocumentById({ payload }) {
  try {
    const response = yield call(getFormDocumentByIdRequest, payload);
    yield put(getFormDocumentByIdSuccess(response.data));
  } catch (error) {
    yield put(editFormDocumentError(error.response.statusText));
  }
}

function* deleteFormDocument({ payload }) {
  try {
    const response = yield call(deleteFormDocumentRequest, payload);
    yield put(deleteFormDocumentSuccess(response.data));
  } catch (error) {
    yield put(deleteFormDocumentError(error.response.statusText));
  }
}

export function* watchFormDocumentList() {
  yield takeEvery(FORM_DOCUMENT_GET_LIST, getFormDocumentList);
}

export function* watchAddNewFormDocument() {
  yield takeEvery(FORM_DOCUMENT_ADD_NEW, addNewFormDocument);
}

export function* watchFormDocumentById() {
  yield takeEvery(FORM_DOCUMENT_GET_BY_ID, getFormDocumentById);
}

export function* watchEditFormDocument() {
  yield takeEvery(FORM_DOCUMENT_EDIT, editFormDocument);
}

export function* watchDeleteFormDocument() {
  yield takeEvery(FORM_DOCUMENT_DELETE, deleteFormDocument);
}

export default function* rootSaga() {
  yield all([
    fork(watchFormDocumentList),
    fork(watchAddNewFormDocument),
    fork(watchFormDocumentById),
    fork(watchEditFormDocument),
    fork(watchDeleteFormDocument),
  ]);
}
