import {
  SURVEY_NOTIFICATION_GET_PAGING,
  SURVEY_NOTIFICATION_GET_PAGING_ERROR,
  SURVEY_NOTIFICATION_GET_PAGING_SUCCESS,
  SURVEY_NOTIFICATION_TURN_OFF,
  SURVEY_NOTIFICATION_TURN_OFF_ERROR,
  SURVEY_NOTIFICATION_TURN_OFF_SUCCESS,
} from './types';

export const getSurveyNotificationPaging = payload => ({
  type: SURVEY_NOTIFICATION_GET_PAGING,
  payload,
});

export const getSurveyNotificationPagingError = error => ({
  type: SURVEY_NOTIFICATION_GET_PAGING_ERROR,
  payload: error,
});

export const getSurveyNotificationPagingSuccess = settings => ({
  type: SURVEY_NOTIFICATION_GET_PAGING_SUCCESS,
  payload: settings,
});

export const onOrOffNotification = payload => ({
  type: SURVEY_NOTIFICATION_TURN_OFF,
  payload,
});

export const onOrOffNotificationError = error => ({
  type: SURVEY_NOTIFICATION_TURN_OFF_ERROR,
  payload: error,
});

export const onOrOffNotificationSuccess = settings => ({
  type: SURVEY_NOTIFICATION_TURN_OFF_SUCCESS,
  payload: settings,
});
