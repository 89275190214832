/* eslint-disable import/no-anonymous-default-export */

import {
  CAMS_CLIENT_FETCH,
  CAMS_CLIENT_FETCH_SUCCESS,
  CAMS_CLIENT_FETCH_ERROR,
  CAMS_OVERWRITE_UPDATE,
  CAMS_OVERWRITE_UPDATE_SUCCESS,
  CAMS_OVERWRITE_UPDATE_ERROR,
  CAMS_SELECTED_PLANNED_ITEM,
  CAMS_SELECTED_ACTIVE_TAB,
  CAMS_TOGGLE_SUBGOAL,
  CAMS_SELECT_OUTCOME_JOB,
  CAMS_SELECT_CHECKPOINT_JOB,
  CAMS_EMPLOYER_FETCH,
  CAMS_EMPLOYER_FETCH_SUCCESS,
  CAMS_EMPLOYER_FETCH_ERROR,
  CAMS_EMPLOYER_OVERWRITE_UPDATE,
  CAMS_EMPLOYER_OVERWRITE_UPDATE_SUCCESS,
  CAMS_EMPLOYER_OVERWRITE_UPDATE_ERROR,
} from '../actions';

const initialState = {
  data: null,
  loading: false,
  error: null,
  activeEapDetail: false,
  activeSubGoals: false,
  activeSubGoal: false,
  activePlannItems: false,
  activeTab: '0',
  selectedPlannItem: null,
  activeOutcome: false,
  activeCheckpoint: false,
  activeCheckpoints: false,
  selectedOutcome: null,
  selectedCheckpoint: null,

  employerData: null,

  employerLoading: false,
  employerError: null,

  employerOverwriteLoading: false,
  employerOverwriteError: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CAMS_CLIENT_FETCH:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CAMS_OVERWRITE_UPDATE:
      return {
        ...state,
        overwriteLoading: true,
        error: null,
      };
    case CAMS_OVERWRITE_UPDATE_SUCCESS:
      return {
        ...state,
        overwriteLoading: false,
        error: null,
      };
    case CAMS_CLIENT_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        overwriteLoading: false,
        error: null,
        data: payload,
      };
    case CAMS_CLIENT_FETCH_ERROR:
    case CAMS_OVERWRITE_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        overwriteLoading: false,
        error: payload,
        data: null,
      };

    case CAMS_SELECTED_PLANNED_ITEM:
      return {
        ...state,
        activeEapDetail: true,
        activeSubGoals: true,
        activeSubGoal: true,
        activePlannItems: true,
        activeOutcome: false,
        activeCheckpoint: false,
        activeTab: '1',

        selectedPlannItem: payload,
      };

    case CAMS_SELECTED_ACTIVE_TAB:
      return {
        ...state,
        activeTab: payload,
      };
    case CAMS_TOGGLE_SUBGOAL:
      return {
        ...state,
        activeSubGoal: payload,
      };

    case CAMS_SELECT_OUTCOME_JOB:
      return {
        ...state,
        activeEapDetail: true,
        activeSubGoals: true,
        activeSubGoal: false,
        activeOutcome: true,
        activeCheckpoint: false,
        activeCheckpoints: false,

        activeTab: '2',
        selectedOutcome: payload,
      };
    case CAMS_SELECT_CHECKPOINT_JOB:
      return {
        ...state,
        activeEapDetail: true,
        activeSubGoals: true,
        activeSubGoal: false,
        activeOutcome: false,
        activeCheckpoint: true,
        activeCheckpoints: true,
        activeTab: '3',
        selectedCheckpoint: payload,
      };
    case CAMS_EMPLOYER_FETCH:
      return {
        ...state,
        employerLoading: true,
        employerError: null,
      };
    case CAMS_EMPLOYER_FETCH_SUCCESS:
      return {
        ...state,
        employerLoading: false,
        employerError: null,
        employerData: payload,
      };
    case CAMS_EMPLOYER_FETCH_ERROR:
      return {
        ...state,
        employerLoading: false,
        employerError: payload,
      };
    case CAMS_EMPLOYER_OVERWRITE_UPDATE:
      return {
        ...state,
        employerOverwriteLoading: true,
        employerOverwriteError: null,
      };
    case CAMS_EMPLOYER_OVERWRITE_UPDATE_SUCCESS:
      return {
        ...state,
        employerOverwriteLoading: false,
        employerOverwriteError: null,
      };
    case CAMS_EMPLOYER_OVERWRITE_UPDATE_ERROR:
      return {
        ...state,
        employerOverwriteLoading: false,
        employerOverwriteError: payload,
      };

    default:
      return { ...state };
  }
};
