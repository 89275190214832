import {
  MILEAGE_ENTRY_ADD_NEW,
  MILEAGE_ENTRY_ADD_NEW_SUCCESS,
  MILEAGE_ENTRY_ADD_NEW_ERROR,
  MILEAGE_ENTRY_DELETE,
  MILEAGE_ENTRY_DELETE_ERROR,
  MILEAGE_ENTRY_DELETE_SUCCESS,
  MILEAGE_ENTRY_EDIT,
  MILEAGE_ENTRY_EDIT_ERROR,
  MILEAGE_ENTRY_EDIT_SUCCESS,
  MILEAGE_ENTRY_GET_BY_ID,
  MILEAGE_ENTRY_GET_BY_ID_ERROR,
  MILEAGE_ENTRY_GET_BY_ID_SUCCESS,
  MILEAGE_ENTRY_GET_LIST_BY_MILEAGE_ID,
  MILEAGE_ENTRY_GET_LIST_BY_MILEAGE_ID_ERROR,
  MILEAGE_ENTRY_GET_LIST_BY_MILEAGE_ID_SUCCESS,
  MILEAGE_ENTRY_ADD_NEW_REQUEST,
  MILEAGE_CALCULATE_DISTANCE_ERROR,
} from '../actions';

const initialState = {
  entries: [],
  mileage: null,
  entry: null,
  loading: false,
  error: null,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
  directionsData: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case MILEAGE_ENTRY_GET_BY_ID:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case MILEAGE_ENTRY_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        entry: payload,
      };

    case MILEAGE_ENTRY_ADD_NEW_REQUEST:
      return {
        ...state,
        addSuccess: false,
      };

    case MILEAGE_ENTRY_GET_LIST_BY_MILEAGE_ID:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case MILEAGE_ENTRY_GET_LIST_BY_MILEAGE_ID_SUCCESS:
      return {
        ...state,
        entries: payload,
        loading: false,
      };

    case MILEAGE_ENTRY_ADD_NEW:
      return {
        ...state,
        loading: true,
        error: null,
        addSuccess: false,
      };

    case MILEAGE_ENTRY_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        entry: payload,
        entries: state.entries.concat(payload),
        addSuccess: true,
      };

    case MILEAGE_ENTRY_EDIT:
      return {
        ...state,
        loading: true,
        error: null,
        editSuccess: false,
      };
    case MILEAGE_ENTRY_EDIT_SUCCESS:
      return {
        ...state,
        entries: state.entries.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        entry: payload,
        error: null,
        loading: false,
        editSuccess: true,
      };

    case MILEAGE_ENTRY_DELETE:
      return {
        ...state,
        loading: true,
        error: null,
        deleteSuccess: false,
      };
    case MILEAGE_ENTRY_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        entries: state.entries.filter(c => c.id !== payload),
        deleteSuccess: true,
      };

    case MILEAGE_ENTRY_DELETE_ERROR:
    case MILEAGE_ENTRY_ADD_NEW_ERROR:
    case MILEAGE_ENTRY_EDIT_ERROR:
    case MILEAGE_ENTRY_GET_BY_ID_ERROR:
    case MILEAGE_ENTRY_GET_LIST_BY_MILEAGE_ID_ERROR:
    case MILEAGE_CALCULATE_DISTANCE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
