import {
  ROLE_GET_LIST,
  ROLE_GET_LIST_SUCCESS,
  ROLE_GET_LIST_ERROR,
  ROLE_GET_LIST_SEARCH,
  ROLE_DELETE_ITEM,
  ROLE_DELETE_ITEM_SUCCESS,
  ROLE_DELETE_ITEM_ERROR,
  ROLE_GET_BY_ID,
  ROLE_GET_BY_ID_SUCCESS,
  ROLE_GET_BY_ID_ERROR,
  CLOSE_ROLE_ERROR_NOTIFICATION,
  GET_PERMISSION_LIST,
  GET_PERMISSION_LIST_SUCCESS,
  GET_PERMISSION_LIST_ERROR,
  ROLE_ADD_OR_UPDATE_ITEM,
} from '../actions';

const INIT_STATE = {
  roleList: null,
  roles: [],
  role: null,
  error: '',
  filter: null,
  searchKeyword: '',
  orderColumn: null,
  loading: false,
  errorNotification: false,
  statusCode: 0,
  itemCount: 0,
  pageCount: 0,
};

export default function initState(state = INIT_STATE, action) {
  const { payload, type } = action;
  switch (type) {
    case ROLE_ADD_OR_UPDATE_ITEM:
    case ROLE_GET_LIST:
      return {
        ...state,
        loading: true,
      };

    case GET_PERMISSION_LIST:
      return {
        ...state,
        loading: true,
      };

    case GET_PERMISSION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        role: {
          id: 0,
          name: '',
          permissions: payload,
        },
      };

    case ROLE_GET_BY_ID:
      return {
        ...state,
        loading: true,
      };

    case ROLE_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        role: payload,
      };

    case ROLE_GET_LIST_SUCCESS:
      const { itemCount, pageCount, data } = payload;
      return {
        ...state,
        loading: false,
        roles: data,
        roleList: data,
        itemCount,
        pageCount,
      };

    case ROLE_GET_LIST_SEARCH:
      if (payload === '') {
        return {
          ...state,
          roles: state.roleList,
        };
      } else {
        return {
          ...state,
          loading: false,
          roles: state.roleList.filter(item => String(item.name.toLowerCase()).startsWith(payload)),
          searchKeyword: payload,
        };
      }

    case ROLE_DELETE_ITEM:
      return { ...state, loading: false };

    case ROLE_DELETE_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.payload,
        role: action.payload,
      };

    case CLOSE_ROLE_ERROR_NOTIFICATION:
      return {
        ...state,
        errorNotification: false,
        statusCode: 0,
      };

    case GET_PERMISSION_LIST_ERROR:
    case ROLE_GET_BY_ID_ERROR:
    case ROLE_DELETE_ITEM_ERROR:
      const { status } = payload;
      return {
        ...state,
        loading: false,
        error: data,
        statusCode: status,
        errorNotification: status === 500,
      };

    case ROLE_GET_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.data,
        statusCode: payload.status,
        errorNotification: payload.status === 401,
      };

    default:
      return {
        ...state,
      };
  }
}
