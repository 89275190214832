// BUDGET_TRACKING
export const BUDGET_TRACKING_GET_BY_ID_ERROR = 'BUDGET_TRACKING_GET_BY_ID_ERROR';

export const BUDGET_TRACKING_GET_LIST = 'BUDGET_TRACKING_GET_LIST';
export const BUDGET_TRACKING_GET_LIST_SUCCESS = 'BUDGET_TRACKING_GET_LIST_SUCCESS';
export const BUDGET_TRACKING_GET_LIST_ERROR = 'BUDGET_TRACKING_GET_LIST_ERROR';

export const BUDGET_TRACKING_EDIT_ERROR = 'BUDGET_TRACKING_EDIT_ERROR';
export const BUDGET_TRACKING_ADD_NEW_ERROR = 'BUDGET_TRACKING_ADD_NEW_ERROR';

export const BUDGET_TRACKING_DELETE_ERROR = 'BUDGET_TRACKING_DELETE_ERROR';

export const BUDGET_TRACKING_LOG_LIST_ERROR = 'BUDGET_TRACKING_LOG_LIST_ERROR';
export const BUDGET_TRACKING_DELETE_ATTACHMENT_ERROR = 'BUDGET_TRACKING_DELETE_ATTACHMENT_ERROR';

export const BUDGET_TRACKING_REPORT_ERROR = 'BUDGET_TRACKING_REPORT_ERROR';

export const BUDGET_TRACKING_ADD_CLIENT_SUPPORT = 'BUDGET_TRACKING_ADD_CLIENT_SUPPORT';
export const BUDGET_TRACKING_EDIT_CLIENT_SUPPORT = 'BUDGET_TRACKING_EDIT_CLIENT_SUPPORT';

export const BUDGET_TRACKING_ADD_PLACEMENT = 'BUDGET_TRACKING_ADD_PLACEMENT';
export const BUDGET_TRACKING_EDIT_PLACEMENT = 'BUDGET_TRACKING_EDIT_PLACEMENT';

export const BUDGET_TRACKING_GET_CLIENT_SUPPORT_BY_ID = 'BUDGET_TRACKING_GET_CLIENT_SUPPORT_BY_ID';
export const BUDGET_TRACKING_GET_PLACEMENT_BY_ID = 'BUDGET_TRACKING_GET_PLACEMENT_BY_ID';

export const BUDGET_TRACKING_GET_CLIENT_SUPPORT_BY_ID_SUCCESS =
  'BUDGET_TRACKING_GET_CLIENT_SUPPORT_BY_ID_SUCCESS';
export const BUDGET_TRACKING_GET_PLACEMENT_BY_ID_SUCCESS =
  'BUDGET_TRACKING_GET_PLACEMENT_BY_ID_SUCCESS';

export const BUDGET_TRACKING_DELETE_CLIENT_SUPPORT = 'BUDGET_TRACKING_DELETE_CLIENT_SUPPORT';
export const BUDGET_TRACKING_DELETE_PLACEMENT = 'BUDGET_TRACKING_DELETE_PLACEMENT';
