import {
  SUB_GOAL_LBS_ADD_NEW,
  SUB_GOAL_LBS_ADD_NEW_ERROR,
  SUB_GOAL_LBS_BY_CLIENT,
  SUB_GOAL_LBS_BY_CLIENT_SUCCESS,
  SUB_GOAL_LBS_BY_CLIENT_ERROR,
} from './types';

/** SubGoalLBS */

export const getSubGoalLBSByClient = query => ({
  type: SUB_GOAL_LBS_BY_CLIENT,
  payload: query,
});

export const getSubGoalLBSByClientSuccess = caseNotes => ({
  type: SUB_GOAL_LBS_BY_CLIENT_SUCCESS,
  payload: caseNotes,
});

export const getSubGoalLBSByClientError = error => ({
  type: SUB_GOAL_LBS_BY_CLIENT_ERROR,
  payload: error,
});

export const addNewSubGoalLBS = caseNote => ({
  type: SUB_GOAL_LBS_ADD_NEW,
  payload: caseNote,
});

export const addNewSubGoalLBSError = error => ({
  type: SUB_GOAL_LBS_ADD_NEW_ERROR,
  payload: error,
});
