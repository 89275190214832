import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authHeader } from '../../helpers/auth-header';
import { buildUrl } from '../../helpers/Utils';
import axios from 'axios';
import Config from '../../config';

import {
  YJC_CONTRACT_GET_LIST,
  YJC_CONTRACT_ADD_ITEM,
  YJC_CONTRACT_EDIT_ITEM,
  YJC_CONTRACT_DELETE_ITEM,
  YJC_CONTRACT_GET_BY_ID,
  YJC_CONTRACT_GET_BY_EMPLOYER,
  YJC_CONTRACT_GET_BY_CLIENT,
} from '../actions';

import {
  getYJCContractListSuccess,
  getYJCContractListError,
  addYJCContractItemError,
  editYJCContractItemError,
  deleteYJCContractItemSuccess,
  deleteYJCContractItemError,
  getYJCContractByIdSuccess,
  getYJCContractByIdError,
  getYJCContractByEmployerError,
  getYJCContractByEmployerSuccess,
  getYJCContractByClientError,
  getYJCContractByClientSuccess,
  addYJCContractItemSuccess,
  editYJCContractItemSuccess,
} from './actions';

const getYJCContractByClientRequest = async query => {
  let url = `${Config.apiServerHost}/api/YJCContract/getByClient`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const getYJCContractByEmployerRequest = async query => {
  let url = `${Config.apiServerHost}/api/YJCContract/getByEmployer`;
  return axios.get(buildUrl(url, query), await authHeader());
};

const getYJCContractsRequest = async () => {
  return await axios.get(`${Config.apiServerHost}/api/YJCContract`, await authHeader());
};

const getYJCContractListRequest = async query => {
  let url = `${Config.apiServerHost}/api/YJCContract/search`;
  return await axios.get(buildUrl(url, query), await authHeader());
};

const getYJCContractRequest = async id => {
  return await axios.get(`${Config.apiServerHost}/api/YJCContract/${id}`, await authHeader());
};

const addNewYJCContractRequest = async data => {
  return await axios.post(`${Config.apiServerHost}/api/YJCContract`, data, await authHeader());
};

const editYJCContractRequest = async data => {
  return await axios.put(`${Config.apiServerHost}/api/YJCContract`, data, await authHeader());
};

const deleteYJCContractRequest = async id => {
  return await axios.delete(`${Config.apiServerHost}/api/YJCContract/${id}`, await authHeader());
};

function* getYJCContractByClient({ payload }) {
  try {
    const response = yield call(getYJCContractByClientRequest, payload);
    yield put(getYJCContractByClientSuccess(response.data));
  } catch (error) {
    yield put(getYJCContractByClientError(error));
  }
}

function* getYJCContractByEmployer({ payload }) {
  try {
    const response = yield call(getYJCContractByEmployerRequest, payload);
    yield put(getYJCContractByEmployerSuccess(response.data));
  } catch (error) {
    yield put(getYJCContractByEmployerError(error));
  }
}

function* getYJCContractById({ payload }) {
  try {
    const response = yield call(getYJCContractRequest, payload);
    yield put(getYJCContractByIdSuccess(response.data));
  } catch (error) {
    yield put(getYJCContractByIdError(error));
  }
}

function* getYJCContractList({ payload }) {
  try {
    const response = yield call(getYJCContractListRequest, payload);
    yield put(getYJCContractListSuccess(response.data));
  } catch (error) {
    yield put(getYJCContractListError(error));
  }
}

function* addNewYJCContract({ payload }) {
  try {
    const { item, history } = payload;
    if (history) {
      yield call(addNewYJCContractRequest, item);
      history.push('/app/contract/yjc');
    } else {
      const { data, clientId, employerId, servicePlanHeaderId } = item;
      const query = {
        clientId: clientId ?? '',
        employerId: employerId ?? '',
        servicePlanHeaderId: servicePlanHeaderId ?? '',
      };

      yield call(addNewYJCContractRequest, data);
      const response = yield call(getYJCContractListRequest, query);
      yield put(addYJCContractItemSuccess(response.data));
    }
  } catch (error) {
    yield put(addYJCContractItemError(error));
  }
}

function* editYJCContract({ payload }) {
  try {
    const { item, history } = payload;
    if (history) {
      yield call(editYJCContractRequest, item);
      history.push('/app/contract/yjc');
    } else {
      const { data, clientId, employerId, servicePlanHeaderId } = item;
      const query = {
        clientId: clientId ?? '',
        employerId: employerId ?? '',
        servicePlanHeaderId: servicePlanHeaderId ?? '',
      };
      yield call(editYJCContractRequest, data);
      const response = yield call(getYJCContractListRequest, query);
      yield put(editYJCContractItemSuccess(response.data));
    }
  } catch (error) {
    yield put(editYJCContractItemError(error));
  }
}

function* deleteYJCContract({ payload }) {
  try {
    const { clientId, id, employerId } = payload;
    const query = {
      clientId: clientId ?? '',
      employerId: employerId ?? '',
    };
    yield call(deleteYJCContractRequest, id);
    const response = yield call(getYJCContractListRequest, query);
    yield put(deleteYJCContractItemSuccess(response.data));
  } catch (error) {
    yield put(deleteYJCContractItemError(error));
  }
}

export function* watchYJCContractByClient() {
  yield takeEvery(YJC_CONTRACT_GET_BY_CLIENT, getYJCContractByClient);
}

export function* watchYJCContractByEmployer() {
  yield takeEvery(YJC_CONTRACT_GET_BY_EMPLOYER, getYJCContractByEmployer);
}

export function* watchSEContactList() {
  yield takeEvery(YJC_CONTRACT_GET_LIST, getYJCContractList);
}

export function* watchSEContactGetById() {
  yield takeEvery(YJC_CONTRACT_GET_BY_ID, getYJCContractById);
}

export function* watchYJCContractAddNew() {
  yield takeEvery(YJC_CONTRACT_ADD_ITEM, addNewYJCContract);
}
export function* watchYJCContractEdit() {
  yield takeEvery(YJC_CONTRACT_EDIT_ITEM, editYJCContract);
}
export function* watchYJCContractDelete() {
  yield takeEvery(YJC_CONTRACT_DELETE_ITEM, deleteYJCContract);
}

export default function* rootSaga() {
  yield all([
    fork(watchSEContactList),
    fork(watchSEContactGetById),
    fork(watchYJCContractAddNew),
    fork(watchYJCContractEdit),
    fork(watchYJCContractDelete),
    fork(watchYJCContractByEmployer),
    fork(watchYJCContractByClient),
  ]);
}

export { getYJCContractsRequest };
