import {
  BUDGET_TRACKING_ADD_NEW_ERROR,
  BUDGET_TRACKING_DELETE_ERROR,
  BUDGET_TRACKING_EDIT_ERROR,
  BUDGET_TRACKING_GET_BY_ID_ERROR,
  BUDGET_TRACKING_GET_LIST,
  BUDGET_TRACKING_GET_LIST_ERROR,
  BUDGET_TRACKING_GET_LIST_SUCCESS,
  BUDGET_TRACKING_LOG_LIST_ERROR,
  BUDGET_TRACKING_DELETE_ATTACHMENT_ERROR,
  BUDGET_TRACKING_REPORT_ERROR,
  BUDGET_TRACKING_GET_CLIENT_SUPPORT_BY_ID_SUCCESS,
  BUDGET_TRACKING_GET_PLACEMENT_BY_ID,
  BUDGET_TRACKING_GET_PLACEMENT_BY_ID_SUCCESS,
  BUDGET_TRACKING_GET_CLIENT_SUPPORT_BY_ID,
  BUDGET_TRACKING_EDIT_CLIENT_SUPPORT,
  BUDGET_TRACKING_EDIT_PLACEMENT,
  BUDGET_TRACKING_DELETE_PLACEMENT,
  BUDGET_TRACKING_DELETE_CLIENT_SUPPORT,
} from './types';
// clientSupport
const initialState = {
  item: null,
  items: [],
  supportTypes: [],
  clientSupport: null,
  placement: null,
  loading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
  generalContract: 0,
  odspContract: 0,

  generalCommitted: 0,
  generalReturn: 0,
  generalSpent: 0,
  totalRollingPaid: 0,
  totalOdspRollingPaid: 0,
  generalAvailable: 0,

  odspCommitted: 0,
  odspReturn: 0,
  odspSpent: 0,
  odspAvailable: 0,

  total: 0,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case BUDGET_TRACKING_GET_CLIENT_SUPPORT_BY_ID:
      return {
        ...state,
        clientSupport: null,
      };
    case BUDGET_TRACKING_DELETE_CLIENT_SUPPORT:
    case BUDGET_TRACKING_DELETE_PLACEMENT:
    case BUDGET_TRACKING_EDIT_PLACEMENT:
    case BUDGET_TRACKING_EDIT_CLIENT_SUPPORT:
    case BUDGET_TRACKING_GET_LIST:
      return {
        ...state,
        loading: true,
        error: null,
        clientSupport: null,
        placement: null,
      };

    case BUDGET_TRACKING_GET_CLIENT_SUPPORT_BY_ID_SUCCESS:
      return {
        ...state,
        // loading: false,
        clientSupport: payload,
      };

    case BUDGET_TRACKING_GET_PLACEMENT_BY_ID_SUCCESS:
      return {
        ...state,
        // loading: false,
        placement: payload,
      };

    case BUDGET_TRACKING_GET_LIST_SUCCESS:
      const {
        generalContract,
        odspContract,
        generalCommitted,
        generalReturn,
        generalSpent,
        totalRollingPaid,
        totalOdspRollingPaid,
        generalAvailable,
        odspCommitted,
        odspReturn,
        odspSpent,
        odspAvailable,
        itemCount,
        pageCount,
        data,
        supportTypes,
      } = payload;
      return {
        ...state,
        generalContract,
        odspContract,
        generalCommitted,
        generalReturn,
        generalSpent,
        totalRollingPaid,
        totalOdspRollingPaid,
        generalAvailable,
        odspCommitted,
        odspReturn,
        odspSpent,
        odspAvailable,
        itemCount,
        pageCount,
        items: data,
        placement: null,
        clientSupport: null,
        supportTypes,
        loading: false,
      };

    case BUDGET_TRACKING_DELETE_ATTACHMENT_ERROR:
    case BUDGET_TRACKING_DELETE_ERROR:
    case BUDGET_TRACKING_ADD_NEW_ERROR:
    case BUDGET_TRACKING_EDIT_ERROR:
    case BUDGET_TRACKING_GET_BY_ID_ERROR:
    case BUDGET_TRACKING_GET_LIST_ERROR:
    case BUDGET_TRACKING_LOG_LIST_ERROR:
    case BUDGET_TRACKING_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
