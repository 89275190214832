import {
  CEC_LIST_ADD_NEW,
  CEC_LIST_ADD_NEW_SUCCESS,
  CEC_LIST_ADD_NEW_ERROR,
  CEC_LIST_DELETE,
  CEC_LIST_DELETE_ERROR,
  CEC_LIST_DELETE_SUCCESS,
  CEC_LIST_EDIT,
  CEC_LIST_EDIT_ERROR,
  CEC_LIST_EDIT_SUCCESS,
  CEC_LIST_GET_BY_ID,
  CEC_LIST_GET_BY_ID_ERROR,
  CEC_LIST_GET_BY_ID_SUCCESS,
  CEC_LIST_GET_LIST,
  CEC_LIST_GET_LIST_ERROR,
  CEC_LIST_GET_LIST_SUCCESS,
  CEC_LIST_ADD_NEW_REQUEST,
  CEC_LIST_FRONT_GET_BY_ID,
  CEC_LIST_SELECTED_ITEMS_CHANGE,
  CEC_LIST_BULK_DELETE,
  CEC_LIST_BULK_DELETE_SUCCESS,
  CEC_LIST_BULK_DELETE_ERROR,
} from '../actions';
// CECList

export const getCECListList = query => ({
  type: CEC_LIST_GET_LIST,
  payload: query,
});

export const getCECListListSuccess = items => ({
  type: CEC_LIST_GET_LIST_SUCCESS,
  payload: items,
});

export const getCECListListError = error => ({
  type: CEC_LIST_GET_LIST_ERROR,
  payload: error,
});

export const addNewCECList = item => ({
  type: CEC_LIST_ADD_NEW,
  payload: item,
});

export const addNewCECListRequest = () => ({
  type: CEC_LIST_ADD_NEW_REQUEST,
});

export const addNewCECListSuccess = item => ({
  type: CEC_LIST_ADD_NEW_SUCCESS,
  payload: item,
});

export const addNewCECListError = error => ({
  type: CEC_LIST_ADD_NEW_ERROR,
  payload: error,
});

export const editCECList = item => ({
  type: CEC_LIST_EDIT,
  payload: item,
});

export const editCECListSuccess = item => ({
  type: CEC_LIST_EDIT_SUCCESS,
  payload: item,
});

export const editCECListError = error => ({
  type: CEC_LIST_EDIT_ERROR,
  payload: error,
});

export const deleteCECList = id => ({
  type: CEC_LIST_DELETE,
  payload: id,
});

export const deleteCECListSuccess = id => ({
  type: CEC_LIST_DELETE_SUCCESS,
  payload: id,
});

export const deleteCECListError = error => ({
  type: CEC_LIST_DELETE_ERROR,
  payload: error,
});

export const bulkDeleteCECList = query => ({
  type: CEC_LIST_BULK_DELETE,
  payload: query,
});

export const bulkDeleteCECListSuccess = data => ({
  type: CEC_LIST_BULK_DELETE_SUCCESS,
  payload: data,
});

export const bulkDeleteCECListError = error => ({
  type: CEC_LIST_BULK_DELETE_ERROR,
  payload: error,
});

export const getCECListById = id => ({
  type: CEC_LIST_GET_BY_ID,
  payload: id,
});

export const getCECListByIdSuccess = item => ({
  type: CEC_LIST_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getCECListByIdError = error => ({
  type: CEC_LIST_GET_BY_ID_ERROR,
  payload: error,
});

export const getCECListFrontById = id => ({
  type: CEC_LIST_FRONT_GET_BY_ID,
  payload: id,
});

export const selectedCECListItemsChange = items => ({
  type: CEC_LIST_SELECTED_ITEMS_CHANGE,
  payload: items,
});
