import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  EVENT_CALENDAR_DELETE,
  EVENT_CALENDAR_EDIT,
  EVENT_CALENDAR_GET_BY_ID,
  EVENT_CALENDAR_ADD_NEW,
  EVENT_CALENDAR_GET_LIST,
} from '../actions';

import {
  getEventCalendarListSuccess,
  getEventCalendarListError,
  editEventCalendarSuccess,
  editEventCalendarError,
  addNewEventCalendarSuccess,
  addNewEventCalendarError,
  deleteEventCalendarSuccess,
  deleteEventCalendarError,
  getEventCalendarByIdSuccess,
} from './actions';

const getEventCalendarListRequest = async userId => {
  return axios.get(
    `${Config.apiServerHost}/api/EventCalendar/my-calendar/${userId}`,
    await authHeader()
  );
};

const addNewEventCalendarReqeust = async event => {
  return axios.post(`${Config.apiServerHost}/api/EventCalendar`, event, await authHeader());
};

const editEventCalendarRequest = async event => {
  return axios.put(`${Config.apiServerHost}/api/EventCalendar`, event, await authHeader());
};

const deleteEventCalendarRequest = async id => {
  return axios.delete(`${Config.apiServerHost}/api/EventCalendar/${id}`, await authHeader());
};

const getEventCalendarByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/EventCalendar/${id}`, await authHeader());
};

function* getEventCalendarList({ payload }) {
  try {
    const response = yield call(getEventCalendarListRequest, payload);
    yield put(getEventCalendarListSuccess(response.data));
  } catch (error) {
    yield put(getEventCalendarListError(error.response.statusText));
  }
}

function* addNewEventCalendar({ payload }) {
  try {
    let response = yield call(addNewEventCalendarReqeust, payload);
    response = yield call(getEventCalendarByIdRequest, response.data);
    yield put(addNewEventCalendarSuccess(response.data));
  } catch (error) {
    yield put(addNewEventCalendarError(error.response.statusText));
  }
}

function* editEventCalendar({ payload }) {
  try {
    let response = yield call(editEventCalendarRequest, payload);
    response = yield call(getEventCalendarByIdRequest, payload.id);
    yield put(editEventCalendarSuccess(response.data));
  } catch (error) {
    yield put(editEventCalendarError(error.response.statusText));
  }
}

function* getEventCalendarById({ payload }) {
  try {
    const response = yield call(getEventCalendarByIdRequest, payload);
    yield put(getEventCalendarByIdSuccess(response.data));
  } catch (error) {
    yield put(editEventCalendarError(error.response.statusText));
  }
}

function* deleteEventCalendar({ payload }) {
  try {
    const response = yield call(deleteEventCalendarRequest, payload);
    yield put(deleteEventCalendarSuccess(response.data));
  } catch (error) {
    yield put(deleteEventCalendarError(error.response.statusText));
  }
}

export function* watchEventCalendarList() {
  yield takeEvery(EVENT_CALENDAR_GET_LIST, getEventCalendarList);
}

export function* watchAddNewEventCalendar() {
  yield takeEvery(EVENT_CALENDAR_ADD_NEW, addNewEventCalendar);
}

export function* watchEventCalendarById() {
  yield takeEvery(EVENT_CALENDAR_GET_BY_ID, getEventCalendarById);
}

export function* watchEditEventCalendar() {
  yield takeEvery(EVENT_CALENDAR_EDIT, editEventCalendar);
}

export function* watchDeleteEventCalendar() {
  yield takeEvery(EVENT_CALENDAR_DELETE, deleteEventCalendar);
}

export default function* rootSaga() {
  yield all([
    fork(watchEventCalendarList),
    fork(watchAddNewEventCalendar),
    fork(watchEventCalendarById),
    fork(watchEditEventCalendar),
    fork(watchDeleteEventCalendar),
  ]);
}
