// EMAIL_TEMPLATE
export const EMAIL_TEMPLATE_GET_BY_ID = 'EMAIL_TEMPLATE_GET_BY_ID';
export const EMAIL_TEMPLATE_GET_BY_ID_SUCCESS = 'EMAIL_TEMPLATE_GET_BY_ID_SUCCESS';
export const EMAIL_TEMPLATE_GET_BY_ID_ERROR = 'EMAIL_TEMPLATE_GET_BY_ID_ERROR';

export const EMAIL_TEMPLATE_EDIT = 'EMAIL_TEMPLATE_EDIT';
export const EMAIL_TEMPLATE_EDIT_SUCCESS = 'EMAIL_TEMPLATE_EDIT_SUCCESS';
export const EMAIL_TEMPLATE_EDIT_ERROR = 'EMAIL_TEMPLATE_EDIT_ERROR';

export const EMAIL_TEMPLATE_ADD_NEW = 'EMAIL_TEMPLATE_ADD_NEW';
export const EMAIL_TEMPLATE_ADD_NEW_SUCCESS = 'EMAIL_TEMPLATE_ADD_NEW_SUCCESS';
export const EMAIL_TEMPLATE_ADD_NEW_ERROR = 'EMAIL_TEMPLATE_ADD_NEW_ERROR';
export const EMAIL_TEMPLATE_ADD_NEW_REQUEST = 'EMAIL_TEMPLATE_ADD_NEW_REQUEST';

export const EMAIL_TEMPLATE_DELETE = 'EMAIL_TEMPLATE_DELETE';
export const EMAIL_TEMPLATE_DELETE_SUCCESS = 'EMAIL_TEMPLATE_DELETE_SUCCESS';
export const EMAIL_TEMPLATE_DELETE_ERROR = 'EMAIL_TEMPLATE_DELETE_ERROR';

export const EMAIL_TEMPLATE_GET_BY_QUERY = 'EMAIL_TEMPLATE_GET_BY_QUERY';
export const EMAIL_TEMPLATE_GET_BY_QUERY_SUCCESS = 'EMAIL_TEMPLATE_GET_BY_QUERY_SUCCESS';
export const EMAIL_TEMPLATE_GET_BY_QUERY_ERROR = 'EMAIL_TEMPLATE_GET_BY_QUERY_ERROR';
