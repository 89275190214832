import {
  ESUITE_ADD_NEW,
  ESUITE_ADD_NEW_SUCCESS,
  ESUITE_ADD_NEW_ERROR,
  ESUITE_EDIT,
  ESUITE_EDIT_ERROR,
  ESUITE_EDIT_SUCCESS,
  ESUITE_GET_BY_QUERY,
  ESUITE_GET_BY_ID,
  ESUITE_GET_BY_QUERY_SUCCESS,
  ESUITE_GET_BY_QUERY_ERROR,
  ESUITE_GET_BY_ID_SUCCESS,
  ESUITE_ADD_NEW_REQUEST,
  ESUITE_DELETE,
  ESUITE_DELETE_SUCCESS,
  ESUITE_DELETE_ERROR,
  ESUITE_GET_BY_ID_ERROR,
} from './types';

// ESuite

export const addNewESuite = items => ({
  type: ESUITE_ADD_NEW,
  payload: items,
});

export const addNewESuiteRequest = () => ({
  type: ESUITE_ADD_NEW_REQUEST,
});

export const addNewESuiteSuccess = items => ({
  type: ESUITE_ADD_NEW_SUCCESS,
  payload: items,
});

export const addNewESuiteError = error => ({
  type: ESUITE_ADD_NEW_ERROR,
  payload: error,
});

export const editESuite = item => ({
  type: ESUITE_EDIT,
  payload: item,
});

export const editESuiteSuccess = item => ({
  type: ESUITE_EDIT_SUCCESS,
  payload: item,
});

export const editESuiteError = error => ({
  type: ESUITE_EDIT_ERROR,
  payload: error,
});

export const getESuiteByQuery = query => ({
  type: ESUITE_GET_BY_QUERY,
  payload: query,
});

export const getESuiteByQuerySuccess = item => ({
  type: ESUITE_GET_BY_QUERY_SUCCESS,
  payload: item,
});

export const getESuiteByQueryError = error => ({
  type: ESUITE_GET_BY_QUERY_ERROR,
  payload: error,
});

export const getESuiteById = id => ({
  type: ESUITE_GET_BY_ID,
  payload: id,
});

export const getESuiteByIdSuccess = item => ({
  type: ESUITE_GET_BY_ID_SUCCESS,
  payload: item,
});

export const getESuiteByIdError = error => ({
  type: ESUITE_GET_BY_ID_ERROR,
  payload: error,
});

export const deleteESuiteById = id => ({
  type: ESUITE_DELETE,
  payload: id,
});

export const deleteESuiteByIdSuccess = item => ({
  type: ESUITE_DELETE_SUCCESS,
  payload: item,
});

export const deleteESuiteByIdErrorr = item => ({
  type: ESUITE_DELETE_ERROR,
  payload: item,
});
