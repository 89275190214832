import {
  STAKE_HOLDER_DOCUMENT_ADD_NEW,
  STAKE_HOLDER_DOCUMENT_ADD_NEW_ERROR,
  STAKE_HOLDER_DOCUMENT_ADD_NEW_SUCCESS,
  STAKE_HOLDER_DOCUMENT_BULK_ADD_NEW,
  STAKE_HOLDER_DOCUMENT_BULK_ADD_NEW_ERROR,
  STAKE_HOLDER_DOCUMENT_BULK_ADD_NEW_SUCCESS,
  STAKE_HOLDER_DOCUMENT_CHANGE_STATUS,
  STAKE_HOLDER_DOCUMENT_CHANGE_STATUS_ERROR,
  STAKE_HOLDER_DOCUMENT_CHANGE_STATUS_SUCCESS,
  STAKE_HOLDER_DOCUMENT_DELETE,
  STAKE_HOLDER_DOCUMENT_DELETE_ERROR,
  STAKE_HOLDER_DOCUMENT_DELETE_SUCCESS,
  STAKE_HOLDER_DOCUMENT_GET_BY_ID,
  STAKE_HOLDER_DOCUMENT_GET_BY_ID_ERROR,
  STAKE_HOLDER_DOCUMENT_GET_BY_ID_SUCCESS,
  STAKE_HOLDER_DOCUMENT_GET_PAGING,
  STAKE_HOLDER_DOCUMENT_GET_PAGING_ERROR,
  STAKE_HOLDER_DOCUMENT_GET_PAGING_SUCCESS,
  STAKE_HOLDER_DOCUMENT_UPDATE,
  STAKE_HOLDER_DOCUMENT_UPDATE_ERROR,
  STAKE_HOLDER_DOCUMENT_UPDATE_SUCCESS,
} from './types';
// StakeHolderDocument
export const getPagingStakeHolderDocument = items => ({
  type: STAKE_HOLDER_DOCUMENT_GET_PAGING,
  payload: items,
});

export const getPagingStakeHolderDocumentSuccess = items => ({
  type: STAKE_HOLDER_DOCUMENT_GET_PAGING_SUCCESS,
  payload: items,
});

export const getPagingStakeHolderDocumentError = error => ({
  type: STAKE_HOLDER_DOCUMENT_GET_PAGING_ERROR,
  payload: error,
});

export const addStakeHolderDocument = items => ({
  type: STAKE_HOLDER_DOCUMENT_ADD_NEW,
  payload: items,
});

export const addStakeHolderDocumentSuccess = items => ({
  type: STAKE_HOLDER_DOCUMENT_ADD_NEW_SUCCESS,
  payload: items,
});

export const addStakeHolderDocumentError = error => ({
  type: STAKE_HOLDER_DOCUMENT_ADD_NEW_ERROR,
  payload: error,
});

export const bulkAddStakeHolderDocument = items => ({
  type: STAKE_HOLDER_DOCUMENT_BULK_ADD_NEW,
  payload: items,
});

export const bulkAddStakeHolderDocumentSuccess = items => ({
  type: STAKE_HOLDER_DOCUMENT_BULK_ADD_NEW_SUCCESS,
  payload: items,
});

export const bulkAddStakeHolderDocumentError = error => ({
  type: STAKE_HOLDER_DOCUMENT_BULK_ADD_NEW_ERROR,
  payload: error,
});

export const getStakeHolderDocumentById = items => ({
  type: STAKE_HOLDER_DOCUMENT_GET_BY_ID,
  payload: items,
});

export const getStakeHolderDocumentByIdSuccess = items => ({
  type: STAKE_HOLDER_DOCUMENT_GET_BY_ID_SUCCESS,
  payload: items,
});

export const getStakeHolderDocumentByIdError = error => ({
  type: STAKE_HOLDER_DOCUMENT_GET_BY_ID_ERROR,
  payload: error,
});

export const updateStakeHolderDocument = items => ({
  type: STAKE_HOLDER_DOCUMENT_UPDATE,
  payload: items,
});

export const updateStakeHolderDocumentSuccess = items => ({
  type: STAKE_HOLDER_DOCUMENT_UPDATE_SUCCESS,
  payload: items,
});

export const updateStakeHolderDocumentError = error => ({
  type: STAKE_HOLDER_DOCUMENT_UPDATE_ERROR,
  payload: error,
});

export const changeStatusStakeHolderDocument = items => ({
  type: STAKE_HOLDER_DOCUMENT_CHANGE_STATUS,
  payload: items,
});

export const changeStatusStakeHolderDocumentSuccess = items => ({
  type: STAKE_HOLDER_DOCUMENT_CHANGE_STATUS_SUCCESS,
  payload: items,
});

export const changeStatusStakeHolderDocumentError = error => ({
  type: STAKE_HOLDER_DOCUMENT_CHANGE_STATUS_ERROR,
  payload: error,
});

export const deleteStakeHolderDocument = items => ({
  type: STAKE_HOLDER_DOCUMENT_DELETE,
  payload: items,
});

export const deleteStakeHolderDocumentSuccess = items => ({
  type: STAKE_HOLDER_DOCUMENT_DELETE_SUCCESS,
  payload: items,
});

export const deleteStakeHolderDocumentError = error => ({
  type: STAKE_HOLDER_DOCUMENT_DELETE_ERROR,
  payload: error,
});
