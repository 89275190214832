import {
  POSTING_NEW_MATCHING_GET_BY_ID,
  POSTING_NEW_MATCHING_GET_BY_ID_SUCCESS,
  POSTING_NEW_MATCHING_GET_LIST,
  POSTING_NEW_MATCHING_GET_LIST_SUCCESS,
  POSTING_NEW_MATCHING_ERROR,
} from './types';
// PostingNewMatching
export const getPostingNewMatchingList = query => ({
  type: POSTING_NEW_MATCHING_GET_LIST,
  payload: query,
});

export const getPostingNewMatchingListSuccess = item => ({
  type: POSTING_NEW_MATCHING_GET_LIST_SUCCESS,
  payload: item,
});

export const getPostingNewMatchingById = id => ({
  type: POSTING_NEW_MATCHING_GET_BY_ID,
  payload: id,
});

export const getPostingNewMatchingByIdSuccess = item => ({
  type: POSTING_NEW_MATCHING_GET_BY_ID_SUCCESS,
  payload: item,
});

export const postingNewMatchingError = error => ({
  type: POSTING_NEW_MATCHING_ERROR,
  payload: error,
});
