import {
  SE_CASE_NOTE_ADD_NEW,
  SE_CASE_NOTE_ADD_NEW_SUCCESS,
  SE_CASE_NOTE_ADD_NEW_ERROR,
  SE_CASE_NOTE_DELETE,
  SE_CASE_NOTE_DELETE_ERROR,
  SE_CASE_NOTE_DELETE_SUCCESS,
  SE_CASE_NOTE_EDIT,
  SE_CASE_NOTE_EDIT_ERROR,
  SE_CASE_NOTE_EDIT_SUCCESS,
  SE_CASE_NOTE_GET_BY_ID,
  SE_CASE_NOTE_GET_BY_ID_ERROR,
  SE_CASE_NOTE_GET_BY_ID_SUCCESS,
  SE_CASE_NOTE_GET_LIST,
  SE_CASE_NOTE_GET_LIST_ERROR,
  SE_CASE_NOTE_GET_LIST_SUCCESS,
  SE_CASE_NOTE_ADD_NEW_REQUEST,
  SE_CASE_NOTE_QUERY,
  SE_CASE_NOTE_QUERY_SUCCESS,
  SE_CASE_NOTE_QUERY_ERROR,
} from '../actions';

const initialState = {
  caseNotes: [],
  caseNote: null,
  loading: false,
  error: null,
  addSuccess: false,
  editSuccess: false,
  deleteSuccess: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SE_CASE_NOTE_GET_BY_ID:
      return {
        ...state,
        loading: false,
        error: null,
        editSuccess: false,
      };
    case SE_CASE_NOTE_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: true,
        caseNote: payload,
      };

    case SE_CASE_NOTE_ADD_NEW_REQUEST:
      return {
        ...state,
        addSuccess: false,
      };

    case SE_CASE_NOTE_QUERY:
    case SE_CASE_NOTE_GET_LIST:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case SE_CASE_NOTE_QUERY_SUCCESS:
    case SE_CASE_NOTE_GET_LIST_SUCCESS:
      return {
        ...state,
        caseNotes: payload,
        loading: true,
      };
    case SE_CASE_NOTE_ADD_NEW:
      return {
        ...state,
        loading: false,
        error: null,
        addSuccess: false,
      };

    case SE_CASE_NOTE_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: true,
        caseNote: payload,
        caseNotes: state.caseNotes.concat(payload),
        addSuccess: true,
      };

    case SE_CASE_NOTE_EDIT:
      return {
        ...state,
        loading: false,
        error: null,
        editSuccess: false,
      };
    case SE_CASE_NOTE_EDIT_SUCCESS:
      return {
        ...state,
        loading: true,
        caseNotes: state.caseNotes.map(c => {
          return c.id === payload.id ? payload : c;
        }),
        caseNote: payload,
        error: null,
        editSuccess: true,
      };
    case SE_CASE_NOTE_DELETE:
      return {
        ...state,
        loading: false,
        error: null,
        deleteSuccess: false,
      };
    case SE_CASE_NOTE_DELETE_SUCCESS:
      return {
        ...state,
        loading: true,
        caseNotes: state.caseNotes.filter(c => c.id !== payload),
        deleteSuccess: true,
      };
    case SE_CASE_NOTE_QUERY_ERROR:
    case SE_CASE_NOTE_DELETE_ERROR:
    case SE_CASE_NOTE_ADD_NEW_ERROR:
    case SE_CASE_NOTE_EDIT_ERROR:
    case SE_CASE_NOTE_GET_BY_ID_ERROR:
    case SE_CASE_NOTE_GET_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
};
